import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { Person, PersonRole } from '../../../types/Asset';
import { CardCircle } from '../../Cards/CardCircle/CardCircle';
import { useWorld } from '../../../providers/useWorld/WorldContext';
import { WorldSectionType } from '../../../types/World';
import { StandardCover } from '../../../types/Cover';
import { useGuestAwareClickHandler } from '../../../providers/useAuth/AuthContext';
import { CardTrackingData } from '../../../types/Tracking';

export const PersonCardPresenter: FC<{
    asset: Person;
    trackingData: CardTrackingData;
}> = ({ asset, trackingData }) => {
    const { isWorldItem, toggleWorldItem, manageInAction } = useWorld();
    const guestCheck = useGuestAwareClickHandler();
    const history = useHistory();

    const clickHandler = (event: React.MouseEvent) => {
        if (asset?.onClickOpenCollection) {
            const personCollection = `/collection/my_world_actors_page/${asset.id}`;
            history.push(personCollection);
        } else {
            guestCheck(event, () => {
                if (!manageInAction) {
                    toggleWorldItem(asset.title, asset.role === PersonRole.ACTOR ? WorldSectionType.ACTORS : WorldSectionType.DIRECTORS);
                }
            });
        }
    };

    const cover: StandardCover = {
        uuid: asset.id,
        id: asset.id,
        title: asset.title,
        image: asset.image,
        circle: true,
        landscape: false,
        metaData: null,
        channelImage: null,
        landscapeImage: null,
        path: null,
        rawData: asset,
        assetType: asset.type,
        role: asset.role,
        onClick: clickHandler,
        isFavorite: asset?.onClickOpenCollection
            ? false
            : isWorldItem(asset.title, asset.role === PersonRole.ACTOR ? WorldSectionType.ACTORS : WorldSectionType.DIRECTORS),
    };

    return <CardCircle asset={cover} {...trackingData} />;
};
