import { css } from 'styled-components';
import SVGInline from 'react-svg-inline';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { buttonZIndex } from '../../style/styled-components/cssGlobalVariables';
import { Breakpoints, MQ, MQ_NOT_TOUCH } from '../../style/styled-components/cssMediaQueries';
import { hexToRgba } from '../../utils/fnColors';

export const paddingMobile = 16;
export const paddingTablet = 32;
export const paddingDesktop = 40;

export const ArrowIcon = styled(SVGInline)`
    width: auto;
    height: auto;
    display: inline-block;
    position: absolute;
    font-size: 0;
    top: 50%;
    transform: translateY(-50%);

    svg {
        transition: transform 0.2s ease-in-out 0s;
        width: 6px;
        height: 14px;

        path {
            fill: ${props => props.theme.brandedConfig.high_contrast};
        }
    }
`;

export const NavigationArrow = styled.div<{ alwaysOn: boolean; active?: boolean }>`
    cursor: pointer;
    position: absolute;
    display: block;
    z-index: ${buttonZIndex};
    height: 32px;
    width: 32px;
    opacity: ${props => (props.alwaysOn ? (props.active ? '1' : '0.5 !important') : '0')};
    box-shadow: none;
    outline: none;
    margin: 0;
    padding: 0;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    border-radius: 50%;
    transition: opacity 0.2s ease-in-out 0s;
    background-color: ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.66)};
    top: 40%;
    margin: 15px;
    transform: translateY(-50%);

    ${MQ_NOT_TOUCH} {
        &:hover {
            ${ArrowIcon} {
                svg {
                    transform: scale(1.2);
                }
            }
        }
    }
`;

export const ArrowLeft = styled(NavigationArrow)`
    left: 0;
    ${ArrowIcon} {
        left: 12px;
    }
`;

export const ArrowRight = styled(NavigationArrow)`
    right: 0;
    ${ArrowIcon} {
        right: 12px;
    }
`;

export const CardWrapper = styled.div<{ height: number | 'auto'; width: number | 'auto'; withDescription?: boolean }>`
    display: inline-block;
    vertical-align: top;
    scroll-snap-align: start;
    scroll-snap-stop: normal;
    margin-top: 8px;
    margin-left: 8px;
    margin-right: 8px;
    width: ${props => (props.width === 'auto' ? 'auto' : `${props.width}px`)};
    height: ${props => (props.height === 'auto' ? 'auto' : `${props.height}px`)};
    position: relative;

    ${props =>
        props.withDescription
            ? css`
                  margin-bottom: 150px;

                  ${MQ(Breakpoints.xxs)} {
                      margin-bottom: 200px;
                  }

                  ${MQ(Breakpoints.xs)} {
                      margin-bottom: 0px;
                      margin-left: 8px;
                      margin-right: 8px;
                  }

                  ${MQ(Breakpoints.s)} {
                      margin-bottom: 50px;
                      display: inline-block;
                  }
              `
            : ''}

    &.gallery-tile {
        width: 100%;
        height: auto;
        padding-left: 0;
    }
`;

export const LaneWrapper = styled.div`
    width: 100%;
    height: auto;
    position: relative;

    ${MQ_NOT_TOUCH} {
        &:hover {
            ${ArrowLeft}, ${ArrowRight} {
                opacity: 1;
            }
        }
    }

    &.gallery-navigation-icon-style {
        ${NavigationArrow} {
            background: ${props => hexToRgba(props.theme.brandedConfig.high_contrast, 0)};
            border-radius: 4px;
            height: 32px;
            width: 32px;
            transform: none;
            top: auto;
            right: auto;
            left: auto;
            bottom: 105px;
            transition: all 0.2s ease-in-out 0s;

            ${ArrowIcon} {
                svg {
                    height: 20px;
                }
            }

            &:hover {
                background: ${props => hexToRgba(props.theme.brandedConfig.high_contrast, 0.2)};
            }
        }

        ${ArrowLeft} {
            right: 96px;
        }

        ${ArrowRight} {
            right: 40px;
        }
    }
`;

export const LaneScroller = styled.div<{ isFullScreen: boolean; useSnap: boolean; scrollable: boolean }>`
    height: 100%;
    padding: 16px ${paddingMobile}px;
    overflow-y: hidden;
    overflow-x: ${props => (props.scrollable ? 'scroll' : 'hidden')};
    margin-top: ${props => (props.isFullScreen ? '0' : '-12px')};
    white-space: nowrap;
    width: auto;
    text-align: left;
    scrollbar-width: none;
    -ms-overflow-style: none;

    ::-webkit-scrollbar {
        display: none;
        width: 0 !important;

        -webkit-appearance: none;
    }

    &.smooth {
        scroll-behavior: smooth;
    }

    ${props =>
        props.useSnap
            ? css`
                  scroll-padding-left: ${props.isFullScreen ? 0 : paddingMobile}px;
                  scroll-snap-type: x mandatory;
                  -webkit-overflow-scrolling: touch;
                  ${MQ(Breakpoints.s)} {
                      scroll-padding-left: ${props.isFullScreen ? 0 : paddingTablet}px;
                  }

                  ${MQ(Breakpoints.m)} {
                      scroll-padding-left: ${props.isFullScreen ? 0 : paddingDesktop}px;
                  }
              `
            : css`
                  @-moz-document url-prefix() {
                      &:after {
                          content: '';
                          display: inline-block;
                          width: ${paddingMobile}px;

                          ${MQ(Breakpoints.m)} {
                              width: ${paddingTablet}px;
                          }

                          ${MQ(Breakpoints.m)} {
                              width: ${paddingDesktop}px;
                          }
                      }
                  }
              `}

    ${MQ(Breakpoints.s)} {
        padding: 16px ${paddingTablet}px;
    }

    ${MQ(Breakpoints.m)} {
        padding: 16px ${paddingDesktop}px;
    }
`;
