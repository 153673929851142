import { BitrateTrack, PlayerAsset, PlayerConfig, Track } from '../../../types/Player';
import YouboraTracking from '../YouboraTracking';
import { IDRM } from './IDRM';

export abstract class IPlayer {
    protected TAG = '';

    protected video: HTMLVideoElement;

    private debug = false;

    private headers: any;

    protected handler: PlayerConfig = null;

    protected drmHandler: IDRM = null;

    abstract isMuted: () => boolean;

    abstract getVolume: () => number;

    abstract setVolume: (level: number) => void;

    abstract pause: () => void;

    abstract play: () => void;

    abstract seekTo: (seek: number) => void;

    abstract mute: (state: boolean, userAction: boolean) => void;

    abstract getCurrentTime: () => number;

    abstract getDuration: () => number;

    abstract getBuffered: () => any;

    abstract isPlaying: () => boolean;

    abstract resetPlayer: (isRelease?: boolean) => void;

    abstract getTextLanguages: () => Track[];

    abstract getAudioLanguages: () => Track[];

    abstract getBitrateTracks: () => any;

    abstract getThumbnails: (id: number, pos: number) => any;

    abstract getThumbnailTrack: () => any;

    abstract setAudioLanguage: (track: Track) => void;

    abstract setTextLanguage: (track: Track) => void;

    abstract setBitrateTrack: (track: BitrateTrack) => void;

    abstract addEventListener: (event: string, callback: (object?: {}) => void) => void;

    abstract removeEventListener: (event: string, callback: (object?: {}) => void) => void;

    abstract setAsset: (assetDetails: PlayerAsset, startTime: number, suffixDomain: 'tv' | 'vod', preparedCb: () => void) => void;

    abstract configureEngine: (config: any) => void;

    abstract getYoubora: () => YouboraTracking;

    setVideo = (): IPlayer => {
        return this;
    };

    setDRMHandler(handler: IDRM) {
        this.drmHandler = handler;
    }

    preparePlayer(config: PlayerConfig) {
        this.handler = config;
    }

    protected log = (logType: 'log' | 'error' | 'info' | 'warn', ...args: any[]): void => {
        if (this.debug) {
            console[logType](`[${this.TAG}]`, ...args);
        }
    };

    // TODO: specify type or remove setter
    setHeaders = (headers: any): IPlayer => {
        this.headers = headers;
        return headers;
    };

    setDebug = (debug: boolean): IPlayer => {
        this.debug = debug;
        return this;
    };

    getVideo = (): HTMLVideoElement => this.video;

    getDRMType = () => this.drmHandler?.getDRMType();

    getSelectedLanguage = () => null;

    getSelectedBitrateTrack = () => null;

    getSelectedSubtitle = () => null;

    getSnapshot = () => null;
}
