import React, { FC, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { hasProgressBar } from 'utils/fnData';
import { CardWrapper } from '../../Lane/Lane.css';
import {
    CardLandscapeContainer,
    CardLandscapeWrapper,
    DescriptionWrapper,
    ImageWrapper,
    InfoWrapper,
    landscapeCardHeight,
    landscapeCardHoveredHeight,
    landscapeCardWidth,
    LandscapeImageWrapper,
    MetaData,
    StyledButtonPlay,
    Title,
} from './CardLandscape.css';
import {
    CardImage,
    ChannelImageInnerWrapper,
    ChannelLogoWrapper,
    ContentMarker,
    LandscapeImageContainer,
    MoviePortraitImage,
    MoviePortraitImageWrapper,
    StyledSVG,
} from '../../../style/styled-components/reusable.css';
import { LinkTrackable } from '../../Trackable/LinkTrackable';
import fallbackImage from '../../../assets/images/landscape_fallback.png';
import { StandardCover } from '../../../types/Cover';
import { Picture } from '../../Picture/Picture';
import fallbackPortrait from '../../../assets/images/portrait_fallback.png';
import icons from '../../../style';

type PropTypes = {
    asset: StandardCover;
    showDescription?: boolean;
};

export const CardLandscape: FC<PropTypes> = ({ asset, showDescription, ...rest }) => {
    const [portraitImageError, setPortraitImageError] = useState<boolean>(false);
    const {
        path,
        onPlayIconClicked,
        renderPlayIcon,
        progressBar,
        image,
        title,
        metaData,
        contentMarker,
        channelImage,
        landscape,
        rawData,
        shortDescription,
        isEntitled,
    } = asset;

    const height = asset.defaultRenderSecondLine ? landscapeCardHoveredHeight : landscapeCardHeight;
    const altText = `${rawData?.genres?.[0] ? rawData?.genres?.[0] : ''} ${title}`;

    const [ref, inView] = useInView({
        threshold: 0,
    });

    return (
        <CardWrapper
            className={`wide-tile`}
            height={height}
            width={landscapeCardWidth}
            ref={ref}
            withDescription={showDescription && !!shortDescription}
        >
            {inView && (
                <CardLandscapeContainer hasDescription={!!shortDescription}>
                    <LinkTrackable
                        to={path}
                        onClick={e => {
                            if (asset.onClick) {
                                e.preventDefault();
                                e.stopPropagation();

                                if (renderPlayIcon) {
                                    onPlayIconClicked(e);
                                } else {
                                    asset.onClick(e, asset.rawData);
                                }
                            }
                        }}
                        {...rest}
                    >
                        <CardLandscapeWrapper data-test-id={'tv-tvcards-tvseries'} defaultRenderSecondLine={asset.defaultRenderSecondLine}>
                            <ImageWrapper>
                                {renderPlayIcon && (
                                    <StyledButtonPlay
                                        onClick={e => {
                                            e.stopPropagation();
                                            onPlayIconClicked(e);
                                        }}
                                    />
                                )}
                                <LandscapeImageContainer>
                                    <LandscapeImageWrapper isBackgroundBlur={image && !landscape && !portraitImageError}>
                                        <CardImage url={image} fallbackImage={fallbackImage} alt={altText} />
                                    </LandscapeImageWrapper>
                                </LandscapeImageContainer>
                                {image && !landscape && !portraitImageError && (
                                    <MoviePortraitImageWrapper>
                                        <MoviePortraitImage>
                                            <CardImage
                                                url={image}
                                                fallbackImage={fallbackPortrait}
                                                alt={altText}
                                                {...{
                                                    imageError: () => {
                                                        setPortraitImageError(true);
                                                    },
                                                }}
                                            />
                                        </MoviePortraitImage>
                                    </MoviePortraitImageWrapper>
                                )}
                                {progressBar}
                                {contentMarker != null && (
                                    <ContentMarker withProgressBar={hasProgressBar(progressBar)} marker={contentMarker}>
                                        {contentMarker.value}
                                    </ContentMarker>
                                )}
                            </ImageWrapper>
                            {channelImage && (
                                <ChannelLogoWrapper>
                                    <ChannelImageInnerWrapper>
                                        <Picture src={channelImage} hPosition={'right'} vPosition={'center'} fit={'scale-down'} />
                                    </ChannelImageInnerWrapper>
                                </ChannelLogoWrapper>
                            )}
                        </CardLandscapeWrapper>
                    </LinkTrackable>
                    <InfoWrapper hasDescription={!!shortDescription}>
                        <Title data-test-id={'tv-detailscreen-episodetitle'}>
                            {isEntitled !== undefined && !isEntitled && <StyledSVG svg={icons.lockIcon} />}
                            {title}
                        </Title>
                        <DescriptionWrapper>{shortDescription}</DescriptionWrapper>
                        {metaData != null && <MetaData hasDescription={!!shortDescription}>{metaData}</MetaData>}
                    </InfoWrapper>
                </CardLandscapeContainer>
            )}
        </CardWrapper>
    );
};
