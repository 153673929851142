import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useConfig } from '../useConfig/ConfigContext';
import CastHandler from './CastHandler';
import { CastEvent, CastMediaInformation } from '../../types/Cast';
import { useApp } from '../useApp/AppContext';

const useCastService = () => {
    const [initialised, setInitialised] = useState(false);
    const { config } = useConfig();
    const { appLanguage } = useApp();

    const [castConnected, setCastConnected] = useState<boolean>(false);
    const [deviceName, setDeviceName] = useState<string>('');
    const [mediaInfo, setMediaInfo] = useState<CastMediaInformation>(null);
    const [shouldRecreateSession, setShouldRecreateSession] = useState<boolean>(false);

    const castingRef = useRef<boolean>(false);

    const connectedChangeListener = (newState: boolean) => {
        setCastConnected(newState);
        window.chromeCastStatus = newState;

        if (castingRef.current && !newState) {
            // switched back to normal mode
            setShouldRecreateSession(true);
        }

        castingRef.current = newState;
    };

    const toggleConnect = async () => CastHandler.toggleConnect();

    const loadMedia = (request: chrome.cast.media.LoadRequest) => CastHandler.loadMedia(request);

    useEffect(() => {
        if (config) {
            CastHandler.init((event, data) => {
                switch (event) {
                    case CastEvent.CASTING: {
                        connectedChangeListener(data);
                        break;
                    }
                    case CastEvent.LOADED: {
                        setInitialised(data);
                        break;
                    }
                    case CastEvent.RECEIVER_NAME: {
                        setDeviceName(data);
                        break;
                    }
                    case CastEvent.MEDIA_INFO_CHANGED: {
                        setMediaInfo(data);
                        break;
                    }
                    default: {
                        // do-nothing
                        break;
                    }
                }
            }).catch(e => console.warn('Init cast handler error', e));
            CastHandler.notify();
        }
    }, [config]);

    useEffect(() => {
        if (appLanguage && castConnected) {
            CastHandler.syncUILanguage(appLanguage);
        }
    }, [appLanguage, castConnected]);

    return useMemo(() => {
        return {
            initialised,
            toggleConnect,
            castConnected,
            deviceName,
            loadMedia,
            mediaInfo,
            shouldRecreateSession,
            setShouldRecreateSession,
        };
    }, [initialised, castConnected, deviceName, mediaInfo, shouldRecreateSession, setShouldRecreateSession]);
};

export const CastContext = createContext<ReturnType<typeof useCastService>>(null);

export const CastProvider = ({ children }) => {
    const context = useCastService();
    return <CastContext.Provider value={context}>{children}</CastContext.Provider>;
};

export const useCast = () => useContext(CastContext);
