import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { Breakpoints, MQ } from '../../../style/styled-components/cssMediaQueries';
import { fontBold, fontNormal, maxZIndex } from '../../../style/styled-components/cssGlobalVariables';

export const OverlayPageWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    z-index: ${maxZIndex + 4};
    overflow: auto;
`;

export const PageWrapper = styled.div`
    margin: 0 auto;
    padding: 88px 16px 0 16px;
    max-width: 640px;

    ${MQ(Breakpoints.s)} {
        padding: 88px 0 0 0;
    }

    ${MQ(Breakpoints.m)} {
        padding: 128px 0 0 0;
    }
`;

export const CloseButton = styled.div`
    position: absolute;
    cursor: pointer;
    top: 20px;
    right: 20px;

    .SVGInline {
        width: 20px;
        height: 20px;
    }

    ${MQ(Breakpoints.s)} {
        top: 42px;
        right: 42px;
    }
`;

export const Title = styled.h1`
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 32px;
    line-height: 40px;
    font-family: ${fontBold};
    text-align: center;
    padding-bottom: 24px;
    display: none;

    ${MQ(Breakpoints.m)} {
        display: block;
    }
`;

export const HintText = styled.p`
    color: ${props => props.theme.brandedConfig.high_contrast};
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 26px;

    ${MQ(Breakpoints.m)} {
        text-align: center;
        padding: 0 90px 16px 90px;
    }
`;

export const SelectHint = styled(HintText)`
    font-family: ${fontNormal};
`;

export const DeviceHint = styled(HintText)`
    font-family: ${fontBold};
    padding-bottom: 24px;
`;

export const DeviceList = styled.div`
    padding-top: 16px;

    ${MQ(Breakpoints.s)} {
        padding-top: 24px;
    }
`;

export const DeviceTypeIcon = styled.div`
    flex: 0 0 32px;
`;

export const DeviceLabel = styled.div`
    flex-grow: 1;
`;

export const Name = styled.p`
    font-size: 16px;
    line-height: 24px;
`;

export const Description = styled.p`
    font-size: 14px;
    line-height: 20px;
    opacity: 0.7;
`;

export const TakeOverButton = styled.a`
    flex-grow: 0;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.brandedConfig.primary};
    cursor: pointer;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    ${MQ(Breakpoints.m)} {
        display: flex;
        position: static;
        width: auto;
        height: auto;
    }
`;

export const TakeOverLabel = styled.span`
    display: none;

    ${MQ(Breakpoints.m)} {
        display: inline;
    }
`;

export const Device = styled.div`
    display: flex;
    position: relative;
    column-gap: 16px;
    align-items: center;
    font-family: ${fontNormal};
    color: ${props => props.theme.brandedConfig.high_contrast};
    background-color: ${props => props.theme.brandedConfig.low_contrast};
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 12px 16px;

    ${MQ(Breakpoints.m)} {
        background-color: transparent;
        padding: 0;
        margin-bottom: 16px;
    }
`;
