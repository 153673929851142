interface ProcessEnv {
    NODE_ENV: 'development' | 'production';
    CONFIG_ENDPOINT: string;
    USE_LOCAL_CONFIG: string;

    FIREBASE_APIKEY: string;
    FIREBASE_AUTHDOMAIN: string;
    FIREBASE_DATABASEURL: string;
    FIREBASE_PROJECTID: string;
    FIREBASE_STORAGEBUCKET: string;
    FIREBASE_MESSAGINGSENDERID: string;
    FIREBASE_APPID: string;
    FIREBASE_MEASUREMENTID: string;
}

export const getProcessEnv = <T extends keyof ProcessEnv>(envVar: T, defaultValue?: ProcessEnv[T]): ProcessEnv[T] => {
    // @ts-ignore
    return (process.env[envVar] as any) || defaultValue || null; // eslint-disable-line
};

export const isDevNodeEnv = () => {
    return getProcessEnv('NODE_ENV') === 'development';
};

export const isProdNodeEnv = () => {
    return getProcessEnv('NODE_ENV') === 'production';
};
