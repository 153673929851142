import translate from './fnTranslate';
import { MINUTES } from './TimeUnit';

export const MOTH_MAP = [
    'MONTH_NAME_JANUARY',
    'MONTH_NAME_FEBRUARY',
    'MONTH_NAME_MARCH',
    'MONTH_NAME_APRIL',
    'MONTH_NAME_MAY',
    'MONTH_NAME_JUNE',
    'MONTH_NAME_JULY',
    'MONTH_NAME_AUGUST',
    'MONTH_NAME_SEPTEMBER',
    'MONTH_NAME_OCTOBER',
    'MONTH_NAME_NOVEMBER',
    'MONTH_NAME_DECEMBER',
];

export const DAY_MAP = [
    'DAY_NAME_SUNDAY',
    'DAY_NAME_MONDAY',
    'DAY_NAME_TUESDAY',
    'DAY_NAME_WEDNESDAY',
    'DAY_NAME_THURSDAY',
    'DAY_NAME_FRIDAY',
    'DAY_NAME_SATURDAY',
];

export enum units {
    MIN,
    HOUR,
}

export const buildTimeString = (time: number, showHour = false, showSeconds = true): string => {
    const isNegative = Math.ceil(time) < 0;

    const h = Math.floor(Math.abs(time) / 3600);
    const m = Math.floor((Math.abs(time) / 60) % 60);
    const s = Math.floor(Math.abs(time) % 60);
    let secondString;

    if (Math.abs(s) < 10) {
        secondString = `0${s}`;
    } else {
        secondString = `${s}`;
    }

    let text = `${m}${showSeconds ? `:${secondString}` : ''}`;

    if (showHour) {
        if (Math.abs(m) < 10) {
            text = `0${text}`;
        }
        if (h !== 0) {
            text = `${h}:${text}`;
        }
    }
    return `${isNegative && Math.abs(time) > 0 ? '-' : ''}${text}`;
};

export const secondsToTime = (time: number, completeHourWithZero = false): string => {
    const h = Math.floor(time / 3600);
    const m = Math.floor((time / 60) % 60);

    let timeString = '';

    if (h > 0) {
        if (completeHourWithZero) {
            if (h < 10) {
                timeString = `0${h}${translate('SCREEN_DETAIL_TIME_HOURS')} `;
            } else {
                timeString = `${h}${translate('SCREEN_DETAIL_TIME_HOURS')} `;
            }
        } else {
            if (h < 10) {
                timeString = `0${h}${translate('SCREEN_DETAIL_TIME_HOURS')} `;
            }
            timeString = `${h}${translate('SCREEN_DETAIL_TIME_HOURS')} `;
        }
    }

    if (m > 0) {
        if (m < 10) {
            timeString += `0${m}${translate('SCREEN_DETAIL_TIME_MINUTES')}`;
        } else {
            timeString += `${m}${translate('SCREEN_DETAIL_TIME_MINUTES')}`;
        }
    }

    if (h === 0 && m === 0) {
        if (completeHourWithZero) {
            timeString = `0${h}${translate('SCREEN_DETAIL_TIME_HOURS')} 0${m}${translate('SCREEN_DETAIL_TIME_MINUTES')}`;
        } else {
            timeString = `${h}${translate('SCREEN_DETAIL_TIME_HOURS')} 0${m}${translate('SCREEN_DETAIL_TIME_MINUTES')}`;
        }
    }

    return timeString;
};

export const buildRentalPeriod = (minutes: number): string => {
    const durationInHours = MINUTES.toHours(minutes);

    if (durationInHours < 1) {
        return durationInHours.toFixed(2);
    }

    return `${Math.round(durationInHours)}`;
};

export const isNow = (start, end) => {
    const now = new Date().getTime();
    return now > start && now < end;
};

export const isPast = end => {
    const now = new Date().getTime();
    return now > end;
};

export const isFuture = start => {
    const now = new Date().getTime();
    return start > now;
};

export const isSameDay = (day1: Date, day2: Date): boolean => {
    return day1.getDate() === day2.getDate() && day1.getMonth() === day2.getMonth() && day1.getFullYear() === day2.getFullYear();
};

export const isToday = (date: Date): boolean => {
    const today = new Date();
    return isSameDay(date, today);
};

export const isTomorrow = (date: Date): boolean => {
    const today = new Date();
    const tomorrow = new Date();

    tomorrow.setDate(today.getDate() + 1);
    return isSameDay(date, tomorrow);
};

export const isYesterday = (date): boolean => {
    const today = new Date();
    const yesterday = new Date();

    yesterday.setDate(today.getDate() - 1);

    return isSameDay(date, yesterday);
};

export const formatDate = (date: Date): string => {
    if (isToday(date)) {
        return translate('DAY_NAME_TODAY');
    }
    if (isYesterday(date)) {
        return translate('DAY_NAME_YESTERDAY');
    }
    if (isTomorrow(date)) {
        return translate('DAY_NAME_TOMORROW');
    }

    const day = date.getDate();

    let formatted = translate(DAY_MAP[date.getDay()]);
    formatted += ', ';
    formatted += day < 10 ? `0${day}` : day;
    formatted += ' ';
    formatted += translate(MOTH_MAP[date.getMonth()]);

    return formatted;
};
