import React, { useEffect, useRef, useState } from 'react';
import { DropdownItemSimple, DropdownMenu } from 'components/DropdownMenu/DropdownMenu';
import { useSearch } from 'providers/useSearch/SearchContext';
import SVGInline from 'react-svg-inline';
import { isMQDesktop } from 'style/styled-components/cssMediaQueries';
import { KeyboardActions } from 'types/Common';
import {
    animationDuration,
    ButtonClose,
    DropdownHistoryText,
    DropdownTitle,
    IconClear,
    IconSearch,
    SearchHistoryDropdownWrapper,
    SearchInput,
    SearchInputContainer,
    SearchWrapper,
} from './HeaderSearch.css';
import icons from '../../../style';
import translate from '../../../utils/fnTranslate';
import { useConfig } from '../../../providers/useConfig/ConfigContext';
import { useElementInteractionTracking } from '../../../providers/useTracking/TrackingContext';
import { useApp } from '../../../providers/useApp/AppContext';
import { SearchHistoryList } from './SearchHistoryList';

const CLOSE_TIMEOUT = parseInt(animationDuration, 10);
const MAX_HEIGHT = 404;

export const HeaderSearch: React.FC<{
    placeholder: string;
    isOpen: boolean;
    value: string;
    onSearch: (query: string) => void;
    onSubmit: (query: string) => void;
    onClose: () => void;
    onOpen: () => void;
}> = ({ placeholder, value, isOpen, onSearch, onSubmit, onClose, onOpen }) => {
    const [closing, setClosing] = useState<boolean>(false);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [isDropdownFocused, setIsDropdownFocused] = useState<boolean>(false);

    const searchInput = useRef<HTMLInputElement>(null);
    const onSearchTimeoutRef = useRef<number>(null);
    const closeTimeoutRef = useRef<number>(null);

    const interactionTracking = useElementInteractionTracking();
    const { trigger_timeout } = useConfig().config?.app_config?.search_settings ?? {};
    const { isDesktop } = useApp();
    const { searchHistory, deleteSearchHistoryItem, handleSearchHistory } = useSearch();

    const closeSearch = () => {
        setClosing(true);
        searchInput.current.blur();

        interactionTracking({
            'data-track-id': 'close_search',
        });
    };

    const onChange = () => {
        if (onSearchTimeoutRef.current) {
            clearTimeout(onSearchTimeoutRef.current);
        }

        onSearchTimeoutRef.current = window.setTimeout(() => {
            if (onSearch) {
                onSearch(searchInput?.current?.value?.trim());
            }
        }, trigger_timeout);
    };

    const clearSearch = () => {
        searchInput.current.value = '';
        searchInput.current.focus();
        setShowDropdown(false);

        if (isOpen) {
            onSearch('');
        }

        if (isDesktop()) {
            closeSearch();
        }
    };

    const openSearch = () => {
        onOpen();

        setTimeout(() => {
            searchInput.current.focus();
        }, 250);

        setShowDropdown(true);

        interactionTracking({
            'data-track-id': 'open_search',
        });
    };

    const onKeyUp = e => {
        if (e?.key === KeyboardActions.ENTER && searchInput?.current?.value?.trim()?.length > 0) {
            onSubmit(searchInput.current.value);
            searchInput.current.blur();
            handleSearchHistory(searchInput.current.value.trim());
            setShowDropdown(false);
        } else {
            setShowDropdown(true);
        }
    };

    const handleDelete = (event: React.MouseEvent<any>, item: string) => {
        event.preventDefault();
        event.stopPropagation();

        deleteSearchHistoryItem(item);
    };

    const handleSearchHistoryClicked = (item: string) => {
        onSearch(item);
        handleSearchHistory(item);
        setShowDropdown(false);
        searchInput.current.value = item;
    };

    const handleBlur = () => {
        if (isMQDesktop()) {
            if (!isDropdownFocused) {
                setShowDropdown(false);
                handleSearchHistory(searchInput.current.value.trim());
            }
        } else {
            handleSearchHistory(searchInput.current.value.trim());
        }
    };

    const isDropdownVisible = () => showDropdown && searchHistory.length > 0 && isOpen;

    useEffect(() => {
        if (closing) {
            if (closeTimeoutRef.current) {
                clearTimeout(closeTimeoutRef.current);
            }
            closeTimeoutRef.current = window.setTimeout(
                () => {
                    onClose();
                    setTimeout(() => setClosing(false), 100);
                },
                isDesktop() ? CLOSE_TIMEOUT : 0
            );
        }
    }, [closing]);

    useEffect(() => {
        if (!value) {
            searchInput.current.value = '';
        }
    }, [value]);

    return (
        <SearchWrapper isOpen={isOpen} closing={closing}>
            <ButtonClose onClick={closeSearch}>{translate('CANCEL_BUTTON')}</ButtonClose>
            <SearchInputContainer>
                <SearchInput
                    data-test-id={'header-search-inputsearch'}
                    defaultValue={value}
                    type={'text'}
                    placeholder={placeholder}
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    inputRef={searchInput}
                    onFocus={() => setShowDropdown(true)}
                    onBlur={handleBlur}
                />
                {isDropdownVisible() && isMQDesktop() && (
                    <SearchHistoryDropdownWrapper>
                        <DropdownMenu
                            triggerButton={null}
                            verticalPosition={'bottom'}
                            horizontalPosition={'right'}
                            showMenuContent={true}
                            keepOpen={true}
                            onMouseEnterCb={() => {
                                setIsDropdownFocused(true);
                                searchInput.current.blur();
                            }}
                            onMouseLeaveCb={() => {
                                setIsDropdownFocused(false);
                                searchInput.current.focus();
                            }}
                            maxHeight={MAX_HEIGHT}
                        >
                            <DropdownTitle
                                selected={false}
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            >
                                {translate('SCREEN_SEARCH_RECENTLY_SEARCHED')}
                            </DropdownTitle>
                            {searchHistory.map((item, index) => (
                                <DropdownItemSimple
                                    key={index}
                                    selected={false}
                                    onClick={() => {
                                        handleSearchHistoryClicked(item);
                                    }}
                                >
                                    <SVGInline className="search-history" svg={icons.icoSearchHistory} />
                                    <DropdownHistoryText>{item}</DropdownHistoryText>
                                    <IconClear
                                        className="delete-history"
                                        svg={icons.closeIcon}
                                        onClick={(e: React.MouseEvent<any>) => handleDelete(e, item)}
                                    />
                                </DropdownItemSimple>
                            ))}
                        </DropdownMenu>
                    </SearchHistoryDropdownWrapper>
                )}
                <IconSearch data-test-id={'header-search-iconsearch'} svg={icons.searchIcon} onClick={openSearch} />
                <IconClear data-test-id={'header-search-iconclear'} svg={icons.closeIcon} onClick={clearSearch} />
            </SearchInputContainer>
            {isDropdownVisible() && !isMQDesktop() && (
                <SearchHistoryList
                    searchHistory={searchHistory}
                    handleSearchHistoryClicked={handleSearchHistoryClicked}
                    handleDelete={handleDelete}
                />
            )}
        </SearchWrapper>
    );
};
