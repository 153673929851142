import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useDataFetcher } from '../../hooks/useDataFetcher/useDataFetcher';
import Api from '../../api/Api';
import { usePersonalized } from '../../hooks/usePersonalizedVod/usePersonalized';
import { Fetcher, FetchParamById } from '../../types/ApiTypes';
import { decodeId } from '../../utils/fnUrl';
import { useGlobalNetworkError, useGlobalNoInternet } from '../../hooks/withNetworkCheck/withNetworkCheck';
import { usePersistentState } from '../../hooks/useStorage/useStorage';

type PostBookmarkParams = {
    titleId: string;
    position: number;
};

const useBookmarkService = () => {
    const { onNoInternet } = useGlobalNoInternet();
    const { onNetworkError } = useGlobalNetworkError();
    const { response: saveBookmarkResponse, loading: saveBookmarkLoading, fetcher: postBookmark } = useDataFetcher<
        string,
        PostBookmarkParams
    >(params => Api.saveBookmark(params.titleId, params.position), onNoInternet, onNetworkError);
    const [bookmarkAssetReloaded, setBookmarkAssetReloaded] = useState(false);
    const { set: setOfflineBookmark, store: offlineBookmark } = usePersistentState('offlineBookmark');

    const { updatePersonalizedInfo, personalizedInfoUpdated } = usePersonalized();

    const saveBookmark = (titleId: string, position: number) => postBookmark({ titleId, position });

    const onlineStateChanged = useCallback(() => {
        if (offlineBookmark) {
            saveBookmark(offlineBookmark.titleId, offlineBookmark.progress);
            setOfflineBookmark(null);
        }
    }, [offlineBookmark]);

    useEffect(() => {
        if (saveBookmarkResponse) {
            updatePersonalizedInfo(saveBookmarkResponse);
        }
    }, [saveBookmarkResponse]);

    useEffect(() => {
        if (personalizedInfoUpdated) {
            setBookmarkAssetReloaded(true);
        }
    }, [personalizedInfoUpdated]);

    useEffect(() => {
        if (bookmarkAssetReloaded) {
            setBookmarkAssetReloaded(false);
        }
    }, [bookmarkAssetReloaded]);

    useEffect(() => {
        window.addEventListener('online', onlineStateChanged);
    }, []);

    return {
        saveBookmark,
        saveBookmarkResponse,
        saveBookmarkLoading,

        bookmarkAssetReloaded,
        setBookmarkAssetReloaded,

        setOfflineBookmark,
        offlineBookmark,
    };
};

export const BookmarkContext = createContext<ReturnType<typeof useBookmarkService>>(null);

export const BookmarkProvider = ({ children }) => {
    const context = useBookmarkService();

    return <BookmarkContext.Provider value={context}>{children}</BookmarkContext.Provider>;
};

export const useBookmark = () => useContext(BookmarkContext);

export const useDetailsReloadOnBookmarkUpdate = (fetcher: Fetcher<FetchParamById>, id: string) => {
    const { bookmarkAssetReloaded } = useBookmark();

    useEffect(() => {
        if (bookmarkAssetReloaded) {
            const assetId = decodeId(id);
            fetcher({ id: assetId }, true);
        }
    }, [bookmarkAssetReloaded]);
};
