import React, { FC } from 'react';
import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { CardPortraitLoading } from '../../Cards/CardPortrait/CardPortraitLoading';
import { CardLandscapeLoading } from '../../Cards/CardLandscape/CardLandscapeLoading';
import { LoaderWrapper, LoadingRectangle } from '../../../style/styled-components/reusable.css';
import { maxZIndex } from '../../../style/styled-components/cssGlobalVariables';
import { CardCircleLoading } from '../../Cards/CardCircle/CardCircleLoading';
import { Breakpoints, MQ } from '../../../style/styled-components/cssMediaQueries';
import { ChannelBundleLoading } from '../../Cards/CardChannel/CardChannelLoading';
import { StripeTypes } from '../../../types/Common';

export const TitlePlaceholder = styled.div`
    width: 250px;
    height: 28px;
    padding-left: 7px;
`;

export const TilesPlaceholder = styled.div`
    white-space: nowrap;
    margin-top: 8px;
`;

const InnerWrapper = styled.div`
    padding-top: 40px;
    padding-left: 16px;
    position: relative;
    z-index: ${maxZIndex};

    ${MQ(Breakpoints.s)} {
        padding-left: 32px;
    }

    ${MQ(Breakpoints.m)} {
        padding-left: 40px;
    }
`;

const loadingCardCount = 20;

export const StripeMixedLoadingUI: FC<{ stripeType: StripeTypes; withTitle: boolean }> = ({ stripeType, withTitle }) => {
    const loadingCards = [];

    for (let i = 0; i < loadingCardCount; i += 1) {
        if (stripeType === StripeTypes.MOVIE || stripeType === StripeTypes.PORTRAIT) {
            loadingCards.push(<CardPortraitLoading key={`loading-${i}`} stripeType={stripeType} />);
        }
        if (stripeType === StripeTypes.EPISODE || stripeType === StripeTypes.NOW_NEXT) {
            loadingCards.push(<CardLandscapeLoading key={`loading-${i}`} />);
        }
        if (stripeType === StripeTypes.PERSON || stripeType === StripeTypes.ROUNDED) {
            loadingCards.push(<CardCircleLoading key={`loading-${i}`} />);
        }
        if (stripeType === StripeTypes.TV_CHANNEL) {
            loadingCards.push(<ChannelBundleLoading key={`loading-${i}`} />);
            i += 1; // the bundle contains 2 channels
        }
        if (stripeType === StripeTypes.MIXED) {
            loadingCards.push(<CardPortraitLoading key={`loading-${i}`} stripeType={stripeType} />);
        }
    }

    return (
        <LoaderWrapper>
            <InnerWrapper>
                {withTitle && (
                    <TitlePlaceholder>
                        <LoadingRectangle />
                    </TitlePlaceholder>
                )}
                <TilesPlaceholder>{loadingCards}</TilesPlaceholder>
            </InnerWrapper>
        </LoaderWrapper>
    );
};
