import React, { useEffect, useRef } from 'react';
import { usePageScroller } from '../../components/PageScroller/usePageScroller';
import { usePlayer } from '../../providers/player/PlayerContext';

export const PLAYER_OPENED_CLASS = 'player-opened';

const isPlayerOpened = (state: boolean) => {
    if (state) {
        document.getElementsByTagName('html')[0].classList.add(PLAYER_OPENED_CLASS);
        document.body.classList.add(PLAYER_OPENED_CLASS);
    } else {
        document.getElementsByTagName('html')[0].classList.remove(PLAYER_OPENED_CLASS);
        document.body.classList.remove(PLAYER_OPENED_CLASS);
    }
};

export const useNoPageScroll = () => {
    const { miniPlayerProps } = usePlayer();

    useEffect(() => {
        isPlayerOpened(!miniPlayerProps);
        return () => isPlayerOpened(false);
    }, [miniPlayerProps]);
};

export const withPlayer = (Wrapped: React.FC | React.ComponentClass) =>
    function WrappedWithOverlay(props) {
        const prevScrollPosition = useRef<number>(null);
        const { scrollTop, scrollTo } = usePageScroller();
        const { isMini } = usePlayer();

        useNoPageScroll();

        useEffect(() => {
            prevScrollPosition.current = scrollTop;
            scrollTo(0);

            return () => {
                if (!isMini) {
                    scrollTo(prevScrollPosition.current);
                }
            };
        }, [isMini]);

        return <Wrapped {...props} />;
    };
