import SVGInline from 'react-svg-inline';
import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';

export const SearchItemWrapper = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 16px;
    width: 100%;
    height: 24px;
    align-items: center;
`;

export const PageWrapper = styled.div`
    padding: 50px 16px 0px 16px;
    width: 100%;
    height: 100vh;
    background-color: ${props => props.theme.brandedConfig.black_color};
`;

export const DeleteIcon = styled(SVGInline)`
    margin-left: auto;

    svg {
        width: 12px;
        height: 12px;
    }
`;

export const SearchHistoryText = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

export const SearchHistoryTitle = styled.div`
    font-weight: bold;
`;
