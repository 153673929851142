import React, { FC, useEffect, useRef } from 'react';
import { usePlayer } from '../../providers/player/PlayerContext';
import {
    LoadingIndicatorWrapper,
    MiniPlayerButton,
    MiniPlayerCloseButton,
    MiniPlayerFullscreenButton,
    MiniPlayerUIWrapper,
    MiniSeekbarHolder,
    StyledSVGInline,
} from './PlayerUI.css';
import icons from '../../style';
import spinner from '../../assets/images/spinner.png';
import { SeekBar } from '../SeekBar/SeekBar';
import { PlayingMode } from '../../types/Player';
import { useElementInteractionTracking } from '../../providers/useTracking/TrackingContext';

const PROGRESS_UPDATE_INTERVAL = 350;

export const MiniPlayerUI: FC<{ loading: boolean; setToNormalScreen: () => void }> = ({ loading, setToNormalScreen }) => {
    const {
        closeMiniPlayer,
        isBuffering,
        isPlaying,
        setPlayingState,
        asset,
        streamEndReached,
        getDuration,
        loaded,
        getProgressWithJump,
        getSecondaryProgressWithJump,
        canUpdateProgress,
        playingMode,
    } = usePlayer();

    const progressLine = useRef(null);
    const bufferLine = useRef(null);
    const isLoaded = useRef(false);
    const progressUpdateIntervalId = useRef(null);

    const miniPlayerUIContainerRef = useRef<HTMLDivElement>();

    const interactionTracking = useElementInteractionTracking();

    const onPlayPauseClicked = () => {
        interactionTracking({
            'data-track-id': 'player_play_pause',
        });

        setPlayingState(!isPlaying);
    };

    const onSetToNormalScreenClicked = () => {
        interactionTracking({
            'data-track-id': 'player_toggle_full_screen',
        });

        setToNormalScreen();
    };

    const onCloseMiniPlayerClicked = e => {
        e.stopPropagation();
        closeMiniPlayer();
    };

    const onSeekBarCreated = ref => {
        progressLine.current = ref.progressLine;
        bufferLine.current = ref.bufferLine;
    };

    const updateTimeAndSeekRange = () => {
        if (!isLoaded.current) {
            return;
        }

        const duration = getDuration();

        if (duration === 0) {
            return;
        }

        if (canUpdateProgress()) {
            const elapsedTime = getProgressWithJump();

            if (playingMode === PlayingMode.TIME_SHIFT && elapsedTime === 0) {
                return;
            }

            const playHeadPosition = elapsedTime / duration || 0;
            if (progressLine.current && progressLine.current.style) {
                progressLine.current.style.width = `${playHeadPosition * 100}%`;
            }
        }

        const secondaryProgress = getSecondaryProgressWithJump() / duration || 0;
        if (bufferLine.current && bufferLine.current.style) {
            bufferLine.current.style.width = `${secondaryProgress * 100}%`;
        }
    };

    useEffect(() => {
        isLoaded.current = loaded;
        if (progressUpdateIntervalId.current) {
            clearInterval(progressUpdateIntervalId.current);
        }
        progressUpdateIntervalId.current = setInterval(updateTimeAndSeekRange, PROGRESS_UPDATE_INTERVAL);
    }, [loaded, playingMode]);

    useEffect(() => {
        if (streamEndReached) {
            closeMiniPlayer();
        }
    }, [streamEndReached, asset]);

    useEffect(() => {
        miniPlayerUIContainerRef.current?.addEventListener('dblclick', onSetToNormalScreenClicked);

        return () => {
            clearInterval(progressUpdateIntervalId.current);
            miniPlayerUIContainerRef.current?.removeEventListener('dblclick', onSetToNormalScreenClicked);
        };
    }, []);

    return (
        <MiniPlayerUIWrapper data-test-id={'player-miniplayer-miniplayerwindow'} ref={miniPlayerUIContainerRef}>
            <MiniPlayerCloseButton
                data-test-id={'player-miniplayer-closebutton'}
                onClick={onCloseMiniPlayerClicked}
                className={'control-button'}
            >
                <StyledSVGInline svg={icons.closeIcon} />
            </MiniPlayerCloseButton>
            <MiniPlayerButton data-test-id={'player-miniplayer-playpausebutton'} onClick={onPlayPauseClicked} className={'control-button'}>
                <StyledSVGInline svg={isPlaying ? icons.pauseIconSimple : icons.playIconSimple} />
            </MiniPlayerButton>

            <MiniPlayerFullscreenButton data-test-id={'player-miniplayer-maximizebutton'} className={'control-button'}>
                <StyledSVGInline svg={icons.fullScreenIcon} onClick={onSetToNormalScreenClicked} />
            </MiniPlayerFullscreenButton>

            <MiniSeekbarHolder>
                <SeekBar enabled={false} onSeekBarCreated={onSeekBarCreated} />
            </MiniSeekbarHolder>

            {(loading || isBuffering) && (
                <LoadingIndicatorWrapper>
                    <img src={spinner} alt={'spinner'} />
                </LoadingIndicatorWrapper>
            )}
        </MiniPlayerUIWrapper>
    );
};
