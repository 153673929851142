import { IPlayer } from '../player/abstract/IPlayer';
import CastHandler from './CastHandler';
import localConfig from '../../config/localConfig';
import { BitrateTrack, PlayingAsset, PlayingMode, SUBTITLE_NONE, Track } from '../../types/Player';
import PlaybackManager from '../player/PlaybackManager';

export default class CastPlayer extends IPlayer {
    private availableSubtitles: Track[] = [];

    private availableAudios: Track[] = [];

    getAudioLanguages = (): any => {
        const tracks = CastHandler.getMediaInfo()?.audioLanguages ?? [];

        this.availableAudios = tracks.map(track => {
            return {
                role: track?.kind ?? track?.roles?.[0],
                label: track.label,
                language: track.language,
                channelsCount: track.channelsCount,
                raw: track,
            };
        });

        return this.availableAudios;
    };

    getThumbnailTrack = () => {
        return null;
    };

    getThumbnails = () => {
        return null;
    };

    getBitrateTracks = (): any => {
        // TODO: implement this
        return null;
    };

    getBuffered = (): any => {
        return null;
    };

    getCurrentTime = (): number => CastHandler.getMediaInfo()?.progress;

    getDuration = (): number => CastHandler.getMediaInfo()?.duration;

    getTextLanguages = (): any => {
        const tracks = CastHandler.getMediaInfo()?.subtitleLanguages ?? [];

        this.availableSubtitles = tracks.length ? [{ role: null, label: null, raw: null, language: SUBTITLE_NONE }] : [];

        this.availableSubtitles = [
            ...this.availableSubtitles,
            ...tracks.map(track => {
                return {
                    role: track?.kind ?? track?.roles?.[0],
                    label: track.label,
                    language: track.language,
                    raw: track,
                };
            }),
        ];

        return this.availableSubtitles;
    };

    getSelectedLanguage = () => CastHandler.getMediaInfo()?.selectedAudio;

    getSelectedSubtitle = () =>
        CastHandler.getMediaInfo()?.selectedSubtitle ?? { kind: null, label: null, raw: null, language: SUBTITLE_NONE };

    getVolume = (): number => CastHandler.getMediaInfo()?.volume;

    isMuted = (): boolean => CastHandler.getMediaInfo()?.muted;

    isPlaying = (): boolean => CastHandler.getMediaInfo()?.playing;

    mute = (state: boolean): void => {
        CastHandler.sendMessage({
            type: 'set',
            targetName: 'video',
            property: 'muted',
            value: state,
        }).catch(() => {});
    };

    pause = (): void => {
        CastHandler.sendMediaMessage({
            type: 'PAUSE',
        }).catch(() => {});
    };

    play = (): void => {
        CastHandler.sendMediaMessage({
            type: 'PLAY',
        }).catch(() => {});
    };

    removeEventListener = (event: string, callback: (object?: {}) => void): void => {
        CastHandler.removeEventListener(event, callback);
    };

    addEventListener = (event: string, callback: (object?: {}) => void): void => {
        CastHandler.addEventListener(event, callback);
    };

    resetPlayer = (): void => {};

    seekTo = (seek: number): void => {
        CastHandler.sendMediaMessage({
            type: 'SEEK',
            currentTime: seek,
        }).catch(() => {});
    };

    setAudioLanguage = (track: Track): void => {
        CastHandler.resetTrackChangeDebounce();
        CastHandler.sendMessage({
            type: 'selectAudio',
            value: track,
        }).catch(() => {});
    };

    setTextLanguage = (track: Track): void => {
        CastHandler.resetTrackChangeDebounce();
        CastHandler.sendMessage({
            type: 'selectSubtitle',
            value: track?.language !== SUBTITLE_NONE ? track : null,
        }).catch(() => {});
    };

    setBitrateTrack = (track: BitrateTrack): void => {};

    setAsset = (assetDetails: PlayingAsset): void => {
        PlaybackManager.setPlayingMode(PlayingMode.NORMAL);
        PlaybackManager.cast(assetDetails);
    };

    setVolume = (level: number): void => {
        CastHandler.sendMessage({
            type: 'set',
            targetName: 'video',
            property: 'volume',
            value: level,
        }).catch(() => {});
    };

    getDRMType = () => localConfig.drm.WIDEVINE;

    configureEngine = () => {
        // console.log('Set configuration is not available in cast');
    };

    getYoubora = () => {
        return null;
    };
}
