import React, { FC, useState } from 'react';
import { hasProgressBar } from 'utils/fnData';
import {
    CardCollectionTileContainer,
    CardCollectionWrapperInner,
    CollectionCardWrapper,
    ImageWrapper,
    InfoWrapper,
    MetaData,
    PlayIcon,
    PlayIconSVG,
    Title,
} from './CardCollection.css';
import {
    CardImage,
    ContentMarker,
    LandscapeImageContainer,
    MoviePortraitImage,
    MoviePortraitImageWrapper,
    ChannelImageInnerWrapper,
    ChannelLogoWrapper,
} from '../../../style/styled-components/reusable.css';
import { LinkTrackable } from '../../Trackable/LinkTrackable';
import { Picture } from '../../Picture/Picture';

import fallbackLandscape from '../../../assets/images/landscape_fallback.png';
import fallbackPortrait from '../../../assets/images/portrait_fallback.png';
import icons from '../../../style';
import { StandardCover } from '../../../types/Cover';

type PropTypes = {
    asset: StandardCover;
};

export const CardCollectionMixed: FC<PropTypes> = ({ asset, ...rest }) => {
    const [portraitImageError, setPortraitImageError] = useState<boolean>(false);

    const {
        channelImage,
        image,
        path,
        landscape,
        metaData,
        title,
        contentMarker,
        progressBar,
        renderPlayIcon,
        onPlayIconClicked,
        rawData,
    } = asset;

    const altText = `${rawData?.genres?.[0] ? rawData?.genres?.[0] : ''} ${title}`;

    return (
        <CollectionCardWrapper data-test-id={'tv-collectionitem'} defaultRenderSecondLine={asset.defaultRenderSecondLine}>
            <CardCollectionWrapperInner>
                <CardCollectionTileContainer>
                    <LinkTrackable
                        to={path}
                        onClick={e => {
                            if (asset.onClick) {
                                e.preventDefault();
                                e.stopPropagation();

                                asset.onClick(e, asset.rawData);
                            }
                        }}
                        {...rest}
                    >
                        <ImageWrapper>
                            {renderPlayIcon && (
                                <PlayIcon data-test-id={'collection-play-button'} onClick={onPlayIconClicked}>
                                    <PlayIconSVG svg={icons.playIcon} />
                                </PlayIcon>
                            )}
                            <LandscapeImageContainer>
                                <CardImage url={image} fallbackImage={fallbackLandscape} alt={altText} />
                            </LandscapeImageContainer>
                            {image && !landscape && !portraitImageError && (
                                <MoviePortraitImageWrapper>
                                    <MoviePortraitImage>
                                        <CardImage
                                            url={image}
                                            fallbackImage={fallbackPortrait}
                                            alt={altText}
                                            {...{
                                                imageError: () => {
                                                    setPortraitImageError(true);
                                                },
                                            }}
                                        />
                                    </MoviePortraitImage>
                                </MoviePortraitImageWrapper>
                            )}
                            {progressBar}
                            {contentMarker != null && (
                                <ContentMarker withProgressBar={hasProgressBar(progressBar)} marker={contentMarker}>
                                    {contentMarker.value}
                                </ContentMarker>
                            )}
                        </ImageWrapper>
                        {channelImage && (
                            <ChannelLogoWrapper>
                                <ChannelImageInnerWrapper>
                                    <Picture src={channelImage} hPosition={'right'} vPosition={'center'} fit={'scale-down'} />
                                </ChannelImageInnerWrapper>
                            </ChannelLogoWrapper>
                        )}
                        <InfoWrapper>
                            <Title>{title}</Title>
                            <MetaData>{metaData}</MetaData>
                        </InfoWrapper>
                    </LinkTrackable>
                </CardCollectionTileContainer>
            </CardCollectionWrapperInner>
        </CollectionCardWrapper>
    );
};
