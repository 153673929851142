import cloneDeep from 'lodash/cloneDeep';
import Expirable from '../../utils/Expirable';
import { Bookmark, CoverAsset } from '../../types/Asset';
import localConfig from '../../config/localConfig';
import { isBroadcast, isEpisode, isMovie, isRecording, isRentable } from '../../utils/fnTypeGuards';
import { MINUTES } from '../../utils/TimeUnit';
import { Rentable } from '../../types/CommonTypes';

export type StripeData = {
    assets?: CoverAsset[];
    totalItems?: number;
    error?: boolean;
    loading?: boolean;
    expiresInMs?: number;
};

type StripeStore = { [key: string]: Expirable<StripeData> };

const initialState: StripeStore = {};
const defaultExpiration = MINUTES.toMillis(10);

export type StripeActions =
    | 'STORE'
    | 'LOADING'
    | 'ERROR'
    | 'INTERRUPTED'
    | 'UPDATE_PERSONALIZED_INFO'
    | 'CLEAR'
    | 'EMPTY_DYNAMIC'
    | 'EMPTY_RECORDING'
    | 'EMPTY_EXPIRED'
    | 'PURGE_CACHES';

export type ActionPayload = {
    key: string;
    data?: CoverAsset[];
    totalItems?: number;
    expirationInMs?: number;
};

export function isActionPayload(payload): payload is ActionPayload {
    return (payload as ActionPayload).key != null;
}

export type StripeAction = {
    type: StripeActions;
    payload: ActionPayload | (Rentable & Bookmark);
    expirationInMs?: number;
};

export const stripeAction = (type: StripeActions, payload: ActionPayload | (Rentable & Bookmark)): StripeAction => {
    return {
        type,
        payload,
    };
};

const storeActionHandler = (action: StripeAction, state: StripeStore): StripeStore => {
    if (!isActionPayload(action.payload)) {
        return state;
    }

    return {
        ...state,
        ...{
            [action.payload.key]: new Expirable<StripeData>(
                {
                    assets: action.payload.data,
                    totalItems: action.payload.totalItems,
                    error: null,
                    loading: false,
                    expiresInMs: action.payload.expirationInMs,
                },
                action.payload.expirationInMs && action.payload.expirationInMs !== Infinity
                    ? action.payload.expirationInMs
                    : Date.now() + defaultExpiration
            ),
        },
    };
};

const loadingActionHandler = (action: StripeAction, state: StripeStore): StripeStore => {
    if (!isActionPayload(action.payload)) {
        return state;
    }

    return {
        ...state,
        ...{
            [action.payload.key]: new Expirable<StripeData>(
                {
                    ...(state[action.payload.key]?.getContent() ?? {}),
                    ...{ loading: true },
                },
                action.payload.expirationInMs && action.payload.expirationInMs !== Infinity
                    ? action.payload.expirationInMs
                    : Date.now() + defaultExpiration
            ),
        },
    };
};

const errorActionHandler = (action: StripeAction, state: StripeStore): StripeStore => {
    if (!isActionPayload(action.payload)) {
        return state;
    }

    return {
        ...state,
        ...{
            [action.payload.key]: new Expirable<StripeData>(
                {
                    assets: null,
                    error: true,
                    totalItems: 0,
                    loading: false,
                },
                action.payload.expirationInMs && action.payload.expirationInMs !== Infinity
                    ? action.payload.expirationInMs
                    : Date.now() + defaultExpiration
            ),
        },
    };
};

const interruptedActionHandler = (action: StripeAction, state: StripeStore): StripeStore => {
    if (!isActionPayload(action.payload)) {
        return state;
    }

    return {
        ...state,
        ...{
            [action.payload.key]: null,
        },
    };
};

const updatePersonalizedActionHandler = (action: StripeAction, state: StripeStore): StripeStore => {
    if (isRentable(action.payload)) {
        const tempState: StripeStore = cloneDeep(state);

        Object.keys(tempState).forEach(key => {
            const stripeData = tempState[key];
            const stripeContent = stripeData?.getContent();

            if (stripeContent?.assets) {
                const assetIndex = stripeContent.assets.findIndex(asset => {
                    let { id } = asset;

                    if (isBroadcast(asset) || isRecording(asset) || isEpisode(asset) || isMovie(asset)) {
                        id = asset.titleId;
                    }

                    return id === (action.payload as Rentable).id;
                });

                if (assetIndex !== -1) {
                    stripeContent.assets[assetIndex] = {
                        ...stripeContent.assets[assetIndex],
                        ...{
                            vodAssetState: (action.payload as Rentable).vodAssetState,
                            entitlementEnd: (action.payload as Rentable).entitlementEnd,
                            rentOffers: (action.payload as Rentable).rentOffers,
                            subscribeOffer: (action.payload as Rentable).subscribeOffer,
                            bookmark: (action.payload as Bookmark).bookmark,
                        },
                    };
                }

                stripeData.updateContent(stripeContent);
            }
        });

        return {
            ...state,
            ...tempState,
        };
    }

    return state;
};

const emptyDynamicCachesHandler = (state: StripeStore): StripeStore => {
    const tempState: StripeStore = cloneDeep(state);

    Object.keys(tempState).forEach(key => {
        if (key.startsWith(localConfig.data.dynamicKeyPrefix)) {
            delete tempState[key];
        }
    });

    return tempState;
};

const emptyRecordingCachesHandler = (state: StripeStore): StripeStore => {
    const tempState: StripeStore = cloneDeep(state);

    Object.keys(tempState).forEach(key => {
        if (key.startsWith(localConfig.data.recordingKeyPrefix)) {
            delete tempState[key];
        }
    });

    return tempState;
};

const emptyExpiredCachesHandler = (state: StripeStore): StripeStore => {
    const tempState: StripeStore = cloneDeep(state);

    Object.keys(tempState).forEach(key => {
        if (tempState[key] && !tempState[key].isValid()) {
            delete tempState[key];
        }
    });

    return tempState;
};

const purgeCaches = (): StripeStore => {
    return {};
};

export default (state: StripeStore = initialState, action: StripeAction): StripeStore => {
    switch (action.type) {
        case 'STORE':
            return storeActionHandler(action, state);
        case 'LOADING':
            return loadingActionHandler(action, state);
        case 'ERROR':
            return errorActionHandler(action, state);
        case 'INTERRUPTED':
        case 'CLEAR':
            return interruptedActionHandler(action, state);
        case 'UPDATE_PERSONALIZED_INFO':
            return updatePersonalizedActionHandler(action, state);
        case 'EMPTY_DYNAMIC':
            return emptyDynamicCachesHandler(state);
        case 'EMPTY_RECORDING':
            return emptyRecordingCachesHandler(state);
        case 'EMPTY_EXPIRED':
            return emptyExpiredCachesHandler(state);
        case 'PURGE_CACHES':
            return purgeCaches();
        default:
            return state;
    }
};
