import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { TrackingElementPropTypes } from '../../types/Tracking';
import { useTracking } from '../../providers/useTracking/TrackingContext';
import { trackingClickHandler } from '../../utils/fnTracking';

export type TrackableLinkProps = LinkProps & TrackingElementPropTypes & { to: string };

export const LinkTrackable: React.FC<TrackableLinkProps> = props => {
    const track = useTracking();

    const handleLinkClick = event => {
        trackingClickHandler(
            event,
            () => {
                if (props.to && props.to.indexOf('http') > -1) {
                    event.preventDefault();
                    event.stopPropagation();

                    if (props.target) {
                        const win = window.open(props.to, props.target || '');
                        win.focus();
                    } else {
                        window.location.href = props.to;
                    }
                }
            },
            props,
            track
        );
    };

    const linkProps = { to: '', ...props };

    if (linkProps.to === null) {
        linkProps.to = '';
    }

    return (
        <Link
            {...linkProps}
            onClick={event => {
                handleLinkClick(event);

                if (linkProps.to == null || linkProps.to === '') {
                    event.preventDefault();
                }

                if (linkProps.onClick) {
                    linkProps.onClick(event);
                }

                return false;
            }}
        >
            {linkProps.children}
        </Link>
    );
};
