import SVGInline from 'react-svg-inline';
import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold } from '../../../style/styled-components/cssGlobalVariables';
import { Breakpoints, MQ, MQ_NOT_TOUCH } from '../../../style/styled-components/cssMediaQueries';
import { hexToRgba } from '../../../utils/fnColors';

export const animationType = 'ease-in-out';
export const animationDuration = '150ms';
export const animationDelay = '150ms';

export const ImageWrapper = styled.div`
    padding-top: 150%;
    width: 100%;
    position: relative;
    overflow: hidden;
    transition: all ${animationDuration} ${animationType} ${animationDelay};
    border-radius: 5px;
    opacity: 0.9;

    img {
        width: 100%;
        position: absolute;
        top: 0;
        height: 100%;
        transition: all ${animationDuration} ${animationType} ${animationDelay};
    }
`;

export const PlayIconSVG = styled(SVGInline)`
    width: 100%;
    height: 100%;
    line-height: 73px;
    text-align: center;
`;

export const PlayIcon = styled.div`
    background-color: ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.5)};
    width: 56px;
    height: 56px;
    border-radius: 56px;
    position: absolute;
    left: calc(50% - 28px);
    top: calc(50% - 28px);
    z-index: 15;
    opacity: 0;
    transition: opacity 300ms ${animationType} 0ms;
    pointer-events: none;

    .SVGInline svg {
        margin: 0 auto;
        width: 21px;
        height: 100%;
    }
`;

export const PortraitImageContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`;

export const FallbackTitle = styled.div`
    height: 48px;
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    position: absolute;
    top: calc(50% - 24px);
    left: 0;
    line-height: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;

    span {
        text-align: center;
        font-size: 16px;
        width: 100%;
        display: block;
        font-family: ${fontBold};
    }
`;

export const CardCollectionWrapperInner = styled.div`
    padding-top: 150%;
    position: relative;
`;

export const CardCollectionTileContainer = styled.div`
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    cursor: pointer;
    box-shadow: 0 4px 8px ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.05)};
    transition: width ${animationDuration} ${animationType} ${animationDelay},
        height ${animationDuration} ${animationType} ${animationDelay}, top ${animationDuration} ${animationType} ${animationDelay},
        left ${animationDuration} ${animationType} ${animationDelay}, transform ${animationDuration} ${animationType} ${animationDelay};
`;

export const CollectionCardWrapper = styled.div`
    display: block;
    float: left;
    position: relative;
    z-index: 1;
    transition-property: z-index;
    transition-delay: ${animationDelay};
    padding: 8px 8px;
    width: 50%;

    ${MQ(Breakpoints.xxs)} {
        width: 33.33%;
    }

    ${MQ(Breakpoints.s)} {
        width: 20%;
    }

    ${MQ(Breakpoints.m)} {
        width: 16.66%;
    }

    ${MQ(Breakpoints.l)} {
        width: 14.28%;
    }

    ${MQ(Breakpoints.xl)} {
        width: 12.5%;
    }

    ${MQ(Breakpoints.xxl)} {
        width: 11.11%;
    }

    ${MQ_NOT_TOUCH} {
        &:hover {
            z-index: 2;

            ${CardCollectionTileContainer} {
                width: 109.677%;
            }

            ${PlayIcon} {
                transition: opacity 300ms ${animationType} 200ms;
                opacity: 1;
                pointer-events: all;
            }

            ${ImageWrapper} {
                border: 2px solid ${props => props.theme.brandedConfig.white_color};
                opacity: 1;
            }
        }
    }
`;
