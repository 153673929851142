import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { stripeAction } from '../../providers/reducers/stripe-reducer';
import { HOURS } from '../../utils/TimeUnit';

/**
 * Utility hook to clear from the memory the expirable stripe content
 * TODO: refactor collections to use redux and include here
 *
 * @param checkFrequencyMs
 */
export const useExpiredDataCleaner = (checkFrequencyMs = HOURS.toMillis(12)) => {
    const dispatch = useDispatch();

    const intervalRef = useRef<number>(null);

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = window.setInterval(() => {
            dispatch(stripeAction('EMPTY_EXPIRED', null));
        }, checkFrequencyMs);

        return () => clearInterval(intervalRef.current);
    }, []);
};
