import React, { FC } from 'react';
import { isAndroid, isIOS, isMobile, isTablet } from 'react-device-detect';
import { PlaceholderAsset } from '../../../types/Asset';
import { CardCollectionMixed } from '../../Cards/CardCollection/CardCollectionMixed';
import { StandardCover } from '../../../types/Cover';
import { CardContext, StripeTypes } from '../../../types/Common';
import { CardLandscape } from '../../Cards/CardLandscape/CardLandscape';
import { CardPortrait } from '../../Cards/CardPortrait/CardPortrait';

export const PlaceholderCardPresenter: FC<{
    asset: PlaceholderAsset;
    cardContext: CardContext;
}> = ({ asset, cardContext }) => {
    const cover: StandardCover = {
        uuid: asset.id,
        id: asset.id,
        title: null,
        image: null,
        landscapeImage: null,
        metaData: null,
        path: null,
        circle: false,
        landscape: false,
        assetType: asset.type,
        rawData: asset,
        contentMarker: null,
        progressBar: null,
        renderPlayIcon: false,
        onPlayIconClicked: null,
        defaultRenderSecondLine: isAndroid || isIOS || isTablet || isMobile,
    };

    if (cardContext === CardContext.STRIPE) {
        if (asset.isPortrait) {
            return <CardPortrait asset={cover} stripeType={StripeTypes.PORTRAIT} />;
        }

        return <CardLandscape asset={cover} />;
    }

    return <CardCollectionMixed asset={cover} />;
};
