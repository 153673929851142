import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold } from '../../style/styled-components/cssGlobalVariables';

export const OptionsContent = styled.div`
    width: 100%;
    margin-top: 48px;
    position: relative;

    @media screen and (min-width: 640px) {
        margin-top: 112px;
    }
`;

export const OptionTitle = styled.div`
    width: 100%;
    font-size: 24px;
    padding-bottom: 8px;
    line-height: 32px;
    white-space: normal;
    font-family: ${fontBold};
    position: relative;
    text-align: center;
`;

export const OptionSubtext = styled.div`
    width: 100%;
    text-align: center;
    font-size: 16px;
    padding-bottom: 32px;
    position: relative;
    line-height: 24px;
    padding-right: 24px;
    padding-left: 24px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;

    @media screen and (min-width: 640px) {
        padding-bottom: 88px;
    }
`;

export const CardWrapper = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;
