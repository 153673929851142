import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import SVGInline from 'react-svg-inline';
import { withNoScroll } from '../../hooks/withNoScroll/withNoScroll';
import icons from '../../style';
import translate from '../../utils/fnTranslate';
import { CardService } from '../Cards/CardSubscription/CardSubscription';
import { ServiceOptionCover } from '../../types/Cover';
import { ModalNavigation, ModalCloseButton, ModalWrapper } from '../../style/styled-components/reusable.css';
import { CardWrapper, OptionsContent, OptionSubtext, OptionTitle } from './UpsellOptionSelector.css';

type PropTypes = {
    options: ServiceOptionCover[];
    onClose: () => void;
};

const ServiceSelector: FC<PropTypes> = ({ options, onClose }) => {
    return createPortal(
        <ModalWrapper opacity={1}>
            <ModalNavigation>
                <ModalCloseButton onClick={onClose}>
                    <SVGInline svg={icons.closeIcon} />
                </ModalCloseButton>
            </ModalNavigation>
            <OptionsContent>
                <OptionTitle>{`${translate('SCREEN_UPSELL_TITLE')}`}</OptionTitle>
                <OptionSubtext>{`${translate('SCREEN_UPSELL_SUBTEXT')} `}</OptionSubtext>
                <CardWrapper>
                    {options.map((option, index) => (
                        <CardService key={`${index}-${option.id}`} asset={option} />
                    ))}
                </CardWrapper>
            </OptionsContent>
        </ModalWrapper>,
        document.getElementById('modal-root')
    );
};

export const UpsellOptionSelector = withNoScroll(ServiceSelector);
