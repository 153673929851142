import ApplicationConfig from '../providers/useConfig/ApplicationConfig';
import { DetailEpisode, MovieDetails, ProgramDetails, RecordingDetails, TVSeriesDetailEpisode } from '../types/Asset';
import { isMovieDetails, isProgramDetails, isRecordingDetails, isTvSeriesEpisode } from './fnTypeGuards';
import { DetailRoutes } from '../types/RouteTypes';
import { encodeId } from './fnUrl';
import { getLocalStorage } from './fnStorage';
import { PlayingAsset } from '../types/Player';
import { HelmetData } from '../types/Common';

const getLocale = () => {
    return getLocalStorage('appLanguage') || 'et';
};

export const getHelmetData = (title: string, description = '', robots = 'all'): HelmetData => {
    return {
        title: title || ApplicationConfig.layout_config.title,
        // eslint-disable-next-line no-restricted-globals
        canonicalLink: location.href,
        description,
        robots,
        openGraph: {
            'og:title': title || ApplicationConfig.layout_config.title,
            'og:locale': getLocale(),
            'og:site_name': ApplicationConfig.layout_config.title,
        },
    };
};

export const getDetailPageHelmetData = (
    details: MovieDetails | ProgramDetails | RecordingDetails | DetailEpisode | TVSeriesDetailEpisode,
    appLanguage: string
): HelmetData => {
    const getTitle = () => {
        if (isMovieDetails(details) || isProgramDetails(details) || isRecordingDetails(details)) return details.title;
        return details.episodeTitle || details?.title;
    };

    const description = () => {
        if (isMovieDetails(details)) return details.shortDescription;
        if (isProgramDetails(details) || isRecordingDetails(details)) return details.description;
        return details.shortDescription;
    };

    const getCanonicalLink = () => {
        // eslint-disable-next-line no-restricted-globals
        const base = location.origin;

        if (isProgramDetails(details)) {
            return `${base}${DetailRoutes[appLanguage].PROGRAM.replace(':id', encodeId(details.id))}`;
        }
        if (isRecordingDetails(details)) {
            return `${base}${DetailRoutes[appLanguage].RECORDING.replace(':id', encodeId(details.id))}`;
        }
        if (isMovieDetails(details)) {
            return `${base}${DetailRoutes[appLanguage].MOVIE.replace(':id', encodeId(details.id))}`;
        }
        if (isTvSeriesEpisode(details)) {
            return `${base}${DetailRoutes[appLanguage].TV_SERIES.replace(':id', encodeId(details.id))}?episodeId=${details.episodeId}`;
        }

        return `${base}${DetailRoutes[appLanguage].VOD_SERIES.replace(':id', encodeId(details.id))}?episodeId=${details.episodeId}`;
    };

    const getImage = () => {
        if (isProgramDetails(details)) {
            return details.landscapeImage;
        }

        if (isMovieDetails(details)) {
            return details.image;
        }

        return details.landscapeImage;
    };

    if (!details) {
        return getHelmetData(null, null);
    }

    return {
        title: `${getTitle()} | ${ApplicationConfig.layout_config.title}`,
        description: description(),
        canonicalLink: getCanonicalLink(),
        openGraph: {
            'og:title': getTitle(),
            'og:description': description(),
            'og:site_name': ApplicationConfig.layout_config.title,
            'og:url': getCanonicalLink(),
            'og:locale': getLocale(),
            'og:image': getImage(),
        },
    };
};

export const getPlayerPageHelmetData = (asset: PlayingAsset): HelmetData => {
    const title = asset.subtitle || asset.title;
    // eslint-disable-next-line no-restricted-globals
    const url = location.href;

    return {
        title: `${title} | ${ApplicationConfig.layout_config.title}`,
        description: null,
        canonicalLink: url,
        openGraph: {
            'og:title': title,
            'og:site_name': ApplicationConfig.layout_config.title,
            'og:url': url,
            'og:locale': getLocale(),
        },
    };
};

export const loadScriptAsync = (url: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        let r = false;
        const t = document.getElementsByTagName('script')[0];
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = url;
        s.async = true;

        // @ts-ignore
        // eslint-disable-next-line no-multi-assign,func-names
        s.onload = s.onreadystatechange = function() {
            // @ts-ignore
            if (!r && (!this.readyState || this.readyState === 'complete')) {
                r = true;
                resolve(this);
            }
        };
        // eslint-disable-next-line no-multi-assign
        s.onerror = s.onabort = reject;
        if (t && t.parentNode) {
            t.parentNode.insertBefore(s, t);
        }
    });
};
