/**
 * Converts a color from hex to rgba format
 *
 * @param hex
 * @param opacity
 */
export const hexToRgba = (hex: string, opacity: number) => {
    hex = hex.replace('#', '');
    return `rgba(${parseInt(hex.substring(0, 2), 16)}, ${parseInt(hex.substring(2, 4), 16)}, ${parseInt(
        hex.substring(4, 6),
        16
    )}, ${opacity})`;
};

export const hexArgbToHexRgba = (hex: string) => {
    // 3 digit hex does not have alpha value
    if (hex.length === 4) {
        return hex;
    }
    // 6 digit hex does not have alpha value
    if (hex.length === 7) {
        return hex;
    }

    return `#${hex.slice(3, 9)}${hex[1]}${hex[2]}`;
};
