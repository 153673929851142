import { Datasource } from '../../types/Config';
import { CollectionChunkDataLoader } from './CollectionChunkDataLoader';
import { Paginated } from '../../types/ApiTypes';
import { Loader } from '../../utils/ChunkLoader/Loader';
import { ChunkLoaderData } from '../../utils/ChunkLoader/ChunkLoaderData';

export class CollectionDataLoader extends Loader {
    private readonly dataSource: Datasource;

    private readonly dataParser;

    constructor(dataSource: Datasource, dataParser, pageSize: number, data?: ChunkLoaderData, refreshData?: boolean) {
        super(pageSize);

        this.dataSource = dataSource;
        this.dataParser = dataParser;

        if (data && !refreshData) {
            this.createLoadersByCachedChunks(data);
        } else {
            this.collectionData = new ChunkLoaderData();
        }
    }

    private createLoadersByCachedChunks = (data: ChunkLoaderData) => {
        this.collectionData = { ...data };
        Object.keys(this.collectionData.chunks).forEach(key =>
            this.createLoader(this.collectionData.chunks[key].pagination, this.collectionData.chunks[key].data, false)
        );
    };

    protected createLoaderInstance = (pagination: Paginated) => {
        return new CollectionChunkDataLoader(this.dataSource, this.dataParser, pagination);
    };

    public setTotal = (toSet: number) => {
        this.collectionData.setTotalCount(toSet);
    };
}
