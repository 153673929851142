import { css } from 'styled-components';
import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { Breakpoints, MQ } from '../../../style/styled-components/cssMediaQueries';
import { fontBold, fontNormal, maxZIndex } from '../../../style/styled-components/cssGlobalVariables';

export const OverlayPageWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    z-index: ${maxZIndex + 4};
    overflow: auto;
`;

export const PageWrapper = styled.div`
    margin: 0 auto;
    padding: 88px 16px 0 16px;
    max-width: 640px;

    ${MQ(Breakpoints.s)} {
        padding: 88px 0 0 0;
    }

    ${MQ(Breakpoints.m)} {
        padding: 128px 0 0 0;
    }
`;

export const Title = styled.h1`
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 32px;
    line-height: 40px;
    font-family: ${fontBold};
    text-align: center;
    padding-bottom: 24px;
    display: none;

    ${MQ(Breakpoints.m)} {
        display: block;
    }
`;

export const HintText = styled.p`
    color: ${props => props.theme.brandedConfig.high_contrast};
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 26px;

    ${MQ(Breakpoints.m)} {
        text-align: center;
        padding: 0 90px 16px 90px;
    }
`;

export const SelectHint = styled(HintText)`
    font-family: ${fontNormal};
`;

export const DeviceHint = styled(HintText)`
    font-family: ${fontBold};
    padding-bottom: 24px;
`;

export const DeviceList = styled.div`
    padding-top: 16px;

    ${MQ(Breakpoints.s)} {
        padding-top: 24px;
    }
`;

export const DeviceTypeIcon = styled.div`
    flex: 0 0 32px;
`;

export const DeviceLabel = styled.div`
    flex-grow: 1;
`;

export const Name = styled.p`
    font-size: 16px;
    line-height: 24px;
`;

export const Description = styled.p`
    font-size: 14px;
    line-height: 20px;
    opacity: 0.7;
`;

export const SignOutButton = styled.a`
    flex-grow: 0;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.brandedConfig.primary};
    cursor: pointer;
`;
export const SignOutLabel = styled.span`
    display: none;

    ${MQ(Breakpoints.m)} {
        display: inline;
    }
`;

export const Icon = styled.div`
    ${MQ(Breakpoints.m)} {
        display: none;
    }

    .SVGInline {
        width: 24px;
        height: 24px;
    }
`;

export const Device = styled.div<{ isDisabled: boolean }>`
    display: flex;
    column-gap: 16px;
    align-items: center;
    font-family: ${fontNormal};
    color: ${props => props.theme.brandedConfig.high_contrast};
    background-color: ${props => props.theme.brandedConfig.low_contrast};
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 8px 16px;

    ${props =>
        props.isDisabled
            ? css`
                  opacity: 0.5;
                  ${SignOutButton} {
                      display: none;
                  }
              `
            : ''};

    ${MQ(Breakpoints.m)} {
        margin-bottom: 16px;
    }
`;
