import SVGInline from 'react-svg-inline';
import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { hexToRgba } from '../../../utils/fnColors';

export const PlayButton = styled.div`
    background-color: ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.5)};
    width: 56px;
    height: 56px;
    border-radius: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 15;
    opacity: 1;
`;

export const StyledSVGInline = styled(SVGInline)`
    width: 21px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    svg {
        width: 100%;
        height: auto;
        vertical-align: middle;
    }
`;
