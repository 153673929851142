import ApplicationConfig from 'providers/useConfig/ApplicationConfig';
import React, { lazy } from 'react';
import { Route as ReactRoute } from 'react-router';
import { deviceType } from 'react-device-detect';
import { PageType, pageTypes } from '../types/Config';
import { DetailRoutes, PlayerRoutes, Route, StaticPageRoutes } from '../types/RouteTypes';
import { getLocalStorage } from './fnStorage';

const PageModular = lazy(() => import('components/Pages/PageModular/PageModular'));
const PageDefault = lazy(() => import('components/Pages/PageDefault/PageDefault'));
const PageSearch = lazy(() => import('components/Pages/PageSearch/PageSearch'));
const PageRecordings = lazy(() => import('../components/Pages/PageRecordings/PageRecordings'));
const PageProgramDetail = lazy(() => import('../components/Pages/PageProgramDetail/PageProgramDetail'));
const PageMovieDetail = lazy(() => import('../components/Pages/PageMovieDetail/PageMovieDetail'));
const PageVODSeriesDetail = lazy(() => import('../components/Pages/PageVODSeriesDetail/PageVODSeriesDetail'));
const PageTVSeriesDetail = lazy(() => import('../components/Pages/PageTVSeriesDetail/PageTVSeriesDetail'));
const PageRecordingDetail = lazy(() => import('../components/Pages/PageRecordingDetail/PageRecordingDetail'));
const PageCollection = lazy(() => import('../components/Pages/PageCollection/PageCollection'));
const PageLanguage = lazy(() => import('../components/Pages/Settings/PageLanguage/PageLanguage'));
const MenuLanguage = lazy(() => import('../components/Pages/Settings/PageLanguage/MenuLanguage'));
const AudioLanguage = lazy(() => import('../components/Pages/Settings/PageLanguage/AudioLanguage'));
const SubtitlesLanguage = lazy(() => import('../components/Pages/Settings/PageLanguage/SubtitlesLanguage'));
const LivePlayer = lazy(() => import('../components/Pages/LivePlayer/LivePlayer'));
const CatchupPlayer = lazy(() => import('../components/Pages/CatchupPlayer/CatchupPlayer'));
const TrailerPlayer = lazy(() => import('../components/Pages/TrailerPlayer/TrailerPlayer'));
const VodPlayer = lazy(() => import('../components/Pages/VodPlayer/VodPlayer'));
const RecordingPlayer = lazy(() => import('../components/Pages/RecordingPlayer/RecordingPlayer'));

export const PATH_COLLECTION = 'collection';
export const PATH_EDITORIAL = 'editorial';

const remapPaths = {
    [StaticPageRoutes.SEARCH]: 'search_page',
    [StaticPageRoutes.RECORDINGS]: 'recording_page',
};
const remapPageType = (path: string, originalType: PageType): PageType => {
    return path.toLowerCase() in remapPaths ? remapPaths[path.toLowerCase()] : path in remapPaths ? remapPaths[path] : originalType;
};

export const detailPageSectionRouting = [
    '/:section/:subSection/:sectionId/:foreignId', // my world actor collection page case
    '/:section/:subSection/:sectionId',
    '/:section/:sectionId',
    '/:section',
    '',
];

export const collectionPageSectionRouting = ['/:section', ''];

export const DetailComponentMapping = ['en', 'et', 'ru']
    .map(language => {
        return {
            [language]: {
                [DetailRoutes[language].VOD_SERIES]: PageVODSeriesDetail,
                [DetailRoutes[language].PROGRAM]: PageProgramDetail,
                [DetailRoutes[language].MOVIE]: PageMovieDetail,
                [DetailRoutes[language].TV_SERIES]: PageTVSeriesDetail,
                [DetailRoutes[language].RECORDING]: PageRecordingDetail,
            },
        };
    })
    .reduce((acc, item) => {
        return { ...acc, ...item };
    });

export const PlayerComponentMapping = {
    [PlayerRoutes.LIVE]: LivePlayer,
    [PlayerRoutes.CATCHUP]: CatchupPlayer,
    [PlayerRoutes.TRAILER]: TrailerPlayer,
    [PlayerRoutes.VOD]: VodPlayer,
    [PlayerRoutes.RECORDING]: RecordingPlayer,
};

export const PageComponentMapping = {
    [pageTypes.modular]: PageModular,
    [pageTypes.default]: PageDefault,
    [pageTypes.search]: PageSearch,
    [pageTypes.recording]: PageRecordings,
    [pageTypes.static]: PageDefault,
};

export const createPageRoutes = (config: typeof ApplicationConfig) => {
    const configs: Route[] = [];
    const appLanguage = getLocalStorage('appLanguage');

    config?.getContentConfigs().forEach(page => {
        const pageConfig = config.getPageConfigByLocation({
            route: {
                path: page.path,
                isExact: true,
                url: '',
                params: {},
            },
            parent: page.parent,
            params: {},
            deviceType,
            appLanguage,
        });

        if (page.path) {
            configs.push({
                path: page.path,
                component: PageComponentMapping[remapPageType(page.path, page.type)],
                pageConfig,
                type: page.type,
                childRoutes: [],
            });
        }
    });

    return configs;
};

export const buildSectionDetailRouting = (appLanguage: string) => {
    const routes = [];

    detailPageSectionRouting.forEach(sectionConfig => {
        Object.keys(DetailComponentMapping[appLanguage]).forEach(key => {
            routes.push(<ReactRoute key={key} component={DetailComponentMapping[appLanguage][key]} path={`${sectionConfig}${key}`} />);
        });
    });

    return routes;
};

export const buildSectionCollectionRouting = () => {
    const routes = [];

    collectionPageSectionRouting.forEach(sectionConfig => {
        const pathRepeater = `${sectionConfig}${StaticPageRoutes.REPEATER_COLLECTION}`;
        routes.push(<ReactRoute key={pathRepeater} component={PageCollection} path={pathRepeater} />);
        // console.log('pathRepeater: ', pathRepeater);

        const path = `${sectionConfig}${StaticPageRoutes.COLLECTION}`;
        routes.push(<ReactRoute key={path} component={PageCollection} path={path} />);
        // console.log('path: ', path);
    });

    return routes;
};

export const buildPlayerRoutes = (enabled: boolean) => {
    if (!enabled) return [];
    return Object.keys(PlayerComponentMapping).map(path => <ReactRoute key={path} component={() => null} path={path} />);
};
export const buildLanguageSettingsRoutes = () => {
    const routes = [];

    routes.push(<ReactRoute key={StaticPageRoutes.LANGUAGE} exact={true} component={PageLanguage} path={StaticPageRoutes.LANGUAGE} />);
    routes.push(
        <ReactRoute key={StaticPageRoutes.MENU_LANGUAGE} exact={true} component={MenuLanguage} path={StaticPageRoutes.MENU_LANGUAGE} />
    );
    routes.push(
        <ReactRoute key={StaticPageRoutes.AUDIO_LANGUAGE} exact={true} component={AudioLanguage} path={StaticPageRoutes.AUDIO_LANGUAGE} />
    );
    routes.push(
        <ReactRoute
            key={StaticPageRoutes.SUBTITLES_LANGUAGE}
            exact={true}
            component={SubtitlesLanguage}
            path={StaticPageRoutes.SUBTITLES_LANGUAGE}
        />
    );

    return routes;
};
