import React, { createContext, useEffect, useState } from 'react';
import { ContentContainer } from './PageLayout.css';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { useDetailLocationParams } from '../../hooks/useDetailLocationParams';
import { PageScroller } from '../PageScroller/PageScroller';
import localConfig from '../../config/localConfig';
import { useApp } from '../../providers/useApp/AppContext';

export const LayoutContext = createContext<{
    setContentLoaded: (contentLoaded: boolean) => void;
}>(null);

export const PageLayout: React.FC<{ pathname: string }> = ({ children, pathname }) => {
    const [contentLoaded, setContentLoaded] = useState<boolean>(true);
    const { isDetailPage } = useDetailLocationParams();
    const { isDesktop } = useApp();

    const allowedLocation = true;
    const enableFooter = !localConfig.ignoreFooterPaths.includes(pathname) && !isDetailPage;

    useEffect(() => {
        if (!contentLoaded) {
            setContentLoaded(true);
        }
    }, [pathname]);

    return (
        <LayoutContext.Provider value={{ setContentLoaded }}>
            <PageScroller>
                {allowedLocation && <Header />}
                <ContentContainer withFooter={enableFooter && isDesktop()}>{children}</ContentContainer>
                {enableFooter && contentLoaded && <Footer />}
            </PageScroller>
        </LayoutContext.Provider>
    );
};
