export const NETWORK_ERROR_CODE = 1002;
export const CONCURRENCY_ERROR_CODE = 403;
export const PLAYER_ERROR_INTERRUPT = 7000;
export const INACTIVE_DEVICE = 'INACTIVE_DEVICE';
export const STREAM_LIMIT = 'STREAM_LIMIT';
export const STREAM_REPLACED = 'STREAM_REPLACED';

interface playerErrors {
    [key: number]: string;
}

// Our internal errors

export const playerErrorsCode: playerErrors = {
    403: 'ERR_DEVICES_LIMIT_REACHED',
    1002: 'ERR_NO_INTERNET_TITLE',
};

// Category errors from the Shaka Player.
// For details please visit https://shaka-player-demo.appspot.com/docs/api/shaka.util.Error.html

export const playerErrorsCategory: playerErrors = {
    1: 'ERR_CATEGORY_NETWORK',
    2: 'ERR_CATEGORY_TEXT',
    3: 'ERR_CATEGORY_MEDIA',
    4: 'ERR_CATEGORY_MANIFEST',
    5: 'ERR_CATEGORY_STREAMING',
    6: 'ERR_CATEGORY_DRM',
    7: 'ERR_CATEGORY_PLAYER',
    8: 'ERR_CATEGORY_CAST',
    9: 'ERR_CATEGORY_STORAGE',
    10: 'ERR_CATEGORY_ADS',
};
