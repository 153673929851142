import { useEffect, useState } from 'react';
import { matchPath } from 'react-router';
import { PlayerRoutes } from '../types/RouteTypes';
import { useWrapLocation } from './useWrapRouter/useWrapLocation';
import { PlayerLocationParams, PlayerProps, PlayingAssetType } from '../types/Player';

export const playerRouteMatch = (path: string): PlayerProps => {
    let match = null;
    let type: PlayingAssetType = null;

    Object.getOwnPropertyNames(PlayerRoutes).forEach(route => {
        const matchByRoute = matchPath(path, {
            path: PlayerRoutes[route],
        });

        if (matchByRoute) {
            match = matchByRoute;
            type = route.toLowerCase() as PlayingAssetType;
        }
    });

    return {
        type,
        params: match?.params ?? {},
    };
};

export const usePlayerRouter = () => {
    const { pathname } = useWrapLocation();
    const [params, setParams] = useState<PlayerLocationParams>();
    const [playerType, setPlayerType] = useState<PlayingAssetType>(null);

    useEffect(() => {
        const { type, params: routeParams } = playerRouteMatch(pathname);

        setParams(routeParams);
        setPlayerType(type ?? null);
    }, [pathname]);

    return {
        params,
        playerType,
    };
};
