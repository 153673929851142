import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import { fontNormal, modalZIndex, pageScrollerZIndex } from './cssGlobalVariables';
import { LayoutConfig } from '../../types/Config';
import { Breakpoints, MQ } from './cssMediaQueries';

export const GlobalStyle = createGlobalStyle<{ theme: { globalConfig: LayoutConfig; brandedConfig: LayoutConfig } }>`
    ${reset}

    .player-opened {
        overflow: hidden;
        width: 100%;
    }

    body {
        font-family: ${fontNormal}, sans-serif;
        color: ${props => props.theme.globalConfig.high_contrast};
        background-color: ${props => props.theme.globalConfig.background};
        -webkit-text-size-adjust: auto;
        cursor: default;
      	-webkit-touch-callout: none;
    	-webkit-user-select: none;
    	-moz-user-select: none;
    	-ms-user-select: none;
    	user-select: none;
    	overflow: auto;
    	line-height: 1;
    }

    a {
        text-decoration: none;
        font-size: 16px;
        color: ${props => props.theme.brandedConfig.high_contrast};
        transition: all .2s ease-out;

        &:hover,
        &:active{
            color: ${props => props.theme.brandedConfig.high_contrast};
        }

        &:focus{
            outline: none;
        }
    }

    .font-svg path {
        fill: ${props => props.theme.brandedConfig.high_contrast};
    }

    .ScrollbarsCustom-Content {
        padding: 0 !important;
        min-height: auto !important;
    }

    .ScrollbarsCustom-Wrapper {
        right: 0 !important;
    }

    .ScrollbarsCustom-TrackY {
        z-index: ${pageScrollerZIndex} !important;
        background: transparent !important;
        height: 100% !important;
        top: 0 !important;
        bottom: 0 !important;
        width: 4px !important;
        right: 4px !important;

        .ScrollbarsCustom-Thumb {
            background: ${props => props.theme.globalConfig.scrollbar_color} !important;
            border-radius: 4px;
        }
    }

    .react-toast-notifications__container {
      bottom: 64px !important;
      z-index: ${modalZIndex + 1} !important;
      padding-right: 16px !important;

      ${MQ(Breakpoints.m)} {
        bottom: auto !important;
        top: 64px !important;
        padding-right: 40px !important;
      }
    }
`;
