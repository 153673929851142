import React, { FC, useEffect, useState } from 'react';
import { usePlayer } from 'providers/player/PlayerContext';
import { getMiniPlayerProps } from 'utils/fnData';
import { PlayingAssetType } from 'types/Player';
import { useHistory } from 'react-router';
import { Marker, TVSeriesDetailEpisode } from '../../../types/Asset';
import { getCatchupPlayerURL, getLivePlayerURL, getTVSeriesEpisodeDetailUrl } from '../../../utils/fnUrl';
import { CardLandscape } from '../../Cards/CardLandscape/CardLandscape';
import { CardCollectionMixed } from '../../Cards/CardCollection/CardCollectionMixed';
import { useDetailLocationParams } from '../../../hooks/useDetailLocationParams';
import { useAuth } from '../../../providers/useAuth/AuthContext';
import { StandardCover } from '../../../types/Cover';
import { CardContext } from '../../../types/Common';
import { ProgressIndicator } from '../../ProgressIndicator/ProgressIndicator';
import { useConfig } from '../../../providers/useConfig/ConfigContext';
import { LiveProgressIndicator } from '../../LiveProgressIndicator/LiveProgressIndicator';
import { isFuture, isNow } from '../../../utils/fnDate';
import { useAssetReplayCapable } from '../../../hooks/useAssetReplayCapable/useAssetReplayCapable';
import { useBroadcastTimeCluster } from '../../../hooks/useBroadcastTimeCluster/useBroadcastTimeCluster';
import { usePlayOptionHelper } from '../../../providers/usePlayOptions/PlayOptionsContext';
import { CardTrackingData } from '../../../types/Tracking';
import { trackingClickHandler } from '../../../utils/fnTracking';
import { useTracking } from '../../../providers/useTracking/TrackingContext';
import { MILLISECONDS } from '../../../utils/TimeUnit';
import { getTVSeriesDetailContentMarker } from '../../../utils/fnContentMarker';
import { useApp } from '../../../providers/useApp/AppContext';

export const TVEpisodeCardPresenter: FC<{
    asset: TVSeriesDetailEpisode;
    cardContext: CardContext;
    trackingData: CardTrackingData;
    onClick?: (event: React.MouseEvent<any>, data: TVSeriesDetailEpisode) => void;
}> = ({ asset, cardContext, onClick, trackingData }) => {
    const { id, episodeId, title, image, metaData, bookmark, startTime, endTime, isChannelSubscribed, isLive, shortDescription } = asset;
    const locationParamsPath = useDetailLocationParams();
    const { isGuest } = useAuth();
    const { appLanguage } = useApp();
    const history = useHistory();
    const { config } = useConfig();

    const timeCluster = useBroadcastTimeCluster(asset);
    const playOptionVerifier = usePlayOptionHelper();
    const track = useTracking();
    const { isMini, updateMiniPlayerProps } = usePlayer();
    const { isReplayCapable } = useAssetReplayCapable(asset);

    const [contentMarker, setContentMarker] = useState<Marker>(null);

    const toPlayerPath = (onlyThePath: boolean = false) => {
        if (timeCluster === 'LIVE') {
            if (isMini) {
                if (onlyThePath) {
                    return null;
                }
                updateMiniPlayerProps(getMiniPlayerProps(asset, PlayingAssetType.LIVE));
            } else {
                if (onlyThePath) {
                    return getLivePlayerURL(asset.channelId);
                }
                history.push(getLivePlayerURL(asset.channelId));
            }
        } else {
            if (onlyThePath) {
                return getCatchupPlayerURL(asset.eventId);
            }
            playOptionVerifier(asset, getCatchupPlayerURL(asset.eventId));
        }

        return null;
    };

    const onPlayClicked = event => {
        event.preventDefault();
        event.stopPropagation();

        trackingClickHandler(
            event,
            () => toPlayerPath(false),
            {
                ...trackingData,
                ...{
                    'data-track-id': 'image_button_play',
                },
            },
            track
        );
    };

    const renderPlayIcon = () => {
        return !isGuest && isChannelSubscribed && (isReplayCapable || isNow(startTime, endTime));
    };

    const calculatePath = () => {
        return renderPlayIcon() ? toPlayerPath(true) : getTVSeriesEpisodeDetailUrl(asset, locationParamsPath, appLanguage);
    };

    useEffect(() => {
        if (timeCluster && asset) {
            setContentMarker(getTVSeriesDetailContentMarker(timeCluster, isLive));
        }
    }, [timeCluster, asset]);

    const progress = (bookmark * 100) / MILLISECONDS.toSeconds(endTime - startTime);

    const cover: StandardCover = {
        uuid: id + episodeId,
        id,
        title,
        image,
        landscapeImage: image,
        path: calculatePath(),
        metaData,
        shortDescription,
        circle: false,
        landscape: true,
        assetType: asset.type,
        rawData: asset,
        contentMarker,
        progressBar:
            timeCluster === 'LIVE' ? (
                <LiveProgressIndicator startTime={startTime} endTime={endTime} onLiveStateChanged={null} />
            ) : (
                !isFuture(startTime) && (
                    <ProgressIndicator percentage={progress < config.app_config.player_settings.bookmark_threshold ? progress : 0} />
                )
            ),
        renderPlayIcon: renderPlayIcon(),
        isEntitled: !isGuest && isChannelSubscribed,
        onPlayIconClicked: onPlayClicked,
        onClick,
    };

    return cardContext === CardContext.STRIPE ? (
        <CardLandscape asset={cover} showDescription={true} {...trackingData} />
    ) : (
        <CardCollectionMixed asset={cover} {...trackingData} />
    );
};
