import { History } from 'history';
import { PageConfigType } from './Page';

export type DetailRoutesLanguages = 'en' | 'et' | 'ru';

export type DetailRoutesInterface = {
    [key in DetailRoutesLanguages]: Partial<DetailRoutesType>;
};

export type DetailRoutesKeyType = 'VOD_SERIES' | 'TV_SERIES' | 'VOD_MOVIE' | 'MOVIE' | 'PROGRAM' | 'RECORDING';

export type DetailRoutesType = {
    [key in DetailRoutesKeyType]: string;
};

export const DetailRoutes: DetailRoutesInterface = {
    en: {
        VOD_SERIES: `/vod-series/:id`,
        TV_SERIES: '/tv-series/:id',
        MOVIE: '/movie/:id',
        PROGRAM: '/program/:id',
        RECORDING: '/recording/:id',
    },
    et: {
        VOD_SERIES: `/seriaalid/:id`,
        TV_SERIES: '/seriaalid-telekanalitelt/:id',
        MOVIE: '/filmid/:id',
        PROGRAM: '/saated/:id',
        RECORDING: '/salvestused/:id',
    },
    ru: {
        VOD_SERIES: `/seriaalid/:id`,
        TV_SERIES: '/seriaalid-telekanalitelt/:id',
        MOVIE: '/filmid/:id',
        PROGRAM: '/saated/:id',
        RECORDING: '/salvestused/:id',
    },
};

export const DetailRouteDomain: DetailRoutesInterface = {
    en: {
        VOD_SERIES: 'vod-series',
        TV_SERIES: 'tv-series',
        VOD_MOVIE: 'movie',
        PROGRAM: 'program',
        RECORDING: 'recording',
    },
    et: {
        VOD_SERIES: 'seriaalid',
        TV_SERIES: 'seriaalid-telekanalitelt',
        VOD_MOVIE: 'filmid',
        PROGRAM: 'saated',
        RECORDING: 'salvestused',
    },
    ru: {
        VOD_SERIES: 'seriaalid',
        TV_SERIES: 'seriaalid-telekanalitelt',
        VOD_MOVIE: 'filmid',
        PROGRAM: 'saated',
        RECORDING: 'salvestused',
    },
};

export enum PlayerRoutes {
    LIVE = '/play/live/:channelId',
    CATCHUP = '/play/catchup/:broadcastId',
    VOD = '/play/vod/:assetId',
    TRAILER = '/play/trailer/:assetId',
    RECORDING = '/play/recording/:recordingId',
}

export enum StaticPageRoutes {
    COLLECTION = '/collection/:collectionId',
    REPEATER_COLLECTION = '/collection/:collectionId/:foreignId',
    SETTINGS = '/settings',
    ABOUT = '/settings/about',
    RECORDINGS_SETTINGS = '/settings/recordings',
    AUTOPLAY = '/settings/autoplay',
    LANGUAGE = '/settings/language',
    MENU_LANGUAGE = '/settings/language/menu',
    AUDIO_LANGUAGE = '/settings/language/audio',
    SUBTITLES_LANGUAGE = '/settings/language/subtitles',
    INTERNET_SPEED_TEST = '/settings/internet-speed-test',
    DEVICE_MANAGER = '/settings/device-manager',
    ACCOUNT = '/settings/account',
    PIN_PROTECTION = '/settings/pin',
    SEARCH = '/search',
    DEV = '/dev-screen',
    RECORDINGS = '/recordings',
}

export type Route = {
    path: string;
    component: any;
    childRoutes: Route[];
    type: string;
    pageConfig: PageConfigType;
};

export type PagePropTypes = {
    history: History;
    match: {
        params: any;
        isExact: boolean;
        path: string;
        url: string;
    };
};
