import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Swipeable } from 'react-swipeable';
import { useHistory } from 'react-router';
import icons from '../../style';
import spinner from '../../assets/images/spinner.png';
import { VolumeControl, VolumeControlElements } from '../VolumeControl/VolumeControl';
import { SeekBar } from '../SeekBar/SeekBar';
import {
    MinimizeButton,
    CastingLabel,
    CloseButton,
    CloseExtraControls,
    ControlButtonLabel,
    ControlButtonWrapper,
    DesktopButtonsContainer,
    DesktopHeaderControls,
    ExtraControlsWrapper,
    FooterControls,
    FullScreenToggleIcon,
    IconsWrapper,
    JumpButtonIcon,
    JumpButtonLabel,
    JumpButtonWrapper,
    LabelMetaData,
    LoadingIndicatorWrapper,
    MetaDataContainer,
    MetaDataWrapper,
    MobileHeaderControlsContainer,
    MobileMiddleButtonsContainer,
    PlayerButton,
    PlayerControls,
    PlayerMetaData,
    SeekBarHolder,
    StyledSVGInline,
    Title,
    VolumeControlButton,
    VolumeControlHoveringWrapper,
    DesktopHeaderControlsInnerContainer,
    HeaderEmbeddedControls,
    EmbeddedMetaDataContainer,
    EmbeddedPlayerMetaData,
    EmbeddedTitle,
    EmbeddedIconsWrapper,
    EmbeddedMetaDataWrapper,
    EmbeddedChanelInfoContainer,
    ChanelNumber,
    EmbeddedMiddleButtonsContainer,
    VolumeEmbeddedWrapper,
    VolumeControlEmbedded,
    VolumeEmbeddedContainer,
    ButtonsInnerContainer,
    ChanelInfoContainer,
    Subtitle,
    MetadataSubtitle,
    MetaDataContainerMobile,
    MoreInfoArrow,
    FullScreenContainer,
} from './PlayerUI.css';
import { usePlayer } from '../../providers/player/PlayerContext';
import { buildTimeString } from '../../utils/fnDate';
import translate from '../../utils/fnTranslate';
import { ContentMarker, StyledSVG } from '../../style/styled-components/reusable.css';
import {
    LivePlayerAssetInfo,
    PlayerControl,
    PlayerKeyboardControl,
    PlayingMode,
    PropTypesAdditional,
    PropTypesPlayerUICommon,
} from '../../types/Player';
import { useConfig } from '../../providers/useConfig/ConfigContext';
import { ControlGroup } from '../Player/PlayerWrapper';
import { getLocalStorage } from '../../utils/fnStorage';
import { isIphone } from '../../utils/fnDevices';
import { useElementInteractionTracking } from '../../providers/useTracking/TrackingContext';
import { useApp } from '../../providers/useApp/AppContext';
import { useCast } from '../../providers/cast/CastContext';
import { getLivePlayerURL } from '../../utils/fnUrl';
import { isOrientationLandscape } from '../../style/styled-components/cssMediaQueries';
import { DetailMoreModal } from '../DetailMoreModal/DetailMoreModal';
import { PersonRole } from '../../types/Asset';

const PROGRESS_UPDATE_INTERVAL = 350;

export const ControlButton: FC<{
    label?: string;
    render?: boolean;
    className?: string;
    onClick?: () => void;
    isEnabled: boolean;
    invisible?: boolean;
}> = ({ label, render = true, invisible = false, className, isEnabled, onClick, children }) => {
    if (!render) {
        return null;
    }

    return (
        <ControlButtonWrapper className={className} onClick={isEnabled ? onClick : null} disabled={!isEnabled} invisible={invisible}>
            {children}
            {label != null && <ControlButtonLabel data-test-id={`player-control-button-${label}`}>{label}</ControlButtonLabel>}
        </ControlButtonWrapper>
    );
};

const JumpButton: FC<{ jumpValue: number; icon: any; isForward: boolean; getMaxJump: () => number; doJump: (value: number) => void }> = ({
    jumpValue,
    isForward,
    icon,
    doJump,
    getMaxJump,
}) => {
    const APPLY_JUMP_TIMEOUT = 1000;

    const interactionTracking = useElementInteractionTracking();

    const [totalJumpValue, setTotalJumpValue] = useState(0);
    const jumpIntervalRef = useRef(null);
    const totalJumpValueRef = useRef(0);

    const onClick = () => {
        interactionTracking({
            'data-track-id': isForward ? 'player_jump_fwd' : 'player_jump_rwd',
        });

        let jumpValueToSet = totalJumpValue + jumpValue;
        if (jumpValueToSet > getMaxJump()) {
            jumpValueToSet = getMaxJump() + 2;
        }

        setTotalJumpValue(jumpValueToSet);
        clearTimeout(jumpIntervalRef.current);

        jumpIntervalRef.current = setTimeout(() => {
            doJump(isForward ? jumpValueToSet : -jumpValueToSet);
            setTotalJumpValue(0);
        }, APPLY_JUMP_TIMEOUT);
    };

    useEffect(() => {
        totalJumpValueRef.current = totalJumpValue;
    }, [totalJumpValue]);

    return (
        <JumpButtonWrapper onClick={onClick}>
            <JumpButtonIcon>{icon}</JumpButtonIcon>
            <JumpButtonLabel>{totalJumpValue === 0 ? jumpValue : totalJumpValue + jumpValue}</JumpButtonLabel>
        </JumpButtonWrapper>
    );
};

const BufferingIndicator: FC<{ buffering: boolean }> = ({ buffering }) => {
    if (!buffering) {
        return null;
    }

    return (
        <LoadingIndicatorWrapper>
            <img src={spinner} alt={'spinner'} />
        </LoadingIndicatorWrapper>
    );
};

export const PlayerUI: FC<PropTypesPlayerUICommon & PropTypesAdditional> = ({
    shouldHideLayout,
    isFullScreen,
    onControlsRef,
    onBackClicked,
    toggleFullScreen,
    forceExcludedControls = [],
    extraControls,
    loading,
    metaData,
    embeddedPlayerMetadata,
    metaIcons,
    contentMarker,
    logo,
    controls,
    disabledControls,
    title,
    subtitle,
    seekEnabled,
    actionPopup,
    mobileOverlayActionHandlers,
    isEmbedded,
    logicalChannelNumber,
    description,
    moreOverlayMetaData,
    cast,
    originalLanguages,
    captionLanguages,
}) => {
    const {
        isPlaying,
        volume,
        isMuted,
        isBuffering,
        playingMode,
        setPlayingState,
        setVolumeLevel,
        seek,
        seekStart,
        seekEnd,
        getThumbnail,
        showThumbnail,
        jump,
        getProgressWithJump,
        getSecondaryProgressWithJump,
        getProgress,
        getDuration,
        getPrePadding,
        getPostPadding,
        getSeekMax,
        canUpdateProgress,
        activeControlGroup,
        setActiveControlGroup,
        toggleMute,
        startOver,
        jumpToLive,
        playerError,
        loaded,
        setStreamEndReached,
        setIsEmbedded,
        switchToMiniPlayer,
        asset,
    } = usePlayer();

    const history = useHistory();

    const { castConnected, toggleConnect, deviceName } = useCast();

    const interactionTracking = useElementInteractionTracking();

    const { config } = useConfig();

    const volumeBar = useRef(null);
    const volumeValue = useRef(null);

    const scrubber = useRef(null);
    const currentTime = useRef(null);
    const remainingTime = useRef(null);
    const progressLine = useRef(null);
    const bufferLine = useRef(null);
    const isLoaded = useRef(false);
    const scrubberValue = useRef(null);
    const thumbnailImage = useRef(null);
    const thumbnail = useRef(null);
    const availableThumbnail = useRef(null);
    const playing = useRef(isPlaying);

    const fullScreenContainerRef = useRef<HTMLDivElement>();

    const [isScrubberValueShown, setIsScrubberValueShown] = useState<boolean>(false);
    const [isVolumeHovered, setIsVolumeHovered] = useState<boolean>(false);

    const [isOverlayActive, setOverlayActive] = useState(false);
    const [isSeeking, setIsSeeking] = useState(false);

    const progressUpdateIntervalId = useRef(null);
    const extraControlsLeaveTimeout = useRef(null);

    const isMobileDevice = getLocalStorage('isMobile');
    const { isMobile } = useApp();

    const openOverlay = () => {
        interactionTracking({
            'data-track-id': 'player_show_more',
            'data-asset-name': title,
        });
        setOverlayActive(true);
    };

    const closeOverlay = () => {
        setOverlayActive(false);
    };

    const onPlayPauseClicked = async () => {
        interactionTracking({
            'data-track-id': 'player_play_pause',
        });

        await setPlayingState(!isPlaying);
    };

    const onStartOverClicked = async () => {
        interactionTracking({
            'data-track-id': 'player_start_over',
        });

        await startOver();
    };

    const onJumpToLiveClicked = async () => {
        interactionTracking({
            'data-track-id': 'player_jump_to_live',
        });

        await jumpToLive();
    };

    const onVolumeClicked = () => {
        interactionTracking({
            'data-track-id': 'player_volume',
        });

        toggleMute();
    };

    const onFullScreenToggleClicked = () => {
        interactionTracking({
            'data-track-id': 'player_toggle_full_screen',
        });

        toggleFullScreen();
    };

    const onPlayerCloseClicked = (exitPlayer?: boolean) => {
        interactionTracking({
            'data-track-id': 'player_close',
        });
        if (exitPlayer) {
            setStreamEndReached(true);
        }

        onBackClicked();
    };

    const onVolumeInput = e => {
        setVolumeLevel(parseFloat(e.target.value));
    };

    const onVolumeBarCreated = (volumeControlElements: VolumeControlElements) => {
        volumeBar.current = volumeControlElements.volumeBar;
        volumeValue.current = volumeControlElements.volumeValue;
    };

    const onCastClicked = () => {
        interactionTracking({
            'data-track-id': 'player_cast',
        });

        toggleConnect().catch(() => {});
    };

    const toggleRefDisplay = (ref: any, display: boolean, callback?: () => void) => {
        if (ref?.current && ref?.current?.style) {
            if (!display) {
                ref.current.style.display = 'none';
            } else {
                ref.current.style.display = 'block';
            }
            if (callback) {
                callback();
            }
        }
        return null;
    };

    const setScrubValueAndThumbnailPosition = (playHeadPosition: number, scrubberMiddlePosition: number) => {
        const scrubberWidth = scrubber.current.offsetWidth;
        const scrubberProgress = playHeadPosition * scrubberWidth;

        if (availableThumbnail.current) {
            if (thumbnail.current && thumbnail.current.style) {
                const thumbnailWidth = thumbnail.current.offsetWidth;
                const thumbnailPosition = scrubberProgress - thumbnailWidth / 2 + 20 - scrubberMiddlePosition;

                const maxPosition = scrubberWidth - thumbnailWidth;
                const minPosition = 0;

                thumbnail.current.style.left =
                    thumbnailPosition > minPosition
                        ? thumbnailPosition < maxPosition
                            ? `${thumbnailPosition}px`
                            : `${maxPosition}px`
                        : minPosition;

                if (scrubberValue.current && scrubberValue.current.style) {
                    const minScrubPosition = thumbnailWidth / 2 - 20;
                    const maxScrubPosition = scrubberWidth - thumbnailWidth / 2 - 20;
                    const scrubPosition = scrubberProgress - scrubberMiddlePosition;
                    scrubberValue.current.style.left =
                        scrubPosition > minScrubPosition
                            ? scrubPosition < maxScrubPosition
                                ? `${scrubPosition}px`
                                : `${maxScrubPosition}px`
                            : `${minScrubPosition}px`;
                }
            }
        } else {
            scrubberValue.current.style.left = `${scrubberProgress - scrubberMiddlePosition}px`;
        }
    };

    const setThumbnail = (thumb: any) => {
        if (thumbnail.current && thumbnail.current.style) {
            thumbnail.current.style.width = `${thumb.width}px`;
            thumbnail.current.style.height = `${thumb.height}px`;
            thumbnail.current.style.top = `calc(-${thumb.height}px - 40px)`;
        }
        if (thumbnailImage.current && thumbnailImage.current.style) {
            const [url] = thumb.uris;
            thumbnailImage.current.src = url;
            thumbnailImage.current.style.transform = `translate(-${thumb?.positionX}px, -${thumb?.positionY}px)`;
        }
    };

    const getDataForThumbnail = async (seekValue: number) => {
        if (!availableThumbnail.current) {
            return;
        }
        try {
            const thumb = await getThumbnail(seekValue);
            setThumbnail(thumb);
        } catch {
            toggleRefDisplay(thumbnail, false);
        }
    };

    const setScrubberValue = () => {
        const streamDuration = getDuration();
        const seekValue = scrubber.current.value >= streamDuration ? streamDuration - 1 : scrubber.current.value;
        const playHeadPosition = seekValue / streamDuration || 0;
        const scrubberMiddlePosition = playHeadPosition * 20 + 10;

        if (scrubberValue.current && scrubberValue.current.style) {
            const scrubberStandardValue = scrubber.current.value - getPrePadding();
            const scrubberPaddingValue = -(streamDuration - scrubber.current.value) + getPostPadding();

            if (streamDuration === 0) {
                scrubberValue.current.innerHTML = '00:00:00';
            } else {
                scrubberValue.current.innerHTML =
                    scrubberPaddingValue > 1
                        ? `+${buildTimeString(scrubberPaddingValue, true)}`
                        : buildTimeString(scrubberStandardValue, true);
            }

            getDataForThumbnail(parseFloat(seekValue));
            setScrubValueAndThumbnailPosition(playHeadPosition, scrubberMiddlePosition);
        }
        return null;
    };

    const updateTimeAndSeekRange = () => {
        if (!isLoaded.current) {
            return;
        }

        const duration = getDuration();

        if (duration === 0) {
            return;
        }

        if (canUpdateProgress()) {
            const elapsedTime = getProgressWithJump();

            if (playingMode === PlayingMode.TIME_SHIFT && elapsedTime === 0) {
                return;
            }

            let playedTimeInSeconds = elapsedTime - getPrePadding();
            let remainingTimeInSeconds = -(duration - elapsedTime) + getPostPadding();

            // When in post padding the played time shout not be counting
            playedTimeInSeconds -= remainingTimeInSeconds > 0 ? remainingTimeInSeconds : 0;
            // When in pre padding the remaining time should not count down
            remainingTimeInSeconds -= playedTimeInSeconds < 0 ? playedTimeInSeconds : 0;

            playedTimeInSeconds = playedTimeInSeconds < -getPrePadding() ? 0 : playedTimeInSeconds;
            remainingTimeInSeconds = remainingTimeInSeconds > getPostPadding() ? 0 : remainingTimeInSeconds;

            if (currentTime.current) {
                currentTime.current.innerHTML =
                    Number.isNaN(elapsedTime) || duration === 0 ? '0:00:00' : buildTimeString(playedTimeInSeconds, true);
            }

            if (remainingTime.current) {
                if (duration === 0) {
                    remainingTime.current.innerHTML = '00:00:00';
                } else {
                    remainingTime.current.innerHTML = `${remainingTimeInSeconds > 1 ? '+' : ''}${
                        Number.isNaN(remainingTimeInSeconds) ? '0:00:00' : buildTimeString(remainingTimeInSeconds, true)
                    }`;
                }
            }

            if (scrubber.current) {
                scrubber.current.min = 0;
                scrubber.current.max = duration ?? 1;
                scrubber.current.value = elapsedTime;
            }

            const playHeadPosition = elapsedTime / duration ?? 0;
            if (progressLine.current && progressLine.current.style) {
                progressLine.current.style.width = `${playHeadPosition * 100}%`;
            }
        }

        const secondaryProgress = getSecondaryProgressWithJump() / duration ?? 0;
        if (bufferLine.current && bufferLine.current.style) {
            bufferLine.current.style.width = `${secondaryProgress * 100}%`;
        }
    };

    const onSeekInput = useCallback(async () => {
        const seekMax = getSeekMax();
        const streamDuration = getDuration();

        if (scrubber.current.value > seekMax && seekMax > 0) {
            scrubber.current.value = seekMax;
        }

        const seekValue = scrubber.current.value >= streamDuration ? streamDuration - 1 : scrubber.current.value;
        const playHeadPosition = seekValue / streamDuration || 0;

        if (progressLine.current && progressLine.current.style) {
            progressLine.current.style.width = `${playHeadPosition * 100}%`;
        }

        setScrubberValue();
        await seek(parseFloat(seekValue));
    }, [seek]);

    const onSeekStart = () => {
        if (progressUpdateIntervalId.current) {
            clearInterval(progressUpdateIntervalId.current);
        }
        if (availableThumbnail.current) {
            toggleRefDisplay(thumbnail, true);
        }
        setScrubberValue();
        toggleRefDisplay(scrubberValue, true, () => {
            setIsScrubberValueShown(true);
        });
        setIsSeeking(true);
        seekStart();
    };

    const onSeekEnd = () => {
        if (progressUpdateIntervalId.current) {
            clearInterval(progressUpdateIntervalId.current);
        }
        if (availableThumbnail.current) {
            toggleRefDisplay(thumbnail, false);
        }

        progressUpdateIntervalId.current = setInterval(updateTimeAndSeekRange, PROGRESS_UPDATE_INTERVAL);
        toggleRefDisplay(scrubberValue, false, () => {
            setIsScrubberValueShown(false);
        });
        setIsSeeking(false);
        seekEnd();
    };

    const onSeekBarCreated = ref => {
        scrubber.current = ref.scrubber;
        currentTime.current = ref.currentTime;
        remainingTime.current = ref.remainingTime;
        progressLine.current = ref.progressLine;
        bufferLine.current = ref.bufferLine;
        scrubberValue.current = ref.scrubberValue;
        thumbnail.current = ref.thumbnailContainer;
        thumbnailImage.current = ref.thumbnailImage;
    };

    const getMetaData = (meta: any) => {
        if (meta?.length) {
            return meta.map((obj, index) => {
                return (
                    <React.Fragment key={`meta-${index}`}>
                        <LabelMetaData data-test-id={'player-content-subtitle'} key={`meta-data-${index}`}>
                            {obj}
                        </LabelMetaData>
                    </React.Fragment>
                );
            });
        }

        return null;
    };

    const getMetaIcons = () => {
        if (metaIcons.length) {
            return metaIcons.map((icon, index) => {
                return <StyledSVG key={`icon-${index}`} svg={icons[icon]} />;
            });
        }

        return null;
    };

    const getMaxJumpValue = (): number => {
        if (getSeekMax() === 0) return Infinity;
        return Math.round(getSeekMax() - getProgress());
    };

    const getVolumeIcon = useCallback(() => {
        if (volume === 0 || isMuted) {
            return icons.volumeMutedIcon;
        }

        if (volume <= 0.5 && volume > 0) {
            return icons.volumeMinIcon;
        }

        return icons.volumeIcon;
    }, [isMuted, volume]);

    const mobileCenterControls = () => {
        if (isScrubberValueShown) {
            return null;
        }
        return (
            <>
                <ControlButton
                    key={PlayerControl.JUMP_BACK}
                    render={controls.includes(PlayerControl.JUMP_BACK)}
                    isEnabled={!disabledControls.includes(PlayerControl.JUMP_BACK)}
                    label={translate('SCREEN_PLAYER_SKIP_BWD_BUTTON')}
                >
                    <PlayerButton data-test-id={'player-skip-back-button'}>
                        <JumpButton
                            jumpValue={config.app_config.player_settings.skip_back_interval}
                            icon={<StyledSVGInline svg={icons.iconJumpBackward} />}
                            isForward={false}
                            doJump={jump}
                            getMaxJump={getMaxJumpValue}
                        />
                    </PlayerButton>
                </ControlButton>
                <ControlButton
                    key={PlayerControl.PLAY_PAUSE}
                    label={translate(isPlaying ? 'SCREEN_PLAYER_PAUSE_BUTTON' : 'SCREEN_PLAYER_PLAY_BUTTON')}
                    invisible={!controls.includes(PlayerControl.PLAY_PAUSE)}
                    isEnabled={!disabledControls.includes(PlayerControl.PLAY_PAUSE)}
                    onClick={onPlayPauseClicked}
                >
                    <PlayerButton>
                        <StyledSVGInline svg={isPlaying ? icons.pauseIconSimple : icons.playIconSimple} />
                    </PlayerButton>
                </ControlButton>
                <ControlButton
                    key={PlayerControl.JUMP_FORWARD}
                    render={controls.includes(PlayerControl.JUMP_FORWARD)}
                    isEnabled={!disabledControls.includes(PlayerControl.JUMP_FORWARD)}
                    label={translate('SCREEN_PLAYER_SKIP_FWD_BUTTON')}
                >
                    <PlayerButton data-test-id={'player-skip-forward-button'}>
                        <JumpButton
                            jumpValue={config.app_config.player_settings.skip_forward_interval}
                            icon={<StyledSVGInline svg={icons.iconJumpForward} />}
                            isForward={true}
                            doJump={jump}
                            getMaxJump={getMaxJumpValue}
                        />
                    </PlayerButton>
                </ControlButton>
            </>
        );
    };

    const embeddedCenterControls = () => {
        if (isScrubberValueShown) {
            return null;
        }
        return (
            <>
                <ControlButton
                    key={PlayerControl.JUMP_BACK}
                    render={controls.includes(PlayerControl.JUMP_BACK)}
                    isEnabled={!disabledControls.includes(PlayerControl.JUMP_BACK)}
                    label={translate('SCREEN_PLAYER_SKIP_BWD_BUTTON')}
                >
                    <PlayerButton data-test-id={'player-skip-back-button'}>
                        <JumpButton
                            jumpValue={config.app_config.player_settings.skip_back_interval}
                            icon={<StyledSVGInline svg={icons.iconJumpBackward} />}
                            isForward={false}
                            doJump={jump}
                            getMaxJump={getMaxJumpValue}
                        />
                    </PlayerButton>
                </ControlButton>
                <ControlButton
                    key={PlayerControl.PLAY_PAUSE}
                    label={translate(isPlaying ? 'SCREEN_PLAYER_PAUSE_BUTTON' : 'SCREEN_PLAYER_PLAY_BUTTON')}
                    invisible={!controls.includes(PlayerControl.PLAY_PAUSE)}
                    isEnabled={!disabledControls.includes(PlayerControl.PLAY_PAUSE)}
                    onClick={onPlayPauseClicked}
                >
                    <PlayerButton>
                        <StyledSVGInline svg={isPlaying ? icons.pauseIconSimple : icons.playIconSimple} />
                    </PlayerButton>
                </ControlButton>
                <ControlButton
                    key={PlayerControl.JUMP_FORWARD}
                    render={controls.includes(PlayerControl.JUMP_FORWARD)}
                    isEnabled={!disabledControls.includes(PlayerControl.JUMP_FORWARD)}
                    label={translate('SCREEN_PLAYER_SKIP_FWD_BUTTON')}
                >
                    <PlayerButton data-test-id={'player-skip-forward-button'}>
                        <JumpButton
                            jumpValue={config.app_config.player_settings.skip_forward_interval}
                            icon={<StyledSVGInline svg={icons.iconJumpForward} />}
                            isForward={true}
                            doJump={jump}
                            getMaxJump={getMaxJumpValue}
                        />
                    </PlayerButton>
                </ControlButton>
            </>
        );
    };

    const rightDesktopControls = () => {
        return (
            <>
                <ControlButton
                    key={PlayerControl.MORE_MEDIA}
                    render={controls.includes(PlayerControl.MORE_MEDIA)}
                    isEnabled={!disabledControls.includes(PlayerControl.MORE_MEDIA)}
                >
                    <PlayerButton>
                        <StyledSVGInline svg={icons.moreMediaIcon} />
                    </PlayerButton>
                </ControlButton>
                <ControlButton
                    key={PlayerControl.NEXT_ASSET}
                    render={controls.includes(PlayerControl.NEXT_ASSET)}
                    label={translate('HINTS_BUTTON_NEXT')}
                    isEnabled={!disabledControls.includes(PlayerControl.NEXT_ASSET)}
                >
                    <PlayerButton key={'next'}>
                        <StyledSVGInline svg={icons.nextAssetIcon} />
                    </PlayerButton>
                </ControlButton>
                <ControlButton
                    key={PlayerControl.SUBTITLE}
                    render={!forceExcludedControls.includes(PlayerControl.SUBTITLE) && controls.includes(PlayerControl.SUBTITLE)}
                    label={translate('SCREEN_PLAYER_SETTINGS_BUTTON')}
                    isEnabled={!disabledControls.includes(PlayerControl.SUBTITLE)}
                >
                    <PlayerButton data-test-id={'player-settings-button'}>{actionPopup?.[PlayerControl.SUBTITLE]}</PlayerButton>
                </ControlButton>
                <VolumeControlButton>
                    <ControlButton
                        key={PlayerControl.VOLUME}
                        render={controls.includes(PlayerControl.VOLUME)}
                        label={translate('SCREEN_PLAYER_VOLUME_BUTTON')}
                        isEnabled={true}
                    >
                        <PlayerButton
                            data-test-id={'player-volume-button'}
                            className={translate('SCREEN_PLAYER_VOLUME_BUTTON')}
                            onClick={onVolumeClicked}
                        >
                            <StyledSVGInline svg={getVolumeIcon()} />
                        </PlayerButton>
                        <VolumeControlHoveringWrapper>
                            <VolumeControl onVolumeInput={onVolumeInput} onVolumeBarCreated={onVolumeBarCreated} />
                        </VolumeControlHoveringWrapper>
                    </ControlButton>
                </VolumeControlButton>
                <ControlButton
                    key={PlayerControl.FULL_SCREEN}
                    render={controls.includes(PlayerControl.FULL_SCREEN)}
                    isEnabled={!disabledControls.includes(PlayerControl.FULL_SCREEN)}
                    label={translate('SCREEN_PLAYER_FULLSCREEN_BUTTON')}
                    onClick={onFullScreenToggleClicked}
                >
                    <PlayerButton data-test-id={'player-fullscreen-button'} key={'fullscreen'}>
                        <StyledSVGInline
                            data-test-id={isFullScreen ? 'player-fullscreen-icon-true' : 'player-fullscreen-icon-false'}
                            svg={isFullScreen ? icons.shrinkScreenIcon : icons.fullScreenIcon}
                        />
                    </PlayerButton>
                </ControlButton>
            </>
        );
    };

    const leftDesktopControls = () => {
        return (
            <>
                <ControlButton
                    key={PlayerControl.RECORDING}
                    render={!forceExcludedControls.includes(PlayerControl.RECORDING) && controls.includes(PlayerControl.RECORDING)}
                    label={translate('SCREEN_PLAYER_RECORD_BUTTON')}
                    isEnabled={!disabledControls.includes(PlayerControl.RECORDING)}
                >
                    <PlayerButton data-test-id={'player-button-record'}>{actionPopup?.[PlayerControl.RECORDING]}</PlayerButton>
                </ControlButton>
                <ControlButton
                    key={PlayerControl.GO_TO_LIVE}
                    render={controls.includes(PlayerControl.GO_TO_LIVE)}
                    isEnabled={!disabledControls.includes(PlayerControl.GO_TO_LIVE)}
                    label={translate('SCREEN_PLAYER_JUMP_TO_LIVE_BUTTON')}
                    onClick={onJumpToLiveClicked}
                >
                    <PlayerButton>
                        <StyledSVGInline svg={icons.icoGoToLiveProgram} />
                    </PlayerButton>
                </ControlButton>
                <ControlButton
                    key={PlayerControl.START_OVER}
                    label={translate('SCREEN_PLAYER_STARTOVER_BUTTON')}
                    render={controls.includes(PlayerControl.START_OVER)}
                    isEnabled={!disabledControls.includes(PlayerControl.START_OVER)}
                    className={'start-over'}
                    onClick={onStartOverClicked}
                >
                    <PlayerButton>
                        <StyledSVGInline data-test-id={'player-startover-button'} svg={icons.icoStartOver} />
                    </PlayerButton>
                </ControlButton>
            </>
        );
    };

    const middleDesktopControls = () => {
        return (
            <>
                <ControlButton
                    key={PlayerControl.JUMP_BACK}
                    render={controls.includes(PlayerControl.JUMP_BACK)}
                    isEnabled={!disabledControls.includes(PlayerControl.JUMP_BACK)}
                    label={translate('SCREEN_PLAYER_SKIP_BWD_BUTTON')}
                >
                    <PlayerButton data-test-id={'player-skip-back-button'}>
                        <JumpButton
                            jumpValue={config.app_config.player_settings.skip_back_interval}
                            icon={<StyledSVGInline svg={icons.iconJumpBackwardStrong} />}
                            isForward={false}
                            doJump={jump}
                            getMaxJump={getMaxJumpValue}
                        />
                    </PlayerButton>
                </ControlButton>
                <ControlButton
                    key={PlayerControl.PLAY_PAUSE}
                    label={translate(isPlaying ? 'SCREEN_PLAYER_PAUSE_BUTTON' : 'SCREEN_PLAYER_PLAY_BUTTON')}
                    render={controls.includes(PlayerControl.PLAY_PAUSE)}
                    isEnabled={!disabledControls.includes(PlayerControl.PLAY_PAUSE)}
                    onClick={onPlayPauseClicked}
                >
                    <PlayerButton data-test-id={'player-play-pause-button'}>
                        <StyledSVGInline svg={isPlaying ? icons.pauseIconSimple : icons.playIconSimple} />
                    </PlayerButton>
                </ControlButton>
                <ControlButton
                    key={PlayerControl.JUMP_FORWARD}
                    render={controls.includes(PlayerControl.JUMP_FORWARD)}
                    isEnabled={!disabledControls.includes(PlayerControl.JUMP_FORWARD)}
                    label={translate('SCREEN_PLAYER_SKIP_FWD_BUTTON')}
                >
                    <PlayerButton data-test-id={'player-skip-forward-button'}>
                        <JumpButton
                            jumpValue={config.app_config.player_settings.skip_forward_interval}
                            icon={<StyledSVGInline svg={icons.iconJumpForwardStrong} />}
                            isForward={true}
                            doJump={jump}
                            getMaxJump={getMaxJumpValue}
                        />
                    </PlayerButton>
                </ControlButton>
            </>
        );
    };

    const mobileHeaderControls = () => {
        if (isScrubberValueShown) {
            return null;
        }
        return (
            <>
                <ControlButton
                    key={PlayerControl.RECORDING}
                    render={!forceExcludedControls.includes(PlayerControl.RECORDING) && controls.includes(PlayerControl.RECORDING)}
                    isEnabled={!disabledControls.includes(PlayerControl.RECORDING)}
                    label={translate('SCREEN_PLAYER_RECORD_BUTTON')}
                >
                    <PlayerButton onClick={() => setActiveControlGroup(ControlGroup.RECORDING)}>
                        <StyledSVGInline svg={icons.icoRecord} />
                    </PlayerButton>
                </ControlButton>
                <ControlButton
                    key={PlayerControl.GO_TO_LIVE}
                    render={controls.includes(PlayerControl.GO_TO_LIVE)}
                    isEnabled={!disabledControls.includes(PlayerControl.GO_TO_LIVE)}
                    label={translate('SCREEN_PLAYER_JUMP_TO_LIVE_BUTTON')}
                    onClick={onJumpToLiveClicked}
                >
                    <PlayerButton>
                        <StyledSVGInline svg={icons.icoGoToLiveProgram} />
                    </PlayerButton>
                </ControlButton>
                <ControlButton
                    key={PlayerControl.START_OVER}
                    label={translate('SCREEN_PLAYER_STARTOVER_BUTTON')}
                    render={controls.includes(PlayerControl.START_OVER)}
                    isEnabled={!disabledControls.includes(PlayerControl.START_OVER)}
                    className={'start-over'}
                    onClick={onStartOverClicked}
                >
                    <PlayerButton>
                        <StyledSVGInline data-test-id={'player-startover-button'} svg={icons.icoStartOver} />
                    </PlayerButton>
                </ControlButton>
                <ControlButton
                    key={PlayerControl.CAST}
                    render={!forceExcludedControls.includes(PlayerControl.CAST) && controls.includes(PlayerControl.CAST)}
                    isEnabled={!disabledControls.includes(PlayerControl.CAST)}
                    label={translate('SCREEN_PLAYER_CAST_BUTTON')}
                    onClick={onCastClicked}
                >
                    <PlayerButton>
                        <StyledSVGInline svg={castConnected ? icons.castConnectedIcon : icons.castIcon} />
                    </PlayerButton>
                </ControlButton>
                <ControlButton
                    key={PlayerControl.SUBTITLE}
                    render={!forceExcludedControls.includes(PlayerControl.SUBTITLE) && controls.includes(PlayerControl.SUBTITLE)}
                    isEnabled={!disabledControls.includes(PlayerControl.SUBTITLE)}
                    label={translate('SCREEN_PLAYER_AUDIO_BUTTON')}
                >
                    <PlayerButton onClick={() => setActiveControlGroup(ControlGroup.AUDIO_SUBTITLE)}>
                        <StyledSVGInline svg={icons.icoSettings} />
                    </PlayerButton>
                </ControlButton>
            </>
        );
    };

    const onExtraControlsHovered = () => {
        clearTimeout(extraControlsLeaveTimeout.current);
        extraControlsLeaveTimeout.current = setTimeout(() => {
            if (activeControlGroup === ControlGroup.BASE && !isMobileDevice && extraControls != null) {
                setActiveControlGroup(ControlGroup.EXTRA);
            }
        }, 250);
    };

    const closeExtraControls = () => {
        setActiveControlGroup(ControlGroup.BASE);
    };

    const onExtraControlsLeave = () => {
        clearTimeout(extraControlsLeaveTimeout.current);
        extraControlsLeaveTimeout.current = setTimeout(() => {
            closeExtraControls();
        }, 500);
    };

    const onSwipeUp = () => {
        if (activeControlGroup === ControlGroup.BASE && extraControls != null) {
            setActiveControlGroup(ControlGroup.EXTRA);
        }
    };

    const onSwipeDown = () => {
        closeExtraControls();
    };

    useEffect(() => {
        if (volumeBar.current && volumeValue.current) {
            const displayValue = isMuted ? 0 : volume;

            volumeBar.current.value = displayValue;
            volumeValue.current.style.width = `${displayValue * 100}%`;
        }
    }, [volume, isMuted, volumeBar.current, volumeValue.current]);

    useEffect(() => {
        if (showThumbnail) {
            availableThumbnail.current = true;
            getDataForThumbnail(0);
        }
    }, [showThumbnail]);

    useEffect(() => {
        isLoaded.current = loaded;
        if (progressUpdateIntervalId.current) {
            clearInterval(progressUpdateIntervalId.current);
        }
        progressUpdateIntervalId.current = setInterval(updateTimeAndSeekRange, PROGRESS_UPDATE_INTERVAL);
    }, [title, loaded, playingMode]);

    useEffect(() => {
        return () => {
            clearInterval(progressUpdateIntervalId.current);
            clearTimeout(extraControlsLeaveTimeout.current);
        };
    }, []);

    const handlePlayerKeyboardControl = (event: KeyboardEvent) => {
        switch (event.code) {
            case PlayerKeyboardControl.SPACE:
                (() => {
                    if (!isEmbedded) {
                        interactionTracking({
                            'data-track-id': 'player_play_pause',
                        });

                        setPlayingState(!playing.current);
                    }
                })();
                break;
            case PlayerKeyboardControl.LEFTARROW:
                jump(-config.app_config.player_settings.skip_back_interval);
                break;
            case PlayerKeyboardControl.RIGHTARROW:
                jump(config.app_config.player_settings.skip_forward_interval);
                break;
            case PlayerKeyboardControl.F:
                if (!isEmbedded) {
                    toggleFullScreen();
                }
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        const orientationChangeHandler = () => {
            setTimeout(() => {
                if (isOrientationLandscape() && isEmbedded && isMobileDevice && asset) {
                    history.push(getLivePlayerURL((asset as LivePlayerAssetInfo).channelId));
                }
            }, 200);
        };

        window.addEventListener('keyup', handlePlayerKeyboardControl);
        window.addEventListener('orientationchange', orientationChangeHandler);
        if (!isEmbedded) {
            fullScreenContainerRef.current?.addEventListener('dblclick', toggleFullScreen);
        }

        return () => {
            window.removeEventListener('keyup', handlePlayerKeyboardControl);
            fullScreenContainerRef.current?.removeEventListener('dblclick', toggleFullScreen);
            window.removeEventListener('orientationchange', orientationChangeHandler);
        };
    }, [isEmbedded, isMobileDevice, asset]);

    useEffect(() => {
        playing.current = isPlaying;
    }, [isPlaying]);

    return (
        <>
            {isOverlayActive && (
                <DetailMoreModal
                    modalTitle={title}
                    modalText={description}
                    showMoreMetadataTitle={getMetaData(moreOverlayMetaData?.firstLine)}
                    showMoreMetadataContent={getMetaData(moreOverlayMetaData?.secondLine)}
                    metadata={getMetaData(metaData)}
                    cast={cast?.filter(person => person.role === PersonRole.ACTOR)}
                    directors={cast?.filter(person => person.role === PersonRole.DIRECTOR)}
                    original={originalLanguages}
                    caption={captionLanguages}
                    closeOverlay={() => closeOverlay()}
                />
            )}
            <PlayerControls isHidden={shouldHideLayout} activeControlGroup={activeControlGroup} ref={ref => onControlsRef(ref)}>
                <>
                    <FullScreenContainer ref={fullScreenContainerRef} />
                    {!isMobile() && !isEmbedded && (
                        <DesktopHeaderControls key={'desktop-header-controls'}>
                            <DesktopHeaderControlsInnerContainer>
                                {!playerError && (
                                    <ControlButton
                                        key={PlayerControl.INFO}
                                        render={controls.includes(PlayerControl.INFO)}
                                        label={translate('SCREEN_PLAYER_INFO_BUTTON')}
                                        isEnabled={!disabledControls.includes(PlayerControl.INFO)}
                                        onClick={openOverlay}
                                    >
                                        <PlayerButton>
                                            <StyledSVGInline svg={icons.infoIcon} />
                                        </PlayerButton>
                                    </ControlButton>
                                )}
                                <ControlButton
                                    key={PlayerControl.CAST}
                                    render={!forceExcludedControls.includes(PlayerControl.CAST) && controls.includes(PlayerControl.CAST)}
                                    label={translate('SCREEN_PLAYER_CAST_BUTTON')}
                                    isEnabled={!disabledControls.includes(PlayerControl.CAST)}
                                    onClick={onCastClicked}
                                >
                                    <PlayerButton>
                                        <StyledSVGInline
                                            data-test-id={'player-cast-button'}
                                            svg={castConnected ? icons.castConnectedIcon : icons.castIcon}
                                        />
                                    </PlayerButton>
                                </ControlButton>
                                {!playerError && (
                                    <MinimizeButton
                                        data-test-id={'player-back-button'}
                                        onClick={() => {
                                            onPlayerCloseClicked();
                                        }}
                                    >
                                        <StyledSVGInline svg={icons.icoMinimize} />
                                    </MinimizeButton>
                                )}
                                <CloseButton
                                    onClick={() => {
                                        onPlayerCloseClicked(true);
                                    }}
                                >
                                    <StyledSVGInline svg={icons.closeIcon} />
                                </CloseButton>
                            </DesktopHeaderControlsInnerContainer>
                        </DesktopHeaderControls>
                    )}

                    {isEmbedded && (
                        <HeaderEmbeddedControls>
                            <VolumeEmbeddedContainer>
                                <ControlButton
                                    key={PlayerControl.VOLUME}
                                    render={controls.includes(PlayerControl.VOLUME)}
                                    label={translate('SCREEN_PLAYER_VOLUME_BUTTON')}
                                    isEnabled={true}
                                >
                                    <VolumeEmbeddedWrapper
                                        onMouseEnter={() => setIsVolumeHovered(true)}
                                        onMouseLeave={() => setIsVolumeHovered(false)}
                                    >
                                        <VolumeControlButton>
                                            <PlayerButton
                                                data-test-id={'player-volume-button'}
                                                className={translate('SCREEN_PLAYER_VOLUME_BUTTON')}
                                                onClick={onVolumeClicked}
                                            >
                                                <StyledSVGInline svg={getVolumeIcon()} />
                                            </PlayerButton>
                                            <VolumeControlEmbedded isVisible={isVolumeHovered}>
                                                <VolumeControl onVolumeInput={onVolumeInput} onVolumeBarCreated={onVolumeBarCreated} />
                                            </VolumeControlEmbedded>
                                        </VolumeControlButton>
                                    </VolumeEmbeddedWrapper>
                                </ControlButton>
                            </VolumeEmbeddedContainer>
                            {!playerError && !isMobile() && (
                                <MinimizeButton
                                    data-test-id={'player-back-button'}
                                    onClick={() => {
                                        setIsEmbedded(false);
                                        switchToMiniPlayer({
                                            params: {
                                                channelId: (asset as LivePlayerAssetInfo).channelId,
                                            },
                                            type: (asset as LivePlayerAssetInfo).type,
                                        });
                                    }}
                                >
                                    <StyledSVGInline svg={icons.icoMinimize} />
                                </MinimizeButton>
                            )}
                            {!isIphone() && !playerError && (
                                <ControlButton
                                    key={PlayerControl.FULL_SCREEN}
                                    render={controls.includes(PlayerControl.FULL_SCREEN)}
                                    isEnabled={!disabledControls.includes(PlayerControl.FULL_SCREEN)}
                                    className={'fullscren-btn'}
                                >
                                    <PlayerButton
                                        onClick={() => {
                                            const liveURL = getLivePlayerURL((asset as LivePlayerAssetInfo).channelId);
                                            const separator = liveURL.includes('?') ? '&' : '?';
                                            history.push(`${liveURL}${separator}live=true`);
                                        }}
                                    >
                                        <FullScreenToggleIcon svg={isFullScreen ? icons.shrinkScreenIcon : icons.fullScreenIcon} />
                                    </PlayerButton>
                                </ControlButton>
                            )}
                        </HeaderEmbeddedControls>
                    )}

                    {isMobile() && !isEmbedded && !isScrubberValueShown && (
                        <MobileHeaderControlsContainer key={'mobile-header-controls'}>
                            {mobileHeaderControls()}
                            {!playerError && (
                                <MinimizeButton
                                    data-test-id={'player-back-button'}
                                    onClick={() => {
                                        onPlayerCloseClicked();
                                    }}
                                >
                                    <StyledSVGInline svg={icons.icoMinimize} />
                                </MinimizeButton>
                            )}
                            <CloseButton key={PlayerControl.CLOSE} data-test-id={'player-back-button'}>
                                <StyledSVGInline svg={icons.closeIcon} onClick={onPlayerCloseClicked} />
                            </CloseButton>
                        </MobileHeaderControlsContainer>
                    )}

                    {!isEmbedded && isMobile() && !playerError && (
                        <MobileMiddleButtonsContainer key={'mobile-middle-controls'}>{mobileCenterControls()}</MobileMiddleButtonsContainer>
                    )}

                    {isEmbedded && !playerError && (
                        <EmbeddedMiddleButtonsContainer key={'embedded-middle-controls'}>
                            {embeddedCenterControls()}
                        </EmbeddedMiddleButtonsContainer>
                    )}

                    {!isScrubberValueShown && isEmbedded && !playerError && (
                        <EmbeddedMetaDataContainer>
                            <EmbeddedChanelInfoContainer>
                                {logicalChannelNumber && <ChanelNumber>{logicalChannelNumber}</ChanelNumber>}
                                {logo}
                            </EmbeddedChanelInfoContainer>
                            {title && <EmbeddedTitle data-test-id={'player-content-title'}>{title}</EmbeddedTitle>}
                            <EmbeddedPlayerMetaData>
                                <EmbeddedIconsWrapper data-test-id={'player-replay-button-icon'}>{getMetaIcons()}</EmbeddedIconsWrapper>
                                <EmbeddedMetaDataWrapper>{getMetaData(embeddedPlayerMetadata)}</EmbeddedMetaDataWrapper>
                            </EmbeddedPlayerMetaData>
                        </EmbeddedMetaDataContainer>
                    )}

                    {!isScrubberValueShown && !isEmbedded && (
                        <MetaDataContainerMobile>
                            <ChanelInfoContainer>
                                {logicalChannelNumber && <ChanelNumber>{logicalChannelNumber}</ChanelNumber>}
                                {logo}
                            </ChanelInfoContainer>
                            {title && (
                                <Title data-test-id={'player-content-title'} onClick={openOverlay}>
                                    {title}
                                    <MoreInfoArrow>
                                        <StyledSVGInline svg={icons.rightArrowIconSmall} />
                                    </MoreInfoArrow>
                                </Title>
                            )}
                            {subtitle && <Subtitle data-test-id={'player-content-subtitle'}>{subtitle}</Subtitle>}
                            <PlayerMetaData>
                                {contentMarker && <ContentMarker marker={contentMarker}>{contentMarker.value}</ContentMarker>}
                                <IconsWrapper data-test-id={'player-replay-button-icon'}>{getMetaIcons()}</IconsWrapper>
                                <MetaDataWrapper>{getMetaData(metaData)}</MetaDataWrapper>
                                {subtitle && <MetadataSubtitle data-test-id={'player-content-subtitle'}>{subtitle}</MetadataSubtitle>}
                            </PlayerMetaData>
                        </MetaDataContainerMobile>
                    )}

                    <Swipeable onSwipedUp={onSwipeUp} onSwipedDown={onSwipeDown}>
                        <FooterControls
                            isEmbedded={isEmbedded}
                            data-test-id={'footer-controls'}
                            hasExtraControls={extraControls != null}
                            activeControlGroup={activeControlGroup}
                            withAnimation={true}
                            hasOpacity={loading || !loaded || (!isSeeking && isBuffering)}
                            isMobile={isMobile()}
                        >
                            {castConnected && deviceName && (
                                <CastingLabel>{translate('FEATURE_CAST_SENDER_HINT', { receiver_name: deviceName })}</CastingLabel>
                            )}
                            {!isScrubberValueShown && !isEmbedded && (
                                <MetaDataContainer>
                                    <ChanelInfoContainer>
                                        {logicalChannelNumber && <ChanelNumber>{logicalChannelNumber}</ChanelNumber>}
                                        {logo}
                                    </ChanelInfoContainer>
                                    {title && <Title data-test-id={'player-content-title'}>{title}</Title>}
                                    {subtitle && <Subtitle data-test-id={'player-content-subtitle'}>{subtitle}</Subtitle>}
                                    <PlayerMetaData>
                                        {contentMarker && <ContentMarker marker={contentMarker}>{contentMarker.value}</ContentMarker>}
                                        <IconsWrapper data-test-id={'player-replay-button-icon'}>{getMetaIcons()}</IconsWrapper>
                                        <MetaDataWrapper>{getMetaData(metaData)}</MetaDataWrapper>
                                        {subtitle && (
                                            <MetadataSubtitle data-test-id={'player-content-subtitle'}>{subtitle}</MetadataSubtitle>
                                        )}
                                    </PlayerMetaData>
                                </MetaDataContainer>
                            )}
                            <SeekBarHolder isEmbedded={isEmbedded}>
                                <SeekBar
                                    enabled={seekEnabled && !playerError}
                                    onSeekInput={onSeekInput}
                                    onSeekStart={onSeekStart}
                                    onSeekEnd={onSeekEnd}
                                    onSeekBarCreated={onSeekBarCreated}
                                />
                            </SeekBarHolder>
                            {!isMobile() && !isEmbedded && !playerError && (
                                <DesktopButtonsContainer>
                                    <ButtonsInnerContainer key={'left-container'}>{leftDesktopControls()}</ButtonsInnerContainer>
                                    <ButtonsInnerContainer key={'center-container'}>{middleDesktopControls()}</ButtonsInnerContainer>
                                    <ButtonsInnerContainer key={'right-container'}>{rightDesktopControls()}</ButtonsInnerContainer>
                                </DesktopButtonsContainer>
                            )}

                            {extraControls != null && (
                                <ExtraControlsWrapper onMouseEnter={onExtraControlsHovered} onMouseLeave={onExtraControlsLeave}>
                                    {activeControlGroup === ControlGroup.EXTRA && (
                                        <CloseExtraControls onClick={closeExtraControls}>
                                            <StyledSVGInline svg={icons.downArrowIcon} />
                                        </CloseExtraControls>
                                    )}
                                    {extraControls}
                                </ExtraControlsWrapper>
                            )}
                        </FooterControls>
                    </Swipeable>
                </>
            </PlayerControls>

            {isMobile() && <>{mobileOverlayActionHandlers}</>}
            <BufferingIndicator buffering={(loading || !loaded || isBuffering) && !playerError} />
        </>
    );
};
