import shaka from 'shaka-player';
import axios, { Canceler } from 'axios';
import { deviceType } from 'react-device-detect';
import { IDRM } from './abstract/IDRM';
import localConfig from '../../config/localConfig';
import ApplicationConfig from '../useConfig/ApplicationConfig';
import { isSafari } from '../../utils/fnDevices';
import { FetchResponse } from '../../types/ApiTypes';
import { DRMToken, RegisterDeviceResponse } from '../../types/AuthTypes';
import { generateUUID } from '../../utils/fnData';
import { RegisterDevice } from '../../types/CommonTypes';
import { SECONDS } from '../../utils/TimeUnit';
import { INACTIVE_DEVICE, STREAM_LIMIT } from './playerConstants';

class DRM extends IDRM {
    private drmType: string;

    private contentType: string;

    private deviceId: string;

    private token: string;

    private errorHandler: (error: any) => void;

    private tokenRequestCanceler: Canceler;

    private keepAliveIntervalRef;

    private keepAliveInterval: number;

    private licenseServerUrl: string;

    private registerDevice: (url: string, payload: RegisterDevice) => Promise<FetchResponse<RegisterDeviceResponse>> = null;

    private requestToken: (url: string, method: 'POST' | 'PUT', payload: string, canceller?) => Promise<FetchResponse<DRMToken>> = null;

    private closeSession: (url: string, payload: string, canceller?) => Promise<FetchResponse<any>> = null;

    private deviceRegistrationCallback: (deviceId: string, error) => void = null;

    subscribeErrorHandler = (handler: (error: any) => void) => {
        this.errorHandler = handler;
    };

    private getOrCreateCxDeviceId = drmClientType => {
        const { DEVICE_ID_STORAGE } = localConfig.drm ?? {};

        if (typeof Storage !== 'undefined') {
            let cxDeviceId = localStorage.getItem(DEVICE_ID_STORAGE + drmClientType);

            if (cxDeviceId == null) {
                cxDeviceId = generateUUID();
                localStorage.setItem(DEVICE_ID_STORAGE + drmClientType, cxDeviceId);
            }
            return cxDeviceId;
        }

        return generateUUID();
    };

    private addDevice = async () => {
        const { DEVICE_TYPE } = localConfig.drm ?? {};

        if (this.registerDevice) {
            const addDeviceRequest = await this.registerDevice(ApplicationConfig.api_config.routes.DRM.register_device, {
                device_type: DEVICE_TYPE,
                friendly_name: DEVICE_TYPE,
                uuid: this.deviceId,
                screen_type: deviceType === 'browser' ? 'computer' : deviceType,
            });

            if (this.deviceRegistrationCallback && addDeviceRequest) {
                this.deviceRegistrationCallback(addDeviceRequest.response?.device_id, addDeviceRequest);
            }
        } else {
            console.warn('Missing register device handler!');
        }
    };

    private stringToArray = string => {
        const buffer = new ArrayBuffer(string.length * 2); // 2 bytes for each char
        const array = new Uint16Array(buffer);
        for (let i = 0, strLen = string.length; i < strLen; i += 1) {
            array[i] = string.charCodeAt(i);
        }
        return array;
    };

    handleWidevine = () => {
        const { DEVICE_TYPE, WIDEVINE } = localConfig.drm ?? {};

        this.drmType = WIDEVINE;
        this.contentType = 'application/dash+xml';
        this.deviceId = this.getOrCreateCxDeviceId(DEVICE_TYPE);
        this.licenseServerUrl = ApplicationConfig.api_config.routes.DRM.license_server_widevine;

        this.addDevice().catch(() => {});
    };

    handlePlayReady = () => {
        const { DEVICE_TYPE, PLAYREADY } = localConfig.drm ?? {};

        this.drmType = PLAYREADY;
        this.contentType = 'application/dash+xml';
        this.deviceId = this.getOrCreateCxDeviceId(DEVICE_TYPE);
        this.licenseServerUrl = ApplicationConfig.api_config.routes.DRM.license_server_playready;

        this.addDevice().catch(() => {});
    };

    handleFairPlay = () => {
        const { DEVICE_TYPE, FAIRPLAY } = localConfig.drm ?? {};

        this.drmType = FAIRPLAY;
        this.contentType = 'application/x-mpegurl';
        this.deviceId = this.getOrCreateCxDeviceId(DEVICE_TYPE);
        this.licenseServerUrl = ApplicationConfig.api_config.routes.DRM.license_server_fairplay;

        this.addDevice().catch(() => {});
    };

    release = () => {
        if (this.tokenRequestCanceler) {
            this.tokenRequestCanceler();
        }

        this.stopKeepAlive();
    };

    stopKeepAlive = () => {
        clearInterval(this.keepAliveIntervalRef);

        if (this.closeSession) {
            this.closeSession(ApplicationConfig.api_config.routes.DRM.heartbeat, this.deviceId, null).catch(() => {});
        }
    };

    keepAlive = () => {
        clearInterval(this.keepAliveIntervalRef);

        if (this.keepAliveInterval && this.requestToken) {
            this.keepAliveIntervalRef = setInterval(async () => {
                const keepAliveResponse = await this.requestToken(
                    ApplicationConfig.api_config.routes.DRM.heartbeat,
                    'PUT',
                    this.deviceId,
                    new axios.CancelToken(executor => {
                        this.tokenRequestCanceler = executor;
                    })
                );

                if (keepAliveResponse?.error) {
                    this.stopKeepAlive();
                    // eslint-disable-next-line no-throw-literal
                    this.errorHandler({
                        code: keepAliveResponse?.error?.response?.data?.status,
                        message: keepAliveResponse?.error?.response?.data?.error,
                        details: keepAliveResponse?.error?.response?.data?.details,
                    });
                }
            }, SECONDS.toMillis(this.keepAliveInterval));
        }
    };

    getToken = async (deviceToReplace: string = null) => {
        if (this.requestToken) {
            const tokenResponse = await this.requestToken(
                ApplicationConfig.api_config.routes.DRM.get_drm_token,
                'POST',
                JSON.stringify({ uuid: this.deviceId, uuid_to_replace: deviceToReplace }),
                new axios.CancelToken(executor => {
                    this.tokenRequestCanceler = executor;
                })
            );

            if (tokenResponse?.response) {
                this.token = tokenResponse.response?.authentication_data;
                this.keepAliveInterval = tokenResponse.response?.interval ?? 120;

                await this.keepAlive();
            }
            if (tokenResponse?.error) {
                if (tokenResponse?.error.response.data.error === INACTIVE_DEVICE) {
                    // eslint-disable-next-line no-throw-literal
                    throw {
                        code: tokenResponse?.status,
                        message: INACTIVE_DEVICE,
                    };
                } else if (tokenResponse?.error.response.data.error === STREAM_LIMIT) {
                    // eslint-disable-next-line no-throw-literal
                    throw {
                        code: tokenResponse?.status,
                        message: STREAM_LIMIT,
                    };
                } else {
                    // eslint-disable-next-line no-throw-literal
                    throw {
                        code: tokenResponse?.status,
                        message: tokenResponse.error.message,
                    };
                }
            }

            return null;
        }

        console.warn('Missing request token handler!');
        return null;
    };

    detectSupport = () => {
        const { KEY_SYSTEM_PLAYREADY, KEY_SYSTEM_WIDEVINE } = localConfig.drm;

        if (navigator.requestMediaKeySystemAccess !== undefined && !isSafari()) {
            // eslint-disable-next-line
            if (window.Cypress) {
                return this.handleWidevine();
            }

            const widevineOptions = [
                {
                    initDataTypes: ['cenc'],
                    videoCapabilities: [{ contentType: 'video/mp4;codecs="avc1.4d401e"' }],
                },
            ];

            navigator
                .requestMediaKeySystemAccess(KEY_SYSTEM_WIDEVINE, widevineOptions)
                .then(this.handleWidevine)
                .catch(() => {});
            navigator
                .requestMediaKeySystemAccess(KEY_SYSTEM_PLAYREADY, [{}])
                .then(this.handlePlayReady)
                .catch(() => {});
        } else if (isSafari()) {
            this.handleFairPlay();
        } else {
            console.error('Not supported DRM');
        }
    };

    getConfiguration = () => {
        const { KEY_SYSTEM_PLAYREADY, KEY_SYSTEM_WIDEVINE, KEY_FAIRPLAY } = localConfig.drm;

        return {
            drm: {
                servers: {
                    [KEY_SYSTEM_WIDEVINE]: this.licenseServerUrl,
                    [KEY_SYSTEM_PLAYREADY]: this.licenseServerUrl,
                    [KEY_FAIRPLAY]: this.licenseServerUrl,
                },
            },
        };
    };

    getAdditionalConfiguration = async (): Promise<{ [p: string]: any }> => {
        if (!isSafari()) {
            return {};
        }

        const req = await fetch(ApplicationConfig.api_config.routes.DRM.fairplay_cert_url);
        const cert = await req.arrayBuffer();

        return {
            'drm.advanced.com\\.apple\\.fps\\.1_0.serverCertificate': new Uint8Array(cert),
            'drm.initDataTransform': (initData, initDataType) => {
                if (initDataType !== 'skd') {
                    return initData;
                }

                const skdUri = shaka.util.StringUtils.fromBytesAutoDetect(initData);
                const skdValue = skdUri.split('skd://')[1].split('?')[0];
                const skdValueAsBytes = this.stringToArray(window.atob(skdValue));

                return shaka.util.FairPlayUtils.initDataTransform(initData, skdValueAsBytes, cert);
            },
        };
    };

    getHeaders = () => {
        const { DEVICE_TYPE, CUSTOM_HEADER_KEY } = localConfig.drm;

        return {
            [CUSTOM_HEADER_KEY]: JSON.stringify({
                Version: '1.0.0',
                CxAuthenticationDataToken: this.token,
                CxClientInfo: {
                    DeviceType: DEVICE_TYPE,
                    DrmClientType: `${this.drmType}-HTML5`,
                    DrmClientVersion: '1.0.0',
                    CxDeviceId: this.deviceId,
                },
            }),
        };
    };

    getResponseFilter = (type, response): void => {
        if (type !== shaka.net.NetworkingEngine.RequestType.LICENSE) {
            return;
        }

        if (isSafari()) {
            let responseText = shaka.util.StringUtils.fromUTF8(response.data);
            responseText = responseText.trim();
            response.data = shaka.util.Uint8ArrayUtils.fromBase64(JSON.parse(responseText).CkcMessage).buffer;
        }
    };

    getRequestFilter = (type, request): void => {
        if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
            request.headers = this.getHeaders();
        }
    };

    getDeviceId = () => {
        const { DEVICE_TYPE } = localConfig.drm ?? {};
        this.deviceId = this.getOrCreateCxDeviceId(DEVICE_TYPE);
        return this.deviceId;
    };

    configurePlayer = async (playerInstance: any) => {
        await this.getToken();

        const additionalConfig = await this.getAdditionalConfiguration();
        const configKeys = Object.keys(additionalConfig);

        if (configKeys.length) {
            configKeys.forEach(key => playerInstance.configure(key, additionalConfig[key]));
        }

        playerInstance.configure(this.getConfiguration());
        playerInstance.getNetworkingEngine().registerRequestFilter(this.getRequestFilter);
        playerInstance.getNetworkingEngine().registerResponseFilter(this.getResponseFilter);
    };

    getDRMType = () => this.drmType;

    init = (
        registerDevice: (url: string, payload: RegisterDevice) => Promise<FetchResponse<RegisterDeviceResponse>>,
        requestToken: (url: string, method: 'POST' | 'PUT', payload: string, canceller?) => Promise<FetchResponse<DRMToken>>,
        closeSession: (url: string, payload: string, canceller?) => Promise<FetchResponse<any>>,
        deviceRegistrationCb: (deviceId: string, error) => void
    ) => {
        this.registerDevice = registerDevice;
        this.requestToken = requestToken;
        this.closeSession = closeSession;
        this.deviceRegistrationCallback = deviceRegistrationCb;

        this.detectSupport();
    };
}

export default new DRM();
