import React, { useEffect, useRef } from 'react';
import { getLocalStorage } from '../../utils/fnStorage';
import { VolumeBar, VolumeControlContainer, VolumeScrubber, VolumeValue } from './VolumeControl.css';

type VolumeControlProps = {
    onVolumeBarCreated: (object: VolumeControlElements) => void;
    onVolumeInput: (e: any) => void;
};

export type VolumeControlElements = {
    volumeValue: HTMLDivElement;
    volumeBar: HTMLInputElement;
};

export const VolumeControl: React.FC<VolumeControlProps> = ({ onVolumeBarCreated, onVolumeInput }) => {
    const volumeValueRef = useRef<HTMLDivElement>(null);
    const volumeBarRef = useRef<HTMLInputElement>(null);

    let storedVolumeState = {
        level: 0,
        muted: false,
    };

    useEffect(() => {
        const volumeValue = volumeValueRef && volumeValueRef.current ? volumeValueRef.current : null;
        const volumeBar = volumeBarRef && volumeBarRef.current ? volumeBarRef.current : null;

        onVolumeBarCreated({ volumeValue, volumeBar });
    }, [volumeValueRef.current, volumeBarRef.current]);

    useEffect(() => {
        storedVolumeState = getLocalStorage('playerVolume');

        if (volumeBarRef && volumeBarRef.current) {
            volumeBarRef.current.addEventListener('input', onVolumeInput);
        }

        return () => {
            if (volumeBarRef.current) {
                volumeBarRef.current.removeEventListener('input', onVolumeInput);
            }
        };
    }, []);

    return (
        <VolumeControlContainer data-test-id={'player-volume-slider'}>
            <VolumeScrubber>
                <VolumeValue data-test-id={'player-volume-value'} ref={volumeValueRef} />
                <VolumeBar type={'range'} step={'any'} min={0} max={1} defaultValue={storedVolumeState.level / 100} ref={volumeBarRef} />
            </VolumeScrubber>
        </VolumeControlContainer>
    );
};
