import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { Breakpoints, MQ } from '../../style/styled-components/cssMediaQueries';

export const PageScrollerWrapper = styled.div<{ isIOS: boolean }>`
    // this hack needs to be applied because of the native bottom menu bar on the iOS devices
    // if you remove it please also remove it from the EpgWrapper also
    padding-bottom: ${props => (props.isIOS ? '99px' : '84px')};

    ${MQ(Breakpoints.m)} {
        padding-bottom: 0;
    }
`;
