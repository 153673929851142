import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold } from '../../../style/styled-components/cssGlobalVariables';
import { ButtonInner, ButtonWrapper } from '../../Buttons/Button/Button.css';

export const DevPageTitle = styled.div`
    font-size: 30px;
    text-align: center;
    font-family: ${fontBold};
`;

export const ActionButtons = styled.div`
    margin-top: 15px;
`;

export const SectionTitle = styled.h2`
    font-size: 20px;
    font-family: ${fontBold};
    margin-bottom: 15px;
`;

export const SectionLabel = styled.p`
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 22px;
`;

export const SectionInput = styled.input`
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid ${props => props.theme.brandedConfig.low_contrast};
    color: ${props => props.theme.brandedConfig.high_contrast};
    display: inline-block;
    margin-top: 5px;
    padding: 5px 0;
    margin-right: 10px;
`;

export const SectionCheckMark = styled.input`
    width: 15px;
    height: 15px;
    display: inline-block;
`;

export const PageSection = styled.div`
    margin: 20px 0;
    border-top: 1px solid ${props => props.theme.brandedConfig.low_contrast};
    padding-top: 25px;
`;

export const DevPageWrapper = styled.div`
    padding: 7px 25px;
    color: ${props => props.theme.brandedConfig.high_contrast};

    ${ButtonWrapper} {
        margin-right: 12px;
        min-width: unset;

        ${ButtonInner} {
            padding: 5px;
            min-width: unset;
        }
    }
`;
