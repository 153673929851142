import React from 'react';
import SVGInline from 'react-svg-inline';
import translate from '../../../utils/fnTranslate';
import { SearchItemWrapper, PageWrapper, DeleteIcon, SearchHistoryText, SearchHistoryTitle } from './SearchHistoryList.css';
import icons from '../../../style';

export const SearchHistoryList: React.FC<{
    searchHistory: string[];
    handleSearchHistoryClicked: (item: string) => void;
    handleDelete: (e: React.MouseEvent<any>, item: string) => void;
}> = ({ searchHistory, handleSearchHistoryClicked, handleDelete }) => {
    if (!searchHistory) return null;

    return (
        <PageWrapper>
            <SearchHistoryTitle>{translate('SCREEN_SEARCH_RECENTLY_SEARCHED')}</SearchHistoryTitle>
            {searchHistory.map(item => (
                <SearchItemWrapper
                    key={item}
                    onClick={() => {
                        handleSearchHistoryClicked(item);
                    }}
                >
                    <SVGInline svg={icons.icoSearchHistory} />
                    <SearchHistoryText>{item}</SearchHistoryText>
                    <DeleteIcon svg={icons.closeIcon} onClick={(e: React.MouseEvent<any>) => handleDelete(e, item)} />
                </SearchItemWrapper>
            ))}
        </PageWrapper>
    );
};
