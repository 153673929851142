import React, { FC } from 'react';
import { format } from 'date-fns';
import { getMiniPlayerProps } from 'utils/fnData';
import { PlayingAssetType } from 'types/Player';
import { usePlayer } from 'providers/player/PlayerContext';
import { useHistory } from 'react-router';
import { Recording } from '../../../types/Asset';
import { CardContext, StripeTypes } from '../../../types/Common';
import { CardTrackingData } from '../../../types/Tracking';
import { StandardCover } from '../../../types/Cover';
import { CardLandscape } from '../../Cards/CardLandscape/CardLandscape';
import { CardCollectionMixed } from '../../Cards/CardCollection/CardCollectionMixed';
import { CardPortrait } from '../../Cards/CardPortrait/CardPortrait';
import { CardCollectionPortrait } from '../../Cards/CardCollection/CardCollectionPortrait';
import { useBroadcastTimeCluster } from '../../../hooks/useBroadcastTimeCluster/useBroadcastTimeCluster';
import { getBroadcastDetailsURL, getRecordingDetailURL, getRecordingPlayerURL } from '../../../utils/fnUrl';
import { useDetailLocationParams } from '../../../hooks/useDetailLocationParams';
import { LiveProgressIndicator } from '../../LiveProgressIndicator/LiveProgressIndicator';
import { getBroadcastCardContentMarkerValue } from '../../../utils/fnContentMarker';
import { formatDate, isFuture } from '../../../utils/fnDate';
import { TIME_FORMAT_HH_MM } from '../../../utils/fnParser';
import { useTracking } from '../../../providers/useTracking/TrackingContext';
import { trackingClickHandler } from '../../../utils/fnTracking';
import { ProgressIndicator } from '../../ProgressIndicator/ProgressIndicator';
import { useConfig } from '../../../providers/useConfig/ConfigContext';
import { MILLISECONDS } from '../../../utils/TimeUnit';
import { CardCircle } from '../../Cards/CardCircle/CardCircle';
import { useApp } from '../../../providers/useApp/AppContext';

type PresenterPropTypes = {
    asset: Recording;
    type: StripeTypes;
    cardContext: CardContext;
    trackingData: CardTrackingData;
};

export const RecordingCardPresenter: FC<PresenterPropTypes> = ({ asset, type, cardContext, trackingData }) => {
    const { id, title, image, isPortrait, startTime, endTime, bookmark, isLive } = asset;
    const timeCluster = useBroadcastTimeCluster(asset);
    const { isMini, updateMiniPlayerProps } = usePlayer();
    const locationParamsPath = useDetailLocationParams();
    const history = useHistory();
    const track = useTracking();
    const { config } = useConfig();
    const { appLanguage } = useApp();

    let path: string;
    let renderPlayIcon = false;
    let onPlayIconClicked = null;

    // scheduled
    if (timeCluster !== 'PAST') {
        path = getBroadcastDetailsURL(asset, locationParamsPath, appLanguage);
    } else {
        path = getRecordingDetailURL(asset, locationParamsPath, appLanguage);
        renderPlayIcon = true;
        onPlayIconClicked = e => {
            e.preventDefault();
            e.stopPropagation();

            trackingClickHandler(
                e,
                () =>
                    isMini
                        ? updateMiniPlayerProps(getMiniPlayerProps(asset, PlayingAssetType.RECORDING))
                        : history.push(getRecordingPlayerURL(asset.id)),
                {
                    ...trackingData,
                    ...{
                        'data-track-id': 'image_button_play',
                    },
                },
                track
            );
        };
    }

    const metaData = [];

    metaData.push(formatDate(new Date(startTime)));
    metaData.push(`${format(startTime, TIME_FORMAT_HH_MM)} - ${format(endTime, TIME_FORMAT_HH_MM)}`);

    const progress = (bookmark * 100) / MILLISECONDS.toSeconds(endTime - startTime);

    const cover: StandardCover = {
        uuid: id + title,
        id,
        title,
        image,
        landscapeImage: image,
        path,
        metaData: metaData.join(' I '),
        circle: false,
        landscape: !isPortrait,
        assetType: asset.type,
        rawData: asset,
        contentMarker: getBroadcastCardContentMarkerValue(isLive, timeCluster),
        renderPlayIcon,
        progressBar:
            timeCluster === 'LIVE' ? (
                <LiveProgressIndicator startTime={startTime} endTime={endTime} onLiveStateChanged={null} />
            ) : (
                !isFuture(startTime) && (
                    <ProgressIndicator percentage={progress < config.app_config.player_settings.bookmark_threshold ? progress : 0} />
                )
            ),
        onPlayIconClicked,
    };

    if (type === StripeTypes.PERSON || type === StripeTypes.ROUNDED) {
        return <CardCircle asset={cover} {...trackingData} />;
    }

    if (type === StripeTypes.MOVIE || type === StripeTypes.PORTRAIT) {
        return cardContext === CardContext.STRIPE ? (
            <CardPortrait asset={cover} stripeType={type} {...trackingData} />
        ) : (
            <CardCollectionPortrait asset={cover} {...trackingData} />
        );
    }

    if (type === StripeTypes.MIXED && cardContext === CardContext.STRIPE) {
        return <CardLandscape asset={cover} {...trackingData} />;
    }

    return cardContext === CardContext.STRIPE ? (
        isPortrait ? (
            <CardPortrait asset={cover} {...trackingData} stripeType={type} />
        ) : (
            <CardLandscape asset={cover} {...trackingData} />
        )
    ) : (
        <CardCollectionMixed asset={cover} {...trackingData} />
    );
};
