import { useDetailLocationParams } from './useDetailLocationParams';
import { useCollectionLocationParams } from './useCollectionLocationParams';
import { useConfig } from '../providers/useConfig/ConfigContext';
import { FooterMenu, MainMenu } from '../types/Config';

/**
 * Hook to obtain active menu index based on the menu data and the routing rules based on router and URL
 *
 * @param pageId - active page id
 * @param menuData - header / footer data
 */
export const useMenu = (pageId: string, menuData: FooterMenu[] | MainMenu[]) => {
    const { config } = useConfig();
    const { params: detailParams, isDetailPage } = useDetailLocationParams();
    const { params: collectionParams, isCollectionPage } = useCollectionLocationParams();

    const getActiveMenuIndex = () => {
        for (let i = 0; i < menuData.length; i += 1) {
            const menu = menuData[i];
            const path = config.getPagePath(menu.id);

            if (menu.id === pageId || menu.id === config.getParentOf(pageId)) {
                return i;
            }

            if (isDetailPage && detailParams && detailParams.section) {
                if (path && detailParams.section === path.slice(1, path.length)) {
                    return i;
                }

                const parent = config.getParentByPathVar(detailParams.section);
                if (menu.id === parent) {
                    return i;
                }
            }

            if (isCollectionPage && collectionParams && collectionParams.section) {
                if (path && collectionParams.section === path.slice(1, path.length)) {
                    return i;
                }

                const parent = config.getParentByPathVar(collectionParams.section);
                if (menu.id === parent) {
                    return i;
                }
            }
        }

        return -1;
    };

    return {
        activeMenuIndex: getActiveMenuIndex(),
    };
};
