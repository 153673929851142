import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useWrapLocation } from '../useWrapRouter/useWrapLocation';

type ScreenState = {
    [key in StoreKeys]?: any;
};

// Please note, the key can be any kind of string, the predefined values are just set to have auto-complete
type StoreKeys = 'pageScroll' | 'epgVerticalScroll' | 'epgHorizontalScroll' | 'selectedEpgData' | string;

const enabled = true;
const screenState: { [key: string]: ScreenState } = {};
const locationHistory: string[] = [];

export const useScreenState = () => {
    const history = useHistory();
    const location = useWrapLocation();
    const locationKey = location?.pathname ?? 'DEFAULT_KEY';

    const storeState = (key: StoreKeys, value: any, setter?: (value: any) => void) => {
        if (!enabled) {
            return;
        }

        screenState[locationKey] = {
            ...screenState[locationKey],
            ...{
                [key]: {
                    value,
                    setter,
                },
            },
        };
    };

    const obtainState = (key: StoreKeys, forceKey?: string) => {
        const pageKey = forceKey || locationKey;
        return screenState?.[pageKey]?.[key] ?? null;
    };

    const emptyStates = (forceKey?: string) => {
        const pageKey = forceKey || locationKey;
        if (screenState?.[pageKey]) {
            screenState[pageKey] = {};
        }
    };

    const pop = (forceKey: string) => {
        const pageKey = forceKey || locationKey;

        const statesByLocation = screenState[pageKey];
        // eslint-disable-next-line no-restricted-syntax
        for (const key of Object.keys(statesByLocation)) {
            if (statesByLocation[key].setter) {
                statesByLocation[key].setter(statesByLocation?.[key]?.value);
            }
        }
    };

    useEffect(() => {
        if (!enabled) {
            return;
        }

        if (locationHistory.indexOf(locationKey) === -1) {
            screenState[locationKey] = {};
            locationHistory.push(locationKey);
        }

        if (history.action === 'POP') {
            pop(locationKey);
        }
    }, [locationKey]);

    return {
        locationKey,
        locationAction: history.action,
        storeState,
        obtainState,
        emptyStates,
    };
};
