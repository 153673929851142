export default class Expirable<T> {
    private content: T = null;

    private readonly expiresAt: number = 0;

    constructor(content: T, expiresAt: number) {
        this.content = content;
        this.expiresAt = expiresAt;
    }

    isValid = (): boolean => {
        return this.content && this.expiresAt >= Date.now();
    };

    updateContent(content: T) {
        this.content = content;
    }

    getContent = (returnIfInvalid = true): T | null => {
        if (this.isValid() || returnIfInvalid) {
            return this.content;
        }

        return null;
    };
}
