import {
    castEnabled,
    getBroadcastPlayerMoreOverlayMetaData,
    getTrailerVODPlayerActionComponents,
    getTrailerVODPlayerControls,
} from '../../utils/fnPlayerUI';
import { PlayingAsset, PropTypesAdditional, PropTypesPlayerUICommon } from '../../types/Player';
import { isTrailerPlayerAsset } from '../../utils/fnTypeGuards';
import { secondsToTime } from '../../utils/fnDate';

export const useTrailerPlayerUI = (common: PropTypesPlayerUICommon, asset: PlayingAsset): PropTypesAdditional => {
    if (!isTrailerPlayerAsset(asset)) {
        return null;
    }

    const { releaseYear, countries, title, subtitle, description, cast, duration, genres, originalLanguages } = asset;

    const getMetaData = () => {
        if (!asset) return [];
        const metaData = [];

        if (releaseYear || countries.length) {
            metaData.push(`${countries?.slice(0, 2).join(',')} ${releaseYear || ''}`);
        }

        if (genres && genres.length) {
            metaData.push(
                genres
                    ?.map(genre => genre.Value)
                    .slice(0, 2)
                    .join(', ')
            );
        }

        if (duration) {
            metaData.push(secondsToTime(duration));
        }

        return metaData;
    };

    const getControls = () => getTrailerVODPlayerControls(common.hasTracks, common.canStartOver, castEnabled());

    const controls = getControls();
    const { actionPopup, mobileOverlayActionHandlers } = getTrailerVODPlayerActionComponents(controls);

    return {
        logo: null,
        metaData: getMetaData(),
        embeddedPlayerMetadata: null,
        contentMarker: null,
        metaIcons: [],
        disabledControls: [],
        controls,
        seekEnabled: true,
        title,
        subtitle,
        actionPopup,
        mobileOverlayActionHandlers,
        moreOverlayMetaData: getBroadcastPlayerMoreOverlayMetaData(asset),
        description,
        cast,
        originalLanguages,
    };
};
