import { useEffect } from 'react';
import { PlayingAsset } from '../../types/Player';
import { isCatchupPlayerAsset, isLivePlayerAsset, isVodPlayerAsset } from '../../utils/fnTypeGuards';
import { TVAssetEntitlementState, VODAssetEntitlementState } from '../../types/Entitlement';
import { useTVAssetEntitlementState } from '../useTVAssetEntitlementState/useTVAssetEntitlementState';
import { useAuth } from '../../providers/useAuth/AuthContext';

type Callback = {
    onNotEntitled: () => void;
    onEntitled?: () => void;
};

export const usePlayerEntitlementChecker = (asset: PlayingAsset, callback: Callback) => {
    const tvCheckEntitlement = useTVAssetEntitlementState();
    const { isGuest } = useAuth();

    useEffect(() => {
        if (asset) {
            if (isLivePlayerAsset(asset)) {
                if (!asset.channelSubscribed) {
                    callback.onNotEntitled();
                } else if (callback.onEntitled) {
                    callback.onEntitled();
                }
            }

            if (isCatchupPlayerAsset(asset)) {
                if (tvCheckEntitlement(asset, asset.channelHasReplay, asset.channelSubscribed) !== TVAssetEntitlementState.ENTITLED) {
                    callback.onNotEntitled();
                } else if (callback.onEntitled) {
                    callback.onEntitled();
                }
            }

            if (isVodPlayerAsset(asset)) {
                if (asset.vodAssetState === VODAssetEntitlementState.ENTITLED) {
                    if (callback.onEntitled) {
                        callback.onEntitled();
                    }
                } else {
                    callback.onNotEntitled();
                }
            }
        }
    }, [asset, isGuest]);
};
