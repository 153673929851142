import React, { FC } from 'react';
import SVGInline from 'react-svg-inline';
import { useHistory } from 'react-router';
import { Option, OptionButton, OptionIcon, OptionName, OptionRightSide, Subtitle } from '../PageSettingsCommon.css';
import icons from '../../../../style';
import translate from '../../../../utils/fnTranslate';
import { useElementInteractionTracking } from '../../../../providers/useTracking/TrackingContext';
import { useConfig } from '../../../../providers/useConfig/ConfigContext';
import { SettingsMenu } from '../../../../types/Config';
import { useAuth } from '../../../../providers/useAuth/AuthContext';
import {
    SETTINGS_AUTOPLAY,
    SETTINGS_PIN,
    SETTINGS_RECORDINGS,
    SETTINGS_DEVICE_MANAGEMENT,
    SETTINGS_ACCOUNT,
    SETTINGS_ABOUT_APP,
    SETTINGS_LANGUAGE,
    SETTINGS_INTERNET_SPEED_TEST,
} from '../../../Application';
import { StaticPageRoutes } from '../../../../types/RouteTypes';

export const getOptionIconName = type => {
    switch (type) {
        case SETTINGS_PIN:
            return 'unlockIcon';
        case SETTINGS_RECORDINGS:
            return 'icoRecord';
        case SETTINGS_AUTOPLAY:
            return 'playOutlineIcon';
        case SETTINGS_ABOUT_APP:
            return 'infoIcon';
        case SETTINGS_LANGUAGE:
            return 'icoLanguage';
        case SETTINGS_DEVICE_MANAGEMENT:
            return 'icoDevices';
        case SETTINGS_INTERNET_SPEED_TEST:
            return 'icoSpeed';
        default:
            return 'icoSettingsPlaceholder';
    }
};

export const redirectTo = (url: string) => {
    window.open(url, '_blank');
};

export const AppSettings: FC = () => {
    const { config } = useConfig();
    const history = useHistory();
    const interactionTracking = useElementInteractionTracking();
    const { isGuest, hasNPVR } = useAuth();

    const getSettingOption = (settingsMenu: SettingsMenu) => {
        const { id, title, action, internal } = settingsMenu;

        const clickListener = () => {
            interactionTracking({
                'data-track-id': 'settings_item',
                'data-button-title': translate(title),
            });

            switch (action.type.toLowerCase()) {
                case 'deeplinking':
                    if (action.url && action.url !== '') {
                        history.push(action.url.startsWith('/') ? action.url : `/${action.url}`);
                    }
                    break;
                case 'custom':
                    if (internal && internal === SETTINGS_PIN) {
                        history.push(StaticPageRoutes.PIN_PROTECTION);
                    }
                    break;
                default:
                    break;
            }
        };

        return (
            <Option clickable={true} onClick={clickListener} key={id} data-test-id={`header-account-settingsbuttons-${id}`}>
                <OptionIcon>
                    <SVGInline svg={icons[getOptionIconName(id)]} />
                </OptionIcon>
                <OptionName data-test-id={`header-account-settingsoptions-${title}`}>{translate(title)}</OptionName>
                <OptionRightSide>
                    <OptionButton>
                        <SVGInline svg={icons.rightArrowIcon} />
                    </OptionButton>
                </OptionRightSide>
            </Option>
        );
    };
    return (
        <>
            <Subtitle hideOnSmallRes={true}>{translate('SETTINGS_APP_SETTINGS_WEB')}</Subtitle>
            {config.content_config.settings_menu
                .filter(option => {
                    return !(
                        (isGuest && option.id === SETTINGS_PIN) ||
                        (isGuest && option.id === SETTINGS_AUTOPLAY) ||
                        (isGuest && option.id === SETTINGS_DEVICE_MANAGEMENT) ||
                        ((isGuest || !hasNPVR()) && option.id === SETTINGS_RECORDINGS) ||
                        option.id === SETTINGS_ACCOUNT
                    );
                })
                .map(settingsMenu => getSettingOption(settingsMenu))}
        </>
    );
};
