import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold } from '../../style/styled-components/cssGlobalVariables';
import { Breakpoints, MQ } from '../../style/styled-components/cssMediaQueries';
import Button from '../Buttons/Button/Button';
import { ButtonInner } from '../Buttons/Button/Button.css';
import { ContentMarker } from '../../style/styled-components/reusable.css';

export const MetaTitle = styled.p`
    font-family: ${fontBold};
    font-size: 32px;
    line-height: 40px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    margin-bottom: 12px;
    padding: 0 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;

    ${MQ(Breakpoints.s)} {
        margin-top: 0;
        padding: 0;
    }
`;

export const TermsAndConditions = styled.div`
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    padding: 0 16px;

    span {
        .spacer {
            height: 12px;
        }
    }

    ${MQ(Breakpoints.s)} {
        padding: 0;
    }
`;

export const StyledButton = styled(Button)`
    margin: 0 16px 12px 16px;
    font-family: ${fontBold};

    ${ButtonInner} {
        display: block;
    }

    ${MQ(Breakpoints.s)} {
        float: left;
        display: inline-block;
        margin: 0 12px 12px 0;

        ${ButtonInner} {
            display: inline-block;
        }
    }
`;

export const ButtonContainer = styled.div`
    margin-top: 46px;
    flex-direction: column;
    display: flex;

    ${MQ(Breakpoints.s)} {
        margin-top: 32px;
        display: block;
        flex-direction: unset;
    }
`;

export const InfoWrapper = styled.p`
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    margin-top: 12px;

    .rent-duration {
        font-family: ${fontBold};
    }
`;

export const MetaContainer = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 40px;

    ${ContentMarker} {
        display: none;
    }

    ${MQ(Breakpoints.s)} {
        width: 480px;
        float: left;
        text-align: left;
        margin-top: 0;

        ${ContentMarker} {
            position: static;
            display: inline-block;
            padding: 4px 8px;
            margin-bottom: 12px;
            transform: unset;
        }
    }
`;

export const ImageWrapperInner = styled.div`
    height: 100%;
    display: inline-block;
    position: relative;

    ${ContentMarker} {
        position: absolute;
        bottom: 7px;
        left: 0;
    }

    img {
        height: 100%;
        width: auto;
        object-fit: contain;
    }

    ${MQ(Breakpoints.s)} {
        height: auto;

        img {
            max-width: 288px;
            max-height: 270px;
        }

        ${ContentMarker} {
            display: none;
        }
    }
`;

export const ImageWrapper = styled.div`
    width: 100%;
    position: relative;
    height: 56.11vw;
    overflow: hidden;
    text-align: center;

    ${MQ(Breakpoints.s)} {
        width: 288px;
        height: auto;
        float: right;
        margin-left: 32px;
    }
`;

export const OptionsContent = styled.div`
    width: 100%;
    margin-top: 48px;

    &::after {
        clear: both;
    }

    ${MQ(Breakpoints.s)} {
        margin-top: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 800px;
    }
`;
