import * as Sentry from '@sentry/react';
import axios, { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import { captureException } from 'utils/fnError';
import { DRMToken, RegisterDeviceResponse, SSOSessionResponse, UserInformation } from '../types/AuthTypes';
import { appendQueryParams, getFilterValue } from '../utils/fnUrl';
import { RequestBodyBuilder } from '../utils/RequestBodyBuilder';
import ApplicationConfig from '../providers/useConfig/ApplicationConfig';
import {
    channelsByCategoryCollectionParser,
    Collection,
    extraMetaDataParser,
    movieDetailsParser,
    nowAndNextCollectionParser,
    parseCatchupPlayerAssetInfo,
    parseContentSessionResponse,
    parseLivePlayerAssetInfo,
    parseLiveSessionResponse,
    parseMyWorldChildCategory,
    parseOrderApiResponse,
    parsePersonalizedInfo,
    parseRecordingByEvent,
    parseRecordingPlayerAssetInfo,
    parseRecordingQuota,
    parseSubscriptionOptions,
    parseTopCategories,
    parseTrailerPlayerAssetInfo,
    parseVodPlayerAssetInfo,
    parseWorldItems,
    parseWorldRootId,
    programDetailsParser,
    purchasedProductParser,
    recordingDetailsParser,
    similarTVMovieAssetsParser,
    similarTVSeriesAssetsParser,
    similarVodMovieAssetsParser,
    tvSeriesDetailsParser,
    vodSeriesDetailsParser,
} from '../utils/fnParser';

import requestCategoryFiltered from '../assets/request_templates/req_category_filtered.xml';
import requestVodSeries from '../assets/request_templates/req_vod_series.xml';
import requestTVSeries from '../assets/request_templates/req_tv_series.xml';
import requestVodMovie from '../assets/request_templates/req_vod_movie.xml';
import requestTvChannels from '../assets/request_templates/req_tv_channels.xml';
import requestTvEpgChannelBroadcast from '../assets/request_templates/req_tv_epg_channel_broadcasts.xml';
import requestResourceId from '../assets/request_templates/sub_templates/req_resource_id.xml';
import requestNowAndNext from '../assets/request_templates/req_now_and_next.xml';
import requestMiniEpgBroadcasts from '../assets/request_templates/req_mini_epg_broadcasts.xml';
import requestTvBroadcastDetails from '../assets/request_templates/req_tv_broadcast_details.xml';
import requestRecordingDetails from '../assets/request_templates/req_recording_details.xml';
import requestTvBroadcastMovieSimilar from '../assets/request_templates/req_tv_broadcast_movie_similar.xml';
import requestTvBroadcastSeriesRelated from '../assets/request_templates/req_tv_broadcast_series_related.xml';
import requestVodSimilar from '../assets/request_templates/req_vod_similar.xml';
import requestLivePlayerInfoByChannel from '../assets/request_templates/req_live_player_info_by_channel.xml';
import requestCatchupPlayerInfoByEvent from '../assets/request_templates/req_catchup_player_info_by_event.xml';
import requestRecordingPlayerInfo from '../assets/request_templates/req_recording_player_info.xml';
import requestTrailer from '../assets/request_templates/req_trailer.xml';
import requestVodPlayerInfo from '../assets/request_templates/req_vod_player_info.xml';
import requestVodPurchase from '../assets/request_templates/req_vod_purchase.xml';
import requestPersonalizedInfo from '../assets/request_templates/req_personalized_info.xml';
import requestSeriesByIds from '../assets/request_templates/req_series_by_ids.xml';
import requestMinimalSeriesInfoByTitleIds from '../assets/request_templates/req_minimal_series_info_by_title_ids.xml';
import requestTitlesByIds from '../assets/request_templates/req_titles_by_ids.xml';
import requestPurchasedTitles from '../assets/request_templates/req_purchased_titles.xml';
import requestSaveBookmark from '../assets/request_templates/req_save_bookmark.xml';
import requestSearchMovies from '../assets/request_templates/req_search_movies.xml';
import requestSearchSeries from '../assets/request_templates/req_search_series.xml';
import requestCreateLivePlaySession from '../assets/request_templates/req_create_live_play_session.xml';
import requestCreateContentPlaySession from '../assets/request_templates/req_create_content_play_session.xml';
import requestKeepAliveSession from '../assets/request_templates/req_keep_alive.xml';
import requestDeleteSession from '../assets/request_templates/req_delete_session.xml';
import requestPurchasedProducts from '../assets/request_templates/req_purchased_products.xml';
import requestCheckPackageEntitlement from '../assets/request_templates/req_check_package_entitled.xml';
import requestRecordEvent from '../assets/request_templates/req_record_event.xml';
import requestDeleteRecording from '../assets/request_templates/req_delete_recording.xml';
import requestDeleteAllRecording from '../assets/request_templates/req_delete_all_recordings.xml';
import requestRecordingByEvent from '../assets/request_templates/req_recording_by_event.xml';
import requestScheduledRecordings from '../assets/request_templates/req_scheduled_recordings.xml';
import requestEpisodeRecordings from '../assets/request_templates/req_episode_recordings.xml';
import requestMovieRecordings from '../assets/request_templates/req_movie_recordings.xml';
import requestAllRecordings from '../assets/request_templates/req_all_recordings.xml';

import requestContentsWithTstvEvents from '../assets/request_templates/sub_templates/req_contents_with_tstv.xml';
import requestContentsWithMixedContent from '../assets/request_templates/sub_templates/req_contents_with_mixed_content.xml';
import requestContentsForVod from '../assets/request_templates/sub_templates/req_contents_for_vod.xml';

import sso from './SSO';
import {
    Bookmark,
    Broadcast,
    CoverAsset,
    DetailEpisode,
    ExtraMetaData,
    Movie,
    MovieDetails,
    NowAndNext,
    Person,
    ProgramDetails,
    RecordingDetails,
    Resource,
    SeriesDetails,
    TVSeriesDetailEpisode,
} from '../types/Asset';
import {
    CatchupPlayerAssetInfo,
    LivePlayerAssetInfo,
    PlaybackSession,
    PlayingAsset,
    RecordingPlayerAssetInfo,
    TrailerPlayerAssetInfo,
    VodPlayerAssetInfo,
} from '../types/Player';
import { getLocalStorage } from '../utils/fnStorage';
import { Datasource } from '../types/Config';
import { EntitlementState, OrderSubscription, PurchasedProduct, SubscribeOption, SubscribeType } from '../types/Entitlement';
import { ApiSource, DataSourceMethod, DataSourceService, FetchResponse, FilterItems, Paginated, UserAgent } from '../types/ApiTypes';
import { MyWorldCategory, MyWorldItem, MyWorldRootCategory } from '../types/World';
import { isEpisode, isMovieDetails, isProgramDetails, isRecordingDetails } from '../utils/fnTypeGuards';
import { HOURS } from '../utils/TimeUnit';
import localConfig from '../config/localConfig';
import { executeWithUserToken, tokenRequestHeader } from '../providers/useAuth/AuthService';
import { RegisterDevice, Rentable } from '../types/CommonTypes';
import { isSafari } from '../utils/fnDevices';
import { RecordingQuota, RecordingRelationRef } from '../types/RecordingTypes';

export default class Api {
    private static TOKEN_ERROR_CODE = 401;

    private static SUCCESS_STATUS_CODES = [200, 202, 204];

    private static SENTRY_EXCLUDED_ERROR_CODES = [404, Api.TOKEN_ERROR_CODE];

    private static AXIOS_NETWORK_ERROR_MESSAGE = 'Network Error';

    private static getNoCollectionRequestResultPromise = () =>
        Promise.resolve({
            response: {
                items: [],
                totalItems: 0,
                scheduleReload: Infinity,
            },
            error: null,
            status: -1,
        });

    private static getQueryWithCpeId = () => {
        const cpeId = getLocalStorage('cpeId');
        const query = { output: 'json' };

        if (cpeId) {
            // @ts-ignore
            query.cpeId = cpeId;
        }

        return query;
    };

    private static dataSourceMethodHandlerMap: {
        [key in DataSourceService]?: {
            [key2 in DataSourceMethod]?: (params: {
                dataSource?: Datasource;
                dataParser?: any;
                canceller?: any;
                paginated?: Paginated;
            }) => any;
        };
    } = {
        [DataSourceService.TV]: {
            [DataSourceMethod.GET_CATEGORY_REQUEST]: ({ dataSource, canceller, paginated, dataParser }) => {
                return Api.fetchMixedContentByCategory(dataSource, dataParser, canceller, paginated);
            },
            [DataSourceMethod.GET_TV_CHANNELS_BY_CATEGORY]: ({ dataSource, canceller }) => {
                return Api.fetchChannelsByCategory(dataSource, canceller);
            },
            [DataSourceMethod.GET_NOW_NEXT_REQUEST]: ({ dataParser, canceller, paginated }) => {
                return Api.fetchNowNext(dataParser, canceller, paginated);
            },
            [DataSourceMethod.GET_CHANNELS_REQUEST]: ({ dataParser, canceller }) => {
                return Api.fetchChannels(dataParser, canceller);
            },
            [DataSourceMethod.GET_TV_RELATED]: ({ dataSource, canceller }) => {
                return Api.fetchTvRelated(dataSource, canceller);
            },
            [DataSourceMethod.GET_CAST_CREW]: ({ dataSource, canceller }) => {
                return Api.fetchCastAndCrew(dataSource, canceller);
            },
        },
        [DataSourceService.VOD]: {
            [DataSourceMethod.GET_VOD_RELATED]: ({ dataSource, canceller }) => {
                return Api.fetchSimilarForVodMovie(dataSource, canceller);
            },
            [DataSourceMethod.GET_SERIES_CATEGORY_REQUEST]: ({ dataSource, canceller, paginated, dataParser }) => {
                return Api.fetchSeriesCategory(dataSource, dataParser, paginated, canceller);
            },
            [DataSourceMethod.GET_PURCHASED_TITLES]: ({ canceller, paginated, dataParser }) => {
                return Api.getRentedAssets(dataParser, paginated, canceller);
            },
        },
        [DataSourceService.RECOMMENDATIONS]: {
            [DataSourceMethod.GET_MOVIE_RECOMMENDATIONS]: ({ dataSource, canceller, paginated, dataParser }) => {
                return Api.fetchMovieKindRecommendations(dataSource, dataParser, paginated, canceller);
            },
            [DataSourceMethod.GET_SERIES_RECOMMENDATIONS]: ({ dataSource, canceller, paginated, dataParser }) => {
                return Api.fetchSeriesKindRecommendations(dataSource, dataParser, paginated, canceller);
            },
        },
        [DataSourceService.SEARCH]: {
            [DataSourceMethod.SEARCH_MOVIES]: ({ dataSource, canceller, paginated, dataParser }) => {
                return Api.searchMovies(dataSource, dataParser, paginated, canceller);
            },
            [DataSourceMethod.SEARCH_SERIES]: ({ dataSource, canceller, paginated, dataParser }) => {
                return Api.searchSeries(dataSource, dataParser, paginated, canceller);
            },
        },
        [DataSourceService.WORLD]: {
            [DataSourceMethod.GET_DYNAMIC_MOVIES]: ({ dataSource, canceller, paginated, dataParser }) => {
                return Api.fetchTitlesByDynamicCategory(dataSource, dataParser, paginated, canceller);
            },
            [DataSourceMethod.GET_DYNAMIC_CATEGORY_CONTENT]: ({ dataSource, canceller, paginated, dataParser }) => {
                return Api.fetchTitlesByDynamicCategory(dataSource, dataParser, paginated, canceller);
            },
            [DataSourceMethod.GET_DYNAMIC_SERIES]: ({ dataSource, canceller, paginated, dataParser }) => {
                return Api.fetchSeriesByDynamicCategory(dataSource, dataParser, paginated, canceller);
            },
        },
        [DataSourceService.RECORDING]: {
            [DataSourceMethod.GET_SCHEDULED]: ({ canceller, paginated, dataParser }) => {
                return Api.getScheduledRecordings(dataParser, paginated, canceller);
            },
            [DataSourceMethod.GET_EPISODES]: ({ canceller, paginated, dataParser }) => {
                return Api.getEpisodeRecordings(dataParser, paginated, canceller);
            },
            [DataSourceMethod.GET_MOVIES]: ({ canceller, paginated, dataParser }) => {
                return Api.getMovieRecordings(dataParser, paginated, canceller);
            },
            [DataSourceMethod.GET_ALL_RECORDINGS]: ({ canceller, paginated, dataParser }) => {
                return Api.getAllRecordings(dataParser, paginated, canceller);
            },
        },
    };

    private static fetch = async <T>({ url, options, dataParser }: ApiSource<T>) => {
        let requestStatus: number;

        try {
            let json;
            const response = await Promise.race([
                axios(url, options),
                new Promise<AxiosResponse<T>>((resolve, reject) => {
                    if (!navigator.onLine) {
                        reject(new Error(localConfig.data.networkError));
                    }
                }),
            ]);

            requestStatus = response.status;

            if (!Api.SUCCESS_STATUS_CODES.includes(requestStatus)) {
                return {
                    error: response,
                    status: requestStatus,
                    response: null,
                };
            }

            json = response.data;

            if (options.responseHandler) {
                json = options.responseHandler(json);
            }

            if (dataParser) {
                json = dataParser(json);
            }

            return {
                error: null,
                status: requestStatus,
                response: json as T,
            };
        } catch (exception) {
            requestStatus = exception?.response?.status;

            if (captureException(exception)) {
                Sentry.captureException(exception);
            }

            return {
                error: exception,
                status: requestStatus,
                response: null,
            };
        }
    };

    private static execute = async <T>(
        requestBody: string,
        dataParser,
        url,
        method: 'GET' | 'POST' | 'PUT' | 'DELETE',
        canceller: CancelToken,
        query: { [key: string]: boolean | number | string } = {
            output: 'json',
        },
        headers: { [key: string]: string } = {
            'Content-Type': 'application/json',
        }
    ): Promise<FetchResponse<T>> => {
        const appLanguage = getLocalStorage('appLanguage');

        const executeWithToken = async (tokenValue: string) => {
            // merge common queries into the request specific one
            const requestQueries = {
                ...query,
                ...{
                    language: appLanguage || 'en',
                    sessionId: tokenValue,
                },
            };

            const options: AxiosRequestConfig = {
                method,
                headers,
                data: requestBody,
                timeout: ApplicationConfig.api_config.timeout_limit,
                timeoutErrorMessage: localConfig.data.networkError,
                cancelToken: canceller,
            };

            return Api.fetch<T>({
                url: appendQueryParams(url, requestQueries),
                options,
                dataParser,
            });
        };

        let fetchResponse = await executeWithToken(sso.getToken());

        if (fetchResponse.status === Api.TOKEN_ERROR_CODE) {
            const updateTokenResult: SSOSessionResponse = await sso.updateToken();
            if (updateTokenResult) {
                fetchResponse = await executeWithToken(updateTokenResult.sessionId);
            }
        }

        return fetchResponse;
    };

    private static fetchTitlesByCategory = async (url: string, categoryId: string, dataParser, canceller, paginated: Paginated) => {
        return Api.execute<any>(
            null,
            data =>
                dataParser({
                    Category: data,
                }),
            url
                .replace('{categoryId}', categoryId)
                .replace('{startIndex}', `${paginated?.start}`)
                .replace('{numberOfItems}', `${paginated?.limit}`),
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    private static fetchTitlesByCategoryAndByIds = async (url: string, categoryId: string, dataParser, canceller, paginated: Paginated) => {
        const categoryResponse = await Api.execute<any>(null, null, url.replace('{categoryId}', categoryId), 'POST', canceller);

        // in case of my world Movies the Titles node is missing and the titles are returned under the ChildCategories.Category node
        // for the actors / directors the titles is present
        const titles =
            (categoryResponse.response?.Category?.ChildCategories?.Category || categoryResponse.response?.Category?.Titles?.Titles) ?? [];

        const allSeries = categoryResponse.response?.Category?.SeriesCollection?.Series ?? [];

        const allIds = [];

        titles.forEach(item => {
            allIds.push({
                id: item?.Name ?? item.id,
                type: 'title',
            });
        });

        allSeries.forEach(series => {
            allIds.push({
                id: series.id,
                type: 'series',
            });
        });

        if (allIds) {
            const templateTitlesByIds = requestTitlesByIds;
            const templateSeriesByIds = requestSeriesByIds;

            const subTemplate = requestResourceId;

            let titlesDetails;
            let seriesDetails;

            const rawIdsOfPage = allIds.slice(paginated.start, paginated.start + paginated.limit);

            // -------- TITLES ---------

            const pageTitleIds = rawIdsOfPage.filter(idItem => idItem.type === 'title').map(resource => resource?.id);

            if (pageTitleIds.length) {
                const titlesRequestBody = RequestBodyBuilder.create(templateTitlesByIds)
                    .setMultiple('TitleIds', subTemplate, 'ResourceId', pageTitleIds)
                    .setSubQuery('SubQueryContentsWithMixedContent', requestContentsWithMixedContent)
                    .build();

                // fetch title by ids
                titlesDetails = await Api.execute<any>(
                    titlesRequestBody,
                    null,
                    ApplicationConfig.api_config.routes.Vod.get_movie_details,
                    'POST',
                    canceller,
                    Api.getQueryWithCpeId(),
                    {
                        'Content-Type': 'application/json',
                        'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
                    }
                );
            }

            // -------- SERIES ---------

            const pageSeriesIds = rawIdsOfPage.filter(idItem => idItem.type === 'series').map(resource => resource?.id);

            // fetch series by ids
            if (pageSeriesIds.length) {
                const seriesRequestBody = RequestBodyBuilder.create(templateSeriesByIds)
                    .setMultiple('SeriesIds', subTemplate, 'ResourceId', pageSeriesIds)
                    .setSubQuery('SubQueryContentsWithMixedContent', requestContentsWithMixedContent)
                    .build();

                seriesDetails = await Api.execute<any>(
                    seriesRequestBody,
                    null,
                    ApplicationConfig.api_config.routes.Vod.get_movie_details,
                    'POST',
                    canceller,
                    Api.getQueryWithCpeId(),
                    {
                        'Content-Type': 'application/json',
                        'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
                    }
                );
            }

            if ((titlesDetails && titlesDetails?.response) || (seriesDetails && seriesDetails?.response)) {
                categoryResponse.response.Category.Titles = {
                    ...(titlesDetails?.response?.Titles ?? {}),
                    ...{
                        // we have to overwrite the Title's result count because that's used for the pagination and
                        // since here we are doing the pagination from code the total size will be limited to the
                        // result's length size
                        resultCount: titles.length,
                    },
                };

                categoryResponse.response.Category.SeriesCollection = {
                    ...(seriesDetails?.response?.SeriesCollection ?? {}),
                    ...{
                        // we have to overwrite the SeriesCollection's result count because that's used for the
                        // pagination and since here we are doing the pagination from code the total size will be
                        // limited to the result's length size
                        resultCount: allSeries.length,
                    },
                };

                return {
                    response: dataParser ? dataParser(categoryResponse.response) : categoryResponse.response,
                    error: titlesDetails?.error ?? seriesDetails?.error,
                };
            }
        }

        return Api.getNoCollectionRequestResultPromise();
    };

    private static fetchSeriesByCategory = async (url: string, categoryId: string, dataParser, canceller, paginated: Paginated) => {
        // fetch category result -> returns series id list
        const seriesResponse = await Api.execute<any>(null, null, url.replace('{categoryId}', categoryId), 'POST', canceller);

        const seriesList = seriesResponse.response?.Category?.SeriesCollection?.Series;

        if (seriesList) {
            const seriesIds: Resource[] = seriesList;

            const template = requestSeriesByIds;
            const subTemplate = requestResourceId;

            const requestBody = RequestBodyBuilder.create(template)
                .setMultiple(
                    'SeriesIds',
                    subTemplate,
                    'ResourceId',
                    seriesIds.slice(paginated.start, paginated.start + paginated.limit).map(resource => resource?.id)
                )
                .setSubQuery('SubQueryContentsWithMixedContent', requestContentsWithMixedContent)
                .build();

            // fetch details by ids
            const seriesDetails = await Api.execute<any>(
                requestBody,
                null,
                ApplicationConfig.api_config.routes.Vod.get_series_details,
                'POST',
                canceller,
                Api.getQueryWithCpeId(),
                {
                    'Content-Type': 'application/json',
                    'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
                }
            );

            if (seriesDetails && seriesDetails.response) {
                seriesResponse.response.Category.SeriesCollection = {
                    ...seriesDetails.response?.SeriesCollection,
                    ...{
                        // we have to overwrite the SeriesCollection's result count because that's used for the pagination and since here we are doing the pagination from code the total size will be limited to the result's length size
                        resultCount: seriesList.length,
                    },
                };

                return {
                    response: dataParser ? dataParser(seriesResponse.response) : seriesResponse.response,
                    error: seriesDetails.error,
                };
            }
        }

        return Api.getNoCollectionRequestResultPromise();
    };

    private static fetchPlayInfo = async <T extends PlayingAsset>(
        metaFetcher: Promise<FetchResponse<T>>,
        sessionCreator: (contentId, eventId) => Promise<FetchResponse<PlaybackSession>>
    ) => {
        const metaDataResponse = await metaFetcher;

        // if the resource has stream create session
        if (metaDataResponse?.response?.hasStream) {
            // create session -> obtain manifest url
            const session = await sessionCreator(metaDataResponse.response.contentId, metaDataResponse.response.id);

            if (session) {
                metaDataResponse.response = {
                    ...metaDataResponse.response,
                    ...session.response,
                };
            }

            return metaDataResponse;
        }

        return metaDataResponse;
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static fetchByDataSource = async <T>(
        dataSource: Datasource,
        dataParser,
        method,
        canceller: any,
        paginated: Paginated = {
            start: 0,
            limit: parseInt(dataSource?.params?.size?.[0], 10) || ApplicationConfig.api_config.page_size,
        }
    ) => {
        const handler = Api.dataSourceMethodHandlerMap?.[dataSource.Service]?.[dataSource.request.method];

        if (!handler) {
            throw new Error(`Not supported fetcher method:${dataSource.Service}.${dataSource.request.method}`);
        }

        return handler({
            dataSource,
            dataParser,
            canceller,
            paginated,
        });
    };

    /**
     *
     * Category
     *
     */

    static fetchSeriesCategory = async (dataSource: Datasource, dataParser, paginated: Paginated, canceller) => {
        const categoryId = getFilterValue(dataSource.params.filter, FilterItems.categoryId);
        if (!categoryId) throw new Error(`Missing category id`);

        return Api.fetchSeriesByCategory(
            ApplicationConfig.api_config.routes.Vod.get_series_category,
            categoryId,
            dataParser,
            canceller,
            paginated
        );
    };

    static fetchMixedContentByCategory = async (dataSource: Datasource, dataParser, canceller, paginated: Paginated) => {
        const categoryId = getFilterValue(dataSource.params.filter, FilterItems.categoryId);
        if (!categoryId) throw new Error(`Missing category id`);

        let sortId;
        if (dataSource.params.sort && dataSource.params.sort[0] !== 'Ordinal') {
            [sortId] = dataSource.params.sort;
        }

        const requestBody = RequestBodyBuilder.create(requestCategoryFiltered)
            .set('CategoryId', categoryId)
            .set('OffsetStart', `${paginated?.start}`)
            .set('Limit', `${paginated.limit}`)
            .set('Order', sortId || '~Ordinal')
            .setSubQuery('SubQueryContentsWithMixedContent', requestContentsWithMixedContent)
            .build();

        return Api.execute<Collection<CoverAsset>>(
            requestBody,
            dataParser,
            ApplicationConfig.api_config.routes.Tv.get_category,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static fetchChannelsByCategory = async (dataSource: Datasource, canceller?) => {
        const categoryId = getFilterValue(dataSource.params.filter, FilterItems.categoryId);
        if (!categoryId) throw new Error(`Missing category id`);

        return Api.execute<any>(
            null,
            channelsByCategoryCollectionParser,
            ApplicationConfig.api_config.routes.Tv.get_tv_channels_by_category.replace('{categoryId}', categoryId),
            'GET',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    /**
     *
     * Details
     *
     */

    static fetchProgramDetails = async (programId: string, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestTvBroadcastDetails)
            .set('BroadcastId', programId)
            .setSubQuery('SubQueryContentsWithTsTv', requestContentsWithTstvEvents)
            .build();

        return Api.execute<ProgramDetails>(
            requestBody,
            programDetailsParser,
            ApplicationConfig.api_config.routes.Tv.get_details,
            'PUT',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static fetchRecordingDetails = async (recordingId: string, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestRecordingDetails)
            .set('RecordingId', recordingId)
            .setSubQuery('SubQueryContentsWithTsTv', requestContentsWithTstvEvents)
            .build();

        return Api.execute<RecordingDetails>(
            requestBody,
            recordingDetailsParser,
            ApplicationConfig.api_config.routes.Tv.get_details,
            'PUT',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static fetchVodMovieDetails = async (vodId: string, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestVodMovie)
            .set('VodId', vodId)
            .setSubQuery('SubQueryContentsForVod', requestContentsForVod)
            .build();

        return Api.execute<MovieDetails>(
            requestBody,
            movieDetailsParser,
            ApplicationConfig.api_config.routes.Vod.get_movie_details,
            'PUT',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static fetchVodSeriesDetails = async (vodId: string, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestVodSeries)
            .set('VodSeriesId', vodId)
            .setSubQuery('SubQueryContentsForVod', requestContentsForVod)
            .build();

        return Api.execute<SeriesDetails<DetailEpisode>>(
            requestBody,
            vodSeriesDetailsParser,
            ApplicationConfig.api_config.routes.Vod.get_series_details,
            'PUT',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static fetchTVSeriesDetails = async (seriesId: string, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestTVSeries)
            .set('TVSeriesId', seriesId)
            .setSubQuery('SubQueryContentsWithTsTv', requestContentsWithTstvEvents)
            .build();

        return Api.execute<SeriesDetails<TVSeriesDetailEpisode>>(
            requestBody,
            tvSeriesDetailsParser,
            ApplicationConfig.api_config.routes.Vod.get_series_details,
            'PUT',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    /**
     *
     * EPG
     *
     */

    static fetchNowNext = async (dataParser, canceller?, paginated?: Paginated) => {
        const requestBody = RequestBodyBuilder.create(requestNowAndNext)
            .set('OffsetStart', `${paginated?.start}`)
            .set('Limit', `${paginated.limit}`)
            .build();

        return Api.execute<Collection<NowAndNext>>(
            requestBody,
            dataParser,
            ApplicationConfig.api_config.routes.Tv.get_now_next,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static fetchMiniEpgBroadcasts = async (channels: string[], canceller?) => {
        const template = requestMiniEpgBroadcasts;
        const subTemplate = requestResourceId;

        const requestBody = RequestBodyBuilder.create(template)
            .setMultiple('ChannelIds', subTemplate, 'ResourceId', channels)
            .build();

        return Api.execute<any>(
            requestBody,
            nowAndNextCollectionParser,
            ApplicationConfig.api_config.routes.Tv.get_programs,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static fetchChannels = async <T>(parser, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestTvChannels).build();

        return Api.execute<T>(
            requestBody,
            parser,
            ApplicationConfig.api_config.routes.Tv.get_channels,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static fetchBroadcasts = async (channels: string[], dayStart: number, dayEnd: number, canceller?) => {
        const template = requestTvEpgChannelBroadcast;
        const subTemplate = requestResourceId;

        const start = new Date(dayStart).toISOString();
        const end = new Date(dayEnd).toISOString();

        const requestBody = RequestBodyBuilder.create(template)
            .set('IntervalStart', `${start}`)
            .set('IntervalEnd', `${end}`)
            .setMultiple('ChannelIds', subTemplate, 'ResourceId', channels)
            .setSubQuery('SubQueryContentsWithTsTv', requestContentsWithTstvEvents)
            .build();

        return Api.execute<any>(
            requestBody,
            null,
            ApplicationConfig.api_config.routes.Tv.get_programs,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    /**
     *
     * Similar
     *
     */

    static fetchSimilarProgramsForMovieBroadcast = async (programDetails: ProgramDetails, canceller?) => {
        const template = requestTvBroadcastMovieSimilar;

        const intervalEnd = new Date();
        const intervalStart = new Date(intervalEnd.getTime() - HOURS.toMillis(12));
        const { genres, progType, id, title } = programDetails;
        const genre = genres?.[0]?.Value;

        if (!genre) {
            return Api.getNoCollectionRequestResultPromise();
        }

        const requestBody = RequestBodyBuilder.create(template)
            .set('IntervalStart', intervalStart.toISOString())
            .set('IntervalEnd', intervalEnd.toISOString())
            .set('ProgType', `&amp;&amp;AllGenres.href=="${progType}"`)
            .set('Genre', genre ? `&amp;&amp;Genres=="${genre}"` : '')
            .setSubQuery('SubQueryContentsWithTsTv', requestContentsWithTstvEvents)
            .build();

        return Api.execute<Collection<Broadcast>>(
            requestBody,
            data => {
                const parsed = similarTVMovieAssetsParser(data);
                parsed.items = [...parsed.items].filter(asset => asset.id !== id && asset.title !== title);

                return parsed;
            },
            ApplicationConfig.api_config.routes.Tv.get_movie_related,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static fetchRelatedProgramsForSeriesBroadcast = async (programDetails: ProgramDetails, canceller?) => {
        const { seasonId, id, title } = programDetails;
        const requestBody = RequestBodyBuilder.create(requestTvBroadcastSeriesRelated)
            .set('SeasonId', seasonId)
            .setSubQuery('SubQueryContentsWithTsTv', requestContentsWithTstvEvents)
            .build();

        return Api.execute<Collection<Broadcast>>(
            requestBody,
            data => {
                const parsed = similarTVSeriesAssetsParser(data);
                parsed.items = [...parsed.items].filter(asset => asset.id !== id && asset.title !== title);

                return parsed;
            },
            ApplicationConfig.api_config.routes.Tv.get_series_related,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static fetchTvRelated = async (dataSource: Datasource, canceller?) => {
        const programDetails = dataSource.request?.variables?.details;
        if (!programDetails) throw new Error(`Missing program details`);

        const { seasonId } = programDetails;

        if (seasonId) {
            return Api.fetchRelatedProgramsForSeriesBroadcast(programDetails, canceller);
        }

        return Api.fetchSimilarProgramsForMovieBroadcast(programDetails, canceller);
    };

    static fetchSimilarForVodMovie = async (dataSource: Datasource, canceller?) => {
        const movieDetails = dataSource.request?.variables?.details;
        if (!movieDetails) throw new Error(`Missing movie details`);

        const { genres, id, title } = movieDetails;
        const template = requestVodSimilar;
        const genre = genres?.[0]?.Value;

        const requestBody = RequestBodyBuilder.create(template)
            .set('Genre', genre ? `&amp;&amp;Genres=="${genre}"` : '')
            .setSubQuery('SubQueryContentsForVod', requestContentsForVod)
            .build();

        return Api.execute<Collection<Movie>>(
            requestBody,
            data => {
                const parsed = similarVodMovieAssetsParser(data);
                parsed.items = [...parsed.items].filter(asset => asset.id !== id && asset.title !== title);

                return parsed;
            },
            ApplicationConfig.api_config.routes.Vod.get_similar,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    /**
     * Play session
     *
     */

    static createLivePlaySession = async (channelId: string, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestCreateLivePlaySession)
            .set('ChannelId', channelId)
            .build();

        return Api.execute<PlaybackSession>(
            requestBody,
            parseLiveSessionResponse,
            ApplicationConfig.api_config.routes.Playback.create_session,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static createContentPlaySession = async (contentId: string, eventId: string, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestCreateContentPlaySession)
            .set('ContentId', contentId)
            .set('EventId', eventId)
            .build();

        return Api.execute<PlaybackSession>(
            requestBody,
            parseContentSessionResponse,
            ApplicationConfig.api_config.routes.Playback.create_session,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static keepAliveSession = async (sessionId: string, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestKeepAliveSession).build();

        return Api.execute<string>(
            requestBody,
            null,
            ApplicationConfig.api_config.routes.Playback.keep_alive.replace('{playSessionId}', sessionId),
            'PUT',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/xml',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static endSession = async (sessionId: string, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestDeleteSession).build();

        return Api.execute<string>(
            requestBody,
            null,
            ApplicationConfig.api_config.routes.Playback.end_session.replace('{playSessionId}', sessionId),
            'DELETE',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/xml',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    /**
     *
     * Player info
     *
     */

    static fetchLivePlayerInfo = async (channelId: string, createSession: boolean, canceller?) => {
        const template = requestLivePlayerInfoByChannel;
        const subTemplate = requestResourceId;

        const start = new Date().toISOString();
        const end = new Date().toISOString();

        const requestBody = RequestBodyBuilder.create(template)
            .set('IntervalStart', `${start}`)
            .set('IntervalEnd', `${end}`)
            .setMultiple('ChannelIds', subTemplate, 'ResourceId', [channelId])
            .build();

        return Api.fetchPlayInfo<LivePlayerAssetInfo>(
            Api.execute<LivePlayerAssetInfo>(
                requestBody,
                parseLivePlayerAssetInfo,
                ApplicationConfig.api_config.routes.Tv.get_live_asset,
                'POST',
                canceller,
                Api.getQueryWithCpeId(),
                {
                    'Content-Type': 'application/json',
                    'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
                }
            ),
            contentId => (createSession ? Api.createLivePlaySession(contentId, canceller) : Promise.resolve(null))
        );
    };

    static fetchCatchupPlayerInfo = async (broadcastId: string, createSession: boolean, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestCatchupPlayerInfoByEvent)
            .set('BroadcastId', broadcastId)
            .build();

        return Api.fetchPlayInfo<CatchupPlayerAssetInfo>(
            Api.execute<CatchupPlayerAssetInfo>(
                requestBody,
                parseCatchupPlayerAssetInfo,
                ApplicationConfig.api_config.routes.Tv.get_details,
                'PUT',
                canceller,
                Api.getQueryWithCpeId(),
                {
                    'Content-Type': 'application/json',
                    'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
                }
            ),
            (contentId, eventId) => (createSession ? Api.createContentPlaySession(contentId, eventId, canceller) : Promise.resolve(null))
        );
    };

    static fetchRecordingPlayerInfo = async (recordingId: string, createSession: boolean, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestRecordingPlayerInfo)
            .set('RecordingId', recordingId)
            .build();

        return Api.fetchPlayInfo<RecordingPlayerAssetInfo>(
            Api.execute<RecordingPlayerAssetInfo>(
                requestBody,
                parseRecordingPlayerAssetInfo,
                ApplicationConfig.api_config.routes.Tv.get_details,
                'PUT',
                canceller,
                Api.getQueryWithCpeId(),
                {
                    'Content-Type': 'application/json',
                    'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
                }
            ),
            contentId => (createSession ? Api.createContentPlaySession(contentId, canceller) : Promise.resolve(null))
        );
    };

    static fetchTrailerPlayerInfo = async (trailerId: string, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestTrailer)
            .set('VodId', trailerId)
            .build();

        return Api.fetchPlayInfo<TrailerPlayerAssetInfo>(
            Api.execute<TrailerPlayerAssetInfo>(
                requestBody,
                parseTrailerPlayerAssetInfo,
                ApplicationConfig.api_config.routes.Vod.get_movie_details,
                'PUT',
                canceller,
                Api.getQueryWithCpeId(),
                {
                    'Content-Type': 'application/json',
                    'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
                }
            ),
            contentId => Api.createContentPlaySession(contentId, canceller)
        );
    };

    static fetchVodPlayerInfo = async (vodId: string, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestVodPlayerInfo)
            .set('VodId', vodId)
            .setSubQuery('SubQueryContentsForVod', requestContentsForVod)
            .build();

        return Api.fetchPlayInfo<VodPlayerAssetInfo>(
            Api.execute<VodPlayerAssetInfo>(
                requestBody,
                parseVodPlayerAssetInfo,
                ApplicationConfig.api_config.routes.Vod.get_movie_details,
                'PUT',
                canceller,
                Api.getQueryWithCpeId(),
                {
                    'Content-Type': 'application/json',
                    'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
                }
            ),
            contentId => Api.createContentPlaySession(contentId, canceller)
        );
    };

    /**
     *
     * Cast and crew with images
     *
     */

    static fetchExtraMetaData = async (crId: string, canceller?) => {
        return Api.execute<ExtraMetaData>(
            null,
            extraMetaDataParser,
            ApplicationConfig.api_config.routes.Tv.get_cast_crew,
            'GET',
            canceller,
            {
                crid: crId,
            }
        );
    };

    static fetchCastAndCrew = async (dataSource: Datasource, canceller?) => {
        const { details: assetDetails, ratingReceiver, itemsReceiver } = dataSource.request?.variables ?? {};

        const { titleId, cast } = assetDetails || {};
        let referenceId = titleId;

        const response: Collection<Person> = {
            items: cast,
            moreResources: false,
            scheduleReload: Infinity,
            totalItems: cast?.length,
        };

        if (
            assetDetails &&
            (isProgramDetails(assetDetails) || isRecordingDetails(assetDetails) || isMovieDetails(assetDetails) || isEpisode(assetDetails))
        ) {
            if (isEpisode(assetDetails)) {
                referenceId = assetDetails.id;
            }

            const castAndCrewResponse = await Api.fetchExtraMetaData(referenceId, canceller);

            if (castAndCrewResponse.response?.imdRating && ratingReceiver) {
                ratingReceiver(castAndCrewResponse.response?.imdRating);
            }

            if (castAndCrewResponse.response) {
                response.items = [...(castAndCrewResponse.response?.directors ?? []), ...(castAndCrewResponse.response?.actors ?? [])];
                response.totalItems = response.items.length;
            }

            if (itemsReceiver && response.items) {
                itemsReceiver(response.items);
            }
        }

        return {
            response,
            error: null,
        };
    };

    /**
     *
     * Purchased products
     *
     */

    static fetchPurchasedProducts = async (canceller?) => {
        return Api.execute<PurchasedProduct[]>(
            RequestBodyBuilder.create(requestPurchasedProducts).build(),
            purchasedProductParser,
            ApplicationConfig.api_config.routes.Entitlement.entitled_products,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static checkPackageEntitlement = async (packageId: string, canceller?) => {
        const { response } = await Api.execute<PurchasedProduct[]>(
            RequestBodyBuilder.create(requestCheckPackageEntitlement)
                .set('SubscriptionPackageId', packageId)
                .build(),
            purchasedProductParser,
            ApplicationConfig.api_config.routes.Entitlement.entitled_products,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );

        return response.length && response[0].state === EntitlementState.ENTITLED;
    };

    /**
     *
     * Renting
     *
     */

    static purchaseVod = async (productId: string, offerId: string, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestVodPurchase)
            .set('ProductId', productId)
            .set('OfferId', offerId)
            .build();

        return Api.execute<VodPlayerAssetInfo>(
            requestBody,
            null,
            ApplicationConfig.api_config.routes.Vod.rent_vod,
            'PUT',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static getPersonalizedInfo = async (titleId: string, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestPersonalizedInfo)
            .set('TitleId', titleId)
            .setSubQuery('SubQueryContentsForVod', requestContentsForVod)
            .build();

        return Api.execute<Rentable & Bookmark>(
            requestBody,
            parsePersonalizedInfo,
            ApplicationConfig.api_config.routes.Vod.get_movie_details,
            'PUT',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static getRentedAssets = async (dataParser, paginated?: Paginated, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestPurchasedTitles)
            .setSubQuery('SubQueryContentsForVod', requestContentsForVod)
            .set('OffsetStart', `${paginated?.start}`)
            .set('Limit', `${paginated.limit}`)
            .build();

        const purchasedResponse = await Api.execute<any>(
            requestBody,
            null,
            ApplicationConfig.api_config.routes.Vod.purchased_titles,
            'PUT',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );

        // it has purchased products
        if (purchasedResponse?.response?.Titles?.resultCount) {
            const episodeIds = [];
            (purchasedResponse.response?.Titles?.Title ?? []).forEach(title => {
                const seriesId = title?.SeriesCollection?.Series?.[0]?.ParentSeriesCollection?.Series?.[0]?.id;
                if (seriesId) {
                    episodeIds.push(title.id);
                }
            });

            // it has purchased episodes
            if (episodeIds.length) {
                const seriesInfoBody = RequestBodyBuilder.create(requestMinimalSeriesInfoByTitleIds)
                    .setMultiple('TitleIds', requestResourceId, 'ResourceId', episodeIds)
                    .build();

                const seriesInfos = await Api.execute<any>(
                    seriesInfoBody,
                    data => {
                        return data?.Titles?.Title?.map(title => {
                            return {
                                titleId: title.id,
                                title,
                            };
                        });
                    },
                    ApplicationConfig.api_config.routes.Vod.get_series_details,
                    'PUT',
                    canceller,
                    Api.getQueryWithCpeId(),
                    {
                        'Content-Type': 'application/json',
                        'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
                    }
                );

                // merge title with series info into the original purchased response
                purchasedResponse.response.Titles.Title = purchasedResponse.response.Titles.Title.map(title => {
                    return {
                        ...title,
                        ...(seriesInfos.response.find(sTitle => sTitle.titleId === title.id)?.title ?? {}),
                    };
                });
            }
        }

        return {
            response: dataParser({ Category: purchasedResponse.response }),
            error: purchasedResponse.error,
            status: purchasedResponse.status,
        };
    };

    /**
     *
     * My World
     *
     */

    static getWorldsRootId = async (canceller?) => {
        return Api.execute<MyWorldRootCategory>(
            null,
            parseWorldRootId,
            ApplicationConfig.api_config.routes.World.get_root_id,
            'GET',
            canceller
        );
    };

    static getMyWorldItems = async (rootId: string, canceller?) => {
        return Api.execute<MyWorldItem[]>(
            null,
            parseWorldItems,
            ApplicationConfig.api_config.routes.World.keywords_and_names.replace('{rootId}', rootId),
            'GET',
            canceller
        );
    };

    static manageWorldItem = async (rootId: string, action: 'add' | 'remove', worldItem: MyWorldItem, canceller?) => {
        const requestBody = {};

        requestBody[action] = [
            {
                keyWordType: worldItem.groupType,
                keyWordValues: [
                    {
                        value: worldItem.value,
                    },
                ],
            },
        ];

        return Api.execute<MyWorldItem[]>(
            JSON.stringify(requestBody),
            parseWorldItems,
            ApplicationConfig.api_config.routes.World.keywords_and_names.replace('{rootId}', rootId),
            'POST',
            canceller
        );
    };

    static getMyWorldTopCategories = async (rootId: string, canceller?) => {
        return Api.execute<MyWorldCategory>(
            null,
            parseTopCategories,
            ApplicationConfig.api_config.routes.World.get_categories.replace('{rootId}', rootId),
            'POST',
            canceller
        );
    };

    static fetchMyWorldSubCategories = async (categoryId: string, canceller?) => {
        return Api.execute<any>(
            null,
            parseMyWorldChildCategory,
            ApplicationConfig.api_config.routes.World.get_categories.replace('{rootId}', categoryId),
            'POST',
            canceller
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    static fetchTitlesByDynamicCategory = async (dataSource: Datasource, dataParser, paginated: Paginated, canceller?) => {
        const { categoryId } = dataSource.request.variables || {};
        if (!categoryId) throw new Error(`Missing category id`);

        return Api.fetchTitlesByCategoryAndByIds(
            ApplicationConfig.api_config.routes.World.content_by_category,
            categoryId,
            dataParser,
            canceller,
            paginated
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    static fetchSeriesByDynamicCategory = async (dataSource: Datasource, dataParser, paginated: Paginated, canceller?) => {
        const { categoryId } = dataSource.request.variables || {};
        if (!categoryId) throw new Error(`Missing category id`);

        return Api.fetchSeriesByCategory(
            ApplicationConfig.api_config.routes.World.dynamic_series,
            categoryId,
            dataParser,
            canceller,
            paginated
        );
    };

    /**
     *
     * Recommendations
     *
     */

    static fetchMovieKindRecommendations = async (dataSource: Datasource, dataParser, paginated: Paginated, canceller?) => {
        const categoryId = getFilterValue(dataSource.params.filter, FilterItems.categoryId);
        if (!categoryId) throw new Error(`Missing category id`);

        return Api.fetchTitlesByCategory(
            ApplicationConfig.api_config.routes.Recommendations.movie,
            categoryId,
            dataParser,
            canceller,
            paginated
        );
    };

    static fetchSeriesKindRecommendations = async (dataSource: Datasource, dataParser, paginated: Paginated, canceller?) => {
        const categoryId = getFilterValue(dataSource.params.filter, FilterItems.categoryId);
        if (!categoryId) throw new Error(`Missing category id`);

        return Api.fetchSeriesByCategory(
            ApplicationConfig.api_config.routes.Recommendations.series,
            categoryId,
            dataParser,
            canceller,
            paginated
        );
    };

    /**
     *
     * Bookmark
     *
     */

    static saveBookmark = async (titleId: string, position: number, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestSaveBookmark)
            .set('Bookmark', `${position}`)
            .set('TitleId', titleId)
            .build();

        return Api.execute<string>(
            requestBody,
            data => data?.Title?.id,
            ApplicationConfig.api_config.routes.Bookmark.save,
            'PUT',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    /**
     *
     * Search
     *
     */

    static searchMovies = async (dataSource: Datasource, dataParser, paginated: Paginated, canceller?) => {
        const query = dataSource.params?.term?.[0];

        const splitQuery = query.trim().split(' ');
        const queryString = splitQuery.map(item => `+${item}*`).join(' ');

        const requestBody = RequestBodyBuilder.create(requestSearchMovies)
            .set('OffsetStart', `${paginated?.start}`)
            .set('Limit', `${paginated.limit}`)
            .set('SearchQuery', `${queryString}`)
            .setSubQuery('SubQueryContentsWithMixedContent', requestContentsWithMixedContent)
            .build();

        return Api.execute<Collection<CoverAsset>>(
            requestBody,
            data =>
                dataParser({
                    Category: data,
                }),
            ApplicationConfig.api_config.routes.Search.search_movies,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static searchSeries = async (dataSource: Datasource, dataParser, paginated: Paginated, canceller?) => {
        const query = dataSource.params?.term?.[0];

        const splitQuery = query.trim().split(' ');
        const queryString = splitQuery.map(item => `+${item}*`).join(' ');

        const requestBody = RequestBodyBuilder.create(requestSearchSeries)
            .set('OffsetStart', `${paginated?.start}`)
            .set('Limit', `${paginated.limit}`)
            .set('SearchQuery', `${queryString}`)
            .build();

        return Api.execute<Collection<CoverAsset>>(
            requestBody,
            data =>
                dataParser({
                    Category: data,
                }),
            ApplicationConfig.api_config.routes.Search.search_series,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    /**
     *
     * Recordings
     *
     */

    static getRecordingByEvent = async (eventId: string, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestRecordingByEvent)
            .set('BroadcastId', eventId)
            .build();

        return Api.execute<RecordingRelationRef>(
            requestBody,
            parseRecordingByEvent,
            ApplicationConfig.api_config.routes.Recording.get_by_event,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static createRecording = async (eventId: string, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestRecordEvent)
            .set('BroadcastId', eventId)
            .build();

        return Api.execute(
            requestBody,
            null,
            ApplicationConfig.api_config.routes.Recording.manage,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static deleteRecordingByEventId = async (eventId: string, canceller?) => {
        const recordingByEvent = await Api.getRecordingByEvent(eventId);

        if (!recordingByEvent || !recordingByEvent.response) return null;

        const requestBody = RequestBodyBuilder.create(requestDeleteRecording)
            .set('RecordingId', recordingByEvent.response.id)
            .build();

        return Api.execute(
            requestBody,
            () => true,
            ApplicationConfig.api_config.routes.Recording.manage,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static deleteRecording = async (recordingId: string, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestDeleteRecording)
            .set('RecordingId', recordingId)
            .build();

        return Api.execute(
            requestBody,
            () => true,
            ApplicationConfig.api_config.routes.Recording.manage,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static getScheduledRecordings = async (dataParser, paginated: Paginated, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestScheduledRecordings)
            .set('OffsetStart', `${paginated?.start}`)
            .set('Limit', `${paginated.limit}`)
            .build();

        return Api.execute<Collection<CoverAsset>>(
            requestBody,
            data =>
                dataParser({
                    Category: data,
                }),
            ApplicationConfig.api_config.routes.Recording.get_scheduled,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static getEpisodeRecordings = async (dataParser, paginated: Paginated, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestEpisodeRecordings)
            .set('OffsetStart', `${paginated?.start}`)
            .set('Limit', `${paginated.limit}`)
            .build();

        return Api.execute<Collection<CoverAsset>>(
            requestBody,
            data =>
                dataParser({
                    Category: data,
                }),
            ApplicationConfig.api_config.routes.Recording.get_episodes,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static getMovieRecordings = async (dataParser, paginated: Paginated, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestMovieRecordings)
            .set('OffsetStart', `${paginated?.start}`)
            .set('Limit', `${paginated.limit}`)
            .build();

        return Api.execute<Collection<CoverAsset>>(
            requestBody,
            data =>
                dataParser({
                    Category: data,
                }),
            ApplicationConfig.api_config.routes.Recording.get_movies,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static getAllRecordings = async (dataParser, paginated: Paginated, canceller?) => {
        const requestBody = RequestBodyBuilder.create(requestAllRecordings)
            .set('OffsetStart', `${paginated?.start}`)
            .set('Limit', `${paginated.limit}`)
            .build();

        return Api.execute<Collection<CoverAsset>>(
            requestBody,
            data =>
                dataParser({
                    Category: data,
                }),
            ApplicationConfig.api_config.routes.Recording.get_all_recordings,
            'POST',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static getRecordingQuota = async (canceller?) => {
        return Api.execute<RecordingQuota>(
            null,
            parseRecordingQuota,
            ApplicationConfig.api_config.routes.Recording.get_quota,
            'GET',
            canceller,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };

    static deleteAllRecording = async () => {
        const requestBody = RequestBodyBuilder.create(requestDeleteAllRecording).build();
        return Api.execute(requestBody, () => true, ApplicationConfig.api_config.routes.Recording.manage, 'POST', null);
    };

    /**
     *
     * User specific requests
     *
     */

    static getSession = async () => {
        return executeWithUserToken<SSOSessionResponse>(() =>
            Api.fetch<SSOSessionResponse>({
                url: ApplicationConfig.api_config.routes.Auth.sso,
                dataParser: null,
                options: {
                    method: 'GET',
                    headers: tokenRequestHeader(),
                },
            })
        );
    };

    static addDevice = async (url: string, payload: RegisterDevice) => {
        return executeWithUserToken<RegisterDeviceResponse>(() =>
            Api.fetch<RegisterDeviceResponse>({
                url,
                dataParser: null,
                options: {
                    method: 'POST',
                    headers: tokenRequestHeader(),
                    data: payload,
                },
            })
        );
    };

    static getAllDevices = async () => {
        return executeWithUserToken<any>(() =>
            Api.fetch<RegisterDeviceResponse>({
                url: ApplicationConfig.api_config.routes.DRM.get_all_devices,
                dataParser: null,
                options: {
                    method: 'GET',
                    headers: tokenRequestHeader(),
                },
            })
        );
    };

    static removeDevice = async (uuid: string) => {
        return executeWithUserToken<any>(() =>
            Api.fetch<any>({
                url: ApplicationConfig.api_config.routes.DRM.delete_device + uuid,
                dataParser: null,
                options: {
                    method: 'DELETE',
                    headers: tokenRequestHeader(),
                },
            })
        );
    };

    static requestDRMToken = async (url: string, method: 'POST' | 'PUT', payload: string, canceller?) => {
        return executeWithUserToken<DRMToken>(() =>
            Api.fetch<DRMToken>({
                url,
                dataParser: null,
                options: {
                    method,
                    headers: {
                        ...tokenRequestHeader(),
                        ...{
                            'Content-Type': 'application/json',
                        },
                    },
                    data: payload,
                    cancelToken: canceller,
                },
            })
        );
    };

    static closeDRMSession = async (url: string, payload: string, canceller?) => {
        return executeWithUserToken<any>(() =>
            Api.fetch<DRMToken>({
                url,
                dataParser: null,
                options: {
                    method: 'DELETE',
                    headers: {
                        ...tokenRequestHeader(),
                        ...{
                            'Content-Type': 'text/plain',
                        },
                    },
                    data: payload,
                    cancelToken: canceller,
                },
            })
        );
    };

    private static fetchProductSubscriptionOffers = async (productCode: string, dataParser) => {
        const appLanguage = getLocalStorage('appLanguage');

        return executeWithUserToken<SubscribeOption[]>(() =>
            Api.fetch<SubscribeOption[]>({
                url: ApplicationConfig.api_config.routes.Entitlement.get_subscription_options_by_product_code
                    .replace('{productCode}', productCode)
                    .replace('{language}', appLanguage),
                dataParser,
                options: {
                    method: 'GET',
                    headers: tokenRequestHeader(),
                },
            })
        );
    };

    private static fetchChannelSubscriptionOffers = async (channelCode: string, dataParser) => {
        const appLanguage = getLocalStorage('appLanguage');

        return executeWithUserToken<SubscribeOption[]>(() =>
            Api.fetch<SubscribeOption[]>({
                url: ApplicationConfig.api_config.routes.Entitlement.get_subscription_options_by_channel_code
                    .replace('{channelCode}', channelCode)
                    .replace('{language}', appLanguage),
                dataParser,
                options: {
                    method: 'GET',
                    headers: tokenRequestHeader(),
                },
            })
        );
    };

    static fetchSubscriptionOffers = async (resourceId: string, type: SubscribeType) => {
        const { npvr_prefix } = ApplicationConfig.app_config.product_settings;
        if (type === 'Channel') {
            if (resourceId.startsWith(npvr_prefix)) {
                return Api.fetchProductSubscriptionOffers(npvr_prefix, parseSubscriptionOptions);
            }
            return Api.fetchChannelSubscriptionOffers(resourceId, parseSubscriptionOptions);
        }

        return Api.fetchProductSubscriptionOffers(resourceId, parseSubscriptionOptions);
    };

    static orderProduct = async (productId: number) => {
        return executeWithUserToken<OrderSubscription>(() =>
            Api.fetch<OrderSubscription>({
                url: ApplicationConfig.api_config.routes.Entitlement.order_product.replace('{productId}', `${productId}`),
                dataParser: parseOrderApiResponse,
                options: {
                    method: 'POST',
                    headers: tokenRequestHeader(),
                },
            })
        );
    };

    static validatePin = async (pin: string) => {
        return executeWithUserToken<any>(() =>
            Api.fetch<any>({
                url: ApplicationConfig.api_config.routes.Pin.manage.replace('{type}', `ADMIN`),
                dataParser: parseOrderApiResponse,
                options: {
                    method: 'POST',
                    headers: tokenRequestHeader(),
                    data: pin,
                },
            })
        );
    };

    static changePin = async (oldPin: string, newPin: string) => {
        return executeWithUserToken<any>(() =>
            Api.fetch<any>({
                url: ApplicationConfig.api_config.routes.Pin.manage.replace('{type}', `ADMIN`),
                dataParser: parseOrderApiResponse,
                options: {
                    method: 'PUT',
                    headers: tokenRequestHeader(),
                    data: {
                        new_pin: newPin,
                        old_pin: oldPin,
                    },
                },
            })
        );
    };

    static fetchUserInfo = async () => {
        return executeWithUserToken<UserInformation>(() =>
            Api.fetch<UserInformation>({
                url: ApplicationConfig.api_config.routes.Auth.user_info,
                options: {
                    method: 'GET',
                    headers: tokenRequestHeader(),
                },
            })
        );
    };

    static channelFavorite = async (action: 'ADD' | 'REMOVE', channelId: string) => {
        return Api.execute<boolean>(
            null,
            () => true,
            `https://acors.starman.ee/traxis/web/Channel/${channelId}/Favorites`,
            action === 'ADD' ? 'PUT' : 'DELETE',
            null,
            Api.getQueryWithCpeId(),
            {
                'Content-Type': 'application/json',
                'X-User-Agent': isSafari() ? UserAgent.HLS : UserAgent.DASH,
            }
        );
    };
}
