import { useEffect, useRef, useState } from 'react';
import { isNow, isPast } from '../../utils/fnDate';
import { AiringTime } from '../../types/CommonTypes';

export type TimeCluster = 'PAST' | 'LIVE' | 'FUTURE';

const EXTRA_TIME_BEFORE_UPDATE = 5;

/**
 * Utility hook to cluster broadcast kind data by the time.
 *
 * If the broadcast is past clusters is as PAST
 * If the broadcast is live clusters is as LIVE and starts a timer to set it PAST when the live ends
 * If the broadcast is future clusters is as FUTURE and starts a timer to set it LIVE when the assets becomes live
 *
 * @param broadcast
 */
export const useBroadcastTimeCluster = (broadcast: AiringTime) => {
    const [cluster, setCluster] = useState<TimeCluster>(null);
    const updateClusterTimeoutRef = useRef<number>(null);

    const handleLiveToPast = () => {
        clearTimeout(updateClusterTimeoutRef.current);

        if (broadcast?.endTime) {
            updateClusterTimeoutRef.current = window.setTimeout(() => {
                setCluster('PAST');
                clearTimeout(updateClusterTimeoutRef.current);
            }, broadcast.endTime - Date.now() + EXTRA_TIME_BEFORE_UPDATE);
        }
    };

    const handleFutureToLive = () => {
        clearTimeout(updateClusterTimeoutRef.current);

        if (broadcast?.startTime) {
            updateClusterTimeoutRef.current = window.setTimeout(() => {
                setCluster('LIVE');
                clearTimeout(updateClusterTimeoutRef.current);
            }, broadcast.startTime - Date.now() + EXTRA_TIME_BEFORE_UPDATE);
        }
    };

    useEffect(() => {
        if (cluster && cluster !== 'PAST') {
            if (cluster === 'LIVE') {
                handleLiveToPast();
            } else {
                handleFutureToLive();
            }
        }
    }, [cluster, broadcast]);

    useEffect(() => {
        if (broadcast) {
            if (isPast(broadcast.endTime)) {
                setCluster('PAST');
            } else if (isNow(broadcast.startTime, broadcast.endTime)) {
                setCluster('LIVE');
            } else {
                setCluster('FUTURE');
            }
        }

        return () => {
            clearTimeout(updateClusterTimeoutRef.current);
        };
    }, [broadcast]);

    return cluster;
};
