import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { isChrome } from 'react-device-detect';
import { BingeWatchingBanner } from 'components/BingeWatchingBanner/BingeWatchingBanner';
import {
    CastImageOverlay,
    PlayerErrorCode,
    PlayerErrorContainer,
    PlayerErrorText,
    PlayerErrorWrapper,
    PlayerObjectContainer,
    PlayerWrapperContainer,
} from './PlayerWrapper.css';
import ShakaPlayer from '../../providers/player/ShakaPlayer';
import { usePlayer } from '../../providers/player/PlayerContext';
import { useNavigation } from '../../hooks/useNavigation';
import { useConfig } from '../../providers/useConfig/ConfigContext';
import { PlayerControl, PlayerError } from '../../types/Player';
import { SECONDS } from '../../utils/TimeUnit';
import translate from '../../utils/fnTranslate';
import {
    NETWORK_ERROR_CODE,
    playerErrorsCode,
    playerErrorsCategory,
    INACTIVE_DEVICE,
    STREAM_LIMIT,
    STREAM_REPLACED,
} from '../../providers/player/playerConstants';
import { PlayerUISelector } from '../PlayerUI/PlayerUISelector';
import { useCast } from '../../providers/cast/CastContext';
import { PictureWithFallback } from '../Picture/PictureWithFallback';
import fallbackImage from '../../assets/images/hero_fallback.png';
import { setVideoAspectRatio } from '../../utils/fnPlayerUI';
import { AspectRatio } from '../../types/Common';
import { getPlayerUrlForPlayerProps } from '../../utils/fnUrl';
import { MiniPlayerUI } from '../PlayerUI/MiniPlayerUI';
import { useAuth } from '../../providers/useAuth/AuthContext';
import { AlertDialog } from '../AlertDialog/AlertDialog';
import { PageStreamManager } from '../Pages/PageStreamManager/PageStreamManager';
import { useApp, usePageUtils } from '../../providers/useApp/AppContext';

export const PLAYER_WRAPPER_CONTAINER = 'player-wrapper';

type PropTypes = {
    loading: boolean;
    forceExcludedControls?: PlayerControl[];
    extraControls?: ReactNode;
};

export enum ControlGroup {
    BASE,
    EXTRA,
    AUDIO_SUBTITLE,
    RECORDING,
}

export const DeviceRegistrationAlert: FC = () => {
    const { logUserOut } = useAuth();

    return (
        <AlertDialog
            title={translate('SCREEN_DEVICE_MANAGEMENT_SIGNOUT_NOTICE_HEADER')}
            bodyText={translate('SCREEN_DEVICE_MANAGEMENT_SIGNOUT_NOTICE_HINT')}
            buttons={[
                {
                    text: translate('CONTINUE_BUTTON'),
                    onClick: () => logUserOut(true),
                },
            ]}
        />
    );
};

const InPlayerError: React.FC<{ error: PlayerError; activeControlGroup: ControlGroup; isMini: boolean; isEmbedded?: boolean }> = ({
    error,
    activeControlGroup,
    isMini,
    isEmbedded = false,
}) => {
    const { code, message, linkClickListener } = error;
    const errorRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
        const linkElement = errorRef?.current?.getElementsByTagName('a')?.[0];

        if (linkElement) {
            linkElement.addEventListener('click', linkClickListener);
        }

        return () => {
            if (linkElement) {
                linkElement.removeEventListener('click', linkClickListener);
            }
        };
    }, [errorRef]);

    return (
        <PlayerErrorContainer isEmbedded={isEmbedded}>
            <PlayerErrorWrapper
                isEmbedded={isEmbedded}
                isMini={isMini}
                hide={activeControlGroup === ControlGroup.EXTRA}
                hideIfMobile={activeControlGroup === ControlGroup.AUDIO_SUBTITLE || activeControlGroup === ControlGroup.RECORDING}
            >
                <PlayerErrorText ref={errorRef} dangerouslySetInnerHTML={{ __html: message }} />
                {code && <PlayerErrorCode>{`${translate('ERR_CODE')}  ${code}`}</PlayerErrorCode>}
            </PlayerErrorWrapper>
        </PlayerErrorContainer>
    );
};

export const PlayerWrapper: React.FC<PropTypes> = ({ loading, forceExcludedControls, extraControls }) => {
    const video = useRef<HTMLVideoElement | null>(null);

    const playerWrapperRef = useRef<HTMLDivElement>(null);
    const playerControlsRef = useRef<HTMLDivElement>(null);
    const hideLayoutTimeoutRef = useRef(null);
    const isMounted = useRef(false);
    const canHideControls = useRef(false);
    const inactivityTime = useRef(null);
    const checkInactivity = useRef(false);
    const bingeWatchIsShown = useRef(false);

    const [shouldHideLayout, setShouldHideLayout] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [dragPosition, setDragPosition] = useState({ top: 0, left: 0 });
    const { onBackPressed, history } = useNavigation();
    const { isDesktop } = useApp();
    const {
        setEngine,
        release,
        isPlaying,
        activeControlGroup,
        setPlayerError,
        playerError,
        hasMultipleTracks,
        canStartOver,
        playingMode,
        isMini,
        isEmbedded,
        miniPlayerProps,
        asset,
        engine: currentEngine,
        nextEpisode,
        displayBingeWatch,
        bingeWatchDuration,
        dismissBingeWatchingBanner,
        loadNextEpisode,
        streamEndReached,
    } = usePlayer();
    const { config } = useConfig();
    const { castConnected } = useCast();

    const { isEpg } = usePageUtils();

    const resizeTimeout = useRef<number>(null);

    const videoAspectRatio: AspectRatio = { width: 16, height: 9 };

    const inactivityTimeout = config.app_config.player_settings.binge_watching_inactivity_timeout;

    const mouseMoveTimeoutHandler = () => {
        setShouldHideLayout(canHideControls.current);
    };

    const setLayoutHidden = (isHidden = true) => {
        if (hideLayoutTimeoutRef.current) {
            clearTimeout(hideLayoutTimeoutRef.current);
        }

        if (!isHidden) {
            setShouldHideLayout(isHidden);
        } else {
            hideLayoutTimeoutRef.current = setTimeout(
                mouseMoveTimeoutHandler,
                SECONDS.toMillis(config.app_config.player_settings.hide_controls_after)
            );
        }
    };

    const onUserEvent = () => {
        // set inactivity time for binge watch
        if (inactivityTime.current) {
            if (checkInactivity.current && !bingeWatchIsShown.current) {
                checkInactivity.current = false;
            }

            clearTimeout(inactivityTime.current);
        }

        inactivityTime.current = setTimeout(() => {
            if (!bingeWatchIsShown.current) {
                checkInactivity.current = true;
            }
        }, inactivityTimeout);

        // show layout
        setLayoutHidden(false);
        // hide layout after timeout
        setLayoutHidden();
    };

    const attachPlayerListeners = useCallback(() => {
        if (playerControlsRef.current) {
            playerWrapperRef.current.addEventListener('mousemove', onUserEvent);
            playerWrapperRef.current.addEventListener('click', onUserEvent);
            playerWrapperRef.current.addEventListener('touchmove', onUserEvent);
            playerWrapperRef.current.addEventListener('touchstart', onUserEvent);
        }
    }, [isMini]);

    const detachListeners = useCallback(() => {
        if (playerControlsRef.current) {
            playerWrapperRef.current.removeEventListener('mousemove', onUserEvent);
            playerWrapperRef.current.removeEventListener('click', onUserEvent);
            playerWrapperRef.current.removeEventListener('touchmove', onUserEvent);
            playerWrapperRef.current.removeEventListener('touchstart', onUserEvent);
        }
    }, [isMini]);

    const fullScreenChangeHandler = () => {
        if (document.fullscreenElement === null) {
            setIsFullScreen(false);
        } else {
            setIsFullScreen(true);
        }
    };

    const toggleFullScreen = () => {
        const playerWrapper = document.getElementsByClassName(PLAYER_WRAPPER_CONTAINER)[0];
        // "Not so great" workaround to fix the fullscreen issue with modals and notifications
        const bodyElement = document.getElementById('body');
        let modalRoot = document.getElementById('modal-root');
        let toastContainer = document.getElementsByClassName('react-toast-notifications__container')[0];

        if (!modalRoot) {
            modalRoot = document.createElement('div');
            modalRoot.id = 'modal-root';
            bodyElement.appendChild(modalRoot);
        }

        if (!toastContainer) {
            toastContainer = document.createElement('div');
            toastContainer.className = 'react-toast-notifications__container';
            bodyElement.appendChild(toastContainer);
        }

        if (document.fullscreenEnabled) {
            if (document.fullscreenElement !== null) {
                document
                    ?.exitFullscreen()
                    .then(() => {
                        setIsFullScreen(false);
                        bodyElement.appendChild(modalRoot);
                        bodyElement.appendChild(toastContainer);
                    })
                    .catch(() => {});
            } else {
                playerWrapper
                    ?.requestFullscreen()
                    .then(() => {
                        setIsFullScreen(true);
                        playerWrapper.appendChild(modalRoot);
                        playerWrapper.appendChild(toastContainer);
                    })
                    .catch(() => {});
            }
        }
    };

    const onBackClicked = () => {
        if (isFullScreen) {
            toggleFullScreen();
        }

        onBackPressed();
    };

    const setToNormalScreen = () => {
        if (isMini && miniPlayerProps) {
            history.push(getPlayerUrlForPlayerProps(miniPlayerProps));
        }
    };

    const handleOnStopDrag = (e, ui) => {
        setDragPosition({ top: ui.y, left: ui.x });
    };

    const setupPlayback = () => {
        setLayoutHidden();
    };
    const getErrorMessage = (code: number, category?: number): string => {
        if (code && playerErrorsCode[code]) {
            return translate(playerErrorsCode[code]);
        }

        if (category && playerErrorsCategory[category]) {
            return translate(playerErrorsCategory[category]);
        }

        return translate('ERR_PLAYER_GENERIC_MESSAGE');
    };

    const resizeHandler = () => {
        clearTimeout(resizeTimeout.current);
        resizeTimeout.current = window.setTimeout(() => {
            setVideoAspectRatio(video.current, videoAspectRatio, isMini ? playerWrapperRef.current : null);
        }, 200);
    };

    useEffect(() => {
        canHideControls.current = isPlaying && activeControlGroup === ControlGroup.BASE;
        setLayoutHidden();
    }, [isPlaying, activeControlGroup]);

    useEffect(() => {
        bingeWatchIsShown.current = displayBingeWatch;
    }, [displayBingeWatch]);

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            const engine = new ShakaPlayer().setVideo(video.current);

            engine.preparePlayer({
                onInitialized: engineInstance => {
                    setEngine(engineInstance);
                    setupPlayback();
                    attachPlayerListeners();
                },
                errorHandler: error => {
                    currentEngine.current?.getYoubora()?.fireFatalError(error.code, error.message, undefined);
                    currentEngine.current?.getYoubora()?.setOptions({ enabled: false });

                    if (error.message === INACTIVE_DEVICE) {
                        setPlayerError(error);
                        return;
                    }
                    if (error.message === STREAM_LIMIT) {
                        setPlayerError(error);
                        return;
                    }
                    if (error.message === STREAM_REPLACED) {
                        setPlayerError(error);
                        return;
                    }
                    setPlayerError({
                        ...error,
                        ...{
                            message: getErrorMessage(error?.code, error?.category),
                        },
                    });
                },
                onNetworkError: () => {
                    setPlayerError({
                        code: NETWORK_ERROR_CODE,
                        message: getErrorMessage(NETWORK_ERROR_CODE),
                    });
                },
            });
        }

        document.addEventListener('fullscreenchange', fullScreenChangeHandler);

        return () => {
            isMounted.current = false;
            clearTimeout(hideLayoutTimeoutRef.current);
            detachListeners();

            release().catch(() => {});
            document.removeEventListener('fullscreenchange', fullScreenChangeHandler);
        };
    }, []);

    useEffect(() => {
        const callBack = () => setVideoAspectRatio(video.current, videoAspectRatio, isMini ? playerWrapperRef.current : null);
        if (currentEngine?.current && isChrome) {
            currentEngine.current.addEventListener('loadstart', callBack);
            window.addEventListener('resize', resizeHandler);
        }

        return () => {
            if (currentEngine?.current) {
                currentEngine.current.removeEventListener('loadstart', callBack);
            }
            window.removeEventListener('resize', resizeHandler);
        };
    }, [currentEngine, isMini]);

    useEffect(() => {
        if (isChrome) {
            setVideoAspectRatio(video.current, videoAspectRatio, isMini ? playerWrapperRef.current : null);
        }

        if (playerWrapperRef.current) {
            if (!isMini) {
                playerWrapperRef.current.style.transform = 'none';
            } else {
                playerWrapperRef.current.style.transform = `translate(${dragPosition.left}px ,${dragPosition.top}px`;
            }
        }

        attachPlayerListeners();

        return () => {
            detachListeners();
        };
    }, [isMini]);

    useEffect(() => {
        if (streamEndReached && isFullScreen) {
            toggleFullScreen();
        }
    }, [streamEndReached]);

    if (playerError && playerError.message === INACTIVE_DEVICE) {
        return <DeviceRegistrationAlert />;
    }

    if (playerError && (playerError.message === STREAM_LIMIT || playerError.message === STREAM_REPLACED)) {
        return <PageStreamManager />;
    }

    return (
        <Draggable disabled={!isMini} bounds={'#body'} cancel={'.control-button'} onStop={handleOnStopDrag}>
            <PlayerWrapperContainer
                ref={playerWrapperRef}
                isMini={isMini}
                className={'player-wrapper'}
                isEpg={isEpg}
                isMobile={!isDesktop()}
            >
                <PlayerObjectContainer data-test-id={'player-page'}>
                    <video id="player" ref={video} poster={''} playsInline />
                    {castConnected && (
                        <CastImageOverlay>
                            <PictureWithFallback
                                src={asset?.image}
                                fallbackImage={fallbackImage}
                                fit={'cover'}
                                hPosition={'center'}
                                vPosition={'top'}
                            />
                        </CastImageOverlay>
                    )}
                </PlayerObjectContainer>

                {!isMini && (
                    <>
                        {playerError && (
                            <InPlayerError
                                error={playerError}
                                activeControlGroup={activeControlGroup}
                                isMini={isMini}
                                isEmbedded={isEmbedded}
                            />
                        )}
                        {displayBingeWatch && bingeWatchDuration && nextEpisode ? (
                            <BingeWatchingBanner
                                title={nextEpisode?.seriesTitle}
                                seasonNumber={nextEpisode?.seasonNumber}
                                episodeNumber={nextEpisode?.episodeNumber}
                                image={nextEpisode?.image}
                                timeLeft={bingeWatchDuration}
                                loadNextEpisode={loadNextEpisode}
                                dismissBanner={dismissBingeWatchingBanner}
                                showLimitNotification={checkInactivity.current}
                            />
                        ) : (
                            <PlayerUISelector
                                isFullScreen={isFullScreen}
                                shouldHideLayout={isPlaying && shouldHideLayout && !castConnected}
                                onControlsRef={ref => {
                                    playerControlsRef.current = ref;
                                }}
                                onBackClicked={onBackClicked}
                                toggleFullScreen={toggleFullScreen}
                                forceExcludedControls={forceExcludedControls}
                                extraControls={extraControls}
                                loading={loading}
                                hasTracks={hasMultipleTracks}
                                canStartOver={canStartOver}
                                playingMode={playingMode}
                                casting={castConnected}
                                isEmbedded={isEmbedded}
                            />
                        )}
                    </>
                )}
                {isMini && (
                    <>
                        {playerError && <InPlayerError error={playerError} activeControlGroup={activeControlGroup} isMini={isMini} />}
                        <MiniPlayerUI loading={loading} setToNormalScreen={setToNormalScreen} />
                    </>
                )}
            </PlayerWrapperContainer>
        </Draggable>
    );
};
