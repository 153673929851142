import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { PinInput } from './InputPin';

type PropTypes = {
    positions?: number;
    isInvalid?: boolean;
    reset?: boolean;

    onChange?(values: string[]): void;
    onComplete?(values: string[]): void;
};

const PinInputsGroup = styled.div<PropTypes>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 17px;
`;

const STEP_BACK_KEYCODES = [8, 46]; // backspace, delete

export const PinInputGroup: FC<PropTypes> = ({ positions = 4, onComplete, reset }) => {
    let pinsArray = Array(positions).fill('');
    const [pins, setPins] = useState<string[]>(pinsArray);
    const [currentPinIndex, setCurrentPinIndex] = useState<number>(0);

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        pinsArray = [...pins];
        pinsArray[currentPinIndex] = e.target.value;
        setPins(pinsArray);
        setCurrentPinIndex(currentPinIndex + 1);
    };

    const onKeyDown = e => {
        if (STEP_BACK_KEYCODES.includes(e.keyCode)) {
            e.preventDefault();
            e.stopPropagation();

            if (currentPinIndex > 0) {
                setCurrentPinIndex(currentPinIndex - 1);
            }
        }
    };

    useEffect(() => {
        pinsArray = [...pins];

        if (pinsArray[currentPinIndex] !== '') {
            pinsArray[currentPinIndex] = '';
            setPins(pinsArray);
        }

        if (currentPinIndex > positions - 1) {
            onComplete(pins);
        }
    }, [currentPinIndex]);

    useEffect(() => {
        if (reset) {
            setPins(Array(positions).fill(''));
            setCurrentPinIndex(0);
        }
    }, [reset]);

    return (
        <PinInputsGroup>
            {pinsArray.map((_, i) => (
                <PinInput
                    data-test-id={'header-settings-pinprotect-enter-pin'}
                    key={i}
                    isActive={currentPinIndex === i}
                    onChange={onInputChange}
                    onKeyDown={onKeyDown}
                    autoComplete={'new-password'}
                    value={pins[i]}
                    index={i}
                    autoFocus
                />
            ))}
        </PinInputsGroup>
    );
};
