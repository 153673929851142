import { ReactNode } from 'react';
import { CoverAsset } from '../../types/Asset';

export abstract class BasePresenterSelector {
    protected assets: CoverAsset[];

    setAssets = (assets: CoverAsset[]): BasePresenterSelector => {
        this.assets = assets;
        return this;
    };

    abstract get: () => ReactNode[];
}
