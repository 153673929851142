export const fontNormal = 'Open Sans';
export const fontBold = 'Open Sans Bold';

export const body1FontSize = '16px';
export const body1LineHeight = '24px';

export const body2FontSize = '12px';
export const body2LineHeight = '16px';

export const body3FontSize = '10px';
export const body3LineHeight = '16px';

export const maxZIndex = 99999;
export const levelingZIndex = 10;
export const overCardZIndex = 11;
export const headerZIndex = 100;
export const buttonZIndex = 99;
export const modalZIndex = 999999;
export const pageScrollerZIndex = headerZIndex + 1;
export const cookieNotificationZIndex = maxZIndex + 1;
export const smartAppBannerZIndex = headerZIndex - 1;
