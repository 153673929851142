import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useRef } from 'react';
import { useDataFetcher } from '../../hooks/useDataFetcher/useDataFetcher';
import { channelsCollectionParser, Collection } from '../../utils/fnParser';
import { Channel } from '../../types/Asset';
import Api from '../../api/Api';
import { RootState } from '../reducers';
import { channelsAction, ChannelsStore } from '../reducers/channel-reducer';
import { useGlobalNetworkError, useGlobalNoInternet } from '../../hooks/withNetworkCheck/withNetworkCheck';
import { MINUTES } from '../../utils/TimeUnit';

const REFRESH_CHANNELS_PERIOD = MINUTES.toMillis(30);

export const useChannels = () => {
    const { onNoInternet, noInternet } = useGlobalNoInternet();
    const { onNetworkError, networkError } = useGlobalNetworkError();
    const { error, response, loading, fetcher } = useDataFetcher<Collection<Channel>, any>(
        () => Api.fetchChannels<Collection<Channel>>(channelsCollectionParser),
        onNoInternet,
        onNetworkError
    );

    const channelData = useSelector<RootState, ChannelsStore>(state => state.channelsStore);
    const dispatch = useDispatch();

    const inLoading = useRef(loading);
    const forceReload = useRef(false);
    const reloadChannelsScheduleRef = useRef<number>(null);

    const fetchChannels = useCallback(
        (force: boolean = false) => {
            const shouldFetch =
                (!channelData || (!channelData.loading && !channelData.error && !channelData.channels)) && !noInternet && !networkError;

            // no data in cache
            if (shouldFetch || force) {
                forceReload.current = true;
                fetcher(null);
            }
        },
        [channelData, noInternet, networkError]
    );

    const scheduleReloadChannels = () => {
        clearTimeout(reloadChannelsScheduleRef.current);
        reloadChannelsScheduleRef.current = window.setTimeout(() => {
            // eslint-disable-next-line no-use-before-define
            fetchChannels(true);
        }, REFRESH_CHANNELS_PERIOD);
    };

    useEffect(() => {
        if (channelData) {
            if ((channelData.channels || channelData.error) && !forceReload.current) {
                return;
            }

            if (loading && channelData.loading) {
                return;
            }
        }

        if (response) {
            forceReload.current = false;
        }

        if (loading) {
            dispatch(channelsAction('CHANNELS_LOADING', null));
        } else {
            dispatch(channelsAction(error ? 'CHANNELS_LOAD_ERROR' : 'STORE_CHANNELS', error ? null : response?.items ?? null));
            scheduleReloadChannels();
        }
    }, [error, loading, response, forceReload.current]);

    useEffect(() => {
        inLoading.current = loading;
    }, [loading]);

    useEffect(() => {
        return () => {
            if (inLoading.current) {
                dispatch(channelsAction('CHANNELS_INTERRUPTED', null));
            }
        };
    }, []);

    const getChannelById = (channelId: string) => channelData?.channels?.find(channel => channel.id === channelId);

    return {
        channels: channelData?.channels,
        loading,
        fetchChannels,
        getChannelById,
    };
};
