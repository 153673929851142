import { downloadConfig, downloadTranslations } from 'providers/useConfig/ConfigApiIntegration';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { getProcessEnv } from 'utils/fnEnvironments';
import ApplicationConfig from './ApplicationConfig';

import ccConfig from '../../config/config-cc.json';

import localTranslationsEn from '../../config/translations-en.json';
import localTranslationsEt from '../../config/translations-et.json';
import localTranslationsRu from '../../config/translations-ru.json';
import { useApp } from '../useApp/AppContext';
import { getLocalStorage, setLocalStorage } from '../../utils/fnStorage';
import { usePersistentState } from '../../hooks/useStorage/useStorage';
import { LocalStorage } from '../../types/Storage';

const LOCAL_TRANSLATION = {
    en: localTranslationsEn,
    et: localTranslationsEt,
    ru: localTranslationsRu,
};

const UPDATE_CONFIG_TIME = 'UPDATE_CONFIG_TIME';

const CONFIG_UPDATE_APPLIED = 'CONFIG_UPDATE_APPLIED';

const useConfigService = () => {
    const [translations, setTranslations] = useState<{ [key: string]: string }>(null);
    const [config, setConfig] = useState<typeof ApplicationConfig>(null);
    const { appLanguage, setApplicationLanguage } = useApp();
    const updateConfigTimeTimeout = useRef<number>(null);

    const { set: setUpdateConfigTime, store: updateConfigTime } = usePersistentState(UPDATE_CONFIG_TIME as keyof LocalStorage, -1);

    const currentUILanguage = useRef<string>(appLanguage);

    const forceLocalConfig = getLocalStorage('forceLocalConfig');
    const useLocalConfig: boolean = getProcessEnv('USE_LOCAL_CONFIG', 'false') === 'true';

    const getRandomIntInclusive = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1) + min);
    };

    const getLanguage = async (language, langUrl) => {
        // if the translation is already loaded
        if (config?.app_config?.languages?.[currentUILanguage.current]?.data) {
            setTranslations(config.app_config.languages[currentUILanguage.current].data);
            return config.app_config.languages[currentUILanguage.current].data;
        }

        // need to load the translation resources
        let languageFile = LOCAL_TRANSLATION[language];

        if (!useLocalConfig && !forceLocalConfig) {
            try {
                languageFile = await downloadTranslations(langUrl);
            } catch (e) {
                console.error('loading remote translation', e);
            }
        }

        if (languageFile?.data) {
            setTranslations(languageFile.data);
            return languageFile.data;
        }

        setTranslations({ language });
        return language;
    };

    const changeLanguage = async (language, configData = config) => {
        currentUILanguage.current = language;
        if (config?.app_config?.general) {
            config.app_config.general.language = language;
        }

        const loadedLanguage = await getLanguage(language, configData.app_config?.languages?.[language]?.url);
        setApplicationLanguage(language);
        return loadedLanguage;
    };

    const loadConfig = async () => {
        let rawConfig = ccConfig as { data: any };

        if (!useLocalConfig && !forceLocalConfig) {
            try {
                rawConfig = await downloadConfig();
            } catch (e) {
                const configLocalStorage = getLocalStorage('config');
                if (configLocalStorage) {
                    rawConfig.data = configLocalStorage;
                }
                console.error('loading remote config', e);
            }
        }

        changeLanguage(appLanguage, rawConfig.data).then(translationData => {
            setLocalStorage('config', rawConfig.data);

            const configData = ApplicationConfig.createConfig(rawConfig.data, appLanguage, translationData);
            const configUpdateTime =
                (ApplicationConfig.api_config.update_interval / 100) * getRandomIntInclusive(-5, 5) +
                ApplicationConfig.api_config.update_interval;

            setConfig(configData);
            setUpdateConfigTime(Date.now() + configUpdateTime);
        });
    };

    useEffect(() => {
        const now = Date.now();
        if (typeof updateConfigTime === 'number' && updateConfigTime && updateConfigTime > now) {
            clearTimeout(updateConfigTimeTimeout.current);
            updateConfigTimeTimeout.current = window.setTimeout(() => {
                loadConfig().then(() => setLocalStorage(CONFIG_UPDATE_APPLIED as keyof LocalStorage, false));
            }, updateConfigTime - now);
        }
    }, [updateConfigTime]);

    useEffect(() => {
        if (!config && !translations) {
            loadConfig().then();
        }

        if (config && !translations) {
            changeLanguage(appLanguage).catch(() => {});
        }

        if (config && translations) {
            if (!config.app_config.languages[currentUILanguage.current]) {
                // if the language configuration is missing from the config create a placeholder object
                // where the translation content to be loaded
                config.app_config.languages[currentUILanguage.current] = {};
            }
            config.app_config.languages[currentUILanguage.current].data = translations;
        }
    }, [config, translations, currentUILanguage.current]);

    return {
        config,
        changeLanguage,
        translations,
    };
};

export const ConfigContext = createContext<ReturnType<typeof useConfigService>>(null);

export const ConfigProvider = ({ children }) => {
    const Config = useConfigService();

    return <ConfigContext.Provider value={Config}>{children}</ConfigContext.Provider>;
};

export const useConfig = () => useContext(ConfigContext);
