import React, { FC } from 'react';
import { Picture, PicturePropType } from './Picture';
import { PictureStyle } from './Picture.css';

export const PictureWithFallback: FC<PicturePropType & { fallbackImage?: string }> = ({ fallbackImage, alt, ...rest }) => (
    <Picture alt={alt} {...rest}>
        <PictureStyle loaded={true} {...rest}>
            <img src={fallbackImage} alt={alt} />
        </PictureStyle>
    </Picture>
);
