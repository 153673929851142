import React, { FC, useEffect, useRef } from 'react';
import {
    ActionButtons,
    DevPageTitle,
    DevPageWrapper,
    PageSection,
    SectionCheckMark,
    SectionInput,
    SectionLabel,
    SectionTitle,
} from './DevPage.css';
import { useNavigation } from '../../../hooks/useNavigation';
import { StaticPageRoutes } from '../../../types/RouteTypes';
import { useConfig } from '../../../providers/useConfig/ConfigContext';
import Button from '../../Buttons/Button/Button';
import { usePersistentState } from '../../../hooks/useStorage/useStorage';
import Api from '../../../api/Api';

export const DevPage: FC = () => {
    const { history } = useNavigation();

    const { set: setConfigVersion, store: configVersion } = usePersistentState('configVersion');
    const { set: setForceLocalConfig, store: shouldForceLocalConfig } = usePersistentState('forceLocalConfig');
    const { set: setEnableAdultContent, store: enableAdultContent } = usePersistentState('enAFMsDcs15dDJskUn');
    const { set: setTimeShiftDash, store: timeShiftDash } = usePersistentState('timeShiftDASH');

    const configVersionInputRef = useRef<HTMLInputElement>(null);
    const forceLocalConfigCheckboxRef = useRef<HTMLInputElement>(null);
    const enableAdultFilterCheckboxRef = useRef<HTMLInputElement>(null);
    const userAgentRef = useRef<HTMLTextAreaElement>(null);

    const goBack = () => {
        history.goBack();
    };

    const goToHome = () => {
        history.push('/');
    };

    const changeConfig = () => {
        setConfigVersion(configVersionInputRef.current.value);
        setForceLocalConfig(forceLocalConfigCheckboxRef.current.checked);
    };

    const changeConfigAndReload = () => {
        changeConfig();
        setTimeout(() => {
            goToHome();
            history.go(0);
        }, 50);
    };

    const versionButtonClick = (version: string) => {
        configVersionInputRef.current.value = version;
    };

    const deleteAllRecordings = async () => {
        await Api.deleteAllRecording();
        history.go(0);
    };

    const saveAdultFilter = () => {
        setEnableAdultContent(enableAdultFilterCheckboxRef.current.checked);
        setTimeout(() => {
            goToHome();
            history.go(0);
        }, 50);
    };

    const timeShiftDashCheckBoxChange = e => {
        setTimeShiftDash(e.target.checked);
    };

    const copyUserAgentToClipboard = () => {
        userAgentRef.current.select();
        document.execCommand('copy');
    };

    useEffect(() => {
        if (forceLocalConfigCheckboxRef) {
            forceLocalConfigCheckboxRef.current.checked = shouldForceLocalConfig === true;
        }

        if (enableAdultFilterCheckboxRef) {
            enableAdultFilterCheckboxRef.current.checked = enableAdultContent === true;
        }
    }, [forceLocalConfigCheckboxRef, shouldForceLocalConfig, enableAdultContent]);

    return (
        <DevPageWrapper>
            <DevPageTitle>Developer options</DevPageTitle>
            <ActionButtons>
                <Button label={'Go Back'} onClick={goBack} />
                <Button label={'Go To Home'} onClick={goToHome} />
            </ActionButtons>

            <PageSection>
                <SectionTitle>Config</SectionTitle>

                <SectionLabel>Setup config version</SectionLabel>
                <SectionInput type={'text'} placeholder={'Config version'} ref={configVersionInputRef} defaultValue={configVersion} />
                <ActionButtons>
                    <Button label={'null'} onClick={() => versionButtonClick(null)} />
                    <Button label={'New module types'} onClick={() => versionButtonClick('0.0.2')} />
                </ActionButtons>

                <SectionLabel>Force local config</SectionLabel>
                <SectionCheckMark type={'checkbox'} ref={forceLocalConfigCheckboxRef} />

                <ActionButtons>
                    <Button label={'Change'} onClick={changeConfig} isPrimary={true} />
                    <Button label={'Change and Restart'} onClick={changeConfigAndReload} isPrimary={false} />
                </ActionButtons>
            </PageSection>

            <PageSection>
                <SectionTitle>Environment</SectionTitle>
                <span>N/A</span>
            </PageSection>

            <PageSection>
                <SectionTitle>Other settings</SectionTitle>
                <Button label={'Delete all recordings'} onClick={deleteAllRecordings} isPrimary={true} />

                <SectionLabel>Enable adult content</SectionLabel>
                <SectionCheckMark type={'checkbox'} ref={enableAdultFilterCheckboxRef} />
                <Button label={'Save'} onClick={saveAdultFilter} isPrimary={true} />

                <SectionLabel>Enable time shift for DASH(Chrome)</SectionLabel>
                <SectionCheckMark type={'checkbox'} onChange={timeShiftDashCheckBoxChange} defaultChecked={timeShiftDash} />
            </PageSection>
            <PageSection>
                <SectionTitle>User Agent</SectionTitle>
                <textarea
                    style={{ display: 'block', width: '100%', height: '100px' }}
                    ref={userAgentRef}
                    defaultValue={window.navigator.userAgent}
                />
                <Button label={'Copy to clipboard'} onClick={copyUserAgentToClipboard} isPrimary={true} />
            </PageSection>
        </DevPageWrapper>
    );
};

export const useOpenDevMode = (element: HTMLElement) => {
    const CLICK_TO_OPEN_DEV_MODE = 5;
    const clicked = useRef<number>(0);

    const { history } = useNavigation();
    const { isFeatureActive } = useConfig()?.config ?? {};

    const handleClick = event => {
        if (!isFeatureActive || !isFeatureActive('dev_mode')) {
            return;
        }

        if (element && event.target === element) {
            clicked.current += 1;

            if (clicked.current === CLICK_TO_OPEN_DEV_MODE) {
                clicked.current = 0;
                history.push(StaticPageRoutes.DEV);
            }
        } else {
            clicked.current = 0;
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleClick);
        return () => window.removeEventListener('click', handleClick);
    }, [element]);
};
