import React, { useEffect, useRef, useState } from 'react';
import { isSafari } from 'utils/fnDevices';
import {
    BufferLine,
    ProgressBar,
    ProgressLine,
    Scrubber,
    SeekBarContainer,
    TimeIndicator,
    ProgressAndScrubber,
    SeekBarValue,
    ThumbnailContainer,
    ThumbnailImage,
} from './SeekBar.css';

type SeekBarProps = {
    enabled: boolean;
    onSeekInput?: () => void;
    onSeekStart?: () => void;
    onSeekEnd?: () => void;
    onSeekBarCreated: (object: SeekBarElements) => void;
};

export type SeekBarElements = {
    scrubber: HTMLInputElement;
    currentTime: HTMLDivElement;
    remainingTime: HTMLDivElement;
    bufferLine: HTMLDivElement;
    progressLine: HTMLDivElement;
    scrubberValue: HTMLDivElement;
    thumbnailContainer: HTMLDivElement;
    thumbnailImage: HTMLImageElement;
};

export const SeekBar: React.FC<SeekBarProps> = ({
    enabled,
    onSeekInput = () => {},
    onSeekBarCreated = () => {},
    onSeekStart = () => {},
    onSeekEnd = () => {},
}) => {
    const scrubberRef = useRef<HTMLInputElement>(null);
    const currentTimeRef = useRef<HTMLDivElement>(null);
    const remainingTimeRef = useRef<HTMLDivElement>(null);
    const bufferLineRef = useRef<HTMLDivElement>(null);
    const progressLineRef = useRef<HTMLDivElement>(null);
    const scrubberValueRef = useRef<HTMLDivElement>(null);
    const thumbnailRef = useRef<HTMLDivElement>(null);
    const thumbnailImageRef = useRef<HTMLImageElement>(null);

    const [seekValue] = useState(0);
    const [seekMin] = useState(0);
    const [seekMax] = useState(1);

    const addEventListeners = () => {
        if (scrubberRef && scrubberRef.current) {
            const scrubber = scrubberRef.current;

            scrubber.addEventListener('mousedown', onSeekStart);
            scrubber.addEventListener('touchstart', onSeekStart);
            scrubber.addEventListener('input', onSeekInput);
            if (!isSafari()) {
                scrubber.addEventListener('change', onSeekInput);
            }
            scrubber.addEventListener('touchend', onSeekEnd);
            scrubber.addEventListener('mouseup', onSeekEnd);
        }
    };

    useEffect(() => {
        addEventListeners();
        const scrubber = scrubberRef?.current || null;
        const currentTime = currentTimeRef?.current || null;
        const remainingTime = remainingTimeRef?.current || null;
        const bufferLine = bufferLineRef?.current || null;
        const progressLine = progressLineRef?.current || null;
        const scrubberValue = scrubberValueRef?.current || null;
        const thumbnailContainer = thumbnailRef?.current || null;
        const thumbnailImage = thumbnailImageRef?.current || null;

        onSeekBarCreated({
            scrubber,
            currentTime,
            remainingTime,
            bufferLine,
            progressLine,
            scrubberValue,
            thumbnailContainer,
            thumbnailImage,
        });

        return () => {
            scrubber.removeEventListener('mousedown', onSeekStart);
            scrubber.removeEventListener('touchstart', onSeekStart);
            scrubber.removeEventListener('input', onSeekInput);
            if (!isSafari()) {
                scrubber.removeEventListener('change', onSeekInput);
            }
            scrubber.removeEventListener('touchend', onSeekEnd);
            scrubber.removeEventListener('mouseup', onSeekEnd);
        };
    }, []);

    return (
        <SeekBarContainer>
            <TimeIndicator data-test-id={'player-seekbar-elapsed-time'} ref={currentTimeRef}>
                0:00:00
            </TimeIndicator>
            <ProgressAndScrubber>
                <ProgressBar data-test-id={'player-progress-bar'}>
                    <BufferLine ref={bufferLineRef} />
                    <ProgressLine ref={progressLineRef} />
                </ProgressBar>
                <SeekBarValue ref={scrubberValueRef}>0:00:00</SeekBarValue>
                <ThumbnailContainer ref={thumbnailRef}>
                    <ThumbnailImage ref={thumbnailImageRef} src={''} positionX={0} positionY={0} />
                </ThumbnailContainer>
                <Scrubber
                    type={'range'}
                    step={'any'}
                    data-test-id={'player-scrubber'}
                    min={seekMin ?? 0}
                    max={seekMax ?? 1}
                    defaultValue={seekValue}
                    disabled={!enabled}
                    ref={scrubberRef}
                />
            </ProgressAndScrubber>
            <TimeIndicator data-test-id={'player-seekbar-remaining-time'} ref={remainingTimeRef}>
                0:00:00
            </TimeIndicator>
        </SeekBarContainer>
    );
};
