import { Paginated } from '../../types/ApiTypes';
import { Loader } from '../../utils/ChunkLoader/Loader';
import { ChunkLoaderData } from '../../utils/ChunkLoader/ChunkLoaderData';
import { Datasource } from '../../types/Config';
import { Collection } from '../../utils/fnParser';
import { CoverAsset } from '../../types/Asset';
import { MiniEpgChunkDataLoader } from './MiniEpgChunkDataLoader';
import { isPlaceholder } from '../../utils/fnTypeGuards';

export class MiniEpgDataLoader extends Loader {
    private readonly dataSource: Datasource;

    private readonly dataParser;

    constructor(dataSource: Datasource, dataParser, pageSize: number) {
        super(pageSize);

        this.dataSource = dataSource;
        this.dataParser = dataParser;

        this.collectionData = new ChunkLoaderData();
    }

    protected createLoaderInstance = (pagination: Paginated) => {
        return new MiniEpgChunkDataLoader(this.dataSource, this.dataParser, pagination);
    };

    protected createLoader = (
        pagination: Paginated,
        data: Collection<CoverAsset>,
        invokeRequest: boolean = true,
        fillWithPlaceholder: boolean = false
    ) => {
        const loaderId = JSON.stringify(pagination);

        const loader = this.createLoaderInstance(pagination)
            .setRequestResponseCallback((result, error) => {
                this.responseHandler(result, error);
            })
            .fillWithPlaceholder(fillWithPlaceholder);

        if (invokeRequest) {
            loader.request().catch();
        }

        this.chunkLoaderMap[loaderId] = loader;
        this.pageIndex += 1;
    };

    public setTotal = (toSet: number) => {
        this.collectionData.setTotalCount(toSet);
    };

    public generatePlaceholderChunks = (totalCount: number) => {
        const numberOfPages = Math.ceil(totalCount / this.pageSize);

        for (let i = 0; i < numberOfPages; i += 1) {
            const offset = i * this.pageSize;
            let size;

            if (offset + this.pageSize >= totalCount) {
                size = totalCount - offset;
            } else {
                size = this.pageSize;
            }

            this.createLoader(
                {
                    start: offset,
                    limit: size,
                },
                null,
                false,
                true
            );
        }

        return this;
    };

    public loadDataFor = (startIndex: number, endIndex: number) => {
        const loaderKeys: string[] = [];

        Object.keys(this.chunkLoaderMap).forEach(key => {
            const keyObj: Paginated = JSON.parse(key);

            const intervalStart = keyObj.start;
            const intervalEnd = keyObj.start + keyObj.limit;

            if (startIndex >= intervalStart && startIndex <= intervalEnd) {
                loaderKeys.push(key);
            }

            if (endIndex >= intervalStart && endIndex <= intervalEnd && !loaderKeys.includes(key)) {
                loaderKeys.push(key);
            }
        });

        if (loaderKeys.length) {
            loaderKeys.forEach(loaderKey => {
                const loader = this.chunkLoaderMap[loaderKey];
                const data = loader.getData();

                if (data && data?.items?.[0] && isPlaceholder(data?.items?.[0])) {
                    loader.request().catch(() => {});
                }
            });
        }
    };
}
