import Keycloak from 'keycloak-js';
import * as Sentry from '@sentry/react';
import { setLocalStorage, getLocalStorage, removeLocalStorage } from 'utils/fnStorage';
import ApplicationConfig from '../useConfig/ApplicationConfig';
import { FetchResponse } from '../../types/ApiTypes';

const NOT_AUTHENTICATED = 401;
let keycloak: Keycloak.KeycloakInstance = null;

const captureAuthError = e => {
    console.warn(e);
    Sentry.captureException(e);
};

export const tokenRequestHeader = () => {
    return {
        Authorization: `Bearer ${keycloak.token}`,
        'Content-Type': 'application/json',
    };
};

export const init = (onInitCallback: (authenticated, accessToken?) => void, onError: () => void) => {
    // @ts-ignore
    keycloak = new Keycloak({
        url: ApplicationConfig.api_config.routes.Auth.auth,
        realm: ApplicationConfig.app_config.auth.realm,
        clientId: ApplicationConfig.app_config.auth.client_id,
    });

    keycloak.onTokenExpired = async () => {
        try {
            const updateTokenResult = await keycloak.updateToken(5);

            if (!updateTokenResult) {
                onError();
                return null;
            }

            return updateTokenResult;
        } catch (e) {
            onError();
            return null;
        }
    };

    const fetchGuestTokens = async () => {
        const URL = `${ApplicationConfig.api_config.routes.Auth.auth}/realms/${ApplicationConfig.app_config.auth.realm}/protocol/openid-connect/token`;
        try {
            const response = await fetch(URL, {
                method: 'post',
                body: `grant_type=password&client_id=${ApplicationConfig.app_config.auth.client_id}&scope=guest`,
                headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            });
            const parsedResponse = await response.json();

            return parsedResponse as { access_token: string; refresh_token: string };
        } catch (e) {
            return { access_token: '', refresh_token: '' };
        }
    };

    const tryInit = () => {
        const guestToken = getLocalStorage('guestToken');
        const guestRefreshToken = getLocalStorage('guestRefreshToken');

        return new Promise((resolve, reject) => {
            const initOptions = {
                checkLoginIframe: false,
                ...(guestToken && { token: guestToken }),
                ...(guestRefreshToken && { refreshToken: guestRefreshToken }),
            };
            keycloak
                .init(initOptions)
                .then(authenticated => {
                    if (authenticated) {
                        removeLocalStorage('guestRefreshToken');
                        removeLocalStorage('guestToken');

                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
                .catch(e => {
                    removeLocalStorage('guestRefreshToken');
                    removeLocalStorage('guestToken');

                    onError();
                    captureAuthError(e);
                    reject();
                });
        });
    };

    tryInit().then(async authenticated => {
        const forceLogin = getLocalStorage('forceLogin');

        if (!authenticated) {
            if (!forceLogin) {
                const token = await fetchGuestTokens();
                setLocalStorage('guestToken', token.access_token);
                setLocalStorage('guestRefreshToken', token.refresh_token);
                // removeLocalStorage('forceLogin');

                tryInit()
                    .then(loginAuthenticated => {
                        onInitCallback(loginAuthenticated);
                    })
                    .catch(value => console.log(value));
            } else {
                onInitCallback(authenticated);
            }
        } else {
            onInitCallback(authenticated, keycloak.token);
            setLocalStorage('forceLogin', true);
        }
    });
};

export const login = (options, callback: (authenticated) => void) => {
    keycloak
        .login(options)
        .then(authenticated => {
            if (callback) {
                callback(authenticated);
            }
        })
        .catch(() => callback(false));
};

export const logout = (callback: (authenticated) => void) => {
    keycloak
        .logout()
        .then(authenticated => callback(authenticated))
        .catch(() => callback(false));
};

export const userInfo = (callback: (userInfo) => void) => {
    keycloak
        .loadUserInfo()
        .then(info => callback(info))
        .catch(() => callback(null));
};

export const executeWithUserToken = async <T>(toExecute: () => Promise<FetchResponse<T>>) => {
    try {
        const result = await toExecute();

        if (result.status !== NOT_AUTHENTICATED) {
            return result;
        }
    } catch (exception) {
        // TODO: check level of status
        if (exception?.status === NOT_AUTHENTICATED || exception?.response?.status === NOT_AUTHENTICATED) {
            let refreshToken;

            try {
                refreshToken = await keycloak.updateToken(0);
            } catch (e) {
                if (e) {
                    await keycloak.logout(null);
                    return toExecute();
                }
            }

            if (refreshToken) {
                return toExecute();
            }
        }
    }

    keycloak.logout(null);

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject<any>(null);
};

export const fetchCastTokens = async accessToken => {
    const URL = `${ApplicationConfig.api_config.routes.Auth.auth}/realms/${ApplicationConfig.app_config.auth.realm}/protocol/openid-connect/token`;
    try {
        const response = await fetch(URL, {
            method: 'post',
            body: `grant_type=urn:ietf:params:oauth:grant-type:token-exchange&client_id=${ApplicationConfig.app_config.auth.google_cast_client_id}&subject_token=${accessToken}&subject_token_type=urn:ietf:params:oauth:token-type:access_token&requested_token_type=urn:ietf:params:oauth:token-type:refresh_token`,
            headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' }),
        });
        const parsedResponse = await response.json();

        return parsedResponse as { access_token: string; refresh_token: string };
    } catch (e) {
        return { access_token: '', refresh_token: '' };
    }
};

export const tokens = () => {
    return {
        accessToken: keycloak.token,
        refreshToken: keycloak.refreshToken,
    };
};
