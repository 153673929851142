import {
    Broadcast,
    Channel,
    CoverAsset,
    DetailEpisode,
    Episode,
    Movie,
    MovieDetails,
    NowAndNext,
    Person,
    PlaceholderAsset,
    ProgramDetails,
    Recording,
    RecordingDetails,
    Series,
    TVSeriesDetailEpisode,
} from '../types/Asset';
import {
    CatchupPlayerAssetInfo,
    LivePlayerAssetInfo,
    PlayingAsset,
    PlayingAssetType,
    RecordingPlayerAssetInfo,
    TrailerPlayerAssetInfo,
    VodPlayerAssetInfo,
} from '../types/Player';
import { AssetTypes } from '../types/Common';
import { AiringTime, Rentable, Subscribable, TVEpisodeInfo } from '../types/CommonTypes';

export function isPlaceholder(cover: CoverAsset): cover is PlaceholderAsset {
    return (cover as PlaceholderAsset).type === AssetTypes.placeholder;
}

export function isChannel(cover: CoverAsset): cover is Channel {
    return (cover as Channel).type === AssetTypes.channel;
}

export function isBroadcast(cover: CoverAsset): cover is Broadcast {
    return (cover as Broadcast).type === AssetTypes.program;
}

export function isRecording(cover: CoverAsset): cover is Recording {
    return (cover as Recording).type === AssetTypes.recording;
}

export function isTVEpisodeBroadcast(asset: any): asset is TVEpisodeInfo {
    const tvEpisodeInfo = asset as TVEpisodeInfo;
    return tvEpisodeInfo?.seriesId != null && tvEpisodeInfo?.episodeId != null;
}

export function isEpisodeRecording(recording: Recording) {
    return recording.seriesId != null;
}

export function isNowAndNext(cover: CoverAsset): cover is NowAndNext {
    return (cover as NowAndNext).type === AssetTypes.now_next;
}

export function isMovie(cover: CoverAsset): cover is Movie {
    return (cover as Movie).type === AssetTypes.movie;
}

export function isSeries(cover: CoverAsset): cover is Series {
    return (cover as Series).type === AssetTypes.series;
}

export function isEpisode(cover: CoverAsset): cover is Episode {
    return (cover as Episode).type === AssetTypes.episode || (cover as Episode).type === AssetTypes.tv_series_episode;
}

export function isVODSeriesEpisode(cover: CoverAsset): cover is DetailEpisode {
    return (cover as DetailEpisode).type === AssetTypes.episode;
}

export function isTvSeriesEpisode(cover: CoverAsset): cover is TVSeriesDetailEpisode {
    return (cover as TVSeriesDetailEpisode).type === AssetTypes.tv_series_episode;
}

export function isPerson(cover: CoverAsset): cover is Person {
    return (cover as Person).type === AssetTypes.person;
}

export function isMovieDetails(asset): asset is MovieDetails {
    return (asset as MovieDetails).type === AssetTypes.movie;
}

export function isProgramDetails(asset: any): asset is ProgramDetails {
    return (asset as ProgramDetails).type === AssetTypes.program;
}

export function isRecordingDetails(asset: any): asset is RecordingDetails {
    return (asset as RecordingDetails).type === AssetTypes.recording;
}

export function isRentable(asset): asset is Rentable {
    return (asset as Rentable).vodAssetState != null;
}

export function isSubscribable(asset): asset is Subscribable {
    return (asset as Subscribable).subscribeOffer != null;
}

export function isWithAiringTime(asset): asset is AiringTime {
    const airing = asset as AiringTime;
    return 'startTime' in airing && 'endTime' in airing;
}

export function isLivePlayerAsset(asset: PlayingAsset): asset is LivePlayerAssetInfo {
    return asset && (asset as LivePlayerAssetInfo).type === PlayingAssetType.LIVE;
}

export function isCatchupPlayerAsset(asset: PlayingAsset): asset is CatchupPlayerAssetInfo {
    return asset && (asset as CatchupPlayerAssetInfo).type === PlayingAssetType.CATCHUP;
}

export function isRecordingPlayerAsset(asset: PlayingAsset): asset is RecordingPlayerAssetInfo {
    return asset && (asset as RecordingPlayerAssetInfo).type === PlayingAssetType.RECORDING;
}

export function isTrailerPlayerAsset(asset: PlayingAsset): asset is TrailerPlayerAssetInfo {
    return asset && (asset as TrailerPlayerAssetInfo).type === PlayingAssetType.TRAILER;
}

export function isVodPlayerAsset(asset: PlayingAsset): asset is VodPlayerAssetInfo {
    return asset && (asset as VodPlayerAssetInfo).type === PlayingAssetType.VOD;
}
