import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { CardWrapper } from '../../Lane/Lane.css';
import { hexToRgba } from '../../../utils/fnColors';
import { MQ_NOT_TOUCH } from '../../../style/styled-components/cssMediaQueries';
import { fontBold } from '../../../style/styled-components/cssGlobalVariables';

export const channelCardWidth = 176;
export const channelCardHeight = 99;

export const channelCardHoveredWidth = 204;
export const channelCardHoveredHeight = 115;

export const animationType = 'ease-in-out';
export const animationDuration = '150ms';
export const animationDelay = '150ms';

export const ImageWrapper = styled.div`
    position: absolute;
    width: calc(100% - 64px);
    height: calc(100% - 32px);
    overflow: hidden;
    left: 32px;
    top: 16px;

    img {
        width: 100%;
        object-fit: contain;
    }
`;

export const ChannelNumber = styled.span`
    position: absolute;
    left: 8px;
    top: 8px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 12px;
    line-height: 16px;
    font-family: ${fontBold};
`;

export const ChannelIcon = styled.span`
    position: absolute;
    right: 8px;
    top: 8px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 12px;
    line-height: 16px;
    font-family: ${fontBold};

    svg {
        path {
            fill: ${props => props.theme.brandedConfig.high_contrast};
        }
    }
`;

export const ChannelFallbackTitle = styled.span`
    position: absolute;
    width: calc(100% - 64px);
    left: 32px;
    top: 50%;
    transform: translateY(-50%);
    color: ${props => props.theme.brandedConfig.high_contrast};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    font-family: ${fontBold};
`;

export const CardChannelWrapperInner = styled.div`
    width: ${channelCardWidth}px;
    height: ${channelCardHeight}px;
    border-radius: 5px;
    background: ${props => props.theme.brandedConfig.low_contrast};
    opacity: 0.9;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    box-shadow: 0 4px 8px ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.05)};
    transition: width ${animationDuration} ${animationType} ${animationDelay},
        height ${animationDuration} ${animationType} ${animationDelay}, top ${animationDuration} ${animationType} ${animationDelay},
        left ${animationDuration} ${animationType} ${animationDelay};

    ${MQ_NOT_TOUCH} {
        &:hover {
            opacity: 1;
            border: 2px solid ${props => props.theme.brandedConfig.white_color};
            width: ${channelCardHoveredWidth}px;
            height: ${channelCardHoveredHeight}px;
            top: -16px;
            left: ${(channelCardWidth - channelCardHoveredWidth) / 2}px;
        }
    }
`;

export const ChannelBundleWrapper = styled.div`
    display: inline-block;
    vertical-align: top;
    scroll-snap-align: start;
    scroll-snap-stop: normal;
    margin-left: 8px;
    margin-right: 8px;
    height: 100%;

    &:first-child {
        margin-left: 0;
    }

    ${CardWrapper} {
        margin: 16px 0 0 0;
        display: block;

        &:first-child {
            margin-top: 0;
        }
    }
`;
