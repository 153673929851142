import SVGInline from 'react-svg-inline';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { MQ_NOT_TOUCH } from '../../style/styled-components/cssMediaQueries';

export const CastIcon = styled(SVGInline)`
    width: auto;
`;

export const CastButtonWrapper = styled.div`
    cursor: pointer;
    float: right;

    ${MQ_NOT_TOUCH} {
        &:hover {
            opacity: 0.7;
        }
    }
`;
