import React, { createContext, FC, MutableRefObject, useCallback, useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { HintModalComponent } from '../../components/HintModal/HintModal';
import translate from '../../utils/fnTranslate';
import { getLocalStorage, setLocalStorage } from '../../utils/fnStorage';

type positionOffsetType = {
    desktop: number;
    tablet: number;
    mobile: number;
};

type ContextStateType = {
    ref: HTMLElement;
    userDismissedLocalStorageKey: any;
    verticalOffset?: positionOffsetType;
    horizontalOffset?: positionOffsetType;
    text: string;
}[];

type ContextType = {
    state: ContextStateType;
    setState: (state: ContextStateType) => void;
};

export const HintContext = createContext<ContextType>(null);

export const HintProvider = ({ children }) => {
    const [state, setState] = useState<ContextStateType>([]);

    return <HintContext.Provider value={{ state, setState }}>{children}</HintContext.Provider>;
};

export const useHint = (
    hintText: string,
    attachToElement: MutableRefObject<HTMLElement>,
    userDismissedLocalStorageKey: string,
    condition = true,
    verticalOffset?: positionOffsetType,
    horizontalOffset?: positionOffsetType
) => {
    const { state, setState } = useContext(HintContext);

    const detachHint = () => {
        setState([]);
    };

    const attachHint = useCallback(() => {
        if (attachToElement.current && condition) {
            const hint = {
                ref: attachToElement.current,
                text: hintText,
                userDismissedLocalStorageKey,
                verticalOffset: typeof verticalOffset !== 'undefined' ? verticalOffset : { desktop: 0, tablet: 0, mobile: 0 },
                horizontalOffset: typeof verticalOffset !== 'undefined' ? horizontalOffset : { desktop: 0, tablet: 0, mobile: 0 },
            };
            setState([...state, hint]);
        }
    }, [state]);

    useEffect(() => {
        detachHint();
    }, [window.location.href]);

    return { detachHint, attachHint };
};

export const HintModal: FC = () => {
    const { state } = useContext(HintContext);
    const [inViewRef, isInView] = useInView({ threshold: 0.5 });
    const [shouldOpen, setShouldOpen] = useState<boolean>(true);

    useEffect(() => {
        if (!state?.length) {
            setShouldOpen(true);
            return;
        }
        const { ref } = state[0];
        if (ref) {
            inViewRef(ref);
        }
    }, [state]);

    if (state?.length > 0) {
        const { ref, text, userDismissedLocalStorageKey, horizontalOffset, verticalOffset } = state[0];
        const userDismissedHint = getLocalStorage(userDismissedLocalStorageKey);

        return (
            <HintModalComponent
                modalText={text}
                attachTo={ref}
                position={'top left'}
                verticalOffset={verticalOffset}
                horizontalOffset={horizontalOffset}
                visible={isInView && shouldOpen && !userDismissedHint}
                closeHint={() => setShouldOpen(false)}
                buttons={[
                    {
                        text: translate('OK_BUTTON'),
                        onClick: () => {
                            setShouldOpen(false);
                        },
                    },
                    {
                        text: translate('DONT_SHOW_AGAIN_BUTTON'),
                        onClick: () => {
                            setLocalStorage(userDismissedLocalStorageKey, true);
                            setShouldOpen(false);
                        },
                    },
                ]}
            />
        );
    }

    return null;
};
