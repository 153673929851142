import { TimeCluster } from '../hooks/useBroadcastTimeCluster/useBroadcastTimeCluster';

export type RecordingPlayerInitiator = 'recording' | 'broadcast';

export enum RecordingEvent {
    MANAGE = 'MANAGE',
    ADD = 'ADD',
    DELETE = 'DELETE',
}

export type RecordingType = 'RECORDED' | 'ONGOING' | 'SCHEDULED';

export type RecordingRelationRef = {
    id: string;
    eventId: string;
};

export type RecordingRef = {
    eventId: string;
    recordingId?: string;
    type?: RecordingType;
};

export type RecordingQuota = {
    all: number;
    remaining: number;
};

export const TimeClusterRecordingTypeMap: { [key in TimeCluster]: RecordingType } = {
    PAST: 'RECORDED',
    LIVE: 'ONGOING',
    FUTURE: 'SCHEDULED',
};
