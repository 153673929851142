export enum AssetTypes {
    movie = 'movie',
    episode = 'episode',
    tv_series_episode = 'tv_series_episode',
    series = 'series',
    channel = 'channel',
    program = 'program',
    person = 'person',
    now_next = 'now_next',
    recording = 'recording',
    placeholder = 'placeholder',
}

export enum CardContext {
    STRIPE = 'stripe',
    COLLECTION = 'collection',
}

export enum StripeTypes {
    EDITORIAL = 'editorial',
    GALLERY = 'gallery',
    // @Deprecated use PORTRAIT
    MOVIE = 'movie',
    PORTRAIT = 'portrait',
    MIXED = 'mixed',
    // @Deprecated, use ROUNDED
    PERSON = 'person',
    ROUNDED = 'rounded',
    EPISODE = 'episode',
    TV_CHANNEL = 'tv_channel',
    NOW_NEXT = 'tv_now_next',
}

export enum ModuleTypes {
    STRIPE = 'stripe',
    GALLERY = 'gallery',
    COLLECTION = 'collection',
    REPEATER = 'repeater',
    EPG = 'epg',
}

export type HelmetData = {
    title: string;
    openGraph: {
        'og:title': string;
        'og:site_name': string;
        'og:locale': string;
        'og:url'?: string;
        'og:type'?: string;
        'og:image'?: string;
        'og:description'?: string;
    };
    canonicalLink?: string;
    description?: string;
    robots?: string | 'all' | 'none'; // all possible values: https://ahrefs.com/blog/meta-robots/
};

export type AspectRatio = {
    width: number;
    height: number;
};

export enum KeyboardActions {
    ENTER = 'Enter',
}
