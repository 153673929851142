import React, { FC, ReactNode, useCallback } from 'react';
import { BroadcastPlayerAsset, PlayerControl, RecordingPlayerAssetInfo } from '../../types/Player';
import {
    CloseButton,
    OptionContainer,
    RecordingActionWindowMobileWrapper,
    RecordingBlock,
    RecordingPopupContentWrapper,
    RecordingPopupWrapper,
    StyledSVGInline,
} from './PlayerRecordingActionWindow.css';
import { useBroadcastTimeCluster } from '../../hooks/useBroadcastTimeCluster/useBroadcastTimeCluster';
import { useRecording } from '../../providers/useRecording/RecordingContext';
import { ControlGroup } from '../Player/PlayerWrapper';
import { usePlayer } from '../../providers/player/PlayerContext';
import { DropdownItemSimple, DropdownMenu } from '../DropdownMenu/DropdownMenu';
import translate from '../../utils/fnTranslate';
import icons from '../../style';
import { isRecordingPlayerAsset } from '../../utils/fnTypeGuards';

const MAX_FLYOUT_HEIGHT = 348;

const useRecordingAction = (asset: BroadcastPlayerAsset | RecordingPlayerAssetInfo) => {
    const timeCluster = useBroadcastTimeCluster(asset);
    const { deleteRecordingByEventId, deleteRecording, createRecording, subscribe } = useRecording();

    const onRecordingOptionClicked = useCallback(() => {
        if (isRecordingPlayerAsset(asset)) {
            deleteRecording(asset.id, 'PAST');
        } else if (asset.isRecordingSubscribed) {
            if (asset.isRecorded) {
                deleteRecordingByEventId(asset.id, timeCluster);
            } else {
                createRecording(asset.id, timeCluster);
            }
        } else {
            subscribe(asset.recordingProductCode);
        }
    }, [asset, timeCluster]);

    const getOptionText = useCallback(() => {
        switch (timeCluster) {
            case 'PAST':
                return translate(asset.isRecorded ? 'DELETE_RECORDING_BUTTON' : null);
            case 'LIVE':
                return translate(asset.isRecorded ? 'STOP_RECORDING_BUTTON' : 'RECORD_BUTTON');
            default:
                return null;
        }
    }, [asset, timeCluster]);

    return {
        onRecordingOptionClicked,
        getOptionText,
    };
};

export const PlayerRecordingActionPopup: FC<{ triggerButton: ReactNode; asset: BroadcastPlayerAsset | RecordingPlayerAssetInfo }> = ({
    triggerButton,
    asset,
}) => {
    const { setActiveControlGroup } = usePlayer();
    const { getOptionText, onRecordingOptionClicked } = useRecordingAction(asset);

    const visibilityChange = (isVisible: boolean) => {
        if (isVisible) {
            setActiveControlGroup(ControlGroup.RECORDING);
        } else {
            setActiveControlGroup(ControlGroup.BASE);
        }
    };

    return (
        <RecordingPopupWrapper>
            <DropdownMenu
                triggerButton={triggerButton}
                horizontalPosition={'left'}
                verticalPosition={'top'}
                maxHeight={MAX_FLYOUT_HEIGHT}
                stripMobilePortal={true}
                visibilityChangeCallback={visibilityChange}
            >
                <RecordingPopupContentWrapper>
                    <RecordingBlock>
                        <OptionContainer>
                            <DropdownItemSimple key={'recording'} selected={false} onClick={onRecordingOptionClicked}>
                                {getOptionText()}
                            </DropdownItemSimple>
                        </OptionContainer>
                    </RecordingBlock>
                </RecordingPopupContentWrapper>
            </DropdownMenu>
        </RecordingPopupWrapper>
    );
};

export const PlayerRecordingActionWindowMobile: FC<{ asset: BroadcastPlayerAsset | RecordingPlayerAssetInfo }> = ({ asset }) => {
    const { setActiveControlGroup, activeControlGroup } = usePlayer();
    const { getOptionText, onRecordingOptionClicked } = useRecordingAction(asset);

    if (activeControlGroup !== ControlGroup.RECORDING) {
        return null;
    }

    const closeOverlay = () => {
        setActiveControlGroup(ControlGroup.BASE);
    };

    const onButtonClick = () => {
        closeOverlay();
        onRecordingOptionClicked();
    };

    return (
        <RecordingActionWindowMobileWrapper>
            <CloseButton key={PlayerControl.CLOSE}>
                <StyledSVGInline svg={icons.closeIcon} onClick={closeOverlay} />
            </CloseButton>
            <RecordingPopupContentWrapper>
                <DropdownItemSimple key={'recording'} selected={false} onClick={onButtonClick}>
                    {getOptionText()}
                </DropdownItemSimple>
            </RecordingPopupContentWrapper>
        </RecordingActionWindowMobileWrapper>
    );
};
