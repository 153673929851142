import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { usePlayer } from '../../../providers/player/PlayerContext';
import { PlayerWrapper } from '../../Player/PlayerWrapper';
import { useDataFetcher, useRequestCanceller } from '../../../hooks/useDataFetcher/useDataFetcher';
import { PlayerEvents, RecordingPlayerAssetInfo } from '../../../types/Player';
import Api from '../../../api/Api';
import { PageErrorNotFound } from '../PageErrorNotFound/PageErrorNotFound';
import { useKPI, useTrackingScreen } from '../../../providers/useTracking/TrackingContext';
import { useAuth } from '../../../providers/useAuth/AuthContext';
import { FetchPlayingAsset } from '../../../types/ApiTypes';
import { StaticPageRoutes } from '../../../types/RouteTypes';
import { useGlobalNetworkError, useGlobalNoInternet, withNetworkCheck } from '../../../hooks/withNetworkCheck/withNetworkCheck';
import { HelmetWrapper } from '../../HelmetWrapper/HelmetWrapper';
import { getPlayerPageHelmetData } from '../../../utils/fnHelmet';
import { withPlayer } from '../../../hooks/withPlayer/withPlayer';
import { useRecording } from '../../../providers/useRecording/RecordingContext';
import { RecordingEvent, RecordingPlayerInitiator } from '../../../types/RecordingTypes';
import { useNavigation } from '../../../hooks/useNavigation';
import { useApp } from '../../../providers/useApp/AppContext';

const INITIATOR = 'initiator';

const RecordingPlayerPage: FC<{ recordingId: string }> = ({ recordingId }) => {
    const { onBackPressed, history } = useNavigation();
    const { appHistory, historyLength, popDetailLocations } = useApp();

    const setCanceller = useRequestCanceller();
    const { onNoInternet } = useGlobalNoInternet();
    const { onNetworkError } = useGlobalNetworkError();

    const sessionCreated = useRef<boolean>(false);

    const { response, error, loading, fetcher } = useDataFetcher<RecordingPlayerAssetInfo, FetchPlayingAsset>(
        params => Api.fetchRecordingPlayerInfo(params.id, params.createSession, new axios.CancelToken(executor => setCanceller(executor))),
        onNoInternet,
        onNetworkError
    );

    const { isGuest, openGuestAlert } = useAuth();
    const {
        asset: currentPlayerAsset,
        setAsset,
        addEventListener,
        removeEventListener,
        resetPlayerAsset,
        isMini,
        closeMiniPlayer,
        setStreamEndReached,
        loaded,
    } = usePlayer();
    const trackScreen = useTrackingScreen(() => true);
    const { fullLoad } = useKPI('RecordingPlayer');
    const { addEventListener: addRecordingEventListener, removeEventListener: removeRecordingEventListener } = useRecording();

    const [initiator, setInitiator] = useState<RecordingPlayerInitiator>(null);

    const fetchDetails = () => {
        if (!isGuest) {
            fetcher(
                {
                    id: recordingId,
                    createSession: !sessionCreated.current,
                },
                true
            );
        }
    };

    const onStreamEndReached = () => {
        if (isMini) {
            closeMiniPlayer();
        } else {
            onBackPressed();
        }
    };

    const onRecordingDeleted = useCallback(() => {
        setStreamEndReached(true);

        if (historyLength === 1) {
            history.replace(!initiator || initiator === 'recording' ? StaticPageRoutes.RECORDINGS : '/');
        } else if (!initiator || initiator === 'recording') {
            let depth = 0;

            for (let i = historyLength - 1; i > 0; i -= 1) {
                if (appHistory[i].path !== StaticPageRoutes.RECORDINGS) {
                    depth += 1;
                } else {
                    break;
                }
            }

            history.go(-depth);
            popDetailLocations();
        } else {
            history.go(-1);
        }
    }, [initiator]);

    useEffect(() => {
        fullLoad.start();
    }, []);

    useEffect(() => {
        if (loaded) {
            fullLoad.stop();
        }
    }, [loaded]);

    useEffect(() => {
        const urlQueryParams = new URLSearchParams(history.location.search);
        setInitiator(urlQueryParams.get(INITIATOR) as RecordingPlayerInitiator);
    }, [history.location.search]);

    useEffect(() => {
        sessionCreated.current = false;
        resetPlayerAsset();
        fetchDetails();
        trackScreen('RecordingPlayer', { asset_id: recordingId });
    }, [recordingId]);

    useEffect(() => {
        if (response) {
            let assetToSet: RecordingPlayerAssetInfo = response;
            if (!sessionCreated.current) {
                sessionCreated.current = true;
            } else {
                assetToSet = {
                    ...response,
                    ...{
                        manifestUrl: currentPlayerAsset?.manifestUrl,
                    },
                };
            }
            setAsset(assetToSet);
        }
    }, [response]);

    useEffect(() => {
        addEventListener(PlayerEvents.END_OF_STREAM, onStreamEndReached);

        return () => {
            removeEventListener(PlayerEvents.END_OF_STREAM, onStreamEndReached);
        };
    }, [response, isMini]);

    useEffect(() => {
        if (isGuest) {
            openGuestAlert('/');
        }
        addRecordingEventListener(RecordingEvent.DELETE, onRecordingDeleted);
        return () => removeRecordingEventListener(RecordingEvent.DELETE, onRecordingDeleted);
    }, [recordingId, initiator]);

    if (error) {
        return <PageErrorNotFound />;
    }

    return (
        <>
            {response && <HelmetWrapper {...getPlayerPageHelmetData(response)} />}
            <PlayerWrapper loading={loading} />
        </>
    );
};

export default withNetworkCheck(withPlayer(RecordingPlayerPage));
