import { styled } from 'components/ThemeConfigConnector/ThemeConfigConnnector';
import { fontNormal } from 'style/styled-components/cssGlobalVariables';
import { ProgressBar, ProgressContainer } from '../../../ProgressIndicator/ProgressIndicator';

export const RecordingProgressIndicator = styled.div`
    position: relative;
    height: 8px;

    ${ProgressContainer} {
        background-color: ${props => props.theme.brandedConfig.white_color};
        bottom: unset;
        left: 0;
        right: 0;
        top: 0;
        border-radius: 8px;
        height: 8px;
    }

    ${ProgressBar} {
        background-color: ${props => props.theme.brandedConfig.primary};
    }
`;

export const RecordingProgressLabels = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    padding-bottom: 16px;
    padding-top: 16px;
    font-family: ${fontNormal};
`;

export const RecordingLabel = styled.div``;

export const Name = styled.span`
    display: inline-block;
    font-weight: bold;
    padding-right: 8px;

    &.highlighted {
        color: ${props => props.theme.brandedConfig.primary};
    }
`;

export const Value = styled.span`
    display: inline-block;
`;
