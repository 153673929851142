import SVGInline from 'react-svg-inline';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import logoImage from '../../assets/images/logo-with-background.svg';
import { fontBold, fontNormal, smartAppBannerZIndex } from '../../style/styled-components/cssGlobalVariables';
import { Breakpoints, MQ } from '../../style/styled-components/cssMediaQueries';

export const SmartAppBannerWrapper = styled.div<{ isFixedToTop: boolean }>`
    display: flex;
    background-color: ${props => props.theme.globalConfig.low_contrast};
    position: fixed;
    align-items: center;
    justify-content: space-between;
    top: ${props => (props.isFixedToTop ? '0' : '48px')};
    left: 0;
    right: 0;
    padding: 9px 12px;
    z-index: ${smartAppBannerZIndex};
    transition: top ease 1s;

    ${MQ(Breakpoints.m)} {
        top: 48px;
    }
`;

export const CloseButton = styled(SVGInline)`
    padding: 5px;
    cursor: pointer;
    flex: 0 1 auto;

    svg {
        display: block;
        width: 14px;
        height: 14px;

        path {
            fill: ${props => props.theme.globalConfig.high_contrast};
        }
    }
`;

export const Logo = styled.div`
    background: url(${logoImage});
    background-size: cover;
    width: 62px;
    height: 62px;
    border-radius: 14px;
    margin: 0 8px;
    flex: 0 0 auto;
`;

export const Description = styled.div`
    padding-right: 12px;
    font-family: ${fontNormal};
    color: ${props => props.theme.globalConfig.high_contrast};
    flex: 2 1 auto;
`;

export const Name = styled.p`
    line-height: 20px;
    font-size: 16px;
`;

export const HintText = styled.p`
    line-height: 16px;
    font-size: 10px;
    opacity: 0.7;
`;

export const ViewButton = styled.a`
    display: block;
    min-width: 74px;
    background-color: ${props => props.theme.globalConfig.high_contrast};
    color: ${props => props.theme.globalConfig.low_contrast};
    font-size: 14px;
    line-height: 20px;
    padding: 6px 20px;
    font-family: ${fontBold};
    border-radius: 16px;
    flex: 0 1 auto;
    text-align: center;

    &:hover {
        color: ${props => props.theme.globalConfig.low_contrast};
    }
`;
