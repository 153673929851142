import { format } from 'date-fns';
import { duration, ISO_8601 } from 'moment';
import { buildEpisodeTitle, getResolution } from './fnData';

import {
    Bookmark,
    Broadcast,
    Channel,
    CoverAsset,
    DetailEpisode,
    Episode,
    ExtraMetaData,
    Movie,
    MovieDetails,
    NowAndNext,
    Person,
    PersonRole,
    ProgramDetails,
    Recording,
    RecordingDetails,
    Resource,
    Season,
    Series,
    SeriesDetails,
    TVSeriesDetailEpisode,
} from '../types/Asset';
import translate from './fnTranslate';
import {
    CatchupPlayerAssetInfo,
    LivePlayerAssetInfo,
    PlaybackSession,
    PlayingAssetType,
    RecordingPlayerAssetInfo,
    TrailerPlayerAssetInfo,
    VodPlayerAssetInfo,
} from '../types/Player';
import {
    getImage,
    getSubscriptionImage,
    getTvEpisodeCardImage,
    getTvEpisodeDetailImage,
    getTvMovieCardImage,
    getTvMovieDetailImage,
    getTVSeriesCardImage,
    getVodEpisodeCardImage,
    getVodMovieCardImage,
    getVodMovieDetailImage,
    getVodSeriesCardImage,
    getVodSeriesDetailImage,
    ImageTypes,
} from './fnImages';
import {
    getNPVRProduct,
    getOffers,
    getPrice,
    getSubscribeOffer,
    getSubscriptionProductCode,
    getTVSubscribeOffer,
    getVodAssetEntitlementState,
    getVODEntitlementEnd,
    isChannelSubscribed,
    isNPVRSubscribedProduct,
} from './fnEntitlement';
import { EntitlementState, OrderSubscription, PurchasedProduct, SubscribeOption } from '../types/Entitlement';
import { formatDate, isFuture, isNow } from './fnDate';
import { MyWorldCategory, MyWorldItem, MyWorldRootCategory } from '../types/World';
import { AssetTypes } from '../types/Common';
import { MILLISECONDS, SECONDS } from './TimeUnit';
import { PlayOption, PlayOptionsData, Rentable } from '../types/CommonTypes';
import { EpgChannel, EpgProgram } from '../types/EpgTypes';
import { RecordingQuota, RecordingRelationRef } from '../types/RecordingTypes';

export const TIME_FORMAT_HH_MM = 'HH:mm';
export const PROG_TYPE = 'progtypeCS';
export const ADI_GENRE = 'AdiGenreCS';

enum ChannelPadding {
    PRE = 'guardTime.Pre',
    POST = 'guardTime.Post',
}

export type Collection<T> = {
    items: Array<T>;
    totalItems: number;
    scheduleReload: number;
    moreResources?: boolean;
};

const isBroadcast = (data): boolean => {
    return (
        data &&
        data?.Contents &&
        data.Contents?.Content &&
        (data.Contents.Content as Array<any>).filter(contentItem => {
            return 'TstvEvents' in contentItem;
        }).length > 0
    );
};
const isVod = (data): boolean => !isBroadcast(data);
const isEpisode = (data): boolean => data && 'SeriesCollection' in data;
const isMovie = (data): boolean => !isEpisode(data);
const isSeriesCollectionItem = (data): boolean =>
    data && 'CollectionItemResourceType' in data && data.CollectionItemResourceType === 'Series';
const isRecordingItem = (data): boolean => data && 'CollectionItemResourceType' in data && data.CollectionItemResourceType === 'Recording';

/**
 * Based on different checks decides if a card should be presented in landscape or portrait.
 *
 * First checks if there is a prog type and if it's present it's Movie kind.
 * If not checks if there is an adi genre custom property and if it's present if it's Movie kind.
 * The last check is if the title has series collection node so if it belongs to season / series or not.
 *
 * @param data
 */
const isPortrait = (data): boolean => {
    const progTypeGenre = (data?.AllGenres?.AllGenre ?? []).find(genre => genre.href.indexOf(PROG_TYPE) !== -1);
    const adiGenre = (data?.CustomProperties?.CustomProperty ?? []).find(property => property?.href.indexOf(ADI_GENRE) !== -1);

    if (progTypeGenre) {
        return progTypeGenre?.href?.endsWith('Movie');
    }

    if (adiGenre) {
        return adiGenre?.href?.endsWith('Movie');
    }

    // SeriesCollection is ABSENT
    return isMovie(data);
};

const getTitleGenres = (title: any) => {
    return title?.AllGenres?.AllGenre?.filter(genre => genre.href.indexOf(PROG_TYPE) === -1 && genre?.Value).map(genre => {
        return {
            name: genre.Value,
            href: genre.href,
        };
    });
};

const getFirstGenre = (data: any) => {
    return data?.AllGenres?.AllGenre?.filter(genre => genre.href.indexOf(PROG_TYPE) === -1)?.[0];
};

const getChannelPaddingSeconds = (channel: any, paddingType: ChannelPadding): number => {
    const paddingValue = channel?.CustomProperties?.CustomProperty?.find(property => property.href.endsWith(paddingType))?.Value;

    if (paddingValue) {
        // @ts-ignore
        return duration(paddingValue, ISO_8601).asSeconds();
    }

    return 0;
};

export const hasChannelReplay = (channel: any): boolean => {
    return channel?.Aliases?.Alias && channel.Aliases.Alias.find(alias => alias.type === 'HAS_REPLAY' && alias.Value === 'TRUE') != null;
};

const getContentByEvent = (title, eventId) => {
    return title?.Contents?.Content?.find(content => {
        return content?.TstvEvents?.TstvEvent?.[0]?.Events?.Event?.[0]?.id === eventId;
    });
};

const parseCastAndCrew = (cast: Person[], title?: any) => {
    // add director to cast
    if (title?.Directors?.Director) {
        title.Directors.Director.forEach(directorItem => {
            cast.push({
                id: 'no-id',
                type: AssetTypes.person,
                title: directorItem,
                role: PersonRole.DIRECTOR,
                image: null,
            });
        });
    }

    // add actors to cast
    if (title?.Actors?.Actor) {
        title.Actors.Actor.forEach(actorItem => {
            cast.push({
                id: 'no-id',
                type: AssetTypes.person,
                title: actorItem,
                role: PersonRole.ACTOR,
                image: null,
            });
        });
    }
};

const getClosestToNow = (contents: any[]) => {
    const now = Date.now();
    let content = contents?.[0];

    if (contents.length && contents.length > 1) {
        contents.forEach(contentItem => {
            const currentContentLiveDistance = Math.abs(now - (content ? Date.parse(content.FirstAvailability) : Number.MAX_VALUE));
            const toCompareLiveDistance = Math.abs(now - Date.parse(contentItem.FirstAvailability));

            if (toCompareLiveDistance < currentContentLiveDistance) {
                content = contentItem;
            }
        });
    }

    return content;
};

const getClosestTsTVEventToNow = (title: any) => {
    const contents =
        title?.Contents?.Content?.filter(contentItem => {
            return contentItem?.TstvEvents?.TstvEvent[0]?.Events;
        }) ?? [];

    const filteredEntitledContent = contents.filter(content => content.EntitlementState === EntitlementState.ENTITLED);
    const filteredNotEntitledContent = contents.filter(content => content.EntitlementState === EntitlementState.NOT_ENTITLED);

    if (!filteredEntitledContent.length && !filteredNotEntitledContent.length) {
        return getClosestToNow(contents);
    }

    const entitledPast = filteredEntitledContent.filter(
        content => !isFuture(Date.parse(content?.TstvEvents?.TstvEvent?.[0]?.AvailabilityStart))
    );

    if (entitledPast.length) {
        return getClosestToNow(entitledPast);
    }

    const notEntitledPast = filteredNotEntitledContent.filter(
        content => !isFuture(Date.parse(content?.TstvEvents?.TstvEvent?.[0]?.AvailabilityStart))
    );

    if (notEntitledPast.length) {
        return getClosestToNow(notEntitledPast);
    }

    const entitledFuture = filteredEntitledContent.filter(content =>
        isFuture(Date.parse(content?.TstvEvents?.TstvEvent?.[0]?.AvailabilityStart))
    );

    if (entitledFuture.length) {
        return getClosestToNow(entitledFuture);
    }

    const notEntitledFuture = filteredNotEntitledContent.filter(content =>
        isFuture(Date.parse(content?.TstvEvents?.TstvEvent?.[0]?.AvailabilityStart))
    );

    if (notEntitledFuture.length) {
        return getClosestToNow(notEntitledFuture);
    }

    return null;
};

const channelCardItemParser = (data: any): Channel => {
    const isChannelEntitled = isChannelSubscribed(data);
    const isChannelFavorite = data?.OnFavorites;
    const isChannelHd = data?.IsHD;

    return {
        id: data?.id,
        logo: getImage(data, ImageTypes.LOGO_ANDROID),
        type: AssetTypes.channel,
        number: data?.LogicalChannelNumber,
        name: data?.Name,
        subscription: isChannelEntitled,
        favorite: isChannelFavorite,
        isHd: isChannelHd,
        replay: hasChannelReplay(data),
        productCode: getSubscriptionProductCode(data?.Products?.Product?.[0]),
    };
};

export const getPlayOptions = (contents: any, backdropImage: string, bookmark: number = 0): PlayOptionsData => {
    const options: PlayOption[] = [];

    if (contents?.length) {
        contents.forEach(content => {
            const tstvEvent = content?.TstvEvents?.TstvEvent?.[0];
            const channel = tstvEvent?.Channels?.Channel?.[0];
            const event = tstvEvent?.Events?.Event?.[0];

            options.push({
                id: event?.id,
                startTime: tstvEvent?.AvailabilityStart ? Date.parse(tstvEvent?.AvailabilityStart) : -1,
                endTime: tstvEvent?.AvailabilityEnd ? Date.parse(tstvEvent?.AvailabilityEnd) : -1,
                channel: channelCardItemParser(channel),
                isLive: event?.IsLive,
                bookmark,
                isRecorded: event?.IsRecorded,
                recordingProductCode: null,
                isRecordingSubscribed: null,
            });
        });
    }

    return {
        options,
        backdropImage,
    };
};

export const genericCollectionParser = <T>(data: any, parser: (item: any) => T): Collection<T> => {
    const resp = {
        items: [],
        totalItems: 0,
        scheduleReload: Infinity,
    };

    const category = data.Category;

    resp.totalItems =
        (category?.Titles?.resultCount ?? 0) + (category?.SeriesCollection?.resultCount ?? 0) + (category?.Recordings?.resultCount ?? 0);

    // titles
    const titles =
        category?.Titles?.Title?.map(item => {
            return parser(item);
        }) ?? [];

    // series
    const series =
        category?.SeriesCollection?.Series?.map(item => {
            return parser(item);
        }) ?? [];

    // recordings
    const recordings =
        category?.Recordings?.Recording?.map(item => {
            return parser(item);
        }) ?? [];

    resp.items = [...titles, ...series, ...recordings];

    return resp;
};

const nowAndNextCardItemParser = (data: any): NowAndNext => {
    const runningEvent = data.Pf?.Event?.[0];
    const upcomingEvent = data.Pf?.Event?.[1];

    const buildTitle = (broadcast: any): string => {
        if (!broadcast) {
            return translate('PLAYER_NO_INFO');
        }

        return `${format(Date.parse(broadcast.AvailabilityStart), TIME_FORMAT_HH_MM)} ${broadcast.Titles?.Title?.[0]?.Name}`;
    };

    const runningProgramTitle = runningEvent?.Titles?.Title?.[0];

    const liveAsset = runningEvent?.Titles?.Title?.[0];
    const series = liveAsset?.SeriesCollection?.Series?.[0]?.ParentSeriesCollection?.Series?.[0];

    const image = series ? getTvEpisodeCardImage(liveAsset, series) : getTvMovieCardImage(liveAsset);
    const channelLogo = getImage(data, ImageTypes.LOGO_ANDROID);

    const seriesId = series?.id;
    const episodeId = runningProgramTitle?.id;

    return {
        id: runningEvent?.id,
        channelId: data?.id,
        channelLogo,
        channelName: data?.Name,
        channelNumber: data?.LogicalChannelNumber,
        isChannelSubscribed: isChannelSubscribed(data),
        image,
        startTime: runningEvent?.AvailabilityStart ? Date.parse(runningEvent?.AvailabilityStart) : -1,
        endTime: runningEvent?.AvailabilityEnd ? Date.parse(runningEvent?.AvailabilityEnd) : -1,
        type: AssetTypes.now_next,
        title: buildTitle(runningEvent),
        nextBroadcastTitle: buildTitle(upcomingEvent),
        seriesId,
        episodeId,
        isLive: runningEvent?.IsLive ?? false,
        bookmark: liveAsset?.Bookmark ?? 0,
        playOptions: null,
        subscribeOffer: getTVSubscribeOffer(data),
    };
};

export const nowAndNextCollectionParser = (data: any): Collection<NowAndNext> => {
    const resp = {
        items: [] as NowAndNext[],
        totalItems: 0,
        scheduleReload: Infinity,
    };

    resp.totalItems = data?.Channels?.resultCount;
    resp.items = data.Channels.Channel.map(item => {
        // eslint-disable-next-line no-use-before-define
        return nowAndNextCardItemParser(item);
    });

    const nextExpiring = [...resp.items].filter(item => item.endTime !== -1).sort((a, b) => a.endTime - b.endTime)?.[0]?.endTime;

    if (nextExpiring > 0 && nextExpiring > Date.now()) {
        resp.scheduleReload = nextExpiring + SECONDS.toMillis(5);
    }

    return resp;
};

export const channelsCollectionParser = (data: any): Collection<Channel> => {
    const resp = {
        items: [] as Channel[],
        totalItems: 0,
        scheduleReload: -1,
    };

    resp.totalItems = data?.Channels?.resultCount;
    resp.items = data.Channels?.Channel.map(item => {
        // eslint-disable-next-line no-use-before-define
        return channelCardItemParser(item);
    });

    return resp;
};

const broadcastCardItemParser = (data: any): Broadcast => {
    const season = data?.SeriesCollection?.Series?.[0];
    const series = data?.SeriesCollection?.Series?.[0]?.ParentSeriesCollection?.Series?.[0];

    const image = getTvEpisodeCardImage(data, series);
    const landscapeImage = series ? getTvEpisodeDetailImage(data, series) : getTvMovieDetailImage(data);

    const content = getClosestTsTVEventToNow(data);
    const metaData = [];

    const tsTVEvent = content?.TstvEvents?.TstvEvent[0];
    const event = tsTVEvent?.Events?.Event?.[0];
    const channel = tsTVEvent?.Channels?.Channel?.[0];
    const startDate = tsTVEvent ? Date.parse(tsTVEvent?.AvailabilityStart) : -1;
    const endDate = tsTVEvent ? Date.parse(tsTVEvent?.AvailabilityEnd) : -1;
    const channelHasReplay = hasChannelReplay(tsTVEvent?.Channels?.Channel?.[0]);

    if (startDate !== -1) {
        metaData.push(formatDate(new Date(startDate)));
        metaData.push(`${format(startDate, TIME_FORMAT_HH_MM)} - ${format(endDate, TIME_FORMAT_HH_MM)}`);
    }

    const channelLogo = getImage(channel, ImageTypes.LOGO_ANDROID);

    const broadcastId = tsTVEvent?.Events?.Event?.[0]?.id;
    const seriesId = series?.id;
    const episodeId = tsTVEvent?.Titles?.Title?.[0]?.id;

    return {
        id: broadcastId,
        titleId: data?.id,
        type: AssetTypes.program,
        title: data?.Name,
        image,
        landscapeImage,
        endTime: endDate,
        startTime: startDate,
        audioMixType: null,
        channelHasReplay,
        countries: data?.ProductionLocations?.ProductionLocation ?? [],
        duration: data?.DurationInSeconds,
        genres: getTitleGenres(data)?.map(genreItem => genreItem?.name),
        hasAudioLanguage: false,
        hasSubtitle: false,
        releaseYear: data?.ProductionDate,
        isLive: event?.IsLive,
        metaData: metaData.join('\xa0\xa0\xa0\xa0'),
        channelId: channel?.id,
        channelName: channel?.Name,
        channelLogo,
        isHDChannel: channel?.IsHD,
        isFavorite: channel?.onFavorites,
        bookmark: data?.Bookmark ?? 0,
        isPortrait: isPortrait(data),
        seriesId,
        episodeId,
        isMovie: season == null,
        worldId: season == null ? data?.id : seriesId,

        playOptions: getPlayOptions(
            data?.Contents?.Content,
            series ? getTvEpisodeDetailImage(data, series) : getTvMovieDetailImage(data),
            data?.Bookmark ?? 0
        ),

        isChannelSubscribed: isChannelSubscribed(channel),
        subscribeOffer: getTVSubscribeOffer(channel),
    };
};

const movieCardItemParser = (data: any): Movie => {
    const image = getVodMovieCardImage(data);
    const landscapeImage = getVodMovieDetailImage(data);

    const content = data?.Contents?.Content;
    const firstAvailability = content && content.length ? Date.parse(content[0].FirstAvailability) : -1;

    const metaData: string[] = [];

    if (data?.ProductionDate) {
        metaData.push(data.ProductionDate);
    }

    const genre = getFirstGenre(data);
    if (genre) {
        metaData.push(genre.Value);
    }

    return {
        id: data?.id,
        titleId: data?.id,
        type: AssetTypes.movie,
        title: data?.Name,
        image,
        landscapeImage,
        metaData: metaData.join('\xa0\xa0\xa0\xa0'),
        audioMixType: data?.Contents?.Content[0]?.AudioMixType?.type,
        countries: data?.ProductionLocations?.ProductionLocation ?? [],
        genres: getTitleGenres(data)?.map(genreItem => genreItem?.name),
        hasAudioLanguage: (data?.Contents?.Content[0]?.OriginalLanguages?.OriginalLanguage ?? []).length > 1,
        hasSubtitle: (data?.Contents?.Content[0]?.CaptionLanguages?.CaptionLanguage ?? []).length > 0,
        releaseYear: data?.ProductionDate,
        duration: data?.DurationInSeconds,
        bookmark: data?.Bookmark ?? 0,
        firstAvailability,
        price: getPrice(data),
        vodAssetState: getVodAssetEntitlementState(data),
        rentOffers: getOffers(data, false),
        buyOffers: getOffers(data, true),
        subscribeOffer: getSubscribeOffer(data),
        isPortrait: isPortrait(data),
        resolution: getResolution(content?.[0]?.Resolution, content?.[0]?.IsHD),
        entitlementEnd: getVODEntitlementEnd(data),
    };
};

const seriesCollectionItemCardParser = (data: any): Series => {
    const title = data?.ChildSeriesCollection?.Series?.[0]?.Titles?.Title?.[0];
    const metaData = [];

    const isVOD = !isBroadcast(title);

    const image = isVOD ? getVodSeriesCardImage(data) : getTVSeriesCardImage(data);
    const landscapeImage = getVodSeriesDetailImage(null, data);

    if (data.ChildSeriesCount > 0) {
        metaData.push(`${data.ChildSeriesCount} ${data.ChildSeriesCount > 1 ? translate('NUMBER_SEASONS') : translate('NUMBER_SEASON')}`);
    }

    const genre = getFirstGenre(title);
    if (genre) {
        metaData.push(genre.Value);
    }

    let id = data?.id;

    if (data?.ParentSeriesCollection) {
        id = data?.ParentSeriesCollection?.Series?.[0]?.id ?? id;
    }

    const content = title?.Contents?.Content;
    const lastEpisodeFirstAvailability = content && content.length ? Date.parse(content[0].FirstAvailability) : -1;

    return {
        id,
        type: AssetTypes.series,
        title: data?.Name,
        image,
        landscapeImage,
        metaData: metaData.join('\xa0\xa0\xa0\xa0'),
        audioMixType: null,
        genres: getTitleGenres(title)?.map(genreItem => genreItem?.name),
        countries: title?.ProductionLocations?.ProductionLocation ?? [],
        releaseYear: title?.ProductionDate,
        hasAudioLanguage: false,
        hasSubtitle: false,
        numberOfSeasons: 0,
        price: getPrice(title),
        firstAvailability: null,
        lastEpisodeFirstAvailability,
        isVOD,
    };
};

const episodeCardItemParser = (data: any): Episode => {
    const metaData: string[] = [];
    const series = data?.SeriesCollection?.Series?.[0]?.ParentSeriesCollection?.Series?.[0];
    const content = data.Contents?.Content?.[0];

    const image = getVodEpisodeCardImage(data, series);
    const landscapeImage = getVodSeriesDetailImage(data, series);

    let title = data?.Name;
    title = series?.Name || title;

    let id = data?.id;
    id = series?.id || id;

    const seasonNumber = series?.RelationOrdinal;
    const episodeNumber = data.SeriesCollection?.Series?.[0]?.RelationOrdinal;

    const episodeTitle = buildEpisodeTitle(data?.Name, seasonNumber, episodeNumber);

    if (episodeTitle) {
        metaData.push(episodeTitle);
    }

    return {
        id,
        titleId: data?.id,
        episodeId: data?.id,
        episodeNumber,
        seasonNumber,
        type: AssetTypes.episode,
        title,
        image,
        landscapeImage,
        metaData: metaData.join('\xa0\xa0\xa0\xa0'),
        releaseYear: data?.ProductionDate,
        hasSubtitle: false,
        hasAudioLanguage: false,
        genres: getTitleGenres(data)?.map(genreItem => genreItem?.name),
        duration: data?.DurationInSeconds,
        bookmark: data?.Bookmark ?? 0,
        countries: data?.ProductionLocations?.ProductionLocation ?? [],
        audioMixType: null,
        price: getPrice(data),
        firstAvailability: null,
        vodAssetState: getVodAssetEntitlementState(data),
        rentOffers: getOffers(title, false),
        buyOffers: getOffers(title, true),
        subscribeOffer: getSubscribeOffer(title),
        resolution: getResolution(content.Resolution, content.IsHD),
    };
};

const recordingItemCardParser = (item: any): Recording => {
    const title = item?.Titles?.Title?.[0];

    const season = title?.SeriesCollection?.Series?.[0];
    const series = season?.ParentSeriesCollection?.Series?.[0];

    const image = getTvEpisodeCardImage(title, series);
    const landscapeImage = series ? getTvEpisodeDetailImage(title, series) : getTvMovieCardImage(title);

    const event = item?.Events?.Event?.[0];

    const startDate = Date.parse(item?.AvailabilityStart);
    const endDate = Date.parse(item?.AvailabilityEnd);

    const recordingTitle = series ? buildEpisodeTitle(series?.Name, series?.RelationOrdinal, season?.RelationOrdinal) : item?.Name;

    return {
        id: item?.id,
        eventId: item?.EventId,
        startTime: startDate ?? -1,
        endTime: endDate ?? -1,
        duration: item?.DurationInSeconds,
        genres: getTitleGenres(title)?.map(genreItem => genreItem?.name),
        type: AssetTypes.recording,
        title: recordingTitle,
        metaData: null,
        releaseYear: title?.ProductionDate,
        isPortrait: isPortrait(title),
        image,
        landscapeImage,
        seriesId: series?.id,
        titleId: title?.id,
        isLive: event?.IsLive,
        bookmark: title?.Bookmark ?? 0,
    };
};

export const mixedStripeItemParser = (item: any): CoverAsset => {
    if (isRecordingItem(item)) {
        return recordingItemCardParser(item);
    }

    if (isSeriesCollectionItem(item)) {
        return seriesCollectionItemCardParser(item);
    }

    if (isBroadcast(item)) {
        return broadcastCardItemParser(item);
    }

    if (isVod(item)) {
        if (!isEpisode(item)) {
            return movieCardItemParser(item);
        }

        return episodeCardItemParser(item);
    }

    return null;
};

export function mixedCardCollectionParser(data: any) {
    return genericCollectionParser(data, mixedStripeItemParser);
}

export function channelsByCategoryCollectionParser(data: any) {
    const resp = {
        items: [] as Resource[],
        totalItems: 0,
        scheduleReload: Infinity,
    };

    const customProperty = data?.Category?.CustomProperties?.CustomProperty;

    if (customProperty) {
        customProperty.forEach(property => {
            if (property?.href === 'Channels' && property?.Value) {
                resp.items.push({
                    id: property.Value,
                });
            }
        });
    }

    resp.totalItems = resp.items.length;

    return resp;
}

export const movieDetailsParser = (data: any): MovieDetails => {
    const title = data?.Title;
    const landscapeImage = getVodMovieDetailImage(title);
    const content = data?.Title?.Contents?.Content;
    const firstAvailability = content && content.length ? Date.parse(content[0].FirstAvailability) : -1;

    const cast: Person[] = [];
    parseCastAndCrew(cast, title);

    const captionLanguages: string[] = content?.[0]?.CaptionLanguages?.CaptionLanguage ?? [];
    const originalLanguages: string[] = content?.[0]?.OriginalLanguages?.OriginalLanguage ?? [];
    const type = title?.AllGenres?.AllGenre?.find(genre => genre.href.indexOf(PROG_TYPE) !== -1)?.href;

    return {
        id: title?.id,
        titleId: title?.id,
        title: title?.Name,
        duration: title?.DurationInSeconds,
        bookmark: title?.Bookmark ?? 0,
        shortDescription: title?.ShortSynopsis,
        longDescription: title?.MediumSynopsis,
        firstAvailability,
        price: getPrice(title),
        type: AssetTypes.movie,
        image: getVodMovieCardImage(title),
        landscapeImage,
        cast,
        captionLanguages,
        originalLanguages,
        fsk: null,
        releaseYear: title?.ProductionDate,
        genres: title?.AllGenres?.AllGenre?.filter(genre => genre.href.indexOf(PROG_TYPE) === -1),
        countries: title?.ProductionLocations?.ProductionLocation ?? [],
        hasTrailer: (title?.PreviewCount ?? 0) > 0,
        progType: type,
        isFavorite: false, // TODO: heartIcon will be implemented with EM-337
        vodAssetState: getVodAssetEntitlementState(title),
        rentOffers: getOffers(title, false),
        buyOffers: getOffers(title, true),
        subscribeOffer: getSubscribeOffer(title),
        resolution: getResolution(content?.[0]?.Resolution, content?.[0]?.IsHD),
        entitlementEnd: getVODEntitlementEnd(title),
    };
};

export const vodSeriesDetailsParser = (data: any): SeriesDetails<DetailEpisode> => {
    const series = data?.Series;
    const seasons: any[] = series?.ChildSeriesCollection?.Series ?? [];
    const landscapeImage = getVodSeriesDetailImage(null, series);

    const parsedResult: SeriesDetails<DetailEpisode> = {
        id: series?.id,
        title: series?.Name,
        type: AssetTypes.series,
        landscapeImage,
        hasTrailer: null,
        description: series.LongSynopsis ?? series.MediumSynopsis ?? series.ShortSynopsis,
        seasons: [],
    };

    seasons.forEach(season => {
        const parsedSeason: Season<DetailEpisode> = {
            id: season?.id,
            name: season?.Name,
            number: season?.Ordinal ?? -1,
            numberOfEpisodes: season?.NumberOfEpisodes,
            episodes: [] as DetailEpisode[],
        };

        if (season?.Titles && (season.Titles?.resultCount ?? 0) > 0) {
            season.Titles.Title.forEach(episodeTitle => {
                const image = getVodEpisodeCardImage(episodeTitle, series);
                const episodeLandscapeImage = getVodSeriesDetailImage(episodeTitle, series);
                const content = episodeTitle?.Contents?.Content;
                const captionLanguages: string[] = content?.[0]?.CaptionLanguages?.CaptionLanguage ?? [];
                const originalLanguages: string[] = content?.[0]?.OriginalLanguages?.OriginalLanguage ?? [];

                const firstAvailability = content && content.length ? Date.parse(content[0].FirstAvailability) : -1;
                const episodeNumber = episodeTitle?.SeriesCollection?.Series?.[0]?.RelationOrdinal;
                const title = buildEpisodeTitle(episodeTitle?.Name, season?.Ordinal, episodeNumber);

                const cast: Person[] = [];
                parseCastAndCrew(cast, episodeTitle);

                parsedSeason.episodes.push({
                    id: series.id,
                    titleId: episodeTitle?.id,
                    episodeId: episodeTitle?.id,
                    episodeNumber,
                    seasonNumber: season?.Ordinal ?? -1,
                    title,
                    episodeTitle: title,
                    episodeTitleShort: episodeTitle.Name,
                    shortDescription: episodeTitle?.ShortSynopsis,
                    longDescription: episodeTitle?.MediumSynopsis,
                    image,
                    landscapeImage: episodeLandscapeImage,
                    metaData: null,
                    countries: episodeTitle?.ProductionLocations?.ProductionLocation ?? [],
                    releaseYear: episodeTitle?.ProductionDate,
                    duration: episodeTitle?.DurationInSeconds,
                    bookmark: episodeTitle?.Bookmark ?? 0,
                    genres: episodeTitle?.AllGenres?.AllGenre?.filter(genre => genre.href.indexOf(PROG_TYPE) === -1),
                    cast,
                    captionLanguages,
                    originalLanguages,
                    audioMixType: null,
                    hasSubtitle: null,
                    hasAudioLanguage: null,
                    firstAvailability,
                    price: getPrice(episodeTitle),
                    hasTrailer: episodeTitle?.PreviewContents != null,
                    type: AssetTypes.episode,
                    vodAssetState: getVodAssetEntitlementState(episodeTitle),
                    rentOffers: getOffers(episodeTitle, false),
                    buyOffers: getOffers(episodeTitle, true),
                    subscribeOffer: getSubscribeOffer(episodeTitle),
                    resolution: getResolution(content?.[0]?.Resolution, content?.[0]?.IsHD),
                    seriesTitle: series?.Name,
                });
            });

            parsedSeason.episodes = [...parsedSeason.episodes].sort((a, b) => {
                if (!a.episodeNumber || !b.episodeNumber) return 0;
                return a.episodeNumber - b.episodeNumber;
            });
        }

        if (!parsedResult.seasons.find(item => item.id === parsedSeason.id || item.number === parsedSeason.number)) {
            parsedResult.seasons.push(parsedSeason);
        }
    });

    return parsedResult;
};

export const tvSeriesDetailsParser = (data: any): SeriesDetails<TVSeriesDetailEpisode> => {
    const series = data?.Series;
    const seasons: any[] = series?.ChildSeriesCollection?.Series ?? [];
    const landscapeImage = getVodSeriesDetailImage(null, series);

    const parsedResult: SeriesDetails<TVSeriesDetailEpisode> = {
        id: series?.id,
        title: series?.Name,
        type: AssetTypes.series,
        landscapeImage,
        hasTrailer: null,
        description: series.LongSynopsis ?? series.MediumSynopsis ?? series.ShortSynopsis,
        seasons: [],
    };

    seasons.forEach(season => {
        const parsedSeason: Season<TVSeriesDetailEpisode> = {
            id: season?.id,
            name: season?.Name,
            number: season?.Ordinal ?? -1,
            numberOfEpisodes: season?.Titles?.resultCount,
            episodes: [] as TVSeriesDetailEpisode[],
        };

        if (season?.Titles && (season.Titles?.resultCount ?? 0) > 0) {
            season.Titles.Title.forEach(episodeTitle => {
                const image = getTvEpisodeCardImage(episodeTitle, series);
                const episodeLandscapeImage = getVodSeriesDetailImage(episodeTitle, series);
                const content = getClosestTsTVEventToNow(episodeTitle);
                const captionLanguages: string[] = content?.CaptionLanguages?.CaptionLanguage ?? [];
                const originalLanguages: string[] = content?.OriginalLanguages?.OriginalLanguage ?? [];

                const firstAvailability = content ? Date.parse(content.FirstAvailability) : -1;
                const episodeNumber = episodeTitle?.SeriesCollection?.Series?.[0]?.RelationOrdinal;

                const cast: Person[] = [];
                parseCastAndCrew(cast, episodeTitle);

                const channel = content?.TstvEvents?.TstvEvent?.[0]?.Channels?.Channel?.[0];
                const event = content?.TstvEvents?.TstvEvent?.[0]?.Events?.Event?.[0];

                const metaData = [];

                const startDate = event ? Date.parse(event?.AvailabilityStart) : -1;
                const endDate = event ? Date.parse(event?.AvailabilityEnd) : -1;

                if (startDate !== -1) {
                    metaData.push(formatDate(new Date(startDate)));
                    metaData.push(`${format(startDate, TIME_FORMAT_HH_MM)} - ${format(endDate, TIME_FORMAT_HH_MM)}`);
                }

                const episodeName = episodeTitle?.EpisodeName ?? episodeTitle?.Name;
                const title = buildEpisodeTitle(episodeName, season?.Ordinal, episodeNumber);

                const NPVRProduct = getNPVRProduct(content);

                const isRecordingSubscribed = isNPVRSubscribedProduct(NPVRProduct);
                const recordingProductCode = getSubscriptionProductCode(NPVRProduct);

                parsedSeason.episodes.push({
                    id: series.id,
                    titleId: episodeTitle?.id,
                    episodeId: episodeTitle?.id,
                    episodeNumber,
                    seasonNumber: season?.Ordinal ?? -1,
                    title,
                    episodeTitle: title,
                    episodeTitleShort: episodeName,
                    shortDescription: episodeTitle?.ShortSynopsis,
                    longDescription: episodeTitle?.MediumSynopsis,
                    image,
                    landscapeImage: episodeLandscapeImage,
                    metaData: metaData.join('\xa0\xa0\xa0\xa0'),
                    countries: episodeTitle?.ProductionLocations?.ProductionLocation ?? [],
                    releaseYear: episodeTitle?.ProductionDate,
                    duration: episodeTitle?.DurationInSeconds,
                    bookmark: episodeTitle?.Bookmark ?? 0,
                    genres: episodeTitle?.AllGenres?.AllGenre?.filter(genre => genre.href.indexOf(PROG_TYPE) === -1),
                    cast,
                    captionLanguages,
                    originalLanguages,
                    audioMixType: null,
                    hasSubtitle: null,
                    hasAudioLanguage: null,
                    firstAvailability,
                    price: getPrice(episodeTitle),
                    hasTrailer: episodeTitle?.PreviewContents != null,
                    type: AssetTypes.tv_series_episode,
                    subscribeOffer: getTVSubscribeOffer(channel),

                    channelId: channel?.id,
                    channelName: channel?.Name,
                    channelImage: getImage(channel, ImageTypes.LOGO_ANDROID),
                    isHDChannel: channel?.IsHD,
                    eventId: event?.id,
                    isChannelSubscribed: isChannelSubscribed(channel),
                    channelHasReplay: hasChannelReplay(channel),
                    startTime: startDate,
                    endTime: endDate,
                    isLive: event?.IsLive,
                    playOptions: getPlayOptions(episodeTitle?.Contents?.Content, image, episodeTitle?.Bookmark ?? 0),

                    isRecorded: event?.IsRecorded,
                    isRecordingSubscribed,
                    recordingProductCode,
                    resolution: null,
                });
            });

            parsedSeason.episodes = [...parsedSeason.episodes].sort((a, b) => {
                if (!a.episodeNumber || !b.episodeNumber) return 0;
                return a.episodeNumber - b.episodeNumber;
            });
        }

        const alreadyExistingSeasonIndex = parsedResult.seasons.findIndex(
            item => item.id === parsedSeason.id || item.number === parsedSeason.number
        );

        if (alreadyExistingSeasonIndex >= 0) {
            parsedResult.seasons[alreadyExistingSeasonIndex].episodes.push(...parsedSeason.episodes);
            parsedResult.seasons[alreadyExistingSeasonIndex].numberOfEpisodes += parsedSeason.numberOfEpisodes;
            parsedResult.seasons[alreadyExistingSeasonIndex].episodes.sort((a, b) => {
                if (!a.episodeNumber || !b.episodeNumber) return 0;
                return a.episodeNumber - b.episodeNumber;
            });
        } else {
            parsedResult.seasons.push(parsedSeason);
        }
    });

    return parsedResult;
};

export const programDetailsParser = (data: any): ProgramDetails => {
    const event = data?.Event;
    const channel = event?.Channels?.Channel?.[0];
    const title = event?.Titles?.Title?.[0];
    const contents = title?.Contents?.Content;
    const content = getContentByEvent(title, event?.id);

    const channelHasReplay = hasChannelReplay(event?.Channels?.Channel?.[0]);
    let subTitle = null;
    let episodeTitle = null;

    const season = title?.SeriesCollection?.Series?.[0];
    const series = season?.ParentSeriesCollection?.Series?.[0];

    // it's part of a series
    if (season?.RelationOrdinal) {
        const seasonNumber = series?.RelationOrdinal;
        const episodeNumber = season?.RelationOrdinal;

        subTitle = buildEpisodeTitle(title?.Name, seasonNumber, episodeNumber);
        episodeTitle = title?.Name;
    }

    const landscapeImage = season ? getTvEpisodeDetailImage(title, series) : getTvMovieDetailImage(title);

    const type = title?.AllGenres?.AllGenre?.find(genre => genre.href.indexOf(PROG_TYPE) !== -1)?.href;

    const cast: Person[] = [];
    parseCastAndCrew(cast, title);

    const NPVRProduct = getNPVRProduct(content);

    const isRecordingSubscribed = isNPVRSubscribedProduct(NPVRProduct);
    const recordingProductCode = getSubscriptionProductCode(NPVRProduct);

    return {
        id: event?.id,
        titleId: title?.id,
        progType: type,
        startTime: event?.AvailabilityStart ? Date.parse(event?.AvailabilityStart) : -1,
        endTime: event?.AvailabilityEnd ? Date.parse(event?.AvailabilityEnd) : -1,
        channelId: channel?.id,
        channelName: channel?.Name,
        channelNumber: channel?.LogicalChannelNumber,
        isFavorite: channel?.onFavorites,
        channelLogo: getImage(channel, ImageTypes.LOGO_ANDROID),
        isHDChannel: channel?.IsHD,
        resolution: channel?.Resolution,
        isChannelSubscribed: isChannelSubscribed(channel),
        type: AssetTypes.program,
        title: season ? season?.Name : title?.Name,
        description: title?.LongSynopsis,
        subTitle,
        episodeName: title?.Name,
        episodeTitleShort: episodeTitle,
        landscapeImage,
        releaseYear: title?.ProductionDate,
        genres: title?.AllGenres?.AllGenre?.filter(genre => genre.href.indexOf(PROG_TYPE) === -1),
        countries: title?.ProductionLocations?.ProductionLocation ?? [],
        seasonId: title?.SeriesCollection?.Series?.[0]?.id,
        cast,
        channelHasReplay,
        hasTrailer: (title?.PreviewCount ?? 0) > 0,
        bookmark: title?.Bookmark ?? 0,
        isLive: event?.IsLive,
        isMovie: season == null,
        worldId: season == null ? title?.id : season?.ParentSeriesCollection?.Series?.[0]?.id,
        playOptions: getPlayOptions(contents, landscapeImage, title?.Bookmark ?? 0),
        subscribeOffer: getTVSubscribeOffer(channel),

        isRecorded: event?.IsRecorded,
        isRecordingSubscribed,
        recordingProductCode,
    };
};

export const recordingDetailsParser = (data: any): RecordingDetails => {
    const recording = data?.Recording;
    const event = recording?.Events?.Event?.[0];
    const channel = recording?.Channels?.Channel?.[0];
    const title = recording?.Titles?.Title?.[0];

    let episodeTitle = null;
    let subTitle = null;

    const season = title?.SeriesCollection?.Series?.[0];
    const series = season?.ParentSeriesCollection?.Series?.[0];

    // it's part of a series
    if (season?.RelationOrdinal) {
        const seasonNumber = series?.RelationOrdinal;
        const episodeNumber = season?.RelationOrdinal;

        subTitle = buildEpisodeTitle(title?.EpisodeName ?? title?.Name, seasonNumber, episodeNumber);
        episodeTitle = title?.Name;
    }

    const landscapeImage = season ? getTvEpisodeDetailImage(title, series) : getTvMovieDetailImage(title);

    const type = title?.AllGenres?.AllGenre?.find(genre => genre.href.indexOf(PROG_TYPE) !== -1)?.href;

    const cast: Person[] = [];
    parseCastAndCrew(cast, title);

    return {
        id: recording?.id,
        titleId: title?.id,
        progType: type,
        startTime: recording?.AvailabilityStart ? Date.parse(recording?.AvailabilityStart) : -1,
        endTime: recording?.AvailabilityEnd ? Date.parse(recording?.AvailabilityEnd) : -1,
        channelId: channel?.id,
        channelName: channel?.Name,
        channelNumber: channel?.LogicalChannelNumber,
        channelLogo: getImage(channel, ImageTypes.LOGO_ANDROID),
        isHDChannel: channel?.IsHD,
        isChannelSubscribed: isChannelSubscribed(channel),
        type: AssetTypes.recording,
        title: season ? season?.Name : title?.Name,
        description: title?.LongSynopsis,
        subTitle,
        episodeName: title?.EpisodeName,
        episodeTitleShort: episodeTitle,
        landscapeImage,
        releaseYear: title?.ProductionDate,
        genres: title?.AllGenres?.AllGenre?.filter(genre => genre.href.indexOf(PROG_TYPE) === -1),
        countries: title?.ProductionLocations?.ProductionLocation ?? [],
        seasonId: title?.SeriesCollection?.Series?.[0]?.id,
        seriesId: series?.id,
        cast,
        channelHasReplay: true,
        hasTrailer: (title?.PreviewCount ?? 0) > 0,
        bookmark: title?.Bookmark ?? 0,
        isLive: event?.IsLive,
        isMovie: season == null,
        playOptions: null,

        isRecorded: true,
        isRecordingSubscribed: true,
        recordingProductCode: null,
    };
};

export const similarTVMovieAssetsParser = (data: any): Collection<Broadcast> => {
    const result: Collection<Broadcast> = {
        totalItems: 0,
        items: [],
        scheduleReload: -1,
    };

    let tempHolder: Broadcast[] = [];

    if (data?.Channels?.Channel) {
        data.Channels.Channel.forEach(channel => {
            if (channel?.Events?.Event) {
                channel.Events.Event.forEach(event => {
                    const title = event?.Titles?.Title[0];

                    if (title) {
                        const channelHasReplay = hasChannelReplay(channel);
                        const isMovieBroadcast = isMovie(title);
                        const series = title?.SeriesCollection?.Series?.[0]?.ParentSeriesCollection?.Series?.[0];

                        const image = isMovieBroadcast ? getTvMovieCardImage(title) : getTvEpisodeCardImage(title, null);
                        const landscapeImage = isMovieBroadcast ? getTvMovieDetailImage(title) : getTvEpisodeDetailImage(title, series);

                        const metaData = [];
                        const startDate = Date.parse(event?.AvailabilityStart);
                        const endDate = Date.parse(event?.AvailabilityEnd);

                        if (startDate) {
                            metaData.push(formatDate(new Date(startDate)));
                            metaData.push(`${format(startDate, TIME_FORMAT_HH_MM)} - ${format(endDate, TIME_FORMAT_HH_MM)}`);
                        }

                        const seriesId = series?.id;
                        const episodeId = title?.id;

                        tempHolder.push({
                            id: event.id || '',
                            titleId: title?.id,
                            type: AssetTypes.program,
                            title: title?.Name,
                            image,
                            landscapeImage: null,
                            startTime: startDate,
                            endTime: endDate,
                            metaData: metaData.join('\xa0\xa0\xa0\xa0'),
                            channelId: channel.id,
                            releaseYear: null,
                            hasSubtitle: false,
                            hasAudioLanguage: false,
                            genres: [],
                            duration: null,
                            bookmark: title?.Bookmark ?? 0,
                            countries: [],
                            audioMixType: null,
                            isLive: null,
                            channelHasReplay,
                            isPortrait: isPortrait(title),

                            seriesId,
                            episodeId,

                            playOptions: getPlayOptions(title?.Contents?.Content, landscapeImage, title?.Bookmark ?? 0),
                            isChannelSubscribed: isChannelSubscribed(channel),
                            subscribeOffer: getTVSubscribeOffer(channel),
                        });
                    }
                });
            }
        });
    }

    tempHolder = tempHolder
        // filter out live events
        .filter(item => !isNow(item.startTime, item.endTime))
        // sort by start time
        .sort((a, b) => b.startTime - a.startTime);

    const similarAssets: Broadcast[] = [];
    tempHolder.forEach(similar => {
        // filter out duplicates
        if (!similarAssets.find(broadcast => broadcast.title === similar.title)) {
            similarAssets.push(similar);
        }
    });

    result.items = similarAssets.slice(0, 20); // limit result to 20
    result.totalItems = result.items.length;

    return result;
};

export const similarTVSeriesAssetsParser = (data: any): Collection<Broadcast> => {
    const result: Collection<Broadcast> = {
        totalItems: 0,
        items: [],
        scheduleReload: -1,
    };

    const tempHolder: Broadcast[] = [];

    if (data?.Series?.Titles?.Title) {
        data.Series.Titles.Title.forEach(title => {
            if (title?.Events?.Event) {
                title.Events.Event.forEach(event => {
                    if (event?.TstvContents && event?.Channels) {
                        const metaData = [];
                        const startDate = Date.parse(event?.AvailabilityStart);
                        const endDate = Date.parse(event?.AvailabilityEnd);

                        const channel = event?.Channels?.Channel?.[0];
                        const channelHasReplay = hasChannelReplay(channel);

                        if (startDate) {
                            metaData.push(formatDate(new Date(startDate)));
                            metaData.push(`${format(startDate, TIME_FORMAT_HH_MM)} - ${format(endDate, TIME_FORMAT_HH_MM)}`);
                        }

                        const series = data?.Series?.ParentSeriesCollection?.Series?.[0];
                        const seasonNumber = series?.RelationOrdinal;
                        const episodeNumber = title.SeriesCollection?.Series?.[0]?.RelationOrdinal;
                        const seriesId = series?.id;
                        const episodeId = event?.Titles?.Title?.[0]?.id;

                        const landscapeImage = getTvEpisodeCardImage(title, series);
                        const eventTitle = buildEpisodeTitle(title?.Name, seasonNumber, episodeNumber);

                        tempHolder.push({
                            id: event.id || '',
                            titleId: title?.id,
                            type: AssetTypes.program,
                            title: eventTitle,
                            image: landscapeImage,
                            landscapeImage,
                            startTime: startDate,
                            endTime: endDate,
                            metaData: metaData.join('\xa0\xa0\xa0\xa0'),
                            channelId: null, // TODO: include
                            releaseYear: null,
                            hasSubtitle: false,
                            hasAudioLanguage: false,
                            genres: [],
                            duration: null,
                            bookmark: title?.Bookmark ?? 0,
                            countries: [],
                            audioMixType: null,
                            isLive: null,
                            channelHasReplay,
                            episodeNumber: title?.SeriesCollection?.Series?.[0]?.RelationOrdinal || 0,
                            isPortrait: isPortrait(title),
                            seriesId,
                            episodeId,

                            playOptions: getPlayOptions(
                                title?.Contents?.Content,
                                getTvEpisodeDetailImage(title, series),
                                title?.Bookmark ?? 0
                            ),

                            isChannelSubscribed: isChannelSubscribed(channel),
                            subscribeOffer: getTVSubscribeOffer(channel),
                        });
                    }
                });
            }
        });
    }

    result.items = [...tempHolder].sort((a, b) => a.episodeNumber - b.episodeNumber).slice(0, 20);

    result.totalItems = result.items.length;

    return result;
};

export const similarVodMovieAssetsParser = (data: any): Collection<Movie> => {
    const result: Collection<Movie> = {
        totalItems: 0,
        items: [],
        scheduleReload: -1,
    };

    if (data?.Titles?.Title) {
        data.Titles.Title.forEach(title => {
            const cardImage = getVodMovieCardImage(title);

            result.items.push({
                id: title.id,
                titleId: title.id,
                title: title?.Name,
                type: AssetTypes.movie,
                image: cardImage,
                firstAvailability: null,
                price: getPrice(title),
                imdbRating: null,
                metaData: null,
                releaseYear: null,
                hasSubtitle: null,
                hasAudioLanguage: null,
                genres: null,
                countries: null,
                audioMixType: null,
                landscapeImage: null,
                duration: title?.DurationInSeconds,
                bookmark: title?.Bookmark ?? 0,
                vodAssetState: getVodAssetEntitlementState(title),
                rentOffers: getOffers(title, false),
                buyOffers: getOffers(title, true),
                subscribeOffer: getSubscribeOffer(title),
                isPortrait: isPortrait(title),
                resolution: null,
            });
        });
    }

    return result;
};

export const extraMetaDataParser = (data: any): ExtraMetaData => {
    const castMapper = (cast): Person => {
        return {
            id: null,
            title: cast.Name,
            image: cast?.Pic?.replace('//mw.', '//amw.'),
            type: AssetTypes.person,
            role: cast.role,
        };
    };

    return {
        imdRating: data?.ImdbRating ?? -1,
        actors: (
            data?.Actors?.map(actor => {
                return { ...actor, ...{ role: PersonRole.ACTOR } };
            }) ?? []
        ).map(castMapper),
        directors: (
            data?.Directors?.map(actor => {
                return { ...actor, ...{ role: PersonRole.DIRECTOR } };
            }) ?? []
        ).map(castMapper),
    };
};

export const purchasedProductParser = (data: any): PurchasedProduct[] => {
    return (
        data?.Products?.Product?.map(product => {
            return {
                state: product?.EntitlementState,
                type: product?.Name,
            };
        }) ?? []
    );
};

export const parseLiveSessionResponse = (data: any): PlaybackSession => {
    return {
        sessionId: data?.Session?.id,
        manifestUrl: data?.Session?.Playlist?.Channel?.Value,
        // @ts-ignore
        keepAliveInterval: (duration(data?.Session?.Timeout, ISO_8601).asMilliseconds() * 80) / 100,
    };
};

export const parseContentSessionResponse = (data: any): PlaybackSession => {
    return {
        sessionId: data?.Session?.id,
        manifestUrl: data?.Session?.Playlist?.Asset?.[0]?.Value,
        // @ts-ignore
        keepAliveInterval: (duration(data?.Session?.Timeout, ISO_8601).asMilliseconds() * 80) / 100,
    };
};

export const parseLivePlayerAssetInfo = (data: any): LivePlayerAssetInfo => {
    const channel = data?.Channels?.Channel?.[0];
    const product = channel?.Products?.Product?.[0];
    const event = channel?.Events?.Event?.[0];
    const title = event?.Titles?.Title?.[0];
    const series = title?.SeriesCollection?.Series?.[0];
    const content = (event?.TstvContents?.Content ?? []).find(contentItem => contentItem.EntitlementState === EntitlementState.ENTITLED);
    const allContents = getContentByEvent(title, event?.id);

    const cast: Person[] = [];
    parseCastAndCrew(cast, title || series);

    const captionLanguages: string[] = content?.[0]?.CaptionLanguages?.CaptionLanguage ?? [];
    const originalLanguages: string[] = content?.[0]?.OriginalLanguages?.OriginalLanguage ?? [];

    let subTitle = null;

    // it's part of a series
    if (title?.SeriesCollection?.Series?.[0]) {
        const seasonNumber = series?.ParentSeriesCollection?.Series?.[0]?.RelationOrdinal;
        const episodeNumber = series?.RelationOrdinal;

        subTitle = buildEpisodeTitle(title?.EpisodeName || title?.Name, seasonNumber, episodeNumber);
    }

    const startDate = event?.AvailabilityStart ? Date.parse(event.AvailabilityStart) : null;
    const endDate = event?.AvailabilityEnd ? Date.parse(event.AvailabilityEnd) : null;

    const image = series ? getTvEpisodeDetailImage(title, series?.ParentSeriesCollection?.Series?.[0]) : getTvMovieDetailImage(title);

    const isChannelEntitled = isChannelSubscribed(channel);
    const channelHasReplay = hasChannelReplay(channel);

    const NPVRProduct = getNPVRProduct(allContents);

    const isRecordingSubscribed = isNPVRSubscribedProduct(NPVRProduct);
    const recordingProductCode = getSubscriptionProductCode(NPVRProduct);

    return {
        type: PlayingAssetType.LIVE,
        id: event?.id,
        title: series ? series?.Name : title?.Name || translate('PLAYER_NO_INFO'),
        titleId: title?.id,
        duration: startDate && endDate ? MILLISECONDS.toSeconds(endDate - startDate) : 0,
        bookmark: title?.Bookmark,
        image,
        cast,
        captionLanguages,
        originalLanguages,
        description: title?.MediumSynopsis,
        subtitle: subTitle,
        episodeName: title?.EpisodeName,
        startTime: startDate,
        endTime: endDate,
        channelId: channel?.id,
        logicalChannelNumber: `${channel.LogicalChannelNumber}`,
        channelName: channel?.Name,
        channelLogo: getImage(channel, ImageTypes.LOGO_ANDROID),
        channelSubscribed: isChannelEntitled,
        channelProductCode: getSubscriptionProductCode(product),
        prePadding: getChannelPaddingSeconds(channel, ChannelPadding.PRE),
        postPadding: getChannelPaddingSeconds(channel, ChannelPadding.POST),
        channelHasReplay,
        countries: title?.ProductionLocations?.ProductionLocation ?? [],
        releaseYear: title?.ProductionDate,
        isLive: event?.IsLive,
        isRecorded: event?.IsRecorded,
        manifestUrl: null,
        hasStream: (channel?.PlayInfos?.Location ?? []).find(locationItem => locationItem.type === '5J'),
        contentId: channel?.id,
        replayContentId: content?.id,
        sessionId: data?.sessionId,
        keepAliveInterval: data?.keepAliveInterval,
        genres: title?.AllGenres?.AllGenre?.filter(genre => genre.href.indexOf(PROG_TYPE) === -1),
        isRecordingSubscribed,
        recordingProductCode,

        subscribeOffer: getTVSubscribeOffer(channel),
    };
};

export const parseCatchupPlayerAssetInfo = (data: any): CatchupPlayerAssetInfo => {
    const event = data?.Event;
    const title = event?.Titles?.Title?.[0];
    const content = (event?.TstvContents?.Content ?? []).find(contentItem => contentItem.EntitlementState === EntitlementState.ENTITLED);
    const channel = event?.Channels?.Channel?.[0];
    const channelProduct = channel?.Products?.Product?.[0];
    const series = title?.SeriesCollection?.Series?.[0];

    const cast: Person[] = [];
    parseCastAndCrew(cast, title);

    const captionLanguages: string[] = content?.[0]?.CaptionLanguages?.CaptionLanguage ?? [];
    const originalLanguages: string[] = content?.[0]?.OriginalLanguages?.OriginalLanguage ?? [];

    const startDate = event?.AvailabilityStart ? Date.parse(event.AvailabilityStart) : null;
    const endDate = event?.AvailabilityEnd ? Date.parse(event.AvailabilityEnd) : null;

    const channelHasReplay = hasChannelReplay(channel);
    const isChannelEntitled = isChannelSubscribed(channel);

    let seriesId;

    let subTitle = null;
    // it's part of a series
    if (series) {
        const seasonNumber = series?.ParentSeriesCollection?.Series?.[0]?.RelationOrdinal;
        const episodeNumber = series?.RelationOrdinal;
        seriesId = series?.ParentSeriesCollection?.Series?.[0]?.id;

        subTitle = buildEpisodeTitle(title?.EpisodeName || title?.Name, seasonNumber, episodeNumber);
    }

    const image = seriesId ? getTvEpisodeDetailImage(title, series?.ParentSeriesCollection?.Series?.[0]) : getTvMovieDetailImage(title);

    const NPVRProduct = getNPVRProduct(content);

    const isRecordingSubscribed = isNPVRSubscribedProduct(NPVRProduct);
    const recordingProductCode = getSubscriptionProductCode(NPVRProduct);

    return {
        type: PlayingAssetType.CATCHUP,
        id: event?.id,
        titleId: title?.id,
        title: series ? series?.Name : title?.Name || translate('PLAYER_NO_INFO'),
        subtitle: subTitle,
        episodeName: title?.EpisodeName,
        image,
        startTime: startDate,
        endTime: endDate,
        channelId: channel?.id,
        logicalChannelNumber: `${channel.LogicalChannelNumber}`,
        channelName: channel?.Name,
        channelLogo: getImage(channel, ImageTypes.LOGO_ANDROID),
        cast,
        captionLanguages,
        originalLanguages,
        description: title?.MediumSynopsis,
        channelHasReplay,
        channelSubscribed: isChannelEntitled,
        channelProductCode: getSubscriptionProductCode(channelProduct),
        prePadding: getChannelPaddingSeconds(channel, ChannelPadding.PRE),
        postPadding: getChannelPaddingSeconds(channel, ChannelPadding.POST),
        countries: title?.ProductionLocations?.ProductionLocation ?? [],
        releaseYear: title?.ProductionDate,
        manifestUrl: null,
        bookmark: title?.Bookmark,
        duration: startDate && endDate ? MILLISECONDS.toSeconds(endDate - startDate) : 0,
        seriesId,
        hasStream: (content?.PlayInfos?.Location ?? []).find(locationItem => locationItem.type === '5J'),
        contentId: content?.id,
        sessionId: data?.sessionId,
        keepAliveInterval: data?.keepAliveInterval,
        genres: title?.AllGenres?.AllGenre?.filter(genre => genre.href.indexOf(PROG_TYPE) === -1),
        isRecorded: event?.IsRecorded,
        isRecordingSubscribed,
        recordingProductCode,
    };
};

export const parseRecordingPlayerAssetInfo = (data: any): RecordingPlayerAssetInfo => {
    const recording = data?.Recording;
    const title = recording?.Titles?.Title?.[0];
    const content = (recording?.Contents?.Content ?? []).find(contentItem => contentItem.EntitlementState === EntitlementState.ENTITLED);
    const channel = recording?.Channels?.Channel?.[0];
    const series = title?.SeriesCollection?.Series?.[0];

    const cast: Person[] = [];
    parseCastAndCrew(cast, title);

    const captionLanguages: string[] = content?.[0]?.CaptionLanguages?.CaptionLanguage ?? [];
    const originalLanguages: string[] = content?.[0]?.OriginalLanguages?.OriginalLanguage ?? [];

    const startDate = recording?.AvailabilityStart ? Date.parse(recording.AvailabilityStart) : null;
    const endDate = recording?.AvailabilityEnd ? Date.parse(recording.AvailabilityEnd) : null;

    let seriesId;

    let subTitle = null;
    // it's part of a series
    if (title?.SeriesCollection?.Series?.[0]) {
        const seasonNumber = title.SeriesCollection?.Series?.[0]?.ParentSeriesCollection?.Series?.[0]?.RelationOrdinal;
        const episodeNumber = title.SeriesCollection?.Series?.[0]?.RelationOrdinal;
        seriesId = title.SeriesCollection?.Series?.[0]?.ParentSeriesCollection?.Series?.[0]?.id;

        subTitle = buildEpisodeTitle(title?.EpisodeName || title?.Name, seasonNumber, episodeNumber);
    }

    const image = series ? getTvEpisodeDetailImage(title, series?.ParentSeriesCollection?.Series?.[0]) : getTvMovieDetailImage(title);

    return {
        type: PlayingAssetType.RECORDING,
        id: recording?.id,
        titleId: title?.id,
        title: series ? series?.Name : title?.Name || translate('PLAYER_NO_INFO'),
        subtitle: subTitle,
        episodeName: title?.EpisodeName,
        image,
        cast,
        captionLanguages,
        originalLanguages,
        description: title?.MediumSynopsis,
        startTime: startDate,
        endTime: endDate,
        genres: title?.AllGenres?.AllGenre?.filter(genre => genre.href.indexOf(PROG_TYPE) === -1),
        channelId: channel?.id,
        logicalChannelNumber: `${channel.LogicalChannelNumber}`,
        channelName: channel?.Name,
        channelLogo: getImage(channel, ImageTypes.LOGO_ANDROID),
        prePadding: getChannelPaddingSeconds(channel, ChannelPadding.PRE),
        postPadding: getChannelPaddingSeconds(channel, ChannelPadding.POST),
        countries: title?.ProductionLocations?.ProductionLocation ?? [],
        releaseYear: title?.ProductionDate,
        manifestUrl: null,
        bookmark: title?.Bookmark,
        duration: startDate && endDate ? MILLISECONDS.toSeconds(endDate - startDate) : 0,
        seriesId,
        hasStream: (content?.PlayInfos?.Location ?? []).find(locationItem => locationItem.type === '5J'),
        contentId: content?.id,
        sessionId: data?.sessionId,
        keepAliveInterval: data?.keepAliveInterval,
        isRecorded: true,
    };
};

export const parseTrailerPlayerAssetInfo = (data: any): TrailerPlayerAssetInfo => {
    const title = data?.Title;
    const content = title?.PreviewContents?.Content?.[0];

    const cast: Person[] = [];
    parseCastAndCrew(cast, title);

    const captionLanguages: string[] = content?.[0]?.CaptionLanguages?.CaptionLanguage ?? [];
    const originalLanguages: string[] = content?.[0]?.OriginalLanguages?.OriginalLanguage ?? [];

    return {
        type: PlayingAssetType.TRAILER,
        title: title?.Name,
        id: title?.id,
        image: null,
        genres: title?.AllGenres?.AllGenre?.filter(genre => genre.href.indexOf(PROG_TYPE) === -1),
        releaseYear: title?.ProductionDate,
        countries: title?.ProductionLocations?.ProductionLocation ?? [],
        manifestUrl: null,
        hasStream: (content?.PlayInfos?.Location ?? []).find(locationItem => locationItem.type === '5J'),
        contentId: content?.id,
        sessionId: data?.sessionId,
        keepAliveInterval: data?.keepAliveInterval,
        cast,
        captionLanguages,
        originalLanguages,
        description: title?.MediumSynopsis,
        duration: title?.DurationInSeconds,
    };
};

export const parseVodPlayerAssetInfo = (data: any): VodPlayerAssetInfo => {
    const vodTitle = data?.Title;
    const content = vodTitle?.Contents?.Content?.[0];
    const isVodMovie = isMovie(vodTitle);
    const series = vodTitle?.SeriesCollection?.Series?.[0]?.ParentSeriesCollection?.Series?.[0];

    const cast: Person[] = [];
    parseCastAndCrew(cast, vodTitle);

    const captionLanguages: string[] = content?.CaptionLanguages?.CaptionLanguage ?? [];
    const originalLanguages: string[] = content?.OriginalLanguages?.OriginalLanguage ?? [];

    let subTitle = null;
    let title;
    let seriesId;
    let seasonNumber;
    let episodeNumber;

    if (!isVodMovie) {
        // it's part of a series
        const episode = vodTitle?.SeriesCollection?.Series?.[0];

        seriesId = series?.id;

        if (episode?.RelationOrdinal) {
            seasonNumber = series?.RelationOrdinal;
            episodeNumber = episode?.RelationOrdinal;

            subTitle = buildEpisodeTitle(vodTitle?.Name, seasonNumber, episodeNumber);
        }
        title = series?.Name;
    } else {
        title = vodTitle?.Name;
    }

    const image = series ? getTvEpisodeDetailImage(title, series) : getTvMovieDetailImage(title);
    const manifestUrl = null;

    return {
        type: PlayingAssetType.VOD,
        title,
        subtitle: subTitle,
        episodeName: vodTitle?.Name,
        id: vodTitle?.id,
        titleId: vodTitle?.id,
        image,
        cast,
        captionLanguages,
        originalLanguages,
        description: vodTitle?.MediumSynopsis,
        releaseYear: vodTitle?.ProductionDate,
        countries: vodTitle?.ProductionLocations?.ProductionLocation ?? [],
        genres: vodTitle?.AllGenres?.AllGenre?.filter(genre => genre.href.indexOf(PROG_TYPE) === -1),
        manifestUrl,
        isMovie: isVodMovie,
        duration: vodTitle?.DurationInSeconds,
        bookmark: vodTitle?.Bookmark,
        seriesId,
        vodAssetState: getVodAssetEntitlementState(vodTitle),
        hasStream: (content?.PlayInfos?.Location ?? []).find(locationItem => locationItem.type === '5J'),
        contentId: content?.id,
        sessionId: data?.sessionId,
        keepAliveInterval: data?.keepAliveInterval,
        seasonNumber,
        episodeNumber,
    };
};

export const parseChannels = (input: Channel[]): EpgChannel[] => {
    if (input == null) return [];
    return input as EpgChannel[];
};

export const parseEpgPrograms = (channelItem: any): EpgProgram[] => {
    if (
        channelItem === null ||
        channelItem?.Events == null ||
        (Array.isArray(channelItem?.Events?.Event) && !channelItem?.Events.Event.length)
    ) {
        return [];
    }

    const channelHasReplay = hasChannelReplay(channelItem);
    const subscribed = isChannelSubscribed(channelItem);

    return channelItem?.Events.Event.map(item => {
        const startTime = Date.parse(item?.AvailabilityStart);
        const endTime = Date.parse(item?.AvailabilityEnd);

        const title = item?.Titles?.Title?.[0];
        const seriesId = title?.SeriesCollection?.Series?.[0]?.ParentSeriesCollection?.Series?.[0]?.id;
        const episodeId = title?.id;

        const series = title?.SeriesCollection?.Series?.[0]?.ParentSeriesCollection?.Series?.[0];
        const backdropImage = series ? getTvEpisodeDetailImage(title, series) : getTvMovieDetailImage(title);

        return {
            id: item?.id,
            channelId: channelItem.id,
            isChannelReplayCapable: channelHasReplay,
            isChannelSubscribed: subscribed,
            title: title?.Name ?? translate('PLAYER_NO_INFO'),
            image: null,
            startTime,
            endTime,
            realStartTime: startTime,
            realEndTime: endTime,
            isBreak: false,
            seriesId,
            episodeId,
            playOptions: {
                backdropImage,
                options: null,
            },
        } as EpgProgram;
    });
};

export const parsePersonalizedInfo = (data: any): Rentable & Bookmark => {
    const title = data?.Title;

    return {
        id: title?.id,
        titleId: title?.id,
        vodAssetState: getVodAssetEntitlementState(title),
        rentOffers: getOffers(title, false),
        buyOffers: getOffers(title, true),
        subscribeOffer: getSubscribeOffer(title),
        bookmark: title?.Bookmark ?? 0,
        entitlementEnd: getVODEntitlementEnd(title),
    };
};

export const parseWorldRootId = (data: any): MyWorldRootCategory => {
    if (data.length) {
        return {
            id: data[0]?.id,
            name: data[0]?.Name,
            isAdult: data[0]?.IsAdult,
        };
    }

    return null;
};

export const parseMyWorldChildCategory = (data: any): MyWorldCategory => {
    return {
        id: data?.Category?.id,
        name: data?.Category?.Name,
        isAdult: data?.Category?.IsAdult,
        titleCount: data?.Category?.TitleCount,
        childCategories:
            data?.Category?.ChildCategories?.Category?.map(subcategory => {
                return {
                    id: subcategory.id,
                    name: subcategory?.Name,
                    isAdult: subcategory?.IsAdult,
                    titleCount: subcategory?.TitleCount,
                };
            }) ?? [],
    };
};

export const parseTopCategories = (data: any): { [key: string]: string } => {
    const typeCategoryMap: { [key: string]: string } = {};

    if (data?.Category?.ChildCategories?.Category) {
        data.Category.ChildCategories.Category.forEach(category => {
            typeCategoryMap[category.KeywordType] = category.id;
        });
    }

    return typeCategoryMap;
};

export const parseWorldItems = (data): MyWorldItem[] => {
    const result: MyWorldItem[] = [];

    if (data) {
        data.forEach(group => {
            if (group?.keyWordValues) {
                group.keyWordValues.forEach(worldValue => {
                    result.push({
                        groupName: group?.keyWordName,
                        groupType: group?.keyWordType,
                        name: worldValue?.name,
                        value: worldValue?.value,
                    });
                });
            }
        });
    }

    return result;
};

export const parseRecordingByEvent = (data): RecordingRelationRef => {
    const raw = data?.Recordings?.Recording?.[0];

    return raw
        ? {
              id: raw?.id,
              eventId: raw?.EventId,
          }
        : null;
};

export const parseSubscriptionOptions = (data: any[]): SubscribeOption[] => {
    return data?.map(item => {
        return {
            name: item?.name,
            description: item?.short_description,
            poster:
                getSubscriptionImage(item?.images, ImageTypes.SUBSCRIPTION_POSTER) ||
                getSubscriptionImage(item?.images, ImageTypes.SUBSCRIPTION_LOGO),
            price: item?.price?.value,
            productId: item?.product_id,
            tags: item?.tags,
        };
    });
};

export const parseOrderApiResponse = (data): OrderSubscription => {
    return {
        cartId: data?.cart_id,
    };
};

export const parseRecordingQuota = (data): RecordingQuota => {
    const customerData = data?.Customers?.Customer?.[0];
    return {
        all: customerData?.NpvrQuota ?? 0,
        remaining: customerData?.NpvrQuotaRemaining ?? 0,
    };
};
