import { RecordingType } from '../types/RecordingTypes';
import icons from '../style';

export enum ImageTypes {
    BACKGROUND = 'Background',
    EPISODE_CARD = 'Episode_Card',
    POSTER = 'Poster',

    // channels
    LOGO_ANDROID = 'logo_android',

    // subscription
    SUBSCRIPTION_POSTER = 'poster',
    SUBSCRIPTION_LOGO = 'logo',
}

type ImageData = {
    src: any;
    type: ImageTypes;
};

export const getSubscriptionImage = (images, key: string = null): string => {
    const imageKeys = Object.keys(images ?? {});
    if (!imageKeys.length) return null;

    return images[key]?.replace('//mw.', '//amw.');
};

export const getImage = (data: any, imageType: ImageTypes): string =>
    data?.Pictures?.Picture?.find(image => image.type === imageType)
        ?.Value?.replace('//mw.', '//amw.')
        .replace('http:', 'https:');

export const getImageUrl = (src: ImageData[]): string => {
    for (let i = 0; i < src.length; i += 1) {
        const imgSrc = src[i];
        const foundImage = getImage(imgSrc.src, imgSrc.type);

        if (foundImage) {
            return foundImage;
        }
    }

    return null;
};

export const getImageWithFallback = (data: any, imageType: ImageTypes, fallbackType?: ImageTypes): string => {
    const imageSources: ImageData[] = [{ src: data, type: imageType }];
    if (fallbackType) {
        imageSources.push({
            src: data,
            type: fallbackType,
        });
    }

    return getImageUrl(imageSources);
};

export const getVodEpisodeCardImage = (episode: any, series: any): string => {
    return getImageUrl([
        { src: episode, type: ImageTypes.EPISODE_CARD },
        { src: series, type: ImageTypes.EPISODE_CARD },
    ]);
};

export const getVodSeriesCardImage = (series: any): string => {
    return getImageWithFallback(series, ImageTypes.EPISODE_CARD, ImageTypes.BACKGROUND);
};

export const getVodMovieCardImage = (movie: any): string => {
    return getImageWithFallback(movie, ImageTypes.POSTER);
};

export const getTvEpisodeCardImage = (episode: any, series: any): string => {
    return getImageUrl([
        { src: episode, type: ImageTypes.POSTER },
        { src: series, type: ImageTypes.POSTER },
    ]);
};

export const getTvMovieCardImage = (movie: any): string => {
    return getImageWithFallback(movie, ImageTypes.POSTER);
};

export const getVodSeriesDetailImage = (episode: any, series: any): string => {
    return getImageUrl([
        { src: episode, type: ImageTypes.BACKGROUND },
        { src: series, type: ImageTypes.BACKGROUND },
    ]);
};

export const getVodMovieDetailImage = (movie: any): string => {
    return getImageUrl([{ src: movie, type: ImageTypes.BACKGROUND }]);
};

export const getTvEpisodeDetailImage = (episode: any, series: any): string => {
    return getImageUrl([
        { src: episode, type: ImageTypes.BACKGROUND },
        { src: series, type: ImageTypes.BACKGROUND },
    ]);
};

export const getTvMovieDetailImage = (movie: any): string => {
    return getImageUrl([{ src: movie, type: ImageTypes.BACKGROUND }]);
};

export const getTVSeriesCardImage = (series: any): string => {
    return getImageWithFallback(series, ImageTypes.POSTER, ImageTypes.BACKGROUND);
};

export const getRecordingIcon = (type: RecordingType, err: boolean = false) => {
    if (!err) {
        switch (type) {
            case 'RECORDED':
                return icons.icoRecordedRecording;
            case 'ONGOING':
                return icons.icoOngoingRecording;
            case 'SCHEDULED':
                return icons.icoScheduledRecording;
            default:
                return null;
        }
    } else {
        return icons.icoFailedRecording;
    }
};

export const getFlag = (type: string) => {
    switch (type) {
        case 'en':
            return icons.EnFlag;
        case 'et':
            return icons.EtFlag;
        case 'ru':
            return icons.RuFlag;
        default:
            return null;
    }
};
