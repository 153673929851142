import { VODAssetEntitlementState } from 'types/Entitlement';
import { Episode, Marker, Movie, Series } from '../types/Asset';
import translate from './fnTranslate';
import { TimeCluster } from '../hooks/useBroadcastTimeCluster/useBroadcastTimeCluster';
import ApplicationConfig from '../providers/useConfig/ApplicationConfig';
import { DAYS, MILLISECONDS, MINUTES } from './TimeUnit';
import { isEpisode, isMovie, isSeries } from './fnTypeGuards';
import { LivePlayerAssetInfo } from '../types/Player';

const getPrioContentMarker = (markers: Marker[]): Marker => {
    if (!markers || !markers.length) return null;
    return markers.sort((a, b) => a.priority - b.priority)[0];
};

const getMarker = (markerKey: string, rentalTime?: number): Marker => {
    const marker = ApplicationConfig.getContentMarker(markerKey);
    let value;

    if (rentalTime) {
        if (markerKey === 'CONTENT_MARKER_REMAINING_HOURS') {
            value = translate(markerKey, { HOURS: `${rentalTime}` });
        }
        if (markerKey === 'CONTENT_MARKER_REMAINING_MIN') {
            value = translate(markerKey, { MINUTES: `${rentalTime}` });
        }
    } else {
        value = translate(markerKey);
    }

    return {
        value,
        backgroundColor: marker.background || ApplicationConfig.layout_config.primary,
        textColor: marker.color,
        priority: marker.priority,
    };
};

export const getBroadcastCardContentMarkerValue = (isLiveEvent: boolean, timeCluster: TimeCluster): Marker => {
    const markers: Marker[] = [];

    if (isLiveEvent && timeCluster === 'LIVE') {
        markers.push(getMarker('CONTENT_MARKER_LIVE'));
    }

    if (timeCluster === 'LIVE') {
        markers.push(getMarker('CONTENT_MARKER_NOW'));
    }
    if (timeCluster === 'FUTURE') {
        markers.push(getMarker('CONTENT_MARKER_UPCOMING'));
    }

    return getPrioContentMarker(markers);
};

export const getNowAndNextCardContentMarkerValue = (isLiveEvent: boolean): Marker => {
    if (isLiveEvent) {
        return getMarker('CONTENT_MARKER_LIVE');
    }

    return null;
};

export const getSeriesCardContentMarkerValue = (
    firstAvailability: number,
    isVOD: boolean,
    lastEpisodeFirstAvailability: number
): Marker => {
    const now = new Date().getTime();
    const markers: Marker[] = [];

    if (firstAvailability !== -1 && now - firstAvailability < DAYS.toMillis(14)) {
        markers.push(getMarker('CONTENT_MARKER_NEW'));
    }

    if (isVOD && lastEpisodeFirstAvailability !== -1 && now - lastEpisodeFirstAvailability < DAYS.toMillis(14)) {
        markers.push(getMarker('CONTENT_MARKER_NEW_EPISODES'));
    }

    return getPrioContentMarker(markers);
};

export const getVodContentMarker = (
    price: number,
    firstAvailability: number,
    entitlementState?: string,
    entitlementEnd?: number
): Marker => {
    const now = new Date().getTime();
    const markers: Marker[] = [];

    if (entitlementState === VODAssetEntitlementState.ENTITLED && entitlementEnd && entitlementEnd - now <= DAYS.toMillis(2)) {
        const remainingTime = entitlementEnd - now;

        if (MILLISECONDS.toMinutes(remainingTime) >= 1) {
            if (remainingTime < MINUTES.toMillis(60)) {
                markers.push(getMarker('CONTENT_MARKER_REMAINING_MIN', Math.floor(MILLISECONDS.toMinutes(remainingTime))));
            } else {
                markers.push(getMarker('CONTENT_MARKER_REMAINING_HOURS', Math.floor(MILLISECONDS.toHours(remainingTime))));
            }
        }
    }

    if (price === 0) {
        markers.push(getMarker('CONTENT_MARKER_FREE'));
    }

    if (firstAvailability !== -1 && now - firstAvailability < DAYS.toMillis(14)) {
        markers.push(getMarker('CONTENT_MARKER_NEW'));
    }

    return getPrioContentMarker(markers);
};

export const getProgramDetailContentMarker = (timeCluster: TimeCluster, isLiveEvent: boolean): Marker => {
    const markers: Marker[] = [];

    if (isLiveEvent && timeCluster === 'LIVE') {
        markers.push(getMarker('CONTENT_MARKER_LIVE'));
    }

    if (timeCluster === 'LIVE') {
        markers.push(getMarker('CONTENT_MARKER_NOW'));
    }
    if (timeCluster === 'FUTURE') {
        markers.push(getMarker('CONTENT_MARKER_UPCOMING'));
    }

    return getPrioContentMarker(markers);
};

export const getTVSeriesDetailContentMarker = (timeCluster: TimeCluster, isLiveEvent?: boolean): Marker => {
    const markers: Marker[] = [];

    if (isLiveEvent && timeCluster === 'LIVE') {
        markers.push(getMarker('CONTENT_MARKER_LIVE'));
    }

    if (timeCluster === 'LIVE') {
        markers.push(getMarker('CONTENT_MARKER_NOW'));
    }
    if (timeCluster === 'FUTURE') {
        markers.push(getMarker('CONTENT_MARKER_UPCOMING'));
    }

    return getPrioContentMarker(markers);
};

export const getGalleryVODContentMarker = (asset: Movie | Series | Episode): Marker => {
    const markers: Marker[] = [];

    const { firstAvailability, price } = asset;
    const now = new Date().getTime();

    if ((isMovie(asset) || isEpisode(asset)) && price === 0) {
        markers.push(getMarker('CONTENT_MARKER_FREE'));
    }

    if (firstAvailability !== -1 && now - firstAvailability < DAYS.toMillis(14)) {
        markers.push(getMarker('CONTENT_MARKER_NEW'));
    }

    if (isSeries(asset) && asset.lastEpisodeFirstAvailability !== -1 && now - asset.lastEpisodeFirstAvailability < DAYS.toMillis(14)) {
        markers.push(getMarker('CONTENT_MARKER_NEW_EPISODES'));
    }

    return getPrioContentMarker(markers);
};

export const getLivePlayerAssetContentMarker = (asset: LivePlayerAssetInfo, timeCluster: TimeCluster): Marker => {
    const markers: Marker[] = [];

    if (!asset) return null;

    if (asset.isLive && timeCluster === 'LIVE') {
        markers.push(getMarker('CONTENT_MARKER_LIVE'));
    }

    if (timeCluster === 'LIVE') {
        markers.push(getMarker('CONTENT_MARKER_NOW'));
    }

    return getPrioContentMarker(markers);
};
