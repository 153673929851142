import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { Breakpoints, MQ } from '../../style/styled-components/cssMediaQueries';
import { fontBold } from '../../style/styled-components/cssGlobalVariables';
import { hexToRgba } from '../../utils/fnColors';

export const ModalDetails = styled.div<{ isVisible: boolean; isScrollable: boolean }>`
    position: absolute;
    visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
    top: ${props => (props.isScrollable ? 0 : 50)}%;
    left: 50%;
    transform: ${props => (props.isScrollable ? 'translate(-50%, 0)' : 'translate(-50%, -50%)')};
    width: 100%;
    padding-top: ${props => (props.isScrollable ? 48 : 0)}px;
`;

export const ModalTitleWrapper = styled.div`
    width: 100%;

    ${MQ(Breakpoints.xs)} {
        display: block;
        margin: 0 auto;
        width: 480px;
    }
`;

export const ModalTitle = styled.div`
    font-size: 24px;
    line-height: 32px;
    width: 100%;
    padding: 0 16px;
    font-family: ${fontBold};
    color: ${props => props.theme.brandedConfig.high_contrast};
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    ${MQ(Breakpoints.xs)} {
        padding: 0;
        width: 480px;
    }

    ${MQ(Breakpoints.s)} {
        -webkit-line-clamp: 2;
    }
`;

export const ModalSubtitle = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    width: 100%;
    padding: 0 16px;
    ${MQ(Breakpoints.xs)} {
        padding: 0;
        width: 480px;
    }
`;

export const DetailMoreAudioAndSubtitle = styled.div<{ isScrollable: boolean }>`
    height: auto;
    min-height: 100%;
    border-radius: 0;
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 16px;
    line-height: 24px;
    padding: 0 16px ${props => (props.isScrollable ? 80 : 0)}px 16px;
    display: block;
    justify-content: center;
`;

export const DetailMoreAudio = styled.div`
    height: auto;
    min-height: 100%;
    border-radius: 0;
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 16px;
    line-height: 24px;
    display: block;
    justify-content: center;

    ${MQ(Breakpoints.xs)} {
        width: 480px;
        margin-right: auto;
        margin-left: auto;
    }
`;

export const DetailMoreSubtitle = styled.div`
    height: auto;
    min-height: 100%;
    border-radius: 0;
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 16px;
    line-height: 24px;
    display: block;
    justify-content: center;

    ${MQ(Breakpoints.xs)} {
        width: 480px;
        margin-right: auto;
        margin-left: auto;
    }
`;

export const ModalContentWrapper = styled.div`
    color: ${props => props.theme.brandedConfig.high_contrast};
    padding-top: 16px;
    opacity: 0.7;

    ${MQ(Breakpoints.xs)} {
        display: block;
    }
`;

export const ModalContent = styled.div`
    height: auto;
    min-height: 100%;
    border-radius: 0;
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 16px;
    line-height: 24px;
    padding: 0 16px 20px 16px;

    ${MQ(Breakpoints.xs)} {
        padding: 0 0 20px 0;
        width: 480px;
        margin-right: auto;
        margin-left: auto;
    }
`;

export const GradientBottom = styled.div`
    display: block;
    position: fixed;
    height: 88px;
    width: 100%;
    bottom: 0;
    background: linear-gradient(
        0deg,
        ${props => props.theme.brandedConfig.black_color} 0%,
        ${props => hexToRgba(props.theme.brandedConfig.black_color, 0)} 100%
    );
`;

export const DetailMoreCast = styled.div`
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;

export const MetadataWrapper = styled.div`
    padding: 0 20px 20px 20px;

    ${ModalContent} {
        padding: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 14px;
    }
`;
