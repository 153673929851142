import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold } from '../../style/styled-components/cssGlobalVariables';
import { PinInputProps } from './InputPin';

export const StyledInputContainer = styled.span`
    display: inline-block;
    position: relative;
`;

export const StyledInput = styled.input<PinInputProps>`
    width: 48px;
    line-height: 88px;
    border: none;
    padding: 0;
    font-size: 48px;
    font-family: ${fontBold};
    color: ${props => props.theme.brandedConfig.high_contrast};
    text-align: center;
    background-color: transparent;
    caret-color: transparent;
    cursor: pointer;
    opacity: 1;

    &:focus {
        outline: none;
    }

    &:required {
        box-shadow: none;
    }

    & + span {
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        opacity: 0;
        font-size: 48px;
        color: ${props => props.theme.brandedConfig.high_contrast};
    }

    &:not([value='']) {
        opacity: 0;
        transition: opacity 0.3s ease-in ${props => (props.showDuration ? props.showDuration : 0)}ms;

        & + span {
            opacity: 1;
            transition: opacity 0.3s ease-in ${props => (props.showDuration ? props.showDuration : 0)}ms;
        }
    }

    &::placeholder {
        content: '○';
        font-family: ${fontBold};
        color: ${props => props.theme.brandedConfig.high_contrast};
        opacity: 1;
    }

    &::selection {
        background-color: transparent;
    }
`;
