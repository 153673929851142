import { css } from 'styled-components';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold, fontNormal, modalZIndex } from '../../style/styled-components/cssGlobalVariables';
import { hexToRgba } from '../../utils/fnColors';
import Button from '../Buttons/Button/Button';
import { Breakpoints, MQ } from '../../style/styled-components/cssMediaQueries';
import { ButtonInner, ButtonWrapper } from '../Buttons/Button/Button.css';

type positionOffsetType = {
    desktop: number;
    tablet: number;
    mobile: number;
};

const pointerHeight: number = 16;

export const HintWrapper = styled.div<{ visible: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    opacity: ${props => (props.visible ? 1 : 0)};
    z-index: ${modalZIndex};
    pointer-events: ${props => (props.visible ? 'auto' : 'none')};
    transition: opacity 0.5s ease-in-out;
`;

export const HintContainer = styled.div<{ top: number; left: number; width: number; height: number }>`
    position: absolute;
    top: ${props => props.top}px;
    left: ${props => props.left}px;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;

export const HintBox = styled.div<{
    position: string[];
    verticalOffset: positionOffsetType;
    horizontalOffset: positionOffsetType;
}>`
    position: absolute;
    width: calc(100vw - 32px);
    height: auto;
    background-color: ${props => props.theme.brandedConfig.low_contrast};
    transition: all 0.4s ease-in-out;
    padding: 24px;
    border-radius: 10px;

    ${MQ(Breakpoints.xs)} {
        width: 406px;
    }

    ${props => {
        return css`
            top: ${props.position[0] === 'top'
                ? 'auto'
                : `calc(100% ${
                      props.verticalOffset.mobile + pointerHeight < 0
                          ? `- ${-props.verticalOffset.mobile + pointerHeight}`
                          : `+ ${props.verticalOffset.mobile + pointerHeight}`
                  }px)`};
            bottom: ${props.position[0] === 'top'
                ? `calc(100% ${
                      props.verticalOffset.mobile + pointerHeight < 0
                          ? `- ${-(props.verticalOffset.mobile + pointerHeight)}`
                          : `+ ${props.verticalOffset.mobile + pointerHeight}`
                  }px)`
                : 'auto'};
            left: ${props.position[1] === 'left' ? `${props.horizontalOffset.mobile}px` : 'auto'};
            right: ${props.position[1] === 'left' ? 'auto' : `${props.horizontalOffset.mobile}px`};
        `;
    }}

    ${MQ(Breakpoints.s)} {
        ${props => {
            return css`
                top: ${props.position[0] === 'top'
                    ? 'auto'
                    : `calc(100% ${
                          props.verticalOffset.tablet + pointerHeight < 0
                              ? `- ${-props.verticalOffset.tablet + pointerHeight}`
                              : `+ ${props.verticalOffset.tablet + pointerHeight}`
                      }px)`};
                bottom: ${props.position[0] === 'top'
                    ? `calc(100% ${
                          props.verticalOffset.tablet + pointerHeight < 0
                              ? `- ${-(props.verticalOffset.tablet + pointerHeight)}`
                              : `+ ${props.verticalOffset.tablet + pointerHeight}`
                      }px)`
                    : 'auto'};
                left: ${props.position[1] === 'left' ? `${props.horizontalOffset.tablet}px` : 'auto'};
                right: ${props.position[1] === 'left' ? 'auto' : `${props.horizontalOffset.tablet}px`};
            `;
        }}
    }

    ${MQ(Breakpoints.m)} {
        ${props => {
            return css`
                top: ${props.position[0] === 'top'
                    ? 'auto'
                    : `calc(100% ${
                          props.verticalOffset.desktop + pointerHeight < 0
                              ? `- ${-props.verticalOffset.desktop + pointerHeight}`
                              : `+ ${props.verticalOffset.desktop + pointerHeight}`
                      }px)`};
                bottom: ${props.position[0] === 'top'
                    ? `calc(100% ${
                          props.verticalOffset.desktop + pointerHeight < 0
                              ? `- ${-(props.verticalOffset.desktop + pointerHeight)}`
                              : `+ ${props.verticalOffset.desktop + pointerHeight}`
                      }px)`
                    : 'auto'};
                left: ${props.position[1] === 'left' ? `${props.horizontalOffset.desktop}px` : 'auto'};
                right: ${props.position[1] === 'left' ? 'auto' : `${props.horizontalOffset.desktop}px`};
            `;
        }}
    }

    &:before {
        position: absolute;
        content: '';
        border-radius: 10px;
        background-color: ${props => hexToRgba(props.theme.brandedConfig.white_color, 0.1)};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
`;

export const HintButtonsContainer = styled.div`
    padding-top: 20px;
    display: flex;
    align-items: stretch;

    ${ButtonWrapper} {
        min-width: auto;
    }

    ${ButtonInner} {
        white-space: normal;
        display: flex;
        justify-content: center;
        align-items: center;

        ${MQ(Breakpoints.s)} {
            display: block;
        }
    }
`;

export const HintButton = styled(Button)`
    font-family: ${fontBold};
    font-size: 16px;
    flex: 1 1 auto;

    ${MQ(Breakpoints.s)} {
        flex: 1 1 auto;
    }
    padding: 0 8px;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }
`;

export const Pointer = styled.div<{ position: string[] }>`
    position: absolute;
    border-style: solid;
    border-width: 0 16px 16px 16px;
    left: 68px;
    width: 0;
    height: 0;

    ${props => {
        return css`
            top: ${props.position[0] === 'top' ? 'auto' : '-16px'};
            bottom: ${props.position[0] === 'top' ? '-16px' : 'auto'};
            border-color: transparent transparent ${props.theme.brandedConfig.low_contrast} transparent;
            transform: translateX(-50%) rotate(${props.position[0] === 'top' ? '180deg' : '0deg'});
        `;
    }}

    ${MQ(Breakpoints.s)} {
        left: 90px;
    }

    &:before {
        position: absolute;
        content: '';
        border-style: solid;
        border-width: 0 16px 16px 16px;
        top: 0;
        left: -16px;
        border-color: transparent transparent ${props => hexToRgba(props.theme.brandedConfig.white_color, 0.1)} transparent;
    }
`;

export const HintText = styled.p`
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 20px;
    line-height: 24px;
    font-family: ${fontNormal};
    color: ${props => props.theme.brandedConfig.high_contrast};
`;

export const HintOverlay = styled.div`
    position: absolute;
    background-color: ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.7)};
    width: 200vw;
    height: 200vh;
`;

export const HintOverlayTop = styled(HintOverlay)`
    left: -100%;
    bottom: 100%;
`;

export const HintOverlayBottom = styled(HintOverlay)`
    top: 100%;
    left: -100%;
`;

export const HintOverlayLeft = styled(HintOverlay)`
    top: 0;
    right: 100%;
    height: 100%;
`;

export const HintOverlayRight = styled(HintOverlay)`
    top: 0;
    left: 100%;
    height: 100%;
`;
