import React from 'react';
import { RemoveScroll } from 'react-remove-scroll';

export const withNoScroll = (Wrapped: React.FC | React.ComponentClass) =>
    function WrappedWithOverlay(props) {
        return (
            <RemoveScroll removeScrollBar={true}>
                <Wrapped {...props} />
            </RemoveScroll>
        );
    };
