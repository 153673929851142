import React, { useEffect, useRef, useState } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import { MILLISECONDS } from 'utils/TimeUnit';
import {
    EpgTimeLineInnerWrapper,
    JumpToTimeIcon,
    JumpToTimeIndicator,
    JumpToTimeIndicatorLabel,
    NowTimeIndicator,
    PrimeTimeIndicator,
    Thumb,
    TimeLabel,
    TimeLine,
    TimeLineTimeLabels,
    TimeLineWrapper,
} from './EpgTimeLine.css';
import { isFutureDate, isToday } from '../../../utils/fnEpg';
import { SelectorOption } from '../EpgFilter/EpgFilter';

type PropTypes = {
    pastLength: number;
    primeTimeIndicatorPosition: number;
    selectedDate: Date;
    dayStartHour: number;
    timeIndicatorIcon: any;
    pixelsPerMinute: number;
    scrubber: any;
    nowTimeText: string;
    primeTimeText: string;
    possibleDates: SelectorOption[];
    onTimeLineScroll: (e) => void;
    obtainReference: (Scrollbar) => void;
    jumpToNow?: () => void;
    jumpToPrimeTime?: () => void;
    jumpToNextDay?: (nr: number) => void;
};

export type JumpPosition = 'past' | 'future' | null;
export type JumpLabels = 'now' | 'primeTime' | 'nextDay';
type IconProps = {
    isNow: boolean;
    text: string;
    position: JumpPosition;
    type: JumpLabels;
    onClick: () => void;
};

const JumpIcon: React.FC<IconProps> = ({ onClick, position, text, isNow, type }) => {
    // left
    return (
        <JumpToTimeIndicator data-test-id={'tv-tvguide-redirecttotodaybutton'} onClick={onClick} position={position}>
            <JumpToTimeIcon position={position} isNow={isNow} type={type} />
            <JumpToTimeIndicatorLabel data-test-id={'tv-tvguide-nowquickjumper'} isDayJumper={type === 'nextDay'} position={position}>
                {text}
            </JumpToTimeIndicatorLabel>
        </JumpToTimeIndicator>
    );
};

export const EpgTimeLine: React.FC<PropTypes> = ({
    pastLength,
    primeTimeIndicatorPosition,
    selectedDate,
    dayStartHour,
    timeIndicatorIcon,
    scrubber,
    nowTimeText,
    primeTimeText,
    possibleDates,
    onTimeLineScroll,
    obtainReference,
    pixelsPerMinute,
    jumpToNow,
    jumpToPrimeTime,
    jumpToNextDay,
}) => {
    const [nowPosition, setNowPosition] = useState<JumpPosition>(null);
    const [primeTimePosition, setPrimeTimePosition] = useState<JumpPosition>(null);
    const [nextDayPosition, setNextDayPosition] = useState<JumpPosition>(null);
    const [dayLabel, setDayLabel] = useState<string>(null);
    const [dayDirection, setDayDirection] = useState<number>(null);
    const [showJumpIcon, setShowJumpIcon] = useState<boolean>(true);

    const timeLineRef = useRef<HTMLDivElement>(null);
    const scroller = useRef<Scrollbar>(null);
    const jumpIconTimeoutRef = useRef<number>(null);

    const passReference = (reference: Scrollbar) => {
        scroller.current = reference;
        obtainReference(reference);
    };

    const getHour = hour => {
        if (hour < 10) {
            return `0${hour}`;
        }

        return hour;
    };

    const renderTimeLine = () => {
        // eslint-disable-next-line prefer-const
        let hours = [];
        let applyHide = true;

        for (let i = dayStartHour; i < dayStartHour + 24; i += 1) {
            const first = `${i < 24 ? getHour(i) : getHour(i - 24)}:00`;
            const second = `${i < 24 ? getHour(i) : getHour(i - 24)}:30`;

            hours.push(
                <TimeLabel
                    data-test-id={'tv-tvguide-timelabel'}
                    className={applyHide ? 'hidden' : ''}
                    width={pixelsPerMinute * 30}
                    key={first + i}
                >
                    {first}
                </TimeLabel>
            );

            if (applyHide) {
                applyHide = false;
            }

            hours.push(
                <TimeLabel width={pixelsPerMinute * 30} key={second + i}>
                    {second}
                </TimeLabel>
            );
        }

        return hours;
    };

    const renderNowTimeIndicator = () => {
        return (
            isToday(selectedDate, dayStartHour) && (
                <NowTimeIndicator data-test-id={'tv-tvguide-nowtimeindicator'} left={pastLength - 5.5}>
                    {timeIndicatorIcon}
                </NowTimeIndicator>
            )
        );
    };

    const renderPrimeTimeIndicator = () => {
        return primeTimeText && <PrimeTimeIndicator left={primeTimeIndicatorPosition - 7}>{timeIndicatorIcon}</PrimeTimeIndicator>;
    };

    const renderNextDayLabel = (day: Date) => {
        const nextDayIndex = possibleDates.findIndex(option => option.value === MILLISECONDS.toSeconds(day.getTime()));
        if (nextDayIndex >= 0 && nextDayIndex < possibleDates.length) {
            setDayLabel(possibleDates[nextDayIndex]?.label);
        } else {
            setDayLabel(null);
        }
    };

    const updateJumpLabels = e => {
        if (timeLineRef.current) {
            // now indicator
            if (!isToday(selectedDate, dayStartHour)) {
                setNowPosition(isFutureDate(selectedDate) ? 'past' : 'future');
            } else if (e) {
                const { scrollLeft } = e;
                if (scrollLeft > pastLength + 10) {
                    setNowPosition('past');
                } else if (scrollLeft < pastLength && scrollLeft + timeLineRef.current.clientWidth < pastLength) {
                    setNowPosition('future');
                } else {
                    setNowPosition(null);
                }
            } else {
                setNowPosition(null);
            }

            // prime time indicator
            if (e) {
                const { scrollLeft } = e;

                if (scrollLeft > primeTimeIndicatorPosition + 10) {
                    setPrimeTimePosition('past');
                } else if (
                    scrollLeft < primeTimeIndicatorPosition &&
                    scrollLeft + timeLineRef.current.clientWidth < primeTimeIndicatorPosition
                ) {
                    setPrimeTimePosition('future');
                } else {
                    setPrimeTimePosition(null);
                }
            }
            // next-previous day quick jumpers
            if (e) {
                const { scrollLeft } = e;
                const timeLineWidth = scroller.current.scrollWidth;

                if (scrollLeft === 0) {
                    const previousDay = new Date(selectedDate.getTime());
                    previousDay.setDate(previousDay.getDate() - 1);
                    renderNextDayLabel(previousDay);
                    setDayDirection(-1);
                    setNextDayPosition('past');
                } else if (timeLineWidth - (scrollLeft + timeLineRef.current.clientWidth) <= 20) {
                    const nextDay = new Date(selectedDate.getTime());
                    nextDay.setDate(nextDay.getDate() + 1);
                    renderNextDayLabel(nextDay);
                    setDayDirection(1);
                    setNextDayPosition('future');
                } else {
                    setNextDayPosition(null);
                    setDayDirection(null);
                }
            }
        }
    };

    const onTimeLineScrollHandler = e => {
        onTimeLineScroll(e);
        updateJumpLabels(e);

        setShowJumpIcon(false);
        clearTimeout(jumpIconTimeoutRef?.current);
        jumpIconTimeoutRef.current = window.setTimeout(() => {
            setShowJumpIcon(true);
        }, 1000);
    };

    useEffect(() => {
        updateJumpLabels({
            scrollLeft: scroller.current?.scrollLeft,
        });
    }, [selectedDate, scroller.current]);

    return (
        <TimeLineWrapper ref={timeLineRef}>
            <TimeLineTimeLabels>
                {showJumpIcon && (
                    <>
                        {nowPosition && nowPosition !== nextDayPosition && (
                            <JumpIcon type={'now'} isNow={true} onClick={jumpToNow} text={nowTimeText} position={nowPosition} />
                        )}
                        {primeTimeText && primeTimePosition && nowPosition !== primeTimePosition && (
                            <JumpIcon
                                type={'primeTime'}
                                isNow={false}
                                onClick={jumpToPrimeTime}
                                position={primeTimePosition}
                                text={primeTimeText}
                            />
                        )}
                        {nextDayPosition && dayLabel && (
                            <JumpIcon
                                type={'nextDay'}
                                isNow={false}
                                onClick={() => {
                                    jumpToNextDay(dayDirection);
                                }}
                                text={dayLabel}
                                position={nextDayPosition}
                            />
                        )}
                    </>
                )}
            </TimeLineTimeLabels>
            <TimeLine
                onScroll={onTimeLineScrollHandler}
                minimalThumbXSize={60}
                maximalThumbXSize={60}
                thumbXProps={{
                    // eslint-disable-next-line react/display-name
                    renderer: props => {
                        const { elementRef } = props;
                        return (
                            <Thumb data-test-id={'tv-tvguide-scrollbar'} ref={elementRef}>
                                {scrubber}
                            </Thumb>
                        );
                    },
                }}
                ref={scrollerRef => passReference(scrollerRef)}
            >
                <EpgTimeLineInnerWrapper>
                    {renderTimeLine()}
                    {renderNowTimeIndicator()}
                    {renderPrimeTimeIndicator()}
                </EpgTimeLineInnerWrapper>
            </TimeLine>
        </TimeLineWrapper>
    );
};
