import React, { FC, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import SVGInline from 'react-svg-inline';
import {
    HintText,
    LockedPinCounter,
    ModalContentWrapper,
    ModalDetails,
    ModalText,
    ModalTitle,
    ResetButton,
    ResetContainer,
} from './PinModal.css';
import icons from '../../style';
import { withNoScroll } from '../../hooks/withNoScroll/withNoScroll';
import { PinInputGroup } from './InputPinGroup';
import { MILLISECONDS, SECONDS } from '../../utils/TimeUnit';
import translate from '../../utils/fnTranslate';
import { ModalCloseButton, ModalWrapper } from '../../style/styled-components/reusable.css';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
import { hexToRgba } from '../../utils/fnColors';
import { useConfig } from '../../providers/useConfig/ConfigContext';

type PropTypes = {
    loading: boolean;
    modalTitle?: string;
    modalText?: string;
    resetInput?: boolean;
    lockPinUntil?: number;
    onPinInserted: () => void;
    closeOverlay: () => void;
};

const PinModalComponent: FC<PropTypes> = ({ modalTitle, modalText, resetInput, lockPinUntil, closeOverlay, onPinInserted, loading }) => {
    const config = useConfig()?.config;

    const { black_color } = config?.layout_config ?? {};
    const { reset } = config?.api_config?.routes?.Pin ?? {};

    const scrollbarRef = useRef<HTMLDivElement>(null);
    const contentScrollbarRef = useRef(null);
    const lockCountDown = useRef<number>(null);

    const [remainedLockSeconds, setRemainedLockSeconds] = useState<number>(0);

    useEffect(() => {
        const now = Date.now();

        if (lockPinUntil && lockPinUntil > now) {
            clearInterval(lockCountDown.current);

            setRemainedLockSeconds(Math.round(MILLISECONDS.toSeconds(lockPinUntil - Date.now())));
            lockCountDown.current = window.setInterval(() => {
                setRemainedLockSeconds(Math.round(MILLISECONDS.toSeconds(lockPinUntil - Date.now())));
            }, SECONDS.toMillis(1));
        } else {
            setRemainedLockSeconds(null);
            clearInterval(lockCountDown.current);
        }

        return () => clearInterval(lockCountDown.current);
    }, [lockPinUntil]);

    const now = Date.now();
    const isLocked = lockPinUntil && lockPinUntil > now;

    const hintText = <HintText>{translate('RESET_PIN_HINT')}</HintText>;

    return createPortal(
        <ModalWrapper data-test-id={'modal-pin-screen'} ref={scrollbarRef}>
            <ModalCloseButton data-test-id={'header-settings-protectpin-close-button'} onClick={closeOverlay}>
                <SVGInline svg={icons.closeIcon} />
            </ModalCloseButton>
            <ModalDetails ref={contentScrollbarRef}>
                <ModalTitle data-test-id={'header-settings-pin-title'}>{modalTitle}</ModalTitle>
                <ModalContentWrapper>
                    <ModalText data-test-id={'header-settings-pin-text'}>{modalText}</ModalText>
                    {!isLocked && <PinInputGroup onComplete={onPinInserted} reset={resetInput} />}
                    {isLocked && <LockedPinCounter>{remainedLockSeconds}</LockedPinCounter>}
                    {isLocked && (
                        <ResetContainer>
                            <ResetButton label={translate('RESET_PIN_BUTTON')} isPrimary={true} link={reset} openNewTab={true} />
                            {hintText}
                        </ResetContainer>
                    )}
                </ModalContentWrapper>
            </ModalDetails>
            {isLocked && (
                <ResetContainer>
                    {hintText}
                    <ResetButton label={translate('RESET_PIN_BUTTON')} isPrimary={true} link={reset} openNewTab={true} />
                </ResetContainer>
            )}
            <LoadingIndicator overlay={true} isLoading={loading} backgroundOverwrite={hexToRgba(black_color, 0.5)} />
        </ModalWrapper>,
        document.getElementById('modal-root')
    );
};

export const PinModal = withNoScroll(PinModalComponent);
