import React, { FC, useEffect } from 'react';
import SVGInline from 'react-svg-inline';
import { useAuth } from 'providers/useAuth/AuthContext';
import { useConfig } from 'providers/useConfig/ConfigContext';
import { isMQDesktop } from 'style/styled-components/cssMediaQueries';
import { useHistory } from 'react-router';
import { useApp } from 'providers/useApp/AppContext';
import { StaticPageRoutes } from 'types/RouteTypes';
import {
    CenterButton,
    Option,
    OptionButton,
    OptionIcon,
    OptionName,
    OptionNameSub,
    OptionRightSide,
    PageWrapper,
    Subtitle,
} from '../PageSettingsCommon.css';
import translate from '../../../../utils/fnTranslate';
import icons from '../../../../style';
import { redirectTo } from './AppSettings';

export const AccountSettings: FC = () => {
    const { userInfoDetails } = useAuth();
    const { config } = useConfig();
    const history = useHistory();

    if (!userInfoDetails) {
        return null;
    }

    const { subscription, email } = userInfoDetails;
    const changeCredentialsURL = config.api_config.routes.Auth.change_credentials.replace('{id}', subscription);

    const getDesktopSection = () => {
        return (
            <>
                <Subtitle hideOnSmallRes={true}>{translate('SETTINGS_ACCOUNT_INFO_WEB')}</Subtitle>
                <Option clickable={true} onClick={() => redirectTo(changeCredentialsURL)} reducedHeight={true}>
                    <OptionIcon>
                        <SVGInline svg={icons.mailIcon} />
                    </OptionIcon>
                    <OptionName>
                        {translate('CHANGE_EMAIL_LINK')}
                        <OptionNameSub>{email}</OptionNameSub>
                    </OptionName>
                    <OptionRightSide>
                        <OptionButton>
                            <SVGInline svg={icons.rightArrowIcon} />
                        </OptionButton>
                    </OptionRightSide>
                </Option>
                <Option clickable={true} onClick={() => redirectTo(changeCredentialsURL)} reducedHeight={true}>
                    <OptionIcon>
                        <SVGInline svg={icons.lockIcon} />
                    </OptionIcon>
                    <OptionName>
                        {translate('CHANGE_PASSWORD_LINK')}
                        <OptionNameSub>**********</OptionNameSub>
                    </OptionName>
                    <OptionRightSide>
                        <OptionButton>
                            <SVGInline svg={icons.rightArrowIcon} />
                        </OptionButton>
                    </OptionRightSide>
                </Option>
            </>
        );
    };

    const getMobileSection = () => {
        return (
            <Option onClick={() => history.push(StaticPageRoutes.ACCOUNT)}>
                <OptionIcon>
                    <SVGInline svg={icons.accountIcon} />
                </OptionIcon>
                <OptionName>{translate('SETTINGS_ACCOUNT_INFO')}</OptionName>
                <OptionRightSide>
                    <OptionButton>
                        <SVGInline svg={icons.rightArrowIcon} />
                    </OptionButton>
                </OptionRightSide>
            </Option>
        );
    };

    return isMQDesktop() ? getDesktopSection() : getMobileSection();
};

export const AccountSettingsPage: FC = () => {
    const { setPageTitle } = useApp();
    const { userInfoDetails } = useAuth();
    const { config } = useConfig();

    useEffect(() => {
        setPageTitle(translate('SETTINGS_ACCOUNT_INFO'));
    }, []);

    if (!userInfoDetails) {
        return null;
    }

    const { subscription, email } = userInfoDetails;
    const changeCredentialsURL = config.api_config.routes.Auth.change_credentials.replace('{id}', subscription);

    return (
        <PageWrapper>
            <Option>
                <OptionName>{translate('SETTINGS_ACCOUNT_INFO_EMAIL')}</OptionName>
                <OptionRightSide>
                    <OptionName>{email}</OptionName>
                </OptionRightSide>
            </Option>
            <Option>
                <OptionName>{translate('SETTINGS_ACCOUNT_INFO_PASSWORD')}</OptionName>
                <OptionRightSide>
                    <OptionName>{'**********'}</OptionName>
                </OptionRightSide>
            </Option>
            <CenterButton
                label={translate('CHANGE_ACCOUNT_INFO_BUTTON')}
                isPrimary={true}
                onClick={() => redirectTo(changeCredentialsURL)}
            />
        </PageWrapper>
    );
};
