import React, { FC, useEffect } from 'react';
import { useApp } from '../../providers/useApp/AppContext';
import { AlertDialog } from '../../components/AlertDialog/AlertDialog';
import translate from '../../utils/fnTranslate';

export const NoInternetAlert: FC<{ onRetryClicked: () => void }> = ({ onRetryClicked }) => {
    return (
        <AlertDialog
            title={translate('ERR_NO_INTERNET_TITLE')}
            bodyText={translate('ERR_NO_INTERNET_BODY')}
            buttons={[
                {
                    text: translate('TRY_AGAIN_BUTTON'),
                    onClick: onRetryClicked,
                },
            ]}
        />
    );
};

export const NetworkErrorAlert: FC<{ onRetryClicked: () => void; networkError: number }> = ({ onRetryClicked, networkError }) => {
    return (
        <AlertDialog
            title={translate('ERR_BACKEND_SERVICE_DOWN_TITLE')}
            bodyText={translate('ERR_BACKEND_SERVICE_DOWN_BODY')}
            errorMessage={networkError !== -1 ? `${translate('ERR_CODE')} ${networkError}` : ''}
            buttons={[
                {
                    text: translate('TRY_AGAIN_BUTTON'),
                    onClick: onRetryClicked,
                },
            ]}
        />
    );
};

export function withNetworkCheck(Wrapped: React.FC | React.ComponentClass) {
    return function WrappedWithOverlay(props) {
        const { noInternet, setNoInternet, networkError, setNetworkError } = useApp();

        const renderWrapped = () => {
            return (
                <React.Fragment>
                    {!noInternet && !networkError && <Wrapped {...props} />}
                    {noInternet && (
                        <NoInternetAlert
                            onRetryClicked={() => {
                                setNoInternet(false);
                            }}
                        />
                    )}
                    {networkError && (
                        <NetworkErrorAlert
                            networkError={networkError}
                            onRetryClicked={() => {
                                setNetworkError(null);
                            }}
                        />
                    )}
                </React.Fragment>
            );
        };

        return renderWrapped();
    };
}

export const useGlobalNoInternet = () => {
    const { setNoInternet, noInternet, setNetworkError } = useApp();

    return {
        noInternet,
        onNoInternet: () => {
            setNoInternet(true);
            setNetworkError(null);
        },
        onInternet: () => {
            setNoInternet(false);
            setNetworkError(null);
        },
    };
};

export const useGlobalNetworkError = () => {
    const { setNetworkError, networkError, setNoInternet } = useApp();

    return {
        networkError,
        onNetworkError: (errorCode: number) => {
            setNetworkError(errorCode);
            setNoInternet(false);
        },
    };
};

export const NetworkErrorBoundaryInner: FC<{ error }> = ({ error, children }) => {
    const { setNoInternet } = useApp();

    useEffect(() => {
        if (error) {
            setNoInternet(true);
        }
    }, [error]);

    return <>{children}</>;
};

export class NetworkErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);

        this.setState({
            error,
            errorInfo,
        });
    }

    render() {
        // eslint-disable-next-line dot-notation
        return <NetworkErrorBoundaryInner error={this.state['error']}>{this.props.children}</NetworkErrorBoundaryInner>;
    }
}
