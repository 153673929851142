import { IPlayer } from './abstract/IPlayer';
import CastHandler from '../cast/CastHandler';
import { BitrateTrack, PlayerEvents, PlayingAsset, Track } from '../../types/Player';
import CastPlayer from '../cast/CastPlayer';

const CAST_START_OFFSET = 5;

export class PlayerProxy extends IPlayer {
    private readonly localPlayer: IPlayer = null;

    private readonly castPlayer: IPlayer = null;

    private resumeProgress = -1;

    private resumeAudio = null;

    private resumeSubtitle = null;

    private localPlayerSnapshot = {
        startPosition: -1,
        selectedAudio: null,
        selectedSubtitle: null,
    };

    private player = () => (CastHandler.isCast() ? this.castPlayer : this.localPlayer);

    private castStatusChanged = () => {
        if (CastHandler.isCast()) {
            this.localPlayer.pause();
            this.localPlayerSnapshot = {
                startPosition:
                    this.localPlayer.getCurrentTime() > CAST_START_OFFSET
                        ? this.localPlayer.getCurrentTime() - CAST_START_OFFSET
                        : this.localPlayer.getCurrentTime(),
                selectedSubtitle: this.localPlayer.getSelectedSubtitle(),
                selectedAudio: this.localPlayer.getSelectedLanguage(),
            };
        }
    };

    private timeUpdated = () => {
        if (CastHandler.isCast()) {
            this.resumeProgress = CastHandler.getMediaInfo()?.progress ?? -1;
        }
    };

    private tracksChanged = () => {
        this.resumeAudio = CastHandler.getMediaInfo().selectedAudio;
        this.resumeSubtitle = CastHandler.getMediaInfo().isSubtitleVisible ? CastHandler.getMediaInfo().selectedSubtitle : null;
    };

    private mediaChanged = () => {
        this.resumeAudio = null;
        this.resumeSubtitle = null;
        this.resumeProgress = null;
    };

    constructor(localPlayer: IPlayer) {
        super();

        this.localPlayer = localPlayer;
        this.castPlayer = new CastPlayer();

        CastHandler.addEventListener(PlayerEvents.CAST_STATUS_CHANGED, this.castStatusChanged);
        CastHandler.addEventListener(PlayerEvents.TIME_UPDATE, this.timeUpdated);
        CastHandler.addEventListener(PlayerEvents.TRACKS_CHANGED, this.tracksChanged);
        CastHandler.addEventListener(PlayerEvents.MEDIA_CHANGED, this.mediaChanged);
    }

    getAudioLanguages = () => this.player()?.getAudioLanguages();

    getThumbnailTrack = () => this.player()?.getThumbnailTrack();

    getBitrateTracks = () => this.player()?.getBitrateTracks();

    getThumbnails = (id: number, pos: number) => this.player()?.getThumbnails(id, pos);

    getBuffered = (): any => this.player()?.getBuffered();

    getCurrentTime = (): number => this.player()?.getCurrentTime();

    getDuration = (): number => this.player()?.getDuration();

    getTextLanguages = (): any => this.player()?.getTextLanguages();

    getVolume = (): number => this.player()?.getVolume();

    isMuted = (): boolean => this.player()?.isMuted();

    isPlaying = (): boolean => this.player()?.isPlaying();

    mute = (state: boolean, userAction: boolean): void => this.player()?.mute(state, userAction);

    pause = (): void => this.player()?.pause();

    play = (): void => this.player()?.play();

    removeEventListener = (event: string, callback: (object?: {}) => void): void => {
        if (this.localPlayer) {
            this.localPlayer.removeEventListener(event, callback);
        }

        if (this.castPlayer) {
            this.castPlayer.removeEventListener(event, callback);
        }
    };

    addEventListener = (event: string, callback: (object?: {}) => void): void => {
        if (this.player()) {
            this.player().addEventListener(event, callback);
        }
    };

    seekTo = (seek: number): void => {
        this.player()?.seekTo(seek);
    };

    setAudioLanguage = (track: Track): void => {
        if (this.player()) {
            this.player().setAudioLanguage(track);
        }

        if (!CastHandler.isCast() && track) {
            this.resumeAudio = null;
        }
    };

    setTextLanguage = (track: Track): void => {
        if (this.player()) {
            this.player().setTextLanguage(track);
        }

        if (!CastHandler.isCast() && track) {
            this.resumeSubtitle = null;
        }
    };

    setBitrateTrack = (track: BitrateTrack): void => {
        if (this.player()) {
            this.player().setBitrateTrack(track);
        }
    };

    setAsset = (assetDetails: PlayingAsset, startTime: number, suffixDomain: 'tv' | 'vod', preparedCb: () => void): void => {
        const startTimeOverwrite = !CastHandler.isCast() && this.resumeProgress > -1 ? this.resumeProgress : startTime;

        this.resumeProgress = -1;

        if (this.player()) {
            this.player().setAsset(assetDetails, startTimeOverwrite, suffixDomain, preparedCb);
        }
    };

    setVolume = (level: number): void => this.player()?.setVolume(level);

    getDRMType = () => this.player()?.getDRMType();

    getSelectedLanguage = () => {
        if (CastHandler.isCast()) {
            return this.player()?.getSelectedLanguage();
        }

        return this.resumeAudio;
    };

    getSelectedSubtitle = () => {
        if (CastHandler.isCast()) {
            return this.player()?.getSelectedSubtitle();
        }

        return this.resumeSubtitle;
    };

    resetPlayer = (isRelease?: boolean) => {
        if (this.localPlayer) {
            this.localPlayer.resetPlayer();
        }

        if (isRelease) {
            CastHandler.killListeners();
        }
    };

    getSnapshot = () => {
        const returnValue = { ...this.localPlayerSnapshot };

        this.localPlayerSnapshot = null;

        return returnValue;
    };

    configureEngine = (config: any) => {
        this.player().configureEngine(config);
    };

    getYoubora = () => {
        return this.player()?.getYoubora();
    };
}
