import { Track } from './Player';

export enum CastEvent {
    LOADED = 'loaded',
    PLAYER_CREATED = 'player_created',
    CONTROLLER_CREATED = 'controller_created',
    CASTING = 'casting',
    RECEIVER_NAME = 'receiver_name',
    MEDIA_INFO_CHANGED = 'media_info_changed',
}

export type CastMediaInformation = {
    id: string;
    type: CastAssetType;
    title: string;
    progress: number;
    duration: number;
    image: string;
    volume: number;
    muted: boolean;
    playing: boolean;
    loaded: boolean;
    audioLanguages?: any[];
    subtitleLanguages?: any[];
    selectedAudio?: Track;
    selectedSubtitle?: Track;
    isSubtitleVisible?: boolean;
    mode: 'normal' | 'time_shift';
    channelId?: string;
};

export type CastAssetType = 'LIVE_TV' | 'CATCHUP' | 'RECORDING' | 'VOD' | 'TRAILER';
export type CastStreamType = 'NONE' | 'BUFFERED' | 'LIVE';

export type AppData = {
    contentId: string;
    streamType: CastStreamType;
    customData: {
        accessToken: string;
        refreshToken: string;
        assetType: CastAssetType;
        selectedAudio: string;
        selectedSubtitle: string;
        startPosition?: number;
    };
};

export type MiniControlState = 'opening' | 'opened' | 'closing' | 'closed';
