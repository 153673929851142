import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';

export type PictureStyleProps = {
    fit?: 'cover' | 'contain' | 'scale-down';
    vPosition?: 'top' | 'center' | 'bottom';
    hPosition?: 'left' | 'center' | 'right';
    alwaysLoad?: boolean;
    loaded?: boolean;
};

export const PictureWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

export const PictureStyle = styled.picture<PictureStyleProps>`
    opacity: ${props => (props.loaded ? '1' : '0')};
    display: block;
    height: 100%;
    transition: opacity ease 1s;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: ${({ fit = 'cover' }) => fit};
        object-position: ${({ vPosition = 'center' }) => vPosition} ${({ hPosition = 'center' }) => hPosition};
    }
`;

export const LoadingPlaceholder = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    img {
        width: 100%;
    }
`;
