import { useHistory, useLocation } from 'react-router';
import { useApp } from '../providers/useApp/AppContext';

export const useNavigation = () => {
    const history = useHistory();
    const location = useLocation();
    const { historyLength } = useApp();

    const onBackPressed = (fallbackPath = '/') => {
        if (historyLength < 2) {
            history.replace(fallbackPath);
        } else {
            history.goBack();
        }
    };

    return {
        history,
        location,
        onBackPressed,
    };
};
