import React, { FC, useEffect, useRef } from 'react';
import axios from 'axios';
import { getMiniPlayerProps } from 'utils/fnData';
import { usePlayer } from '../../../providers/player/PlayerContext';
import { PlayerWrapper } from '../../Player/PlayerWrapper';
import { getCatchupPlayOptionDetailURL, getLivePlayerURL } from '../../../utils/fnUrl';
import { useDataFetcher, useRequestCanceller } from '../../../hooks/useDataFetcher/useDataFetcher';
import { BroadcastPlayerAsset, CatchupPlayerAssetInfo, PlayerEvents, PlayingAssetType } from '../../../types/Player';
import Api from '../../../api/Api';
import { PageErrorNotFound } from '../PageErrorNotFound/PageErrorNotFound';
import { useKPI, useTrackingScreen } from '../../../providers/useTracking/TrackingContext';
import { useAuth } from '../../../providers/useAuth/AuthContext';
import { FetchPlayingAsset } from '../../../types/ApiTypes';
import { useGlobalNetworkError, useGlobalNoInternet, withNetworkCheck } from '../../../hooks/withNetworkCheck/withNetworkCheck';
import { HelmetWrapper } from '../../HelmetWrapper/HelmetWrapper';
import { getPlayerPageHelmetData } from '../../../utils/fnHelmet';
import { withPlayer } from '../../../hooks/withPlayer/withPlayer';
import { usePlayerEntitlementChecker } from '../../../hooks/useEntitlementChecker/usePlayerEntitlementChecker';
import { useRecording } from '../../../providers/useRecording/RecordingContext';
import { RecordingEvent } from '../../../types/RecordingTypes';
import { useNavigation } from '../../../hooks/useNavigation';
import { useApp } from '../../../providers/useApp/AppContext';

const CatchupPlayerPage: FC<{ broadcastId: string }> = ({ broadcastId }) => {
    const { onNoInternet } = useGlobalNoInternet();
    const { onNetworkError } = useGlobalNetworkError();
    const setCanceller = useRequestCanceller();

    const { history } = useNavigation();

    const { response, error, loading, fetcher } = useDataFetcher<CatchupPlayerAssetInfo, FetchPlayingAsset>(
        params => Api.fetchCatchupPlayerInfo(params.id, params.createSession, new axios.CancelToken(executor => setCanceller(executor))),
        onNoInternet,
        onNetworkError
    );

    const sessionCreated = useRef<boolean>(false);

    const { isGuest, openGuestAlert } = useAuth();
    const {
        asset: currentPlayerAsset,
        setAsset,
        addEventListener,
        removeEventListener,
        resetPlayerAsset,
        loaded,
        isMini,
        updateMiniPlayerProps,
    } = usePlayer();
    const { appLanguage } = useApp();
    const trackScreen = useTrackingScreen(() => true);
    const { fullLoad } = useKPI('CatchupPlayer');
    const { addEventListener: addRecordingEventListener, removeEventListener: removeRecordingEventListener } = useRecording();

    const fetchDetails = () => {
        if (!isGuest) {
            fetcher(
                {
                    id: broadcastId,
                    createSession: !sessionCreated.current,
                },
                true
            );
        }
    };

    const setNotRecorded = () => {
        setAsset({
            ...currentPlayerAsset,
            ...{
                isRecorded: false,
            },
        });
    };

    const onStreamEndReached = () => {
        if (isMini) {
            updateMiniPlayerProps(getMiniPlayerProps(response, PlayingAssetType.LIVE));
        } else {
            history.replace(getLivePlayerURL(response.channelId));
        }
    };

    const onJumpToLiveRequested = () => {
        history.replace(getLivePlayerURL(response.channelId));
    };

    usePlayerEntitlementChecker(response, {
        onNotEntitled: () => {
            history.replace(getCatchupPlayOptionDetailURL(response, appLanguage));
        },
    });

    useEffect(() => {
        fullLoad.start();
    }, []);

    useEffect(() => {
        if (loaded) {
            fullLoad.stop();
        }
    }, [loaded]);

    useEffect(() => {
        sessionCreated.current = false;
        resetPlayerAsset();
        fetchDetails();
        trackScreen('CatchupPlayer', { asset_id: broadcastId });
    }, [broadcastId]);

    useEffect(() => {
        if (response) {
            let assetToSet: BroadcastPlayerAsset = response;
            if (!sessionCreated.current) {
                sessionCreated.current = true;
            } else {
                assetToSet = {
                    ...response,
                    ...{
                        manifestUrl: currentPlayerAsset?.manifestUrl,
                    },
                };
            }
            setAsset(assetToSet);
        }

        addEventListener(PlayerEvents.JUMP_TO_LIVE, onJumpToLiveRequested);

        return () => {
            removeEventListener(PlayerEvents.JUMP_TO_LIVE, onJumpToLiveRequested);
        };
    }, [response]);

    useEffect(() => {
        if (response) {
            addEventListener(PlayerEvents.END_OF_STREAM, onStreamEndReached);
        }

        return () => {
            removeEventListener(PlayerEvents.END_OF_STREAM, onStreamEndReached);
        };
    }, [response, isMini]);

    useEffect(() => {
        if (isGuest) {
            openGuestAlert('/');
        }
    }, [broadcastId]);

    useEffect(() => {
        addRecordingEventListener(RecordingEvent.DELETE, setNotRecorded);
        return () => removeRecordingEventListener(RecordingEvent.DELETE, setNotRecorded);
    }, [currentPlayerAsset]);

    if (error) {
        return <PageErrorNotFound />;
    }

    return (
        <>
            {response && <HelmetWrapper {...getPlayerPageHelmetData(response)} />}
            <PlayerWrapper loading={loading} />
        </>
    );
};

export default withNetworkCheck(withPlayer(CatchupPlayerPage));
