export enum WorldSectionType {
    MOVIES = 1,
    BROADCASTS_AND_SERIES = 2,
    ACTORS = 8,
    DIRECTORS = 16,
}

export type MyWorldRootCategory = {
    id: string;
    name: string;
    isAdult: boolean;
};
export type MyWorldCategory = MyWorldRootCategory & {
    titleCount: number;
    childCategories?: MyWorldCategory[];
};
export type MyWorldItem = {
    name: string;
    value: string;
    groupName: string;
    groupType: number;
};
export type Callback = {
    onReady: (childCategory: MyWorldCategory, topCategories: any) => void;
};
