import shaka from 'shaka-player';
import { isChrome, isEdge, isFirefox, isSafari } from 'react-device-detect';
import * as Sentry from '@sentry/react';
import YouboraTracking from './YouboraTracking';
import { IPlayer } from './abstract/IPlayer';
import { BITRATE_TRACK_AUTO, BitrateTrack, PlayerAsset, PlayerConfig, SUBTITLE_NONE, Track } from '../../types/Player';
import DRM from './DRM';
import { NETWORK_ERROR_CODE, PLAYER_ERROR_INTERRUPT } from './playerConstants';
import ApplicationConfig from '../useConfig/ApplicationConfig';
import { compareBitrateTracks, compareTracks, getThumbnailSize } from '../../utils/fnData';

class ShakaPlayer extends IPlayer {
    private storedVolume = 0;

    private engine = null;

    private asset: PlayerAsset = null;

    private availableSubtitles: Track[] = [];

    private availableAudios: Track[] = [];

    private availableBitrateTracks: any = [];

    private eventManager;

    private shouldResetBeforeSet = false;

    private eventManagerEvents = ['buffering', 'trackschanged'];

    private currentAudio = null;

    private currentSubtitle = null;

    private currentBitrate = null;

    private youbora: YouboraTracking = null;

    constructor() {
        super();
        this.TAG = 'Shaka Player';

        this.eventManager = new shaka.util.EventManager();

        this.drmHandler = DRM;
        this.youbora = new YouboraTracking();
        this.init();
    }

    static getPlayerVersion = (): string => {
        return shaka.Player.version;
    };

    private getSubtitleTracks = () => {
        const tracks = this.engine.getTextTracks();

        this.availableSubtitles = [{ role: null, label: null, raw: null, language: SUBTITLE_NONE }];

        this.availableSubtitles = [
            ...this.availableSubtitles,
            ...tracks
                .filter(track => track.language != null && track.language !== '')
                .map(track => {
                    return {
                        role: track?.kind ?? track?.roles?.[0],
                        label: track.label,
                        language: track.language,
                        raw: track,
                    };
                }),
        ];

        return this.availableSubtitles;
    };

    private getVariantTracks = () => {
        let tracks = this.engine.getVariantTracks();

        const selectedTrack = tracks.find(track => track.active);

        if (selectedTrack) {
            // Filter by current audio language and channel count.
            tracks = tracks.filter(
                track => track.language === selectedTrack.language && track.channelsCount === selectedTrack.channelsCount
            );
        }

        // Remove duplicate entries with the same resolution
        tracks = tracks.filter((track, idx) => {
            // Keep the first one with the same height or bandwidth.
            const otherIdx = tracks.findIndex(t => t.height === track.height);
            return otherIdx === idx;
        });

        // Sort the tracks by height or bandwidth depending on content type.
        tracks.sort((t1, t2) => t2.height - t1.height);

        this.availableBitrateTracks = tracks.length > 1 ? [{ height: null, width: null, bandwidth: BITRATE_TRACK_AUTO }] : [];

        this.availableBitrateTracks = [
            ...this.availableBitrateTracks,
            ...tracks
                .filter(track => track.language != null && track.language !== '')
                .map(track => {
                    return {
                        height: track.height,
                        width: track.width,
                        bandwidth: track.bandwidth,
                        raw: track,
                    };
                }),
        ];

        return this.availableBitrateTracks;
    };

    private getImageTrack = () => {
        const imageTracks = this.engine.getImageTracks();

        if (imageTracks) {
            const [availableImages] = imageTracks;
            return availableImages;
        }

        return null;
    };

    private getThumbnailImage = async (id: number, position: number) => {
        const thumbnailImage = await this.engine.getThumbnails(id, position);
        if (thumbnailImage) {
            return getThumbnailSize(thumbnailImage);
        }
        return null;
    };

    private getAudioTracks = () => {
        const tracks = this.engine.getVariantTracks();

        this.availableAudios = tracks
            .filter(track => track.language != null && track?.language !== '')
            .map(track => {
                return {
                    role: track?.kind ?? track?.roles?.[0],
                    label: track.label,
                    language: track.language,
                    channelsCount: track.channelsCount,
                    raw: track,
                };
            });

        return this.availableAudios;
    };

    private getManifestUrlWithSuffix = (manifestUrl: string, suffixDomain: 'tv' | 'vod'): string => {
        if (!manifestUrl) return null;

        const { manifest_suffix } = ApplicationConfig?.app_config?.player_settings ?? {};
        let suffix = '';
        let querySymbol = '';

        if (suffixDomain) {
            if (isChrome) {
                suffix = manifest_suffix?.[suffixDomain]?.chrome ?? '';
            } else if (isFirefox) {
                suffix = manifest_suffix?.[suffixDomain]?.firefox ?? '';
            } else if (isEdge) {
                suffix = manifest_suffix?.[suffixDomain]?.edge ?? '';
            } else if (isSafari) {
                suffix = manifest_suffix?.[suffixDomain]?.safari ?? '';
            }

            if (suffix && suffix.startsWith('?') && manifestUrl.includes('?')) {
                suffix = suffix.substring(1, suffix.length);
                querySymbol = '&';
            }
        }

        return `${manifestUrl}${querySymbol}${suffix}`; // &drm=true
    };

    setVideo = (video?: HTMLVideoElement): ShakaPlayer => {
        this.video = video;
        return this;
    };

    init = () => {
        this.log('info', 'init');

        const { Player } = shaka;

        if (!this.video) {
            return;
        }

        if (!Player.isBrowserSupported()) {
            this.log('error', 'Not supported browser');
        }
    };

    static getChromeCastCustomData() {
        return {};
    }

    addEventListener = (event: string, callback: (object?: {}) => void) => {
        if (this.eventManagerEvents.includes(event)) {
            this.addEventManagerListener(event, callback);
        } else {
            this.video.addEventListener(event, callback);
        }
    };

    removeEventListener = (event: string, callback: (object?: {}) => void) => {
        if (this.eventManagerEvents.includes(event)) {
            this.removeEventManagerListener(event, callback);
        } else {
            this.video.removeEventListener(event, callback);
        }
    };

    addEventManagerListener = (event, cb) => {
        this.eventManager.listen(this.engine, event, cb);
    };

    removeEventManagerListener = (event, cb) => {
        this.eventManager.unlisten(this.engine, event, cb);
    };

    resetPlayer = () => {
        this.asset = null;
        this.shouldResetBeforeSet = false;

        if (this.drmHandler) {
            this.drmHandler.release();
        }

        if (this.engine) {
            this.engine.resetConfiguration();
            this.engine.unload();
        }
    };

    setAsset = async (assetDetails: PlayerAsset, startTime: number = 0, suffixDomain: 'tv' | 'vod', preparedCb: () => void) => {
        if (this.asset && this.asset.manifestUrl === assetDetails.manifestUrl) {
            // already playing the same asset
            return;
        }

        if (this.shouldResetBeforeSet) {
            this.resetPlayer();
        }

        this.shouldResetBeforeSet = true;
        this.asset = assetDetails;

        if (this.engine && this.asset) {
            const manifestUrl = this.getManifestUrlWithSuffix(this.asset.manifestUrl, suffixDomain);

            if (manifestUrl) {
                try {
                    if (this.drmHandler) {
                        await this.drmHandler.configurePlayer(this.engine);
                        this.drmHandler.subscribeErrorHandler(e => {
                            if (this.handler.errorHandler && typeof this.handler.errorHandler === 'function') {
                                // this.engine.unload();

                                this.handler.errorHandler({
                                    code: e?.code,
                                    category: e?.category,
                                    message: e?.message,
                                    details: e?.details,
                                });
                            }
                        });
                    }

                    await this.engine.load(manifestUrl, startTime);
                    this.log('log', 'Manifest is ok', manifestUrl);
                    preparedCb();

                    if (isSafari && startTime) {
                        // this is needed for Safari because load() startTime does not work properly
                        const bufferingCallback = event => {
                            if (event.buffering === false && startTime !== 0) {
                                this.video.currentTime = startTime;

                                this.removeEventListener('buffering', bufferingCallback);
                            }
                        };

                        this.addEventListener('buffering', bufferingCallback);
                    }

                    this.getAudioTracks();
                    this.getSubtitleTracks();
                } catch (e) {
                    this.log('warn', 'Manifest error', manifestUrl, e);

                    if (e.code && e.code !== PLAYER_ERROR_INTERRUPT) {
                        if (this.handler.errorHandler && typeof this.handler.errorHandler === 'function') {
                            this.engine.unload();

                            this.handler.errorHandler({
                                code: e?.code,
                                category: e?.category,
                                message: e?.message,
                            });
                        }

                        Sentry.captureException(e);
                    }
                }
            }
        }
    };

    preparePlayer = (config: PlayerConfig) => {
        super.preparePlayer(config);

        const { errorHandler, onInitialized, onNetworkError } = config;

        this.engine = new shaka.Player(this.video);

        if (this.youbora) {
            this.youbora.setShakaAdapter(this.engine);
        }

        this.engine.addEventListener('error', event => {
            const code = event?.detail?.code || event?.code;
            const category = event?.detail?.category || event?.category;

            if (code === NETWORK_ERROR_CODE && !window.navigator.onLine && onNetworkError) {
                onNetworkError();
            } else if (errorHandler) {
                errorHandler({
                    code,
                    category,
                    message: null,
                });
            }
        });

        if (onInitialized && typeof onInitialized === 'function') {
            onInitialized(this);
        }
    };

    play = () => {
        if (this.video) {
            this.video.play().catch(() => {
                // NO-OP
            });
        }
    };

    pause = () => {
        if (this.video) {
            this.video.pause();
        }
    };

    isPlaying = () => {
        if (this.video) {
            return !this.video.paused;
        }
        return false;
    };

    seekTo = value => {
        if (this.video) {
            this.video.currentTime = value;
        }
    };

    getDuration = () => {
        if (this.video) {
            return this.video.duration || 0;
        }
        return 0;
    };

    getCurrentTime = () => {
        if (this.video) {
            return this.video.currentTime;
        }
        return 0;
    };

    getVolume = () => {
        if (this.video) {
            return this.video.volume;
        }
        return 0;
    };

    setVolume = (value: number) => {
        if (this.video && value >= 0) {
            this.video.volume = value;
        }
    };

    mute = (status = false, userAction = false) => {
        if (status) {
            this.storedVolume = this.getVolume();
            this.setVolume(0);
        } else if (userAction) {
            this.setVolume(this.storedVolume);
        }
    };

    isMuted = () => {
        if (this.video) {
            return this.video.muted;
        }
        return false;
    };

    getBuffered = () => {
        if (this.video) {
            return this.video.buffered || [];
        }
        return [];
    };

    getAudioLanguages = (): any => {
        return this.getAudioTracks();
    };

    getTextLanguages = (): any => {
        return this.getSubtitleTracks();
    };

    getBitrateTracks = (): any => {
        return this.getVariantTracks();
    };

    getThumbnailTrack = (): any => {
        return this.getImageTrack();
    };

    getThumbnails = (id: number, pos: number): any => {
        return this.getThumbnailImage(id, pos);
    };

    setAudioLanguage = (track: Track): void => {
        this.currentAudio = track;

        const audioTrack = this.availableAudios.find(audio => compareTracks(audio, track));

        if (audioTrack) {
            this.engine.selectVariantTrack(audioTrack.raw, true);
        }
    };

    setTextLanguage = (track: Track): void => {
        this.currentSubtitle = !track || track.language === SUBTITLE_NONE ? null : track;

        if (!track || track.language === SUBTITLE_NONE) {
            this.engine.setTextTrackVisibility(false);
            return;
        }

        const subtitleTrack = this.availableSubtitles.find(subtitle => compareTracks(subtitle, track));

        if (subtitleTrack) {
            this.engine.selectTextTrack(subtitleTrack.raw);
            this.engine.setTextTrackVisibility(true);
        }
    };

    setBitrateTrack = (track: BitrateTrack): void => {
        this.currentBitrate = track;

        const bitrateTrack = this.availableBitrateTracks.find(video => compareBitrateTracks(video, track));

        if (bitrateTrack) {
            this.engine.selectVariantTrack(bitrateTrack.raw, true);
        }
    };

    getSelectedLanguage = () => this.currentAudio;

    getSelectedSubtitle = () => this.currentSubtitle;

    getSelectedBitrateTrack = () => this.currentBitrate;

    configureEngine = (config: any) => {
        this.engine.configure(config);
    };

    getYoubora = () => {
        return this.youbora;
    };
}

export default ShakaPlayer;
