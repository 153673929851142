const localConfig = {
    title: 'Elisa Elamus',
    data: {
        rentCollectionCacheKey: 'PURCHASED_ITEMS',
        dynamicKeyPrefix: 'DYNAMIC_',
        recordingKeyPrefix: 'RECORDING_',
        searchCachePrefix: 'SEARCH_',
        networkError: 'Network Error',
        healthCheckURL: 'https://acors.starman.ee/traxis/web/',
    },
    ignoreFooterPaths: ['/Epg', '/Epg-a', '/live-tv'],
    pin: {
        LOCK_PIN_STORE_KEY: 'pinLock',
        WRONG_PIN_ATTEMPTS: 'wrongPinAttempts',
    },
    drm: {
        DEVICE_ID_STORAGE: 'cxDeviceId',
        WIDEVINE: 'Widevine',
        PLAYREADY: 'PlayReady',
        FAIRPLAY: 'FairPlay',
        DEVICE_TYPE: 'Browser',
        KEY_SYSTEM_WIDEVINE: 'com.widevine.alpha',
        KEY_SYSTEM_PLAYREADY: 'com.microsoft.playready',
        KEY_FAIRPLAY: 'com.apple.fps.1_0',
        CUSTOM_HEADER_KEY: 'Conax-Custom-Data',
    },
};

export default localConfig;
