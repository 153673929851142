import { AudioMixType, Episode, Movie, MovieDetails, ResolutionType } from './Asset';

export enum VODAssetEntitlementState {
    ENTITLED = 'entitled',
    SHOULD_RENT = 'should_rent',
    SHOULD_SUBSCRIBE = 'should_subscribe',
    SHOULD_BUY = 'should_buy',
}

export enum TVAssetEntitlementState {
    ENTITLED = 'entitled',
    SHOULD_BUY_BASIC_REPLAY_WINDOW = 'should_buy_basic_replay_window', // 2 -> TimeMachine
    SHOULD_BUY_EXTENDED_REPLAY_WINDOW = 'should_buy_extended_replay_window', // 3 -> TimeMachinePlus
    SHOULD_SUBSCRIBE_CHANNEL = 'should_subscribe_channel', // 1
}

export enum PurchasedProductType {
    TIME_MACHINE = 'TimeMachine',
    TIME_MACHINE_PLUS = 'TimeMachinePlus',
    SUBSCRIPTION = 'Subscription',
}

export enum EntitlementState {
    ENTITLED = 'Entitled',
    NOT_ENTITLED = 'NotEntitled',
}

export enum ProductType {
    TRANSACTION = 'Transaction',
    FREE = 'Free',
    SUBSCRIPTION = 'Subscription',
}

export type PurchasedProduct = {
    type: PurchasedProductType | string;
    state: string;
};

export type ContentProduct = {
    productId: string;
    productName: string;
    productCode: string;
    currency?: string;
    listPrice?: number;
    rentalPeriodInMinutes?: number;
    description?: string;
    audios?: string[];
    subtitles?: string[];
    audioMixType?: AudioMixType;
    availabilityEnd?: number;
    isBuyOffer: boolean;
};

export type Offer = ContentProduct & {
    offerId: string;
    offerValue: number;
    rentalPeriod: string;
    resolution: ResolutionType;
};

export type RentData = {
    productId: string;
    offerId: string;
};

export type SubscribeType = 'Product' | 'Channel';

export type SubscribeData = {
    code: string;
    type: SubscribeType;
    assetId?: string;
    metaData?: Episode | Movie | MovieDetails;
};

export type SubscribeOption = {
    name: string;
    tags: string[];
    price: number;
    productId: number;
    poster: string;
    description: string;
    assetId?: string;
    packageId?: string;
};

export type OrderSubscription = {
    cartId: number;
};
