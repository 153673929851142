import { parse } from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { WrapLocation } from '../../types/HistoryTypes';

export const useWrapLocation = (): WrapLocation => {
    const location = useLocation();

    return useMemo(
        () => ({
            ...location,
            query: parse(location?.search),
        }),
        [location?.search, location?.pathname]
    );
};
