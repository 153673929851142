import React, { useState } from 'react';
import { getLocalStorage, setLocalStorage } from '../../utils/fnStorage';
import { CookieNotificationButton, CookieNotificationContainer, CookieNotificationMessage } from './CookieNotification.css';
import translate from '../../utils/fnTranslate';

export const CookieNotification: React.FC = () => {
    const userAcceptedCookies = getLocalStorage('userAcceptedCookies');
    const [userAcceptedCookiesState, setUserAcceptedCookies] = useState(userAcceptedCookies);
    const isVisible = !userAcceptedCookiesState && !userAcceptedCookies;

    try {
        const userSnap = document.querySelectorAll('iframe[name=us-entrypoint-button]');
        if (userSnap && userSnap.length) {
            const userSnapElement = userSnap[0] as HTMLElement;
            userSnapElement.style.opacity = isVisible ? '0' : '1';
            userSnapElement.style.setProperty('height', isVisible ? '0' : '35px', isVisible ? 'important' : '');
            userSnapElement.style.zIndex = '2147483640';
        }
    } catch (e) {
        console.warn(e);
    }

    const onClose = () => {
        setUserAcceptedCookies(true);
        setLocalStorage('userAcceptedCookies', true);
    };

    return isVisible ? (
        <CookieNotificationContainer data-test-id="CookieNotification">
            <CookieNotificationMessage>
                <span
                    dangerouslySetInnerHTML={{
                        __html: translate('COOKIE_NOTIFICATION_BODY'),
                    }}
                />
            </CookieNotificationMessage>

            <CookieNotificationButton
                label={translate('COOKIE_NOTIFICATION_BUTTON')}
                onClick={onClose}
                isPrimary={true}
                isDisabled={false}
                dataTestId={'CookieNotification-OK-Button'}
                data-event-name={'click'}
                data-track-id={'button_cookie_notification_ok'}
                data-button-title={translate('COOKIE_NOTIFICATION_BUTTON')}
            />
        </CookieNotificationContainer>
    ) : null;
};
