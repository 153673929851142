import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import {
    HintBox,
    HintButton,
    HintButtonsContainer,
    HintContainer,
    HintOverlayBottom,
    HintOverlayLeft,
    HintOverlayRight,
    HintOverlayTop,
    HintText,
    HintWrapper,
    Pointer,
} from './HintModal.css';
import { useResizeWatcher } from '../../hooks/useResizeWatcher/useResizeWatcher';

type HintModalPropTypes = {
    modalText: string;
    attachTo: HTMLElement;
    position?: 'top left' | 'top right' | 'bottom left' | 'bottom right';
    visible: boolean;
    closeHint: () => void;
    buttons?: Array<{
        text: string;
        onClick: () => void;
    }>;
    verticalOffset?: { desktop: number; tablet: number; mobile: number };
    horizontalOffset?: { desktop: number; tablet: number; mobile: number };
};

type CoordinatesPropTypes = {
    top: number;
    left: number;
    width: number;
    height: number;
};

const calculateCoords = (attachTo: HTMLElement) => {
    if (!attachTo) {
        return { top: 0, left: 0, width: 0, height: 0 };
    }
    const { height, width, top, left } = attachTo.getBoundingClientRect();

    return {
        top,
        left,
        width,
        height,
    };
};

export const HintModalComponent: FC<HintModalPropTypes> = ({
    modalText,
    attachTo,
    position = 'top left',
    verticalOffset = { desktop: 0, tablet: 0, mobile: 0 },
    horizontalOffset = { desktop: 0, tablet: 0, mobile: 0 },
    buttons,
    visible,
    closeHint,
}) => {
    const [coords, setCoords] = useState<CoordinatesPropTypes>({
        top: 0,
        left: 0,
        width: 0,
        height: 0,
    });

    const resizeHandler = useCallback(() => {
        setCoords(calculateCoords(attachTo));
    }, [setCoords]);

    useResizeWatcher(null, resizeHandler);

    const { top, left, width, height } = coords;

    useEffect(() => {
        setCoords(calculateCoords(attachTo));

        const onScroll = () => {
            setCoords(calculateCoords(attachTo));
        };

        document.addEventListener('scroll', onScroll);

        return () => {
            document.removeEventListener('scroll', onScroll);
        };
    }, [attachTo]);

    const hintButtons: ReactNode[] = buttons
        ? buttons.map((button, index) => (
              <HintButton
                  key={`hint-button-${index}`}
                  onClick={button.onClick}
                  isPrimary={index === 1}
                  label={button.text}
                  link={null}
                  data-event-name={'click'}
                  data-track-id={'button_hint'}
                  data-button-title={button.text}
              />
          ))
        : null;

    return createPortal(
        <HintWrapper visible={visible} onClick={closeHint}>
            <HintContainer top={top} left={left} width={width} height={height}>
                <HintOverlayTop />
                <HintOverlayBottom />
                <HintOverlayLeft />
                <HintOverlayRight />
                <HintBox position={position.split(' ')} verticalOffset={verticalOffset} horizontalOffset={horizontalOffset}>
                    <HintText>{modalText}</HintText>
                    {hintButtons?.length > 0 && <HintButtonsContainer>{hintButtons}</HintButtonsContainer>}
                    <Pointer position={position.split(' ')} />
                </HintBox>
            </HintContainer>
        </HintWrapper>,
        document.getElementById('hint-root')
    );
};
