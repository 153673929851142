import React, { FC } from 'react';
import { CardWrapper } from '../../Lane/Lane.css';
import { landscapeCardHeight, landscapeCardWidth } from './CardLandscape.css';
import { LoadingRectangle, TitlePlaceholder } from '../../../style/styled-components/reusable.css';

export const CardLandscapeLoading: FC<{ withTitle?: boolean }> = ({ withTitle = false }) => {
    return (
        <CardWrapper width={landscapeCardWidth} height={landscapeCardHeight}>
            <LoadingRectangle />
            {withTitle && (
                <TitlePlaceholder>
                    <LoadingRectangle />
                </TitlePlaceholder>
            )}
        </CardWrapper>
    );
};
