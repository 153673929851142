import React from 'react';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import video from '../../assets/images/preloader-animation.webm';

export const SplashScreenWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: #000;

    video {
        display: block;
        width: 100%;
        height: 100%;
    }
`;

export const SplashScreen: React.FC = () => {
    return (
        <SplashScreenWrapper>
            <video loop autoPlay muted playsInline>
                <source src={video} type="video/webm" />
            </video>
        </SplashScreenWrapper>
    );
};
