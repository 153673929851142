import { NR_OF_RETRIES, SubscriptionValidatorInfo, SubscriptionPackageValidator } from './SubscriptionPackageValidator';
import { SubscribeData } from '../../types/Entitlement';
import { getLocalStorage, setLocalStorage } from '../../utils/fnStorage';

export class SubscriptionValidator {
    private readonly callback: (data: SubscriptionValidatorInfo) => void = null;

    private validators: { [key: string]: SubscriptionPackageValidator } = {};

    private onValidationEnded = (active: boolean, data: SubscriptionValidatorInfo) => {
        if (this.callback && active) {
            this.callback(data);
        }

        delete this.validators[data.packageId];
        setLocalStorage('subscriptionValidator', JSON.stringify(this.validators));
    };

    private restoreValidators = () => {
        const storedValue = getLocalStorage('subscriptionValidator');
        if (storedValue) {
            const parsed: { [key: string]: SubscriptionPackageValidator } = JSON.parse(storedValue);

            // eslint-disable-next-line guard-for-in,no-restricted-syntax
            for (const validatorKey in parsed) {
                const validator = parsed[validatorKey];
                const { scheduleMaxTime, nrOfTries, packageId } = validator?.validatorData ?? {};

                if (scheduleMaxTime > Date.now() && nrOfTries < NR_OF_RETRIES) {
                    this.validators[packageId] = new SubscriptionPackageValidator(validator.validatorData, this.onValidationEnded);
                }
            }
        }
    };

    constructor(callback?: (data: SubscriptionValidatorInfo) => void) {
        if (callback) {
            this.callback = callback;
        }

        this.restoreValidators();
    }

    public addValidator = (subscriptionData: SubscribeData) => {
        if (this.validators[subscriptionData.code]) {
            return;
        }

        this.validators[subscriptionData.code] = new SubscriptionPackageValidator(
            {
                packageId: subscriptionData.code,
                type: subscriptionData.type,
                personalizedAssetId: subscriptionData?.assetId ?? subscriptionData?.metaData?.id,
                scheduleMaxTime: 0,
                nrOfTries: 0,
            },
            this.onValidationEnded
        );

        setLocalStorage('subscriptionValidator', JSON.stringify(this.validators));
    };
}
