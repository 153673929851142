import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import SVGInline from 'react-svg-inline';
import { DropdownItemSimple, DropdownMenu } from '../DropdownMenu/DropdownMenu';
import { usePlayer } from '../../providers/player/PlayerContext';
import {
    BlockTitle,
    CloseButton,
    OptionContainer,
    StyledSVGInline,
    TrackBlock,
    TrackSelectorContentWrapper,
    TrackSelectorMobileWrapper,
    TrackSelectorWrapper,
} from './PlayerTrackSelector.css';
import translate from '../../utils/fnTranslate';
import { useConfig } from '../../providers/useConfig/ConfigContext';
import { BITRATE_TRACK_AUTO, BitrateTrack, PlayerControl, SUBTITLE_NONE, Track, SUBTITLE_UNDEFINED } from '../../types/Player';
import { ControlGroup } from '../Player/PlayerWrapper';
import icons from '../../style';
import { useElementInteractionTracking } from '../../providers/useTracking/TrackingContext';
import { compareBitrateTracks, compareTracks, getLanguage, selectDefaultAudio, selectDefaultSubtitles } from '../../utils/fnData';

const MAX_FLYOUT_HEIGHT = 348;

const HOH_ROLES = ['caption', 'captions'];
const VISUALLY_IMPAIRED_ROLES = ['description', 'commentary', 'dub'];

enum ResolutionType {
    'SD' = 'SD',
    'HD' = 'HD',
    '4K' = '4K',
}

enum SettingGroups {
    NONE,
    AUDIO,
    SUBTITLE,
    RESOLUTION,
}

const useTrackSelector = () => {
    const { config } = useConfig();
    const interactionTracking = useElementInteractionTracking();
    const {
        getTextLanguages,
        getAudioLanguages,
        getBitrateTracks,
        setTextLanguage,
        setAudioLanguage,
        setBitrateStream,
        subtitleTrack,
        audioTrack,
        bitrateTrack,
        primaryAudioLanguage,
        secondaryAudioLanguage,
        primarySubtitlesLanguage,
        secondarySubtitlesLanguage,
        accessibility,
        asset,
    } = usePlayer();

    const supportedLanguages = Object.keys(config.app_config.languages);

    const audioLanguages = getAudioLanguages();
    const subtitles = getTextLanguages();
    const bitrateTracks = getBitrateTracks();

    const audioKeys = Object.keys(audioLanguages);
    const subtitleKeys = Object.keys(subtitles);

    const subtitlesRef = useRef<Track[]>();
    const audioLanguagesRef = useRef<Track[]>();

    const getAudioAndSubtitleLabel = (track: Track, type: 'audio' | 'text'): string => {
        const parts = [];

        if (track.language === SUBTITLE_NONE) {
            parts.push(subtitles.length > 1 ? translate('SCREEN_AUDIO_SUBTITLES_SUBTITLES_OFF') : translate('SUBTITLES_NONE'));
        } else if (track.language === SUBTITLE_UNDEFINED) {
            parts.push(translate('SETTINGS_LANGUAGE_UND'));
        } else {
            parts.push(
                supportedLanguages.includes(track.language)
                    ? getLanguage(track.language, translate(`LANGUAGE_${track.language.toUpperCase()}`))
                    : `${translate('SCREEN_AUDIO_OTHER_LANGUAGE')}(${track.language})`
            );
        }

        if (type === 'audio' && track.role != null && VISUALLY_IMPAIRED_ROLES.includes(track.role)) {
            parts.push(translate('SCREEN_AUDIO_ACCESSIBILITY'));
        }

        if (type === 'text' && track.role != null && HOH_ROLES.includes(track.role)) {
            parts.push(translate('SCREEN_HARD_OF_HEARING'));
        }

        if (type === 'audio' && track.channelsCount === 2) {
            parts.push(translate('SCREEN_AUDIO_DOLBY_DIGITAL'));
        }

        if (type === 'audio' && track.channelsCount === 6) {
            parts.push(translate('SCREEN_AUDIO_DOLBY_DIGITAL_PLUS'));
        }

        return parts.join(' - ');
    };

    const getBitrateTrackLabel = (track: BitrateTrack): string => {
        const parts = [];

        if (track.height != null) {
            parts.push(`${track.height}p`);
        }

        if (track.height) {
            if (track.height > 1080) {
                parts.push(ResolutionType['4K']);
            }
            if (track.height >= 720 && track.height <= 1080) {
                parts.push(ResolutionType.HD);
            }
            if (track.height < 720) {
                parts.push(ResolutionType.SD);
            }
        }

        if (track.bandwidth === BITRATE_TRACK_AUTO) {
            parts.push(translate('SCREEN_PLAYER_VIDEO_QUALITY_AUTO'));
        } else {
            parts.push(`@ ${Math.round(track.bandwidth / 1000000)}.0 Mbps`);
        }

        return parts.join(' ');
    };

    const setSubtitle = textLanguage => {
        interactionTracking({
            'data-track-id': 'player_subtitle_selector',
            'data-button-title': textLanguage,
        });

        setTextLanguage(textLanguage);
    };

    const setAudio = audioLanguage => {
        interactionTracking({
            'data-track-id': 'player_audio_selector',
            'data-button-title': audioLanguage,
        });

        setAudioLanguage(audioLanguage);
    };

    useEffect(() => {
        if (subtitles && subtitles?.length !== subtitlesRef.current?.length) {
            subtitlesRef.current = subtitles;
            const defaultSubtitleTrack = selectDefaultSubtitles(
                {
                    primarySubtitlesLanguage,
                    secondarySubtitlesLanguage,
                },
                subtitles,
                accessibility,
                HOH_ROLES
            );

            setSubtitle(defaultSubtitleTrack);
        }
    }, [subtitles]);

    useEffect(() => {
        if (audioLanguages && audioLanguages?.length !== audioLanguagesRef.current?.length) {
            audioLanguagesRef.current = audioLanguages;
            const defaultAudioLanguage = selectDefaultAudio(
                {
                    primaryAudioLanguage,
                    secondaryAudioLanguage,
                },
                audioLanguages,
                accessibility,
                VISUALLY_IMPAIRED_ROLES
            );

            setAudio(defaultAudioLanguage);
        }
    }, [audioLanguages]);

    useEffect(() => {
        audioLanguagesRef.current = null;
        subtitlesRef.current = null;
    }, [asset]);

    return {
        subtitleKeys,
        audioKeys,
        bitrateTracks,

        audioLanguages,
        subtitles,

        supportedLanguages,
        getAudioAndSubtitleLabel,
        getBitrateTrackLabel,
        setTextLanguage: setSubtitle,
        setAudioLanguage: setAudio,
        setBitrateTrack: bitrateTrack => {
            interactionTracking({
                'data-track-id': 'player_bitrate_track_selector',
                'data-button-title': bitrateTrack,
            });

            setBitrateStream(bitrateTrack);
        },
        selectedSubtitle: subtitleTrack,
        selectedAudio: audioTrack,
        selectedBitrateTrack: bitrateTrack,
    };
};

export const PlayerTrackSelector: FC<{ triggerButton: ReactNode }> = ({ triggerButton }) => {
    const [selectedGroup, setSelectedGroup] = useState<SettingGroups>(SettingGroups.NONE);
    const { setActiveControlGroup } = usePlayer();
    const {
        audioLanguages,
        subtitles,
        bitrateTracks,

        selectedAudio,
        setAudioLanguage,

        selectedSubtitle,
        setTextLanguage,

        selectedBitrateTrack,
        setBitrateTrack,

        getAudioAndSubtitleLabel,
        getBitrateTrackLabel,
    } = useTrackSelector();

    const audioNodes: ReactNode[] =
        audioLanguages.length > 0
            ? audioLanguages.map((audioTrack, index) => {
                  return (
                      <DropdownItemSimple
                          key={index}
                          selected={compareTracks(audioTrack, selectedAudio)}
                          onClick={() => setAudioLanguage(audioTrack)}
                      >
                          {getAudioAndSubtitleLabel(audioTrack, 'audio')}
                      </DropdownItemSimple>
                  );
              })
            : [];

    const subtitleNodes: ReactNode[] =
        subtitles.length > 0
            ? subtitles.map((subtitleTrack, index) => {
                  return (
                      <DropdownItemSimple
                          key={index}
                          selected={compareTracks(selectedSubtitle, subtitleTrack)}
                          onClick={() => setTextLanguage(subtitleTrack)}
                      >
                          {getAudioAndSubtitleLabel(subtitleTrack, 'text')}
                      </DropdownItemSimple>
                  );
              })
            : [];

    const bitrateTrackNodes: ReactNode[] =
        bitrateTracks.length > 0
            ? bitrateTracks.map((bitrateTrack, index) => {
                  return (
                      <DropdownItemSimple
                          key={index}
                          selected={compareBitrateTracks(selectedBitrateTrack, bitrateTrack)}
                          onClick={() => setBitrateTrack(bitrateTrack)}
                      >
                          {getBitrateTrackLabel(bitrateTrack)}
                      </DropdownItemSimple>
                  );
              })
            : [];

    const mainOptions: ReactNode[] = [];

    const mainOptionsMap = {
        [SettingGroups.AUDIO]: 'SCREEN_AUDIO_SUBTITLES_AUDIO_LANGUAGE',
        [SettingGroups.SUBTITLE]: 'SCREEN_AUDIO_SUBTITLES_SUBTITLES',
        [SettingGroups.RESOLUTION]: 'SCREEN_PLAYER_VIDEO_QUALITY',
    };

    const optionsMap = {
        [SettingGroups.NONE]: mainOptions,
        [SettingGroups.AUDIO]: audioNodes,
        [SettingGroups.SUBTITLE]: subtitleNodes,
        [SettingGroups.RESOLUTION]: bitrateTrackNodes,
    };

    Object.keys(mainOptionsMap).forEach((key, index) => {
        mainOptions.push(
            <DropdownItemSimple
                key={index}
                onClick={e => {
                    e.stopPropagation();
                    setSelectedGroup(parseInt(key, 10) as SettingGroups);
                }}
            >
                {translate(mainOptionsMap[key])}
            </DropdownItemSimple>
        );
    });

    const visibilityChange = (isVisible: boolean) => {
        if (isVisible) {
            setActiveControlGroup(ControlGroup.AUDIO_SUBTITLE);
        } else {
            setActiveControlGroup(ControlGroup.BASE);
            setTimeout(() => setSelectedGroup(SettingGroups.NONE), 200);
        }
    };

    return (
        <TrackSelectorWrapper>
            <DropdownMenu
                triggerButton={triggerButton}
                horizontalPosition={'left'}
                verticalPosition={'top'}
                maxHeight={MAX_FLYOUT_HEIGHT}
                stripMobilePortal={true}
                visibilityChangeCallback={visibilityChange}
            >
                <TrackSelectorContentWrapper>
                    <TrackBlock>
                        {selectedGroup !== SettingGroups.NONE && (
                            <BlockTitle
                                onClick={e => {
                                    e.stopPropagation();
                                    setSelectedGroup(SettingGroups.NONE);
                                }}
                            >
                                <SVGInline svg={icons.leftArrowIcon} />
                                {translate(mainOptionsMap[selectedGroup])}
                            </BlockTitle>
                        )}
                        <OptionContainer>{optionsMap[selectedGroup]}</OptionContainer>
                    </TrackBlock>
                </TrackSelectorContentWrapper>
            </DropdownMenu>
        </TrackSelectorWrapper>
    );
};

export const PlayerTrackSelectorMobile: FC = () => {
    const { activeControlGroup, setActiveControlGroup } = usePlayer();
    const {
        audioLanguages,
        subtitles,
        bitrateTracks,

        selectedAudio,
        setAudioLanguage,

        selectedSubtitle,
        setTextLanguage,

        selectedBitrateTrack,
        setBitrateTrack,

        getAudioAndSubtitleLabel,
        getBitrateTrackLabel,
    } = useTrackSelector();

    if (activeControlGroup !== ControlGroup.AUDIO_SUBTITLE) {
        return null;
    }

    const setSelectedAudio = audioKey => {
        setAudioLanguage(audioKey);
        setActiveControlGroup(ControlGroup.BASE);
    };

    const setSelectedSubtitle = subtitleKey => {
        setTextLanguage(subtitleKey);
        setActiveControlGroup(ControlGroup.BASE);
    };

    const closeOverlay = () => {
        setActiveControlGroup(ControlGroup.BASE);
    };

    const audioNodes: ReactNode[] =
        audioLanguages.length > 0
            ? audioLanguages.map((audioTrack, index) => {
                  return (
                      <DropdownItemSimple
                          key={index}
                          selected={compareTracks(audioTrack, selectedAudio)}
                          onClick={() => setSelectedAudio(audioTrack)}
                      >
                          {getAudioAndSubtitleLabel(audioTrack, 'audio')}
                      </DropdownItemSimple>
                  );
              })
            : [];

    const subtitleNodes: ReactNode[] =
        subtitles.length > 0
            ? subtitles.map((subtitleTrack, index) => {
                  return (
                      <DropdownItemSimple
                          key={index}
                          selected={compareTracks(selectedSubtitle, subtitleTrack)}
                          onClick={() => setSelectedSubtitle(subtitleTrack)}
                      >
                          {getAudioAndSubtitleLabel(subtitleTrack, 'text')}
                      </DropdownItemSimple>
                  );
              })
            : [];

    const bitrateTrackNodes: ReactNode[] =
        bitrateTracks.length > 0
            ? bitrateTracks.map((bitrateTrack, index) => {
                  return (
                      <DropdownItemSimple
                          key={index}
                          selected={compareBitrateTracks(selectedBitrateTrack, bitrateTrack)}
                          onClick={() => setBitrateTrack(bitrateTrack)}
                      >
                          {getBitrateTrackLabel(bitrateTrack)}
                      </DropdownItemSimple>
                  );
              })
            : [];

    return (
        <TrackSelectorMobileWrapper>
            <CloseButton key={PlayerControl.CLOSE}>
                <StyledSVGInline svg={icons.closeIcon} onClick={closeOverlay} />
            </CloseButton>
            <TrackSelectorContentWrapper>
                {audioNodes.length > 0 && (
                    <TrackBlock>
                        <BlockTitle>{translate('SCREEN_AUDIO_SUBTITLES_AUDIO_LANGUAGE')}</BlockTitle>
                        <OptionContainer>{audioNodes}</OptionContainer>
                    </TrackBlock>
                )}
                {subtitleNodes.length > 0 && (
                    <TrackBlock>
                        <BlockTitle>{translate('SCREEN_AUDIO_SUBTITLES_SUBTITLES')}</BlockTitle>
                        <OptionContainer>{subtitleNodes}</OptionContainer>
                    </TrackBlock>
                )}
                {bitrateTrackNodes.length > 0 && (
                    <TrackBlock>
                        <BlockTitle>{translate('SCREEN_PLAYER_VIDEO_QUALITY')}</BlockTitle>
                        <OptionContainer>{bitrateTrackNodes}</OptionContainer>
                    </TrackBlock>
                )}
            </TrackSelectorContentWrapper>
        </TrackSelectorMobileWrapper>
    );
};
