/* eslint-disable */

import get from 'lodash/get';
import { getParentRoute } from 'utils/fnData';
import { RouteChildrenProps } from 'react-router';
import { PageConfigType } from '../../types/Page';
import translate from '../../utils/fnTranslate';
import { API_DEFAULT_CONFIG, APP_CONFIG_DEFAULT, FEATURE_CONTROLS_DEFAULT, LAYOUT_CONFIG_DEFAULT } from './DefaultConfigs';
import {
    ApiConfig,
    AppConfig,
    Config,
    Configs,
    ContentConfig,
    ContentMarker,
    FeatureControl,
    LayoutConfig,
    PageConfigs,
} from '../../types/Config';
import { MILLISECONDS, MINUTES, SECONDS } from '../../utils/TimeUnit';
import { getEditorialModulePageId, stripeHasEditorialViewAll } from '../../utils/fnEditorial';

class ApplicationConfig {
    api_config: ApiConfig = null;
    app_config: AppConfig = null;
    layout_config: LayoutConfig = null;
    content_config: ContentConfig = null;
    private feature_control: FeatureControl = null;

    // Combine page store (static from local config + dynamic from ML)
    _page_ids = {};

    // Contains the config for each page (so we don't have to cycle trough the config each time we need a page
    _page_configs = {};

    _editorial_view_all_configs = {};

    /**
     * Creates a page.id => path mapping
     * @param page
     * @private
     */
    private createPageId = (page: Configs) => {
        if (!page || !page.id) {
            return;
        }
        // let id = page.id;
        let id = page.id,
            merged: PageConfigs,
            pageIds = Object.keys(this._page_configs),
            filter: string[];

        if (id.toLowerCase() === 'home') {
            this._page_ids[id] = '/';
        } else {
            //@SEO improvement
            this._page_ids[id] =
                '/' +
                translate(id)
                    .replace(/ +/g, '-')
                    .toLowerCase();
        }

        filter = pageIds.filter(item => item.toLowerCase() === id.toLowerCase());

        if (filter.length) {
            merged = this._page_configs[filter[0]];
            page = { ...merged, ...page, ...{ title: merged.title } };
            delete this._page_configs[filter[0]];
            // id = filter[0];
        }

        page.path = this._page_ids[id];

        this._page_configs[id] = { ...this._page_configs[id], ...page };
    };

    private createFromEditorialCollectionConfiguration = () => {
        let contentConfigs = this.content_config.configs || [];

        contentConfigs.forEach(contentConfig => {
            if (contentConfig.modules) {
                contentConfig.modules.forEach(module => {
                    if (stripeHasEditorialViewAll(module)) {
                        const editorialItem = module.items[0];

                        this._editorial_view_all_configs[getEditorialModulePageId(editorialItem.action)] = {
                            ...editorialItem.action.module,
                            ...{
                                title: module.title,
                            },
                        };
                    }
                });
            }
        });
    };

    private insertLayoutConfigurations = (layout_options: LayoutConfig): void => {
        if (!this._page_configs) {
            return null;
        }

        for (let key in this._page_configs) {
            this._page_configs[key]['layout_config'] = { ...layout_options, ...this._page_configs[key].layout_config };
        }
    };

    /**
     * Creates a single page store to be used together with static/dynamic Pages
     * (all Pages should have a unique id so the Pages can be identified and generated properly)
     *
     */
    public createPagesConfiguration = (page_configs: Configs[]): void => {
        let page: Configs;

        for (let i = 0; i < page_configs.length; i++) {
            page = page_configs[i];

            if (page === null || page.id === null) {
                continue;
            }

            this.createHTMLModuleConfig(page);
            this.createPageId(page);
        }
    };

    private createHTMLModuleConfig = (page: Configs): void => {
        if (page && page.modules && page.modules.length) {
            page.modules.forEach(module => {
                if (module.type === 'html' && module.route && module.module && module.module.toLowerCase() === 'stripe') {
                    module.id = module.route;
                    this.createPageId(page);
                }
            });
        }
    };

    private getConfigValue = (input, path, fallback, transformer: (value) => any = value => value) => {
        const value = path ? get(input, path) : input;

        if (value != null) {
            return transformer(value);
        }

        console.warn(`Missing config path=${path} >>> Fallback to ${fallback}`);

        return fallback;
    };

    /**
     * Parse app configs coming from the CC, put the default values in the missing places
     *
     * @param input
     */
    private parseAppConfigs = (input: AppConfig): AppConfig => {
        return {
            general: {
                title: null,
                language: null,
            },
            player_settings: {
                skip_forward_interval: this.getConfigValue(
                    input,
                    'player_settings.skip_forward_interval',
                    APP_CONFIG_DEFAULT.player_settings.skip_forward_interval,
                    val => MILLISECONDS.toSeconds(val)
                ),
                skip_back_interval: this.getConfigValue(
                    input,
                    'player_settings.skip_back_interval',
                    APP_CONFIG_DEFAULT.player_settings.skip_back_interval,
                    val => MILLISECONDS.toSeconds(val)
                ),
                hide_controls_after: this.getConfigValue(
                    input,
                    'player_settings.hide_controls_after',
                    APP_CONFIG_DEFAULT.player_settings.hide_controls_after,
                    val => MILLISECONDS.toSeconds(val)
                ),
                bookmark_threshold: this.getConfigValue(
                    input,
                    'player_settings.bookmark_threshold',
                    APP_CONFIG_DEFAULT.player_settings.bookmark_threshold
                ),
                binge_watching_threshold: this.getConfigValue(
                    input,
                    'player_settings.binge_watching_threshold',
                    APP_CONFIG_DEFAULT.player_settings.binge_watching_threshold
                ),
                startover_min_position_ms: this.getConfigValue(
                    input,
                    'player_settings.startover_min_position_ms',
                    APP_CONFIG_DEFAULT.player_settings.startover_min_position_ms
                ),
                binge_watching_start_timeout: this.getConfigValue(
                    input,
                    'player_settings.binge_watching_start_time',
                    APP_CONFIG_DEFAULT.player_settings.binge_watching_start_timeout
                ),
                binge_watching_inactivity_timeout: this.getConfigValue(
                    input,
                    'player_settings.binge_watching_inactivity_timeout',
                    APP_CONFIG_DEFAULT.player_settings.binge_watching_inactivity_timeout
                ),
                manifest_suffix: input?.player_settings?.manifest_suffix,
                buffers: input?.player_settings?.buffers,
                timeshift_disable_duration_threshold: this.getConfigValue(
                    input,
                    'player_settings.timeshift_disable_duration_threshold',
                    APP_CONFIG_DEFAULT.player_settings.timeshift_disable_duration_threshold
                ),
                playback_languages_audio: this.getConfigValue(
                    input,
                    'player_settings.playback_languages_audio',
                    APP_CONFIG_DEFAULT.player_settings.playback_languages_audio
                ),
                playback_languages_subtitles: this.getConfigValue(
                    input,
                    'player_settings.playback_languages_subtitles',
                    APP_CONFIG_DEFAULT.player_settings.playback_languages_subtitles
                ),
                bookmark_save_interval: this.getConfigValue(
                    input,
                    'player_settings.bookmark_save_interval',
                    APP_CONFIG_DEFAULT.player_settings.bookmark_save_interval
                ),
            },
            languages: input?.languages,
            product_settings: {
                replay_window_time_machine: this.getConfigValue(
                    input,
                    'product_settings.replay_window_time_machine',
                    APP_CONFIG_DEFAULT.product_settings.replay_window_time_machine,
                    value => parseInt(value, 10)
                ),
                replay_window_time_machine_plus: this.getConfigValue(
                    input,
                    'product_settings.replay_window_time_machine_plus',
                    APP_CONFIG_DEFAULT.product_settings.replay_window_time_machine_plus,
                    value => parseInt(value, 10)
                ),
                product_code_time_machine_plus: this.getConfigValue(
                    input,
                    'product_settings.product_code_time_machine_plus',
                    APP_CONFIG_DEFAULT.product_settings.product_code_time_machine_plus
                ),
                linear_prefix: this.getConfigValue(
                    input,
                    'product_settings.linear_prefix',
                    APP_CONFIG_DEFAULT.product_settings.linear_prefix
                ),
                npvr_prefix: this.getConfigValue(input, 'product_settings.npvr_prefix', APP_CONFIG_DEFAULT.product_settings.npvr_prefix),
                subscription_update_delay: this.getConfigValue(
                    input,
                    'product_settings.subscription_update_delay',
                    APP_CONFIG_DEFAULT.product_settings.subscription_update_delay
                ),
            },
            epg_settings: {
                past_visibility: this.getConfigValue(
                    input,
                    'epg_settings.past_visibility',
                    APP_CONFIG_DEFAULT.epg_settings.past_visibility,
                    val => MILLISECONDS.toDays(val)
                ),
                future_visibility: this.getConfigValue(
                    input,
                    'epg_settings.future_visibility',
                    APP_CONFIG_DEFAULT.epg_settings.future_visibility,
                    val => MILLISECONDS.toDays(val)
                ),
                prime_time: this.getConfigValue(input, 'epg_settings.prime_time', APP_CONFIG_DEFAULT.epg_settings.prime_time),
                prime_time_dst: this.getConfigValue(input, 'epg_settings.prime_time_dst', APP_CONFIG_DEFAULT.epg_settings.prime_time_dst),
                day_start_hour: this.getConfigValue(input, 'epg_settings.day_start_hour', APP_CONFIG_DEFAULT.epg_settings.day_start_hour),
                row_height: this.getConfigValue(input, 'epg_settings.row_height', APP_CONFIG_DEFAULT.epg_settings.row_height),
                pixels_per_minute: APP_CONFIG_DEFAULT.epg_settings.pixels_per_minute,
            },
            pin_settings: {
                allowed_wrong_attempts: this.getConfigValue(
                    input,
                    'pin_settings.allowed_wrong_attempts',
                    APP_CONFIG_DEFAULT.pin_settings.allowed_wrong_attempts
                ),
                wrong_attempts_lock_duration: this.getConfigValue(
                    input,
                    'pin_settings.wrong_attempts_lock_duration',
                    APP_CONFIG_DEFAULT.pin_settings.wrong_attempts_lock_duration
                ),
            },
            search_settings: {
                key_threshold: this.getConfigValue(
                    input,
                    'search_settings.key_threshold',
                    APP_CONFIG_DEFAULT.search_settings.key_threshold
                ),
                trigger_timeout: this.getConfigValue(
                    input,
                    'search_settings.trigger_timeout',
                    APP_CONFIG_DEFAULT.search_settings.trigger_timeout
                ),
            },
            auth: {
                realm: this.getConfigValue(input, 'auth.realm', APP_CONFIG_DEFAULT.auth.realm),
                client_id: this.getConfigValue(input, 'auth.client_id', APP_CONFIG_DEFAULT.auth.client_id),
                google_cast_client_id: this.getConfigValue(
                    input,
                    'auth.google_cast_client_id',
                    APP_CONFIG_DEFAULT.auth.google_cast_client_id
                ),
            },
            miscellanious: {
                max_items_collection: this.getConfigValue(
                    input,
                    'miscellanious.max_items_collection',
                    APP_CONFIG_DEFAULT.miscellanious.max_items_collection
                ),
                gallery_auto_scroll: this.getConfigValue(
                    input,
                    'miscellanious.gallery_auto_scroll',
                    APP_CONFIG_DEFAULT.miscellanious.gallery_auto_scroll
                ),
                detail_page_small_image_threshold: this.getConfigValue(
                    input,
                    'miscellanious.detail_page_small_image_threshold',
                    APP_CONFIG_DEFAULT.miscellanious.detail_page_small_image_threshold
                ),
                sign_up_link: this.getConfigValue(input, 'miscellanious.sign_up_link', APP_CONFIG_DEFAULT.miscellanious.sign_up_link),
                sign_up_link_huub: this.getConfigValue(
                    input,
                    'miscellanious.sign_up_link_huub',
                    APP_CONFIG_DEFAULT.miscellanious.sign_up_link_huub
                ),
                internet_speed_test: this.getConfigValue(
                    input,
                    'miscellanious.internet_speed_test',
                    APP_CONFIG_DEFAULT.miscellanious.internet_speed_test
                ),
                youbora_account_code: this.getConfigValue(
                    input,
                    'miscellanious.youbora_account_code',
                    APP_CONFIG_DEFAULT.miscellanious.youbora_account_code
                ),
            },
            chromecast: {
                receiver_id: this.getConfigValue(input, 'chromecast.receiver_id', APP_CONFIG_DEFAULT.chromecast.receiver_id), // '341EF4DA'
                namespace: this.getConfigValue(input, 'chromecast.namespace', APP_CONFIG_DEFAULT.chromecast.namespace),
                media_namespace: this.getConfigValue(input, 'chromecast.media_namespace', APP_CONFIG_DEFAULT.chromecast.media_namespace),
            },
            content_markers: {
                ...APP_CONFIG_DEFAULT.content_markers,
                ...(input?.content_markers ?? {}),
            },
            smart_app_banner: {
                android_app_id: this.getConfigValue(
                    input,
                    'smart_app_banner.android_app_id',
                    APP_CONFIG_DEFAULT.smart_app_banner.android_app_id
                ),
                ios_app_id: this.getConfigValue(input, 'smart_app_banner.ios_app_id', APP_CONFIG_DEFAULT.smart_app_banner.ios_app_id),
            },
            locale_settings: {
                currency: this.getConfigValue(input, 'locale_settings.currency', APP_CONFIG_DEFAULT.locale_settings.currency),
                currency_sign: this.getConfigValue(
                    input,
                    'locale_settings.currency_sign',
                    APP_CONFIG_DEFAULT.locale_settings.currency_sign
                ),
                date_format: this.getConfigValue(input, 'locale_settings.date_format', APP_CONFIG_DEFAULT.locale_settings.date_format),
                decimal_seperator: this.getConfigValue(
                    input,
                    'locale_settings.decimal_seperator',
                    APP_CONFIG_DEFAULT.locale_settings.decimal_seperator
                ),
                number_seperator: this.getConfigValue(
                    input,
                    'locale_settings.number_seperator',
                    APP_CONFIG_DEFAULT.locale_settings.number_seperator
                ),
                time_format: this.getConfigValue(input, 'locale_settings.time_format', APP_CONFIG_DEFAULT.locale_settings.time_format),
            },
            // @ts-ignore
            meta_tags: Object.entries(this.getConfigValue(input, 'meta_tags', APP_CONFIG_DEFAULT.meta_tags)).reduce((acc, [key, value]) => {
                return { ...acc, [key.toLowerCase()]: value };
            }, {}),
        };
    };

    /**
     * Parse api configs coming from CC, include some default values in case missing
     *
     * @param input
     */
    private parseApiConfig = (input: ApiConfig): ApiConfig => {
        return {
            ...input,
            ...{
                config_id: this.getConfigValue(input, 'config_id', API_DEFAULT_CONFIG.config_id),
                page_size: this.getConfigValue(input, 'page_size', API_DEFAULT_CONFIG.page_size),
                slowlog_limit: this.getConfigValue(input, 'slowlog_limit', API_DEFAULT_CONFIG.slowlog_limit),
                timeout_limit: this.getConfigValue(input, 'timeout_limit', API_DEFAULT_CONFIG.timeout_limit),
                update_interval: this.getConfigValue(input, 'update_interval', API_DEFAULT_CONFIG.update_interval),
                backend_error_update_interval: this.getConfigValue(
                    input,
                    'backend_error_update_interval',
                    API_DEFAULT_CONFIG.backend_error_update_interval
                ),
            },
        };
    };

    /**
     * Parse feature control configs coming from CC, put the default values in the missing places
     *
     * @param input
     */
    private parseFeatureControls = (input: FeatureControl): FeatureControl => {
        return {
            search: input?.search ?? FEATURE_CONTROLS_DEFAULT.search,
            guest_mode: input?.guest_mode ?? FEATURE_CONTROLS_DEFAULT.guest_mode,
            guest_mode_show_login: input?.guest_mode_show_login ?? FEATURE_CONTROLS_DEFAULT.guest_mode_show_login,
            google_cast: input?.google_cast ?? FEATURE_CONTROLS_DEFAULT.google_cast,
            account_profile_language: input?.account_profile_language ?? FEATURE_CONTROLS_DEFAULT.account_profile_language,
            mini_epg: input?.mini_epg ?? FEATURE_CONTROLS_DEFAULT.mini_epg,
            multiple_audio: input?.mini_epg ?? FEATURE_CONTROLS_DEFAULT.multiple_audio,
            subtitles: input?.subtitles ?? FEATURE_CONTROLS_DEFAULT.subtitles,
            analytics: input?.analytics ?? FEATURE_CONTROLS_DEFAULT.analytics,
            epg_search: input?.epg_search ?? FEATURE_CONTROLS_DEFAULT.epg_search,
            timeshift: input?.timeshift ?? FEATURE_CONTROLS_DEFAULT.timeshift,
            dev_mode: input?.dev_mode ?? FEATURE_CONTROLS_DEFAULT.dev_mode,
            pvr: input?.pvr ?? FEATURE_CONTROLS_DEFAULT.pvr,
            buy_tvod: input?.buy_tvod ?? FEATURE_CONTROLS_DEFAULT.buy_tvod,
            npvr_upsell: input?.npvr_upsell ?? FEATURE_CONTROLS_DEFAULT.npvr_upsell,
            mini_player: input?.mini_player ?? FEATURE_CONTROLS_DEFAULT.mini_player,
            thumbnail_scroll: input?.thumbnail_scroll ?? FEATURE_CONTROLS_DEFAULT.thumbnail_scroll,
            self_service: input?.self_service ?? FEATURE_CONTROLS_DEFAULT.self_service,
        };
    };

    /**
     * Parse layout configs coming from CC, put the default values in the missing places
     *
     * @param input
     */
    private parseLayoutConfig = (input: LayoutConfig): LayoutConfig => {
        return {
            advanced: input?.advanced ?? LAYOUT_CONFIG_DEFAULT.advanced,
            background: input?.background ?? LAYOUT_CONFIG_DEFAULT.background,
            background_image: input?.background_image,
            black_color: input?.black_color ?? LAYOUT_CONFIG_DEFAULT.black_color,
            error_color: input?.error_color ?? LAYOUT_CONFIG_DEFAULT.error_color,
            secondary: input?.secondary ?? LAYOUT_CONFIG_DEFAULT.secondary,
            title: input?.title ?? LAYOUT_CONFIG_DEFAULT.title,
            language: input?.language ?? LAYOUT_CONFIG_DEFAULT.language,
            logo: input?.logo ?? LAYOUT_CONFIG_DEFAULT.logo,
            primary: input?.primary ?? LAYOUT_CONFIG_DEFAULT.primary,
            success_color: input?.success_color ?? LAYOUT_CONFIG_DEFAULT.success_color,
            white_color: input?.white_color ?? LAYOUT_CONFIG_DEFAULT.white_color,
            low_contrast: input?.low_contrast ?? LAYOUT_CONFIG_DEFAULT.low_contrast,
            high_contrast: input?.high_contrast ?? LAYOUT_CONFIG_DEFAULT.high_contrast,
            notification_background_color: input?.notification_background_color ?? LAYOUT_CONFIG_DEFAULT.notification_background_color,
            scrollbar_color: input?.scrollbar_color ?? LAYOUT_CONFIG_DEFAULT.scrollbar_color,
            epg_hover_color: input?.epg_hover_color ?? LAYOUT_CONFIG_DEFAULT.epg_hover_color,
            epg_live_program_color: input?.epg_live_program_color ?? LAYOUT_CONFIG_DEFAULT.epg_live_program_color,
            dropdown_background_color: input?.dropdown_background_color ?? LAYOUT_CONFIG_DEFAULT.dropdown_background_color,
        };
    };

    /**
     * Sets the properties of the Config retrieved from the CC
     * @param obj
     * @param appLanguage
     * @param translations
     * @returns Config
     */
    public createConfig = (obj: Config, appLanguage, translations): ApplicationConfig => {
        for (let i in obj) {
            if (obj.hasOwnProperty(i)) {
                switch (i) {
                    case 'app_config':
                        this.app_config = this.parseAppConfigs(obj[i]);
                        break;
                }
            }
        }

        this.app_config.general.language = appLanguage;
        if (!this.app_config.languages[appLanguage]) {
            // if the language configuration is missing from the config create a placeholder object
            // where the translation content to be loaded

            this.app_config.languages[appLanguage] = {};
        }
        this.app_config.languages[appLanguage].data = translations;

        for (let i in obj) {
            if (obj.hasOwnProperty(i)) {
                switch (i) {
                    case 'content_config':
                        this[i] = obj[i];
                        this.createPagesConfiguration(obj[i].configs);
                        break;
                    case 'layout_config':
                        this['layout_config'] = this.parseLayoutConfig(obj[i]);
                        this.setTitle(obj[i]);
                        this.insertLayoutConfigurations(obj[i]);
                        break;
                    case 'app_config':
                        break;
                    case 'api_config':
                        this['api_config'] = this.parseApiConfig(obj[i]);
                        break;
                    case 'feature_control':
                        this['feature_control'] = this.parseFeatureControls(obj[i]);
                        break;
                    default:
                        this[i] = obj[i];
                }
            }
        }

        this.createFromEditorialCollectionConfiguration();

        return this;
    };

    /**
     * Return an array of paths
     * @returns {Array}
     */
    public getPagePaths = (): string[] => {
        let page_paths: string[] = [];

        for (let page_id in this._page_ids) {
            if (page_id in this._page_ids && !page_paths.includes(this._page_ids[page_id])) {
                page_paths.push(this._page_ids[page_id]);
            }
        }

        return page_paths;
    };

    public setTitle = (layout_options: LayoutConfig): void => {
        document.title = layout_options.title;
    };

    /**
     * Returns the page id corresponding to a path (if found)
     * @param path
     * @returns {*}
     */
    getPageId(path: string): string {
        if (path === undefined) {
            path = '/';
        }
        if (path[0] !== '/') {
            path = '/' + path;
        }
        for (let page_id in this._page_ids) {
            if (page_id in this._page_ids && this._page_ids[page_id] === path) {
                return page_id;
            }
        }
    }

    /**
     * Get all page configs
     *
     */
    public getContentConfigs = (): Configs[] => {
        return this.content_config.configs || [];
    };

    /**
     * Returns the configuration options for a modular page
     * If page has tabs config for the first tab should be loaded
     * @param page_id
     * @returns {*}
     */

    public getPageConfig = (page_id: string): any => {
        if (!page_id) {
            // return {}; - Need to be clarified
            return;
        }

        let pageIds = Object.keys(this._page_configs),
            filter = pageIds.filter(item => item.toLowerCase() === page_id.toLowerCase());
        if (filter.length) {
            page_id = filter[0];
        }

        let firstValidChild: string = null,
            pageConfig: Configs = this._page_configs[page_id];

        if (this.pageHasTabs(page_id) && !this.isSubpage(page_id)) {
            this._page_configs[page_id].subpages.forEach(subPage => {
                if (firstValidChild == null && subPage in this._page_configs) {
                    firstValidChild = subPage;
                }
            });

            if (!firstValidChild) {
                firstValidChild = this._page_configs[page_id].subpages[0];
            }
            // TODO: [branding] test it properly to avoid misconfigurations caused by parent page
            pageConfig = { ...pageConfig, ...this._page_configs[firstValidChild] };
        }

        if (this.isSubpage(page_id)) {
            let parentPageConfig = this._page_configs[this.getParentOf(page_id)];
            if (parentPageConfig) {
                pageConfig = { ...parentPageConfig, ...pageConfig };
            }
        }

        return {
            ...pageConfig,
            modules: pageConfig?.modules?.filter(module => !module.disabled),
        };
    };

    public getConfigById = (configId: string): any => {
        return this._page_configs[configId];
    };

    public belongsToMainSection = (pageConfig: PageConfigType): boolean => this.isMainSection(pageConfig.id);

    public isMainSection = (configId: string): boolean => {
        if (configId == null) return true;

        return (
            this.content_config.main_menu.find(menuItem => menuItem.id.toLowerCase() === configId.toLowerCase()) != null ||
            this.content_config.footer_menu.find(menuItem => menuItem.id.toLowerCase() === configId.toLowerCase()) != null
        );
    };

    /**
     * Get page configuration by the current location and params
     *
     * @return {object | null}
     * @param props
     */
    getPageConfigByLocation = (props: {
        route: RouteChildrenProps['match'];
        parent?: string;
        params?: {
            collectionid?: string;
        };
        deviceType?: string;
        appLanguage?: string;
    }): PageConfigType => {
        let { route, params } = props,
            parent = getParentRoute(route, params),
            parentBP = getParentRoute(route, params, true),
            pageId = this.getPageId(parent),
            pageConfig = this.getPageConfig(pageId),
            now = new Date(),
            nowUTC = Date.UTC(1970, 0, 1, now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());

        const currentDay = now.toLocaleDateString('en', { weekday: 'long' }).toLowerCase();
        const timeZoneOffset = now.getTimezoneOffset();
        const currentTime = nowUTC - MINUTES.toMillis(timeZoneOffset);

        if (pageConfig && pageConfig.isCollectionPage) {
            let collectionId = params.collectionid,
                collectionConfiguration = this.getCollectionConfiguration(collectionId);

            if (!collectionConfiguration) {
                return null;
            }
            pageConfig = { ...pageConfig, ...collectionConfiguration, collectionId };
        }

        pageConfig = {
            ...pageConfig,
            modules: pageConfig?.modules?.filter(
                module =>
                    !module.disabled &&
                    (module.conditional_display?.time
                        ? module.conditional_display?.time?.start < currentTime && module.conditional_display?.time?.end > currentTime
                        : true) &&
                    (module.conditional_display?.days ? module.conditional_display?.days.includes(currentDay) : true) &&
                    (!module.conditional_display?.deviceType || module.conditional_display?.deviceType.toLowerCase() === 'any'
                        ? true
                        : module.conditional_display?.deviceType.toLowerCase() === props.deviceType) &&
                    (module.conditional_display?.languages ? module.conditional_display?.languages.includes(props.appLanguage) : true)
            ),
        };

        return {
            parentPath: parent || '/',
            parentBPPath: parentBP || '/',
            ...pageConfig,
        };
    };

    /**
     * Return true if current page has subpages/tabs
     * @returns {boolean}
     */
    public pageHasTabs = (page_id: string): boolean => {
        if (!page_id) {
            return false;
        }
        return !!(
            this.isSubpage(page_id) ||
            (this._page_configs[page_id] && this._page_configs[page_id].subpages && this._page_configs[page_id].subpages.length)
        );
    };

    /**
     * Return true if current page is subpage
     * @returns {boolean}
     */
    public isSubpage = (page_id: string): boolean => {
        if (!page_id) {
            return false;
        }
        return !!(this._page_configs[page_id] && this._page_configs[page_id]['parent'] != null);
    };

    /**
     * Return page id of parent page if any
     * @returns {string}
     */
    public getParentOf = (page_id: string): string => {
        return page_id == null ? undefined : this._page_configs[page_id] ? this._page_configs[page_id].parent : undefined;
    };

    /**
     * Returns the parent page config by main section path name
     *
     * @param section
     */
    public getParentByPathVar = (section: string): string => {
        const keys = Object.keys(this._page_configs);

        for (let i = 0; i < keys.length; i += 1) {
            const key = keys[i];
            const config = this._page_configs[key];
            const configPath = config.path;

            if (configPath.slice(1, configPath.length) === section) {
                return this._page_configs[key].parent;
            }
        }

        return null;
    };

    /**
     * Return an array of subpages for current page
     * @returns {Array}
     */

    public getPageTabs = (page_id: string): PageConfigs[] => {
        let tab_list = [],
            subpages;

        if (page_id == null) {
            return tab_list;
        }

        subpages = this.isSubpage(page_id)
            ? 'parent' in this._page_configs[page_id]
                ? this._page_configs[this._page_configs[page_id].parent].subpages
                : []
            : this._page_configs[page_id].subpages;

        for (let _page_id in subpages) {
            if (_page_id in subpages && subpages[_page_id] in this._page_ids) {
                tab_list.push(this._page_configs[subpages[_page_id]]);
            }
        }

        return tab_list;
    };

    public getPagePath = (pageId: string): string => this._page_ids[pageId];

    /**
     * Return all possible modules
     *
     * @returns {Array}
     */
    public getAllLinkedModules = (): any[] => {
        let modules = [];

        for (let key in this._page_configs) {
            if (key in this._page_configs) {
                if (this._page_configs[key].modules && this._page_configs[key].modules.length) {
                    for (let i = 0; i < this._page_configs[key].modules.length; ++i) {
                        this._page_configs[key].modules[i]['page_id'] = key;
                    }
                    modules = [...modules, ...this._page_configs[key].modules];
                }
            }
        }

        return modules;
    };

    /**
     * Find collection configuration by id
     *
     * @param collectionId
     * @returns {*}
     */
    public getCollectionConfiguration = (collectionId: string): any => {
        let modules = this.getAllLinkedModules(),
            collectionConfig,
            parentModuleConfig;

        const fromEditorialModule = this._editorial_view_all_configs[collectionId];
        if (fromEditorialModule) {
            return {
                type: 'modular_page',
                page_id: collectionId,
                layout_config: null,
                min_elements: 0,
                title: fromEditorialModule?.title,
                variant: 'a1',
                setChannelLogo: null,
                setChannelTitle: null,
                page: collectionId,
                modules: [fromEditorialModule],
            };
        }

        parentModuleConfig = modules.find(module => {
            return (
                'collection' in module &&
                encodeURI(module.collection.id)
                    .toLowerCase()
                    .replace(/_/g, '-') ===
                    encodeURI(collectionId)
                        .toLowerCase()
                        .replace(/_/g, '-')
            );
        });

        if (parentModuleConfig) {
            let category_id;

            if (parentModuleConfig.collection.id === 'category') {
                category_id = `${collectionId}`;
                collectionId = 'category';
            }

            let page = this.getPageConfig(parentModuleConfig['page_id']);
            delete parentModuleConfig.id;
            //don't want the style of the stripe, we need the collection's own style if available
            delete parentModuleConfig.style;
            collectionConfig = {
                ...parentModuleConfig,
                modules: [
                    {
                        ...parentModuleConfig.collection,
                        page: page.page,
                        page_id: parentModuleConfig['page_id'],
                        page_title: parentModuleConfig['title'],
                        type: parentModuleConfig.collection['type'] || parentModuleConfig['type'],
                        variant: parentModuleConfig.collection['variant'] || parentModuleConfig['variant'],
                        id: collectionId,
                        category_id,
                        style: parentModuleConfig.collection['style'],
                    },
                ],
                title: parentModuleConfig.title,
                page: page.page,
                layout_config: page.layout_config,
                setChannelTitle: false,
                setChannelLogo: false,
                type: 'modular_page',
            };
            if (!collectionConfig.modules[0].datasource) {
                collectionConfig.modules[0].datasource = { ...parentModuleConfig.datasource };
            }
            delete collectionConfig.datasource;
            delete collectionConfig.collection;
            delete collectionConfig.module;
            return collectionConfig;
        }

        return null;
    };

    public getRepeaterStripeCollectionConfiguration = (foreignId: string, collectionId: string): any => {
        const collectionConfig = this.getCollectionConfiguration(collectionId);

        if (collectionConfig) {
            if (collectionConfig.modules && collectionConfig.modules[0]) {
                collectionConfig.modules[0].datasource.request.variables = {
                    categoryId: foreignId,
                };
            }
        }

        return collectionConfig;
    };

    public getContentMarker = (key: string): ContentMarker => {
        return this.app_config.content_markers[key] ?? this.app_config.content_markers._DEFAULT;
    };

    public isFeatureControlValue = key => key in this.feature_control;

    public isFeatureActive = (key: keyof FeatureControl) => this.feature_control[key] === true;
}

export default new ApplicationConfig();
