import SVGInline from 'react-svg-inline';
import { Breakpoints, MQ } from 'style/styled-components/cssMediaQueries';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';

// todo: font-size should change based on screen resolution
export const AllButtonText = styled.span`
    font-size: 14px;
    padding-right: 8px;
    display: none;

    ${MQ(Breakpoints.m)} {
        display: inline;
    }
`;

// todo: height and margin-bottom -> resolution dependent
export const AllButtonArrow = styled(SVGInline)`
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 5px;
    }
`;

export const LinkShowAllWrapper = styled.div`
    padding-left: 8px;
`;
