import React, { FC, ReactNode, useEffect, useState } from 'react';
import SVGInline from 'react-svg-inline';
import {
    PageWrapper,
    Title,
    SelectHint,
    DeviceList,
    DeviceTypeIcon,
    Device,
    DeviceLabel,
    Name,
    TakeOverButton,
    TakeOverLabel,
    OverlayPageWrapper,
    CloseButton,
} from './PageStreamManager.css';

import translate from '../../../utils/fnTranslate';
import icons from '../../../style';
import { useGlobalNetworkError, useGlobalNoInternet } from '../../../hooks/withNetworkCheck/withNetworkCheck';
import { useDataFetcher } from '../../../hooks/useDataFetcher/useDataFetcher';
import Api from '../../../api/Api';
import { TrackingElementPropTypes } from '../../../types/Tracking';
import { AlertDialog } from '../../AlertDialog/AlertDialog';
import { useNavigation } from '../../../hooks/useNavigation';
import DRM from '../../../providers/player/DRM';
import { usePlayer } from '../../../providers/player/PlayerContext';
import { STREAM_LIMIT, STREAM_REPLACED } from '../../../providers/player/playerConstants';

export enum DeviceType {
    'mobile' = 'mobile',
    'tablet' = 'tablet',
    'computer' = 'computer',
    'tv' = 'tv',
    'stb' = 'stb',
}

type DevicePropType = TrackingElementPropTypes & {
    icon: any;
    name: string;
    takeOverLabel: string;
    onClick: () => void;
};

const TakeOverAlert: React.FC<{ uuid: string; onSuccess: () => void; onClose: () => void }> = ({ uuid, onSuccess, onClose }) => {
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

    const takeOver = async () => {
        try {
            await DRM.getToken(uuid);
            onSuccess();
        } catch (error) {
            setShowErrorAlert(true);
        }
    };

    return (
        <AlertDialog
            title={translate(showErrorAlert ? 'TAKE_OVER_FAILED_SCREEN_TITLE' : 'CONFIRMATION_SCREEN_TITLE')}
            bodyText={translate(showErrorAlert ? 'TAKE_OVER_FAILED_SCREEN_HINT' : 'CONFIRMATION_SCREEN_HINT')}
            buttons={[
                {
                    text: translate('CANCEL_BUTTON'),
                    onClick: onClose,
                },
                {
                    text: translate(showErrorAlert ? 'TRY_AGAIN_BUTTON' : 'TAKE_OVER_BUTTON'),
                    onClick: takeOver,
                },
            ]}
        />
    );
};

const DeviceItem: React.FC<DevicePropType> = ({ icon, name, takeOverLabel, onClick }) => {
    return (
        <Device>
            <DeviceTypeIcon>{icon && <SVGInline svg={icon} />}</DeviceTypeIcon>
            <DeviceLabel>
                <Name>{name}</Name>
            </DeviceLabel>
            <TakeOverButton onClick={onClick}>
                <TakeOverLabel>{takeOverLabel}</TakeOverLabel>
            </TakeOverButton>
        </Device>
    );
};

export const PageStreamManager: FC = () => {
    const { onNoInternet } = useGlobalNoInternet();
    const { onNetworkError } = useGlobalNetworkError();
    const [deviceToTakeOver, setDeviceToTakeOver] = useState<string | null>(null);
    const { playerError, resetError, setStreamEndReached } = usePlayer();
    const { onBackPressed, history } = useNavigation();
    const [mainAlertType, setMainAlertType] = useState<string | null>(playerError?.message);

    const { response, error, fetcher: getAllDevices } = useDataFetcher<any, any>(() => Api.getAllDevices(), onNoInternet, onNetworkError);

    const getDeviceIcon = (deviceType: string) => {
        switch (deviceType) {
            case DeviceType.mobile:
                return icons.icoDevicePhone;
            case DeviceType.tablet:
                return icons.icoDeviceTablet;
            case DeviceType.computer:
                return icons.icoDeviceDesktop;
            case DeviceType.tv:
                return icons.icoDeviceTv;
            case DeviceType.stb:
                return icons.icoDeviceTv;
            default:
                return null;
        }
    };

    const deviceItems: ReactNode[] = response?.devices
        ? response?.devices
              .filter(device => device.is_streaming === true)
              .map((device, index) => (
                  <DeviceItem
                      key={`device-${index}`}
                      icon={getDeviceIcon(device.screen_type)}
                      name={`${device.friendly_name}, ${device.os_type}`}
                      takeOverLabel={translate('TAKE_OVER_BUTTON')}
                      onClick={() => setDeviceToTakeOver(device.uuid)}
                  />
              ))
        : null;

    useEffect(() => {
        getAllDevices(null);
    }, []);

    if (mainAlertType) {
        if (mainAlertType === STREAM_LIMIT) {
            return (
                <AlertDialog
                    title={translate('PLAY_SLOTS_ERROR_SCREEN_TITLE', {
                        stream_count: response?.stream_count,
                        max_stream_count: response?.max_stream_count,
                    })}
                    bodyText={translate('PLAY_SLOTS_ERROR_SCREEN_HINT')}
                    buttons={[
                        {
                            text: translate('CANCEL_BUTTON'),
                            onClick: () => {
                                resetError();
                                onBackPressed();
                                setStreamEndReached(true);
                            },
                        },
                        {
                            text: translate('MANAGE_STREAMS_BUTTON'),
                            onClick: () => {
                                setMainAlertType(null);
                            },
                        },
                    ]}
                />
            );
        }
        if (mainAlertType === STREAM_REPLACED) {
            return (
                <AlertDialog
                    title={translate('TAKE_OVER_ERROR_SCREEN_TITLE')}
                    bodyText={
                        <div
                            dangerouslySetInnerHTML={{
                                __html: translate('TAKE_OVER_ERROR_SCREEN_HINT_DEVICE', {
                                    device: `<div class="bold">${playerError.details.substitute_name}</div>`,
                                }),
                            }}
                        ></div>
                    }
                    buttons={[
                        {
                            text: translate('CANCEL_BUTTON'),
                            onClick: () => {
                                resetError();
                                onBackPressed();
                                setStreamEndReached(true);
                            },
                        },
                        {
                            text: translate('MANAGE_STREAMS_BUTTON'),
                            onClick: () => {
                                setMainAlertType(null);
                            },
                        },
                    ]}
                />
            );
        }
    }
    return (
        <OverlayPageWrapper>
            <CloseButton
                onClick={() => {
                    resetError();
                    onBackPressed();
                    setStreamEndReached(true);
                }}
            >
                <SVGInline svg={icons.closeIcon} />
            </CloseButton>
            <PageWrapper>
                <Title>{translate('MANAGE_STREAMS_SCREEN_TITLE')}</Title>
                <SelectHint>{translate('MANAGE_STREAMS_SCREEN_HINT_WEB')}</SelectHint>
                {deviceItems?.length > 0 && <DeviceList>{deviceItems}</DeviceList>}
            </PageWrapper>
            {deviceToTakeOver && (
                <TakeOverAlert
                    uuid={deviceToTakeOver}
                    onSuccess={() => {
                        history.go(0);
                    }}
                    onClose={() => {
                        setDeviceToTakeOver(null);
                    }}
                />
            )}
        </OverlayPageWrapper>
    );
};
