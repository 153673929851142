import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { PlayerControl, PlayerIcon, PlayingAsset, PropTypesAdditional, PropTypesPlayerUICommon } from '../../types/Player';
import { useAssetReplayCapable } from '../useAssetReplayCapable/useAssetReplayCapable';
import { useConfig } from '../../providers/useConfig/ConfigContext';
import { getRecordingIconName } from '../../utils/fnData';
import { TimeClusterRecordingTypeMap } from '../../types/RecordingTypes';
import {
    castEnabled,
    getBroadcastEmbeddedPlayerMetaData,
    getBroadcastPlayerActionComponents,
    getBroadcastPlayerMetaData,
    getBroadcastPlayerMoreOverlayMetaData,
} from '../../utils/fnPlayerUI';
import { ChanelLogo, ChannelLogoFallback } from '../../components/PlayerUI/PlayerUI.css';
import { Picture } from '../../components/Picture/Picture';
import { isCatchupPlayerAsset } from '../../utils/fnTypeGuards';

export const useCatchupPlayerUI = (common: PropTypesPlayerUICommon, asset: PlayingAsset): PropTypesAdditional => {
    const { isReplayCapable } = useAssetReplayCapable(asset);
    const { config } = useConfig();

    if (!isCatchupPlayerAsset(asset)) {
        return null;
    }

    const {
        channelLogo,
        channelName,
        isRecorded,
        channelSubscribed,
        title,
        subtitle,
        logicalChannelNumber,
        description,
        cast,
        originalLanguages,
    } = asset;

    const getIcons = (): PlayerIcon[] => {
        const metaIcons: PlayerIcon[] = [];

        if (isReplayCapable) {
            metaIcons.push(PlayerIcon.CATCHUP);
        }

        if (isRecorded) {
            metaIcons.push(getRecordingIconName(TimeClusterRecordingTypeMap.PAST) as PlayerIcon);
        }

        return metaIcons;
    };

    const getControls = (): PlayerControl[] => {
        const controls: PlayerControl[] = [PlayerControl.FULL_SCREEN, PlayerControl.VOLUME];

        if (castEnabled()) {
            controls.push(PlayerControl.CAST);
        }

        controls.push(PlayerControl.INFO);

        controls.push(PlayerControl.SUBTITLE);

        controls.push(PlayerControl.START_OVER);

        if (!isMobile && !isTablet) {
            controls.push(PlayerControl.JUMP_BACK);
            controls.push(PlayerControl.JUMP_FORWARD);
        }
        controls.push(PlayerControl.PLAY_PAUSE);

        if (channelSubscribed && isRecorded && config && config.isFeatureActive('pvr')) {
            controls.push(PlayerControl.RECORDING);
        }

        return controls;
    };

    const controls = getControls();
    const { actionPopup, mobileOverlayActionHandlers } = getBroadcastPlayerActionComponents(controls, asset);

    return {
        logo: (channelLogo || channelName) && (
            <ChanelLogo>
                <Picture src={channelLogo} hPosition={'left'} vPosition={'center'} fit={'scale-down'}>
                    {channelName && <ChannelLogoFallback>{channelName}</ChannelLogoFallback>}
                </Picture>
            </ChanelLogo>
        ),
        logicalChannelNumber,
        metaData: getBroadcastPlayerMetaData(asset),
        embeddedPlayerMetadata: getBroadcastEmbeddedPlayerMetaData(asset),
        contentMarker: null,
        metaIcons: getIcons(),
        disabledControls: [],
        controls,
        seekEnabled: true,
        title,
        subtitle,
        actionPopup,
        mobileOverlayActionHandlers,
        moreOverlayMetaData: getBroadcastPlayerMoreOverlayMetaData(asset),
        description,
        cast,
        originalLanguages,
    };
};
