import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { AssetTypes } from 'types/Common';
import { Bookmark, PersonRole } from '../../types/Asset';
import Expirable from '../../utils/Expirable';

import { channelsCollectionParser, mixedCardCollectionParser, nowAndNextCollectionParser } from '../../utils/fnParser';
import { Datasource } from '../../types/Config';
import { useConfig } from '../useConfig/ConfigContext';
import { useChannels } from '../useChannels/useChannels';
import { useFetchOnWorldContent } from '../useWorld/WorldContext';
import { getFilterValue } from '../../utils/fnUrl';
import localConfig from '../../config/localConfig';
import { DataSourceMethod, FilterItems } from '../../types/ApiTypes';
import { isBroadcast, isEpisode, isMovie, isRecording } from '../../utils/fnTypeGuards';
import { DAYS } from '../../utils/TimeUnit';
import { useGlobalNetworkError, useGlobalNoInternet } from '../../hooks/withNetworkCheck/withNetworkCheck';
import { Rentable } from '../../types/CommonTypes';
import { CollectionDataLoader } from './CollectionDataLoader';
import { ChunkLoaderData } from '../../utils/ChunkLoader/ChunkLoaderData';

const CACHE_TIME = DAYS.toMillis(1);
const DEFAULT_STATE: ChunkLoaderData = new ChunkLoaderData();

type CollectionStore = {
    [key: string]: Expirable<ChunkLoaderData>;
};

const getParser = (method: string) => {
    switch (method) {
        case DataSourceMethod.GET_CATEGORY_REQUEST:
        case DataSourceMethod.GET_SERIES_CATEGORY_REQUEST:
        case DataSourceMethod.GET_DYNAMIC_SERIES:
        case DataSourceMethod.GET_DYNAMIC_MOVIES:
        case DataSourceMethod.GET_PURCHASED_TITLES:
        case DataSourceMethod.GET_DYNAMIC_CATEGORY_CONTENT:
        case DataSourceMethod.GET_MOVIE_RECOMMENDATIONS:
        case DataSourceMethod.GET_SERIES_RECOMMENDATIONS:
        case DataSourceMethod.SEARCH_MOVIES:
        case DataSourceMethod.SEARCH_SERIES:
        case DataSourceMethod.GET_EPISODES:
        case DataSourceMethod.GET_MOVIES:
        case DataSourceMethod.GET_SCHEDULED:
        case DataSourceMethod.GET_ALL_RECORDINGS:
            return mixedCardCollectionParser;
        case DataSourceMethod.GET_NOW_NEXT_REQUEST:
            return nowAndNextCollectionParser;
        case DataSourceMethod.GET_CHANNELS_REQUEST:
            return channelsCollectionParser;
        default:
            return input => input;
    }
};

const useCollectionStore = () => {
    const [collectionStore, setCollectionStore] = useState<CollectionStore>(null);

    const update = (collectionId: string, data: ChunkLoaderData, expiration?: number) => {
        setCollectionStore({
            ...collectionStore,
            ...{
                [collectionId]: new Expirable<ChunkLoaderData>(
                    data,
                    expiration && expiration !== Infinity ? expiration : Date.now() + CACHE_TIME
                ),
            },
        });
    };

    const select = (collectionId: string, returnInvalid = false): ChunkLoaderData => {
        return collectionStore?.[collectionId]?.getContent(returnInvalid);
    };

    return {
        select,
        update,
        store: collectionStore,
        updateStore: (store: CollectionStore) => setCollectionStore(store),
    };
};

const CollectionDataContext = createContext<ReturnType<typeof useCollectionStore>>(null);

export const CollectionProvider = ({ children }) => {
    const store = useCollectionStore();
    return <CollectionDataContext.Provider value={store}>{children}</CollectionDataContext.Provider>;
};

export const useCollectionFetcher = (collectionId: string, dataSource: Datasource) => {
    const { select, update, updateStore } = useContext(CollectionDataContext);
    const { config } = useConfig();

    const data = select(collectionId);
    const loader = useRef<CollectionDataLoader>(null);
    const { onNoInternet, onInternet } = useGlobalNoInternet();
    const { onNetworkError } = useGlobalNetworkError();

    const { channels, loading: channelsLoading, fetchChannels } = useChannels();
    const { fetchWithTopAndChildCategory, loading: worldContentLoading } = useFetchOnWorldContent();
    const [isLoading, setIsLoading] = useState(false);
    const [requested, setRequested] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dataUpdated, setDataUpdated] = useState(false);

    const fetch = () => {
        const dsMethod = dataSource.request.method;

        if (dsMethod === DataSourceMethod.GET_CHANNELS_REQUEST) {
            setRequested(true);
            fetchChannels();
        } else if (dsMethod === DataSourceMethod.GET_DYNAMIC_MOVIES || dsMethod === DataSourceMethod.GET_DYNAMIC_SERIES) {
            fetchWithTopAndChildCategory(null, {
                onReady: (childCategory, topCategories) => {
                    if (topCategories) {
                        setRequested(true);

                        const categoryType = getFilterValue(dataSource.params.filter, FilterItems.categoryType);
                        if (!dataSource.request.variables) {
                            dataSource.request.variables = {};
                        }

                        // eslint-disable-next-line dot-notation
                        dataSource.request.variables['categoryId'] = topCategories[categoryType];

                        setLoading(true);
                        loader.current.initialRequest();
                    }
                },
            });
        } else if (dataSource.request.method === DataSourceMethod.GET_WORLD_CATEGORIES) {
            fetchWithTopAndChildCategory(getFilterValue(dataSource.params.filter, FilterItems.categoryType), {
                onReady: childCategory => {
                    const role =
                        childCategory.name === 'Actors'
                            ? PersonRole.ACTOR
                            : childCategory.name === 'Directors'
                            ? PersonRole.DIRECTOR
                            : null;

                    const parser = childCategory?.childCategories
                        .filter(asset => asset.titleCount > 0)
                        .map(asset => {
                            return {
                                id: asset.id,
                                title: asset.name,
                                role,
                                type: AssetTypes.person,
                                image: null,
                                onClickOpenCollection: true,
                            };
                        });

                    update(
                        collectionId,
                        new ChunkLoaderData(parser.length, [
                            {
                                pagination: {
                                    start: 0,
                                    limit: parser.length - 1,
                                },
                                data: {
                                    items: parser,
                                    scheduleReload: -1,
                                    moreResources: false,
                                    totalItems: parser.length,
                                },
                            },
                        ])
                    );
                },
            });
        } else {
            setRequested(true);
            setIsLoading(true);
            setLoading(true);

            loader.current.initialRequest();
        }
    };

    const loadMore = () => {
        if (error || !loader.current) {
            return;
        }

        setLoading(true);
        loader.current.loadMore();
    };

    const requestData = () => {
        if (!data && !error) {
            fetch();
        }
    };

    const onDataUpdated = (loaderData: ChunkLoaderData) => {
        setLoading(false);

        const expiration = loaderData.getNearestExpiration();

        update(collectionId, loaderData, expiration && expiration > Date.now() ? expiration : null);
    };

    const updateDataSource = (param: any) => {
        updateStore({});
        setRequested(false);
        setLoading(true);

        dataSource.params.sort = [param];

        loader.current = new CollectionDataLoader(dataSource, getParser(dataSource.request.method), config.api_config.page_size, data, true)
            .setDataChangedListener(onDataUpdated)
            .setErrorCallback(() => setError(true))
            .setIsOfflineCallback(onNoInternet)
            .setIsOnlineCallback(onInternet)
            .setNetworkErrorCallback(onNetworkError);

        setDataUpdated(true);
    };

    useEffect(() => {
        if (error) {
            console.log(error);
        }
    }, [error]);

    useEffect(() => {
        if (channels && dataSource.request.method === DataSourceMethod.GET_CHANNELS_REQUEST) {
            update(
                collectionId,
                new ChunkLoaderData(channels.length, [
                    {
                        pagination: {
                            start: 0,
                            limit: channels.length - 1,
                        },
                        data: {
                            items: channels,
                            scheduleReload: -1,
                            moreResources: false,
                            totalItems: channels.length,
                        },
                    },
                ])
            );
        }
    }, [channels]);

    useEffect(() => {
        if (data && !requested) {
            setRequested(true);
        }
    }, [data]);

    useEffect(() => {
        if (dataUpdated) {
            fetch();
            setDataUpdated(false);
        }
    }, [dataUpdated]);

    useEffect(() => {
        return () => {
            if (loader?.current) {
                loader.current.destroy();
            }
        };
    }, []);

    useEffect(() => {
        if (dataSource) {
            const dsMethod = dataSource.request.method;

            if (dsMethod !== DataSourceMethod.GET_CHANNELS_REQUEST) {
                if (!loader.current) {
                    loader.current = new CollectionDataLoader(
                        dataSource,
                        getParser(dataSource.request.method),
                        config.api_config.page_size,
                        data
                    )
                        .setDataChangedListener(onDataUpdated)
                        .setErrorCallback(() => setError(true))
                        .setIsOnlineCallback(onInternet)
                        .setIsOfflineCallback(onNoInternet)
                        .setNetworkErrorCallback(onNetworkError);
                }
            }

            if (dsMethod === DataSourceMethod.GET_CHANNELS_REQUEST) {
                setIsLoading(channelsLoading);
            } else if (dsMethod === DataSourceMethod.GET_DYNAMIC_MOVIES || dsMethod === DataSourceMethod.GET_DYNAMIC_SERIES) {
                setIsLoading(loading || !data);
            } else {
                setIsLoading(loading);
            }
        }
    }, [loading, worldContentLoading, channelsLoading, dataSource, data]);

    return {
        data: data ?? DEFAULT_STATE,
        requestData,
        loadMore,
        loading: isLoading,
        requested,
        updateDataSource,
    };
};

export const usePurgeCollectionCache = () => {
    const { updateStore } = useContext(CollectionDataContext);
    return () => updateStore({});
};

export const useCollectionCacheCleaner = () => {
    const { store, updateStore } = useContext(CollectionDataContext);

    return (cacheKey: string) => {
        const tempState: CollectionStore = cloneDeep(store ?? {});
        delete tempState[cacheKey];

        updateStore(tempState);
    };
};

export const useEmptyDynamicCache = () => {
    const { store, updateStore } = useContext(CollectionDataContext);
    const tempState: CollectionStore = cloneDeep(store ?? {});

    return () => {
        Object.keys(tempState).forEach(key => {
            if (key.startsWith(localConfig.data.dynamicKeyPrefix)) {
                console.log(`delete data on key=${key}`);
                delete tempState[key];
            }
        });

        updateStore(tempState);
    };
};

export const useEmptyRecordingCache = () => {
    const { store, updateStore } = useContext(CollectionDataContext);
    const tempState: CollectionStore = cloneDeep(store ?? {});

    return () => {
        Object.keys(tempState).forEach(key => {
            if (key.startsWith(localConfig.data.recordingKeyPrefix)) {
                console.log(`delete data on key=${key}`);
                delete tempState[key];
            }
        });

        updateStore(tempState);
    };
};

export const useCollectionPersonalizedVodInfoHandler = () => {
    const { store, updateStore } = useContext(CollectionDataContext);

    return (vodPersonalized: Rentable & Bookmark) => {
        const tempState: CollectionStore = cloneDeep(store ?? {});

        Object.keys(tempState).forEach(key => {
            const collectionData = tempState[key];
            const collectionContent = collectionData?.getContent();

            if (Object.keys(collectionContent?.chunks ?? {}).length) {
                Object.keys(collectionContent.chunks).forEach(chunkKey => {
                    const assetIndex = collectionContent.chunks[chunkKey].data.items.findIndex(asset => {
                        let { id } = asset;

                        if (isBroadcast(asset) || isRecording(asset) || isEpisode(asset) || isMovie(asset)) {
                            id = asset.titleId;
                        }

                        return id === vodPersonalized.id;
                    });

                    if (assetIndex !== -1) {
                        collectionContent.chunks[chunkKey].data.items[assetIndex] = {
                            ...collectionContent.chunks[chunkKey].data.items[assetIndex],
                            ...{
                                vodAssetState: vodPersonalized.vodAssetState,
                                rentOffers: vodPersonalized.rentOffers,
                                subscribeOffer: vodPersonalized.subscribeOffer,
                                bookmark: vodPersonalized.bookmark,
                            },
                        };
                    }

                    collectionData.updateContent(collectionContent);
                });
            }
        });

        updateStore(tempState);
    };
};
