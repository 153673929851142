import Cookies, { CookieSetOptions } from 'universal-cookie';
import { CookieStorage, LocalStorage } from '../types/Storage';

const cookieStorage = new Cookies();

export const removeLocalStorage = <T extends keyof LocalStorage>(key: T) => {
    localStorage.removeItem(key);
};

export const setLocalStorage = <T extends keyof LocalStorage>(key: T, value: LocalStorage[T]) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const setCookieStorage = <T extends keyof CookieStorage>(key: T, value: CookieStorage[T], options?: CookieSetOptions) => {
    cookieStorage.set(key, JSON.stringify(value), options);
};

export const setLocalStorageString = <T extends keyof LocalStorage>(key: T, value: string) => {
    localStorage.setItem(key, value);
};

export const setCookieStorageString = <T extends keyof CookieStorage>(key: T, value: string, options?: CookieSetOptions) => {
    cookieStorage.set(key, value, options);
};

const getStorage = (key: string, storage: typeof localStorage | typeof sessionStorage) => {
    const item = storage.getItem(key);
    if (item) {
        try {
            return JSON.parse(item);
        } catch (e) {
            return null;
        }
    }
    return null;
};

const getCookie = (key: string, storage: typeof cookieStorage) => {
    const item = storage.get(key);
    if (item) {
        try {
            return JSON.parse(item);
        } catch (e) {
            return null;
        }
    }
    return null;
};

export const getLocalStorage = <T extends keyof LocalStorage>(key: T): LocalStorage[T] => {
    return getStorage(key, localStorage);
};

export const getCookieStorage = <T extends keyof CookieStorage>(key: T): CookieStorage[T] => {
    return getCookie(key, cookieStorage);
};

export const getLocalStorageString = <T extends keyof LocalStorage>(key: T): string => {
    return localStorage.getItem(key);
};

export const getCookieStorageString = <T extends keyof CookieStorage>(key: T): string => {
    return cookieStorage.get(key);
};
