import { EditorialItem, EditorialItemAction, ModuleConfigs } from '../types/Config';
import { StripeTypes } from '../types/Common';
import { PurchasedProduct } from '../types/Entitlement';
import { isSubscribedToProduct } from './fnEntitlement';

export const getEditorialModulePageId = (input: EditorialItemAction) => {
    return input?.collection_id || input?.module?.id || input?.module?.title?.toLocaleLowerCase();
};

export const stripeHasEditorialViewAll = (module: ModuleConfigs) => {
    return (
        (module?.module?.toLowerCase() === 'stripe' || module?.module?.toLowerCase() === 'repeater') &&
        (module.type === StripeTypes.MIXED ||
            module.type === StripeTypes.MOVIE ||
            module.type === StripeTypes.ROUNDED ||
            module.type === StripeTypes.PORTRAIT ||
            module.type === StripeTypes.PERSON ||
            module.type === StripeTypes.NOW_NEXT) &&
        module.items &&
        module.items.length === 1 &&
        module.items[0]?.action?.type?.toLowerCase() === 'view_all_link'
    );
};

export const isEditorialStripeModule = (module: ModuleConfigs) => {
    return module.module === 'Stripe' && module.type === 'editorial' && module.items && module.items.length;
};

export const isViewAllLink = (input?: EditorialItem) => input && input?.action.type.toLowerCase() === 'view_all_link';

export const isCollectionItem = (input?: EditorialItem) => input && input?.action.type.toLowerCase() === 'collection';

export const isPageItem = (input?: EditorialItem) => input && input?.action.type.toLowerCase() === 'page';

export const isMenuTabItem = (input?: EditorialItem) => input && input?.action.type.toLowerCase() === 'menu_tab';

export const isDeepLink = (input?: EditorialItem) => input && input?.action.type.toLowerCase() === 'deeplink';

export const getStripeEditorialModulePageId = (input?: EditorialItem) => {
    if (!input) return null;
    return getEditorialModulePageId(input.action);
};

export const canRender = (purchasedProducts: PurchasedProduct[], input: EditorialItem): boolean => {
    return !input.show_when_subscribed || isSubscribedToProduct(purchasedProducts, input.show_when_subscribed);
};
