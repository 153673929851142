import { Paginated } from '../../types/ApiTypes';
import { Collection } from '../fnParser';
import { CoverAsset } from '../../types/Asset';

export type DataChunk = {
    pagination: Paginated;
    data: Collection<CoverAsset>;
};

export class ChunkLoaderData {
    totalCount: number;

    chunks: { [key: number]: DataChunk };

    invalidateAt: number;

    constructor(totalCount: number = 0, chunks: { [key: number]: DataChunk } = {}) {
        this.totalCount = totalCount;
        this.chunks = chunks;
    }

    public setTotalCount = (totalCount: number) => {
        this.totalCount = totalCount;
    };

    public pushChunk = (chunk: DataChunk) => {
        this.chunks = {
            ...this.chunks,
            ...{
                [chunk.pagination.start + chunk.pagination.limit]: chunk,
            },
        };
    };

    public empty = () => {
        this.chunks = {};
    };

    public replaceChunk = (chunk: DataChunk) => {
        const chunkId = chunk.pagination.start + chunk.pagination.limit;

        if (!(chunkId in this.chunks)) {
            this.pushChunk(chunk);
            return;
        }

        this.chunks = {
            ...this.chunks,
            ...{
                [chunkId]: chunk,
            },
        };
        this.invalidateAt = this.getNearestExpiration();
    };

    public getMergedData = () => {
        let mergedChunkData: CoverAsset[] = [];

        if (Object.keys(this.chunks).length) {
            Object.keys(this.chunks)
                .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
                .forEach(key => {
                    mergedChunkData = [...mergedChunkData, ...this.chunks[key].data.items];
                });
        }

        return {
            totalCount: this.totalCount,
            loadedAssetCount: mergedChunkData.length,
            items: mergedChunkData,
        };
    };

    public getNearestExpiration = () => {
        if (!this.chunks || !Object.keys(this.chunks).length) {
            return null;
        }

        return Object.keys(this.chunks)
            .map(key => this.chunks[key])
            .sort((a, b) => a.data.scheduleReload - b.data.scheduleReload)[0].data.scheduleReload;
    };
}
