import * as Sentry from '@sentry/react';
import { SSOSessionResponse } from '../types/AuthTypes';
import { isAuthError } from '../providers/useAuth/AuthContext';
import Api from './Api';

const STORAGE_TOKEN = 'token';

class SSO {
    private semaphore = null;

    private token: SSOSessionResponse = null;

    constructor() {
        this.token = JSON.parse(localStorage.getItem(STORAGE_TOKEN));
    }

    reset = () => {
        this.semaphore = null;
        this.token = null;
    };

    setTokenValues = (token: SSOSessionResponse) => {
        this.token = token;
        localStorage.setItem(STORAGE_TOKEN, JSON.stringify(token));
    };

    getToken = (): string => this.token?.sessionId;

    updateToken = async () => {
        if (this.semaphore != null) {
            return this.semaphore;
        }

        let resolveCb = null;
        let rejectCb = null;

        this.semaphore = new Promise<SSOSessionResponse>((resolve, reject) => {
            resolveCb = resolve;
            rejectCb = reject;
        });

        try {
            const result = await Api.getSession();
            const { response } = result;

            if (!isAuthError(result)) {
                this.setTokenValues(response);
                resolveCb(response);
                this.semaphore = null;

                return response;
            }

            resolveCb(null);
            this.semaphore = null;

            return response;
        } catch (e) {
            Sentry.captureException(e);
            rejectCb(e);
            this.semaphore = null;

            return null;
        }
    };
}

export default new SSO();
