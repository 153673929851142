import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { Breakpoints, MQ, MQ_NOT_TOUCH } from '../../../style/styled-components/cssMediaQueries';
import { fontBold, fontNormal, levelingZIndex } from '../../../style/styled-components/cssGlobalVariables';

export const cardCircleImageWidthHeightMobile = 104;
export const cardCircleImageWidthHeightTablet = 132;
export const cardCircleImageWidthHeightDesktop = 192;

export const CircleCardImageContainer = styled.div`
    overflow: hidden;
    background-color: ${props => props.theme.brandedConfig.low_contrast};
    border-radius: 100%;
    width: 100%;
    height: ${cardCircleImageWidthHeightMobile}px;
    vertical-align: middle;
    transition: all 0.25s ease-in-out;
    opacity: 0.9;

    ${MQ(Breakpoints.s)} {
        height: ${cardCircleImageWidthHeightTablet}px;
    }

    ${MQ(Breakpoints.m)} {
        height: ${cardCircleImageWidthHeightDesktop}px;
    }

    img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        object-position: top center;
    }
`;

export const CircleCardTitleWrapper = styled.div`
    text-align: center;
`;

export const CircleCardTitle = styled.div`
    display: inline-block;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-family: ${fontBold};
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    max-width: ${cardCircleImageWidthHeightMobile}px;

    ${MQ(Breakpoints.s)} {
        max-width: ${cardCircleImageWidthHeightTablet}px;
    }

    ${MQ(Breakpoints.m)} {
        max-width: ${cardCircleImageWidthHeightDesktop}px;
    }

    .SVGInline {
        display: inline-block;
        float: left;
        width: 12px;
        margin-right: 6px;

        svg {
            width: 100%;
            height: auto;
        }
    }
`;

export const CircleCardSubtitle = styled.div`
    font-family: ${fontNormal};
    font-size: 16px;
    line-height: 24px;
`;

export const CircleCardWrapper = styled.div`
    position: relative;
    z-index: ${levelingZIndex};
    width: ${cardCircleImageWidthHeightMobile}px;

    ${MQ(Breakpoints.s)} {
        width: ${cardCircleImageWidthHeightTablet}px;
    }

    ${MQ(Breakpoints.m)} {
        width: ${cardCircleImageWidthHeightDesktop}px;
    }

    ${MQ_NOT_TOUCH} {
        &:hover {
            ${CircleCardImageContainer} {
                box-shadow: 0 0 0 2px ${props => props.theme.brandedConfig.white_color};
                transform: scale(1.1);
                opacity: 1;
            }
        }
    }
`;

export const InitialsFallback = styled.div`
    font-size: 48px;
    line-height: 40px;
    text-align: center;
    margin-top: calc(50% - 20px);

    ${MQ(Breakpoints.m)} {
        font-size: 64px;
    }
`;
