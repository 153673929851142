import React from 'react';
import { LoaderContainer, LoaderIcon, LoaderOverlay, LoaderText } from './LoadingIndicator.css';

export const LoadingIndicator: React.FC<{
    isLoading?: boolean;
    overlay?: boolean;
    minHeight?: string;
    verticalTranslate?: string;
    hasBackgroundColor?: boolean;
    backgroundOverwrite?: string;
    loaderHint?: string;
}> = ({
    isLoading = true,
    overlay = false,
    hasBackgroundColor = true,
    backgroundOverwrite,
    minHeight,
    verticalTranslate,
    children,
    loaderHint,
    ...rest
}) => {
    if (isLoading) {
        if (overlay) {
            return (
                <LoaderOverlay hasBackgroundColor={hasBackgroundColor} backgroundOverwrite={backgroundOverwrite}>
                    <LoaderIcon {...rest} />
                    {loaderHint && <LoaderText>{loaderHint}</LoaderText>}
                </LoaderOverlay>
            );
        }

        return (
            <LoaderContainer minHeight={minHeight} verticalTranslate={verticalTranslate}>
                <LoaderIcon {...rest} />
            </LoaderContainer>
        );
    }

    return <>{children}</>;
};
