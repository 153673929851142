import { useEffect, useState } from 'react';
import { matchPath } from 'react-router';
import { useWrapLocation } from './useWrapRouter/useWrapLocation';
import { collectionPageSectionRouting } from '../utils/fnRouting';
import { StaticPageRoutes } from '../types/RouteTypes';

export type CollectionLocationParams = {
    section?: string;
    sectionId?: string;
    id?: string;
    foreignId?: string;
};

export type CollectionLocationParamsPath = {
    params?: CollectionLocationParams;
    isCollectionPage: boolean;
    pathname: string;
};

export const useCollectionLocationParams = (): CollectionLocationParamsPath => {
    const { pathname } = useWrapLocation();
    const [params, setParams] = useState<CollectionLocationParams>();
    const [isCollectionPage, setIsCollectionPage] = useState(true);

    const routeSchemas = [];

    collectionPageSectionRouting.forEach(sectionConfig => {
        routeSchemas.push(`${sectionConfig}${StaticPageRoutes.REPEATER_COLLECTION}`);
        routeSchemas.push(`${sectionConfig}${StaticPageRoutes.COLLECTION}`);
    });

    useEffect(() => {
        let match = null;
        routeSchemas.forEach(route => {
            const matchByRoute = matchPath(pathname, {
                path: route,
            });

            if (matchByRoute) {
                match = matchByRoute;
            }
        });

        setIsCollectionPage(match != null);

        setParams(match?.params ?? {});
    }, [pathname]);

    return {
        params,
        pathname,
        isCollectionPage,
    };
};
