import React, { FC } from 'react';
import { isMQMobile, isMQTablet } from 'style/styled-components/cssMediaQueries';
import { CardWrapper } from '../../Lane/Lane.css';
import { cardCircleImageWidthHeightDesktop, cardCircleImageWidthHeightMobile, cardCircleImageWidthHeightTablet } from './CardCircle.css';
import { LoadingRectangle } from '../../../style/styled-components/reusable.css';

export const CardCircleLoading: FC = () => {
    const getCardSize = () => {
        if (isMQMobile()) {
            return cardCircleImageWidthHeightMobile;
        }

        if (isMQTablet()) {
            return cardCircleImageWidthHeightTablet;
        }

        return cardCircleImageWidthHeightDesktop;
    };

    return (
        <CardWrapper style={{ borderRadius: '50%', overflow: 'hidden' }} width={getCardSize()} height={getCardSize()}>
            <LoadingRectangle />
        </CardWrapper>
    );
};
