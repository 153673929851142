import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDataFetcher } from '../../hooks/useDataFetcher/useDataFetcher';
import Api from '../../api/Api';
import { useConfig } from '../useConfig/ConfigContext';
import { useEmptyDynamicCache } from '../useCollection/CollectionContext';
import { stripeAction } from '../reducers/stripe-reducer';
import { FetchParamById } from '../../types/ApiTypes';
import { Callback, MyWorldCategory, MyWorldItem, MyWorldRootCategory, WorldSectionType } from '../../types/World';
import { useAuth } from '../useAuth/AuthContext';
import { useGlobalNetworkError, useGlobalNoInternet } from '../../hooks/withNetworkCheck/withNetworkCheck';

type ToggleWorldParams = {
    rootId: string;
    action: 'add' | 'remove';
    worldItem: MyWorldItem;
};

const useWorldService = () => {
    const { onNoInternet, noInternet } = useGlobalNoInternet();
    const { onNetworkError } = useGlobalNetworkError();

    const { response: rootIdResponse, loading: rootIdIsLoading, fetcher: fetchRootId, error: rootIdError } = useDataFetcher<
        MyWorldRootCategory,
        any
    >(() => Api.getWorldsRootId(), onNoInternet, onNetworkError);

    const { response: worldItemsResponse, loading: worldItemsIsLoading, fetcher: fetchWorldItems, error: itemsError } = useDataFetcher<
        MyWorldItem[],
        FetchParamById
    >(param => Api.getMyWorldItems(param.id), onNoInternet, onNetworkError);

    const { response: toggleWorldResponse, loading: toggleIsLoading, fetcher: toggleWorld, reset: resetManage } = useDataFetcher<
        MyWorldItem[],
        ToggleWorldParams
    >(param => Api.manageWorldItem(param.rootId, param.action, param.worldItem), onNoInternet, onNetworkError);

    const {
        response: topCategoriesResponse,
        loading: topCategoriesLoading,
        fetcher: fetchTopCategories,
        error: topCategoriesError,
    } = useDataFetcher<any, FetchParamById>(param => Api.getMyWorldTopCategories(param.id), onNoInternet, onNetworkError);

    const {
        response: childCategoryResponse,
        loading: childCategoryLoading,
        fetcher: fetchChildCategory,
        error: childCategoryError,
    } = useDataFetcher<MyWorldCategory, FetchParamById>(param => Api.fetchMyWorldSubCategories(param.id), onNoInternet, onNetworkError);

    const [rootId, setRootId] = useState<MyWorldRootCategory>(null);
    const [worldItems, setWorldItems] = useState<MyWorldItem[]>(null);
    const [topCategories, setTopCategories] = useState(null);
    const [childCategories, setChildCategories] = useState<{ [key: string]: MyWorldCategory }>(null);

    const topCategoriesRef = useRef(null);
    const childCategoriesRef = useRef<{ [key: string]: MyWorldCategory }>(null);

    const { config } = useConfig();
    const { isGuest, isLoggedIn } = useAuth();

    useEffect(() => {
        if (config && isLoggedIn) {
            if (!noInternet) {
                if (!isGuest && !rootId && !rootIdIsLoading && !rootIdError) {
                    fetchRootId(null);
                }

                if (isGuest && !rootId && !rootIdIsLoading) {
                    setWorldItems([]);
                }
            }
        }
    }, [config, isLoggedIn, isGuest, rootId, rootIdIsLoading, noInternet]);

    useEffect(() => {
        if (rootIdResponse) {
            setRootId(rootIdResponse);
        }
    }, [rootIdResponse]);

    useEffect(() => {
        if (topCategoriesResponse) {
            setTopCategories(topCategoriesResponse);
            topCategoriesRef.current = topCategoriesResponse;
        }
    }, [topCategoriesResponse]);

    useEffect(() => {
        if (childCategoryResponse) {
            setChildCategories({
                ...childCategories,
                ...{ [childCategoryResponse?.id]: childCategoryResponse },
            });
        }
    }, [childCategoryResponse]);

    useEffect(() => {
        childCategoriesRef.current = childCategories;
    }, [childCategories]);

    useEffect(() => {
        if (rootId && !worldItems && !worldItemsIsLoading && !itemsError && !noInternet) {
            fetchWorldItems({
                id: rootId.id,
            });
        }
    }, [rootId, noInternet]);

    useEffect(() => {
        if (worldItemsResponse) {
            setWorldItems(worldItemsResponse);
        }
    }, [worldItemsResponse]);

    useEffect(() => {
        if (toggleWorldResponse) {
            setWorldItems(toggleWorldResponse);
            setChildCategories(null);
            resetManage();
        }
    }, [toggleWorldResponse]);

    const isWorldItem = (valueToCheck: string, type: WorldSectionType): boolean =>
        !isGuest && worldItems?.find(item => item.value === valueToCheck && item.groupType === type) != null;

    const toggleWorldItem = (value: string, sectionType: WorldSectionType) => {
        let worldItem: MyWorldItem = worldItems?.find(item => item.value === value && item.groupType === sectionType);
        const action = worldItem != null ? 'remove' : 'add';

        if (!worldItem) {
            worldItem = {
                value,
                groupType: sectionType,
                groupName: null,
                name: null,
            };
        }

        toggleWorld({
            rootId: rootId.id,
            action,
            worldItem,
        });
    };

    const getTopCategories = () => {
        if (!isGuest && rootId && !topCategories && !topCategoriesLoading && !topCategoriesError && !noInternet) {
            fetchTopCategories({
                id: rootId.id,
            });
        }

        return topCategories;
    };

    const getSubCategory = (subCategoryId: string): MyWorldCategory => {
        if (
            !isGuest &&
            (!childCategories || !childCategories[subCategoryId]) &&
            !childCategoryError &&
            !childCategoryLoading &&
            !noInternet
        ) {
            fetchChildCategory({ id: subCategoryId });
        }

        return childCategories?.[subCategoryId] ?? null;
    };

    const resetWorld = () => {
        setRootId(null);
        setTopCategories(null);
        setChildCategories(null);
    };

    return {
        isWorldItem,
        toggleWorldItem,
        worldItems,
        manageInAction: toggleIsLoading,
        worldInitialized: rootId != null && worldItems != null,
        getTopCategories,
        topCategoriesLoading,
        topCategories,
        topCategoriesRef: topCategoriesRef.current,
        getSubCategory,
        childCategoryLoading,
        childCategories,
        childCategoriesRef: childCategoriesRef.current,
        resetWorld,
    };
};

export const WorldContext = createContext<ReturnType<typeof useWorldService>>(null);

export const WorldProvider = ({ children }) => {
    const context = useWorldService();

    return <WorldContext.Provider value={context}>{children}</WorldContext.Provider>;
};

export const useWorld = () => useContext(WorldContext);

export const useWorldContentChangeObserver = () => {
    const dispatch = useDispatch();
    const emptyDynamicCollectionCache = useEmptyDynamicCache();
    const { worldItems } = useWorld();

    useEffect(() => {
        if (worldItems) {
            dispatch(stripeAction('EMPTY_DYNAMIC', null));
            emptyDynamicCollectionCache();
        }
    }, [worldItems]);
};

/**
 * Utility hook to fetch fist world related content and then execute post fetching requests with the world data
 *
 */
export const useFetchOnWorldContent = () => {
    const {
        topCategories,
        getTopCategories,
        childCategories,
        getSubCategory,
        topCategoriesLoading,
        childCategoryLoading,
        worldInitialized,
    } = useWorld();

    const [postLoadFetcher, setPostLoadFetcher] = useState<Callback>(null);
    const [sectionId, setSectionId] = useState<string>();
    const [subCategoryId, setSubCategoryId] = useState<string>();

    // use for movies / series my world sub category
    const fetchWithTopAndChildCategory = (childCategoryId: string, callback: Callback) => {
        setPostLoadFetcher(callback);
        setSectionId(childCategoryId);

        getTopCategories();
    };

    // use for actor / director collection pages
    const fetchWithChildCategory = (childCategoryId: string, callback: Callback) => {
        setPostLoadFetcher(callback);
        setSubCategoryId(childCategoryId);

        getSubCategory(childCategoryId);
    };

    useEffect(() => {
        if (topCategories && (sectionId != null || postLoadFetcher != null)) {
            if (sectionId) {
                // should fetch sub category content so movies or series
                setSubCategoryId(topCategories[sectionId]);
                getSubCategory(topCategories[sectionId]);
            } else if (postLoadFetcher) {
                postLoadFetcher.onReady(null, topCategories);
            }
        }
    }, [topCategories, sectionId, postLoadFetcher]);

    useEffect(() => {
        if (childCategories && subCategoryId && childCategories?.[subCategoryId] && postLoadFetcher) {
            postLoadFetcher.onReady(childCategories[subCategoryId], null);
        }
    }, [childCategories, subCategoryId, postLoadFetcher]);

    return {
        fetchWithChildCategory,
        fetchWithTopAndChildCategory,
        loading: topCategoriesLoading || childCategoryLoading,
        canFetch: worldInitialized,
    };
};
