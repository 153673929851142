import React, { FC } from 'react';
// eslint-disable-next-line no-restricted-imports
import baseStyled, { ThemedStyledInterface, ThemeProvider } from 'styled-components';
import { useConfig } from '../../providers/useConfig/ConfigContext';
import { LayoutConfig } from '../../types/Config';
import { LAYOUT_CONFIG_DEFAULT } from '../../providers/useConfig/DefaultConfigs';
import { GlobalStyle } from '../../style/styled-components/cssGlobalStyles';
import { usePageUtils } from '../../providers/useApp/AppContext';

type Theme = {
    globalConfig: LayoutConfig;
    brandedConfig: LayoutConfig;
};
export const styled = baseStyled as ThemedStyledInterface<Theme>;

export const ThemeConfigConnector: FC = ({ children }) => {
    const { config } = useConfig();
    const { pageConfig } = usePageUtils();

    const theme: { globalConfig: LayoutConfig; brandedConfig: LayoutConfig } = {
        globalConfig: config?.layout_config ?? LAYOUT_CONFIG_DEFAULT,
        brandedConfig: {
            ...(config?.layout_config ?? LAYOUT_CONFIG_DEFAULT),
            ...(pageConfig?.layout_config ?? config?.layout_config ?? LAYOUT_CONFIG_DEFAULT),
        },
    };

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle theme={theme} />
            {children}
        </ThemeProvider>
    );
};
