import { TVAssetEntitlementState } from '../../types/Entitlement';
import { useAuth } from '../../providers/useAuth/AuthContext';
import { MILLISECONDS } from '../../utils/TimeUnit';
import { isFuture, isNow, isPast } from '../../utils/fnDate';
import { getReplayWindowLength, getReplayWindowLengthByProduct } from '../../utils/fnData';
import { useConfig } from '../../providers/useConfig/ConfigContext';
import { AiringTime } from '../../types/CommonTypes';

export const useTVAssetEntitlementState = () => {
    const { getReplayWindowTimeFrame } = useAuth();
    const { product_code_time_machine_plus } = useConfig().config?.app_config?.product_settings ?? {};

    return (input: AiringTime, channelHasReplay: boolean, isChannelSubscribed: boolean): TVAssetEntitlementState => {
        if (input == null) {
            return null;
        }

        // no option, button for the future assets and the past ones which don't have channel replay
        if (!channelHasReplay && !isNow(input.startTime, input.endTime)) { // isFuture(input.startTime) ||
            return null;
        }

        // the channel should be subscribed
        if (!isChannelSubscribed) {
            return TVAssetEntitlementState.SHOULD_SUBSCRIBE_CHANNEL;
        }

        // if the channel is subscribed the live can be watched
        if (isNow(input.startTime, input.endTime)) {
            return TVAssetEntitlementState.ENTITLED;
        }

        // get number of day diff so can decide which replay product should be entitled or if the asset is entitled
        const dayDiff = MILLISECONDS.toDays(Date.now() - input.startTime);

        // if the program is out of max replay window omit state
        if (dayDiff > getReplayWindowLength()) {
            return null;
        }

        // check entitled replay window is less then required
        if (dayDiff > getReplayWindowTimeFrame()) {
            // if the user has no replay product entitled should entitle the basic (TimeMachine)
            if (dayDiff < getReplayWindowLengthByProduct(product_code_time_machine_plus)) {
                return TVAssetEntitlementState.SHOULD_BUY_BASIC_REPLAY_WINDOW;
            }

            // if the user has no replay product entitled should entitle the basic (TimeMachinePlus)
            return TVAssetEntitlementState.SHOULD_BUY_EXTENDED_REPLAY_WINDOW;
        }

        // if the same thing has aired in the past
        if (isFuture(input.startTime) && input.playOptions?.options?.some(option => isPast(option.startTime))) {
            return TVAssetEntitlementState.ENTITLED;
        }

        return TVAssetEntitlementState.ENTITLED;
    };
};
