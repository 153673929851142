import React, { FC } from 'react';
import { isAndroid, isIOS, isMobile, isTablet } from 'react-device-detect';
import { usePlayer } from 'providers/player/PlayerContext';
import { getMiniPlayerProps } from 'utils/fnData';
import { PlayingAssetType } from 'types/Player';
import { useHistory } from 'react-router';
import { ProgressIndicator } from 'components/ProgressIndicator/ProgressIndicator';
import { MILLISECONDS } from 'utils/TimeUnit';
import { Broadcast, NowAndNext } from '../../../types/Asset';
import { getLivePlayerURL, getNowAndNextDetailsURL } from '../../../utils/fnUrl';
import { CardCollectionMixed } from '../../Cards/CardCollection/CardCollectionMixed';
import { LiveProgressIndicator } from '../../LiveProgressIndicator/LiveProgressIndicator';
import { useDetailLocationParams } from '../../../hooks/useDetailLocationParams';
import { useAuth } from '../../../providers/useAuth/AuthContext';
import { StandardCover } from '../../../types/Cover';
import { isTVEpisodeBroadcast } from '../../../utils/fnTypeGuards';
import { CardTrackingData } from '../../../types/Tracking';
import { trackingClickHandler } from '../../../utils/fnTracking';
import { useTracking } from '../../../providers/useTracking/TrackingContext';
import { getNowAndNextCardContentMarkerValue } from '../../../utils/fnContentMarker';
import { CardContext } from '../../../types/Common';
import { CardLandscape } from '../../Cards/CardLandscape/CardLandscape';
import { useChannels } from '../../../providers/useChannels/useChannels';
import { useApp } from '../../../providers/useApp/AppContext';

export const NowAndNextCardPresenter: FC<{
    asset: NowAndNext;
    trackingData: CardTrackingData;
    cardContext: CardContext;
    onClick?: (event: React.MouseEvent<any>, data: Broadcast) => void;
}> = ({ asset, cardContext, trackingData, onClick }) => {
    const history = useHistory();
    const locationParamsPath = useDetailLocationParams();
    const { getChannelById } = useChannels();
    const channel = getChannelById(asset.channelId);
    const { isGuest } = useAuth();
    const { isMini, updateMiniPlayerProps } = usePlayer();
    const track = useTracking();
    const { appLanguage } = useApp();

    const progress = (asset.bookmark * 100) / MILLISECONDS.toSeconds(asset.endTime - asset.startTime);

    const cover: StandardCover = {
        uuid: asset.id + asset.channelId,
        id: asset.id,
        title: asset.title,
        image: asset.image,
        landscapeImage: asset.image,
        channelImage: asset.channelLogo,
        metaData: asset.nextBroadcastTitle,
        path: getNowAndNextDetailsURL(asset, locationParamsPath, appLanguage),
        circle: false,
        landscape: isTVEpisodeBroadcast(asset),
        assetType: asset.type,
        rawData: asset,
        contentMarker: getNowAndNextCardContentMarkerValue(asset.isLive),
        progressBar:
            progress > 0 ? (
                <ProgressIndicator percentage={progress} />
            ) : (
                <LiveProgressIndicator startTime={asset.startTime} endTime={asset.endTime} />
            ),
        renderPlayIcon: !isGuest && (channel?.subscription ?? asset.isChannelSubscribed),
        defaultRenderSecondLine: isAndroid || isIOS || isTablet || isMobile,
        onPlayIconClicked: event => {
            event.preventDefault();
            event.stopPropagation();

            trackingClickHandler(
                event,
                () =>
                    isMini
                        ? updateMiniPlayerProps(getMiniPlayerProps(asset, PlayingAssetType.LIVE))
                        : history.replace(`${getLivePlayerURL(asset.channelId)}${progress > 0 ? '' : '?live=true'}`),
                {
                    ...trackingData,
                    ...{
                        'data-track-id': 'image_button_play',
                    },
                },
                track
            );
        },
        onClick,
    };

    if (cardContext === CardContext.STRIPE) {
        return <CardLandscape asset={cover} />;
    }

    return <CardCollectionMixed asset={cover} {...trackingData} />;
};
