import SVGInline from 'react-svg-inline';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { hexToRgba } from '../../utils/fnColors';

export const VolumeControlContainer = styled.div`
    transition: opacity 0.2s ease-in-out;
    margin: 9px auto;
    position: relative;
`;

export const VolumeSubAddButton = styled.a`
    width: 24px;
    height: 24px;
    display: inline-block;
    position: absolute;
    top: 0;
`;

export const VolumeAddSubIcon = styled(SVGInline)`
    width: 100%;
    height: 100%;
    display: block;
`;

export const VolumeScrubber = styled.div`
    position: relative;
    width: 128px;
    margin: 0 auto;

    &:before {
        position: absolute;
        top: 10px;
        left: 0;
        content: ' ';
        width: 100%;
        height: 3px;
        border-radius: 4px;
        opacity: 0.2;
        z-index: 1;
        background-color: ${props => props.theme.brandedConfig.white_color};
    }
`;

export const VolumeValue = styled.div`
    border-radius: 4px;
    height: 3px;
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 2;
    opacity: 0.7;
    background-color: ${props => props.theme.brandedConfig.white_color};
`;

export const VolumeBar = styled.input`
    appearance: none;
    -webkit-appearance: none;
    height: 18px;
    width: 128px;
    cursor: pointer;
    margin: 3px 0 0;
    border-radius: 4px;
    background-color: transparent;
    position: relative;
    z-index: 5;

    &:focus {
        outline: none;
    }

    &::-webkit-slider-thumb {
        border-radius: 50% !important;
        outline: none !important;
        width: 20px;
        height: 20px;
        margin-top: -11px;
        -webkit-appearance: none;
        border: none;
        box-shadow: 0 5px 9px ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.2)};
        background-color: ${props => props.theme.brandedConfig.high_contrast};
    }

    &::-moz-range-thumb {
        border-radius: 50% !important;
        outline: none !important;
        width: 30px;
        height: 30px;
        margin-top: -18px;
        margin-left: -10px;
        appearance: none;
        -webkit-appearance: none;
        border: none;
        box-shadow: 0 5px 9px ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.2)};
        background-color: ${props => props.theme.brandedConfig.high_contrast};
    }

    &::-ms-thumb {
        border-radius: 50% !important;
        outline: none !important;
        width: 30px;
        height: 30px;
        margin-top: -18px;
        margin-left: -10px;
        appearance: none;
        -webkit-appearance: none;
        border: none;
        box-shadow: 0 5px 9px ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.2)};
        background-color: ${props => props.theme.brandedConfig.high_contrast};
    }

    &::-webkit-slider-runnable-track {
        height: 0;
        width: 100%;
        border: none;
        -webkit-appearance: none;
    }
`;
