import React, { FC, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { usePlayerRouter } from '../../hooks/usePlayerRouter';
import { PlayerProps, PlayingAssetType } from '../../types/Player';
import LivePlayer from '../Pages/LivePlayer/LivePlayer';
import CatchupPlayer from '../Pages/CatchupPlayer/CatchupPlayer';
import { decodeId } from '../../utils/fnUrl';
import RecordingPlayer from '../Pages/RecordingPlayer/RecordingPlayer';
import VodPlayer from '../Pages/VodPlayer/VodPlayer';
import TrailerPlayer from '../Pages/TrailerPlayer/TrailerPlayer';
import { usePlayer } from '../../providers/player/PlayerContext';
import { useNavigation } from '../../hooks/useNavigation';
import { useConfig } from '../../providers/useConfig/ConfigContext';
import { useCast } from '../../providers/cast/CastContext';

export const Player: FC<{ props?: PlayerProps; isEmbedded?: boolean }> = ({ props: inputProps, isEmbedded = false }) => {
    const {
        miniPlayerProps,
        switchToMiniPlayer,
        closeMiniPlayer,
        setIsOpen,
        streamEndReached,
        setIsEmbedded,
        isEmbedded: isEmbeddedPlayerContext,
    } = usePlayer();
    const { castConnected, mediaInfo } = useCast();
    const { params, playerType } = usePlayerRouter();
    const { location } = useNavigation();
    const { config } = useConfig();
    const [props, setProps] = useState<PlayerProps>();

    const actualProps: PlayerProps = useMemo(() => {
        if (inputProps) {
            return inputProps;
        }

        if (props) {
            return props;
        }

        return miniPlayerProps;
    }, [inputProps, props, miniPlayerProps]);

    useEffect(() => {
        const miniPlayerEnabled =
            config.isFeatureActive('mini_player') && !castConnected && !mediaInfo?.loaded && !isMobile && !streamEndReached;

        if (params && playerType) {
            setProps({
                params,
                type: playerType,
            });
        } else if (miniPlayerEnabled && props != null) {
            switchToMiniPlayer(props);
        }

        if (!miniPlayerEnabled && !playerType) {
            setProps(null);
        }
    }, [location.pathname, params, playerType, streamEndReached, isEmbeddedPlayerContext]);

    useEffect(() => {
        if (miniPlayerProps) {
            setProps(null);
        }
    }, [miniPlayerProps]);

    useEffect(() => {
        if (playerType) {
            closeMiniPlayer();
        }
    }, [playerType]);

    useEffect(() => {
        setIsOpen(miniPlayerProps != null || props != null);
    }, [miniPlayerProps, props]);

    useEffect(() => {
        if (isEmbedded) {
            setIsEmbedded(true);
        }
    }, [isEmbedded]);

    useEffect(() => {
        if (castConnected && mediaInfo?.loaded) {
            closeMiniPlayer();
        }
    }, [castConnected, mediaInfo, miniPlayerProps]);

    const type = actualProps?.type;
    const playerParams = actualProps?.params;

    if (!type || !playerParams) return null;

    switch (type) {
        case PlayingAssetType.LIVE:
            return <LivePlayer channelId={playerParams.channelId} isEmbedded={isEmbedded} />;
        case PlayingAssetType.CATCHUP:
            return <CatchupPlayer broadcastId={decodeId(playerParams.broadcastId ?? '')} isEmbedded={isEmbedded} />;
        case PlayingAssetType.RECORDING:
            return <RecordingPlayer recordingId={decodeId(playerParams.recordingId ?? '')} />;
        case PlayingAssetType.VOD:
            return <VodPlayer assetId={decodeId(playerParams.assetId ?? '')} />;
        case PlayingAssetType.TRAILER:
            return <TrailerPlayer assetId={decodeId(playerParams.assetId ?? '')} />;
        default:
            return null;
    }
};
