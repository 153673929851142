import { useRef, useState } from 'react';

/**
 * Utility hook for state management. It receives an initial state which is set internally.
 *
 * It provides a state, a stateRef, a setState and update function.
 *
 * The setState receives the new state and an optional parameter to omit or not the state update. If the second parameter
 * is set to true only the ref value will be updated but the state remain unchanged. In order to push the ref state in the
 * state which results a re-render an update() invocation is required.
 * Using the setState without secondary parameter will update the state and the ref value too.
 *
 * @param initialState - initial state value
 */
export const useForceUpdateRef = <T>(initialState: T): [T, T, (newState: T, omitUpdate?: boolean) => void, () => void] => {
    const stateRef = useRef<T>(initialState);
    const [state, updateState] = useState<T>(initialState);

    const setState = (newState: T, omitUpdate: boolean = false) => {
        stateRef.current = newState;

        if (!omitUpdate) {
            updateState(newState);
        }
    };

    const update = () => updateState(stateRef.current);

    return [state, stateRef.current, setState, update];
};
