import React, { FC } from 'react';
import { usePlayer } from 'providers/player/PlayerContext';
import { getMiniPlayerProps, isEpisodeTrailer } from 'utils/fnData';
import { PlayingAssetType } from 'types/Player';
import { useHistory } from 'react-router';
import { DetailEpisode, Episode } from '../../../types/Asset';
import { getVodPlayerURL, getVODSeriesEpisodeDetailUrl } from '../../../utils/fnUrl';
import { CardLandscape } from '../../Cards/CardLandscape/CardLandscape';
import { CardCollectionMixed } from '../../Cards/CardCollection/CardCollectionMixed';
import { useDetailLocationParams } from '../../../hooks/useDetailLocationParams';
import { VODAssetEntitlementState } from '../../../types/Entitlement';
import { useAuth } from '../../../providers/useAuth/AuthContext';
import { StandardCover } from '../../../types/Cover';
import { CardContext } from '../../../types/Common';
import { ProgressIndicator } from '../../ProgressIndicator/ProgressIndicator';
import { useConfig } from '../../../providers/useConfig/ConfigContext';
import { CardTrackingData } from '../../../types/Tracking';
import { trackingClickHandler } from '../../../utils/fnTracking';
import { useTracking } from '../../../providers/useTracking/TrackingContext';
import { getVodContentMarker } from '../../../utils/fnContentMarker';
import { useApp } from '../../../providers/useApp/AppContext';

export const VODEpisodeCardPresenter: FC<{
    asset: DetailEpisode;
    cardContext: CardContext;
    onClick?: (event: React.MouseEvent<any>, data: Episode) => void;
    trackingData: CardTrackingData;
}> = ({ asset, cardContext, onClick, trackingData }) => {
    const { id, episodeId, title, image, metaData, price, firstAvailability, vodAssetState, duration, bookmark, shortDescription } = asset;
    const locationParamsPath = useDetailLocationParams();
    const { isGuest } = useAuth();
    const { appLanguage } = useApp();
    const history = useHistory();
    const { config } = useConfig();
    const track = useTracking();
    const { isMini, updateMiniPlayerProps } = usePlayer();

    const onPlayClicked = event => {
        event.preventDefault();
        event.stopPropagation();

        trackingClickHandler(
            event,
            () =>
                isMini
                    ? updateMiniPlayerProps(getMiniPlayerProps(asset, PlayingAssetType.VOD))
                    : history.push(getVodPlayerURL(asset.episodeId)),
            {
                ...trackingData,
                ...{
                    'data-track-id': 'image_button_play',
                },
            },
            track
        );
    };

    const progress = isEpisodeTrailer(asset) ? 0 : (bookmark * 100) / duration;

    const cover: StandardCover = {
        uuid: id + episodeId,
        id,
        title,
        image,
        landscapeImage: image,
        path: getVODSeriesEpisodeDetailUrl(asset, locationParamsPath, appLanguage),
        metaData,
        circle: false,
        landscape: true,
        assetType: asset.type,
        rawData: asset,
        shortDescription,
        contentMarker: getVodContentMarker(price, firstAvailability),
        renderPlayIcon: !isGuest && vodAssetState === VODAssetEntitlementState.ENTITLED,
        isEntitled: !isGuest && vodAssetState === VODAssetEntitlementState.ENTITLED,
        progressBar: <ProgressIndicator percentage={progress < config.app_config.player_settings.bookmark_threshold ? progress : 0} />,
        onPlayIconClicked: onPlayClicked,
        onClick,
    };

    return cardContext === CardContext.STRIPE ? (
        <CardLandscape asset={cover} showDescription={true} {...trackingData} />
    ) : (
        <CardCollectionMixed asset={cover} {...trackingData} />
    );
};
