import React, { createContext, FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useDataFetcher } from '../../hooks/useDataFetcher/useDataFetcher';
import { FetchParamById } from '../../types/ApiTypes';
import Api from '../../api/Api';
import { useGlobalNetworkError, useGlobalNoInternet } from '../../hooks/withNetworkCheck/withNetworkCheck';
import Events from '../../utils/Events';
import { RecordingEvent, RecordingRef, RecordingRelationRef, TimeClusterRecordingTypeMap } from '../../types/RecordingTypes';
import { AlertDialog } from '../../components/AlertDialog/AlertDialog';
import translate from '../../utils/fnTranslate';
import { useRecordingNotification } from '../../components/Notification/Notification';
import { TimeCluster } from '../../hooks/useBroadcastTimeCluster/useBroadcastTimeCluster';
import { useSubscription } from '../useSubscription/SubscriptionContext';
import { LoadingIndicator } from '../../components/LoadingIndicator/LoadingIndicator';
import { hexToRgba } from '../../utils/fnColors';
import { useConfig } from '../useConfig/ConfigContext';
import { stripeAction } from '../reducers/stripe-reducer';
import { useEmptyRecordingCache } from '../useCollection/CollectionContext';
import { getRecordingPlayerURL } from '../../utils/fnUrl';
import { StaticPageRoutes } from '../../types/RouteTypes';

const ERROR_CODE_STORAGE_FULL = 'EQuotaExceeded';

const useRecordingService = () => {
    const { onNoInternet } = useGlobalNoInternet();
    const { onNetworkError } = useGlobalNetworkError();
    const recordingNotification = useRecordingNotification();
    const { initSubscribeFlow } = useSubscription();

    const eventEmitter = useRef<Events>(new Events());

    const [toDelete, setToDelete] = useState<RecordingRef>(null);
    const [toAdd, setToAdd] = useState<RecordingRef>(null);
    const [error, setError] = useState<any>(null);
    const [toDeleteAll, setToDeleteAll] = useState<boolean>(false);

    const {
        response: createRecordingResponse,
        error: createRecordingError,
        loading: isRecording,
        fetcher: record,
        reset: resetRecordingApi,
    } = useDataFetcher<any, FetchParamById>(param => Api.createRecording(param.id), onNoInternet, onNetworkError);

    const {
        response: deleteRecordingResponse,
        error: deleteRecordingError,
        loading: isDeletingRecording,
        fetcher: deleteRecordingRequest,
        reset: resetDeleteRecordingApi,
    } = useDataFetcher<any, FetchParamById>(param => Api.deleteRecordingByEventId(param.id), onNoInternet, onNetworkError);

    const {
        response: deleteRecordingByIdResponse,
        error: deleteRecordingByIdError,
        loading: isDeletingRecordingById,
        fetcher: deleteRecordingByIdRequest,
        reset: resetDeleteRecordingByIdApi,
    } = useDataFetcher<any, FetchParamById>(param => Api.deleteRecording(param.id), onNoInternet, onNetworkError);

    const { response: recordingQuotaResponse, error: fetchRecordingError, fetcher: getRecordingQuotaRequest } = useDataFetcher<
        any,
        FetchParamById
    >(Api.getRecordingQuota, onNoInternet, onNetworkError);

    const {
        response: deleteAllRecordingsResponse,
        fetcher: deleteAllRecordingsRequest,
        loading: isDeletingAllrecordings,
        error: deleteAllRecordingsError,
    } = useDataFetcher<any, FetchParamById>(Api.deleteAllRecording, onNoInternet, onNetworkError);

    const reset = () => {
        resetRecordingApi();
        resetDeleteRecordingApi();
        resetDeleteRecordingByIdApi();
    };

    const addEventListener = (event: RecordingEvent, callback) => {
        eventEmitter.current.addEventListener(event, callback);
    };

    const removeEventListener = (event: RecordingEvent, callback) => {
        eventEmitter.current.removeEventListener(event, callback);
    };

    const deleteRecordingByEventId = (eventId: string, timeCluster: TimeCluster) => {
        setToDelete({
            eventId,
            type: TimeClusterRecordingTypeMap[timeCluster],
        });
    };

    const deleteRecording = (recordingId: string, timeCluster: TimeCluster) => {
        setToDelete({
            eventId: null,
            recordingId,
            type: TimeClusterRecordingTypeMap[timeCluster],
        });
    };

    const createRecording = (eventId: string, timeCluster: TimeCluster) => {
        setToAdd({
            eventId,
            type: TimeClusterRecordingTypeMap[timeCluster],
        });
    };

    const subscribe = (productCode: string) => {
        initSubscribeFlow(
            null,
            {
                code: productCode,
                type: 'Channel',
            },
            {
                onSuccess: () => {
                    eventEmitter.current.triggerEvents(RecordingEvent.MANAGE, null);
                },
            }
        );
    };

    useEffect(() => {
        if (createRecordingResponse) {
            recordingNotification(toAdd.type, 'ADD', () => {
                eventEmitter.current.triggerEvents(RecordingEvent.ADD, toAdd.eventId);
                eventEmitter.current.triggerEvents(RecordingEvent.MANAGE, toAdd.eventId);

                setToAdd(null);
            });
        }
    }, [createRecordingResponse]);

    useEffect(() => {
        if (deleteRecordingResponse || deleteRecordingByIdResponse) {
            recordingNotification(toDelete.type, 'REMOVE', () => {
                eventEmitter.current.triggerEvents(RecordingEvent.DELETE, toDelete.eventId);
                eventEmitter.current.triggerEvents(RecordingEvent.MANAGE, toDelete.eventId);

                setToDelete(null);
            });
        }
    }, [deleteRecordingResponse, deleteRecordingByIdResponse]);

    useEffect(() => {
        if (deleteAllRecordingsResponse) {
            eventEmitter.current.triggerEvents(RecordingEvent.MANAGE, null);
        }
    }, [deleteAllRecordingsResponse]);

    useEffect(() => {
        if (createRecordingError) {
            const errorCode = createRecordingError?.response?.data?.Error?.InternalError;
            if (errorCode !== ERROR_CODE_STORAGE_FULL) {
                recordingNotification(
                    toAdd.type,
                    'ADD',
                    () => {
                        setToAdd(null);
                    },
                    true
                );
            } else {
                setError(createRecordingError);
            }
        }
    }, [createRecordingError]);

    useEffect(() => {
        if (deleteRecordingByIdError || deleteRecordingError) {
            recordingNotification(
                toDelete.type,
                'REMOVE',
                () => {
                    setToDelete(null);
                },
                true
            );
        }
    }, [deleteRecordingByIdError, deleteRecordingError]);

    useEffect(() => {
        setError(deleteAllRecordingsError);
    }, [deleteAllRecordingsError]);

    useEffect(() => {
        if (toAdd) {
            record({
                id: toAdd.eventId,
            });
        }
    }, [toAdd]);

    return {
        createRecordingResponse,
        isRecording,
        record,

        recordingQuotaResponse,
        getRecordingQuotaRequest,

        deleteRecordingResponse,
        isDeletingRecording,
        deleteRecordingRequest,

        deleteAllRecordingsRequest,
        isDeletingAllrecordings,
        deleteAllRecordingsResponse,

        deleteRecordingByIdResponse,
        isDeletingRecordingById,
        deleteRecordingByIdRequest,

        toDelete,
        toDeleteAll,
        setToDeleteAll,
        setToDelete,
        deleteRecordingByEventId,
        deleteRecording,
        createRecording,

        error,
        setError,
        fetchRecordingError,

        reset,
        addEventListener,
        removeEventListener,
        subscribe,
    };
};

export const RecordingContext = createContext<ReturnType<typeof useRecordingService>>(null);

export const RecordingProvider = ({ children }) => {
    const service = useRecordingService();

    return <RecordingContext.Provider value={service}>{children}</RecordingContext.Provider>;
};

export const useRecording = () => useContext(RecordingContext);

export const useRecordingContentChangeObserver = () => {
    const dispatch = useDispatch();
    const emptyRecordingCollectionCache = useEmptyRecordingCache();
    const { addEventListener } = useRecording();

    useEffect(() => {
        addEventListener(RecordingEvent.MANAGE, () => {
            dispatch(stripeAction('EMPTY_RECORDING', null));
            emptyRecordingCollectionCache();
        });
    }, []);
};

export const DeleteRecordingConfirmation: FC = () => {
    const { toDelete, setToDelete, deleteRecordingRequest, deleteRecordingByIdRequest } = useRecording();

    const getTitle = useCallback(() => {
        switch (toDelete?.type) {
            case 'RECORDED':
                return translate('ALERT_DELETE_RECORDING_TITLE');
            case 'ONGOING':
                return translate('ALERT_STOP_RECORDING_TITLE');
            case 'SCHEDULED':
                return translate('ALERT_CANCEL_RECORDING_TITLE');
            default:
                return null;
        }
    }, [toDelete]);

    const getMessage = useCallback(() => {
        switch (toDelete?.type) {
            case 'RECORDED':
                return translate('ALERT_DELETE_RECORDING_BODY');
            case 'ONGOING':
                return translate('ALERT_STOP_RECORDING_BODY');
            case 'SCHEDULED':
                return translate('ALERT_CANCEL_RECORDING_BODY');
            default:
                return null;
        }
    }, [toDelete]);

    if (!toDelete) return null;

    const idToRemove = toDelete?.recordingId || toDelete?.eventId;
    const deleteMethod = toDelete?.recordingId ? deleteRecordingByIdRequest : deleteRecordingRequest;

    return (
        <AlertDialog
            title={getTitle()}
            bodyText={getMessage()}
            buttons={[
                {
                    text: translate('BUTTON_NO'),
                    onClick: () => setToDelete(null),
                },
                {
                    text: translate('BUTTON_YES'),
                    onClick: () => deleteMethod({ id: idToRemove }),
                },
            ]}
        />
    );
};

export const ManageRecordingLoadingOverlay = () => {
    const { isRecording, isDeletingRecording, isDeletingAllrecordings } = useRecording();
    const { config } = useConfig();

    return (
        <LoadingIndicator
            overlay={true}
            isLoading={isRecording || isDeletingRecording || isDeletingAllrecordings}
            backgroundOverwrite={hexToRgba(config.layout_config.black_color, 0.8)}
            loaderHint={isDeletingAllrecordings && translate('DELETE_ALL_RECORDINGS_HINT')}
        />
    );
};

export const CreateRecordingError = () => {
    const { error, setError } = useRecording();
    const history = useHistory();

    if (error) {
        const errorCode = error?.response?.data?.Error?.InternalError;
        const title = errorCode === ERROR_CODE_STORAGE_FULL ? 'ERR_RECORDING_STORAGE_FULL_TITLE' : 'ERR_GENERIC_TITLE';
        const bodyText = errorCode === ERROR_CODE_STORAGE_FULL ? 'ERR_RECORDING_STORAGE_FULL_BODY' : 'ERR_GENERIC_BODY';

        return (
            <AlertDialog
                title={translate(title)}
                bodyText={translate(bodyText)}
                buttons={[
                    {
                        text: translate('CLOSE_BUTTON'),
                        onClick: () => setError(null),
                    },
                    {
                        text: translate('MANAGE_RECORDINGS_BUTTON'),
                        onClick: () => {
                            history.push(StaticPageRoutes.RECORDINGS_SETTINGS);
                            setError(null);
                        },
                    },
                ]}
            />
        );
    }

    return null;
};

export const useArchivedEventWatchHandler = () => {
    const { onNoInternet } = useGlobalNoInternet();
    const { onNetworkError } = useGlobalNetworkError();
    const history = useHistory();

    const { response, error, loading, fetcher, reset } = useDataFetcher<RecordingRelationRef, FetchParamById>(
        param => Api.getRecordingByEvent(param.id),
        onNoInternet,
        onNetworkError
    );

    const loadAndPlayArchivedEvent = eventId => {
        if (!loading) {
            fetcher({ id: eventId });
        }
    };

    useEffect(() => {
        if (response) {
            reset();
            history.push(getRecordingPlayerURL(response.id, 'broadcast'));
        }
    }, [response]);

    useEffect(() => {
        if (error) {
            reset();
            throw new Error();
        }
    }, [error]);

    return { handleRecordingByEvent: loadAndPlayArchivedEvent, loadingRecordingId: loading };
};
