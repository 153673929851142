import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { Episode, Movie, MovieDetails } from '../../types/Asset';
import { PurchaseOptionConfirmation } from '../../components/PurchaseOptionConfirmation/PurchaseOptionConfirmation';
import { Offer, RentData, SubscribeOption } from '../../types/Entitlement';
import { useDataFetcher } from '../../hooks/useDataFetcher/useDataFetcher';
import Api from '../../api/Api';
import { usePersonalized } from '../../hooks/usePersonalizedVod/usePersonalized';
import { useCollectionCacheCleaner } from '../useCollection/CollectionContext';
import localConfig from '../../config/localConfig';
import { stripeAction } from '../reducers/stripe-reducer';
import { AlertDialog } from '../../components/AlertDialog/AlertDialog';
import translate from '../../utils/fnTranslate';
import { FetchParamRent } from '../../types/ApiTypes';
import { useGlobalNetworkError, useGlobalNoInternet } from '../../hooks/withNetworkCheck/withNetworkCheck';
import { PinFlowType, usePin } from '../usePin/PinContext';
import { LoadingIndicator } from '../../components/LoadingIndicator/LoadingIndicator';
import { UpsellOptionSelector } from '../../components/UpsellOptionSelector/UpsellOptionSelector';
import { getOfferTypeByTag } from '../../utils/fnEntitlement';
import { ServiceOptionCover } from '../../types/Cover';
import { isEpisode, isRentable } from '../../utils/fnTypeGuards';
import { useSubscription } from '../useSubscription/SubscriptionContext';
import { SubscriptionConfirmation } from '../../components/SubscriptionConfirmation/SubscriptionConfirmation';
import { useElementInteractionTracking } from '../useTracking/TrackingContext';
import { Rentable } from '../../types/CommonTypes';
import { useSubscriptionSuccessNotification, useTransactionSuccessNotification } from '../../components/Notification/Notification';
import { buildRentalPeriod } from '../../utils/fnDate';

export enum PurchaseFlowStep {
    SELECT_OFFER = 'select_offer',
    CONFIRM_PURCHASE_OFFER = 'confirm_purchase_offer',
    CONFIRM_SUBSCRIPTION_OFFER = 'confirm_subscription_offer',
    ENTER_PIN = 'enter_pin',
    ERROR = 'renting_subscribe_error',
}

const usePurchasingService = () => {
    const [asset, setAsset] = useState<Episode | Movie | MovieDetails>(null);
    const [step, setStep] = useState<PurchaseFlowStep>();
    const [purchaseType, setPurchaseType] = useState<'rent' | 'buy'>(null);
    const [playPath, setPlayPath] = useState<string>(null);

    const [selectedOffer, setSelectedOffer] = useState<Rentable | SubscribeOption>(null);
    const [selectedRentOffer, setSelectedPurchaseOffer] = useState<RentData>(null);

    const history = useHistory();
    const { updatePersonalizedInfo } = usePersonalized();
    const clearCollectionCache = useCollectionCacheCleaner();
    const { onNoInternet } = useGlobalNoInternet();
    const { onNetworkError } = useGlobalNetworkError();
    const { openPinDialog, closePinDialog } = usePin();

    const dispatch = useDispatch();
    const confirmTransactionSuccess = useTransactionSuccessNotification();
    const confirmSubscriptionSuccess = useSubscriptionSuccessNotification();

    const { error, response, loading, fetcher: purchaseVod, reset } = useDataFetcher<any, FetchParamRent>(
        params => Api.purchaseVod(params.productId, params.offerId),
        onNoInternet,
        onNetworkError
    );

    const {
        fetchSubscriptionOffers,
        subscriptionOffersLoading,
        subscriptionOffers,
        subscriptionOfferError,
        resetSubscriptionOffers,

        orderResponse,
        orderError,
        isOrdering,
        subscribe,
        resetOrder,
    } = useSubscription();

    const initPurchaseFlow = (playerUrl: string, assetToPurchase: Episode | Movie | MovieDetails) => {
        setAsset(assetToPurchase);
        setPlayPath(playerUrl);

        if (assetToPurchase.subscribeOffer) {
            fetchSubscriptionOffers({
                type: 'Product',
                code: assetToPurchase.subscribeOffer.productCode,
            });
        }

        const { rentOffers, buyOffers, subscribeOffer } = assetToPurchase;
        let initStep = PurchaseFlowStep.SELECT_OFFER;

        if (!subscribeOffer) {
            if (rentOffers.length && !buyOffers.length) {
                initStep = PurchaseFlowStep.CONFIRM_PURCHASE_OFFER;
                setPurchaseType('rent');
            } else if (buyOffers.length && !rentOffers.length) {
                initStep = PurchaseFlowStep.CONFIRM_PURCHASE_OFFER;
                setPurchaseType('buy');
            }
        }

        setStep(initStep);
    };

    const goToConfirmation = (flowStep: PurchaseFlowStep) => {
        if (selectedOffer && isRentable(selectedOffer)) {
            const { rentOffers, buyOffers } = selectedOffer;

            if (rentOffers.length && !buyOffers.length) {
                setPurchaseType('rent');
            } else if (buyOffers.length && !rentOffers.length) {
                setPurchaseType('buy');
            }
        }
        setStep(flowStep);
    };

    const goToEnterPin = () => {
        setStep(PurchaseFlowStep.ENTER_PIN);
    };

    const exitFlow = () => {
        setAsset(null);
        setPlayPath(null);
        setSelectedOffer(null);
        setSelectedPurchaseOffer(null);
        setStep(null);
        setPurchaseType(null);
        resetSubscriptionOffers();
        reset();
        resetOrder();

        closePinDialog(false);
    };

    const onPinValidated = () => {
        if (selectedRentOffer) {
            purchaseVod(selectedRentOffer);
        }

        if (selectedOffer && !isRentable(selectedOffer)) {
            subscribe(selectedOffer);
        }
    };

    const onPinDialogClosed = () => {
        exitFlow();
    };

    const clearPurchasedItemsCaches = () => {
        clearCollectionCache(localConfig.data.rentCollectionCacheKey);
        dispatch(stripeAction('CLEAR', { key: localConfig.data.rentCollectionCacheKey }));
    };

    const onPurchaseSuccessful = () => {
        confirmTransactionSuccess(() => {
            updatePersonalizedInfo(asset.id);
            clearPurchasedItemsCaches();

            setTimeout(() => {
                history.push(playPath);
            }, 50);
        });
    };

    const onSubscribeSuccessful = () => {
        confirmSubscriptionSuccess(() => {
            updatePersonalizedInfo(asset.id);

            setTimeout(() => {
                history.push(playPath);
            }, 50);
        });
    };

    const selectOffer = (offer: Rentable | SubscribeOption) => {
        setSelectedOffer(offer);
    };

    const selectPurchaseOffer = (offer: Offer) => {
        setSelectedPurchaseOffer({
            offerId: offer.offerId,
            productId: offer.productId,
        });
        setStep(PurchaseFlowStep.ENTER_PIN);
    };

    useEffect(() => {
        if (selectedOffer) {
            goToConfirmation(
                isRentable(selectedOffer) ? PurchaseFlowStep.CONFIRM_PURCHASE_OFFER : PurchaseFlowStep.CONFIRM_SUBSCRIPTION_OFFER
            );
        }
    }, [selectedOffer]);

    useEffect(() => {
        if (subscriptionOffers) {
            if (asset) {
                const { rentOffers, buyOffers } = asset;

                // if there are no purchase options but there is one subscription offer -> go to subscription confirmation
                if (!rentOffers.length && !buyOffers.length && subscriptionOffers.length === 1) {
                    setSelectedOffer(subscriptionOffers[0]);
                    goToConfirmation(PurchaseFlowStep.CONFIRM_SUBSCRIPTION_OFFER);
                    return;
                }

                // if there are rent offers but no buy or subscription -> go to rent confirmation
                if (rentOffers.length && !buyOffers.length && !subscriptionOffers.length) {
                    goToConfirmation(PurchaseFlowStep.CONFIRM_PURCHASE_OFFER);
                    return;
                }

                // if there are buy offers but no rent or subscription -> go to buy confirmation
                if (buyOffers.length && !rentOffers.length && !subscriptionOffers.length) {
                    goToConfirmation(PurchaseFlowStep.CONFIRM_PURCHASE_OFFER);
                }
            }
        }
    }, [subscriptionOffers]);

    useEffect(() => {
        if (selectedRentOffer) {
            goToEnterPin();
        }
    }, [selectedRentOffer]);

    useEffect(() => {
        if (response && asset) {
            onPurchaseSuccessful();
        }
    }, [response]);

    useEffect(() => {
        if (orderResponse && asset) {
            onSubscribeSuccessful();
        }
    }, [orderResponse]);

    useEffect(() => {
        if (step && (error || orderError)) {
            setStep(PurchaseFlowStep.ERROR);
        }
    }, [error, orderError]);

    useEffect(() => {
        if (step && step === PurchaseFlowStep.SELECT_OFFER) {
            setSelectedOffer(null);
        }

        if (step && (step === PurchaseFlowStep.CONFIRM_PURCHASE_OFFER || step === PurchaseFlowStep.CONFIRM_SUBSCRIPTION_OFFER)) {
            closePinDialog(false);
        }

        if (step && step === PurchaseFlowStep.ENTER_PIN) {
            openPinDialog(PinFlowType.CHECK, {
                onPinValidated,
                onDialogClosed: onPinDialogClosed,
            });
        }

        if (step === PurchaseFlowStep.ERROR) {
            closePinDialog(false);
        }
    }, [step]);

    return {
        pendingFlow: asset != null,
        isBuyFlow: purchaseType === 'buy',
        purchaseError: error,
        purchasing: loading,
        ordering: isOrdering,
        orderError,
        step,
        asset,
        subscriptionOffersLoading,
        subscriptionOffers,
        subscriptionOfferError,
        resetSubscriptionOffers,
        initPurchaseFlow,
        cancel: () => {
            exitFlow();
        },
        selectPurchaseOffer,
        selectOffer,
        resetApi: () => {
            // reset renting api
            reset();

            // reset subscribe api
            resetOrder();
        },

        selectedOffer,
        goToEnterPin,
    };
};

export const PurchaseContext = createContext<ReturnType<typeof usePurchasingService>>(null);

export const PurchaseProvider = ({ children }) => {
    const service = usePurchasingService();

    return <PurchaseContext.Provider value={service}>{children}</PurchaseContext.Provider>;
};

export const usePurchase = () => useContext(PurchaseContext);

export const usePurchaseFlowScreenProvider = () => {
    const {
        pendingFlow,
        asset,
        step,
        purchaseError,
        orderError,
        ordering,
        subscriptionOffers,
        subscriptionOffersLoading,
        subscriptionOfferError,
        selectedOffer,
        cancel,
        selectOffer,
        goToEnterPin,
    } = usePurchase();

    const interactionTracking = useElementInteractionTracking();

    const ERROR_CODE_RENTAL_LIMIT = 498;

    const getSelectOfferScreen = () => {
        if (step && asset.subscribeOffer && !subscriptionOfferError && (subscriptionOffersLoading || !subscriptionOffers)) {
            return <LoadingIndicator overlay={true} />;
        }

        const options: ServiceOptionCover[] = [];
        const { rentOffers, buyOffers } = asset;

        // renting option
        if (rentOffers.length) {
            const minPrice = rentOffers.sort((a, b) => b.offerValue - a.offerValue)?.[0]?.offerValue;

            options.push({
                id: asset?.id,
                image: asset?.image,
                duration: `${buildRentalPeriod(asset?.rentOffers?.[0]?.rentalPeriodInMinutes ?? 0)}h`,
                price: minPrice,
                currency: rentOffers[0].currency,
                title: asset.title,
                optionTypeLabel: translate(isEpisode(asset) ? 'TYPE_EPISODE' : 'TYPE_MOVIE'),
                type: isEpisode(asset) ? 'EPISODE' : 'MOVIE',
                rawData: null,
                rentableData: asset,
                onClick: () => {
                    interactionTracking({
                        'data-track-id': 'upsell_option_card',
                        'data-page-name': 'UpsellSelect',
                        'data-type': 'rent',
                        'data-asset-id': asset.id,
                        'data-asset-name': asset.title,
                    });
                    selectOffer({
                        ...asset,
                        ...{
                            buyOffers: [],
                        },
                    });
                },
            });
        }

        // buying option
        if (buyOffers.length) {
            const minPrice = buyOffers.sort((a, b) => b.offerValue - a.offerValue)?.[0]?.offerValue;

            options.push({
                id: asset?.id,
                image: asset?.image,
                duration: null,
                price: minPrice,
                currency: buyOffers[0].currency,
                title: asset.title,
                optionTypeLabel: translate(isEpisode(asset) ? 'TYPE_EPISODE' : 'TYPE_MOVIE'),
                type: isEpisode(asset) ? 'EPISODE' : 'MOVIE',
                rawData: null,
                rentableData: asset,
                onClick: () => {
                    interactionTracking({
                        'data-track-id': 'upsell_option_card',
                        'data-page-name': 'UpsellSelect',
                        'data-type': 'buy',
                        'data-asset-id': asset.id,
                        'data-asset-name': asset.title,
                    });
                    selectOffer({
                        ...asset,
                        ...{
                            rentOffers: [],
                        },
                    });
                },
            });
        }

        // subscribe options
        if (subscriptionOffers) {
            subscriptionOffers.forEach(offer => {
                options.push({
                    id: `${offer.productId}`,
                    title: offer.name,
                    price: offer.price,
                    currency: asset.subscribeOffer.currency,
                    optionTypeLabel: getOfferTypeByTag(offer?.tags?.[0]),
                    type: 'SUBSCRIPTION',
                    image: offer.poster,
                    duration: translate('SUBSCRIPTION_PERIOD_MONTH'),
                    rawData: offer,
                    onClick: () => {
                        interactionTracking({
                            'data-track-id': 'upsell_option_card',
                            'data-page-name': 'UpsellSelect',
                            'data-type': 'subscribe',
                            'data-asset-id': `${offer.productId}`,
                            'data-asset-name': offer.name,
                        });
                        selectOffer({
                            ...offer,
                            assetId: asset?.id,
                            packageId: asset?.subscribeOffer?.productCode,
                        });
                    },
                });
            });
        }

        return <UpsellOptionSelector onClose={cancel} options={options} />;
    };

    const getConfirmSubscriptionOfferScreen = () => {
        return (
            <SubscriptionConfirmation
                onClose={cancel}
                onConfirm={goToEnterPin}
                selectedOffer={selectedOffer as SubscribeOption}
                loading={ordering}
            />
        );
    };

    const getGenericError = () => {
        const genericError = subscriptionOfferError;

        if (genericError) {
            const code = parseInt(genericError?.response?.status, 10);

            return (
                <AlertDialog
                    title={translate('ERR_GENERIC_TITLE')}
                    bodyText={translate('ERR_GENERIC_BODY')}
                    hintText={`${translate('ERR_CODE')} ${code}`}
                    buttons={[
                        {
                            text: translate('CLOSE_BUTTON'),
                            onClick: cancel,
                        },
                    ]}
                />
            );
        }

        return null;
    };

    const getErrorAlert = () => {
        const transactionError = purchaseError || orderError;

        let title;
        let bodyText;

        if (transactionError) {
            const code = parseInt(transactionError?.response?.status, 10);

            if (purchaseError) {
                title = code === ERROR_CODE_RENTAL_LIMIT ? 'ERR_RENTAL_LIMIT_ERROR_TITLE' : 'ERR_RENTAL_GENERIC_TITLE';
                bodyText = code === ERROR_CODE_RENTAL_LIMIT ? 'ERR_RENTAL_LIMIT_ERROR_BODY' : 'ERR_RENTAL_GENERIC_BODY';
            } else {
                title = 'ERR_SUBSCRIBE_GENERIC_TITLE';
                bodyText = 'ERR_SUBSCRIBE_GENERIC_BODY';
            }

            return (
                <AlertDialog
                    title={translate(title)}
                    bodyText={translate(bodyText)}
                    hintText={`${translate('ERR_CODE')} ${code}`}
                    buttons={[
                        {
                            text: translate('OK_BUTTON'),
                            onClick: cancel,
                        },
                    ]}
                />
            );
        }

        return getGenericError();
    };

    const getScreen = () => {
        if (!step) {
            return null;
        }

        if (pendingFlow && !subscriptionOfferError) {
            if (step === PurchaseFlowStep.SELECT_OFFER) {
                return getSelectOfferScreen();
            }

            if (step === PurchaseFlowStep.CONFIRM_PURCHASE_OFFER) {
                return <PurchaseOptionConfirmation />;
            }

            if (step === PurchaseFlowStep.CONFIRM_SUBSCRIPTION_OFFER) {
                return getConfirmSubscriptionOfferScreen();
            }
        }

        return getErrorAlert();
    };

    return getScreen;
};
