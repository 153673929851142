import { fontBold } from 'style/styled-components/cssGlobalVariables';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import bingeWatchOverlay from '../../assets/images/binge-watch-overlay.png';
import { Breakpoints, MQ } from '../../style/styled-components/cssMediaQueries';
import { hexToRgba } from '../../utils/fnColors';

export const BingeWatchingContainer = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 0 16px 148px 16px;
    background: ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.6)};
    background-size: cover;

    ${MQ(Breakpoints.xs)} {
        padding-bottom: 40px;
        padding-right: 40px;
        padding-top: 66px;
        flex-direction: row;
        background: url(${bingeWatchOverlay});
        height: 240px;
        width: auto;
    }
`;

export const BingeWatchingCover = styled.div`
    width: 240px;
    height: 135px;
`;

export const Title = styled.div`
    font-size: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: ${fontBold};
    line-height: 32px;

    ${MQ(Breakpoints.xs)} {
        text-align: right;
    }
`;

export const Description = styled.div`
    font-size: 20px;
    line-height: 24px;
    margin-top: 8px;
    padding-bottom: 16px;

    ${MQ(Breakpoints.xs)} {
        text-align: right;
    }
`;

export const Buttons = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    padding: 0 16px 32px 16px;
    gap: 12px;
    display: flex;
    flex-direction: column;

    ${MQ(Breakpoints.xs)} {
        padding: 0;
        width: auto;
        flex-direction: row;
    }
`;

export const BingeWatchingDetails = styled.div`
    margin-right: 16px;
    max-width: 450px;

    ${MQ(Breakpoints.xs)} {
        position: relative;
    }
`;
