import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Broadcast, Marker } from '../../../types/Asset';
import { CardLandscape } from '../../Cards/CardLandscape/CardLandscape';
import { CardPortrait } from '../../Cards/CardPortrait/CardPortrait';
import { CardCollectionPortrait } from '../../Cards/CardCollection/CardCollectionPortrait';
import { CardCollectionMixed } from '../../Cards/CardCollection/CardCollectionMixed';
import { getBroadcastDetailsURL, getCatchupPlayerURL, getLivePlayerURL } from '../../../utils/fnUrl';
import { LiveProgressIndicator } from '../../LiveProgressIndicator/LiveProgressIndicator';
import { useAssetReplayCapable } from '../../../hooks/useAssetReplayCapable/useAssetReplayCapable';
import { useDetailLocationParams } from '../../../hooks/useDetailLocationParams';
import { useConfig } from '../../../providers/useConfig/ConfigContext';
import { isFuture, isNow } from '../../../utils/fnDate';
import { useAuth } from '../../../providers/useAuth/AuthContext';
import { StandardCover } from '../../../types/Cover';
import { CardContext, StripeTypes } from '../../../types/Common';
import { ProgressIndicator } from '../../ProgressIndicator/ProgressIndicator';
import { MILLISECONDS } from '../../../utils/TimeUnit';
import { useBroadcastTimeCluster } from '../../../hooks/useBroadcastTimeCluster/useBroadcastTimeCluster';
import { CardTrackingData } from '../../../types/Tracking';
import { trackingClickHandler } from '../../../utils/fnTracking';
import { useTracking } from '../../../providers/useTracking/TrackingContext';
import { usePlayOptionHelper } from '../../../providers/usePlayOptions/PlayOptionsContext';
import { getBroadcastCardContentMarkerValue } from '../../../utils/fnContentMarker';
import { CardCircle } from '../../Cards/CardCircle/CardCircle';
import { useChannels } from '../../../providers/useChannels/useChannels';
import { useApp } from '../../../providers/useApp/AppContext';

type AdapterPropTypes = {
    cover: StandardCover;
    cardContext: CardContext;
    type: StripeTypes;

    trackingData: CardTrackingData;
};

const BroadcastCardAdapter: FC<AdapterPropTypes> = ({ cover, cardContext, type, trackingData }) => {
    if (type === StripeTypes.PERSON || type === StripeTypes.ROUNDED) {
        return <CardCircle asset={cover} {...trackingData} />;
    }

    switch (cardContext) {
        case CardContext.STRIPE:
            if (cover.landscape && type !== StripeTypes.PORTRAIT && type !== StripeTypes.MOVIE) {
                return <CardLandscape asset={cover} {...trackingData} />;
            }

            return <CardPortrait asset={cover} stripeType={type} {...trackingData} />;
        case CardContext.COLLECTION:
            if (type === StripeTypes.MOVIE || type === StripeTypes.PORTRAIT) {
                return <CardCollectionPortrait asset={cover} {...trackingData} />;
            }

            return <CardCollectionMixed asset={cover} {...trackingData} />;
        default:
            return null;
    }
};

type PresenterPropTypes = {
    asset: Broadcast;
    type: StripeTypes;
    cardContext: CardContext;
    onClick?: (event: React.MouseEvent<any>, data: Broadcast) => void;
    trackingData: CardTrackingData;
};

export const BroadcastCardPresenter: FC<PresenterPropTypes> = ({ asset, type, cardContext, onClick, trackingData }) => {
    const history = useHistory();
    const { isGuest } = useAuth();
    const { id, title, image, startTime, endTime, metaData, isLive, bookmark, isPortrait, isChannelSubscribed, channelLogo } = asset;
    const { getChannelById } = useChannels();

    const channel = getChannelById(asset.channelId);
    const { isReplayCapable } = useAssetReplayCapable(asset, channel);
    const locationParamsPath = useDetailLocationParams();
    const { config } = useConfig();
    const { appLanguage } = useApp();

    const playOptionVerifier = usePlayOptionHelper();
    const timeCluster = useBroadcastTimeCluster(asset);
    const track = useTracking();

    const [contentMarker, setContentMarker] = useState<Marker>(null);

    const onPlayClicked = event => {
        event.preventDefault();
        event.stopPropagation();

        trackingClickHandler(
            event,
            () => {
                if (timeCluster === 'LIVE') {
                    history.push(getLivePlayerURL(asset.channelId));
                } else {
                    playOptionVerifier(asset, getCatchupPlayerURL(asset.id));
                }
            },
            {
                ...trackingData,
                ...{
                    'data-track-id': 'image_button_play',
                },
            },
            track
        );
    };

    useEffect(() => {
        setContentMarker(getBroadcastCardContentMarkerValue(isLive, timeCluster));
    }, [timeCluster]);

    const progress = (bookmark * 100) / MILLISECONDS.toSeconds(endTime - startTime);

    return (
        <BroadcastCardAdapter
            cover={{
                uuid: id + startTime + endTime,
                id,
                path: getBroadcastDetailsURL(asset, locationParamsPath, appLanguage),
                title,
                image,
                landscapeImage: image,
                channelImage: channelLogo,
                metaData,
                landscape: !isPortrait,
                circle: false,
                assetType: asset.type,
                contentMarker,
                rawData: asset,
                progressBar:
                    timeCluster === 'LIVE' ? (
                        <LiveProgressIndicator startTime={startTime} endTime={endTime} onLiveStateChanged={null} />
                    ) : (
                        !isFuture(startTime) && (
                            <ProgressIndicator
                                percentage={progress < config.app_config.player_settings.bookmark_threshold ? progress : 0}
                            />
                        )
                    ),
                renderPlayIcon: !isGuest && isChannelSubscribed && (isReplayCapable || isNow(startTime, endTime)),
                onPlayIconClicked: onClick ? null : onPlayClicked,
                onClick,
            }}
            cardContext={cardContext}
            type={type}
            trackingData={trackingData}
        />
    );
};
