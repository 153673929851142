import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useCollectionPersonalizedVodInfoHandler } from '../../providers/useCollection/CollectionContext';
import { useDataFetcher } from '../useDataFetcher/useDataFetcher';
import Api from '../../api/Api';
import { Bookmark } from '../../types/Asset';
import { stripeAction } from '../../providers/reducers/stripe-reducer';
import { FetchParamById } from '../../types/ApiTypes';
import { Rentable } from '../../types/CommonTypes';

/**
 * Use this hook to update personalized info of a vod / catchup like entitled state, bookmark
 *
 *
 */
export const usePersonalized = () => {
    const { response, fetcher, reset } = useDataFetcher<Rentable & Bookmark, FetchParamById>(params => Api.getPersonalizedInfo(params.id));

    const dispatch = useDispatch();
    const collectionHandler = useCollectionPersonalizedVodInfoHandler();

    const updatePersonalizedInfo = (titleId: string) => {
        fetcher({
            id: titleId,
        });
    };

    useEffect(() => {
        if (response) {
            dispatch(stripeAction('UPDATE_PERSONALIZED_INFO', response));
            collectionHandler(response);

            reset();
        }
    }, [response]);

    return {
        updatePersonalizedInfo,
        personalizedInfoUpdated: response != null,
    };
};
