import React, { FC } from 'react';
import { usePlayer } from '../../providers/player/PlayerContext';
import {
    isCatchupPlayerAsset,
    isLivePlayerAsset,
    isRecordingPlayerAsset,
    isTrailerPlayerAsset,
    isVodPlayerAsset,
} from '../../utils/fnTypeGuards';
import { PropTypesAdditional, PropTypesPlayerUICommon } from '../../types/Player';
import { PlayerUI } from './PlayerUI';
import { useLivePlayerUI } from '../../hooks/usePlayerUI/useLivePlayerUI';
import { useCatchupPlayerUI } from '../../hooks/usePlayerUI/useCatchupPlayerUI';
import { useRecordingPlayerUI } from '../../hooks/usePlayerUI/useRecordingPlayerUI';
import { useTrailerPlayerUI } from '../../hooks/usePlayerUI/useTrailerPlayerUI';
import { useVODPlayerUI } from '../../hooks/usePlayerUI/useVODPlayerUI';
import { useConfig } from '../../providers/useConfig/ConfigContext';

export const PlayerUISelector: FC<PropTypesPlayerUICommon> = props => {
    const { asset, engine } = usePlayer();
    const { config } = useConfig();

    const livePlayerProps = useLivePlayerUI(props, asset);
    const catchupPlayerProps = useCatchupPlayerUI(props, asset);
    const recordingPlayerProps = useRecordingPlayerUI(props, asset);
    const trailerPlayerProps = useTrailerPlayerUI(props, asset);
    const vodPlayerProps = useVODPlayerUI(props, asset);

    const getProps = (): PropTypesAdditional => {
        if (isLivePlayerAsset(asset) && engine.current) {
            engine.current.configureEngine({
                streaming: {
                    bufferingGoal: config?.app_config?.player_settings?.buffers?.live?.bufferingGoal,
                    rebufferingGoal: config?.app_config?.player_settings?.buffers?.live?.rebufferingGoal,
                    bufferBehind: config?.app_config?.player_settings?.buffers?.live?.bufferBehind,
                },
            });
            return livePlayerProps;
        }

        if (isCatchupPlayerAsset(asset) && engine.current) {
            engine.current.configureEngine({
                streaming: {
                    bufferingGoal: config?.app_config?.player_settings?.buffers?.tv?.bufferingGoal,
                    rebufferingGoal: config?.app_config?.player_settings?.buffers?.tv?.rebufferingGoal,
                    bufferBehind: config?.app_config?.player_settings?.buffers?.tv?.bufferBehind,
                },
            });
            return catchupPlayerProps;
        }

        if (isRecordingPlayerAsset(asset)) {
            return recordingPlayerProps;
        }

        if (isTrailerPlayerAsset(asset)) {
            return trailerPlayerProps;
        }

        if (isVodPlayerAsset(asset) && engine.current) {
            engine.current.configureEngine({
                streaming: {
                    bufferingGoal: config?.app_config?.player_settings?.buffers?.vod?.bufferingGoal,
                    rebufferingGoal: config?.app_config?.player_settings?.buffers?.vod?.rebufferingGoal,
                    bufferBehind: config?.app_config?.player_settings?.buffers?.vod?.bufferBehind,
                },
            });
            return vodPlayerProps;
        }

        return {
            logo: null,
            logicalChannelNumber: null,
            metaData: [],
            embeddedPlayerMetadata: [],
            contentMarker: null,
            metaIcons: [],
            controls: [],
            disabledControls: [],
            seekEnabled: false,
            title: null,
            subtitle: null,
            moreOverlayMetaData: null,
            description: null,
            cast: null,
            originalLanguages: null,
            captionLanguages: null,
        };
    };

    return (
        <PlayerUI
            {...{
                ...props,
                ...getProps(),
            }}
        />
    );
};
