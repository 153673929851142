import { CastEvent, CastMediaInformation } from '../../types/Cast';
import { CastLoader } from './CastLoader';
import Events from '../../utils/Events';
import { PlayerEvents, PlayingMode, SUBTITLE_NONE } from '../../types/Player';
import ApplicationConfig from '../useConfig/ApplicationConfig';
import { getLocalStorage } from '../../utils/fnStorage';

const TRACKS_CHANGED_DEBOUNCE = 10;

class CastHandler {
    private callback: (event: CastEvent, data: any) => void;

    private session = null;

    private isCasting = false;

    private deviceName = null;

    private remotePlayer: cast.framework.RemotePlayer = null;

    private remotePlayerControls: cast.framework.RemotePlayerController = null;

    private events = new Events();

    private ignoreUpdateTill = 0;

    private tracksChangedDebounce = TRACKS_CHANGED_DEBOUNCE;

    private mediaInfo: CastMediaInformation = {
        playing: false,
        muted: null,
        volume: 0,
        duration: 0,
        progress: 0,
        title: null,
        image: null,
        loaded: false,
        id: null,
        type: null,
        mode: 'normal',
        channelId: undefined,
    };

    private castProgress = null;

    private playingMode: PlayingMode = PlayingMode.NORMAL;

    private notifyCaller = (event: CastEvent, data: any) => {
        if (this.callback) {
            this.callback(event, data);
        }
    };

    private loadLibrary = async () => {
        try {
            await CastLoader.load();
            this.notifyCaller(CastEvent.LOADED, true);
        } catch (e) {
            this.notifyCaller(CastEvent.LOADED, false);
        }
    };

    private setPlayer = remotePlayer => {
        this.remotePlayer = remotePlayer;
        this.notifyCaller(CastEvent.PLAYER_CREATED, this.remotePlayer);
    };

    private setPlayerController = controller => {
        this.remotePlayerControls = controller;
        this.notifyCaller(CastEvent.CONTROLLER_CREATED, this.remotePlayerControls);

        if (this.remotePlayerControls) {
            this.remotePlayerControls.addEventListener(
                window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
                this.onConnectedChange
            );

            this.remotePlayerControls.addEventListener(
                window.cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
                this.onCurrentTimeChange
            );
            this.remotePlayerControls.addEventListener(window.cast.framework.RemotePlayerEventType.DURATION_CHANGED, this.onDurationChange);
            this.remotePlayerControls.addEventListener(
                window.cast.framework.RemotePlayerEventType.IS_MEDIA_LOADED_CHANGED,
                this.onMediaLoadedChange
            );
            this.remotePlayerControls.addEventListener(
                window.cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
                this.onIsPausedChange
            );
            this.remotePlayerControls.addEventListener(window.cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED, this.onIsMutedChange);
            this.remotePlayerControls.addEventListener(
                window.cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED,
                this.onVolumeLevelChanged
            );
            this.remotePlayerControls.addEventListener(
                window.cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED,
                this.onMediaInfoChanged
            );
            this.remotePlayerControls.addEventListener(window.cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED, event => {
                console.log(event.field, event.value);
            });
        }
    };

    private setConnected = (connected: boolean) => {
        this.isCasting = connected;
        this.notifyCaller(CastEvent.CASTING, this.isCasting);
        this.events.triggerEvents(PlayerEvents.CAST_STATUS_CHANGED, this.isCasting);
    };

    private setReceiverName = (receiverName: string) => {
        this.deviceName = receiverName;
        this.notifyCaller(CastEvent.RECEIVER_NAME, this.deviceName);
    };

    private onCurrentTimeChange = (data: cast.framework.RemotePlayerChangedEvent) => {
        if (this.mediaInfo) {
            this.mediaInfo = {
                ...this.mediaInfo,
                progress: data?.value,
            };

            if (data?.value !== undefined && data.value > 0) {
                this.castProgress = data.value;
            }
        }

        this.notifyCaller(CastEvent.MEDIA_INFO_CHANGED, this.mediaInfo);
        this.events.triggerEvents(PlayerEvents.TIME_UPDATE, this.mediaInfo.progress);
    };

    private onDurationChange = (data: cast.framework.RemotePlayerChangedEvent) => {
        if (this.mediaInfo) {
            this.mediaInfo = {
                ...this.mediaInfo,
                duration: data?.value,
            };
        }

        this.notifyCaller(CastEvent.MEDIA_INFO_CHANGED, this.mediaInfo);
        this.events.triggerEvents(PlayerEvents.TIME_UPDATE, this.mediaInfo.progress);
    };

    private onMediaLoadedChange = (data: cast.framework.RemotePlayerChangedEvent) => {
        this.mediaInfo = {
            ...this.mediaInfo,
            loaded: data?.value ?? false,
        };

        this.notifyCaller(CastEvent.MEDIA_INFO_CHANGED, this.mediaInfo);
    };

    private onIsPausedChange = (data: cast.framework.RemotePlayerChangedEvent) => {
        if (this.mediaInfo) {
            this.mediaInfo = {
                ...this.mediaInfo,
                playing: data?.value === false,
            };
        }

        this.notifyCaller(CastEvent.MEDIA_INFO_CHANGED, this.mediaInfo);
        this.events.triggerEvents(this.mediaInfo.playing ? PlayerEvents.PLAYING : PlayerEvents.PAUSE, {
            type: this.mediaInfo.playing ? 'play' : 'pause',
        });
    };

    private onIsMutedChange = (data: cast.framework.RemotePlayerChangedEvent) => {
        if (this.mediaInfo) {
            this.mediaInfo = {
                ...this.mediaInfo,
                muted: data?.value,
            };
        }

        this.notifyCaller(CastEvent.MEDIA_INFO_CHANGED, this.mediaInfo);
        this.events.triggerEvents(PlayerEvents.VOLUME_CHANGE, this.mediaInfo.volume);
    };

    private onVolumeLevelChanged = (data: cast.framework.RemotePlayerChangedEvent) => {
        if (this.mediaInfo) {
            this.mediaInfo = {
                ...this.mediaInfo,
                volume: data?.value,
            };
        }

        this.notifyCaller(CastEvent.MEDIA_INFO_CHANGED, this.mediaInfo);
        this.events.triggerEvents(PlayerEvents.VOLUME_CHANGE, this.mediaInfo.volume);
    };

    private onMediaInfoChanged = (data: cast.framework.RemotePlayerChangedEvent) => {
        if (!data || !data.value || !data.value.metadata) {
            // do nothing
        } else {
            if (
                data.value.contentType === 'LIVE_TV' &&
                this.mediaInfo.type === data.value.contentType &&
                data.value.metadata.title !== this.mediaInfo.title
            ) {
                this.events.triggerEvents(PlayerEvents.ENDED, null);
            }
            this.playingMode = data.value.customData.playMode as PlayingMode;
            this.mediaInfo = {
                ...this.mediaInfo,
                title: data.value.metadata.title,
                image: data.value.metadata.images?.[0]?.url,
                id: data.value.contentId,
                type: data.value.contentType,
                mode: data.value.customData.playMode,
                channelId: data.value.channelId,
            };
        }

        this.notifyCaller(CastEvent.MEDIA_INFO_CHANGED, this.mediaInfo);
    };

    private onMessage = (namespace, msg) => {
        const message = JSON.parse(msg);

        if (message && message?.type === 'event') {
            if (message?.event?.type === 'ended') {
                // console.log('onMessage', message);
                this.events.triggerEvents(PlayerEvents.ENDED, null);
                this.mediaInfo = {
                    ...this.mediaInfo,
                    loaded: false,
                };
                this.notifyCaller(CastEvent.MEDIA_INFO_CHANGED, this.mediaInfo);
            }

            if (message?.event?.type === 'timeupdate') {
                this.events.triggerEvents(PlayerEvents.TIME_UPDATE, null);
            }
        }

        if (this.ignoreUpdateTill > Date.now()) {
            return;
        }

        if (message && message.type === 'update') {
            if (message.update) {
                if (message?.update?.player && message.update.player?.isBuffering != null) {
                    this.events.triggerEvents(PlayerEvents.BUFFERING, {
                        buffering: message.update.player.isBuffering,
                    });
                }

                if (message.update?.video?.paused != null) {
                    this.mediaInfo = {
                        ...this.mediaInfo,
                        playing: !message.update.video.paused,
                    };

                    this.events.triggerEvents(this.mediaInfo.playing ? PlayerEvents.PLAYING : PlayerEvents.PAUSE, {
                        type: this.mediaInfo.playing ? 'play' : 'pause',
                    });
                }

                const audiosBefore = [...(this.mediaInfo?.audioLanguages ?? [])];
                const subtitlesBefore = [...(this.mediaInfo?.subtitleLanguages ?? [])];
                const selectedAudioBefore = this.mediaInfo.selectedAudio;
                const selectedSubtitleBefore = this.mediaInfo.selectedSubtitle;

                if (message.update?.player?.getAudioLanguages) {
                    const selectedTrack = message.update.player?.getVariantTracks.find(track => track.active === true);

                    this.mediaInfo = {
                        ...this.mediaInfo,
                        audioLanguages: message.update.player.getVariantTracks,
                        selectedAudio: {
                            language: selectedTrack?.language,
                            channelsCount: selectedTrack?.channelsCount,
                            role: selectedTrack?.roles?.[0],
                            label: null,
                            raw: selectedTrack,
                        },
                    };
                }

                if (message.update?.player?.getTextTracks) {
                    const selectedTrack = message.update.player?.getTextTracks.find(track => track.active === true);

                    this.mediaInfo = {
                        ...this.mediaInfo,
                        subtitleLanguages: message.update.player.getTextTracks,
                        selectedSubtitle: !message.update.player.isTextTrackVisible
                            ? { role: null, label: null, raw: null, language: SUBTITLE_NONE }
                            : {
                                  language: selectedTrack?.language,
                                  channelsCount: null,
                                  role: selectedTrack?.roles?.[0],
                                  label: null,
                                  raw: selectedTrack,
                              },
                        isSubtitleVisible: message.update.player.isTextTrackVisible,
                    };
                }

                if (
                    this.mediaInfo.audioLanguages !== audiosBefore ||
                    this.mediaInfo.subtitleLanguages !== subtitlesBefore ||
                    this.mediaInfo.selectedAudio.language !== selectedAudioBefore.language ||
                    this.mediaInfo.selectedAudio.role !== selectedAudioBefore.role ||
                    this.mediaInfo.selectedSubtitle.language !== selectedSubtitleBefore.language ||
                    this.mediaInfo.selectedSubtitle.role !== selectedSubtitleBefore.role
                ) {
                    this.tracksChangedDebounce -= 1;

                    if (this.tracksChangedDebounce === 0) {
                        this.events.triggerEvents(PlayerEvents.TRACKS_CHANGED, null);
                        this.tracksChangedDebounce = TRACKS_CHANGED_DEBOUNCE;
                    }
                }
            }
        }
    };

    private onConnectedChange = (data: cast.framework.RemotePlayerChangedEvent) => {
        if (data.value) {
            this.setConnected(true);
            this.session = window.cast.framework.CastContext.getInstance().getCurrentSession();

            if (this.session) {
                this.setReceiverName(this.session.getSessionObj().receiver.friendlyName);
                this.session.addMessageListener(ApplicationConfig.app_config.chromecast.namespace, this.onMessage);
            }
        } else {
            this.setConnected(false);
        }
    };

    private onSessionStateChange = (data: cast.framework.SessionStateEventData) => {
        if (
            data.sessionState === window.cast.framework.SessionState.SESSION_RESUMED ||
            data.sessionState === window.cast.framework.SessionState.SESSION_STARTED
        ) {
            this.setConnected(true);
        }

        if (data.sessionState === window.cast.framework.SessionState.SESSION_ENDED) {
            this.setReceiverName(null);
            this.setConnected(false);
        }
    };

    private initConnection = () => {
        if (window.chrome && window.chrome.cast && window.cast) {
            window.cast.framework.CastContext.getInstance().setOptions({
                receiverApplicationId: ApplicationConfig.app_config.chromecast.receiver_id,
                autoJoinPolicy: window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
                resumeSavedSession: true,
            });

            const remotePlayer = new window.cast.framework.RemotePlayer();
            this.setPlayer(remotePlayer);

            const controller = new window.cast.framework.RemotePlayerController(remotePlayer);
            this.setPlayerController(controller);

            window.cast.framework.CastContext.getInstance().addEventListener(
                window.cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
                this.onSessionStateChange
            );
        }
    };

    init = async (callback: (event: CastEvent, data: any) => void) => {
        this.callback = callback;

        if (ApplicationConfig.isFeatureActive('google_cast') && ApplicationConfig.app_config.chromecast.receiver_id) {
            await this.loadLibrary();
            this.initConnection();
        }
    };

    notify = () => {
        this.notifyCaller(CastEvent.CASTING, this.isCasting);
        this.notifyCaller(CastEvent.RECEIVER_NAME, this.deviceName);
        this.notifyCaller(CastEvent.LOADED, true);
    };

    syncUILanguage = (language: string) => {
        this.sendMessage({
            type: 'setLanguage',
            value: language || getLocalStorage('appLanguage'),
        }).catch(() => {});
    };

    killListeners = () => {
        this.events.removeAllListeners();
    };

    toggleConnect = async () => {
        try {
            this.session = await window.cast.framework.CastContext.getInstance().requestSession();
        } catch (e) {
            this.session = null;
        }
    };

    sendMediaMessage = async (message: any) => {
        const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();

        if (castSession) {
            return castSession.sendMessage(ApplicationConfig.app_config.chromecast.media_namespace, JSON.stringify(message));
        }
        return Promise.reject(new Error('No active session'));
    };

    sendMessage = async (message: any) => {
        const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();

        console.log('message', message);

        if (castSession) {
            return castSession.sendMessage(ApplicationConfig.app_config.chromecast.namespace, JSON.stringify(message));
        }
        return Promise.reject(new Error('No active session'));
    };

    loadMedia = (request: chrome.cast.media.LoadRequest) => {
        this.sendMessage({
            type: 'loadMedia',
            data: request,
        })
            .then(() => {
                this.ignoreUpdateTill = -1;
                this.events.triggerEvents(PlayerEvents.MEDIA_CHANGED, null);
            })
            .catch(e => {
                console.error('load failed', e);
            });
    };

    getMediaInfo = () => this.mediaInfo;

    getRemotePlayer = () => this.remotePlayer;

    getRemotePlayerController = () => this.remotePlayerControls;

    isCast = () => this.isCasting;

    addEventListener = (event: string, callback: (object?: {}) => void) => this.events.addEventListener(event, callback);

    removeEventListener = (event: string, callback: (object?: {}) => void) => this.events.removeEventListener(event, callback);

    setIgnoreUpdateTill = (till: number) => {
        this.ignoreUpdateTill = Date.now() + till;
    };

    resetTrackChangeDebounce = () => {
        this.tracksChangedDebounce = TRACKS_CHANGED_DEBOUNCE;
    };

    getCastProgress = () => {
        const returnValue = this.castProgress;
        this.castProgress = null;

        return returnValue;
    };

    getPlayMode = () => {
        const returnValue = this.playingMode;
        this.playingMode = PlayingMode.NORMAL;

        return returnValue;
    };
}

export default new CastHandler();
