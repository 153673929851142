import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useApp } from '../../providers/useApp/AppContext';
import { usePurgeCollectionCache } from '../../providers/useCollection/CollectionContext';
import { stripeAction } from '../../providers/reducers/stripe-reducer';
import { useEpgStore } from '../../providers/useEpgStore/EpgContext';

/**
 * When the UI language is changed empty every cached data what can have language dependency
 *
 */
export const useUILanguageChangedObserver = () => {
    const { appLanguage } = useApp();

    const dispatch = useDispatch();
    const purgeCollectionCache = usePurgeCollectionCache();
    const { purgeCache } = useEpgStore();

    useEffect(() => {
        dispatch(stripeAction('PURGE_CACHES', null));
        purgeCollectionCache();
        purgeCache();
    }, [appLanguage]);
};
