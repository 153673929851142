import React, { createContext, useContext, useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getPerformance, PerformanceTrace, trace } from 'firebase/performance';

import { useConfig } from '../useConfig/ConfigContext';
import {
    DetailPageScreenName,
    EventTypes,
    PlaybackEvenTypes,
    PlaybackTrackingType,
    ScreenName,
    TrackingElementPropTypes,
} from '../../types/Tracking';
import { generateUUID } from '../../utils/fnData';
import { usePersistentState } from '../../hooks/useStorage/useStorage';
import { getConfig, transformAttributeKeys } from '../../utils/fnTracking';

export type ContextType = {
    initialised: boolean;
    sessionId: string;
    configId: string;
};

const KpiMeasurements = {
    PRELOAD: '_to_preloaded',
    FULL_LOAD: '_to_fully_loaded',
};

const TrackingContext = createContext<ContextType>(null);

const TRACKING_DEBUG = false;

const KPI_DEBUG = false;

export const TrackingProvider = ({ children }) => {
    const [init, setInit] = useState(false);
    const { set: storeTrackingSessionId, store: trackSessionId } = usePersistentState('trackingSessionId', generateUUID());
    const { config } = useConfig();

    useEffect(() => {
        initializeApp(getConfig());

        setInit(true);
        storeTrackingSessionId(trackSessionId);
    }, []);

    return (
        <TrackingContext.Provider
            value={{
                initialised: init,
                sessionId: trackSessionId,
                configId: config?.api_config.config_id,
            }}
        >
            {children}
        </TrackingContext.Provider>
    );
};

export const useTracking = () => {
    const { initialised, sessionId, configId } = useContext(TrackingContext) ?? {};
    const { config } = useConfig();

    return (event: EventTypes, attributes?: { [key: string]: any }) => {
        if (initialised && config.isFeatureActive('analytics')) {
            const trackAttributes = {
                ...transformAttributeKeys(attributes),
                ...{
                    session_id: sessionId,
                    config_id: configId,
                },
            };

            const analytics = getAnalytics();

            if (TRACKING_DEBUG) {
                console.log('[tracking]', event, trackAttributes);
            }
            logEvent(analytics, `${event}` as any, trackAttributes);
        }
    };
};

export const useTrackingScreen = (condition: () => boolean, watch: any[] = []) => {
    const [trackingParams, setTrackingParams] = useState<{ screenName: ScreenName | string; args: { [key: string]: any } }>(null);
    const track = useTracking();

    useEffect(() => {
        if (trackingParams && condition()) {
            track('page_view', {
                ...{ page_name: trackingParams.screenName },
                ...trackingParams.args,
            });
        }
    }, [trackingParams, ...watch]);

    return (screenName: DetailPageScreenName | string, args: { [key: string]: any } = {}) => {
        if (trackingParams?.screenName !== screenName) {
            setTrackingParams({ screenName, args });
        }
    };
};

export const useTrackingPlayback = () => {
    const track = useTracking();

    return (event: PlaybackEvenTypes, attributes: PlaybackTrackingType) => {
        track(event, attributes);
    };
};

export const useElementInteractionTracking = () => {
    const track = useTracking();

    return (attributes: TrackingElementPropTypes) => {
        track('click', attributes);
    };
};

const tracesMap = new Map();

class Trace {
    private trace: PerformanceTrace;

    private wasStarted = false;

    private wasStopped = false;

    private name = '';

    constructor(t: PerformanceTrace, n = '') {
        this.trace = t;
        this.name = n;
    }

    start() {
        if (!this.wasStarted) {
            this.trace.start();
            if (KPI_DEBUG) {
                console.log('Trace start ', this.name);
            }
            this.wasStarted = true;
        }
    }

    stop() {
        if (this.wasStarted && !this.wasStopped) {
            this.trace.stop();
            if (KPI_DEBUG) {
                console.log('Trace stop ', this.name);
            }
            this.wasStopped = true;
        }
    }
}

export const useKPI = traceName => {
    const perf = getPerformance();
    let preLoad;
    let fullLoad;

    const [prevState, setPrevState] = useState('');

    if (prevState !== traceName) {
        tracesMap.forEach(item => {
            item.stop();
        });
        tracesMap.clear();
        setPrevState(traceName);
    }

    if (tracesMap.has(traceName + KpiMeasurements.PRELOAD)) {
        preLoad = tracesMap.get(traceName + KpiMeasurements.PRELOAD);
    } else {
        preLoad = new Trace(trace(perf, traceName + KpiMeasurements.PRELOAD), traceName + KpiMeasurements.PRELOAD);
        tracesMap.set(traceName + KpiMeasurements.PRELOAD, preLoad);
    }

    if (tracesMap.has(traceName + KpiMeasurements.FULL_LOAD)) {
        fullLoad = tracesMap.get(traceName + KpiMeasurements.FULL_LOAD);
    } else {
        fullLoad = new Trace(trace(perf, traceName + KpiMeasurements.FULL_LOAD), traceName + KpiMeasurements.FULL_LOAD);
        tracesMap.set(traceName + KpiMeasurements.FULL_LOAD, fullLoad);
    }

    return {
        fullLoad,
        preLoad,
    };
};
