import { useEffect, useState } from 'react';
import { isCatchUpCapable } from '../../utils/fnData';
import { useAuth } from '../../providers/useAuth/AuthContext';
import { isFuture } from '../../utils/fnDate';
import { Channel } from '../../types/Asset';

export const useAssetReplayCapable = (data: any, channel?: Channel) => {
    const [isReplayCapable, setIsReplayCapable] = useState(false);
    const { getReplayWindowTimeFrame } = useAuth();

    useEffect(() => {
        if (!data || !('startTime' in data)) {
            setIsReplayCapable(false);
            return;
        }

        const startTime = data?.startTime;
        const channelHasReplay = channel?.replay ?? data?.channelHasReplay;

        setIsReplayCapable(
            startTime != null &&
                channelHasReplay != null &&
                !isFuture(startTime) &&
                isCatchUpCapable(startTime, channelHasReplay, getReplayWindowTimeFrame())
        );
    }, [data]);

    return {
        isReplayCapable,
    };
};
