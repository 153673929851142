import { CardTrackingData, FirebaseConfig, TrackId } from '../types/Tracking';
import { getProcessEnv } from './fnEnvironments';

const basicFields = ['data-test-id'];

export const getConfig = (): FirebaseConfig => {
    return {
        apiKey: getProcessEnv('FIREBASE_APIKEY'),
        appId: getProcessEnv('FIREBASE_APPID'),
        authDomain: getProcessEnv('FIREBASE_AUTHDOMAIN'),
        databaseURL: getProcessEnv('FIREBASE_DATABASEURL'),
        measurementId: getProcessEnv('FIREBASE_MEASUREMENTID'),
        messagingSenderId: getProcessEnv('FIREBASE_MESSAGINGSENDERID'),
        projectId: getProcessEnv('FIREBASE_PROJECTID'),
        storageBucket: getProcessEnv('FIREBASE_STORAGEBUCKET'),
    };
};

export const getTrackingDataFromDataAttributes = (props: { [key: string]: any }): { [key: string]: string } => {
    const trackingData = {};
    let trackingKey;

    // eslint-disable-next-line no-restricted-syntax
    for (const key in props) {
        if (key in props) {
            if (key.startsWith('data-') && basicFields.indexOf(key) === -1 && key !== 'data-event-name') {
                trackingKey = key.replace('data-', '').replace(/-/g, '_');
                trackingData[trackingKey] = props[key];
            }
        }
    }

    return trackingData;
};

export const cardTrackingData = (
    trackingId: TrackId,
    pageName: string,
    cardIndex: number,
    moduleTitle: string,
    assetId: string,
    assetName,
    stripePosition?: number
): CardTrackingData => {
    return {
        'data-event-name': 'click',
        'data-track-id': trackingId,
        'data-card-position': cardIndex,
        'data-module-title': moduleTitle,
        'data-stripe-position': stripePosition,
        'data-page-name': pageName,
        'data-asset-id': assetId,
        'data-asset-name': assetName,
    };
};

export const trackingClickHandler = (event, onClick, props, tracker) => {
    if (props['data-event-name']) {
        tracker(props['data-event-name'], getTrackingDataFromDataAttributes(props));
    }

    if (onClick) {
        onClick(event);
    }
};

export const transformAttributeKeys = (input: any) => {
    const output = {};
    const keys = Object.keys(input);

    if (input && keys?.length) {
        keys.forEach(key => {
            output[key.replace('data-', '').replace(/-/g, '_')] = input[key];
        });
    }

    return output;
};
