import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';

export const RowItemContainer = styled.div<{ height: number }>`
    text-align: left;
    width: 30000px;
    height: ${props => props.height}px;
    white-space: nowrap;
    background-color: ${props => props.theme.brandedConfig.black_color};
    position: relative;
    z-index: auto;

    &:first-child {
        border-top-color: transparent;
    }
`;

export const EpgFullRowCell = styled.span<{ height: number }>`
    width: 100%;
    height: ${props => props.height}px;
    display: inline-block;
    text-align: left;
    border-width: 2px;
    border-style: solid;
    opacity: 1;
    background-color: ${props => props.theme.brandedConfig.low_contrast};
    border-color: ${props => props.theme.brandedConfig.background};
    border-radius: 4px;
    white-space: nowrap;
    pointer-events: none;
    padding-top: 15px;
    padding-right: 24px;
`;
