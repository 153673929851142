export default class Events {
    events = {};

    addEventListener(type: string, callback: (args) => void) {
        if (!this.events[type]) {
            this.events[type] = [];
        }
        this.events[type].push(callback);
    }

    removeEventListener(type: string, callback: (args) => void) {
        if (!this.events[type]) {
            return false;
        }

        const events = this.events[type];
        const index = events.indexOf(callback);

        if (index < 0) {
            return false;
        }
        this.events[type].splice(index, 1);
        return true;
    }

    removeAllListeners = (except: string[] = []) => {
        if (!except.length) {
            this.events = {};
        } else {
            // eslint-disable-next-line no-restricted-syntax
            for (const event in this.events) {
                if (!except.includes(event)) {
                    delete this.events[event];
                }
            }
        }
    };

    triggerEvents(type: string, args) {
        if (!this.events[type]) {
            return false;
        }

        const events = this.events[type];
        events.forEach(callback => {
            try {
                callback(args);
            } catch (exc) {
                console.error('Error in callback type:', type, 'Ref:', callback);
            }
        });
        return true;
    }

    hasListener = (event: string) => event in this.events;
}
