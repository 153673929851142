import SVGInline from 'react-svg-inline';
import { css } from 'styled-components';
import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { Breakpoints, MQ } from '../../../style/styled-components/cssMediaQueries';

export const EpgPageWrapper = styled.div``;

export const InnerWrapper = styled.div<{ withExtraTopPadding: boolean }>`
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding-top: ${props => (props.withExtraTopPadding ? '40px' : '0')};
`;

export const StyledSVGInline = styled(SVGInline)`
    width: 100%;
    height: 100%;
    z-index: 100;
`;

export const AnimatedPlayerWrapper = styled.div<{ playerProps: boolean; playerAnimationDuration: number }>`
    transition: height ${props => props.playerAnimationDuration}ms ease-in-out;
    overflow: hidden;
    background-color: ${props => props.theme.brandedConfig.black_color};
    margin-bottom: 16px;

    ${props =>
        props.playerProps
            ? css`
                  height: 202px;

                  ${MQ(Breakpoints.s)} {
                      height: 268px;
                  }
              `
            : css`
                  height: 0;
              `}

    ${MQ(Breakpoints.s)} {
        padding-left: 44px;
        padding-right: 44px;
    }
`;
