import ApplicationConfig from 'providers/useConfig/ApplicationConfig';

export default (key: string, vars?: { [key: string]: string | number }, valueTransformer?: (translationValue) => string): string => {
    const configLanguages = ApplicationConfig?.app_config?.languages;
    const configGeneralLanguage = ApplicationConfig?.app_config?.general?.language;
    const languageData = configLanguages?.[configGeneralLanguage]?.data;

    if (!languageData) {
        return key;
    }

    let translation: string = languageData[key] || key;

    if (valueTransformer) {
        translation = valueTransformer(translation);
    }

    if (vars && Object.keys(vars).length) {
        Object.keys(vars).forEach(varKey => {
            translation = translation.replace(new RegExp(`%${varKey}%`, 'gi'), `${vars[varKey]}`);
        });
    }
    return translation;
};
