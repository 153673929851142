import ApplicationConfig from '../providers/useConfig/ApplicationConfig';
import { PATH_COLLECTION, PATH_EDITORIAL } from './fnRouting';
import { EditorialItem, ModuleConfigs } from '../types/Config';
import { getStripeEditorialModulePageId, isCollectionItem, isViewAllLink } from './fnEditorial';

export const getEditorialCollectionItem = (moduleConfig?: ModuleConfigs): EditorialItem | null => {
    if (!moduleConfig || !moduleConfig.items || !moduleConfig.items.length) {
        return null;
    }
    return moduleConfig.items.length === 1 && moduleConfig.items[0];
};

export const getCollectionNavigationPath = (
    showAll: boolean,
    config: typeof ApplicationConfig,
    collectionId?: string,
    editorialAll?: EditorialItem
): string => {
    if (!showAll && !editorialAll) {
        return null;
    }

    const isCollectionLink = isViewAllLink(editorialAll) || isCollectionItem(editorialAll);
    const editorialType = isCollectionLink ? `/${PATH_COLLECTION}` : '';
    const collectionAllId = getStripeEditorialModulePageId(editorialAll);

    if (isCollectionLink && !collectionAllId) {
        return null;
    }

    let editorialPath = editorialAll
        ? `${editorialType}${
              isCollectionLink
                  ? `/${collectionAllId}`
                  : `${config.getPagePath(editorialAll.action.menu_tab_id || editorialAll.action.page_id)}`
          }`
        : null;

    if (isCollectionLink && editorialAll?.action?.module?.foreignId) {
        editorialPath += `/${editorialAll.action.module.foreignId}`;
    }

    return editorialPath || `/${PATH_COLLECTION}/${collectionId}`;
};

export const editorialPathVarToId = (pathVar: string): string => {
    return pathVar.replace(`${PATH_EDITORIAL}-`, '').replace(/-/g, '_');
};
