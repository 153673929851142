import { css } from 'styled-components';
import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold, levelingZIndex } from '../../../style/styled-components/cssGlobalVariables';
import { MQ_NOT_TOUCH } from '../../../style/styled-components/cssMediaQueries';

export const ProgramItem = styled.div<{ width: number; height: number; isNow: boolean; isSelected: boolean }>`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    display: inline-block;
    text-align: left;
    border-width: 2px;
    border-style: solid;
    opacity: 1;
    position: relative;
    background-color: ${props =>
        props.isSelected
            ? props.theme.brandedConfig.primary
            : props.isNow
            ? props.theme.brandedConfig.epg_live_program_color
            : props.theme.brandedConfig.low_contrast};
    border-color: ${props => props.theme.brandedConfig.black_color};
    border-radius: 4px;
    float: left;
    font-size: 16px;
    line-height: 18px;
    vertical-align: middle;
    cursor: pointer;
    z-index: auto;

    ${MQ_NOT_TOUCH} {
        &:hover {
            background: ${props => (props.isSelected ? props.theme.brandedConfig.primary : props.theme.brandedConfig.epg_hover_color)};
        }
    }
`;

export const ProgramTitle = styled.span<{ rowHeight: number; past: boolean }>`
    display: inline-block;
    width: 100%;
    height: 100%;
    line-height: ${props => props.rowHeight - 4}px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 8px;
    padding-right: 24px;
    opacity: ${props => (props.past ? '0.5' : '1')};
`;

export const HoverStateContainer = styled.div`
    position: absolute;
    height: 100%;
    left: 3px; // set this for the first column hover effect, so that the rounding of the box is not cut
    top: 0;
    pointer-events: none;
    z-index: ${levelingZIndex + 1};
`;

export const HoverStateInnerWrapper = styled.div<{ hovered: boolean; selected: boolean }>`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: auto;
    opacity: 0;
    border-radius: 4px;
    overflow: hidden;
    pointer-events: all;
    transition: opacity 0.2s ease-in-out;
    background: ${props => (props.selected ? props.theme.brandedConfig.primary : props.theme.brandedConfig.epg_hover_color)};

    ${props =>
        props.hovered
            ? css`
                  transition: all 0.2s ease-in-out;
              `
            : ''}
`;

export const HoveredTitleContainer = styled.div`
    height: auto;
    padding-top: 4px;
    float: left;
    overflow: hidden;
    display: inline-block;
    margin-right: 10px;
    font-size: 16px;
    line-height: 20px;

    &:after {
        content: '';
        display: block;
        clear: both;
    }
`;

export const TitleText = styled.p`
    margin-left: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-family: ${fontBold};
`;

export const AirTimeText = styled.span`
    font-size: 14px;
    line-height: 21px;
    margin-left: 10px;
    white-space: nowrap;
    display: inline-block;
    color: ${props => props.theme.brandedConfig.high_contrast};
    float: left;
`;

export const PlayIcon = styled.div`
    width: 27px;
    height: 27px;

    .SVGInline svg {
        width: 100%;
        height: auto;
    }
`;

export const HoverActionIconsContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
`;
