import SVGInline from 'react-svg-inline';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { Breakpoints, MQ, MQ_NOT_TOUCH } from '../../style/styled-components/cssMediaQueries';
import { fontBold, maxZIndex } from '../../style/styled-components/cssGlobalVariables';
import { hexToRgba } from '../../utils/fnColors';

export const Overlay = styled.div`
    background: ${props => hexToRgba(props.theme.globalConfig.black_color, 0.2)};
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`;

export const CastedTitle = styled.p`
    font-size: 12px;
    line-height: 16px;
    color: ${props => props.theme.globalConfig.high_contrast};
    font-family: ${fontBold};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    ${MQ(Breakpoints.s)} {
        font-size: 14px;
        line-height: 20px;
    }
`;

export const CastDeviceInfo = styled.span`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${props => hexToRgba(props.theme.globalConfig.high_contrast, 0.7)};
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 8px;
    display: block;
`;

export const InnerWrapper = styled.div`
    padding: 8px;

    ${MQ(Breakpoints.s)} {
        padding-top: 16px;
    }
`;

export const InfoWrapper = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 48px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, ${props => props.theme.globalConfig.black_color} 100%);

    ${MQ(Breakpoints.s)} {
        height: 56px;
    }
`;

export const PlayPauseIcon = styled(SVGInline)`
    width: 40px;
    height: 40px;
    opacity: 1;
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;

    ${MQ(Breakpoints.s)} {
        top: 32px;
        width: 48px;
        height: 48px;
    }

    ${MQ_NOT_TOUCH} {
        &:hover {
            opacity: 0.7;
        }
    }

    svg {
        width: 100%;
        height: 100%;
        vertical-align: middle;
    }
`;

export const InnerContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;

    a {
        display: block;
    }
`;

export const MiniControllerWrapper = styled.div`
    transition: all 150ms;
    width: 184px;
    height: 104px;
    background: ${props => props.theme.globalConfig.black_color};
    position: fixed;
    z-index: ${maxZIndex * 2};
    right: 16px;
    bottom: 64px;
    transform: translateY(30px);
    opacity: 0;
    cursor: pointer;
    filter: drop-shadow(0px 4px 8px ${props => hexToRgba(props.theme.globalConfig.black_color, 0.05)});

    &.opened {
        transition: all 250ms;
        transform: translateY(0);
        opacity: 1;
    }

    ${MQ(Breakpoints.s)} {
        width: 224px;
        height: 128px;
        right: 24px;
        bottom: 72px;
    }

    ${MQ(Breakpoints.m)} {
        right: 40px;
        bottom: 40px;
    }
`;
