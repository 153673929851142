import React, { FC, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import {
    CardChannelWrapperInner,
    channelCardHeight,
    channelCardWidth,
    ChannelFallbackTitle,
    ChannelIcon,
    ChannelNumber,
    ImageWrapper,
} from './CardChannel.css';
import { LinkTrackable } from '../../Trackable/LinkTrackable';

import { StyledSVGInline } from '../../Epg/EpgModule/EpgModule.css';
import icons from '../../../style';
import { CardImage } from '../../../style/styled-components/reusable.css';
import { CardWrapper } from '../../Lane/Lane.css';
import { ChannelCover } from '../../../types/Cover';

export const CardChannel: FC<{ asset: ChannelCover }> = ({ asset, ...rest }) => {
    const { path, number, subscription, favorite, title, image } = asset;
    const [showFallbackTitle, setShowFallbackTitle] = useState(false);

    const [ref, inView] = useInView({
        /* Optional options */
        threshold: 0,
    });

    return (
        <CardWrapper height={channelCardHeight} width={channelCardWidth} ref={ref}>
            {inView && (
                <LinkTrackable
                    to={path}
                    onClick={e => {
                        if (asset.onClick) {
                            asset.onClick(e, asset.rawData);
                        }
                    }}
                    {...rest}
                >
                    <CardChannelWrapperInner>
                        <ChannelNumber>{number}</ChannelNumber>
                        <ChannelIcon>
                            {(!subscription && <StyledSVGInline svg={icons.lockIcon} />) ||
                                (favorite && <StyledSVGInline svg={icons.icoFavorite} />)}
                        </ChannelIcon>
                        {showFallbackTitle && <ChannelFallbackTitle>{title}</ChannelFallbackTitle>}
                        {!showFallbackTitle && (
                            <ImageWrapper>
                                <CardImage
                                    url={image}
                                    alt={asset.title}
                                    {...{
                                        imageError: () => {
                                            setShowFallbackTitle(true);
                                        },
                                    }}
                                />
                            </ImageWrapper>
                        )}
                    </CardChannelWrapperInner>
                </LinkTrackable>
            )}
        </CardWrapper>
    );
};
