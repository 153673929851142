import React, { FC } from 'react';
import { usePlayer } from 'providers/player/PlayerContext';
import { getMiniPlayerProps } from 'utils/fnData';
import { PlayingAssetType } from 'types/Player';
import { useHistory } from 'react-router';
import { Movie } from '../../../types/Asset';
import { getVodMovieDetailsURL, getVodPlayerURL } from '../../../utils/fnUrl';
import { CardLandscape } from '../../Cards/CardLandscape/CardLandscape';
import { CardPortrait } from '../../Cards/CardPortrait/CardPortrait';
import { CardCollectionPortrait } from '../../Cards/CardCollection/CardCollectionPortrait';
import { CardCollectionMixed } from '../../Cards/CardCollection/CardCollectionMixed';
import { useDetailLocationParams } from '../../../hooks/useDetailLocationParams';
import { useConfig } from '../../../providers/useConfig/ConfigContext';
import { VODAssetEntitlementState } from '../../../types/Entitlement';
import { useAuth } from '../../../providers/useAuth/AuthContext';
import { StandardCover } from '../../../types/Cover';
import { CardContext, StripeTypes } from '../../../types/Common';
import { ProgressIndicator } from '../../ProgressIndicator/ProgressIndicator';
import { CardTrackingData } from '../../../types/Tracking';
import { trackingClickHandler } from '../../../utils/fnTracking';
import { useTracking } from '../../../providers/useTracking/TrackingContext';
import { getVodContentMarker } from '../../../utils/fnContentMarker';
import { CardCircle } from '../../Cards/CardCircle/CardCircle';
import { useApp } from '../../../providers/useApp/AppContext';

const MovieCardAdapter: FC<{
    cover: StandardCover;
    type: StripeTypes;
    cardContext: CardContext;
    trackingData: CardTrackingData;
}> = ({ cover, cardContext, type, trackingData }) => {
    if (type === StripeTypes.PERSON || type === StripeTypes.ROUNDED) {
        return <CardCircle asset={cover} {...trackingData} />;
    }

    if (type === StripeTypes.MIXED && cardContext === CardContext.COLLECTION) {
        return <CardCollectionMixed asset={cover} key={cover.uuid} {...trackingData} />;
    }

    switch (cardContext) {
        case CardContext.STRIPE:
            if (cover.landscape && type !== StripeTypes.PORTRAIT && type !== StripeTypes.MOVIE) {
                return <CardLandscape asset={cover} {...trackingData} />;
            }
            return <CardPortrait asset={cover} stripeType={type} {...trackingData} />;
        case CardContext.COLLECTION:
            return <CardCollectionPortrait asset={cover} {...trackingData} />;
        default:
            return null;
    }
};

export const MovieCardPresenter: FC<{
    asset: Movie;
    type: StripeTypes;
    cardContext: CardContext;
    trackingData: CardTrackingData;
}> = ({ asset, type, cardContext, trackingData }) => {
    const { id, title, image, metaData, firstAvailability, price, vodAssetState, duration, bookmark, isPortrait, entitlementEnd } = asset;
    const locationParamsPath = useDetailLocationParams();
    const { config } = useConfig();
    const { isGuest } = useAuth();
    const { appLanguage } = useApp();
    const history = useHistory();
    const track = useTracking();
    const { isMini, updateMiniPlayerProps } = usePlayer();

    const onPlayClicked = event => {
        event.preventDefault();
        event.stopPropagation();

        trackingClickHandler(
            event,
            () =>
                isMini ? updateMiniPlayerProps(getMiniPlayerProps(asset, PlayingAssetType.VOD)) : history.push(getVodPlayerURL(asset.id)),
            {
                ...trackingData,
                ...{
                    'data-track-id': 'image_button_play',
                },
            },
            track
        );
    };

    const progress = (bookmark * 100) / duration;

    return (
        <MovieCardAdapter
            cover={{
                uuid: asset.id,
                id,
                title,
                image,
                landscapeImage: image,
                path: getVodMovieDetailsURL(asset, locationParamsPath, appLanguage),
                metaData,
                circle: false,
                landscape: !isPortrait,
                assetType: asset.type,
                rawData: asset,
                renderPlayIcon: !isGuest && vodAssetState === VODAssetEntitlementState.ENTITLED,
                progressBar: (
                    <ProgressIndicator percentage={progress < config.app_config.player_settings.bookmark_threshold ? progress : 0} />
                ),
                onPlayIconClicked: onPlayClicked,
                contentMarker: getVodContentMarker(price, firstAvailability, vodAssetState, entitlementEnd),
            }}
            type={type}
            cardContext={cardContext}
            trackingData={trackingData}
        />
    );
};
