import React, { FC, useEffect } from 'react';
import SVGInline from 'react-svg-inline';
import { Option, OptionButton, OptionName, OptionRightSide, PageWrapper } from '../PageSettingsCommon.css';
import translate from '../../../../utils/fnTranslate';
import { useTrackingScreen } from '../../../../providers/useTracking/TrackingContext';
import { useApp } from '../../../../providers/useApp/AppContext';
import { HelmetWrapper } from '../../../HelmetWrapper/HelmetWrapper';
import { getHelmetData } from '../../../../utils/fnHelmet';
import icons from '../../../../style';
import { PinFlowType, usePin } from '../../../../providers/usePin/PinContext';
import { redirectTo } from '../PageSettings/AppSettings';
import { useConfig } from '../../../../providers/useConfig/ConfigContext';

const PagePinProtection: FC = () => {
    const { setPageTitle } = useApp();
    const trackScreen = useTrackingScreen(() => true);
    const { openPinDialog } = usePin();
    const { config } = useConfig();
    const { reset } = config?.api_config?.routes?.Pin ?? {};

    useEffect(() => {
        const title = translate('SETTINGS_PIN');

        setPageTitle(title);
        trackScreen(title);
    }, []);

    return (
        <HelmetWrapper {...getHelmetData(translate('SETTINGS_PIN'))}>
            <PageWrapper>
                <Option clickable={true} onClick={() => openPinDialog(PinFlowType.CHANGE)}>
                    <OptionName>{translate('SETTINGS_CHANGE_PURCHASE_PIN')}</OptionName>
                    <OptionRightSide>
                        <OptionButton>
                            <SVGInline svg={icons.rightArrowIcon} />
                        </OptionButton>
                    </OptionRightSide>
                </Option>
                <Option clickable={true} onClick={() => redirectTo(reset)}>
                    <OptionName>{translate('SETTINGS_FORGOT_YOUR_PIN')}</OptionName>
                    <OptionRightSide>
                        <OptionButton>
                            <SVGInline svg={icons.rightArrowIcon} />
                        </OptionButton>
                    </OptionRightSide>
                </Option>
            </PageWrapper>
        </HelmetWrapper>
    );
};

export default PagePinProtection;
