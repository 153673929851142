import React, { FC } from 'react';
import { Series } from '../../../types/Asset';
import { getSeriesDetailUrl } from '../../../utils/fnUrl';
import { CardLandscape } from '../../Cards/CardLandscape/CardLandscape';
import { CardCollectionMixed } from '../../Cards/CardCollection/CardCollectionMixed';
import { useDetailLocationParams } from '../../../hooks/useDetailLocationParams';
import { CardCircle } from '../../Cards/CardCircle/CardCircle';
import { useWorld } from '../../../providers/useWorld/WorldContext';
import { StandardCover } from '../../../types/Cover';
import { CardContext, StripeTypes } from '../../../types/Common';
import { CardTrackingData } from '../../../types/Tracking';
import { getSeriesCardContentMarkerValue } from '../../../utils/fnContentMarker';
import { WorldSectionType } from '../../../types/World';
import { useApp } from '../../../providers/useApp/AppContext';

export const SeriesCardPresenter: FC<{
    asset: Series;
    type: StripeTypes;
    cardContext: CardContext;
    trackingData: CardTrackingData;
}> = ({ asset, type, cardContext, trackingData }) => {
    const { id, title, image, metaData, firstAvailability, lastEpisodeFirstAvailability, isVOD } = asset;
    const locationParamsPath = useDetailLocationParams();
    const { isWorldItem } = useWorld();
    const { appLanguage } = useApp();

    const cover: StandardCover = {
        uuid: id + title,
        id,
        title,
        image,
        landscapeImage: image,
        path: getSeriesDetailUrl(asset, locationParamsPath, appLanguage),
        metaData,
        circle: false,
        landscape: true,
        assetType: asset.type,
        rawData: asset,
        contentMarker: getSeriesCardContentMarkerValue(firstAvailability, isVOD, lastEpisodeFirstAvailability),
        renderPlayIcon: false,
        isFavorite: isWorldItem(id, WorldSectionType.BROADCASTS_AND_SERIES),
    };

    if (type === StripeTypes.PERSON || type === StripeTypes.ROUNDED) {
        return <CardCircle asset={cover} {...trackingData} />;
    }

    return cardContext === CardContext.STRIPE ? (
        <CardLandscape asset={cover} {...trackingData} />
    ) : (
        <CardCollectionMixed asset={cover} {...trackingData} />
    );
};
