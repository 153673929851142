import { DetailLocationParamsPath } from '../hooks/useDetailLocationParams';
import { CollectionLocationParamsPath } from '../hooks/useCollectionLocationParams';
import { Broadcast, Episode, Movie, NowAndNext, Recording, Series, TVSeriesDetailEpisode } from '../types/Asset';
import { DetailRouteDomain } from '../types/RouteTypes';
import { EpgProgram } from '../types/EpgTypes';
import { isBroadcast, isEpisodeRecording, isRecording, isTVEpisodeBroadcast } from './fnTypeGuards';
import { CatchupPlayerAssetInfo, PlayerProps, PlayingAssetType, VodPlayerAssetInfo } from '../types/Player';
import { RecordingPlayerInitiator } from '../types/RecordingTypes';
import { CastAssetType } from '../types/Cast';

export const getFilterValue = (filter: string[], key: string): string | null => {
    if (!filter || !filter.length) return null;
    let found = null;
    // eslint-disable-next-line consistent-return
    filter.forEach(filterValue => {
        const explodeRegExp = new RegExp('(.*)\\[(.*)\\]', 'g');
        const matches = explodeRegExp.exec(filterValue);
        let name: string;
        let value: string;
        if (matches) {
            [, name, value] = matches;

            if (name === key) {
                found = value;
            }
        }
    });

    return found;
};

export const appendQueryParams = (url: string, queries: { [key: string]: boolean | number | string }): string => {
    let finalUrl = url;

    Object.keys(queries).forEach(queryKey => {
        finalUrl += !finalUrl.includes('?') ? '?' : '&';
        finalUrl += `${queryKey}=${queries[queryKey]}`;
    });

    return finalUrl;
};

export const encodeId = (id: string): string => {
    return id?.replace(/\./g, '~dot');
};

export const decodeId = (id: string): string => {
    return id?.replace(/~dot/g, '.');
};

export const buildDetailPageUrl = (locationParamsPath: DetailLocationParamsPath, targetPath: string): string => {
    if (targetPath == null) {
        return null;
    }

    const deepnessUrlSeparator = targetPath.indexOf('?') > -1 ? '&' : '?';

    if (!locationParamsPath?.params || Object.keys(locationParamsPath?.params ?? {}).length === 0) {
        const parentPath = (locationParamsPath?.pathname ?? '/') === '/' ? '' : locationParamsPath.pathname;
        return `${parentPath}${targetPath}${deepnessUrlSeparator}`;
    }

    let detailToDetailPath = '';

    if (locationParamsPath?.params?.section) {
        detailToDetailPath += '/';
        detailToDetailPath += locationParamsPath.params.section === '/' ? '' : locationParamsPath.params.section;
    }

    if (locationParamsPath?.params?.subSection) {
        detailToDetailPath += '/';
        detailToDetailPath += locationParamsPath.params.subSection === '/' ? '' : locationParamsPath.params.subSection;
    }

    if (locationParamsPath?.params?.sectionId) {
        if (detailToDetailPath.length > 0) {
            detailToDetailPath += '/';
        }

        detailToDetailPath += locationParamsPath.params.sectionId;
    }

    if (targetPath) {
        detailToDetailPath += targetPath;
    }

    detailToDetailPath += `${deepnessUrlSeparator}`;

    return detailToDetailPath;
};

export const buildCollectionPageUrl = (locationParamsPath: CollectionLocationParamsPath, targetPath: string): string => {
    if (targetPath == null) {
        return null;
    }

    if (!locationParamsPath.params || Object.keys(locationParamsPath?.params ?? {}).length === 0) {
        const parentPath = (locationParamsPath?.pathname ?? '/') === '/' ? '' : locationParamsPath.pathname;
        return `${parentPath}${targetPath}`;
    }

    let toCollectionPath = '';

    if (locationParamsPath?.params?.section) {
        toCollectionPath += '/';
        toCollectionPath += locationParamsPath.params.section === '/' ? '' : locationParamsPath.params.section;
    }

    if (locationParamsPath?.params?.sectionId) {
        if (toCollectionPath.length > 0) {
            toCollectionPath += '/';
        }

        toCollectionPath += locationParamsPath.params.sectionId;
    }

    if (targetPath) {
        toCollectionPath += targetPath;
    }

    return toCollectionPath;
};

/**
 * Detail page urls
 */

export const getBroadcastDetailsURL = (
    broadcast: Broadcast | EpgProgram | Recording,
    locationParamsPath: DetailLocationParamsPath,
    appLanguage: string
): string => {
    if (isRecording(broadcast)) {
        if (isEpisodeRecording(broadcast)) {
            const { seriesId, eventId } = broadcast;
            return buildDetailPageUrl(
                locationParamsPath,
                `/${DetailRouteDomain[appLanguage].TV_SERIES}/${encodeId(seriesId)}?event=${eventId}`
            );
        }

        const { eventId } = broadcast;
        return eventId
            ? buildDetailPageUrl(locationParamsPath ?? {}, `/${DetailRouteDomain[appLanguage].PROGRAM}/${encodeId(eventId)}`)
            : null;
    }

    if (isTVEpisodeBroadcast(broadcast)) {
        if (isBroadcast(broadcast)) {
            const { seriesId, episodeId } = broadcast;
            return buildDetailPageUrl(
                locationParamsPath,
                `/${DetailRouteDomain[appLanguage].TV_SERIES}/${encodeId(seriesId)}?episode=${episodeId}`
            );
        }

        const { seriesId, id } = broadcast;
        return buildDetailPageUrl(locationParamsPath, `/${DetailRouteDomain[appLanguage].TV_SERIES}/${encodeId(seriesId)}?event=${id}`);
    }

    const { id } = broadcast;
    return id ? buildDetailPageUrl(locationParamsPath ?? {}, `/${DetailRouteDomain[appLanguage].PROGRAM}/${encodeId(id)}`) : null;
};

export const getNowAndNextDetailsURL = (
    nowAndNext: NowAndNext,
    locationParamsPath: DetailLocationParamsPath,
    appLanguage: string
): string => {
    if (isTVEpisodeBroadcast(nowAndNext)) {
        const { seriesId, episodeId } = nowAndNext;
        return buildDetailPageUrl(
            locationParamsPath,
            `/${DetailRouteDomain[appLanguage].TV_SERIES}/${encodeId(seriesId)}?episode=${episodeId}`
        );
    }

    const { id } = nowAndNext;
    return id ? buildDetailPageUrl(locationParamsPath ?? {}, `/${DetailRouteDomain[appLanguage].PROGRAM}/${encodeId(id)}`) : null;
};

export const getRecordingDetailURL = (recording: Recording, locationParamsPath: DetailLocationParamsPath, appLanguage: string): string => {
    const { id } = recording;
    return id ? buildDetailPageUrl(locationParamsPath ?? {}, `/${DetailRouteDomain[appLanguage].RECORDING}/${encodeId(id)}`) : null;
};

export const getVodMovieDetailsURL = (movie: Movie, locationParamsPath: DetailLocationParamsPath, appLanguage: string): string => {
    const { id } = movie;

    return id ? buildDetailPageUrl(locationParamsPath ?? {}, `/${DetailRouteDomain[appLanguage].VOD_MOVIE}/${encodeId(id)}`) : null;
};

export const getSeriesDetailUrl = (series: Series, locationParamsPath: DetailLocationParamsPath, appLanguage: string): string => {
    const { id, isVOD } = series;
    const detailDomain = isVOD ? DetailRouteDomain[appLanguage].VOD_SERIES : DetailRouteDomain[appLanguage].TV_SERIES;

    return id ? buildDetailPageUrl(locationParamsPath ?? {}, `/${detailDomain}/${encodeId(id)}`) : null;
};

export const getSeriesDetailsUrl = (
    seriesId: string,
    isVod: boolean,
    locationParamsPath: DetailLocationParamsPath,
    appLanguage: string
): string => {
    const detailDomain = isVod ? DetailRouteDomain[appLanguage].VOD_SERIES : DetailRouteDomain[appLanguage].TV_SERIES;

    return seriesId ? buildDetailPageUrl(locationParamsPath ?? {}, `/${detailDomain}/${encodeId(seriesId)}`) : null;
};

export const getTVSeriesEpisodeDetailUrl = (
    episode: TVSeriesDetailEpisode,
    locationParamsPath: DetailLocationParamsPath,
    appLanguage: string
): string => {
    const { id, episodeId } = episode;

    return id
        ? buildDetailPageUrl(locationParamsPath, `/${DetailRouteDomain[appLanguage].TV_SERIES}/${encodeId(id)}?episode=${episodeId}`)
        : null;
};

export const getVODSeriesEpisodeDetailUrl = (
    episode: Episode,
    locationParamsPath: DetailLocationParamsPath,
    appLanguage: string
): string => {
    const { id, episodeId } = episode;

    return id
        ? buildDetailPageUrl(locationParamsPath, `/${DetailRouteDomain[appLanguage].VOD_SERIES}/${encodeId(id)}?episode=${episodeId}`)
        : null;
};

export const getCatchupPlayOptionDetailURL = (playOption: CatchupPlayerAssetInfo, appLanguage: string): string => {
    const { id: eventId, seriesId } = playOption;

    if (seriesId) {
        return `/${DetailRouteDomain[appLanguage].TV_SERIES}/${encodeId(seriesId)}?event=${eventId}`;
    }

    return `/${DetailRouteDomain[appLanguage].PROGRAM}/${encodeId(eventId)}`;
};

export const getVODPlayOptionDetailURL = (playOption: VodPlayerAssetInfo, appLanguage: string): string => {
    const { id, seriesId } = playOption;

    if (seriesId) {
        return `/${DetailRouteDomain[appLanguage].VOD_SERIES}/${encodeId(seriesId)}?episode=${id}`;
    }

    return `/${DetailRouteDomain[appLanguage].VOD_MOVIE}/${encodeId(id)}`;
};

/**
 * Player page urls
 */

export const getLivePlayerURL = (channelId: string, mode?: 'normal' | 'time_shift'): string =>
    `/play/live/${encodeId(channelId)}${mode ? `?mode=${mode}` : ''}`;
export const getCatchupPlayerURL = (eventId: string): string => `/play/catchup/${encodeId(eventId)}`;
export const getVodPlayerURL = (assetId: string): string => `/play/vod/${encodeId(assetId)}`;
export const getTrailerPlayerURL = (id: string): string => `/play/trailer/${encodeId(id)}`;
export const getRecordingPlayerURL = (id: string, initiator: RecordingPlayerInitiator = 'recording'): string =>
    `/play/recording/${encodeId(id)}${initiator !== 'recording' ? `?initiator=${initiator}` : ''}`;

export const getPlayerURLForCastMiniController = (contentId: string, contentType: CastAssetType) => {
    if (!contentId || !contentType) {
        return null;
    }

    switch (contentType) {
        default:
            return null;
        case 'LIVE_TV':
            return getLivePlayerURL(contentId);
        case 'CATCHUP':
            return getCatchupPlayerURL(contentId);
        case 'RECORDING':
            return getRecordingPlayerURL(contentId, 'broadcast');
        case 'VOD':
            return getVodPlayerURL(contentId);
        case 'TRAILER':
            return getTrailerPlayerURL(contentId);
    }
};

export const getPlayerUrlForPlayerProps = (props: PlayerProps): string => {
    if (!props || !props.type) {
        return null;
    }

    switch (props.type) {
        default:
            return null;
        case PlayingAssetType.LIVE:
            return getLivePlayerURL(props.params.channelId);
        case PlayingAssetType.CATCHUP:
            return getCatchupPlayerURL(props.params.broadcastId);
        case PlayingAssetType.RECORDING:
            return getRecordingPlayerURL(props.params.recordingId, 'broadcast');
        case PlayingAssetType.VOD:
            return getVodPlayerURL(props.params.assetId);
        case PlayingAssetType.TRAILER:
            return getTrailerPlayerURL(props.params.assetId);
    }
};
