import Api from '../../api/Api';
import { SubscribeType } from '../../types/Entitlement';
import ApplicationConfig from '../useConfig/ApplicationConfig';
import { MINUTES } from '../../utils/TimeUnit';

export type SubscriptionValidatorInfo = {
    packageId: string;
    type: SubscribeType;
    personalizedAssetId?: string;
};

export type ValidatorData = SubscriptionValidatorInfo & {
    nrOfTries: number;
    scheduleMaxTime: number;
};

export const NR_OF_RETRIES = 3;

export class SubscriptionPackageValidator {
    validatorData: ValidatorData = null;

    private readonly callback: (active: boolean, data: SubscriptionValidatorInfo) => void;

    private retryTimeoutRef: number = null;

    constructor(validatorData: ValidatorData, callback: (active: boolean, data: SubscriptionValidatorInfo) => void) {
        this.validatorData = validatorData;
        this.callback = callback;

        this.scheduleValidationCheck();
    }

    private scheduleValidationCheck = () => {
        clearTimeout(this.retryTimeoutRef);

        const retryTimeout = ApplicationConfig.app_config.product_settings.subscription_update_delay;

        if (this.validatorData.scheduleMaxTime === 0) {
            this.validatorData.scheduleMaxTime = Date.now() + MINUTES.toMillis(2);
        }
        this.retryTimeoutRef = window.setTimeout(() => {
            this.validate().catch(() => {});
        }, retryTimeout);
    };

    private validate = async () => {
        const isActive = await Api.checkPackageEntitlement(this.validatorData.packageId);

        this.validatorData.nrOfTries += 1;

        if (isActive) {
            this.callback(true, this.validatorData);
        } else if (this.validatorData.nrOfTries >= NR_OF_RETRIES) {
            this.callback(false, this.validatorData);
        } else {
            this.scheduleValidationCheck();
        }
    };

    public stopSchedule = () => {
        clearTimeout(this.retryTimeoutRef);
    };
}
