import { LivePlayerAssetInfo, PlayerEvents, PlayingAsset, PlayingMode } from '../../types/Player';
import { IPlayer } from './abstract/IPlayer';
import {
    isCatchupPlayerAsset,
    isLivePlayerAsset,
    isRecordingPlayerAsset,
    isTrailerPlayerAsset,
    isVodPlayerAsset,
} from '../../utils/fnTypeGuards';
import { MILLISECONDS, SECONDS } from '../../utils/TimeUnit';
import Api from '../../api/Api';
import ApplicationConfig from '../useConfig/ApplicationConfig';
import { isNow } from '../../utils/fnDate';
import { fetchCastTokens, tokens } from '../useAuth/AuthService';
import { castAssetType } from '../../utils/fnPlayerUI';
import CastHandler from '../cast/CastHandler';
import { isSafari } from '../../utils/fnDevices';
import { isCatchupAssetInPostPadding } from '../../utils/fnData';

class PlaybackManager {
    private playingAsset: PlayingAsset = null;

    private playerEngine: IPlayer = null;

    private playbackMode: PlayingMode = PlayingMode.NORMAL;

    private isPlaying = false;

    private playSession: string | null = null;

    private keepAliveInterval: null | number = null;

    private seekTime: number;

    private pauseLiveTime: number;

    private castConnected: boolean = false;

    private switchingMode: boolean = false;

    private timeShiftStarted: boolean = false;

    private timeUpdateReceived: boolean = false;

    private bufferEndReceived: boolean = false;

    private seekAppliedValidity: number = Infinity;

    private pauseOnLive: boolean = false;

    private isReplayCapable: boolean = false;

    private releaseSeekLockTimeout: number | null = null;

    private debug = false;

    private playbackModeChangeListener?: (mode: PlayingMode) => void;

    private assetChangedListener?: (asset: PlayingAsset) => void;

    private castInitialisedListener?: () => void;

    private log = (logType: 'log' | 'error' | 'info' | 'warn', ...args: any[]): void => {
        if (this.debug) {
            console[logType](`[PlaybackManager]`, ...args);
        }
    };

    private timeUpdatedListener = () => {
        this.timeUpdateReceived = true;
        this.onSeekApplied();
    };

    private bufferingListener = e => {
        if (e.buffering === false) {
            this.bufferEndReceived = true;
            this.onSeekApplied();
        }
    };

    /**
     * This logic is used to unfreeze the progress value after the playback is
     * initialised but also to avoid the jumping seekbar
     *
     */
    private onSeekApplied = () => {
        if (this.timeUpdateReceived && Date.now() > this.seekAppliedValidity) {
            if (this.castConnected) {
                const mediaInfo = CastHandler.getMediaInfo();

                if (mediaInfo.loaded === true && mediaInfo.mode === this.playbackMode && mediaInfo.playing) {
                    this.switchingMode = false;
                }
            }

            if (this.seekTime != null) {
                if (!this.switchingMode && this.playbackMode === PlayingMode.TIME_SHIFT) {
                    this.timeShiftStarted = true;
                    this.seekTime = null;
                    this.bufferEndReceived = false;
                    this.timeUpdateReceived = false;
                    this.seekAppliedValidity = Infinity;
                }

                if (!this.switchingMode && this.playbackMode === PlayingMode.NORMAL) {
                    this.seekTime = null;
                    this.bufferEndReceived = false;
                    this.timeUpdateReceived = false;
                    this.seekAppliedValidity = Infinity;
                }
            }
        } else {
            this.switchingMode = false;
        }
    };

    private onSeeked = (to: number, waitForSeconds: number) => {
        this.seekAppliedValidity = Date.now() + SECONDS.toMillis(waitForSeconds);
        if (to !== Infinity) {
            this.seekTime = to;
        }

        // in case something goes wrong unlock the seekbar
        this.releaseSeekLockTimeout = window.setTimeout(() => {
            this.timeShiftStarted = true;
            this.seekTime = null;
            this.bufferEndReceived = false;
            this.timeUpdateReceived = false;
            this.seekAppliedValidity = Infinity;
            this.releaseSeekLockTimeout = null;
        }, SECONDS.toMillis(waitForSeconds + 10));
    };

    private registerListeners = () => {
        if (this.playerEngine) {
            this.playerEngine.addEventListener(PlayerEvents.TIME_UPDATE, this.timeUpdatedListener);
            this.playerEngine.addEventListener(PlayerEvents.BUFFERING, this.bufferingListener);
        }
    };

    private unregisterListeners = () => {
        if (this.playerEngine) {
            this.playerEngine.removeEventListener(PlayerEvents.TIME_UPDATE, this.timeUpdatedListener);
            this.playerEngine.removeEventListener(PlayerEvents.BUFFERING, this.bufferingListener);
        }
    };

    private createPlaySession = (type: 'channel' | 'content', assetId: string, eventId: string = '') => {
        this.log('info', 'createPlaySession', type, assetId);
        return type === 'channel' ? Api.createLivePlaySession(assetId) : Api.createContentPlaySession(assetId, eventId);
    };

    private keepAlive = async () => {
        return Api.keepAliveSession(this.playSession);
    };

    private endPlaySession = async () => {
        if (this.playSession) {
            this.log('info', 'endPlaySession');
            const session = this.playSession;
            this.playSession = null;
            return Api.endSession(session);
        }

        return Promise.resolve(false);
    };

    private sameAssetIsPlaying = (asset: PlayingAsset) => {
        if (!this.playingAsset) return false;

        return (
            this.playingAsset.id === asset.id &&
            this.playingAsset.type === asset.type &&
            this.playingAsset.manifestUrl === asset.manifestUrl
        );
    };

    private keepAliveSessionHandler = () => {
        if (this.playingAsset && this.playingAsset.sessionId && this.playingAsset.keepAliveInterval) {
            this.playSession = this.playingAsset.sessionId;

            clearInterval(this.keepAliveInterval);
            this.keepAliveInterval = window.setInterval(async () => {
                this.log('info', 'keepAlive');
                await this.keepAlive();
            }, this.playingAsset.keepAliveInterval);
        }
    };

    private getBookmark = () => {
        if (
            this.playingAsset &&
            (isCatchupPlayerAsset(this.playingAsset) ||
                isRecordingPlayerAsset(this.playingAsset) ||
                isLivePlayerAsset(this.playingAsset) ||
                (isVodPlayerAsset(this.playingAsset) && this.playingAsset.titleId))
        ) {
            const duration = isVodPlayerAsset(this.playingAsset)
                ? this.playingAsset.duration
                : MILLISECONDS.toSeconds(this.playingAsset.endTime - this.playingAsset.startTime);
            const progressInPercentage = (this.playingAsset.bookmark * 100) / duration;

            if (progressInPercentage > 0 && progressInPercentage < ApplicationConfig.app_config.player_settings.bookmark_threshold) {
                return this.playingAsset.bookmark;
            }
        }
        return 0;
    };

    private setPlaybackMode = (mode: PlayingMode) => {
        this.playbackMode = mode;
        if (this.playbackModeChangeListener) {
            this.playbackModeChangeListener(mode);
        }
    };

    private seekLiveHandler = async (to: number, mode: PlayingMode) => {
        if (mode === PlayingMode.NORMAL) {
            await this.switchToTimeShift(to);
        } else if (this.getSeekMax() > 0 && Math.round(to) >= Math.round(this.getSeekMax())) {
            await this.switchToNormalMode();
        } else if (this.playerEngine) {
            this.onSeeked(to, this.castConnected ? 5 : 3);
            this.playerEngine.seekTo(to);
        }
    };

    private switchSession = async (mode: PlayingMode, id: string) => {
        await this.endPlaySession();

        if (this.castConnected) {
            // TODO: review me
            await this.cast({
                ...this.playingAsset,
                id,
            });
        } else {
            const createSessionResponse = await this.createPlaySession(mode === PlayingMode.NORMAL ? 'channel' : 'content', id);

            if (createSessionResponse?.response) {
                this.playSession = createSessionResponse.response.sessionId;

                await this.updatePlayingAsset({
                    ...this.playingAsset,
                    keepAliveInterval: createSessionResponse.response.keepAliveInterval,
                    manifestUrl: createSessionResponse.response.manifestUrl,
                    sessionId: createSessionResponse.response.sessionId,
                });
            }
        }
    };

    public getPrePadding = (): number => {
        if (this.playingAsset && (isCatchupPlayerAsset(this.playingAsset) || isRecordingPlayerAsset(this.playingAsset))) {
            return this.playingAsset.prePadding ?? 0;
        }

        return 0;
    };

    public getPostPadding = (): number => {
        if (this.playingAsset && (isCatchupPlayerAsset(this.playingAsset) || isRecordingPlayerAsset(this.playingAsset))) {
            return this.playingAsset.postPadding ?? 0;
        }
        return 0;
    };

    public getStartTime = () => {
        const castProgress = CastHandler.getCastProgress();

        if (castProgress !== null) {
            return castProgress;
        }

        if (isLivePlayerAsset(this.playingAsset)) {
            if (this.playbackMode === PlayingMode.TIME_SHIFT && this.seekTime) {
                return this.seekTime;
            }
        }

        return this.getBookmark() + this.getPrePadding();
    };

    public setEngine = (engine: IPlayer) => {
        if (this.playerEngine) {
            this.log('info', 'engine already set');
            return;
        }

        this.log('info', 'setEngine');
        this.playerEngine = engine;
        this.registerListeners();
    };

    public cast = async (castAsset: PlayingAsset) => {
        this.log('info', 'cast');
        try {
            await this.endPlaySession();
            if (this.playerEngine) {
                this.playerEngine.resetPlayer();
            }

            const castTokens = await fetchCastTokens(tokens().accessToken);

            const mediaInfo = new window.chrome.cast.media.MediaInfo(
                isLivePlayerAsset(castAsset) ? castAsset.channelId : castAsset.id,
                isLivePlayerAsset(castAsset) ? 'LIVE' : 'BUFFERED'
            );

            const playFrom = isLivePlayerAsset(castAsset) && this.seekTime;

            if (castAsset) {
                mediaInfo.customData = {
                    ...{
                        accessToken: castTokens.access_token,
                        refreshToken: castTokens.refresh_token,
                        clientId: ApplicationConfig.app_config.auth.client_id,
                        assetType: castAssetType(this.playingAsset),
                        playMode: this.playbackMode,
                        startPosition: playFrom,
                    },
                    ...(this.playerEngine?.getSnapshot() ?? {}),
                };

                this.log('info', 'casting media info: ', mediaInfo);
                await CastHandler.loadMedia(new window.chrome.cast.media.LoadRequest(mediaInfo));

                if (this.castInitialisedListener) {
                    this.castInitialisedListener();
                }
            }
        } catch (e) {
            this.log('error', e);
        }
    };

    public updatePlayingAsset = async (asset: PlayingAsset, preparedCallback?: () => void, forceLoad?: boolean) => {
        if (this.sameAssetIsPlaying(asset) && !forceLoad) {
            return;
        }

        const url = new URL(window.location.href);
        const startFromLive = url.searchParams.get('live') === 'true';

        this.log('info', 'updatePlayingAsset', asset);

        this.playingAsset = asset;

        if (
            isLivePlayerAsset(this.playingAsset) &&
            this.playbackMode === PlayingMode.NORMAL &&
            this.playingAsset.replayContentId &&
            !startFromLive
        ) {
            const bookmark = this.getBookmark();
            this.switchToTimeShift(bookmark < this.getSeekMax() ? bookmark : 0.1);

            return;
        }

        if (this.assetChangedListener) {
            this.assetChangedListener(this.playingAsset);
        }

        if (this.castConnected) {
            await this.cast(this.playingAsset);
        } else {
            this.keepAliveSessionHandler();

            const startTime = this.getStartTime();

            if (this.playerEngine) {
                this.playerEngine.setAsset(
                    this.playingAsset,
                    startTime,
                    !isVodPlayerAsset(this.playingAsset) && !isTrailerPlayerAsset(this.playingAsset) ? 'tv' : 'vod',
                    () => {
                        if (!this.pauseOnLive) {
                            this.log('info', 'play after load');
                            this.setPlayingState(true);
                        }

                        if (preparedCallback) {
                            preparedCallback();
                        }

                        this.switchingMode = false;
                        this.pauseOnLive = false;
                    }
                );
            }
        }
    };

    public getDuration = () => {
        if (!this.playingAsset || !this.playerEngine) {
            return 0;
        }

        if (isLivePlayerAsset(this.playingAsset)) {
            return MILLISECONDS.toSeconds(this.playingAsset.endTime - this.playingAsset.startTime);
        }

        if (isCatchupPlayerAsset(this.playingAsset) && isCatchupAssetInPostPadding(this.playingAsset)) {
            const { prePadding, postPadding } = this.playingAsset;
            const paddingTime = SECONDS.toMillis(postPadding ?? 0) + SECONDS.toMillis(prePadding ?? 0);
            return MILLISECONDS.toSeconds(this.playingAsset.endTime + paddingTime - this.playingAsset.startTime);
        }

        return this.playerEngine.getDuration() ?? 1;
    };

    public getProgress = () => {
        if (!this.playingAsset || !this.playerEngine) {
            return 0;
        }

        if (this.pauseLiveTime != null) {
            return this.pauseLiveTime;
        }

        if (isLivePlayerAsset(this.playingAsset) && this.playbackMode === PlayingMode.NORMAL) {
            const now = Date.now();
            return Math.max(0, MILLISECONDS.toSeconds(now - this.playingAsset.startTime));
        }

        if (isLivePlayerAsset(this.playingAsset) && this.playbackMode === PlayingMode.TIME_SHIFT) {
            if (!this.timeShiftStarted && this.seekTime != null) {
                return this.seekTime;
            }

            if (isSafari()) {
                return this.seekTime ?? this.playerEngine.getCurrentTime();
            }

            if (this.castConnected) {
                return this.playerEngine.getCurrentTime();
            }

            let current = this.playerEngine.getCurrentTime() * 1000;
            if (!current) {
                current = Date.now();
            }

            return MILLISECONDS.toSeconds(current);
        }

        return this.seekTime ?? this.playerEngine.getCurrentTime();
    };

    public getSecondaryProgress = (): number => {
        if (isLivePlayerAsset(this.playingAsset) && this.playbackMode === PlayingMode.TIME_SHIFT) {
            if (isNow(this.playingAsset.startTime, this.playingAsset.endTime)) {
                return MILLISECONDS.toSeconds(Date.now() - this.playingAsset.startTime);
            }
            return 0;
        }

        return this.getProgress();
    };

    public getSeekMax = () => {
        if (isLivePlayerAsset(this.playingAsset)) {
            // return elapsed time if the asset is still live
            if (isNow(this.playingAsset.startTime, this.playingAsset.endTime)) {
                return MILLISECONDS.toSeconds(Date.now() - this.playingAsset.startTime);
            }
            return 0;
        }

        return this.getDuration();
    };

    public getThumbnail = async (time: number) => {
        if (!this.playerEngine) {
            return null;
        }
        const imageTrack = this.playerEngine.getThumbnailTrack();

        if (!imageTrack) {
            return null;
        }
        const { id } = imageTrack;
        const thumb = await this.playerEngine.getThumbnails(id, time);

        if (!thumb) {
            return null;
        }

        return thumb;
    };

    private pauseLiveStream = () => {
        const progress = this.getProgress();

        this.pauseLiveTime = progress;
        this.pauseOnLive = true;
        this.setPlaybackMode(PlayingMode.TIME_SHIFT);

        this.log('info', `pause live stream at ${progress}`);

        this.playerEngine.pause();
    };

    private playPausedLiveStream = async () => {
        this.log('info', `play  paused live stream from ${this.pauseLiveTime}`);

        this.pauseOnLive = false;
        await this.switchToTimeShift(this.pauseLiveTime);
        this.pauseLiveTime = null;
    };

    public setPlayingState = async (playing: boolean, timeShiftPlayed = false) => {
        this.isPlaying = playing;

        if (this.isPlaying) {
            if (isLivePlayerAsset(this.playingAsset) && this.pauseLiveTime && !timeShiftPlayed) {
                await this.playPausedLiveStream();
            }
            if (this.playerEngine) {
                this.playerEngine.play();
            }
        } else {
            if (isLivePlayerAsset(this.playingAsset) && this.playbackMode !== PlayingMode.TIME_SHIFT && this.isReplayCapable) {
                return this.pauseLiveStream();
            }

            this.playerEngine.pause();
        }

        return null;
    };

    public seek = async (to: number) => {
        this.pauseLiveTime = null;
        if (this.switchingMode || !this.playerEngine) {
            return;
        }

        this.log('info', 'seek', to, this.playingAsset.type, this.playbackMode);

        this.timeUpdateReceived = false;

        if (!isLivePlayerAsset(this.playingAsset)) {
            this.onSeeked(to, this.castConnected ? 4 : 2);
            this.playerEngine.seekTo(to);
        } else {
            if (this.playbackMode === PlayingMode.NORMAL && this.getSeekMax() > 0 && Math.round(to) >= Math.round(this.getSeekMax())) {
                // can't seek to the future
                return;
            }

            // live is already paused and ready for the mode change, so just update the pause live time
            if (this.pauseOnLive) {
                this.pauseLiveTime = to;
                return;
            }

            await this.seekLiveHandler(to, this.playbackMode);
        }
    };

    public switchToTimeShift = async (from: number) => {
        if (from < 0) {
            return;
        }

        this.log('info', 'switchToTimeShift', from);

        this.switchingMode = true;
        this.timeShiftStarted = false;
        this.setPlaybackMode(PlayingMode.TIME_SHIFT);
        this.onSeeked(from, this.castConnected ? 5 : 3);

        await this.switchSession(PlayingMode.TIME_SHIFT, (this.playingAsset as LivePlayerAssetInfo).replayContentId);
    };

    public switchToNormalMode = async () => {
        this.log('info', 'switchToNormalMode');

        this.switchingMode = true;

        await this.switchSession(PlayingMode.NORMAL, (this.playingAsset as LivePlayerAssetInfo).channelId);
        this.setPlaybackMode(PlayingMode.NORMAL);
    };

    public startOver = async () => {
        if (!this.playingAsset) {
            return;
        }

        await this.seek(isLivePlayerAsset(this.playingAsset) ? 0.1 : this.getPrePadding());
    };

    public setCastConnected = async (connected: boolean) => {
        this.castConnected = connected;

        this.log('info', 'setCastConnected', connected);

        if (this.playingAsset) {
            this.log('info', 'with asset');

            if (this.castConnected) {
                await this.cast(this.playingAsset);
            } else {
                const createSessionResponse = await this.createPlaySession(
                    !isLivePlayerAsset(this.playingAsset) || this.playbackMode === PlayingMode.TIME_SHIFT ? 'content' : 'channel',
                    isLivePlayerAsset(this.playingAsset) && this.playbackMode === PlayingMode.TIME_SHIFT
                        ? (this.playingAsset as LivePlayerAssetInfo).replayContentId
                        : this.playingAsset.contentId
                );

                if (createSessionResponse?.response) {
                    this.playSession = createSessionResponse.response.sessionId;

                    await this.updatePlayingAsset(
                        {
                            ...this.playingAsset,
                            keepAliveInterval: createSessionResponse.response.keepAliveInterval,
                            manifestUrl: createSessionResponse.response.manifestUrl,
                            sessionId: createSessionResponse.response.sessionId,
                        },
                        undefined,
                        true
                    );
                }
            }
        }

        this.unregisterListeners();
        this.registerListeners();
    };

    public setPlayingMode = (mode: PlayingMode = PlayingMode.NORMAL) => {
        this.log('info', 'setPlayingMode', mode);
        this.playbackMode = mode;
    };

    public setPlaybackModeChangeListener = (listener: (mode: PlayingMode) => void) => {
        this.playbackModeChangeListener = listener;
    };

    public setAssetChangedListener = (listener: (asset: PlayingAsset) => void) => {
        this.assetChangedListener = listener;
    };

    public setCastInitialisedListener = (listener: () => void) => {
        this.castInitialisedListener = listener;
    };

    public isPlayerLoaded = () => {
        return this.playingAsset && this.playerEngine;
    };

    public setIsReplayCapable = (isReplayCapable: boolean) => {
        this.isReplayCapable = isReplayCapable;
    };

    public destroy = async () => {
        this.log('info', 'destroy');

        await this.endPlaySession();
        this.unregisterListeners();

        if (this.playerEngine) {
            this.playerEngine.resetPlayer(true);
        }

        this.playerEngine = null;
        this.playingAsset = null;
        this.switchingMode = false;
        this.pauseLiveTime = null;
        this.pauseOnLive = false;
        this.isReplayCapable = false;

        this.setPlaybackMode(PlayingMode.NORMAL);

        // this.playbackModeChangeListener = null;
        // this.assetChangedListener = null;

        if (this.keepAliveInterval) {
            clearInterval(this.keepAliveInterval);
            this.keepAliveInterval = null;
        }

        if (this.releaseSeekLockTimeout) {
            clearTimeout(this.releaseSeekLockTimeout);
            this.releaseSeekLockTimeout = null;
        }
    };
}

export default new PlaybackManager();
