import { PlayingAsset, PropTypesAdditional, PropTypesPlayerUICommon } from '../../types/Player';
import { isVodPlayerAsset } from '../../utils/fnTypeGuards';
import { secondsToTime } from '../../utils/fnDate';
import { castEnabled, getTrailerVODPlayerActionComponents, getTrailerVODPlayerControls } from '../../utils/fnPlayerUI';

export const useVODPlayerUI = (common: PropTypesPlayerUICommon, asset: PlayingAsset): PropTypesAdditional => {
    if (!isVodPlayerAsset(asset)) {
        return null;
    }

    const { duration, releaseYear, countries, title, subtitle, genres, description, cast, originalLanguages, captionLanguages } = asset;

    const getMetaData = () => {
        if (!asset) return [];
        const metaData = [];

        if (releaseYear || countries.length) {
            metaData.push(`${countries?.slice(0, 2).join(',')} ${releaseYear || ''}`);
        }

        if (genres && genres.length) {
            metaData.push(
                genres
                    ?.map(genre => genre.Value)
                    .slice(0, 2)
                    .join(', ')
            );
        }

        if (duration) {
            metaData.push(secondsToTime(duration));
        }

        return metaData;
    };

    const getControls = () => getTrailerVODPlayerControls(common.hasTracks, true, castEnabled());

    const controls = getControls();
    const { actionPopup, mobileOverlayActionHandlers } = getTrailerVODPlayerActionComponents(controls);

    return {
        logo: null,
        metaData: getMetaData(),
        embeddedPlayerMetadata: null,
        contentMarker: null,
        metaIcons: [],
        disabledControls: [],
        controls,
        seekEnabled: true,
        title,
        subtitle,
        actionPopup,
        mobileOverlayActionHandlers,
        moreOverlayMetaData: null,
        description,
        cast,
        originalLanguages,
        captionLanguages,
    };
};
