import PropTypes from 'prop-types';
import React, { CSSProperties } from 'react';
import { useHistory } from 'react-router';
import { ButtonInner, ButtonWrapper } from './Button.css';
import { getLocalStorage } from '../../../utils/fnStorage';
import { TrackingElementPropTypes } from '../../../types/Tracking';
import { useTracking } from '../../../providers/useTracking/TrackingContext';
import { trackingClickHandler } from '../../../utils/fnTracking';

type ButtonPropType = TrackingElementPropTypes & {
    link?: string;
    className?: string;
    isPrimary?: boolean;
    label?: string;
    onClick?: (e) => void;
    isDisabled?: boolean;
    dataTestId?: string;
    openNewTab?: boolean;
    selected?: boolean;
};

const Button: React.FC<ButtonPropType> = ({
    onClick,
    link,
    className,
    label,
    isDisabled,
    children,
    dataTestId,
    isPrimary = false,
    openNewTab = false,
    selected = false,
    ...rest
}) => {
    const history = useHistory();
    const track = useTracking();

    const clickHandler = e => {
        trackingClickHandler(e, onClick, rest, track);

        if (link) {
            e.stopPropagation();
            e.preventDefault();

            if (!openNewTab) {
                history.push(link);
            } else {
                window.open(link, '_blank');
            }
        }
    };
    const isMobile = getLocalStorage('isMobile');

    const style: CSSProperties = {};
    if (isDisabled) {
        style.pointerEvents = 'none';
    }

    return (
        <ButtonWrapper
            data-test-id={dataTestId}
            style={style}
            className={`${className}`}
            onClick={isDisabled ? null : clickHandler}
            {...rest}
        >
            <ButtonInner
                data-test-id={'button-moreinfo'}
                className={`${!isMobile ? '' : 'no-hover '}`}
                isDisabled={isDisabled}
                isPrimary={isPrimary}
                isSelected={selected}
            >
                {children}
                <span className={'label'}>{label}</span>
            </ButtonInner>
        </ButtonWrapper>
    );
};

Button.propTypes = {
    dataTestId: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    link: PropTypes.string,
    isPrimary: PropTypes.bool,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
};

export default Button;
