import ApplicationConfig from '../providers/useConfig/ApplicationConfig';
import { getReplayWindowLength } from './fnData';
import { getLocalStorage } from './fnStorage';

export type Variable =
    | 'BroadcastId'
    | 'CategoryId'
    | 'ChannelId'
    | 'ChannelIds'
    | 'CpeId'
    | 'ContentId'
    | 'EventId'
    | 'IntervalEnd'
    | 'IntervalStart'
    | 'OfferId'
    | 'ProductId'
    | 'VodId'
    | 'TVSeriesId'
    | 'VodSeriesId'
    | 'OffsetStart'
    | 'ProgType'
    | 'Genre'
    | 'SeasonId'
    | 'Limit'
    | 'ResourceId'
    | 'SeriesIds'
    | 'Bookmark'
    | 'TitleId'
    | 'TitleIds'
    | 'RecordingId'
    | 'SubscriptionPackageId'
    | 'CatchupWindowDays'
    | 'SearchQuery'
    | 'ProductLinear'
    | 'ProductNPVR'
    | 'AdultFilter'
    | 'AdultFilterFirst'
    | 'EncodingProfile'
    | 'SubQueryContentsWithTsTv'
    | 'SubQueryContentsWithMixedContent'
    | 'SubQueryContentsForVod'
    | 'Order';

type VariablesMap = {
    [key in Variable]?: string;
};

export class RequestBodyBuilder {
    private template: string = '';

    private values: VariablesMap = {};

    private static applyCommonFilters = (builder: RequestBodyBuilder) => {
        const adultFilter = getLocalStorage('enAFMsDcs15dDJskUn');

        builder.set('ProductLinear', ApplicationConfig.app_config.product_settings.linear_prefix);
        builder.set('ProductNPVR', ApplicationConfig.app_config.product_settings.npvr_prefix);
        builder.set('CatchupWindowDays', `${getReplayWindowLength()}`);
        builder.set('EncodingProfile', `EncodingProfile!=MPEG2&amp;&amp;EncodingProfile!=HLSRT&amp;&amp;EncodingProfiles.compatible==true`);
        builder.set('AdultFilter', adultFilter === true ? '' : '&amp;&amp;IsAdult==false');
        builder.set('AdultFilterFirst', adultFilter === true ? '' : 'IsAdult==false');
    };

    public static create = (template: string): RequestBodyBuilder => {
        const builder = new RequestBodyBuilder(template);
        RequestBodyBuilder.applyCommonFilters(builder);

        return builder;
    };

    public set = (variable: Variable, value: string): RequestBodyBuilder => {
        this.values[variable] = value;

        return this;
    };

    public setMultiple = (variable: Variable, subTemplate: string, subTemplateVariable: Variable, values: string[]) => {
        let concat = '';

        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const val of values) {
            const subBuilder = RequestBodyBuilder.create(subTemplate);
            concat += subBuilder.set(subTemplateVariable, val).build();
        }

        this.values[variable] = concat;

        return this;
    };

    public setSubQuery = (variable: Variable, subTemplate: string, subTemplateVariables?: { [key in Variable]?: string }) => {
        const subTemplateBuilder = RequestBodyBuilder.create(subTemplate);
        RequestBodyBuilder.applyCommonFilters(subTemplateBuilder);

        const subTemplateVariableKeys = Object.keys(subTemplateVariables ?? {});

        subTemplateVariableKeys.forEach(key => {
            subTemplateBuilder.set(key as Variable, subTemplateVariables[key]);
        });

        this.set(variable, subTemplateBuilder.build());

        return this;
    };

    public build = (): string => {
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const key in this.values) {
            this.template = RequestBodyBuilder.replaceVariable(this.template, key as Variable, this.values[key]);
        }

        return this.template;
    };

    private static varToKey = (variable: Variable) => `{{${variable}}}`;

    private static replaceVariable = (template: string, variable: Variable, value: string): string => {
        let idx = 0;
        const key = RequestBodyBuilder.varToKey(variable);

        // eslint-disable-next-line no-cond-assign
        while ((idx = template.indexOf(RequestBodyBuilder.varToKey(variable), idx)) >= 0) {
            template = template.replace(key, value);
        }

        return template;
    };

    private constructor(template: string) {
        this.template = template;
    }
}
