import * as Sentry from '@sentry/react';
import { getProcessEnv } from 'utils/fnEnvironments';
import { captureException } from 'utils/fnError';
import { ConfigError, ConfigResponse } from '../../types/Config';
import { getLocalStorage } from '../../utils/fnStorage';

const configApiKey = '117a3f89a361d27ad13549f47a672431704cc30d2109eff444d0f1ea09e64ece';

const commonHeaders = {
    acceptAllHeader: {
        Accept: '*/*',
    },
    acceptJsonHeader: {
        Accept: 'application/json',
    },
    contentTypeHeader: {
        'Content-Type': 'application/json',
    },
    configHeaders: {
        'x-operating-system': 'browser',
        'x-device-type': 'generic',
        Authorization: `Bearer ${configApiKey}`,
    },
};

const fullUrl = (path: string = ''): string => {
    let endpoint = getProcessEnv('CONFIG_ENDPOINT', '');

    if (endpoint.slice(-1) === '/') {
        endpoint = endpoint.slice(0, -1);
    }

    if (path !== '' && path.slice(0, 1) !== '/') {
        path = ['/', path].join();
    }

    return `${endpoint}${path}/v2`;
};

const defaultErrorResponseParser = async (response: UnPromisify<ReturnType<typeof fetch>>): Promise<ConfigError> => {
    return {
        code: response.status,
        msg: response.statusText,
    };
};

const captureConfigError = error => {
    console.error(error);
    Sentry.captureException(error);
};

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const parseServerResponse = async <T>(
    response: UnPromisify<ReturnType<typeof fetch>>,
    parsingRules: {
        [key: number]: (response: UnPromisify<ReturnType<typeof fetch>>) => T;
    }
): Promise<T> => {
    if (response.status in parsingRules) {
        if (response.status === 422) {
            captureConfigError(new Error(`Received unexpected status code: ${response.status} ${response.statusText}`));
        }
        return parsingRules[response.status](response);
    }
    throw new Error(`Received unexpected status code: ${response.status} ${response.statusText}`);
};

const getConfigServiceHeaders = () => {
    const configVersion = getLocalStorage('configVersion');
    let headers = commonHeaders.configHeaders;
    // eslint-disable-next-line no-underscore-dangle
    const version = configVersion?.length ? configVersion : window.__VERSION__.replace('-', '.');

    if (version && version.length) {
        headers = {
            ...headers,
            ...{
                'x-operating-system-version': version,
                'x-client-app-version': version,
            },
        };
    }

    return headers;
};

const addEnvironment = (url: string): string => {
    if (!url) return null;

    const environment = getProcessEnv('NODE_ENV', 'development');
    return `${url}?customFilters=environment[${environment}]`;
};

export const downloadConfig = async (): Promise<ConfigResponse | ConfigError | any> => {
    const timeout = 5000;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    try {
        const url = fullUrl();
        const serverResponse = await fetch(addEnvironment(url), {
            method: 'GET',
            headers: {
                ...commonHeaders.contentTypeHeader,
                ...commonHeaders.acceptJsonHeader,
                ...getConfigServiceHeaders(),
            },
            signal: controller.signal,
        });

        clearTimeout(id);

        return await parseServerResponse<Promise<ConfigResponse | ConfigError>>(serverResponse, {
            200: async response => {
                return (await response.json()) as ConfigResponse;
            },
            401: defaultErrorResponseParser,
            422: defaultErrorResponseParser,
        });
    } catch (error) {
        captureConfigError(error);
        throw error;
    }
};

export const downloadTranslations = async (url): Promise<any> => {
    const timeout = 5000;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    try {
        const serverResponse = await fetch(addEnvironment(url), {
            method: 'GET',
            headers: {
                ...commonHeaders.contentTypeHeader,
                ...commonHeaders.acceptJsonHeader,
                ...getConfigServiceHeaders(),
            },
            signal: controller.signal,
        });

        clearTimeout(id);

        return await parseServerResponse<Promise<ConfigResponse | ConfigError>>(serverResponse, {
            200: async response => {
                return (await response.json()) as ConfigResponse;
            },
            401: defaultErrorResponseParser,
            422: defaultErrorResponseParser,
        });
    } catch (error) {
        if (captureException(error)) {
            captureConfigError(error);
        }
        throw error;
    }
};
