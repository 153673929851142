import React, { useEffect, useState } from 'react';
import { isIOS, isAndroid } from 'react-device-detect';
import { CloseButton, Description, HintText, Logo, Name, SmartAppBannerWrapper, ViewButton } from './SmartAppBanner.css';
import { getLocalStorage, setLocalStorage } from '../../utils/fnStorage';
import icons from '../../style';
import { usePlayer } from '../../providers/player/PlayerContext';
import { useDetailLocationParams } from '../../hooks/useDetailLocationParams';

export type SmartAppBannerPropTypes = {
    name: string;
    hintText: string;
    viewButton: string;
    androidAppId: string;
    iOsAppId: string;
    onOpen: () => void;
    onClose: () => void;
};

export const SmartAppBanner: React.FC<SmartAppBannerPropTypes> = ({
    name,
    hintText,
    viewButton,
    androidAppId,
    iOsAppId,
    onOpen,
    onClose,
}) => {
    const userDismissedSmartAppBanner = getLocalStorage('userDismissedSmartAppBanner');
    const [userDismissedSmartAppBannerState, setUserDismissedSmartAppBannerState] = useState(userDismissedSmartAppBanner);
    const { isDetailPage } = useDetailLocationParams();
    const { isOpen: isPlayerOpen } = usePlayer();
    const isVisible = !userDismissedSmartAppBannerState && (isIOS || isAndroid) && !isPlayerOpen;

    const closeAppBanner = () => {
        setUserDismissedSmartAppBannerState(true);
        setLocalStorage('userDismissedSmartAppBanner', true);
    };

    const createAppURL = () => {
        if (isAndroid) {
            return `https://play.google.com/store/apps/details?id=${androidAppId}`;
        }
        if (isIOS) {
            return `itms-apps://apple.com/app/id${iOsAppId}`;
        }
        return null;
    };

    useEffect(() => {
        if (isVisible && onOpen) {
            console.log(createAppURL());
            onOpen();
        }
        if (!isVisible && onClose) {
            onClose();
        }
    }, [isVisible]);

    return isVisible ? (
        <SmartAppBannerWrapper isFixedToTop={isDetailPage}>
            <CloseButton onClick={closeAppBanner} svg={icons.closeIcon} />
            <Logo />
            <Description>
                <Name>{name}</Name>
                <HintText>{hintText}</HintText>
            </Description>
            <ViewButton href={createAppURL()} target={'_blank'}>
                {viewButton}
            </ViewButton>
        </SmartAppBannerWrapper>
    ) : null;
};
