import { matchPath } from 'react-router';
import { useEffect, useState } from 'react';
import { useWrapLocation } from './useWrapRouter/useWrapLocation';
import { detailPageSectionRouting } from '../utils/fnRouting';
import { DetailRoutes } from '../types/RouteTypes';

export type DetailLocationParams = {
    section?: string;
    subSection?: string;
    sectionId?: string;
    id?: string;
};

export type DetailLocationParamsPath = {
    params?: DetailLocationParams;
    isDetailPage?: boolean;
    pathname?: string;
};

export const detailRouteMatch = (path: string) => {
    let match = null;
    const routeSchemas = [];

    detailPageSectionRouting.forEach(sectionConfig => {
        Object.getOwnPropertyNames(DetailRoutes).forEach(language =>
            Object.getOwnPropertyNames(DetailRoutes[language]).forEach(s => {
                routeSchemas.push(`${sectionConfig}${DetailRoutes[language][s]}`);
            })
        );
    });

    routeSchemas.forEach(route => {
        const matchByRoute = matchPath(path, {
            path: route,
        });

        if (matchByRoute) {
            match = matchByRoute;
        }
    });

    return match;
};

export const useDetailLocationParams = (): DetailLocationParamsPath => {
    const { pathname } = useWrapLocation();
    const [params, setParams] = useState<DetailLocationParams>();
    const [isDetailPage, setIsDetailPage] = useState(true);

    useEffect(() => {
        const match = detailRouteMatch(pathname);

        setIsDetailPage(match != null);
        setParams(match?.params ?? {});
    }, [pathname]);

    return {
        params,
        pathname,
        isDetailPage,
    };
};
