import axios from 'axios';
import { Paginated } from '../../types/ApiTypes';
import Api from '../../api/Api';
import { ChunkLoader } from '../../utils/ChunkLoader/ChunkLoader';
import { Datasource } from '../../types/Config';
import { Collection } from '../../utils/fnParser';
import { CoverAsset, PlaceholderAsset } from '../../types/Asset';
import { AssetTypes } from '../../types/Common';

export class MiniEpgChunkDataLoader extends ChunkLoader {
    private readonly dataSource: Datasource;

    private readonly dataParser;

    constructor(dataSource: Datasource, dataParser, pagination: Paginated) {
        super(pagination);

        this.dataSource = dataSource;
        this.dataParser = dataParser;
    }

    private generatePlaceholderData = (): PlaceholderAsset[] => {
        const returnData: PlaceholderAsset[] = [];

        for (let i = this.pagination.start; i < this.pagination.start + this.pagination.limit; i += 1) {
            returnData.push({
                id: `dummy-${this.pagination.start}-${i}`,
                isPortrait: false,
                type: AssetTypes.placeholder,
            });
        }

        return returnData;
    };

    protected requestCreator = async () => {
        return Api.fetchByDataSource<Collection<CoverAsset>>(
            this.dataSource,
            this.dataParser,
            'PUT',
            new axios.CancelToken(executor => {
                this.canceller = executor;
            }),
            this.pagination
        );
    };

    public fillWithPlaceholder = (enabled: boolean) => {
        if (enabled) {
            this.data = {
                items: this.generatePlaceholderData(),
                scheduleReload: Infinity,
                totalItems: -1,
            };

            if (this.requestResponseCallback) {
                this.requestResponseCallback(
                    {
                        pagination: this.pagination,
                        response: {
                            response: this.data,
                            error: null,
                        },
                    },
                    null
                );
            }
        }

        return this;
    };
}
