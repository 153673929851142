import SVGInline from 'react-svg-inline';
import { css } from 'styled-components';
import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold, overCardZIndex } from '../../../style/styled-components/cssGlobalVariables';
import { Breakpoints, MQ, MQ_NOT_TOUCH } from '../../../style/styled-components/cssMediaQueries';
import { hexToRgba } from '../../../utils/fnColors';

export const animationType = 'ease-in-out';
export const animationDuration = '150ms';
export const animationDelay = '150ms';

export const ImageWrapper = styled.div`
    padding-top: 56.25%;
    width: 100%;
    position: relative;
    overflow: hidden;
    transition: all ${animationDuration} ${animationType} ${animationDelay};
    border-radius: 5px;

    img {
        width: 100%;
        position: absolute;
        top: 0;
        height: 100%;
        transition: all ${animationDuration} ${animationType} ${animationDelay};
    }
`;

export const InfoWrapper = styled.div`
    width: 100%;
    height: 44px;
    padding: 10px 0px;
    transition: height ${animationDuration} ${animationType} ${animationDelay},
        padding ${animationDuration} ${animationType} ${animationDelay};
`;

export const Title = styled.p`
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 16px;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: ${fontBold};
`;

export const MetaData = styled.p`
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 12px;
    line-height: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    opacity: 0;
    transition: opacity 300ms ${animationType} 0ms;
`;

export const PlayIconSVG = styled(SVGInline)`
    width: 100%;
    height: 100%;
    line-height: 73px;
    text-align: center;
    pointer-events: none;
`;

export const PlayIcon = styled.div`
    background-color: ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.5)};
    width: 56px;
    height: 56px;
    border-radius: 56px;
    position: absolute;
    left: calc(50% - 28px);
    top: calc(50% - 28px);
    z-index: ${overCardZIndex};
    opacity: 0;
    transition: opacity 300ms ${animationType} 0ms;

    .SVGInline svg {
        margin: 0 auto;
        width: 21px;
        height: 100%;
    }
`;

export const CardCollectionWrapperInner = styled.div`
    padding-top: 56.25%;
    padding-bottom: 44px;
    position: relative;
`;

export const CardCollectionTileContainer = styled.div`
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    cursor: pointer;
    box-shadow: 0 4px 8px ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.05)};
    transition: width ${animationDuration} ${animationType} ${animationDelay},
        height ${animationDuration} ${animationType} ${animationDelay}, top ${animationDuration} ${animationType} ${animationDelay},
        left ${animationDuration} ${animationType} ${animationDelay}, transform ${animationDuration} ${animationType} ${animationDelay};
`;

export const CollectionCardWrapper = styled.div<{ defaultRenderSecondLine?: boolean }>`
    display: block;
    float: left;
    position: relative;
    z-index: 1;
    transition-property: z-index;
    transition-delay: ${animationDelay};
    padding: 8px 8px;
    width: 50%;

    ${MQ(Breakpoints.s)} {
        width: 33.333%;
    }

    ${MQ(Breakpoints.m)} {
        width: 25%;
    }

    ${MQ(Breakpoints.xxxl)} {
        width: 20%;
    }

    ${MQ_NOT_TOUCH} {
        &:hover {
            z-index: 2;

            ${CardCollectionTileContainer} {
                width: 111.112%;
            }

            ${InfoWrapper} {
                height: 56px;
                padding: 8px 8px;
            }

            ${PlayIcon} {
                transition: opacity 300ms ${animationType} 200ms;
                opacity: 1;
                pointer-events: all;
            }

            ${MetaData} {
                transition: opacity 300ms ${animationType} 200ms;
                opacity: 1;
            }

            ${ImageWrapper} {
                border: 2px solid ${props => props.theme.brandedConfig.white_color};
            }
        }
    }

    ${props => {
        return props.defaultRenderSecondLine
            ? css`
                  margin-bottom: 16px;

                  ${InfoWrapper} {
                      height: 56px;
                      padding: 8px 8px;
                  }

                  ${MetaData} {
                      transition: opacity 300ms ${animationType} 200ms;
                      opacity: 1;
                  }
              `
            : css``;
    }};
`;
