import React, { FC, ReactNode, useCallback, useState } from 'react';
import SVGInline from 'react-svg-inline';
import { createPortal } from 'react-dom';
import { format } from 'date-fns';
import {
    BackgroundImage,
    Channel,
    ChannelLogo,
    ChannelLogoFallback,
    ColorOverlay,
    Details,
    DialogWrapper,
    IconsAndMarkers,
    ModalCloseButton,
    ModalNavigation,
    OptionsContent,
    PlayOptionWrapper,
    RightSideIcons,
    StyledButton,
    Time,
    Title,
} from './PlayOptionScreen.css';
import icons from '../../style';
import translate from '../../utils/fnTranslate';
import { Marker } from '../../types/Asset';
import { withNoScroll } from '../../hooks/withNoScroll/withNoScroll';
import { ContentMarker, StyledSVG } from '../../style/styled-components/reusable.css';
import { Picture } from '../Picture/Picture';
import fallbackImage from '../../assets/images/hero_fallback.png';
import { PictureWithFallback } from '../Picture/PictureWithFallback';
import { usePlayOptions } from '../../providers/usePlayOptions/PlayOptionsContext';
import { PlayOption, PlayOptionsData } from '../../types/CommonTypes';
import { formatDate, isPast } from '../../utils/fnDate';
import { TIME_FORMAT_HH_MM } from '../../utils/fnParser';
import { MILLISECONDS } from '../../utils/TimeUnit';
import { getCatchupPlayerURL, getLivePlayerURL } from '../../utils/fnUrl';
import { useConfig } from '../../providers/useConfig/ConfigContext';
import { useBroadcastTimeCluster } from '../../hooks/useBroadcastTimeCluster/useBroadcastTimeCluster';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
import { getUniquePlayOptions } from '../../utils/fnData';
import { useAuth } from '../../providers/useAuth/AuthContext';
import { getBroadcastCardContentMarkerValue } from '../../utils/fnContentMarker';
import { useSubscription, useSubscriptionChangedObserver } from '../../providers/useSubscription/SubscriptionContext';

const PlayOptionItem: FC<{ asset: PlayOption; index: number; onSubscribe: (packageId: string) => void }> = ({
    asset,
    index,
    onSubscribe,
}) => {
    const { channel, startTime, endTime, id, bookmark, isLive } = asset;
    const { config } = useConfig();
    const { bookmark_threshold } = config?.app_config?.player_settings ?? {};
    const timeCluster = useBroadcastTimeCluster(asset);
    const { initSubscribeFlow } = useSubscription();

    const getContentMarker = (): ReactNode => {
        const contentMarker: Marker = getBroadcastCardContentMarkerValue(isLive, timeCluster);
        return contentMarker && <ContentMarker marker={contentMarker}>{contentMarker.value}</ContentMarker>;
    };

    const getProgress = (): number => {
        const duration = MILLISECONDS.toSeconds(endTime - startTime);
        return (bookmark * 100) / duration;
    };

    const hasProgress = (): boolean => {
        const progress = getProgress();
        return progress > 0 && progress < bookmark_threshold;
    };

    const getButtonText = () => {
        if (!channel.subscription) {
            return 'ORDER_BUTTON';
        }

        if (timeCluster === 'LIVE') {
            if (hasProgress()) {
                if (bookmark > MILLISECONDS.toSeconds(Date.now() - startTime)) {
                    return 'SCREEN_DETAIL_PLAY_BUTTON';
                }
                return 'SCREEN_DETAIL_RESUME_BUTTON';
            }
            return 'SCREEN_DETAIL_PLAY_BUTTON';
        }

        if (timeCluster === 'PAST') {
            if (hasProgress()) {
                return 'SCREEN_DETAIL_RESUME_BUTTON';
            }

            return 'SCREEN_DETAIL_PLAY_BUTTON';
        }

        return 'SCREEN_DETAIL_PLAY_BUTTON';
    };

    const getLinkToWatch = () => {
        return timeCluster === 'LIVE' ? getLivePlayerURL(channel.id) : getCatchupPlayerURL(id);
    };

    return (
        <PlayOptionWrapper>
            <Channel>
                <ChannelLogo>
                    <Picture src={channel.logo} hPosition={'center'} vPosition={'center'} fit={'scale-down'}>
                        {channel.name && <ChannelLogoFallback>{channel.name}</ChannelLogoFallback>}
                    </Picture>
                </ChannelLogo>
            </Channel>
            <Details>
                <IconsAndMarkers>
                    {getContentMarker()}
                    {isPast(MILLISECONDS.toSeconds(endTime)) && <StyledSVG key={'catchup'} svg={icons.catchUpIcon} />}
                </IconsAndMarkers>
                <Time>{`${formatDate(new Date(startTime))} ${format(startTime, TIME_FORMAT_HH_MM)} - ${format(
                    endTime,
                    TIME_FORMAT_HH_MM
                )}`}</Time>
                <RightSideIcons>{!channel.subscription && <StyledSVG svg={icons.lockIcon} />}</RightSideIcons>
            </Details>

            <StyledButton
                label={translate(getButtonText())}
                link={channel.subscription ? getLinkToWatch() : undefined}
                onClick={
                    !channel.subscription
                        ? () => {
                              initSubscribeFlow(undefined, {
                                  type: 'Channel',
                                  code: channel.productCode,
                              });
                              onSubscribe(channel.productCode);
                          }
                        : undefined
                }
                isPrimary={index === 0}
            />
        </PlayOptionWrapper>
    );
};

const OptionSelector: FC<{ asset: PlayOptionsData; refEvent: string; onClose: () => void; load: (refEvent) => void }> = ({
    asset,
    refEvent,
    onClose,
    load,
}) => {
    const { getReplayWindowTimeFrame } = useAuth();
    const filteredPlayOptions = getUniquePlayOptions(asset, getReplayWindowTimeFrame(), refEvent, true);
    const [backgroundImageError, setBackgroundImageError] = useState(false);

    const [packageToSubscribe, setPackageToSubscribe] = useState<string>();

    const onSubscriptionChange = useCallback(() => {
        load(refEvent);
    }, [refEvent, load]);

    useSubscriptionChangedObserver([packageToSubscribe], onSubscriptionChange, [onSubscriptionChange, packageToSubscribe]);

    return createPortal(
        <DialogWrapper>
            <BackgroundImage>
                <PictureWithFallback
                    src={asset?.backdropImage}
                    alwaysLoad={true}
                    vPosition={'top'}
                    hPosition={'center'}
                    fit={'cover'}
                    fallbackImage={fallbackImage}
                    imageError={() => setBackgroundImageError(true)}
                />
            </BackgroundImage>
            {!backgroundImageError && <ColorOverlay />}
            <ModalNavigation>
                <ModalCloseButton onClick={onClose}>
                    <SVGInline svg={icons.closeIcon} />
                </ModalCloseButton>
            </ModalNavigation>
            <OptionsContent>
                <Title>{translate('SCREEN_PLAY_OPTIONS_HEADER')}</Title>
                {filteredPlayOptions?.map((optionItem, index) => (
                    <PlayOptionItem
                        key={`option-${index}`}
                        asset={optionItem}
                        index={index}
                        onSubscribe={packageId => {
                            setPackageToSubscribe(packageId);
                        }}
                    />
                ))}
            </OptionsContent>
        </DialogWrapper>,
        document.getElementById('modal-root')
    );
};

const OptionScreen = withNoScroll(OptionSelector);

export const PlayOptionSelector: FC = () => {
    const { closeSelector, playOptionsData: asset, referenceEventId, fetching, load } = usePlayOptions();

    if (fetching && !asset) {
        return <LoadingIndicator overlay={true} />;
    }

    if (!asset) {
        return null;
    }

    return <OptionScreen onClose={closeSelector} asset={asset} refEvent={referenceEventId()} load={load} />;
};
