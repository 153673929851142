import SVGInline from 'react-svg-inline';
import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { Breakpoints, MQ, touchDeviceLandscape } from '../../../style/styled-components/cssMediaQueries';
import { fontBold, maxZIndex } from '../../../style/styled-components/cssGlobalVariables';

export const ErrorPage = styled.div`
    min-height: 43vh;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props => props.theme.brandedConfig.black_color};
    z-index: ${maxZIndex * 2};
    overflow: auto;
`;

export const ErrorContainer = styled.div`
    pointer-events: all;
    margin-top: 0;
    text-align: center;
    width: 100%;
    padding: 0 16px;

    ${MQ(Breakpoints.xs)} {
        width: 480px;
        margin: 0 auto;
        padding: 0;
    }
`;

export const ErrorIcon = styled.div`
    height: auto;
    margin: 0 auto;

    ${touchDeviceLandscape} {
        width: 237px;
    }

    ${MQ(Breakpoints.s)} {
        width: 100%;
    }
`;

export const ErrorIconSVG = styled(SVGInline)`
    svg {
        width: 100%;
    }
`;

export const TitleContainer = styled.div`
    margin-top: 50px;

    ${MQ(Breakpoints.xxs)} {
        margin-top: 30px;

        ${touchDeviceLandscape} {
            margin-top: 10px;
        }
    }

    ${MQ(Breakpoints.xs)} {
        margin-top: 10px;
    }
`;

export const ErrorTitle = styled.p`
    font-size: 24px;
    margin: 0;
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-family: ${fontBold};

    ${MQ(Breakpoints.xxs)} {
        font-size: 26px;
    }

    ${MQ(Breakpoints.s)} {
        font-size: 28px;
    }
`;

export const ErrorMessage = styled.p`
    margin: 20px auto;
    font-size: 15px;
    line-height: 24px;
    color: ${props => props.theme.brandedConfig.high_contrast};

    ${MQ(Breakpoints.xxs)} {
        font-size: 16px;
        margin: 30px auto;

        ${touchDeviceLandscape} {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    ${MQ(Breakpoints.xs)} {
        ${touchDeviceLandscape} {
            margin: 30px auto;
        }
    }

    ${MQ(Breakpoints.s)} {
        font-size: 17px;
    }
`;

export const ErrorButtonContainer = styled.div`
    width: 100%;
    display: inline-block;
`;

export const ErrorButton = styled.div`
    display: inline-block;
    width: 100%;
    margin: 0;
`;
