import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { getLocalStorage, setLocalStorage } from '../../utils/fnStorage';
import { useConfig } from '../useConfig/ConfigContext';
import { useWrapLocation } from '../../hooks/useWrapRouter/useWrapLocation';
import { detailRouteMatch } from '../../hooks/useDetailLocationParams';
import { StaticPageRoutes } from '../../types/RouteTypes';
import { useResizeWatcher } from '../../hooks/useResizeWatcher/useResizeWatcher';
import { useNavigation } from '../../hooks/useNavigation';

const visitedLocations = [];
const DEBUG_HISTORY = false;

type InAppHistory = {
    key: string;
    path: string;
    isDetailPage: boolean;
};

const useAppService = () => {
    const appLanguageStorage = getLocalStorage('appLanguage');

    const [appLanguage, setAppLanguage] = useState<string>(appLanguageStorage || 'et');
    const [pageTitle, setPageTitle] = useState<string>(null);
    const [isCollectionPage, setIsCollectionPage] = useState<boolean>(false);
    const [appHistory, setAppHistory] = useState<InAppHistory[]>([]);
    const [noInternet, setNoInternet] = useState<boolean>(false);
    const [networkError, setNetworkError] = useState<number>(null);

    const popDetailHistory = useRef(false);

    const { isDesktop, isMobile, isTablet, resolution } = useResizeWatcher();

    const changeApplicationLanguage = (toSetLanguage: string) => {
        setAppLanguage(toSetLanguage);
    };

    const popDetailHistoryHandler = () => {
        const historyLength = appHistory.length;

        if (historyLength) {
            let depth = 0;
            for (let i = historyLength - 1; i > 0; i -= 1) {
                if (appHistory[i].isDetailPage) {
                    depth += 1;
                } else {
                    break;
                }
            }

            if (depth > 0) {
                setAppHistory([...appHistory].slice(0, historyLength - depth));
            }
        }
    };

    /**
     * This is a HACK. It's necessary because navigation back from more detail Pages with the X so like history.go(-X)
     * trigger only once the in app history manager so can keep leaks in the memory. This function set's a flag so when the in-app history will change
     * this additionally pops the invalid items from the in app history.
     *
     */
    const popDetailLocations = () => {
        popDetailHistory.current = true;
    };

    const setGlobalNoInternet = () => {
        setNoInternet(true);
    };

    const setGlobalNetworkError = () => {
        setNetworkError(networkError);
    };

    useEffect(() => {
        setLocalStorage('appLanguage', appLanguage);
    }, [appLanguage]);

    useEffect(() => {
        if (popDetailHistory.current) {
            popDetailHistory.current = false;
            popDetailHistoryHandler();
        }
    }, [appHistory]);

    return {
        pageTitle,
        setPageTitle,
        appLanguage,

        isCollectionPage,
        setIsCollectionPage,
        setApplicationLanguage: changeApplicationLanguage,

        appHistory,
        setAppHistory,
        historyLength: appHistory.length,
        popDetailLocations,

        noInternet,
        setNoInternet,
        globalNoInternet: setGlobalNoInternet,

        networkError,
        setNetworkError,
        setGlobalNetworkError,

        isMobile,
        isTablet,
        isDesktop,
        resolution,
    };
};

export const AppContext = createContext<ReturnType<typeof useAppService>>(null);

export const AppProvider = ({ children }) => {
    const appData = useAppService();

    return <AppContext.Provider value={appData}>{children}</AppContext.Provider>;
};

export const useApp = () => useContext(AppContext);

export const usePageUtils = () => {
    const { config } = useConfig();
    const { isCollectionPage } = useApp();
    const { location } = useNavigation();

    const pageConfig = config?.getPageConfigByLocation({
        route: {
            path: location.pathname,
            isExact: true,
            url: '',
            params: {},
        },
        params: {},
    });

    const isSettings = [StaticPageRoutes.SETTINGS, StaticPageRoutes.ABOUT].find(route => location.pathname.indexOf(route) !== -1) != null;

    const isEpg = location.pathname.indexOf('live-tv') !== -1;

    const pageBelongsToMainSections = !!(
        config?.belongsToMainSection(pageConfig) ||
        (pageConfig?.parent && config?.belongsToMainSection(pageConfig?.parent))
    );

    const isSearch = location.pathname === StaticPageRoutes.SEARCH;

    return {
        pageConfig,
        isSettings,
        pageBelongsToMainSections,
        isCollectionPage,
        isSearch,
        isEpg,
    };
};

export const useAppHistory = () => {
    const history = useHistory();
    const location = useWrapLocation();
    const { appHistory, setAppHistory, historyLength } = useApp();

    const path = location.pathname;
    const { key } = location;
    const isDetailPage = detailRouteMatch(path) != null;

    const handlePush = () => {
        if (!visitedLocations.includes(key)) {
            visitedLocations.push(key);
        }

        setAppHistory([
            ...appHistory,
            {
                key,
                path,
                isDetailPage,
            },
        ]);

        if (DEBUG_HISTORY) {
            console.log('pushing...');
        }
    };

    const handlePop = () => {
        if (!historyLength) {
            // initial load
            handlePush();
        } else if (visitedLocations.includes(key) && appHistory.find(item => item.key === key) == null) {
            handlePush();
        } else {
            setAppHistory([...appHistory].slice(0, historyLength - 1));

            if (DEBUG_HISTORY) {
                console.log('popping...');
            }
        }
    };

    const handleReplace = () => {
        setAppHistory([...appHistory.slice(0, historyLength - 1), { key, path, isDetailPage }]);
        if (DEBUG_HISTORY) {
            console.log('replacing...');
        }
    };

    useEffect(() => {
        if (DEBUG_HISTORY) {
            console.log(appHistory);
        }
    }, [appHistory]);

    useEffect(() => {
        switch (history.action) {
            case 'PUSH': {
                handlePush();
                break;
            }
            case 'POP': {
                handlePop();
                break;
            }
            case 'REPLACE': {
                handleReplace();
                break;
            }
            default: {
                console.log(`not supported action ${history.action}`);
            }
        }
    }, [location.key]);
};
