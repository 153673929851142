import get from 'lodash/get';
import { duration, ISO_8601 } from 'moment';
import {
    ContentProduct,
    EntitlementState,
    Offer,
    ProductType,
    PurchasedProduct,
    PurchasedProductType,
    VODAssetEntitlementState,
} from '../types/Entitlement';
import translate from './fnTranslate';
import ApplicationConfig from '../providers/useConfig/ApplicationConfig';
import { FeatureControl } from '../types/Config';
import { getResolution } from './fnData';

const VOD_SUBSCRIPTION_PACKAGE = 'SUBSCRIPTION_PACKAGE_ID';

const TAG_TRANSLATION_MAP = {
    TV_PACKAGE: 'TV_PACKAGE',
    TV_CHANNEL_BUNDLE: 'TV_CHANNEL_BUNDLE',
    TV_ADDITIONS: 'TV_ADDITIONS',
    TV_CHANNEL: 'TV_CHANNEL',
};

const getProductFeatureControlMap = () => {
    return {
        [ApplicationConfig.app_config.product_settings.npvr_prefix]: 'pvr',
    };
};

export const getEntitledReplayProduct = (products: PurchasedProduct[]): PurchasedProduct => {
    if (!products || !products.length) return null;
    const timeMachineProduct = products.find(
        product => product.type === PurchasedProductType.TIME_MACHINE || product.type === PurchasedProductType.TIME_MACHINE_PLUS
    );

    if (!timeMachineProduct || timeMachineProduct.state === EntitlementState.NOT_ENTITLED) return null;

    return timeMachineProduct;
};

export const isSubscribedToProduct = (products: PurchasedProduct[], condition: string): boolean => {
    let productType = condition;

    // should find the value in the config
    if (condition.startsWith('%') && condition.endsWith('%')) {
        productType = get(ApplicationConfig, condition.substring(1, condition.length - 1));
    }

    const feature = getProductFeatureControlMap()?.[productType];
    if (
        feature &&
        ApplicationConfig.isFeatureControlValue(feature) &&
        !ApplicationConfig.isFeatureActive(feature as keyof FeatureControl)
    ) {
        return false;
    }

    return (
        products?.find(
            product => (product.type === productType || product.type.startsWith(productType)) && product.state === EntitlementState.ENTITLED
        ) != null ?? false
    );
};

export const isChannelSubscribed = (channel: any) => {
    return (
        channel?.Products?.Product?.find(
            product => product.Type === PurchasedProductType.SUBSCRIPTION && product.EntitlementState === EntitlementState.ENTITLED
        ) != null
    );
};

const getAllProducts = (title: any, includeEntitled = true) => {
    const products = [];

    if (title?.Contents?.Content) {
        title.Contents.Content.forEach(content => {
            if (includeEntitled || content?.EntitlementState === EntitlementState.NOT_ENTITLED) {
                if (content?.Products?.Product) {
                    content.Products.Product.forEach(product => {
                        // @ts-ignore
                        const isBuyOffer = duration(product?.RentalPeriod, ISO_8601).asMilliseconds() < 0;

                        products.push({
                            ...product,
                            ...{
                                resolution: getResolution(content?.Resolution, content?.IsHD),
                                audios: content?.OriginalLanguages?.OriginalLanguage ?? [],
                                subtitles: content?.CaptionLanguages?.CaptionLanguage ?? [],
                                audioMixType: content?.AudioMixType?.type,
                                isBuyOffer,
                            },
                        });
                    });
                }
            }
        });
    }

    return products;
};

const getProduct = (title: any, type: ProductType[], includeEntitled = true) => {
    return getAllProducts(title, includeEntitled).filter(product => type.includes(product.Type));
};

const getProductCount = (title: any) => {
    return getAllProducts(title).length;
};

export const getSubscriptionProductCode = (product: any) => {
    const vodKind = (product?.Aliases?.Alias ?? [])?.find(alias => alias.type === VOD_SUBSCRIPTION_PACKAGE)?.Value;
    return vodKind || product?.Name;
};

export const getNPVRProduct = (content: any) =>
    (content?.Products?.Product ?? []).find(product =>
        product?.Name?.startsWith(ApplicationConfig.app_config.product_settings.npvr_prefix)
    );
export const isNPVRSubscribedProduct = product => product?.EntitlementState === EntitlementState.ENTITLED;

export const getPrice = (
    title: any,
    productTypes: ProductType[] = [ProductType.FREE, ProductType.TRANSACTION, ProductType.SUBSCRIPTION]
) => {
    const price = getProduct(title, productTypes).sort((a, b) => parseFloat(a?.ListPrice ?? '-1') - parseFloat(b?.ListPrice ?? '-1'))?.[0]
        ?.ListPrice;

    if (price) {
        return parseFloat(price);
    }

    return null;
};

export const getOffers = (title: any, buyOffersOnly: boolean = null): Offer[] => {
    const products = getProduct(title, [ProductType.TRANSACTION], false);
    const offers: Offer[] = [];

    products.forEach(product => {
        if (product?.OfferPrice?.Offer) {
            product.OfferPrice.Offer.forEach(beOffer => {
                // @ts-ignore
                const isBuyOffer = duration(product?.RentalPeriod, ISO_8601).asMilliseconds() < 0;

                if (
                    (isBuyOffer && buyOffersOnly === true && ApplicationConfig.isFeatureActive('buy_tvod')) ||
                    (!isBuyOffer && buyOffersOnly === false) ||
                    buyOffersOnly == null
                ) {
                    offers.push({
                        productId: product?.id,
                        productName: product?.Name,
                        currency: product?.Currency,
                        listPrice: parseFloat(product?.ListPrice ?? '-1'),
                        description: product?.ShortSynopsis,
                        rentalPeriodInMinutes: product?.RentalPeriodInMinutes,
                        offerId: beOffer?.id,
                        offerValue: parseFloat(beOffer?.Value ?? '-1'),
                        rentalPeriod: beOffer?.rentalPeriod,
                        resolution: product?.resolution,
                        audios: product.audios,
                        subtitles: product.subtitles,
                        audioMixType: product.audioMixType,
                        availabilityEnd: product?.AvailabilityEnd ? Date.parse(product?.AvailabilityEnd) : -1,
                        productCode: null,
                        isBuyOffer,
                    });
                }
            });
        }
    });

    return offers;
};

export const getSubscribeOffer = (title: any): ContentProduct => {
    const products = getProduct(title, [ProductType.SUBSCRIPTION], false);

    if (!products.length) {
        return null;
    }

    const product = products[0];

    return {
        productId: product?.id,
        productName: product?.Name,
        currency: product?.Currency,
        listPrice: parseFloat(product?.ListPrice ?? '-1'),
        audios: product?.audios,
        subtitles: product?.subtitles,
        audioMixType: product?.audioMixType,
        availabilityEnd: product?.AvailabilityEnd ? Date.parse(product?.AvailabilityEnd) : -1,
        productCode: getSubscriptionProductCode(product),
        isBuyOffer: false,
    };
};

export const getTVSubscribeOffer = (channel: any): ContentProduct => {
    const product = channel?.Products?.Product?.[0];

    if (!product) {
        return null;
    }

    return {
        productId: product?.id,
        productName: product?.Name,
        currency: product?.Currency,
        listPrice: parseFloat(product?.ListPrice ?? '-1'),
        productCode: getSubscriptionProductCode(product),
        isBuyOffer: false,
    };
};

export const isVODEntitled = (title: any) => {
    return title?.Contents?.Content?.find(content => content.EntitlementState === EntitlementState.ENTITLED) != null;
};

export const getVODEntitlementEnd = (title: any) => {
    if (!isVODEntitled(title)) {
        return null;
    }
    return Date.parse(title?.Contents?.Content?.[0]?.EntitlementEnd);
};

export const getVodAssetEntitlementState = (title: any): VODAssetEntitlementState => {
    const hasFreeTag = getProduct(title, [ProductType.FREE]).length;

    if (isVODEntitled(title) || hasFreeTag) {
        return VODAssetEntitlementState.ENTITLED; // -> Watch button
    }

    const productCount = getProductCount(title);
    const subscriptionOption = getProduct(title, [ProductType.SUBSCRIPTION]);

    // has only one product and it's subscription -> SVOD
    if (productCount === 1 && subscriptionOption.length) {
        return VODAssetEntitlementState.SHOULD_SUBSCRIBE;
    }

    // TVOD OR EST (BUY)

    const rentingOffers = getOffers(title, false);
    const buyOffers = getOffers(title, true);

    if (productCount > 0 && buyOffers.length) {
        return VODAssetEntitlementState.SHOULD_BUY;
    }

    if (productCount > 0 && rentingOffers.length) {
        return VODAssetEntitlementState.SHOULD_RENT;
    }

    // SVOD
    if (subscriptionOption.length) {
        return VODAssetEntitlementState.SHOULD_SUBSCRIBE;
    }

    return null;
};

export const getOfferTypeByTag = (tag: string) => translate(TAG_TRANSLATION_MAP[tag] ?? TAG_TRANSLATION_MAP.TV_PACKAGE);
