import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { EpgProgram } from '../../types/EpgTypes';
import { alignDate } from '../../utils/fnEpg';
import Api from '../../api/Api';
import { parseChannels, parseEpgPrograms } from '../../utils/fnParser';
import translate from '../../utils/fnTranslate';
import { useChannels } from '../useChannels/useChannels';
import { useConfig } from '../useConfig/ConfigContext';
import { SECONDS } from '../../utils/TimeUnit';
import { useGlobalNetworkError, useGlobalNoInternet } from '../../hooks/withNetworkCheck/withNetworkCheck';
import { useApp } from '../useApp/AppContext';
import { healthCheck } from '../../hooks/useDataFetcher/useDataFetcher';
import { PlayerProps } from '../../types/Player';
import { SelectorOption } from '../../components/Epg/EpgFilter/EpgFilter';
import { ChannelFilterOptionsValue } from '../../components/Epg/EpgComponent/EpgComponent';
import { Channel } from '../../types/Asset';

const useEpgStoreService = () => {
    const [epgData, setEpgData] = useState({
        channels: [],
        programs: [],
        search: null,
    });

    const { channels, fetchChannels, loading } = useChannels();
    const [allChannels, setAllChannels] = useState(channels);
    const { config } = useConfig();
    const { onNoInternet } = useGlobalNoInternet();
    const { onNetworkError } = useGlobalNetworkError();

    const canceller = useRef(null);
    const [selectedProgram, setSelectedProgram] = useState(null);

    const { setNetworkError, setNoInternet } = useApp();
    const updateTimeOutRef = useRef(null);
    const backEndErrorUpdateInterval = 5000;

    const [lineupFilter, setLineupFilter] = useState<SelectorOption>(null);
    const [searchQuery, setSearchQuery] = useState<string>(null);
    const [filteredChannels, setFilteredChannels] = useState<Channel[]>(channels);

    const [playerProps, setPlayerProps] = useState<PlayerProps>(null);

    const createTvBreak = (start: number, end: number): any => {
        return {
            id: null,
            isBreak: true,
            title: translate('PLAYER_NO_INFO'),
            startTime: start,
            endTime: end,
        };
    };

    const fillTvBreaks = (data, dayStart, dayEnd): EpgProgram[] => {
        let finalData = [];

        // sort programs by start time
        data = data.sort((programA, programB) => programA.startTime - programB.startTime);

        // if there are no programs, create dummy item for the entire day
        if (!data || !data.length) {
            finalData.push(createTvBreak(dayStart, dayEnd));
        } else {
            finalData = [
                ...data.filter(programItem => {
                    return programItem.startTime < dayEnd;
                }),
            ];

            for (let i = 0; i < data.length; i += 1) {
                const program = data[i];
                const previousProgram = data[i - 1];

                // if the start time of the next program is before the end time of previous one
                if (previousProgram && program.startTime < previousProgram.endTime) {
                    program.startTime += previousProgram.endTime - program.startTime;
                }

                // if the gap is more then 30 seconds
                if (previousProgram && program.startTime - previousProgram.endTime > 30) {
                    finalData.splice(i + 1, 0, createTvBreak(previousProgram.endTime, program.startTime));
                }

                if (i === 0) {
                    if (program.startTime > dayStart) {
                        finalData.splice(0, 0, createTvBreak(dayStart, program.startTime));
                    }
                }
            }

            // handle last item
            const lastProgram = finalData[finalData.length - 1];
            if (lastProgram.endTime > dayEnd) {
                lastProgram.endTime = dayEnd;
            } else {
                finalData.push(createTvBreak(lastProgram.endTime, dayEnd - 10));
            }

            // handle first item
            if (finalData[0].startTime < dayStart) {
                finalData[0].startTime = dayStart;
            }
        }

        return finalData.sort((programA, programB) => programA.startTime - programB.startTime);
    };

    const isOffline = () => {
        return !window.navigator.onLine;
    };

    const downloadProgramsByChannels = async (channelIds: string[], dayStart: number, dayEnd: number) => {
        // clear pending requests
        if (canceller.current) {
            canceller.current();
        }

        const broadcastsResponse = await Api.fetchBroadcasts(
            channelIds,
            dayStart,
            dayEnd,
            new axios.CancelToken(executor => {
                canceller.current = executor;
            })
        );

        if (broadcastsResponse.error && isOffline()) {
            updateTimeOutRef.current = setInterval(() => {
                if (!isOffline()) {
                    console.log('clearInterval offline');
                    clearInterval(updateTimeOutRef.current);
                    setNoInternet(false);
                }
            }, backEndErrorUpdateInterval);
            onNoInternet();
        } else if (
            !!broadcastsResponse?.error ||
            broadcastsResponse?.status?.toString().startsWith('4') ||
            broadcastsResponse?.status?.toString().startsWith('5')
        ) {
            if (await healthCheck()) {
                console.log('healthCheck');
                setNetworkError(null);
                setNoInternet(false);
            } else {
                updateTimeOutRef.current = setInterval(async () => {
                    if (await healthCheck()) {
                        clearInterval(updateTimeOutRef.current);
                        setNetworkError(null);
                    }
                }, backEndErrorUpdateInterval);
                onNetworkError(broadcastsResponse.status || -1);
            }
        } else {
            const requestResponse = broadcastsResponse.response;

            const epgDataHelper = { ...epgData };

            if (requestResponse?.Channels?.Channel) {
                requestResponse.Channels.Channel.forEach(channelItem => {
                    if (!epgDataHelper.programs[channelItem?.id]) {
                        epgDataHelper.programs[channelItem?.id] = {};
                    }

                    if (!epgDataHelper.programs[channelItem?.id][dayStart]) {
                        epgDataHelper.programs[channelItem?.id][dayStart] = [];
                    }

                    const parsedByChannel = fillTvBreaks(parseEpgPrograms(channelItem), dayStart, dayEnd);

                    epgDataHelper.programs[channelItem?.id][dayStart] = [
                        ...epgDataHelper.programs[channelItem?.id][dayStart],
                        ...parsedByChannel,
                    ];
                });
            }

            setEpgData({
                ...epgData,
                ...epgDataHelper,
            });
        }

        return null;
    };

    const getProgramsByChannels = async (channelIds: string[], day: number) => {
        let dayStart;

        // calculate day start
        dayStart = new Date(SECONDS.toMillis(day));
        dayStart = alignDate(dayStart, config.app_config.epg_settings.day_start_hour);

        // create day key
        const dayKey = dayStart.getTime();

        // calculate day end
        const dayEnd = new Date(dayStart);
        dayEnd.setDate(dayEnd.getDate() + 1);

        const notCachedChannels = channelIds.filter(
            channel => epgData.programs[channel] == null || epgData.programs[channel][dayKey] == null
        );

        // each channel from the set is cached
        if (!notCachedChannels.length) {
            return;
        }

        await downloadProgramsByChannels(notCachedChannels, dayStart.getTime(), dayEnd.getTime());
    };

    const search = (query: string) => {
        setSearchQuery(query);

        if (query != null && ((query.trim() === '' && epgData.search === '') || (query.trim() === '' && epgData.search !== ''))) {
            return setEpgData({
                ...epgData,
                ...{ search: null },
            });
        }
        setEpgData({
            ...epgData,
            ...{ search: query },
        });

        return null;
    };

    const filterChannel = (selection: SelectorOption) => {
        setLineupFilter(selection);

        setEpgData({
            ...epgData,
        });
    };

    const loadChannels = (force: boolean = false) => {
        if (!channels || !channels.length) {
            fetchChannels(force);
        }
    };

    const purgeCache = () => {
        setEpgData({
            programs: [],
            search: null,
            channels: null,
        });

        setSearchQuery(null);
    };

    useEffect(() => {
        if (!allChannels) {
            setAllChannels(channels);
        }
    }, [channels]);

    useEffect(() => {
        if (channels?.length === 0) {
            setFilteredChannels(allChannels);
        }

        if (!searchQuery || searchQuery === '') {
            if (!lineupFilter || lineupFilter.value === ChannelFilterOptionsValue.ALL) {
                setFilteredChannels(allChannels);
            } else {
                setFilteredChannels(
                    allChannels.filter(channel => {
                        return channel[lineupFilter.value];
                    })
                );
            }
        } else if (searchQuery && searchQuery !== '') {
            const filtered = allChannels.filter(channel => {
                return channel.name.toLowerCase().includes(searchQuery.toLowerCase());
            });

            setFilteredChannels(filtered);
        }
    }, [lineupFilter, searchQuery, allChannels, channels]);

    return {
        channels: parseChannels(filteredChannels),
        channelsLoading: loading,
        programs: epgData.programs,
        searched: searchQuery,
        selectedProgram,
        loadChannels,
        getProgramsByChannels,
        search,
        purgeCache,
        setSelectedProgram,
        playerProps,
        setPlayerProps,
        filterChannel,
    };
};

export const EpgDataContext = createContext<ReturnType<typeof useEpgStoreService>>(null);

export const EpgProvider = ({ children }) => {
    const epg = useEpgStoreService();
    return <EpgDataContext.Provider value={epg}>{children}</EpgDataContext.Provider>;
};

export const useEpgStore = () => useContext(EpgDataContext);
