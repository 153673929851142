import { useHistory } from 'react-router';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { OrderSubscription, SubscribeData, SubscribeOption } from '../../types/Entitlement';
import { PinFlowType, usePin } from '../usePin/PinContext';
import { UpsellOptionSelector } from '../../components/UpsellOptionSelector/UpsellOptionSelector';
import { useDataFetcher } from '../../hooks/useDataFetcher/useDataFetcher';
import Api from '../../api/Api';
import { LoadingIndicator } from '../../components/LoadingIndicator/LoadingIndicator';
import translate from '../../utils/fnTranslate';
import { SubscriptionConfirmation } from '../../components/SubscriptionConfirmation/SubscriptionConfirmation';
import { getOfferTypeByTag } from '../../utils/fnEntitlement';
import { useGlobalNetworkError, useGlobalNoInternet } from '../../hooks/withNetworkCheck/withNetworkCheck';
import { FetchParamsSubscribe } from '../../types/ApiTypes';
import { usePersonalized } from '../../hooks/usePersonalizedVod/usePersonalized';
import { useAuth } from '../useAuth/AuthContext';
import { useChannels } from '../useChannels/useChannels';
import { useElementInteractionTracking } from '../useTracking/TrackingContext';
import { useSubscriptionSuccessNotification } from '../../components/Notification/Notification';
import { AlertDialog } from '../../components/AlertDialog/AlertDialog';
import { SubscriptionValidator } from './SubscriptionValidator';
import { SubscriptionValidatorInfo } from './SubscriptionPackageValidator';
import Events from '../../utils/Events';
import { useConfig } from '../useConfig/ConfigContext';

enum FlowStep {
    SELECT_OFFER = 'select_offer',
    CONFIRM_OFFER = 'confirm_offer',
    ENTER_PIN = 'enter_pin',
    ERROR = 'subscribe_error',
}

export type SubscriptionCallback = {
    onSuccess: () => void;
};

export const SUBSCRIPTION_CHANGED_EVENT = 'SUBSCRIPTION_CHANGED';

const useSubscribeService = () => {
    const [subscribeData, setSubscribeData] = useState<SubscribeData>(null);
    const [selectedOffer, setSelectedOffer] = useState<SubscribeOption>(null);
    const [step, setStep] = useState<FlowStep>(null);
    const [playPath, setPlayPath] = useState<string>(null);
    const [subscriptionCallback, setSubscriptionCallback] = useState<SubscriptionCallback>(null);
    const tvPostActionEnabled = useRef(false);
    const events = useRef(new Events());

    const confirmSubscriptionSuccess = useSubscriptionSuccessNotification();
    const { onNoInternet } = useGlobalNoInternet();
    const { onNetworkError } = useGlobalNetworkError();
    const { updatePersonalizedInfo } = usePersonalized();
    const { loadPurchasedProducts, purchasedProducts } = useAuth();
    const { fetchChannels, channels } = useChannels();

    const { config } = useConfig();

    const onSubscriptionActivated = useCallback(
        (data: SubscriptionValidatorInfo) => {
            if (data.type === 'Channel' || data.packageId === config.app_config.product_settings.product_code_time_machine_plus) {
                loadPurchasedProducts();
                fetchChannels(true);
            } else if (data.personalizedAssetId) {
                updatePersonalizedInfo(data.personalizedAssetId);
            }

            events.current.triggerEvents(SUBSCRIPTION_CHANGED_EVENT, data);
        },
        [config]
    );

    const subscriptionValidator = useRef<SubscriptionValidator>(null);

    const {
        response: subscriptionOffers,
        error: subscriptionOfferError,
        loading: subscriptionOffersLoading,
        reset: resetSubscriptionOffers,
        fetcher: fetchSubscriptionOffers,
    } = useDataFetcher<SubscribeOption[], SubscribeData>(
        props => Api.fetchSubscriptionOffers(props.code, props.type),
        onNoInternet,
        onNetworkError
    );

    const { response: orderResponse, error: orderError, loading: isOrdering, reset: resetOrder, fetcher: orderProduct } = useDataFetcher<
        OrderSubscription,
        FetchParamsSubscribe
    >(props => Api.orderProduct(props.productId), onNoInternet, onNetworkError);

    const { openPinDialog, closePinDialog } = usePin();
    const history = useHistory();

    const initSubscribeFlow = (playerUrl: string, toSubscribe: SubscribeData, callback?: SubscriptionCallback) => {
        setSubscribeData(toSubscribe);
        setPlayPath(playerUrl);
        setSubscriptionCallback(callback);
        setStep(FlowStep.SELECT_OFFER);
    };

    const goToSubscriptionConfirmation = () => {
        setStep(FlowStep.CONFIRM_OFFER);
    };

    const goToEnterPin = () => {
        setStep(FlowStep.ENTER_PIN);
    };

    const exitFlow = () => {
        setSubscribeData(null);
        setSelectedOffer(null);
        setStep(null);
        setPlayPath(null);
        setSubscriptionCallback(null);
        resetSubscriptionOffers();
        resetOrder();
        closePinDialog(false);
        tvPostActionEnabled.current = false;
    };

    const subscribe = (externalOffer?: SubscribeOption) => {
        // external offer means the subscribe got initialised from the purchase context for a VOD/SVOD compatible asset
        if (externalOffer) {
            subscriptionValidator.current.addValidator({
                type: 'Product',
                code: externalOffer?.packageId,
                assetId: externalOffer?.assetId,
            });
        }

        orderProduct({
            productId: (externalOffer ?? selectedOffer).productId,
        });
    };

    const onPinValidated = () => {
        subscribe();
    };

    const onPinDialogClosed = () => {
        exitFlow();
    };

    const onSubscribeSuccessful = () => {
        if (subscriptionCallback) {
            subscriptionCallback.onSuccess();
        }

        confirmSubscriptionSuccess(() => {
            // the subscription data is null if the subscribe request was initialised from the purchase context
            if (subscribeData) {
                subscriptionValidator.current.addValidator(subscribeData);
            }
            exitFlow();
        });
    };

    const addSubscriptionChangedListener = (callback: (data: SubscriptionValidatorInfo) => void) => {
        events.current.addEventListener(SUBSCRIPTION_CHANGED_EVENT, callback);
    };

    const removeSubscriptionChangedListener = (callback: (data: SubscriptionValidatorInfo) => void) => {
        events.current.removeEventListener(SUBSCRIPTION_CHANGED_EVENT, callback);
    };

    useEffect(() => {
        if (step && step === FlowStep.SELECT_OFFER) {
            setSelectedOffer(null);
        }

        if (step && (step === FlowStep.SELECT_OFFER || step === FlowStep.CONFIRM_OFFER)) {
            closePinDialog(false);
        }

        if (step && step === FlowStep.ENTER_PIN) {
            openPinDialog(PinFlowType.CHECK, {
                onPinValidated,
                onDialogClosed: onPinDialogClosed,
            });
        }
    }, [step]);

    useEffect(() => {
        if (subscribeData) {
            fetchSubscriptionOffers(subscribeData);
        }
    }, [subscribeData]);

    useEffect(() => {
        if (subscriptionOffers && step) {
            if (subscriptionOffers.length === 1) {
                setSelectedOffer(subscriptionOffers[0]);
            }
        }
    }, [subscriptionOffers]);

    useEffect(() => {
        if (selectedOffer) {
            goToSubscriptionConfirmation();
        }
    }, [selectedOffer]);

    useEffect(() => {
        // execute only if the subscription context initialized the flow
        if (orderResponse && step) {
            onSubscribeSuccessful();
            resetOrder();
        }
    }, [orderResponse]);

    useEffect(() => {
        if (step && orderError) {
            setStep(FlowStep.ERROR);
        }
    }, [orderError]);

    useEffect(() => {
        if (purchasedProducts && channels && step && tvPostActionEnabled.current) {
            if (playPath) {
                history.push(playPath);
            }

            exitFlow();
        }

        if (step === FlowStep.ERROR) {
            closePinDialog(false);
        }
    }, [purchasedProducts, channels, step]);

    useEffect(() => {
        if (config) {
            subscriptionValidator.current = new SubscriptionValidator(onSubscriptionActivated);
        }
    }, [config]);

    return {
        pendingFlow: subscribeData != null,
        step,
        subscribeData,
        initSubscribeFlow,
        cancel: () => {
            exitFlow();
        },

        fetchSubscriptionOffers,
        subscriptionOffersLoading,
        subscriptionOffers,
        subscriptionOfferError,
        resetSubscriptionOffers,

        selectOffer: (subscribeOption: SubscribeOption) => {
            setSelectedOffer(subscribeOption);
        },
        selectedOffer,

        subscribe,
        orderResponse,
        isOrdering,
        orderError,
        resetOrder,
        goToEnterPin,

        addSubscriptionChangedListener,
        removeSubscriptionChangedListener,
    };
};

export const SubscriptionContext = createContext<ReturnType<typeof useSubscribeService>>(null);

export const SubscribeProvider = ({ children }) => {
    const service = useSubscribeService();

    return <SubscriptionContext.Provider value={service}>{children}</SubscriptionContext.Provider>;
};

export const useSubscription = () => useContext(SubscriptionContext);

export const useSubscribeFlowScreenProvider = () => {
    const {
        pendingFlow,
        step,
        cancel,
        subscriptionOffersLoading,
        subscriptionOffers,
        subscriptionOfferError,
        selectOffer,
        selectedOffer,
        orderError,
        isOrdering,
        goToEnterPin,
    } = useSubscription();

    const interactionTracking = useElementInteractionTracking();

    const getSelectOfferScreen = () => {
        if (step && !subscriptionOfferError && (subscriptionOffersLoading || !subscriptionOffers || subscriptionOffers?.length === 1)) {
            return <LoadingIndicator overlay={true} />;
        }

        if (!subscriptionOffers.length) {
            return (
                <AlertDialog
                    title={translate('NOTIFICATION_NO_PRODUCTS_TITLE')}
                    bodyText={translate('NOTIFICATION_NO_PRODUCTS_BODY')}
                    buttons={[
                        {
                            text: translate('CLOSE_BUTTON'),
                            onClick: cancel,
                        },
                    ]}
                />
            );
        }

        return (
            <UpsellOptionSelector
                onClose={cancel}
                options={subscriptionOffers.map(offer => {
                    return {
                        id: offer.productId,
                        title: offer.name,
                        price: offer.price,
                        optionTypeLabel: getOfferTypeByTag(offer?.tags?.[0]),
                        type: 'SUBSCRIPTION',
                        image: offer.poster,
                        duration: translate('SUBSCRIPTION_PERIOD_MONTH'),
                        rawData: offer,
                        onClick: () => {
                            interactionTracking({
                                'data-track-id': 'upsell_option_card',
                                'data-page-name': 'UpsellSelect',
                                'data-type': 'subscribe',
                                'data-asset-id': `${offer.productId}`,
                                'data-asset-name': offer.name,
                            });
                            selectOffer(offer);
                        },
                    };
                })}
            />
        );
    };

    const getConfirmOfferScreen = () => {
        return <SubscriptionConfirmation onClose={cancel} onConfirm={goToEnterPin} selectedOffer={selectedOffer} loading={isOrdering} />;
    };

    const getGenericError = () => {
        const genericError = subscriptionOfferError;

        if (genericError) {
            const code = parseInt(genericError?.response?.status, 10);

            return (
                <AlertDialog
                    title={translate('ERR_GENERIC_TITLE')}
                    bodyText={translate('ERR_GENERIC_BODY')}
                    hintText={`${translate('ERR_CODE')} ${code}`}
                    buttons={[
                        {
                            text: translate('CLOSE_BUTTON'),
                            onClick: cancel,
                        },
                    ]}
                />
            );
        }

        return null;
    };

    const getErrorAlert = () => {
        const error = orderError;

        if (error) {
            const code = parseInt(error?.response?.status, 10);

            return (
                <AlertDialog
                    title={translate('ERR_SUBSCRIBE_GENERIC_TITLE')}
                    bodyText={translate('ERR_SUBSCRIBE_GENERIC_BODY')}
                    hintText={`${translate('ERR_CODE')} ${code}`}
                    buttons={[
                        {
                            text: translate('OK_BUTTON'),
                            onClick: cancel,
                        },
                    ]}
                />
            );
        }

        return getGenericError();
    };

    const getScreen = () => {
        if (!step) {
            return null;
        }

        if (pendingFlow && !subscriptionOfferError) {
            if (step === FlowStep.SELECT_OFFER) {
                return getSelectOfferScreen();
            }

            if (step === FlowStep.CONFIRM_OFFER) {
                return getConfirmOfferScreen();
            }
        }

        return getErrorAlert();
    };

    return getScreen;
};

export const useSubscriptionChangedObserver = (packageIds: string[], callback: () => void, deps: any[] = []) => {
    const { addSubscriptionChangedListener, removeSubscriptionChangedListener } = useSubscription();

    const onChanged = (data: SubscriptionValidatorInfo) => {
        if (data && packageIds.length && packageIds.includes(data.packageId)) {
            callback();
        }
    };

    useEffect(() => {
        addSubscriptionChangedListener(onChanged);
        return () => removeSubscriptionChangedListener(onChanged);
    }, deps);
};
