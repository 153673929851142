import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { usePersistentState } from '../../hooks/useStorage/useStorage';
import { PinModal } from '../../components/PinModal/PinModal';
import translate from '../../utils/fnTranslate';
import localConfig from '../../config/localConfig';
import { LocalStorage } from '../../types/Storage';
import { useConfig } from '../useConfig/ConfigContext';
import { useDataFetcher } from '../../hooks/useDataFetcher/useDataFetcher';
import { ChangePin, ValidatePin } from '../../types/ApiTypes';
import Api from '../../api/Api';
import { useGlobalNetworkError, useGlobalNoInternet } from '../../hooks/withNetworkCheck/withNetworkCheck';

export enum PinFlowType {
    CHECK = 'flow_check',
    CHANGE = 'flow_change',
}

enum PinChangeStep {
    CHECK = 'step_check',
    ENTER = 'step_enter',
    CONFIRM = 'step_confirm',
}

enum PinError {
    VALIDATION_ERROR = 'validation',
    CONFIRM_ERROR = 'confirmation',
    TOO_MANY_WRONG_ATTEMPTS_ERROR = 'too_many_wrong_attempts',
}

const PIN_ERROR_MESSAGES = {
    [PinError.VALIDATION_ERROR]: 'PIN_SCREEN_BODY_WRONG_PIN',
    [PinError.CONFIRM_ERROR]: 'CHANGE_PURCHASE_PIN_SCREEN_HINT',
    [PinError.TOO_MANY_WRONG_ATTEMPTS_ERROR]: 'PIN_SCREEN_BODY_BLOCKED',
};

export type PinCallback = {
    onPinValidated: () => void;
    onDialogClosed: () => void;
};

const usePinService = () => {
    const { wrong_attempts_lock_duration, allowed_wrong_attempts } = useConfig()?.config?.app_config?.pin_settings ?? {};

    const [flow, setFlow] = useState<PinFlowType>(null);
    const [step, setStep] = useState<PinChangeStep>(null);
    const [error, setError] = useState<PinError>(null);

    const [oldPin, setOldPin] = useState<string>(null);
    const [changePin, setChangePin] = useState<string>(null);
    const [resetInput, setResetInput] = useState<boolean>(false);

    const [pinCallback, setPinCallback] = useState<PinCallback>(null);

    const { addToast } = useToasts();

    const { LOCK_PIN_STORE_KEY, WRONG_PIN_ATTEMPTS } = localConfig.pin;

    const { set: setLockPinUntil, store: lockPinUntil } = usePersistentState(LOCK_PIN_STORE_KEY as keyof LocalStorage, -1);
    const { set: setWrongPinAttempts, store: wrongPinAttempts } = usePersistentState(WRONG_PIN_ATTEMPTS as keyof LocalStorage, 0);

    const { onNoInternet } = useGlobalNoInternet();
    const { onNetworkError } = useGlobalNetworkError();

    const closePinDialog = (notifyOnCallback = true) => {
        if (pinCallback && notifyOnCallback) {
            pinCallback.onDialogClosed();
        }

        setFlow(null);
        setStep(null);
        setError(null);
        setOldPin(null);
        setChangePin(null);
        setResetInput(null);
        setPinCallback(null);
    };
    const {
        error: validatePinError,
        response: pinValid,
        loading: validatingPin,
        fetcher: validatePin,
        reset: resetValidatePin,
    } = useDataFetcher<any, ValidatePin>(
        param => Api.validatePin(param.pin),
        () => {
            onNoInternet();
            // eslint-disable-next-line no-use-before-define
            closePinDialog(false);
        },
        onNetworkError
    );

    const { error: pinUpdateError, response: pinUpdated, loading: updatingPin, fetcher: updatePin, reset: resetUpdatePin } = useDataFetcher<
        any,
        ChangePin
    >(
        param => Api.changePin(param.oldPin, param.newPin),
        () => {
            onNoInternet();
            // eslint-disable-next-line no-use-before-define
            closePinDialog(false);
        },
        onNetworkError
    );

    const openPinDialog = (pinFlow: PinFlowType, callback?: PinCallback) => {
        setFlow(pinFlow);

        if (pinFlow === PinFlowType.CHANGE) {
            setStep(PinChangeStep.CHECK);
        }

        if (callback) {
            setPinCallback(callback);
        }

        const now = Date.now();
        if (typeof lockPinUntil === 'number' && lockPinUntil && lockPinUntil > now) {
            setError(PinError.TOO_MANY_WRONG_ATTEMPTS_ERROR);
        }
    };

    const onInvalidPinTried = () => {
        setError(PinError.VALIDATION_ERROR);

        const wrongAttemptsNr = wrongPinAttempts ? (wrongPinAttempts as number) : 0;

        if (wrongAttemptsNr + 1 >= allowed_wrong_attempts) {
            setLockPinUntil(Date.now() + wrong_attempts_lock_duration);
            setWrongPinAttempts(0);
            setError(PinError.TOO_MANY_WRONG_ATTEMPTS_ERROR);
        } else {
            setWrongPinAttempts(wrongAttemptsNr + 1);
        }

        setResetInput(true);
    };

    const onValidPinTried = () => {
        setLockPinUntil(-1);
        setWrongPinAttempts(0);
        setError(null);
    };

    const checkEntered = (pin: string) => {
        validatePin({
            pin,
        });
    };

    const onSuccessfullyValidated = () => {
        if (flow === PinFlowType.CHANGE) {
            setError(null);
            setStep(PinChangeStep.ENTER);
            onValidPinTried();
        }

        if (flow === PinFlowType.CHECK) {
            onValidPinTried();

            if (pinCallback) {
                pinCallback.onPinValidated();
            }
        }
    };

    const onValidationError = () => {
        if (flow === PinFlowType.CHANGE) {
            setError(PinError.VALIDATION_ERROR);
            onInvalidPinTried();
        }

        if (flow === PinFlowType.CHECK) {
            onInvalidPinTried();
        }
    };

    const onSuccessfullyUpdated = () => {
        closePinDialog();
        addToast(
            {
                title: translate('NOTIFICATION_PIN_SUCCESS_TITLE'),
                message: translate('NOTIFICATION_PIN_SUCCESS_BODY'),
            },
            {
                appearance: 'success',
                autoDismiss: true,
            }
        );
    };

    const onUpdateError = () => {
        closePinDialog();
    };

    const validateAndGoNext = (pin: string) => {
        if (flow === PinFlowType.CHANGE) {
            switch (step) {
                case PinChangeStep.CHECK:
                    setOldPin(pin);
                    validatePin({
                        pin,
                    });
                    break;
                case PinChangeStep.ENTER:
                    setChangePin(pin);
                    setStep(PinChangeStep.CONFIRM);
                    setError(null);
                    break;
                case PinChangeStep.CONFIRM:
                    if (pin === changePin) {
                        updatePin({
                            oldPin,
                            newPin: pin,
                        });
                    } else {
                        setError(PinError.CONFIRM_ERROR);
                        setStep(PinChangeStep.ENTER);
                        setChangePin(null);
                    }
                    break;
                default:
                    break;
            }
        }

        setResetInput(true);
    };

    useEffect(() => {
        if (resetInput) {
            setResetInput(false);
        }
    }, [resetInput]);

    useEffect(() => {
        const now = Date.now();

        if (typeof lockPinUntil === 'number' && lockPinUntil && lockPinUntil > now) {
            setError(PinError.TOO_MANY_WRONG_ATTEMPTS_ERROR);
            setTimeout(() => {
                setLockPinUntil(-1);
                setError(null);
            }, lockPinUntil - now);
        } else {
            setLockPinUntil(-1);
            setError(null);
        }
    }, [lockPinUntil]);

    useEffect(() => {
        if (pinValid) {
            onSuccessfullyValidated();
        }

        if (validatePinError) {
            onValidationError();
        }

        if (pinValid || validatePinError) {
            resetValidatePin();
        }
    }, [pinValid, validatePinError]);

    useEffect(() => {
        if (pinUpdated) {
            onSuccessfullyUpdated();
        }

        if (pinUpdateError) {
            onUpdateError();
        }

        if (pinUpdated || pinUpdateError) {
            resetUpdatePin();
        }
    }, [pinUpdated, pinUpdateError]);

    return {
        openPinDialog,
        closePinDialog,
        validateAndGoNext,
        checkEntered,
        pin: flow
            ? {
                  flow,
                  step,
              }
            : null,
        error,
        resetInput,
        lockPinUntil,
        managing: validatingPin || updatingPin,
    };
};

export const PinContext = createContext<ReturnType<typeof usePinService>>(null);

export const PinProvider = ({ children }) => {
    const service = usePinService();

    return <PinContext.Provider value={service}>{children}</PinContext.Provider>;
};

export const usePin = () => useContext(PinContext);

export const PurchasePinDialog: FC = () => {
    const { pin, closePinDialog, validateAndGoNext, checkEntered, error, resetInput, lockPinUntil, managing } = usePin();

    if (pin?.flow === PinFlowType.CHECK) {
        return (
            <PinModal
                modalTitle={translate('PIN_SCREEN_TITLE')}
                modalText={error ? translate(PIN_ERROR_MESSAGES[error]) : translate('PIN_SCREEN_BODY')}
                closeOverlay={closePinDialog}
                onPinInserted={(inserted: string[]) => checkEntered(inserted.join(''))}
                lockPinUntil={lockPinUntil}
                resetInput={resetInput}
                loading={managing}
            />
        );
    }

    if (pin?.flow === PinFlowType.CHANGE) {
        let title;
        let subText;

        if (pin.step === PinChangeStep.CHECK) {
            title = translate('PIN_SCREEN_TITLE');
            subText = translate('PIN_SCREEN_BODY');
        } else if (pin.step === PinChangeStep.ENTER) {
            title = translate('CHANGE_PURCHASE_PIN_SCREEN_TITLE');
            subText = translate('CHANGE_PURCHASE_PIN_SCREEN_BODY');
        } else if (pin.step === PinChangeStep.CONFIRM) {
            title = translate('CHANGE_PURCHASE_PIN_SCREEN_RE_ENTER_TITLE');
            subText = translate('CHANGE_PURCHASE_PIN_SCREEN_RE_ENTER_BODY');
        }

        if (title && subText) {
            return (
                <PinModal
                    modalTitle={title}
                    modalText={error ? translate(PIN_ERROR_MESSAGES[error]) : subText}
                    resetInput={resetInput}
                    lockPinUntil={lockPinUntil}
                    closeOverlay={closePinDialog}
                    onPinInserted={(inserted: string[]) => validateAndGoNext(inserted.join(''))}
                    loading={managing}
                />
            );
        }
    }

    return null;
};
