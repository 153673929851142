import { useEffect, useRef, useState } from 'react';
import axios, { Canceler } from 'axios';
import * as Sentry from '@sentry/react';
import { FetchResponse, UseBackendResponse } from '../../types/ApiTypes';
import localConfig from '../../config/localConfig';
import { useApp } from '../../providers/useApp/AppContext';
import { useConfig } from '../../providers/useConfig/ConfigContext';

export const healthCheck = () => {
    return new Promise<boolean>(resolve => {
        fetch(localConfig.data.healthCheckURL, {})
            .then(response => {
                resolve(response.ok);
            })
            .catch(() => {
                resolve(false);
            });
    });
};

export const useDataFetcher = <Data extends any, Param extends any>(
    executor: (param: Param) => Promise<FetchResponse<Data>>,
    onNoInternet?: () => void,
    onNetworkError?: (errorCode: number) => void
): UseBackendResponse<Data, Param> => {
    const [response, setResponse] = useState<Data | null>(null);
    const [responseCode, setResponseCode] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const updateTimeOutRef = useRef(null);
    const { setNetworkError, setNoInternet } = useApp();
    const { config } = useConfig();

    const backEndErrorUpdateInterval = config?.api_config.backend_error_update_interval;

    const isOffline = () => {
        return !window.navigator.onLine;
    };

    const isCancelResponse = (beResponse: any) => axios.isCancel(beResponse);

    const reset = () => {
        setLoading(false);
        setResponse(null);
        setError(null);
        setResponseCode(null);
    };

    const fetcher = async (param: Param, keepOldState: boolean = false) => {
        setLoading(true);

        if (!keepOldState) {
            setError(null);
            setResponse(null);
            setResponseCode(null);
        }

        try {
            const apiRequest = executor(param);

            if (apiRequest) {
                const apiResponse = await apiRequest;

                if (!isCancelResponse(apiResponse?.error)) {
                    if (isOffline() && onNoInternet) {
                        updateTimeOutRef.current = setInterval(() => {
                            if (!isOffline()) {
                                clearInterval(updateTimeOutRef.current);
                                setNoInternet(false);
                            }
                        }, backEndErrorUpdateInterval);
                        onNoInternet();
                    } else if (
                        (!!apiResponse?.error ||
                            apiResponse?.status?.toString().startsWith('4') ||
                            apiResponse?.status?.toString().startsWith('5')) &&
                        onNetworkError
                    ) {
                        if (await healthCheck()) {
                            setError(apiResponse.error);
                            setLoading(false);
                            setNetworkError(null);
                        } else {
                            updateTimeOutRef.current = setInterval(async () => {
                                if (await healthCheck()) {
                                    clearInterval(updateTimeOutRef.current);
                                    setNetworkError(null);
                                }
                            }, backEndErrorUpdateInterval);
                            setLoading(false);
                            setError(apiResponse.error);
                            onNetworkError(apiResponse.status || -1);
                        }
                    } else {
                        setResponse(apiResponse.response);
                        setResponseCode(apiResponse.status);
                        setLoading(false);
                        setNetworkError(null);
                        setNoInternet(false);
                    }

                    return apiResponse;
                }
            }

            return null;
        } catch (e) {
            setResponse(null);
            setResponseCode(null);
            setError(e);
            setLoading(false);
            console.warn(e);
            Sentry.captureException(e);

            return e;
        }
    };

    return {
        response,
        error,
        loading,
        fetcher,
        reset,
        responseCode,
    };
};

export const useRequestCanceller = () => {
    const canceller = useRef<Canceler>(null);

    useEffect(() => {
        return () => {
            if (canceller.current) {
                canceller.current();
            }
        };
    }, []);

    return (cancellerExecutor: Canceler) => {
        canceller.current = cancellerExecutor;
    };
};
