import React, { FC, MutableRefObject, useEffect, useRef } from 'react';
import { Input, InputWrapper, Placeholder } from './InputFieldWithPlaceholder.css';

type PropTypes = {
    defaultValue: string;
    type: 'text' | 'password';
    placeholder: string;
    onChange: () => void;
    onKeyUp: (e) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    inputRef: MutableRefObject<HTMLInputElement>;
};

export const InputFieldWithPlaceholder: FC<PropTypes> = ({
    defaultValue = '',
    type,
    placeholder,
    onChange,
    onKeyUp,
    onFocus = () => {},
    onBlur = () => {},
    inputRef,
    ...rest
}) => {
    const inputReference = useRef<HTMLInputElement>(null);
    const placeholderReference = useRef<HTMLSpanElement>(null);

    const onInputChange = () => {
        placeholderReference.current.style.display = inputReference.current.value.length ? 'none' : 'block';
        onChange();
    };

    useEffect(() => {
        placeholderReference.current.style.display = defaultValue ? 'none' : 'block';
    }, [defaultValue]);

    useEffect(() => {
        inputRef.current = inputReference.current;
    }, [inputReference.current]);

    return (
        <InputWrapper {...rest}>
            <Placeholder ref={placeholderReference}>{placeholder}</Placeholder>
            <Input
                defaultValue={defaultValue}
                type={type}
                onKeyUp={onKeyUp}
                onChange={onInputChange}
                onFocus={onFocus}
                onBlur={onBlur}
                ref={inputReference}
            />
        </InputWrapper>
    );
};
