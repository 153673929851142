import React, { FC, useEffect } from 'react';
import { isIOS } from 'react-device-detect';
import { PageScrollerWrapper } from './PageScroller.css';
import { useScreenState } from '../../hooks/useScreenState/useScreenState';
import { usePageScroller } from './usePageScroller';

export const PageScroller: FC = ({ children }) => {
    const { scrollTop, scrollTo } = usePageScroller();
    const { obtainState, storeState, locationKey } = useScreenState();

    useEffect(() => {
        const scrollByLocation = obtainState('pageScroll')?.value ?? 0;
        setTimeout(() => scrollTo(scrollByLocation), 1);
    }, [locationKey]);

    useEffect(() => {
        storeState('pageScroll', scrollTop);
    }, [scrollTop]);

    return <PageScrollerWrapper isIOS={isIOS}>{children}</PageScrollerWrapper>;
};
