import { AxiosRequestConfig } from 'axios';

export type UseBackendResponse<Data, Param> = {
    response: Data | null;
    error: any;
    loading: boolean;
    fetcher: Fetcher<Param>;
    reset: () => any;
    responseCode: string | null | number;
};

export type FetchParamById = {
    id: string;
};

export type FetchParamByIdList = {
    ids: string[];
};

export type FetchPlayingAsset = {
    id: string;
    createSession: boolean;
};

export type CreateSession = {
    id: string;
    type: 'content' | 'channel';
};

export type FetchParamRent = {
    productId: string;
    offerId: string;
};

export type FetchParamsSubscribe = {
    productId: number;
};

export type ValidatePin = {
    pin: string;
};

export type ChangePin = {
    oldPin: string;
    newPin: string;
};

export type ApiSource<T> = {
    url?: string;
    options?: AxiosRequestConfig & {
        mock?: any; // will not execute request but rather return this data
        responseHandler?: (response: any) => FetchResponse<T>;
    };
    dataSource?: any;
    dataParser?: (data: any) => T;
};

export type FetchResponse<T> = {
    response: T;
    error: any;
    status?: number;
};

export type Paginated = {
    start: number;
    limit: number;
};

export type Fetcher<Param> = (param: Param, keepOldState?: boolean) => any;

export enum DataSourceService {
    TV = 'Tv',
    VOD = 'Vod',
    WORLD = 'World',
    RECOMMENDATIONS = 'Recommendations',
    SEARCH = 'Search',
    RECORDING = 'Recording',
}

export enum DataSourceMethod {
    GET_CATEGORY_REQUEST = 'get_category',
    GET_NOW_NEXT_REQUEST = 'get_now_next',
    GET_CHANNELS_REQUEST = 'get_channels',
    GET_TV_CHANNELS_BY_CATEGORY = 'get_tv_channels_by_category',
    GET_TV_RELATED = 'get_related',
    GET_CAST_CREW = 'get_cast_crew',

    GET_SERIES_CATEGORY_REQUEST = 'get_series_category',
    GET_PURCHASED_TITLES = 'purchased_titles',
    GET_VOD_RELATED = 'get_similar',

    GET_DYNAMIC_MOVIES = 'dynamic_movies',
    GET_DYNAMIC_SERIES = 'dynamic_series',
    GET_DYNAMIC_CATEGORY_CONTENT = 'category_content',
    GET_WORLD_CATEGORIES = 'get_categories',

    GET_MOVIE_RECOMMENDATIONS = 'movie',
    GET_SERIES_RECOMMENDATIONS = 'series',

    SEARCH_MOVIES = 'search_movies',
    SEARCH_SERIES = 'search_series',

    GET_MOVIES = 'get_movies',
    GET_EPISODES = 'get_episodes',
    GET_SCHEDULED = 'get_scheduled',
    GET_ALL_RECORDINGS = 'get_all_recordings',
}

export enum FilterItems {
    categoryId = 'categoryId',
    categoryType = 'categoryType',
}

export enum UserAgent {
    DASH = 'ElisaDASH',
    HLS = 'ElisaCMAF',
}
