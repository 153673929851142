import React from 'react';
import SVGInline from 'react-svg-inline';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { useConfig } from '../../providers/useConfig/ConfigContext';
import { MenuLink } from './Header';
import { Breakpoints, MQ } from '../../style/styled-components/cssMediaQueries';
import icons from '../../style';
import { useScreenState } from '../../hooks/useScreenState/useScreenState';
import translate from '../../utils/fnTranslate';
import { useMenu } from '../../hooks/useMenu';
import { usePageScroller } from '../PageScroller/usePageScroller';
import { useSearch } from '../../providers/useSearch/SearchContext';
import { hexToRgba } from '../../utils/fnColors';

export interface MenuProps {
    pageId: string;
    scroller?: any;
    id?: string;
    className?: string;
    backgroundColor?: string;
    isIOS: boolean;
}

const BareMainMenu: React.FC<MenuProps> = ({ pageId, ...rest }) => {
    const { config } = useConfig();
    const menuData = config?.content_config?.main_menu ?? [];
    const { emptyStates } = useScreenState();
    const { scrollTo } = usePageScroller();
    const { activeMenuIndex } = useMenu(
        pageId,
        menuData.filter(item => item.id && item.id.length)
    );
    const { exitSearch } = useSearch();

    return (
        <div {...rest}>
            <ul>
                {menuData.map((menu, index) => {
                    const path = config.getPagePath(menu.id);
                    let isActive = activeMenuIndex === index;

                    // menu has no active item -> fallback to home
                    if (activeMenuIndex === -1 && menu.id === 'HOME') {
                        isActive = true;
                    }

                    const text = translate(menu.title);

                    return (
                        <li key={index}>
                            <MenuLink
                                to={path}
                                active={isActive}
                                onClick={() => {
                                    emptyStates(path);
                                    scrollTo(0);
                                    exitSearch(false);
                                }}
                                data-event-name={'click'}
                                data-test-id={`header-menu`}
                                data-track-id={`menu_${text.toLowerCase()}`}
                            >
                                <SVGInline svg={icons[menu.icon] || ''} />
                                <span className={'title'}>{text}</span>
                            </MenuLink>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MainMenu = styled(({ isIOS, ...rest }) => <BareMainMenu {...rest} />)<{ isIOS: boolean }>`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${props => props.theme.brandedConfig.background};
    display: block;
    border-top: 1px solid ${props => hexToRgba(props.theme.brandedConfig.white_color, 0.1)};

    ${MQ(Breakpoints.m)} {
        float: left;
        padding-left: 40px;
        background-color: transparent;
        position: relative;
        display: none;
        border: none;

        &:not(.hide-on-desktop) {
            display: block;
        }
    }

    & ul {
        display: flex;
        padding: 6px 0 ${props => (props.isIOS ? '20px' : '6px')} 0;
        justify-content: space-evenly;

        ${MQ(Breakpoints.s)} {
            padding: 14px 0 ${props => (props.isIOS ? '20px' : '14px')} 0;
        }

        ${MQ(Breakpoints.m)} {
            display: block;
            list-style: none;
            border: 0;
            text-align: left;
            padding: 0;
        }
    }

    & ul > li {
        font-size: 0;
        text-align: center;

        .SVGInline {
            display: block;
            height: 20px;
            vertical-align: middle;

            svg {
                height: 100%;
                width: auto;
            }

            ${MQ(Breakpoints.s)} {
                display: inline-block;
                padding-right: 12px;
            }
        }

        .title {
            display: block;
            vertical-align: middle;

            ${MQ(Breakpoints.s)} {
                display: inline-block;
            }
        }

        ${MQ(Breakpoints.m)} {
            display: inline-block;
            padding: 0 12px;

            .SVGInline {
                display: none;
            }

            .title {
                line-height: inherit;
            }
        }

        a {
            :hover {
                opacity: 0.6;
            }
        }
    }
`;
