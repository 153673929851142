import { css } from 'styled-components';
import spinner from '../../assets/images/spinner.png';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { fontNormal, maxZIndex } from '../../style/styled-components/cssGlobalVariables';
import { hexToRgba } from '../../utils/fnColors';

export const LoaderText = styled.div`
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-family: ${fontNormal};
`;

export const LoaderIcon = styled.div`
    background-image: url(${spinner});
    background-size: cover;
    display: inline-block;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    will-change: transform;
`;

export const LoaderOverlay = styled.div<{ hasBackgroundColor?: boolean; backgroundOverwrite?: string }>`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${props =>
        props.hasBackgroundColor ? props.backgroundOverwrite ?? hexToRgba(props.theme.brandedConfig.black_color, 0.95) : 'transparent'};
    z-index: ${maxZIndex};
    pointer-events: all;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    ${LoaderIcon} {
        grid-column: 1;
        grid-row: 1;
        align-self: center;
        justify-self: center;
    }
    ${LoaderText} {
        grid-column: 1;
        grid-row: 1;
        align-self: center;
        justify-self: center;
        transform: translateY(62px);
    }
`;

export const LoaderContainer = styled.div<{ minHeight?: string; verticalTranslate?: string }>`
    text-align: center;
    min-height: ${props => (props.minHeight ? `${props.minHeight}` : '150px')};
    transform: ${props => (props.verticalTranslate ? `translateY(${props.verticalTranslate})` : 'none')};

    ${LoaderIcon} {
        ${props =>
            props.minHeight
                ? css`
                      position: absolute;
                      left: 50%;
                      top: 50%;
                  `
                : css``}
    }
`;
