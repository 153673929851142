import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';

export const Input = styled.input`
    outline: none;
    background: transparent;
    border: none;
`;

export const Placeholder = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    width: 91%;
    height: 100%;
    pointer-events: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: none;
`;

export const InputWrapper = styled.div`
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;
`;
