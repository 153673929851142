import { css } from 'styled-components';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold, maxZIndex } from '../../style/styled-components/cssGlobalVariables';
import { Breakpoints, MQ } from '../../style/styled-components/cssMediaQueries';
import { hexToRgba } from '../../utils/fnColors';

export const PlayerWrapperContainer = styled.div<{ isMini: boolean; isEpg: boolean; isMobile: boolean }>`
    position: relative;
    width: 100%;
    height: 100%;
    background: ${props => props.theme.brandedConfig.black_color};
    transform: ${props => (!props.isMini ? 'none' : '')};

    ${props =>
        props.isMini
            ? css`
                  overflow: hidden;
                  position: fixed;
                  cursor: move;
                  right: 25px;
                  bottom: ${props.isEpg && props.isMobile ? '130px' : '65px'};
                  width: 216px;
                  height: 122px;
                  border-radius: 8px;
                  z-index: ${maxZIndex};
                  box-shadow: 0 22px 34px 0 ${hexToRgba(props.theme.brandedConfig.black_color, 0.25)};

                  ${MQ(Breakpoints.s)} {
                      width: 280px;
                      height: 157px;
                  }

                  ${MQ(Breakpoints.m)} {
                      width: 400px;
                      height: 225px;
                      bottom: 60px;
                      right: 96px;
                  }
              `
            : css``}
`;

export const PlayerObjectContainer = styled.div`
    width: 100%;
    height: 100%;

    video {
        width: 100%;
        height: 100%;
    }
`;

export const PlayerErrorText = styled.p`
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    max-width: 310px;

    a {
        font-family: ${fontBold};
        color: ${props => props.theme.brandedConfig.primary};
        text-decoration: underline;

        &:hover {
            color: ${props => props.theme.brandedConfig.primary};
        }
    }

    ${MQ(Breakpoints.m)} {
        text-align: left;
    }
`;
export const PlayerErrorCode = styled.p`
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: ${props => props.theme.brandedConfig.high_contrast};
    margin-top: 8px;

    ${MQ(Breakpoints.m)} {
        text-align: left;
    }
`;

export const PlayerErrorContainer = styled.div<{ isEmbedded: boolean }>`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: ${props => props.theme.brandedConfig.black_color};

    ${props =>
        props.isEmbedded
            ? css`
                  ${PlayerErrorText}, ${PlayerErrorCode} {
                      ${MQ(Breakpoints.m)} {
                          text-align: center;
                      }
                  }
              `
            : css``}
`;

export const PlayerErrorWrapper = styled.div<{ hide: boolean; hideIfMobile: boolean; isMini: boolean; isEmbedded: boolean }>`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: ${maxZIndex + 2};
    opacity: ${props => (props.hide || props.hideIfMobile ? '0' : '1')};
    transition: opacity 0.3s ease;

    ${MQ(Breakpoints.s)} {
        left: 50%;
        top: 40%;
        transform: translate(-50%, -40%);
    }

    ${MQ(Breakpoints.m)} {
        left: ${props => (props.isEmbedded ? '50%' : '72px')};
        top: ${props => (props.isEmbedded ? '50%' : '32px')};
        transform: ${props => (props.isEmbedded ? 'translate(-50%, -50%)' : 'none')};
    }

    ${props =>
        props.isMini
            ? css`
                  ${PlayerErrorText} {
                      display: none;
                  }
                  ${PlayerErrorCode} {
                      display: none;
                  }
              `
            : ''}
`;

export const CastImageOverlay = styled.div`
    background: ${props => props.theme.globalConfig.black_color};
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    img {
        height: 56.25vw;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        object-fit: contain;
    }
`;
