import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { Breakpoints, MQ } from '../../style/styled-components/cssMediaQueries';
import Button from '../Buttons/Button/Button';
import { cookieNotificationZIndex, fontBold } from '../../style/styled-components/cssGlobalVariables';

export const CookieNotificationContainer = styled.div`
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: ${props => props.theme.brandedConfig.low_contrast};
    z-index: ${cookieNotificationZIndex};
    padding: 16px 16px 16px 16px;

    ${MQ(Breakpoints.s)} {
        padding-left: 32px;
        padding-right: 32px;
    }

    ${MQ(Breakpoints.m)} {
        padding-left: 40px;
        padding-right: 40px;
    }
`;

export const CookieNotificationMessage = styled.div`
    font-size: 12px;
    line-height: normal;
    padding: 0;

    a {
        color: ${props => props.theme.brandedConfig.primary};
        cursor: pointer;
        font-family: ${fontBold};
        font-size: 12px;

        &:hover {
            opacity: 0.5;
        }
    }

    ${MQ(Breakpoints.xs)} {
        font-size: 13px;
        padding-right: 145px;
        a {
            font-size: 13px;
        }
    }

    ${MQ(Breakpoints.s)} {
        font-size: 13px;
        padding-right: 197px;
        a {
            font-size: 13px;
        }
    }
`;

export const CookieNotificationButton = styled(Button)`
    position: relative;
    width: 100%;
    cursor: pointer;
    background-color: ${props => props.theme.brandedConfig.black_color};
    top: 50%;
    margin-top: 20px;
    right: unset;
    transform: none;

    ${MQ(Breakpoints.xs)} {
        width: 120px;
        right: 16px;
        margin: 0;
        position: absolute;
        transform: translateY(-50%);
    }

    ${MQ(Breakpoints.s)} {
        right: 32px;
        position: absolute;
    }

    ${MQ(Breakpoints.m)} {
        right: 40px;
        position: absolute;
        transform: translateY(-50%);
        height: 44px;
    }
`;
