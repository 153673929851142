import { ApiConfig, AppConfig, FeatureControl, LayoutConfig } from '../../types/Config';

export const API_DEFAULT_CONFIG: Partial<ApiConfig> = {
    page_size: 20,
    slowlog_limit: 10,
    timeout_limit: 40,
    update_interval: 86400000,
    backend_error_update_interval: 30000,
    config_id: 'default_standard_web',
};

export const APP_CONFIG_DEFAULT: AppConfig = {
    general: {
        title: 'Elisa Elamus',
        language: 'en',
    },
    player_settings: {
        skip_forward_interval: 30,
        skip_back_interval: 10,
        hide_controls_after: 5,
        bookmark_threshold: 95,
        binge_watching_threshold: 2,
        startover_min_position_ms: 30000,
        binge_watching_start_timeout: 15000,
        binge_watching_inactivity_timeout: 10800000,
        timeshift_disable_duration_threshold: 21600000,
        playback_languages_audio: [
            {
                iso_code: 'et',
                title: 'SETTINGS_LANGUAGE_ET',
            },
            {
                iso_code: 'ru',
                title: 'SETTINGS_LANGUAGE_RU',
            },
            {
                iso_code: 'en',
                title: 'SETTINGS_LANGUAGE_EN',
            },
            {
                iso_code: 'fi',
                title: 'SETTINGS_LANGUAGE_FI',
            },
            {
                iso_code: 'sv',
                title: 'SETTINGS_LANGUAGE_SW',
            },
            {
                iso_code: 'de',
                title: 'SETTINGS_LANGUAGE_DE',
            },
            {
                iso_code: 'fr',
                title: 'SETTINGS_LANGUAGE_FR',
            },
            {
                iso_code: 'it',
                title: 'SETTINGS_LANGUAGE_IT',
            },
            {
                iso_code: 'uk',
                title: 'SETTINGS_LANGUAGE_UK',
            },
        ],
        playback_languages_subtitles: [
            {
                iso_code: 'off',
                title: 'SETTINGS_LANGUAGE_OFF',
            },
            {
                iso_code: 'et',
                title: 'SETTINGS_LANGUAGE_ET',
            },
            {
                iso_code: 'ru',
                title: 'SETTINGS_LANGUAGE_RU',
            },
            {
                iso_code: 'en',
                title: 'SETTINGS_LANGUAGE_EN',
            },
            {
                iso_code: 'fi',
                title: 'SETTINGS_LANGUAGE_FI',
            },
        ],
        bookmark_save_interval: 120000,
    },
    languages: null,
    product_settings: {
        replay_window_time_machine: 7,
        replay_window_time_machine_plus: 30,
        product_code_time_machine_plus: 'TimeMachinePlus',
        linear_prefix: 'OTT_Linear',
        npvr_prefix: 'OTT_NPVR',
        subscription_update_delay: 50000,
    },
    epg_settings: {
        day_start_hour: 0,
        future_visibility: 7,
        past_visibility: 14,
        prime_time: null,
        prime_time_dst: 'GMT',
        row_height: 52,
        pixels_per_minute: 6,
    },
    pin_settings: {
        allowed_wrong_attempts: 3,
        wrong_attempts_lock_duration: 30,
    },
    search_settings: {
        key_threshold: 3,
        trigger_timeout: 250,
    },
    auth: {
        realm: 'kontoapi',
        client_id: 'web-app-dev',
        google_cast_client_id: 'tv-chromecast-dev',
    },
    miscellanious: {
        detail_page_small_image_threshold: 768,
        gallery_auto_scroll: 6000,
        max_items_collection: 20,
        sign_up_link: 'https://www.elisa.ee/et/eraklient/tv/tv-kodus/elisa-elamus-app',
        sign_up_link_huub: 'https://www.elisa.ee/itb/elamus-liitumine?productId=4804&appSignup=true',
        internet_speed_test: [
            {
                endpoint_name: 'Elisa Server 1',
                endpoint_url: 'http://aplayout5.elisa.ee:9280/backend/garbage.php?r=0.022609128819469637&ckSize=1',
            },
        ],
        youbora_account_code: '',
    },
    chromecast: {
        receiver_id: 'D8079804', // '102E8BF9', // '2510D630', // 'D8079804', // '341EF4DA',
        namespace: 'urn:x-cast:com.google.shaka.v2',
        media_namespace: 'urn:x-cast:com.google.cast.media',
    },
    content_markers: {
        _DEFAULT: {
            color: '#eeeeee',
            background: '#2264F4',
        },
        CONTENT_MARKER_NOW: {
            color: '#eeeeee',
            background: '#2264F4',
            priority: 3,
        },
        CONTENT_MARKER_LIVE: {
            color: '#eeeeee',
            background: '#ff1d15',
            priority: 2,
        },
        CONTENT_MARKER_NOT_SUBSCRIBED: {
            color: '#eeeeee',
            background: '#2264f4',
            priority: 6,
        },
        CONTENT_MARKER_NEW: {
            color: '#eeeeee',
            background: '#2264f4',
            priority: 0,
        },
        CONTENT_MARKER_FREE: {
            color: '#eeeeee',
            background: '#2264f4',
            priority: 1,
            transparency: 1,
        },
        CONTENT_MARKER_NEW_EPISODES: {
            color: '#eeeeee',
            background: '#2264f4',
            priority: 0,
        },
    },
    locale_settings: {
        currency: 'EUR',
        currency_sign: '€',
        date_format: 'dd.MM.YYYY',
        decimal_seperator: ',',
        number_seperator: '.',
        time_format: 'HH:mm',
    },
    smart_app_banner: {
        android_app_id: 'ee.elisa.mobile',
        ios_app_id: 'ee.elisa.elamus.release',
    },
    meta_tags: {
        kids: {
            meta_title: 'META_TITLE_KIDS',
            meta_description: 'META_DESCRIPTION_KIDS',
        },
        rentals: {
            meta_title: 'META_TITLE_RENTALS',
            meta_description: 'META_DESCRIPTION_RENTALS',
        },
        rentals_collection_new_movies: {
            meta_title: 'META_TITLE_RENTALS_COLLECTION_NEW_MOVIES',
            meta_description: 'META_DESCRIPTION_RENTALS_COLLECTION_NEW_MOVIES',
        },
        rentals_collection_action: {
            meta_title: 'META_TITLE_RENTALS_COLLECTION_ACTION',
            meta_description: 'META_DESCRIPTION_RENTALS_COLLECTION_ACTION',
        },
        rentals_collection_comedy: {
            meta_title: 'META_TITLE_RENTALS_COLLECTION_COMEDY',
            meta_description: 'META_DESCRIPTION_RENTALS_COLLECTION_COMEDY',
        },
        rentals_collection_dramas: {
            meta_title: 'META_TITLE_RENTALS_COLLECTION_DRAMA',
            meta_description: 'META_DESCRIPTION_RENTALS_COLLECTION_DRAMA',
        },
        rentals_collection_estonian: {
            meta_title: 'META_TITLE_RENTALS_COLLECTION_ESTONIAN',
            meta_description: 'META_DESCRIPTION_RENTALS_COLLECTION_ESTONIAN',
        },
        rentals_collection_romantic: {
            meta_title: 'META_TITLE_RENTALS_COLLECTION_ROMANTIC',
            meta_description: 'META_DESCRIPTION_RENTALS_COLLECTION_ROMANTIC',
        },
        rentals_collection_bio: {
            meta_title: 'META_TITLE_RENTALS_COLLECTION_BIO',
            meta_description: 'META_DESCRIPTION_RENTALS_COLLECTION_BIO',
        },
        rentals_collection_family: {
            meta_title: 'META_TITLE_RENTALS_COLLECTION_FAMILY',
            meta_description: 'META_DESCRIPTION_RENTALS_COLLECTION_FAMILY',
        },
        rentals_collection_fiction: {
            meta_title: 'META_TITLE_RENTALS_COLLECTION_FICTION',
            meta_description: 'META_DESCRIPTION_RENTALS_COLLECTION_FICTION',
        },
        rentals_collection_documental: {
            meta_title: 'META_TITLE_RENTALS_COLLECTION_DOCUMENTAL',
            meta_description: 'META_DESCRIPTION_RENTALS_COLLECTION_DOCUMENTAL',
        },
        rentals_collection_horror: {
            meta_title: 'META_TITLE_RENTALS_COLLECTION_HORROR',
            meta_description: 'META_DESCRIPTION_RENTALS_COLLECTION_HORROR',
        },
        rentals_collection_historical: {
            meta_title: 'META_TITLE_RENTALS_COLLECTION_HISTORICAL',
            meta_description: 'META_DESCRIPTION_RENTALS_COLLECTION_HISTORICAL',
        },
        rentals_collection_musical: {
            meta_title: 'META_TITLE_RENTALS_COLLECTION_MUSICAL',
            meta_description: 'META_DESCRIPTION_RENTALS_COLLECTION_MUSICAL',
        },
        rentals_collection_anima: {
            meta_title: 'META_TITLE_RENTALS_COLLECTION_ANIMA',
            meta_description: 'META_DESCRIPTION_RENTALS_COLLECTION_ANIMA',
        },
        huub: {
            meta_title: 'META_TITLE_HUUB',
            meta_description: 'META_DESCRIPTION_HUUB',
        },
        tv_guide: {
            meta_title: 'META_TITLE_LIVE_TV',
            meta_description: 'META_DESCRIPTION_LIVE_TV',
        },
        tv_now_and_next: {
            meta_title: 'META_TITLE_NOW_AND_NEXT',
            meta_description: 'META_DESCRIPTION_NOW_AND_NEXT',
        },
        tv_channels: {
            meta_title: 'META_TITLE_TV_CHANNELS',
            meta_description: 'META_DESCRIPTION_TV_CHANNELS',
        },
        kids_collection_rentals: {
            meta_title: 'META_TITLE_KIDS_COLLECTION_RENTALS',
            meta_description: 'META_DESCRIPTION_KIDS_COLLECTION_RENTALS',
        },
        rentals_collection_sfanytime: {
            meta_title: 'META_TITLE_RENTALS_COLLECTION_STANYTIME',
            meta_description: 'META_DESCRIPTION_RENTALS_COLLECTION_STANYTIME',
        },
    },
};

export const FEATURE_CONTROLS_DEFAULT: FeatureControl = {
    search: true,
    guest_mode: true,
    guest_mode_show_login: true,
    mini_epg: true,
    multiple_audio: true,
    subtitles: true,
    google_cast: true,
    account_profile_language: true,
    analytics: true,
    epg_search: true,
    timeshift: false,
    dev_mode: false,
    pvr: false,
    buy_tvod: false,
    npvr_upsell: false,
    mini_player: false,
    thumbnail_scroll: false,
    self_service: false,
};

export const LAYOUT_CONFIG_DEFAULT: LayoutConfig = {
    advanced: false,
    background: '#000000',
    black_color: '#000000',
    error_color: '#FF1D15',
    secondary: '#2264F4',
    title: 'Elisa Elamus',
    language: 'en',
    logo: 'elisa_menu_logo',
    primary: '#2264F4',
    scrollbar_color: '#808080',
    success_color: '#89B613',
    white_color: '#FFFFFF',
    low_contrast: '#1A1A1A',
    high_contrast: '#EEEEEE',
    notification_background_color: '#333333',
    epg_hover_color: '#1a75ff',
    dropdown_background_color: '#2e2f42',
    epg_live_program_color: '#444556',
};
