import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { createPortal } from 'react-dom';
import { usePersistentState } from '../../hooks/useStorage/useStorage';
import { AuthError, UserInformation } from '../../types/AuthTypes';
import { setLocalStorage, removeLocalStorage } from '../../utils/fnStorage';
import { useDataFetcher } from '../../hooks/useDataFetcher/useDataFetcher';
import Api from '../../api/Api';
import { useConfig } from '../useConfig/ConfigContext';
import { getReplayWindowLengthByProduct } from '../../utils/fnData';
import { PurchasedProduct } from '../../types/Entitlement';
import { getEntitledReplayProduct, isSubscribedToProduct } from '../../utils/fnEntitlement';
import { init, login, logout, userInfo } from './AuthService';
import { useApp } from '../useApp/AppContext';
import { AlertDialog } from '../../components/AlertDialog/AlertDialog';
import translate from '../../utils/fnTranslate';
import DRM from '../player/DRM';
import sso from '../../api/SSO';
import { PageDeviceManager } from '../../components/Pages/PageDeviceManager/PageDeviceManager';
import { OverlayPageWrapper } from '../../components/Pages/PageDeviceManager/PageDeviceManager.css';

export const isAuthError = (value: any): value is AuthError => {
    return value && 'code' in value && 'msg' in value;
};

export const DEVICE_LIMIT = 'DEVICE_LIMIT';
export const UNKNOWN_DEVICE = 'UNKNOWN_DEVICE';

const useAuthService = () => {
    const [postLoginAction, setPostLoginAction] = useState<boolean>(false);
    const [purchasedProductsLoaded, setPurchasedProductsLoaded] = useState(false);
    const [postLoginPath, setPostLoginPath] = useState<string>(null);
    const [redirectAfterLogin, setRedirectAfterLogin] = useState(false);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [isGuestAlert, setIsGuestAlert] = useState(false);
    const [isDeviceLimitReached, setIsDeviceLimitReached] = useState(false);
    const [deviceRegistrationError, setDeviceRegistrationError] = useState(null);

    const [isLogOutAlert, setIsLogOutAlert] = useState(false);
    const [guestAlertClosePath, setGuestAlertClosePath] = useState(null);
    const { store: isGuest, set: setIsGuest } = usePersistentState('isGuestUser');
    const { store: cpeId, set: setCpeId } = usePersistentState('cpeId');

    const { config } = useConfig();
    const { appLanguage } = useApp();

    const {
        response: purchasedProducts,
        error: purchasedProductsError,
        loading: loadingPurchasedProducts,
        fetcher: fetchPurchasedProducts,
    } = useDataFetcher<PurchasedProduct[], any>(() => Api.fetchPurchasedProducts());

    const { response: userInfoDetailsResponse, fetcher: userInfoDetailsFetcher } = useDataFetcher<UserInformation, null>(() =>
        Api.fetchUserInfo()
    );

    const logUserIn = () => {
        removeLocalStorage('forceLogin');
        login(
            {
                locale: appLanguage,
                scope: null,
            },
            state => setLoggedIn(state)
        );
    };

    const logUserOut = (forceLogin = false, local = false) => {
        Api.removeDevice(DRM.getDeviceId());
        if (local) {
            setLoggedIn(false);
            setLocalStorage('token', null);
            setLocalStorage('trackingSessionId', null);
            setLocalStorage('wrongPinAttempts', null);
            setLocalStorage('pinLock', null);
            setLocalStorage('cpeId', null);
        } else {
            if (forceLogin) {
                removeLocalStorage('guestRefreshToken');
                removeLocalStorage('guestToken');
                setLocalStorage('forceLogin', true);
            } else {
                removeLocalStorage('forceLogin');
            }
            setIsGuest(false);
            setLoggedIn(false);

            logout(state => {
                setLoggedIn(state);
                setLocalStorage('token', null);
                setLocalStorage('trackingSessionId', null);
                setLocalStorage('wrongPinAttempts', null);
                setLocalStorage('pinLock', null);
                setLocalStorage('cpeId', null);
            });
        }
    };

    const getReplayWindowTimeFrame = (): number => {
        const replayProduct = getEntitledReplayProduct(purchasedProducts);

        if (!replayProduct) return -1;
        return getReplayWindowLengthByProduct(replayProduct.type);
    };

    const hasNPVR = (): boolean => isSubscribedToProduct(purchasedProducts, config.app_config.product_settings.npvr_prefix);

    const drmInit = (userData, resetDeviceLimitReached = false) => {
        if (resetDeviceLimitReached) {
            setIsDeviceLimitReached(false);
        }

        if (!userData?.guest) {
            DRM.init(Api.addDevice, Api.requestDRMToken, Api.closeDRMSession, (deviceId = null, deviceRegistrationResponse) => {
                setCpeId(deviceId);
                setInitialized(true);
                if (deviceRegistrationResponse.error?.response?.data?.error === DEVICE_LIMIT) {
                    setIsDeviceLimitReached(true);
                } else if (deviceRegistrationResponse.status >= 400) {
                    setDeviceRegistrationError(deviceRegistrationResponse.error);
                }
            });
        } else {
            setIsGuest(userData?.guest);
            setInitialized(true);
        }
    };

    useEffect(() => {
        setPurchasedProductsLoaded(purchasedProducts || purchasedProductsError);
    }, [purchasedProducts, purchasedProductsError]);

    useEffect(() => {
        if (!userInfoDetailsResponse && isLoggedIn) {
            userInfoDetailsFetcher(null);
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (config && !initialized) {
            init(
                authenticated => {
                    if (authenticated) {
                        userInfo(async (userData: any) => {
                            await sso.updateToken();
                            setCpeId(null);

                            setLoggedIn(authenticated);

                            drmInit(userData);
                        });
                    } else {
                        logUserIn();
                    }
                },
                () => logUserOut()
            );
        }
    }, [config]);

    return {
        logUserIn,
        logUserOut,
        setRedirectAfterLogin,
        postLoginAction,
        setPostLoginAction,
        setPostLoginPath,

        isLoggedIn,
        isGuest,
        isDeviceLimitReached,
        deviceRegistrationError,
        authApiInitialized: initialized,
        redirectAfterLogin,
        postLoginPath,
        drmInit,

        loadPurchasedProducts: () => {
            if (!loadingPurchasedProducts) {
                setPurchasedProductsLoaded(false);
                fetchPurchasedProducts(null);
            }
        },
        purchasedProductsLoaded,
        purchasedProducts,
        getReplayWindowTimeFrame,
        hasNPVR,

        guestAlert: isGuestAlert,
        guestAlertClosePath,
        closeGuestAlert: () => {
            setIsGuestAlert(false);
            setGuestAlertClosePath(null);
        },
        openGuestAlert: (onClosePath?: string) => {
            setIsGuestAlert(true);
            setGuestAlertClosePath(onClosePath);
        },

        isLogOutAlert,
        openLogOutAlert: () => setIsLogOutAlert(true),
        closeLogOutAlert: () => setIsLogOutAlert(false),

        cpeId,

        userInfoDetails: userInfoDetailsResponse,
    };
};

export const AuthContext = createContext<ReturnType<typeof useAuthService>>(null);

export const AuthProvider = ({ children }) => {
    const auth = useAuthService();

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export const useGuestAwareClickHandler = () => {
    const { isGuest, openGuestAlert } = useAuth();

    return (event: React.MouseEvent<any>, continueCallback) => {
        if (isGuest) {
            event.preventDefault();
            event.stopPropagation();

            event.bubbles = false;

            openGuestAlert();

            return;
        }

        if (continueCallback) {
            continueCallback(event);
        }
    };
};

export const GuestAlert: FC = () => {
    const { guestAlert, guestAlertClosePath, closeGuestAlert, logUserOut } = useAuth();
    const history = useHistory();

    if (!guestAlert) return null;

    return (
        <AlertDialog
            title={translate('ERR_LOGIN_REQUIRED_HEADER')}
            bodyText={translate('ERR_LOGIN_REQUIRED_BODY')}
            buttons={[
                {
                    text: translate('CONTINUE_AS_GUEST_BUTTON'),
                    onClick: () => {
                        if (guestAlertClosePath) {
                            history.replace(guestAlertClosePath);
                        }

                        closeGuestAlert();
                    },
                },
                {
                    text: translate('SCREEN_LOGIN_BUTTON'),
                    onClick: () => logUserOut(true),
                },
            ]}
        />
    );
};

export const SignOut: FC = () => {
    const { isLogOutAlert, closeLogOutAlert, logUserOut } = useAuth();

    if (!isLogOutAlert) return null;

    return (
        <AlertDialog
            title={translate('SCREEN_SIGNOUT_HEADER')}
            bodyText={translate('SCREEN_SIGNOUT_BODY')}
            buttons={[
                {
                    text: translate('CANCEL_BUTTON'),
                    onClick: () => closeLogOutAlert(),
                },
                {
                    text: translate('SIGNOUT_BUTTON'),
                    onClick: () => logUserOut(true),
                },
            ]}
        />
    );
};

export const DeviceLimitAlert: FC = () => {
    const { isDeviceLimitReached, logUserOut } = useAuth();
    const [isAlert, setIsAlert] = useState(true);

    if (!isDeviceLimitReached) return null;

    return isAlert ? (
        <AlertDialog
            title={translate('SCREEN_DEVICE_MANAGEMENT_LIMIT_REACHED_HEADER')}
            bodyText={translate('SCREEN_DEVICE_MANAGEMENT_LIMIT_REACHED_HINT')}
            buttons={[
                {
                    text: translate('CANCEL_BUTTON'),
                    onClick: () => {
                        logUserOut(true);
                    },
                },
                {
                    text: translate('DEVICE_MANAGEMENT_BUTTON'),
                    onClick: () => {
                        setIsAlert(false);
                    },
                },
            ]}
        />
    ) : (
        createPortal(
            <OverlayPageWrapper>
                <PageDeviceManager />
            </OverlayPageWrapper>,
            document.getElementById('modal-root')
        )
    );
};

export const DeviceRegistrationError: FC = () => {
    const { deviceRegistrationError, logUserOut } = useAuth();

    if (!deviceRegistrationError) return null;

    return (
        <AlertDialog
            title={translate('SCREEN_ERROR_DEVICE_NOT_REGISTERED_TITLE')}
            bodyText={translate('SCREEN_ERROR_DEVICE_NOT_REGISTERED_BODY')}
            buttons={[
                {
                    text: translate('SIGN_IN_BUTTON'),
                    onClick: () => {
                        logUserOut(true);
                    },
                },
            ]}
        />
    );
};
