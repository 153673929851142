import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { PlayingAssetType } from 'types/Player';
import { usePlayer } from 'providers/player/PlayerContext';
import { Broadcast, ProgramDetails, TVSeriesDetailEpisode } from '../../types/Asset';
import { EpgProgram } from '../../types/EpgTypes';
import { useDataFetcher } from '../../hooks/useDataFetcher/useDataFetcher';
import Api from '../../api/Api';
import { PlayOptionsData } from '../../types/CommonTypes';
import { getMiniPlayerProps, getUniquePlayOptions } from '../../utils/fnData';
import { isBroadcast, isProgramDetails, isTvSeriesEpisode } from '../../utils/fnTypeGuards';
import { useAuth } from '../useAuth/AuthContext';

type PlayOptionInput = ProgramDetails | Broadcast | TVSeriesDetailEpisode;

const usePlayOptionsService = () => {
    const [playerPath, setPlayerPath] = useState<string>(null);
    const [input, setInput] = useState<PlayOptionInput>(null);
    const [playOptionsData, setPlayOptionsData] = useState<PlayOptionsData>(null);

    const { pathname } = useLocation();
    const { isMini, updateMiniPlayerProps } = usePlayer();
    const history = useHistory();
    const { getReplayWindowTimeFrame } = useAuth();

    const onNoInternet = () => {
        setPlayOptionsData(input.playOptions);
    };

    const { error, response, loading, fetcher: load, reset: resetLoader } = useDataFetcher<ProgramDetails, string>(
        id => Api.fetchProgramDetails(id),
        onNoInternet
    );

    const openSelector = (inputAsset: PlayOptionInput) => {
        setInput(inputAsset);
    };

    const closeSelector = () => {
        setInput(null);
        setPlayOptionsData(null);
        setPlayerPath(null);
        resetLoader();
    };

    const referenceEventId = () => {
        if (!input) return null;

        if (isProgramDetails(input)) {
            return input.id;
        }

        if (isBroadcast(input)) {
            return input.id;
        }

        if (isTvSeriesEpisode(input)) {
            return input.eventId;
        }

        return null;
    };

    const handleEpgPlayOptions = (program: EpgProgram, playPath: string) => {
        setPlayerPath(playPath);
        load(program.id);
    };

    const optionsHandler = (asset: PlayOptionInput, playPath: string, filter: (asset) => boolean = asset => true) => {
        const filteredPlayOptions = getUniquePlayOptions(asset.playOptions, getReplayWindowTimeFrame(), null).filter(filter);

        if (filteredPlayOptions.length === 0 || filteredPlayOptions.length === 1) {
            if (isMini) {
                updateMiniPlayerProps(getMiniPlayerProps(asset, PlayingAssetType.CATCHUP));
            } else {
                history.push(playPath);
            }
        } else {
            openSelector(asset);
        }
    };

    // close selector when the path is changing so the caller parent gets unmounted
    useEffect(() => closeSelector(), [pathname]);

    useEffect(() => {
        if (input) {
            setPlayOptionsData(input.playOptions);
        }
    }, [input]);

    useEffect(() => {
        if (error || response) {
            if (response && playerPath) {
                optionsHandler(response, playerPath);
            }

            resetLoader();

            if (error && playerPath) {
                history.push(playerPath);
            }
        }
    }, [error, response, playerPath]);

    return {
        openSelector,
        closeSelector,
        referenceEventId,
        handleEpgPlayOptions,
        optionsHandler,
        playOptionsData,
        fetching: loading,
        load,
        response,
    };
};

export const PlayOptionsContext = createContext<ReturnType<typeof usePlayOptionsService>>(null);

export const PlayOptionsProvider = ({ children }) => {
    const service = usePlayOptionsService();
    return <PlayOptionsContext.Provider value={service}>{children}</PlayOptionsContext.Provider>;
};

export const usePlayOptions = () => useContext(PlayOptionsContext);

export const usePlayOptionHelper = () => {
    const { optionsHandler } = usePlayOptions();

    return (asset: PlayOptionInput, playPath: string, filter?: (asset) => boolean) => {
        if (asset) {
            optionsHandler(asset, playPath, filter);
        }
    };
};
