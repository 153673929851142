import { Error } from './fnError';

export class ErrorEmitter {
    static emit(arg1, arg2) {
        let message = '';
        let code = '';

        if (arguments.length === 1) {
            throw new Error('NOT_EXISTING_ERROR_CODE', 'NOT_EXISTING_ERROR_MESSAGE');
        }

        if (arguments.length === 2) {
            code = 'NOT_EXISTING_ERROR_CODE';
            message = 'NOT_EXISTING_ERROR_MESSAGE';
            message += ` ${arg2}`;
            throw new Error(code, message);
        }
    }
}
