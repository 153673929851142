import { Channel } from '../../types/Asset';

export type ChannelsStore = {
    channels?: Channel[];
    error?: boolean;
    loading?: boolean;
};

const initialState: ChannelsStore = {};

export type ChannelsActions = 'STORE_CHANNELS' | 'CHANNELS_LOADING' | 'CHANNELS_LOAD_ERROR' | 'CHANNELS_INTERRUPTED';

export type ChannelAction = {
    type: ChannelsActions;
    payload: Channel[];
};

export const channelsAction = (type: ChannelsActions, payload?: Channel[]): ChannelAction => {
    return {
        type,
        payload,
    };
};

export default (state: ChannelsStore = initialState, action: ChannelAction): ChannelsStore => {
    switch (action.type) {
        case 'STORE_CHANNELS':
            return {
                ...state,
                ...{
                    channels: action.payload,
                    error: null,
                    loading: false,
                },
            };
        case 'CHANNELS_LOADING':
            return {
                ...state,
                ...{
                    channels: state.channels,
                    error: null,
                    loading: true,
                },
            };
        case 'CHANNELS_LOAD_ERROR':
            return {
                ...state,
                ...{
                    channels: null,
                    error: true,
                    loading: false,
                },
            };
        case 'CHANNELS_INTERRUPTED':
            return initialState;
        default:
            return state;
    }
};
