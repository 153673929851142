import translate from './fnTranslate';
import { buildTimeString, DAY_MAP, MOTH_MAP } from './fnDate';
import ApplicationConfig from '../providers/useConfig/ApplicationConfig';
import { MILLISECONDS } from './TimeUnit';

export const alignDate = (date, dayStart): Date => {
    if (date.getHours() < dayStart) {
        date.setDate(date.getDate() - 1);
    }
    date.setHours(dayStart, 0, 0, 0);
    return date;
};

export const getEPGToday = (dayStart = ApplicationConfig.app_config.epg_settings.day_start_hour): Date => {
    const now = new Date();
    return alignDate(now, dayStart);
};

export const isToday = (date, dayStart = ApplicationConfig.app_config.epg_settings.day_start_hour): boolean => {
    let today = new Date();
    today = alignDate(today, dayStart);

    return date.getTime() === today.getTime();
};

export const isTomorrow = (date, dayStart = ApplicationConfig.app_config.epg_settings.day_start_hour): boolean => {
    const today = new Date();
    let tomorrow = new Date();

    tomorrow.setDate(today.getDate() + 1);
    tomorrow = alignDate(tomorrow, dayStart);

    return tomorrow.getTime() === date.getTime();
};

export const isYesterday = (date, dayStart = ApplicationConfig.app_config.epg_settings.day_start_hour): boolean => {
    const today = new Date();
    let yesterday = new Date();

    yesterday.setDate(today.getDate() - 1);
    yesterday = alignDate(yesterday, dayStart);

    return yesterday.getTime() === date.getTime();
};

export const isFutureDate = (date, dayStart = ApplicationConfig.app_config.epg_settings.day_start_hour) => {
    let today = new Date();
    today = alignDate(today, dayStart);

    return date.getTime() > today.getTime();
};

export const formatEpgDate = (date, dayStart = ApplicationConfig.app_config.epg_settings.day_start_hour): string => {
    if (isToday(date, dayStart)) {
        return translate('DAY_NAME_TODAY');
    }
    if (isYesterday(date, dayStart)) {
        return translate('DAY_NAME_YESTERDAY');
    }
    if (isTomorrow(date, dayStart)) {
        return translate('DAY_NAME_TOMORROW');
    }

    const day = date.getDate();
    let formatted = translate(DAY_MAP[date.getDay()]);
    formatted += ', ';
    formatted += day < 10 ? `0${day}` : day;
    formatted += ' ';
    formatted += translate(MOTH_MAP[date.getMonth()]);

    return formatted;
};

export const getHourMinute = (input: number): string => {
    if (input > 9) {
        return `${input}`;
    }

    return `0${input}`;
};

export const getTextWidth = (text: string, fontFamily: string, fontSize: number, paddings: number, fontWeight: string) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = `${fontWeight || 'normal'} ${fontSize}px ${fontFamily}`;
    const metrics = context.measureText(text);

    return Math.ceil(metrics.width + paddings);
};

export const formatPrimeTime = (valueInMillis: number): string => {
    if (valueInMillis == null) {
        return null;
    }
    return buildTimeString(MILLISECONDS.toSeconds(valueInMillis), true, false);
};
