import React, { FC, useEffect, useRef, useState } from 'react';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { ProgressIndicator } from '../ProgressIndicator/ProgressIndicator';
import { isNow } from '../../utils/fnDate';

const UPDATE_INTERVAL_LIVE = 5000;
const UPDATE_INTERVAL_NOT_LIVE = 15000;

type PropTypes = {
    startTime?: number;
    endTime?: number;
    displayWithNullProgress?: boolean;
    onLiveStateChanged?: (boolean) => void;
};

export const LiveProgressIndicatorWrapper = styled.div``;

export const LiveProgressIndicator: FC<PropTypes> = ({
    startTime = -1,
    endTime = -1,
    displayWithNullProgress = false,
    onLiveStateChanged,
}) => {
    const progressUpdateInterval = useRef(0);
    const [progress, setProgress] = useState(0);

    const isLive = isNow(startTime, endTime);

    const getProgressInPercentage = (): number => {
        if (startTime === -1 || endTime === -1) {
            return 0;
        }

        const duration = endTime - startTime;
        const now = Date.now();

        if (now >= startTime && now <= endTime) {
            return (100 / duration) * (now - startTime);
        }

        return 0;
    };

    useEffect(() => {
        setProgress(getProgressInPercentage());

        if (progressUpdateInterval.current) {
            clearInterval(progressUpdateInterval.current);
        }

        progressUpdateInterval.current = window.setInterval(
            () => {
                setProgress(getProgressInPercentage());
            },
            isLive ? UPDATE_INTERVAL_LIVE : UPDATE_INTERVAL_NOT_LIVE
        );

        return () => {
            if (progressUpdateInterval.current) {
                clearInterval(progressUpdateInterval.current);
            }
        };
    }, [startTime, endTime]);

    if (onLiveStateChanged) {
        onLiveStateChanged(isLive);
    }

    if (!isLive) return null;

    if (!displayWithNullProgress && progress < 1) {
        return null;
    }

    return (
        <LiveProgressIndicatorWrapper>
            <ProgressIndicator percentage={progress} displayWithNullProgress={displayWithNullProgress} />
        </LiveProgressIndicatorWrapper>
    );
};
