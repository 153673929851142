import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold, fontNormal, modalZIndex } from '../../style/styled-components/cssGlobalVariables';
import { hexToRgba } from '../../utils/fnColors';
import { Breakpoints, MQ } from '../../style/styled-components/cssMediaQueries';
import Button from '../Buttons/Button/Button';
import { ButtonInner } from '../Buttons/Button/Button.css';
import { ContentMarker, StyledSVG } from '../../style/styled-components/reusable.css';

export const DialogWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: ${modalZIndex};
    background: ${props => props.theme.brandedConfig.black_color};
`;

export const ColorOverlay = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    background: ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.75)};
    top: 0;
    left: 0;
`;

export const BackgroundImage = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: none;

    ${MQ(Breakpoints.s)} {
        display: block;
    }
`;

export const ModalNavigation = styled.div`
    position: fixed;
    height: 48px;
    width: 100%;
    top: 0;
    z-index: 1;
`;

export const ModalCloseButton = styled.div`
    right: 18px;
    top: 14px;
    display: block;
    cursor: pointer;
    position: absolute;
    z-index: 9;

    &:hover {
        opacity: 0.5;
    }

    svg {
        width: 20px;
        height: 20px;
    }

    .SVGInline {
        width: 20px;
        height: 20px;
    }

    ${MQ(Breakpoints.s)} {
        right: 26px;
    }

    ${MQ(Breakpoints.m)} {
        right: 46px;
    }
`;

export const Title = styled.p`
    font-family: ${fontBold};
    font-size: 20px;
    line-height: 24px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    margin-bottom: 8px;
    text-align: left;

    ${MQ(Breakpoints.s)} {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        margin-bottom: 50px;
    }
`;

export const PlayOptionWrapper = styled.div`
    padding: 10px 16px;
    background: ${props => hexToRgba(props.theme.brandedConfig.white_color, 0.1)};
    border-radius: 4px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    height: 76px;
    position: relative;

    ${ContentMarker} {
        position: static;
        margin: 0 6px;
    }

    ${StyledSVG} {
        margin: 0 6px;
    }

    ${MQ(Breakpoints.s)} {
        height: auto;
        padding: 16px 24px;
        border-radius: 8px;
    }
`;

export const Channel = styled.div`
    margin-right: 10px;

    ${MQ(Breakpoints.s)} {
        margin-right: 18px;
    }
`;

export const ChannelLogo = styled.div`
    width: 48px;
    height: 32px;
    display: -webkit-flex;
    -webkit-align-items: center;

    ${MQ(Breakpoints.s)} {
        width: 64px;
        height: 40px;
    }

    img {
        width: 100%;
        height: 100%;
    }
`;

export const Details = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;

    ${MQ(Breakpoints.s)} {
        align-items: center;
        flex-direction: row;
    }
`;

export const IconsAndMarkers = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    ${MQ(Breakpoints.s)} {
        margin-bottom: 0;
    }
`;

export const RightSideIcons = styled.div`
    display: flex;
    position: absolute;
    right: -6px;

    ${StyledSVG} {
        height: 24px;
    }

    ${MQ(Breakpoints.s)} {
        right: 15px;
    }
`;

export const Time = styled.div`
    font-family: ${fontNormal};
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    margin: 0 6px;
`;

export const ChannelLogoFallback = styled.span`
    font-family: ${fontBold};
    color: ${props => props.theme.brandedConfig.high_contrast};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    width: 100%;

    ${MQ(Breakpoints.s)} {
        font-size: 14px;
        line-height: 20px;
    }
`;

export const StyledButton = styled(Button)`
    font-family: ${fontBold};
    margin-left: auto;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;

    ${ButtonInner} {
        padding: 10px 16px;
    }

    ${MQ(Breakpoints.s)} {
        display: block;
        opacity: 1;
        width: auto;
        height: auto;
        position: static;
    }
`;

export const OptionsContent = styled.div`
    padding: 0 16px;
    z-index: 1;
    position: absolute;
    bottom: 0;
    width: 100%;

    ${MQ(Breakpoints.s)} {
        position: relative;
        margin: 104px auto 0 auto;
        width: 610px;
    }
`;
