import { AlertDialog } from 'components/AlertDialog/AlertDialog';
import { format } from 'date-fns';
import { useAuth } from 'providers/useAuth/AuthContext';
import React, { FC, useEffect } from 'react';
import { TIME_FORMAT_HH_MM } from 'utils/fnParser';
import translate from 'utils/fnTranslate';

type maintenanceProps = {
    startTime: number;
    endTime: number;
    hideMaintenance: () => void;
};

export const MaintenanceAlert: FC<maintenanceProps> = ({ startTime, endTime, hideMaintenance }) => {
    const { logUserOut } = useAuth();
    const now = Date.now();

    const startHour = format(startTime, TIME_FORMAT_HH_MM);
    const endHour = format(endTime, TIME_FORMAT_HH_MM);

    useEffect(() => {
        setTimeout(() => {
            logUserOut(true);
            if (hideMaintenance) {
                hideMaintenance();
            }
        }, endTime - now);
    }, []);

    return (
        <AlertDialog
            title={translate('ALERT_MAINTENANCE_TITLE')}
            bodyText={translate('ALERT_MAINTENANCE_BODY')}
            subTitle={`${startHour} - ${endHour}`}
        />
    );
};
