import React, { FC } from 'react';
import { ChannelBundleWrapper, channelCardHeight, channelCardWidth } from './CardChannel.css';
import { LoadingRectangle } from '../../../style/styled-components/reusable.css';
import { CardWrapper } from '../../Lane/Lane.css';

export const CardChanelLoading: FC = () => {
    return (
        <CardWrapper width={channelCardWidth} height={channelCardHeight}>
            <LoadingRectangle />
        </CardWrapper>
    );
};

export const ChannelBundleLoading: FC = () => {
    return (
        <ChannelBundleWrapper>
            <CardChanelLoading key={'line-1'} />
            <CardChanelLoading key={'line-2'} />
        </ChannelBundleWrapper>
    );
};
