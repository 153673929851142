import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import { useHistory } from 'react-router';
import { isIOS } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { Channel } from 'types/Asset';
import { channelsAction } from 'providers/reducers/channel-reducer';
import Api from 'api/Api';
import { getLivePlayerURL } from 'utils/fnUrl';
import {
    ChanelListProgramGridWrapper,
    ChannelFallbackIcon,
    ChannelImageHolder,
    ChannelItemContainer,
    ChannelListContainer,
    ChannelListInnerContainer,
    ChannelText,
    ChannelTitle,
    ChannelTitleWrapper,
    EpgChannelFilter,
    EpgGrid,
    EpgNoResult,
    EpgRowHolder,
    EpgWrapper,
    FavoriteIconWrapper,
    FilterTimeLineWrapper,
    GridContainer,
    GridInner,
    LeftNavigationArrow,
    PastOverlay,
    RightNavigationArrow,
    SearchFilterWrapper,
    TimeSelectorWrapper,
} from './EpgComponent.css';
import { EpgFilterSearch } from '../EpgFilterSearch/EpgFilterSearch';
import { EpgFilter, SelectorOption } from '../EpgFilter/EpgFilter';
import { EpgTimeLine } from '../EpgTimeLine/EpgTimeLine';
import { EpgChannel } from '../../../types/EpgTypes';
import { formatEpgDate, formatPrimeTime, getEPGToday, isToday } from '../../../utils/fnEpg';
import { MemoizedRow } from '../EpgProgramRow/EpgProgramRow';
import { useScreenState } from '../../../hooks/useScreenState/useScreenState';
import { Clear } from '../../../style/styled-components/reusable.css';
import { Picture } from '../../Picture/Picture';
import { getLocalStorage } from '../../../utils/fnStorage';
import { Breakpoints } from '../../../style/styled-components/cssMediaQueries';
import { useGuestAwareClickHandler } from '../../../providers/useAuth/AuthContext';
import { HOURS, MILLISECONDS, SECONDS } from '../../../utils/TimeUnit';
// import { useSubscription } from '../../../providers/useSubscription/SubscriptionContext';
import { useElementInteractionTracking } from '../../../providers/useTracking/TrackingContext';
import { useForceUpdateRef } from '../../../hooks/useForceUpdateRef/useForceUpdateRef';
import { usePlayer } from '../../../providers/player/PlayerContext';
import { LivePlayerAssetInfo, PlayingAssetType } from '../../../types/Player';
import { useEpgStore } from '../../../providers/useEpgStore/EpgContext';
import { EpgLoading } from './EpgLoading';
import { FetchEndCallback } from '../../../types/CommonTypes';
import translate from '../../../utils/fnTranslate';
import { DropDownArrow, DropDownLabelWrapper, SelectedLabel, StyledSVGInline } from '../EpgFilter/EpgFilter.css';
import icons2 from '../../../style';
import { useApp } from '../../../providers/useApp/AppContext';
import { useChannels } from '../../../providers/useChannels/useChannels';

type EpgTexts = {
    epgChannelFilterDialogTitle: string;
    noResultTitle?: string;
    noResultHint?: string;
    searchInputField?: string;
    nowTimeName?: string;
    tonightTimeName?: string;
    tonightTimeNameShort?: string;
    loadingText?: string;
};

type EpgIcons = {
    searchIcon?: any;
    closeIcon?: any;
    arrowDownIcon?: any;
    playIcon?: any;
    playIconHovered?: any;
    recordIcon?: any;
    arrowLeftIcon?: any;
    arrowRightIcon?: any;
    scrubber?: any;
    timeIndicatorIcon?: any;
    primeTimeIndicatorIcon?: any;
    timeshiftIcon?: any;
    tvPlaceholderIcon?: any;
    lockIcon?: any;
    starIcon?: any;
    hdIcon?: any;
    dolbyIcon?: any;
    favoriteIcon?: any;
    favoriteIconEmpty?: any;
    channelFilterIcon?: any;
};

type EpgOptions = {
    rowHeight?: number;
    daysInPast?: number;
    daysInFuture?: number;
    dayStartHour?: number;
    pixelsPerMinute: number;
    checkCatchupAvailability?: boolean;
    useSearch?: boolean;
    useNavigationArrows?: boolean;
    primeTimeValue?: number;
    texts?: EpgTexts;
    icons?: EpgIcons;

    searchQuery?: string;
    channels: EpgChannel[];
    programs: {};
    fetchPrograms: (channels: string[], date: number) => void;
    isLoading: boolean;
    onFetchEnded: FetchEndCallback;
};

type ScrollingElement = 'timeline' | 'grid';

export type WindowIdx = {
    startIdx: number;
    endIdx: number;
    bufferedStart: number;
    bufferedEnd: number;
};

const eventHandlerDebounce = 2;
const resetScrollingItemDelay = 250;
const loadDataTimeout = 250;
const resizeExecTimeout = 250;
const loadingRowsCount = 30;
const heightExtraOffset = 0;
const updateNowLineInterval = SECONDS.toMillis(30);

const defaultVisibleDataLength = 15;

export interface EpgHandler {
    setEpgHeight: () => void;
}

export enum ChannelFilterOptionsValue {
    ALL = 'all',
    ENTITLED = 'subscription',
    FAVORITE = 'favorite',
}

export const EpgComponent = forwardRef<EpgHandler, EpgOptions>(
    (
        {
            useSearch = false,
            rowHeight = 0,
            useNavigationArrows,
            dayStartHour,
            daysInPast,
            daysInFuture,
            pixelsPerMinute,
            primeTimeValue,
            texts,
            icons,
            searchQuery,
            channels = [],
            programs = {},
            fetchPrograms,
            onFetchEnded,
            isLoading,
        },
        ref
    ) => {
        const primeNowScrollLeftOffset = window.innerWidth > 768 ? 350 : 100;
        const primeTimeIndicatorPosition = pixelsPerMinute * (MILLISECONDS.toMinutes(primeTimeValue) - HOURS.toMinutes(dayStartHour));

        const tonight = new Date();
        tonight.setHours(0, 0, 0, 0);
        tonight.setTime(tonight.getTime() + primeTimeValue);

        const [possibleDays, setPossibleDays] = useState<SelectorOption[]>([]);

        const mounted = useRef(false);
        const timeLineScroller = useRef<Scrollbar>(null);
        const scrollStopTimeout = useRef(null);
        const pastOverlayUpdateInterval = useRef(null);
        const resetScrollingItemTimeoutRef = useRef(null);
        const gridReference = useRef<HTMLDivElement>(null);
        const scrollingElement = useRef<ScrollingElement>(null);
        const gridScrollerRef = useRef<Scrollbar>(null);
        const filterSearchWrapper = useRef<HTMLDivElement>(null);
        const timeLineRef = useRef<HTMLDivElement>(null);
        const gridWrapper = useRef<HTMLDivElement>(null);
        const selectedChannelRef = useRef<HTMLAnchorElement>(null);

        const { isDesktop } = useApp();

        const jumpToNowApplied = useRef(false);
        const debounce = useRef(1);
        const resizeTimeout = useRef(null);
        const scrollHandlerTimeout = useRef(null);

        const visibleRowCount = useRef(defaultVisibleDataLength);

        const appliedHorizontalRestore = useRef(false);
        const appliesVerticalRestore = useRef(false);
        const [gridHeight, setGridHeight] = useState(0);

        const { channels: allChannels } = useChannels();

        const [indexes, , setIndexes, forceUpdate] = useForceUpdateRef<WindowIdx>(null);

        const { storeState, obtainState, emptyStates } = useScreenState();
        const history = useHistory();

        const [selectedDate, setSelectedDate] = useState<Date>(obtainState('selectedEpgData')?.value || getEPGToday(dayStartHour));

        const channelFilterOptions = [
            { label: translate('EPG_CHANNELS_FILTER_LINEUP_DEFAULT'), value: ChannelFilterOptionsValue.ALL },
            { label: translate('EPG_CHANNELS_FILTER_LINEUP_ENTITLED'), value: ChannelFilterOptionsValue.ENTITLED },
            { label: translate('EPG_CHANNELS_FILTER_LINEUP_FAVORITE'), value: ChannelFilterOptionsValue.FAVORITE },
        ];

        const [selectedChannelFilter, setSelectedChannelFilter] = useState<SelectorOption>(channelFilterOptions[0]);

        const [pastLength, setPastLength] = useState(
            Math.round((Date.now() - getEPGToday(dayStartHour).getTime()) / 60000) * pixelsPerMinute
        );
        const [showLeftArrow, setShowLeftArrow] = useState(false);
        const [showRightArrow, setShowRightArrow] = useState(false);
        const [isBeginningOfDay, setIsBeginningOfDay] = useState(false);
        const [isEndOfDay, setIsEndOfDay] = useState(false);
        const [isTonight, setIsTonight] = useState(false);

        const guestCheck = useGuestAwareClickHandler();
        // const { initSubscribeFlow } = useSubscription();
        const interactionTracking = useElementInteractionTracking();
        const dispatch = useDispatch();

        const { isEmbedded, isOpen, isMini, updateMiniPlayerProps, asset, loaded } = usePlayer();

        const { filterChannel } = useEpgStore();

        let firstVisibleItemIndex = 0;
        let lastVisibleItemIndex = visibleRowCount.current;

        const isMobile = getLocalStorage('isMobile');

        const setTimeLineScroll = (scrollPosition: number) => {
            if (timeLineScroller.current) {
                timeLineScroller.current.scrollLeft = scrollPosition;
            }
        };

        const getTimeLineScroll = (): number => {
            if (timeLineScroller.current) {
                return timeLineScroller.current.scrollLeft;
            }

            return -1;
        };

        const calculateGridHeight = useCallback(() => {
            const menuElement = document.getElementById('MainMenu');
            const menuElementHeight = menuElement ? menuElement.offsetHeight : 0;

            let calculatedHeight =
                document.documentElement.clientHeight -
                (gridWrapper.current?.getBoundingClientRect().top ?? 0) -
                heightExtraOffset -
                (window.matchMedia(`(min-width: ${Breakpoints.m})`).matches ? 0 : menuElementHeight) -
                window.scrollY;

            if (channels.length * rowHeight < calculatedHeight) {
                calculatedHeight = channels.length * rowHeight;
            }

            return calculatedHeight;
        }, [channels]);

        const requestData = (startIdx, endIdx) => {
            if (channels == null || !channels.length || startIdx == null || endIdx == null) {
                return;
            }

            const programChannels = [];

            if (endIdx > channels.length) {
                endIdx = channels.length;
            }

            if (endIdx > channels.length) {
                endIdx = channels.length;
            }

            for (let i = startIdx; i < endIdx; i += 1) {
                if (channels[i]) {
                    programChannels.push(channels[i].id);
                }
            }

            fetchPrograms(programChannels, MILLISECONDS.toSeconds(selectedDate.getTime()));
        };

        const requestDataInScroll = () => {
            if (channels == null || !channels.length) {
                return;
            }

            const programChannels = [];

            for (let i = firstVisibleItemIndex; i < lastVisibleItemIndex; i += 1) {
                if (channels[i]) {
                    programChannels.push(channels[i].id);
                }
            }

            fetchPrograms(programChannels, MILLISECONDS.toSeconds(selectedDate.getTime()));
        };

        const generatePossibleDays = () => {
            const today = new Date();

            // set day start
            if (today.getHours() < dayStartHour) {
                today.setDate(today.getDate() - 1);
            }
            today.setHours(dayStartHour, 0, 0, 0);

            const days: SelectorOption[] = [];

            for (let i = -daysInPast; i <= daysInFuture; i += 1) {
                const itemDate = new Date(today);
                itemDate.setDate(itemDate.getDate() + i);

                days.push({
                    label: formatEpgDate(new Date(itemDate.getTime()), dayStartHour),
                    value: MILLISECONDS.toSeconds(itemDate.getTime()),
                });

                if (i === 0 && primeTimeValue) {
                    days.push({
                        label: texts.tonightTimeName,
                        value: MILLISECONDS.toSeconds(tonight.getTime()),
                        isTonight: true,
                    });
                }
            }

            setPossibleDays(days);
        };

        const computeIndexes = (scrollPosition): WindowIdx => {
            const extraChannelCountWithData = 5;
            const startEnd: WindowIdx = {
                startIdx: 0,
                endIdx: 0,
                bufferedStart: 0,
                bufferedEnd: 0,
            };

            // calculate first/last visible row index
            firstVisibleItemIndex = scrollPosition < rowHeight ? 0 : Math.round(scrollPosition / rowHeight);
            lastVisibleItemIndex = firstVisibleItemIndex + visibleRowCount.current;

            // align first visible index with data!
            if (firstVisibleItemIndex > extraChannelCountWithData) {
                firstVisibleItemIndex -= extraChannelCountWithData;
            } else {
                firstVisibleItemIndex = 0;
            }

            // align last visible index with data!
            if (lastVisibleItemIndex + extraChannelCountWithData <= channels.length) {
                lastVisibleItemIndex += extraChannelCountWithData;
            } else {
                lastVisibleItemIndex = channels.length;
            }

            startEnd.startIdx = firstVisibleItemIndex;
            startEnd.endIdx = lastVisibleItemIndex;

            const channelIndex = channels.findIndex(currentChannel => currentChannel.id === (asset as LivePlayerAssetInfo)?.channelId);
            const loadingRows = channelIndex !== -1 ? channelIndex : loadingRowsCount;

            startEnd.bufferedStart = startEnd.startIdx - loadingRows;
            if (startEnd.bufferedStart < 0) startEnd.bufferedStart = 0;

            startEnd.bufferedEnd = startEnd.endIdx + loadingRows;
            if (startEnd.bufferedEnd > channels.length) startEnd.bufferedEnd = channels.length;

            if (channels.length < loadingRows) {
                startEnd.startIdx = 0;
                startEnd.bufferedStart = 0;
                startEnd.endIdx = channels.length;
                startEnd.bufferedEnd = channels.length;
            }

            return startEnd;
        };

        const shouldCommitPositionChange = (): boolean => {
            if (debounce.current > eventHandlerDebounce) {
                debounce.current = 1;
            }

            debounce.current += 1;
            return debounce.current === eventHandlerDebounce;
        };

        const onGridScroll = e => {
            const { scrollTop, preventDataLoading } = e;

            if (scrollStopTimeout.current) {
                clearTimeout(scrollStopTimeout.current);
            }

            storeState('epgVerticalScroll', scrollTop);
            const computedIndexes = computeIndexes(scrollTop);

            if (shouldCommitPositionChange()) {
                setIndexes(computedIndexes, false);
            }

            setIndexes(computedIndexes, true);

            if (!preventDataLoading) {
                scrollStopTimeout.current = setTimeout(() => {
                    requestDataInScroll();
                    clearTimeout(scrollStopTimeout.current);
                }, loadDataTimeout);
            }
        };

        const onGridScrollStop = () => {
            if (gridScrollerRef.current) {
                forceUpdate();
            }
        };

        const calculatePastOverlayWidth = () => {
            const dayStart = getEPGToday(dayStartHour);
            setPastLength(Math.round((Date.now() - dayStart.getTime()) / 60000) * 6);
        };

        const handlePastOverlayChanges = () => {
            if (pastOverlayUpdateInterval.current) {
                clearInterval(pastOverlayUpdateInterval.current);
            }

            calculatePastOverlayWidth();

            pastOverlayUpdateInterval.current = setInterval(() => {
                calculatePastOverlayWidth();
                generatePossibleDays();
            }, updateNowLineInterval);
        };

        const runOnScroll = useCallback(
            (scrollPosition: number) => {
                if (possibleDays && possibleDays.length) {
                    const viewportWidth = gridReference.current?.clientWidth ?? 0;
                    const timeLineWidth = gridReference.current?.scrollWidth ?? 0;

                    if (scrollPosition === 0) {
                        setShowLeftArrow(
                            !(
                                MILLISECONDS.toSeconds(selectedDate.getTime()) === possibleDays[0].value &&
                                !(timeLineWidth - (scrollPosition + viewportWidth) <= 2)
                            )
                        );
                        setShowRightArrow(true);
                        setIsEndOfDay(false);
                        setIsBeginningOfDay(true);
                    } else {
                        setShowLeftArrow(true);
                        setIsBeginningOfDay(false);
                        setIsEndOfDay(timeLineWidth - (scrollPosition + viewportWidth) <= 20);
                        setShowRightArrow(
                            !(
                                MILLISECONDS.toSeconds(selectedDate.getTime()) === possibleDays[possibleDays.length - 1].value &&
                                timeLineWidth - (scrollPosition + viewportWidth) <= 20
                            )
                        );
                    }

                    setIsTonight(
                        isToday(selectedDate, dayStartHour) && scrollPosition === primeTimeIndicatorPosition - primeNowScrollLeftOffset
                    );
                }
            },
            [selectedDate, possibleDays]
        );

        const onHorizontalScroll = (scrollPosition: number) => {
            if (resetScrollingItemTimeoutRef.current) {
                clearTimeout(resetScrollingItemTimeoutRef.current);
            }

            clearTimeout(scrollHandlerTimeout.current);
            scrollHandlerTimeout.current = setTimeout(() => {
                runOnScroll(scrollPosition);
            }, 100);

            resetScrollingItemTimeoutRef.current = setTimeout(() => {
                scrollingElement.current = null;
            }, resetScrollingItemDelay);
        };

        const handleHorizontalScroll = (target: string) => {
            if (target === 'grid' && gridReference.current) {
                setTimeLineScroll(gridReference.current.scrollLeft);
                scrollingElement.current = 'grid';
            }

            if (target === 'timeline') {
                gridReference.current.scrollLeft = getTimeLineScroll();
                scrollingElement.current = 'timeline';
            }

            storeState('epgHorizontalScroll', getTimeLineScroll());
            onHorizontalScroll(getTimeLineScroll());
        };

        const gridTimeLineScrollHandler = (toPrevent: string, toNotify: string) => {
            if (scrollingElement.current === toPrevent) {
                return;
            }

            handleHorizontalScroll(toNotify);
        };

        const onTimeLineScrollHandler = () => gridTimeLineScrollHandler('grid', 'timeline');

        const onGridHorizontalScrollHandler = () => gridTimeLineScrollHandler('timeline', 'grid');

        const goToNow = (click = false) => {
            if (click) {
                interactionTracking({
                    'data-track-id': 'epg_grid_now',
                });
            }

            if (!isToday(selectedDate, dayStartHour)) {
                if (click) {
                    const now = getEPGToday(dayStartHour);
                    setSelectedDate(now);
                    storeState('selectedEpgData', now);
                }

                setTimeLineScroll(pastLength - primeNowScrollLeftOffset);
                return;
            }

            setTimeLineScroll(pastLength - primeNowScrollLeftOffset);
        };

        const goToPrimeTime = (click = false) => {
            if (click) {
                interactionTracking({
                    'data-track-id': 'epg_grid_prime_time',
                });
            }

            setTimeLineScroll(primeTimeIndicatorPosition - primeNowScrollLeftOffset);
        };

        const obtainTimeLineScrollerRef = (scroller: Scrollbar) => {
            const horizontalScrollPosition = obtainState('epgHorizontalScroll');
            timeLineScroller.current = scroller;

            if (timeLineScroller.current && !appliedHorizontalRestore.current) {
                if (horizontalScrollPosition && horizontalScrollPosition?.value !== -1) {
                    setTimeLineScroll(horizontalScrollPosition.value);
                }
                appliedHorizontalRestore.current = true;

                // FIXME: dirty dirty dirty hack for https://3screensolutions.atlassian.net/browse/EM-229
                setTimeout(() => {
                    if (getTimeLineScroll() === 0) {
                        setTimeLineScroll(1);
                        setTimeout(() => {
                            setTimeLineScroll(0);
                        }, 50);
                    }
                }, 50);
            }

            if (!jumpToNowApplied.current && pastLength && timeLineScroller.current && (horizontalScrollPosition?.value ?? -1) < 0) {
                goToNow();
                jumpToNowApplied.current = true;
            }
        };

        const obtainGridReference = ref => {
            if (gridScrollerRef.current === null && ref !== null) {
                gridScrollerRef.current = ref;

                if (!appliesVerticalRestore.current) {
                    gridScrollerRef.current.scrollTop = obtainState('epgVerticalScroll')?.value ?? 0;
                    appliesVerticalRestore.current = true;
                }
            }
        };

        const onDayNavigationClicked = (day: number, direction: number) => {
            const nextDate = new Date(SECONDS.toMillis(day));
            nextDate.setHours(dayStartHour, 0, 0, 0);

            setSelectedDate(nextDate);
            storeState('selectedEpgData', nextDate);

            if (isToday(nextDate, dayStartHour)) {
                goToNow();
            } else if (timeLineScroller.current) {
                appliedHorizontalRestore.current = true;

                if (direction > 0) {
                    setTimeLineScroll(0);
                } else {
                    setTimeLineScroll(1000000);
                }
            }
        };

        const goToNextDay = dir => {
            if (dir > 0) {
                const nextDay = new Date(selectedDate.getTime());
                nextDay.setDate(nextDay.getDate() + 1);
                onDayNavigationClicked(MILLISECONDS.toSeconds(nextDay.getTime()), 1);
            } else {
                const nextDay = new Date(selectedDate.getTime());
                nextDay.setDate(nextDay.getDate() - 1);
                onDayNavigationClicked(MILLISECONDS.toSeconds(nextDay.getTime()), -1);
            }
        };

        const onArrowClicked = (dir: number) => {
            if (dir > 0) {
                if (!isEndOfDay) {
                    const timeLine = timeLineScroller.current;
                    if (timeLine) {
                        setTimeLineScroll(timeLine.scrollLeft + gridReference.current.clientWidth);
                    }
                } else {
                    const nextDay = new Date(selectedDate.getTime());
                    nextDay.setDate(nextDay.getDate() + 1);

                    onDayNavigationClicked(MILLISECONDS.toSeconds(nextDay.getTime()), 1);
                }
            } else if (!isBeginningOfDay) {
                const timeLine = timeLineScroller.current;
                if (timeLine) {
                    setTimeLineScroll(timeLine.scrollLeft - gridReference.current.clientWidth);
                }
            } else {
                const nextDay = new Date(selectedDate.getTime());
                nextDay.setDate(nextDay.getDate() - 1);

                onDayNavigationClicked(MILLISECONDS.toSeconds(nextDay.getTime()), -1);
            }
        };
        const onLeftArrowClicked = () => {
            interactionTracking({
                'data-track-id': 'epg_grid_left',
            });
            onArrowClicked(-1);
        };
        const onRightArrowClicked = () => {
            interactionTracking({
                'data-track-id': 'epg_grid_right',
            });
            onArrowClicked(1);
        };

        const onSearch = () => {
            storeState('epgVerticalScroll', -1);
            storeState('epgHorizontalScroll', -1);
        };

        const onDaySelected = (selected: SelectorOption) => {
            const selectionAsDate = new Date(SECONDS.toMillis(selected.value));

            if (selected.isTonight) {
                if (!isToday(selectedDate, dayStartHour)) {
                    const now = getEPGToday(dayStartHour);
                    setSelectedDate(now);
                    storeState('selectedEpgData', now);

                    setTimeLineScroll(primeTimeIndicatorPosition - primeNowScrollLeftOffset);
                } else {
                    setTimeLineScroll(primeTimeIndicatorPosition - primeNowScrollLeftOffset);
                }
            } else {
                setSelectedDate(selectionAsDate);
                storeState('selectedEpgData', selectionAsDate);

                if (selectionAsDate && isToday(selectionAsDate)) {
                    goToNow();
                }
            }
        };

        const onChannelFilterSelected = (selected: SelectorOption) => {
            emptyStates();
            if (gridScrollerRef?.current) {
                gridScrollerRef.current.scrollTop = 0;
            }

            if (selectedChannelFilter.value !== selected.value) {
                filterChannel(selected);
                setSelectedChannelFilter(selected);
            }
        };

        const renderNavigationArrows = () => {
            return useNavigationArrows ? (
                <>
                    {showLeftArrow && (
                        <LeftNavigationArrow onClick={onLeftArrowClicked}>{icons?.arrowLeftIcon ?? null}</LeftNavigationArrow>
                    )}
                    {showRightArrow && (
                        <RightNavigationArrow data-test-id={'tvguide-right-navigation-arrow'} onClick={onRightArrowClicked}>
                            {icons?.arrowRightIcon ?? null}
                        </RightNavigationArrow>
                    )}
                </>
            ) : null;
        };

        const handleFavoriteIconClicked = async (event: React.MouseEvent<HTMLDivElement>, channel: Channel, index: number) => {
            event.preventDefault();
            event.stopPropagation();

            const action = channel.favorite ? 'REMOVE' : 'ADD';
            const response = await Api.channelFavorite(action, channel.id);

            if (response.response) {
                const updatedChannels = channels as Channel[];
                updatedChannels[index].favorite = !updatedChannels[index].favorite;
                dispatch(channelsAction('STORE_CHANNELS', updatedChannels));
            }
        };

        const onChannelLogoClicked = (channelId: string) => {
            history.push(getLivePlayerURL(channelId));
        };

        const renderChannelList = () => {
            const channelItems = [];
            const { bufferedStart, bufferedEnd } = indexes ?? {};
            const chCount = bufferedEnd > channels.length ? channels.length : bufferedEnd;

            for (let i = bufferedStart; i < chCount; i += 1) {
                const channel = channels[i];

                channelItems.push(
                    <ChannelItemContainer
                        data-test-id={'tv-tvguide-channels-column-item'}
                        key={`channel-${i}`}
                        rowHeight={rowHeight}
                        isMobile={isMobile}
                        isSelected={(asset as LivePlayerAssetInfo)?.channelId === channel?.id}
                        ref={(asset as LivePlayerAssetInfo)?.channelId === channel?.id ? selectedChannelRef : null}
                        onClick={event => {
                            interactionTracking({
                                'data-track-id': 'epg_channel_item',
                                'data-channel-id': channel.id,
                            });

                            guestCheck(event, () => {
                                if (isMini) {
                                    updateMiniPlayerProps({
                                        params: {
                                            channelId: channel.id,
                                        },
                                        type: PlayingAssetType.LIVE,
                                    });
                                } else {
                                    onChannelLogoClicked(channel.id);
                                }
                            });
                        }}
                    >
                        {channel && !channel.logo && !channel.name && <ChannelFallbackIcon>{icons.tvPlaceholderIcon}</ChannelFallbackIcon>}
                        {channel && (
                            <ChannelImageHolder data-test-id={'tv-tvguide-channels-column-item-logo'}>
                                <Picture src={channel.logo} hPosition={'left'} fit={'scale-down'}>
                                    <>
                                        {channel && !channel.name && <ChannelFallbackIcon>{icons.tvPlaceholderIcon}</ChannelFallbackIcon>}
                                        {channel && channel.name && (
                                            <ChannelTitleWrapper>
                                                <ChannelTitle>{channel.name}</ChannelTitle>
                                            </ChannelTitleWrapper>
                                        )}
                                    </>
                                </Picture>
                            </ChannelImageHolder>
                        )}
                        <ChannelText data-test-id={'tv-tvguide-channels-column-item-number'}>
                            {!channel.subscription && !channel.favorite && icons.lockIcon}
                            {(channel.subscription || channel.favorite) && (
                                <>
                                    <FavoriteIconWrapper onClick={e => handleFavoriteIconClicked(e, channel as Channel, i)}>
                                        {channel.favorite ? icons.favoriteIcon : icons.favoriteIconEmpty}
                                    </FavoriteIconWrapper>
                                    {channel.number}
                                </>
                            )}
                        </ChannelText>
                    </ChannelItemContainer>
                );
            }

            return (
                <ChannelListContainer data-test-id={'tv-tvguide-channels-column'} height={channels.length * rowHeight}>
                    <ChannelListInnerContainer top={bufferedStart * rowHeight}>{channelItems}</ChannelListInnerContainer>
                </ChannelListContainer>
            );
        };

        const renderGrid = () => {
            const programRowItems = [];
            const { startIdx, endIdx, bufferedStart, bufferedEnd } = indexes ?? {};

            // at one point bufferedEnd has a wrong value
            for (let i = bufferedStart; i < bufferedEnd; i += 1) {
                if (channels[i]) {
                    programRowItems.push(
                        <MemoizedRow
                            key={`program-row-${channels[i].id}`}
                            dataIndex={i}
                            channels={channels}
                            programs={programs}
                            selectedDate={selectedDate}
                            loadingText={texts.loadingText}
                            rowHeight={rowHeight}
                            grid={gridReference.current}
                            pixelsPerMinute={pixelsPerMinute}
                            tvPlaceholderIcon={icons.tvPlaceholderIcon}
                            playIcon={icons.playIcon}
                            playIconHovered={icons.playIconHovered}
                            pastLength={pastLength}
                            isMobile={isMobile}
                            forceEmpty={!(i >= startIdx && i <= endIdx)}
                        />
                    );
                }
            }

            return (
                <GridContainer height={channels.length * rowHeight}>
                    <GridInner ref={gridReference} onScroll={onGridHorizontalScrollHandler}>
                        <EpgGrid pixelsPerMinute={pixelsPerMinute}>
                            <EpgRowHolder top={bufferedStart * rowHeight}>
                                {programRowItems}
                                {isToday(selectedDate, dayStartHour) ? <PastOverlay width={pastLength} /> : null}
                            </EpgRowHolder>
                        </EpgGrid>
                    </GridInner>
                </GridContainer>
            );
        };

        const resizeHandler = () => {
            if (!mounted.current) {
                return;
            }

            clearTimeout(resizeTimeout.current);
            resizeTimeout.current = setTimeout(() => {
                setGridHeight(calculateGridHeight());
            }, resizeExecTimeout);
        };

        const onStart = () => {
            mounted.current = true;
            handlePastOverlayChanges();
            generatePossibleDays();
        };

        const onRelease = () => {
            clearInterval(pastOverlayUpdateInterval.current);
            clearTimeout(scrollStopTimeout.current);
            clearTimeout(resizeTimeout.current);
            clearTimeout(scrollHandlerTimeout.current);

            mounted.current = false;
        };

        const getChannelFilters = (arr: Channel[]) => {
            if (arr && arr.length !== 0) {
                return channelFilterOptions.filter(
                    filter =>
                        arr.filter(channel => {
                            return channel[filter.value];
                        }).length !== 0 || filter.value === ChannelFilterOptionsValue.ALL
                );
            }
        };

        useImperativeHandle(
            ref,
            () => ({
                setEpgHeight: () => {
                    setGridHeight(calculateGridHeight());
                },
            }),
            [calculateGridHeight]
        );

        useEffect(() => {
            onStart();
            return () => {
                filterChannel(channelFilterOptions[0]);
                onRelease();
            };
        }, []);

        useEffect(() => {
            const horizontalScroll = obtainState('epgHorizontalScroll');

            if (horizontalScroll && horizontalScroll.value === -1 && pastLength && timeLineScroller.current) {
                goToNow();
                jumpToNowApplied.current = true;
            }

            if (channels && channels.length === 0) {
                onChannelFilterSelected(channelFilterOptions[0]);
            }
        }, [channels]);

        useEffect(() => {
            // when the channel count or the search query changes should re-attach the resize handler otherwise the states
            // are stuck
            window.removeEventListener('resize', resizeHandler);
            window.addEventListener('resize', resizeHandler);

            if (searchQuery) {
                const verticalScroll = obtainState('epgVerticalScroll');

                if (verticalScroll && verticalScroll.value <= 0) {
                    setTimeout(() => {
                        debounce.current = 0;
                        gridScrollerRef.current.scrollTop = 0;

                        onGridScroll({ scrollTop: 0, preventDataLoading: true });

                        setIndexes(computeIndexes(0), true);
                        onGridScrollStop();
                    }, 50);
                }
            }
        }, [searchQuery]);

        useEffect(() => {
            setTimeout(() => {
                const scrollTo = selectedChannelRef.current?.offsetTop;

                if (scrollTo) {
                    gridScrollerRef.current?.scrollerElement?.scrollTo({
                        behavior: 'smooth',
                        top: scrollTo,
                    });
                }
            }, 1000);
        }, [loaded]);

        useEffect(() => {
            if (channels && isEmbedded) {
                setGridHeight(calculateGridHeight());
            }
            window.addEventListener('resize', resizeHandler);
            return () => {
                window.removeEventListener('resize', resizeHandler);
            };
        }, [channels, isEmbedded, isOpen]);

        useEffect(() => {
            requestData(indexes?.startIdx, indexes?.endIdx);
            runOnScroll(gridReference.current?.scrollLeft ?? 0);
        }, [selectedDate]);

        useEffect(() => {
            const visibleNr = Math.ceil(gridHeight / rowHeight);

            if (visibleNr > 0) {
                visibleRowCount.current = visibleNr;
                const tIndexes = computeIndexes(gridScrollerRef?.current?.scrollTop ?? 0);

                setIndexes(tIndexes);
                requestData(tIndexes.startIdx, tIndexes.endIdx);
            }
        }, [gridHeight, rowHeight]);

        useEffect(() => {
            if (!isLoading) {
                if (onFetchEnded) {
                    onFetchEnded(channels.length, 'EPG');
                }
            }
        }, [isLoading]);

        const selectedItemIndex = channelFilterOptions.findIndex(option => option.value === selectedChannelFilter.value);

        const selectedDayItemIndex = possibleDays.findIndex(
            option => option.value === MILLISECONDS.toSeconds((isTonight ? tonight : selectedDate).getTime())
        );

        return isLoading ? (
            <EpgLoading isIOS={isIOS} rowHeight={rowHeight} isMobile={isMobile} />
        ) : (
            <EpgWrapper isIOS={isIOS}>
                {useSearch && (
                    <SearchFilterWrapper data-test-id={'tv-tvguide-searchbar'} ref={filterSearchWrapper}>
                        <EpgFilterSearch placeholder={texts.searchInputField} onSearch={onSearch} />
                    </SearchFilterWrapper>
                )}
                <EpgChannelFilter>
                    <EpgFilter
                        filterItems={getChannelFilters(allChannels)}
                        selectedItemIndex={selectedItemIndex}
                        title={texts.epgChannelFilterDialogTitle}
                        onSelected={onChannelFilterSelected}
                        dataTrackId={'dropdown_channel_filter_selector'}
                        triggerButton={
                            <DropDownLabelWrapper>
                                {isDesktop() ? (
                                    <SelectedLabel data-test-id={'tv-tvguide-currentdate'}>
                                        {selectedItemIndex != null && channelFilterOptions[selectedItemIndex]?.label}
                                        <DropDownArrow data-test-id={'tv-tvguide-dropdownarrow'}>
                                            <StyledSVGInline svg={icons2.bottomArrowSmallIcon} />
                                        </DropDownArrow>
                                    </SelectedLabel>
                                ) : (
                                    icons.channelFilterIcon
                                )}
                            </DropDownLabelWrapper>
                        }
                    />
                </EpgChannelFilter>
                {channels.length > 0 && (
                    <FilterTimeLineWrapper ref={timeLineRef}>
                        <TimeSelectorWrapper>
                            {possibleDays && possibleDays.length > 0 && (
                                <EpgFilter
                                    filterItems={possibleDays}
                                    selectedItemIndex={selectedDayItemIndex}
                                    title={translate('EPG_DATE_PICKER_DIALOG_TITLE')}
                                    onSelected={onDaySelected}
                                    dataTrackId={'dropdown_time_selector'}
                                    triggerButton={
                                        <DropDownLabelWrapper>
                                            <SelectedLabel data-test-id={'tv-tvguide-currentdate'}>
                                                {selectedDayItemIndex != null && possibleDays[selectedDayItemIndex]?.isTonight
                                                    ? texts.tonightTimeNameShort
                                                    : possibleDays[selectedDayItemIndex]?.label}
                                                <DropDownArrow data-test-id={'tv-tvguide-dropdownarrow'}>
                                                    <StyledSVGInline svg={icons2.bottomArrowSmallIcon} />
                                                </DropDownArrow>
                                            </SelectedLabel>
                                        </DropDownLabelWrapper>
                                    }
                                />
                            )}
                            <EpgTimeLine
                                pastLength={pastLength}
                                primeTimeIndicatorPosition={primeTimeIndicatorPosition}
                                primeTimeText={formatPrimeTime(primeTimeValue)}
                                selectedDate={selectedDate}
                                possibleDates={possibleDays}
                                dayStartHour={dayStartHour}
                                timeIndicatorIcon={icons.timeIndicatorIcon}
                                nowTimeText={texts.nowTimeName}
                                pixelsPerMinute={pixelsPerMinute}
                                scrubber={icons.scrubber}
                                onTimeLineScroll={onTimeLineScrollHandler}
                                obtainReference={obtainTimeLineScrollerRef}
                                jumpToNow={() => {
                                    goToNow(true);
                                }}
                                jumpToPrimeTime={() => {
                                    goToPrimeTime(true);
                                }}
                                jumpToNextDay={dir => {
                                    goToNextDay(dir);
                                }}
                            />
                            <Clear />
                        </TimeSelectorWrapper>
                        <Clear />
                    </FilterTimeLineWrapper>
                )}
                {channels.length > 0 && (
                    <ChanelListProgramGridWrapper data-test-id={'tv-tvguide-programgrid'} ref={gridWrapper} height={gridHeight}>
                        <Scrollbar onScroll={onGridScroll} onScrollStop={onGridScrollStop} ref={obtainGridReference}>
                            {renderChannelList()}
                            {renderGrid()}
                            <Clear />
                        </Scrollbar>
                        {renderNavigationArrows()}
                    </ChanelListProgramGridWrapper>
                )}
                {!channels.length && searchQuery && (
                    <EpgNoResult data-test-id={'tv-tvguide-noresultsfoundmessage'}>{texts.noResultHint}</EpgNoResult>
                )}
            </EpgWrapper>
        );
    }
);
