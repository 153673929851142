import { css } from 'styled-components';
import SVGInline from 'react-svg-inline';
import { DropdownItemSimpleWrapper } from 'components/DropdownMenu/DropdownMenu.css';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { Breakpoints, MQ, MQ_NOT_TOUCH } from '../../style/styled-components/cssMediaQueries';
import { fontBold, fontNormal, headerZIndex } from '../../style/styled-components/cssGlobalVariables';
import { hexToRgba } from '../../utils/fnColors';
import { ellipsis } from '../../style/styled-components/reusable.css';
import { MainMenu } from './MainMenu';

export const Logo = styled.a<{ imageSrc: string }>`
    display: block;
    cursor: pointer;
    float: left;
    background-image: url(${props => props.imageSrc});
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    transition: all 0.2s ease;
    justify-self: center;
    width: 37px;
    height: 32px;
    margin: 0;
`;

export const StyledSVGInline = styled(SVGInline)`
    height: 15px;
    padding-right: 14px;

    svg {
        height: 100%;
        width: auto;
    }
`;

export const GlobalNavPageTitle = styled.div`
    ${ellipsis(1)};
    font-size: 20px;
    line-height: 24px;
`;

export const GlobalBackNavigation = styled.div`
    display: flex;
    font-family: ${fontBold};
    color: ${props => props.theme.brandedConfig.high_contrast};
    cursor: pointer;
    align-items: center;

    ${MQ(Breakpoints.m)} {
        width: 100%;
        padding-top: 16px;
    }
`;

export const HeaderWrapper = styled.header<{ isSticky: boolean; hideOnMobile: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: auto;
    z-index: ${headerZIndex};
    transition: all 0.2s ease-in;
    opacity: 1;
    padding: 12px 16px;
    min-height: 48px;
    background-color: ${props => props.theme.brandedConfig.background};
    border-bottom: 1px solid ${props => (props.isSticky ? hexToRgba(props.theme.brandedConfig.white_color, 0.1) : 'transparent')};

    ${MQ(Breakpoints.s)} {
        padding: ${props => (props.hideOnMobile ? '0' : '12px 24px')};
    }

    ${MQ(Breakpoints.m)} {
        padding: 8px 40px;
        background-color: ${props => (props.isSticky ? props.theme.brandedConfig.background : 'transparent')};
    }

    &::before {
        height: 100px;
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0.9;
        z-index: -1;
        background: linear-gradient(
            0deg,
            ${props => props.theme.brandedConfig.background} 0%,
            ${props => hexToRgba(props.theme.brandedConfig.background, 0)} 100%
        );
        transform: rotate(-180deg);
        transition: opacity 0.3s ease-out;
        pointer-events: none;
    }

    &.with-back-navigation {
        ${Logo} {
            display: none;
        }

        ${MQ(Breakpoints.m)} {
            ${Logo} {
                display: block;
            }
        }
    }

    ${props =>
        props.hideOnMobile
            ? css`
                  height: 0;
                  padding: 0;
                  min-height: 0;

                  &:before {
                      display: none;
                  }

                  & > * {
                      display: none;
                  }

                  ${MainMenu} {
                      display: block;
                  }

                  ${MQ(Breakpoints.m)} {
                      height: auto;

                      & > * {
                          display: block;
                      }

                      &:before {
                          display: block;
                      }
                  }
              `
            : ``}
`;

export const HeaderButtonsWrapper = styled.div<{ hideOnMobile: boolean }>`
    display: flex;
    align-items: center;
    float: right;
    gap: 24px;
    flex-direction: row-reverse;

    ${props =>
        props.hideOnMobile
            ? css`
                  display: none;

                  ${MQ(Breakpoints.m)} {
                      display: flex;
                  }
              `
            : ``}
`;

export const LanguageDropdownButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    height: 40px;
    width: 40px;
    cursor: pointer;

    ${MQ_NOT_TOUCH} {
        &:hover {
            opacity: 0.6;
        }
    }
`;

export const LoginButton = styled.div`
    cursor: pointer;

    ${MQ_NOT_TOUCH} {
        &:hover {
            opacity: 0.6;
        }
    }
`;

export const LanguageDropdownWrapper = styled.div`
    ${DropdownItemSimpleWrapper} {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        .colored {
            position: relative;
            top: 0;
            transform: none;
            right: 0;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            overflow: hidden;

            svg {
                position: relative;
                left: -50%;
                width: auto;
                height: 100%;
            }
        }
    }
`;

export const HeaderCountWrapper = styled(GlobalNavPageTitle)`
    padding-left: 8px;
    color: ${props => hexToRgba(props.theme.brandedConfig.white_color, 0.7)};
    font-family: ${fontNormal};
`;
