import { css } from 'styled-components';
import SVGInline from 'react-svg-inline';
import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { hexToRgba } from '../../../utils/fnColors';

export const FilterWrapper = styled.div`
    position: relative;
    height: 100%;
`;

export const IconSearch = styled(SVGInline)`
    position: absolute;
    right: 0;
    margin: 0 14px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    transition: all 0.2s ease-in-out 0.2s;

    svg {
        path {
            fill: ${props => props.theme.brandedConfig.high_contrast};
        }
    }
`;

export const IconSearchDelete = styled(SVGInline)`
    position: absolute;
    width: 16px;
    height: 16px;
    opacity: 0;
    cursor: pointer;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.2s ease-in-out 0.2s;

    svg {
        path {
            fill: ${props => props.theme.brandedConfig.high_contrast};
        }
    }
`;

export const FilterInput = styled.input<{ isEmpty: boolean }>`
    display: block;
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    color: ${props => props.theme.brandedConfig.high_contrast};
    padding: 4px 44px 4px 12px;
    line-height: 24px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid ${props => hexToRgba(props.theme.brandedConfig.high_contrast, 0.2)};
    font-family: inherit;
    transition: padding-left 0.2s ease-in-out 0.2s;
    position: relative;
    z-index: 1;
    padding-left: 45px;

    ~ ${IconSearch} {
        right: calc(100% - 48px);
    }

    &::-webkit-input-placeholder {
        color: ${props => props.theme.brandedConfig.high_contrast};
        opacity: 0.7;
    }

    &:-ms-input-placeholder {
        color: ${props => props.theme.brandedConfig.high_contrast};
        opacity: 0.7;
    }

    &::-moz-placeholder {
        color: ${props => props.theme.brandedConfig.high_contrast};
        opacity: 0.7;
    }

    ::placeholder {
        color: ${props => props.theme.brandedConfig.high_contrast};
        opacity: 0.7;
    }

    &:focus,
    &:active {
        outline: none;
    }

    &:focus ~ ${IconSearchDelete} {
        opacity: 1;
        z-index: 2;
    }

    ${props =>
        !props.isEmpty
            ? css`
                  ${IconSearchDelete} {
                      opacity: 1;
                      z-index: 2;
                  }
              `
            : ``}
`;
