import React from 'react';
import icons from '../../../style';
import Button from '../../Buttons/Button/Button';
import {
    ErrorButton,
    ErrorButtonContainer,
    ErrorContainer,
    ErrorIcon,
    ErrorIconSVG,
    ErrorMessage,
    ErrorPage,
    ErrorTitle,
    TitleContainer,
} from './PageErrorNotFound.css';
import translate from '../../../utils/fnTranslate';
import { HelmetWrapper } from '../../HelmetWrapper/HelmetWrapper';
import { getHelmetData } from '../../../utils/fnHelmet';

export const PageErrorNotFound: React.FC = () => {
    return (
        <HelmetWrapper {...getHelmetData('404', 'none')}>
            <ErrorPage>
                <ErrorContainer>
                    <ErrorIcon>
                        <ErrorIconSVG data-test-id={'alertsandnotifications-pagenotfound-404image'} svg={icons.icon404} />
                    </ErrorIcon>
                    <TitleContainer>
                        <ErrorTitle data-test-id={'alertsandnotifications-pagenotfound-404errortitle'}>
                            {translate('ERR_PAGE_NOT_FOUND_HEADER')}
                        </ErrorTitle>
                    </TitleContainer>
                    <ErrorMessage data-test-id={'alertsandnotifications-pagenotfound-404errormessage'}>
                        {translate('ERR_PAGE_NOT_FOUND_BODY')}
                    </ErrorMessage>

                    <ErrorButtonContainer>
                        <ErrorButton>
                            <Button
                                data-test-id={'alertsandnotifications-pagenotfound-homebutton'}
                                label={translate('ERR_PAGE_NOT_FOUND_BUTTON')}
                                isPrimary={true}
                                link={'/'}
                            />
                        </ErrorButton>
                    </ErrorButtonContainer>
                </ErrorContainer>
            </ErrorPage>
        </HelmetWrapper>
    );
};

export default PageErrorNotFound;
