import axios, { AxiosError } from 'axios';
import { ErrorEmitter } from './ErrorEmitter';

export const generateError = (msg: string, code: string = 'GEN', emit = true): void => {
    if (!emit) {
        console.error(code, `Generated Error: ${msg}`);
        return;
    }
    ErrorEmitter.emit(code, `Generated Error: ${msg}`);
};

export class Error {
    code: string = null;

    message: string = null;

    constructor(code: string, message: string) {
        this.code = code;
        this.message = message;
    }
}

export const generateErrorNoEmit = (msg: string, code: string = 'GEN') => {
    return generateError(msg, code, false);
};

export const captureException = (exception: AxiosError | Error) => {
    const SENTRY_EXCLUDED_ERROR_CODES = [400, 401, 403, 404];

    const AXIOS_NETWORK_ERROR_MESSAGE = 'Network Error';

    if (axios.isAxiosError(exception)) {
        if (axios.isCancel(exception)) {
            return false;
        }

        if (SENTRY_EXCLUDED_ERROR_CODES.includes(exception?.response?.status)) {
            return false;
        }

        if (exception.message === AXIOS_NETWORK_ERROR_MESSAGE) {
            return false;
        }
    }

    return true;
};
