import React, { FC, useEffect, useRef } from 'react';
import axios from 'axios';
import { ControlGroup, PlayerWrapper } from '../../Player/PlayerWrapper';
import { LivePlayerAssetInfo, PlayerControl, PlayerEvents, PlayingMode } from '../../../types/Player';
import { usePlayer } from '../../../providers/player/PlayerContext';
import { useDataFetcher, useRequestCanceller } from '../../../hooks/useDataFetcher/useDataFetcher';
import Api from '../../../api/Api';
import { PageErrorNotFound } from '../PageErrorNotFound/PageErrorNotFound';
import { getLocalStorage } from '../../../utils/fnStorage';
import translate from '../../../utils/fnTranslate';
import { MiniEpg } from '../../MiniEpg/MiniEpg';
import { useConfig } from '../../../providers/useConfig/ConfigContext';
import { useKPI, useTrackingScreen } from '../../../providers/useTracking/TrackingContext';
import { useAuth } from '../../../providers/useAuth/AuthContext';
import { FetchPlayingAsset } from '../../../types/ApiTypes';
import { useGlobalNetworkError, useGlobalNoInternet, withNetworkCheck } from '../../../hooks/withNetworkCheck/withNetworkCheck';
import { HelmetWrapper } from '../../HelmetWrapper/HelmetWrapper';
import { getPlayerPageHelmetData } from '../../../utils/fnHelmet';
import { useSubscription, useSubscriptionChangedObserver } from '../../../providers/useSubscription/SubscriptionContext';
import { withPlayer } from '../../../hooks/withPlayer/withPlayer';
import { usePlayerEntitlementChecker } from '../../../hooks/useEntitlementChecker/usePlayerEntitlementChecker';
import { useRecording } from '../../../providers/useRecording/RecordingContext';
import { RecordingEvent } from '../../../types/RecordingTypes';
import { useChannels } from '../../../providers/useChannels/useChannels';
import CastHandler from '../../../providers/cast/CastHandler';

export const LivePlayerPage: FC<{ channelId: string; isEmbedded: boolean }> = ({ channelId, isEmbedded }) => {
    const isMobile = getLocalStorage('isMobile');

    const { isGuest, openGuestAlert } = useAuth();

    const { config } = useConfig();
    const trackScreen = useTrackingScreen(() => true, [channelId]);
    const { fullLoad } = useKPI('LivePlayer');

    const channelProductCodeRef = useRef<string>(null);
    const { initSubscribeFlow } = useSubscription();

    const { onNoInternet } = useGlobalNoInternet();
    const { onNetworkError } = useGlobalNetworkError();
    const setCanceller = useRequestCanceller();
    const { addEventListener: addRecordingEventListener, removeEventListener: removeRecordingEventListener } = useRecording();

    const { response, error, loading, fetcher } = useDataFetcher<LivePlayerAssetInfo, FetchPlayingAsset>(
        params => Api.fetchLivePlayerInfo(params.id, params.createSession, new axios.CancelToken(executor => setCanceller(executor))),
        onNoInternet,
        onNetworkError
    );

    const sessionCreated = useRef<boolean>(false);
    const reFetchTimeoutRef = useRef(null);
    const previousChannelId = useRef<string | null>(null);

    const { channels, fetchChannels } = useChannels();

    const {
        setAsset,
        asset: currentPlayerAsset,
        addEventListener,
        removeEventListener,
        resetPlayerAsset,
        setActiveControlGroup,
        setPlayerError,
        playingMode,
        activeControlGroup,
        loaded,
    } = usePlayer();

    const fetchDetails = () => {
        if (!isGuest) {
            fetcher(
                {
                    id: channelId,
                    createSession: !sessionCreated.current,
                },
                true
            );
        }
    };

    const onStreamEndReached = () => {
        sessionCreated.current = false;
        resetPlayerAsset();
        fetchDetails();
    };

    const onSubscriptionSuccessful = () => {
        fetchDetails();
    };

    const subscribeClickListener = e => {
        e.preventDefault();
        e.stopPropagation();

        initSubscribeFlow(
            null,
            {
                code: channelProductCodeRef.current,
                type: 'Channel',
            },
            {
                onSuccess: onSubscriptionSuccessful,
            }
        );
    };

    const setRecorded = () => {
        setAsset({
            ...currentPlayerAsset,
            isRecorded: true,
        });
    };

    const setNotRecorded = () => {
        setAsset({
            ...currentPlayerAsset,
            isRecorded: false,
        });
    };

    usePlayerEntitlementChecker(response, {
        onNotEntitled: () => {
            setPlayerError({
                message: translate(isMobile ? 'UNSUBSCRIBED_PLAYER_INFO_TEXT_MOBILE' : 'UNSUBSCRIBED_PLAYER_INFO_TEXT_WEB'),
                code: null,
                linkClickListener: subscribeClickListener,
            });
        },
    });

    useSubscriptionChangedObserver(
        [response?.subscribeOffer?.productCode],
        () => {
            sessionCreated.current = false;
            resetPlayerAsset();
            fetchDetails();
        },
        [channelId, response]
    );

    useEffect(() => {
        fullLoad.start();

        if (isGuest) {
            openGuestAlert('/');
        }

        return () => {
            if (reFetchTimeoutRef.current) {
                clearTimeout(reFetchTimeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (loaded) {
            fullLoad.stop();
        }
    }, [loaded]);

    useEffect(() => {
        sessionCreated.current = false;
        resetPlayerAsset(previousChannelId.current == null ? CastHandler.getPlayMode() : undefined);
        fetchDetails();

        setActiveControlGroup(ControlGroup.BASE);
        addEventListener(PlayerEvents.END_OF_STREAM, onStreamEndReached);

        trackScreen('LivePlayer', { asset_id: channelId });
        previousChannelId.current = channelId;

        return () => {
            removeEventListener(PlayerEvents.END_OF_STREAM, onStreamEndReached);
        };
    }, [channelId]);

    useEffect(() => {
        if (response) {
            let assetToSet: LivePlayerAssetInfo = response;
            if (!sessionCreated.current) {
                sessionCreated.current = true;
            } else {
                assetToSet = {
                    ...response,
                    ...{
                        manifestUrl: currentPlayerAsset?.manifestUrl,
                    },
                };
            }
            setAsset(assetToSet);

            channelProductCodeRef.current = response.channelProductCode;

            if (response.endTime) {
                clearTimeout(reFetchTimeoutRef.current);
                reFetchTimeoutRef.current = setTimeout(fetchDetails, response.endTime - Date.now());
            }
        }
    }, [response]);

    useEffect(() => {
        if (response && playingMode) {
            if (playingMode === PlayingMode.TIME_SHIFT) {
                clearTimeout(reFetchTimeoutRef.current);
            } else if (response.endTime) {
                clearTimeout(reFetchTimeoutRef.current);
                reFetchTimeoutRef.current = setTimeout(fetchDetails, response.endTime - Date.now());
            }
        }
    }, [response, playingMode]);

    useEffect(() => {
        addRecordingEventListener(RecordingEvent.ADD, setRecorded);
        addRecordingEventListener(RecordingEvent.DELETE, setNotRecorded);

        return () => {
            removeRecordingEventListener(RecordingEvent.ADD, setRecorded);
            removeRecordingEventListener(RecordingEvent.DELETE, setNotRecorded);
        };
    }, [channelId, currentPlayerAsset]);

    useEffect(() => {
        fetchChannels();
        return () => {
            previousChannelId.current = null;
        };
    }, []);

    if (error) {
        return <PageErrorNotFound />;
    }

    return (
        <>
            {response && <HelmetWrapper {...getPlayerPageHelmetData(response)} />}
            <PlayerWrapper
                loading={loading}
                forceExcludedControls={!response?.channelSubscribed ? [PlayerControl.CAST, PlayerControl.SUBTITLE, PlayerControl.INFO] : []}
                extraControls={
                    !isEmbedded && config.isFeatureActive('mini_epg') ? (
                        <MiniEpg currentChannel={channelId} channels={channels} activeGroup={activeControlGroup} />
                    ) : null
                }
            />
        </>
    );
};

export default withNetworkCheck(withPlayer(LivePlayerPage));
