import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { Breakpoints, MQ } from '../../style/styled-components/cssMediaQueries';
import { fontBold } from '../../style/styled-components/cssGlobalVariables';
import Button from '../Buttons/Button/Button';
import { ButtonInner } from '../Buttons/Button/Button.css';

export const ModalDetails = styled.div`
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 16px;

    ${MQ(Breakpoints.s)} {
        width: 440px;
        padding: 0;
    }
`;

export const ModalTitle = styled.div`
    font-size: 32px;
    padding-bottom: 16px;
    line-height: 40px;
    width: 100%;
    font-family: ${fontBold};
    color: ${props => props.theme.brandedConfig.high_contrast};
`;

export const ModalText = styled.p`
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 24px;
    opacity: 0.7;
`;

export const LockedPinCounter = styled.span`
    font-family: ${fontBold};
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 64px;
`;

export const ResetContainer = styled.div`
    position: absolute;
    bottom: 24px;
    left: 16px;
    width: calc(100% - 32px);
    margin-top: 30px;
    display: block;

    ${MQ(Breakpoints.xs)} {
        display: none;
    }
`;

export const ResetButton = styled(Button)`
    font-family: ${fontBold};
    width: 100%;

    ${ButtonInner} {
        display: inline-block;
        width: 100%;
    }

    ${MQ(Breakpoints.xs)} {
        width: auto;
    }
`;

export const HintText = styled.p`
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 14px;
    line-height: 20px;
    opacity: 0.7;
    text-align: center;
    margin-bottom: 16px;
    margin-top: 16px;
`;

export const ModalContentWrapper = styled.div`
    color: ${props => props.theme.brandedConfig.high_contrast};

    ${ResetContainer} {
        display: none;
    }

    ${MQ(Breakpoints.xs)} {
        ${ResetContainer} {
            display: block;
            width: auto;
            position: static;

            ${ResetButton} {
                ${ButtonInner} {
                    width: auto;
                }
            }
        }
    }
`;
