import { useState } from 'react';
import { getLocalStorage, setLocalStorage } from '../../utils/fnStorage';
import { LocalStorage } from '../../types/Storage';

/**
 * Use this function to store/restore a value from local storage but in the same time to have it in the memory also (state)
 *
 * @param key - storage key
 * @param forcedInitialValue - optional initial value
 */
export function usePersistentState<T extends keyof LocalStorage>(key: T, forcedInitialValue?: any) {
    const initialValue = getLocalStorage(key);
    const [store, setStore] = useState<typeof initialValue>(initialValue || forcedInitialValue);

    const set = (value: LocalStorage[T]) => {
        setLocalStorage(key, value);
        setStore(value);
    };

    return {
        store,
        set,
    };
}
