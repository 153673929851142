import React, { FC } from 'react';
import { portraitMixedCardHeight, portraitMixedCardWidth, portraitMovieCardHeight, portraitMovieCardWidth } from './CardPortrait.css';
import { CardWrapper } from '../../Lane/Lane.css';
import { LoadingRectangle } from '../../../style/styled-components/reusable.css';
import { StripeTypes } from '../../../types/Common';

export const CardPortraitLoading: FC<{ stripeType: StripeTypes }> = ({ stripeType }) => {
    const width = stripeType === StripeTypes.MOVIE || stripeType === StripeTypes.PORTRAIT ? portraitMovieCardWidth : portraitMixedCardWidth;
    const height =
        stripeType === StripeTypes.MOVIE || stripeType === StripeTypes.PORTRAIT ? portraitMovieCardHeight : portraitMixedCardHeight;

    return (
        <CardWrapper width={width} height={height}>
            <LoadingRectangle />
        </CardWrapper>
    );
};
