export const isSafari = () => {
    const { userAgent } = navigator;

    return (
        (userAgent.toLowerCase().indexOf('safari') > -1 && userAgent.toLowerCase().indexOf('chrome') === -1) ||
        userAgent.match(/ipad/i) ||
        userAgent.match(/iphone/i)
    );
};

export const isIphone = () => {
    const { userAgent } = navigator;

    return /iPhone/i.test(userAgent);
};
