import { Canceler } from 'axios';
import { Collection } from '../fnParser';
import { CoverAsset } from '../../types/Asset';
import { FetchResponse, Paginated } from '../../types/ApiTypes';

export type ChunkResponseData = {
    pagination: Paginated;
    response: FetchResponse<Collection<CoverAsset>>;
};

export abstract class ChunkLoader {
    private isLoading: boolean = false;

    private reloadTimeout;

    protected requestResponseCallback: (data: ChunkResponseData, error) => void;

    protected data: Collection<CoverAsset> = null;

    protected readonly pagination: Paginated;

    protected canceller: Canceler;

    protected constructor(pagination: Paginated) {
        this.pagination = pagination;
    }

    private scheduleReload = () => {
        if (this.reloadTimeout) {
            clearTimeout(this.reloadTimeout);
        }

        if (this.data && this.data.scheduleReload && this.data.scheduleReload !== Infinity && this.data.scheduleReload > Date.now()) {
            this.reloadTimeout = setTimeout(() => {
                this.request().catch();
            }, this.data.scheduleReload - Date.now());
        }
    };

    protected abstract requestCreator: () => Promise<FetchResponse<Collection<CoverAsset>>>;

    public cancelReload = () => {
        if (this.reloadTimeout) {
            clearTimeout(this.reloadTimeout);
        }
    };

    public setData = (data: Collection<CoverAsset>) => {
        this.data = data;
        this.scheduleReload();

        return this;
    };

    public getData = () => this.data;

    public setRequestResponseCallback = (callback: (data: ChunkResponseData, error) => void) => {
        this.requestResponseCallback = callback;
        return this;
    };

    public request = async () => {
        if (this.isLoading) {
            return;
        }

        try {
            this.isLoading = true;
            const requestResponse = await this.requestCreator();

            if (requestResponse.response) {
                this.setData(requestResponse.response);
            }

            if (this.requestResponseCallback) {
                this.requestResponseCallback(
                    {
                        pagination: this.pagination,
                        response: requestResponse,
                    },
                    null
                );
            }
        } catch (e) {
            console.error(e);

            if (this.requestResponseCallback) {
                this.requestResponseCallback(null, e);
            }
        } finally {
            this.isLoading = false;
        }
    };
}
