import React from 'react';
import icons from '../../../style';
import { PlayButton, StyledSVGInline } from './ButtonPlayOutline.css';
import { getTrackingDataFromDataAttributes } from '../../../utils/fnTracking';
import { useTracking } from '../../../providers/useTracking/TrackingContext';

type PropType = {
    onClick?: (e) => void;
    assetId?: string;
    screenName?: string;
    style?: {};
};

export const ButtonPlayOutline: React.FC<PropType> = ({ onClick, ...rest }) => {
    const track = useTracking();

    const handleClick = e => {
        if (rest['data-event-name']) {
            track(rest['data-event-name'], getTrackingDataFromDataAttributes(rest));
        }

        if (onClick) {
            onClick(e);
        }
    };

    return (
        <PlayButton data-test-id={'stripe-card-play-button'} onClick={handleClick} {...rest}>
            <StyledSVGInline svg={icons.playIcon} />
        </PlayButton>
    );
};
