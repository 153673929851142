import ApplicationConfig from '../../providers/useConfig/ApplicationConfig';
import { ChunkLoaderData } from './ChunkLoaderData';
import { ChunkLoader, ChunkResponseData } from './ChunkLoader';
import { Paginated } from '../../types/ApiTypes';
import { Collection } from '../fnParser';
import { CoverAsset } from '../../types/Asset';
import { healthCheck } from '../../hooks/useDataFetcher/useDataFetcher';

export abstract class Loader {
    private dataChangedCallback: (data: ChunkLoaderData) => void;

    private errorCallback: () => void;

    private isOfflineCallback: () => void;

    private isOnlineCallback: () => void;

    private networkErrorCallback: (error: number) => void;

    private dataUpdateTimeout: number;

    private errorTimeout: number;

    private updateTimeOut: number;

    private backEndErrorUpdateInterval = ApplicationConfig?.api_config.backend_error_update_interval;

    protected pageIndex = 0;

    protected readonly pageSize: number = ApplicationConfig.api_config.page_size;

    protected chunkLoaderMap: { [key: string]: ChunkLoader } = {};

    protected collectionData: ChunkLoaderData;

    protected constructor(pageSize: number) {
        this.pageSize = pageSize;
    }

    protected createLoaderInstance: (pagination: Paginated) => ChunkLoader;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    protected setTotal = (total: number) => {
        // Implement in the sub class
    };

    protected responseHandler = (chunkResponse?: ChunkResponseData, error?: any) => {
        if (chunkResponse && chunkResponse.response?.response) {
            this.setTotal(chunkResponse.response.response.totalItems);

            this.collectionData.replaceChunk({
                data: chunkResponse.response.response,
                pagination: chunkResponse.pagination,
            });

            clearTimeout(this.dataUpdateTimeout);
            this.dataUpdateTimeout = window.setTimeout(() => {
                if (this.dataChangedCallback) {
                    this.dataChangedCallback({ ...this.collectionData });
                }
            }, 100);
        }

        if ((chunkResponse && chunkResponse.response?.error) || error) {
            clearTimeout(this.errorTimeout);
            clearTimeout(this.errorTimeout);
            this.errorTimeout = window.setTimeout(async () => {
                if (this.isOffline() && this.isOfflineCallback) {
                    this.updateTimeOut = window.setInterval(() => {
                        if (!this.isOffline()) {
                            clearInterval(this.updateTimeOut);
                            this.isOnlineCallback();
                        }
                    }, this.backEndErrorUpdateInterval);
                    this.isOfflineCallback();
                } else if (
                    !!chunkResponse.response?.error ||
                    chunkResponse.response?.status?.toString().startsWith('4') ||
                    chunkResponse.response?.status?.toString().startsWith('5')
                ) {
                    if (await healthCheck()) {
                        this.networkErrorCallback(null);
                    } else {
                        this.errorTimeout = window.setInterval(async () => {
                            if (await healthCheck()) {
                                clearInterval(this.errorTimeout);
                                this.networkErrorCallback(null);
                            }
                        }, this.backEndErrorUpdateInterval);
                        this.networkErrorCallback(chunkResponse.response?.status || -1);
                        this.errorCallback();
                    }
                }
            }, 250);
        }
    };

    protected createLoader = (pagination: Paginated, data: Collection<CoverAsset>, invokeRequest: boolean = true) => {
        const loaderId = JSON.stringify(pagination);

        if (loaderId in this.chunkLoaderMap) {
            return;
        }

        const loader = this.createLoaderInstance(pagination)
            .setRequestResponseCallback((result, error) => {
                this.responseHandler(result, error);
            })
            .setData(data);

        if (invokeRequest) {
            loader.request().catch();
        }

        this.chunkLoaderMap[loaderId] = loader;
        this.pageIndex += 1;
    };

    public setData = (data: ChunkLoaderData) => {
        this.collectionData = data;

        return this;
    };

    public setDataChangedListener = (callback: (data: ChunkLoaderData) => void) => {
        this.dataChangedCallback = callback;

        return this;
    };

    public isOffline = () => {
        return !window.navigator.onLine;
    };

    public setErrorCallback = callback => {
        this.errorCallback = callback;

        return this;
    };

    public setIsOfflineCallback = callback => {
        this.isOfflineCallback = callback;

        return this;
    };

    public setIsOnlineCallback = callback => {
        this.isOnlineCallback = callback;

        return this;
    };

    public setNetworkErrorCallback = callback => {
        this.networkErrorCallback = callback;

        return this;
    };

    public initialRequest = () => {
        this.createLoader(
            {
                start: 0,
                limit: this.pageSize,
            },
            null
        );
    };

    public loadMore = () => {
        const mergedInfo = this.collectionData.getMergedData();
        if (mergedInfo.loadedAssetCount < mergedInfo.totalCount) {
            const offset = this.pageIndex * this.pageSize;
            let size;

            if (offset + this.pageSize >= mergedInfo.totalCount) {
                size = mergedInfo.totalCount - offset;
            } else {
                size = this.pageSize;
            }

            this.createLoader(
                {
                    start: offset,
                    limit: size,
                },
                null
            );
        }
    };

    public destroy = () => {
        Object.keys(this.chunkLoaderMap).forEach(key => this.chunkLoaderMap[key].cancelReload());
    };
}
