import axios from 'axios';
import { Datasource } from '../../types/Config';
import { Paginated } from '../../types/ApiTypes';
import Api from '../../api/Api';
import { Collection } from '../../utils/fnParser';
import { CoverAsset } from '../../types/Asset';
import { ChunkLoader } from '../../utils/ChunkLoader/ChunkLoader';

export class CollectionChunkDataLoader extends ChunkLoader {
    private readonly dataSource: Datasource;

    private readonly dataParser;

    constructor(dataSource: Datasource, dataParser, pagination: Paginated) {
        super(pagination);

        this.dataSource = dataSource;
        this.dataParser = dataParser;
    }

    protected requestCreator = async () => {
        return Api.fetchByDataSource<Collection<CoverAsset>>(
            this.dataSource,
            this.dataParser,
            'PUT',
            new axios.CancelToken(executor => {
                this.canceller = executor;
            }),
            this.pagination
        );
    };
}
