import React, { FC } from 'react';
import * as Sentry from '@sentry/react';
import { AlertDialog, AlertPropTypes } from '../AlertDialog/AlertDialog';
import translate from '../../utils/fnTranslate';
import { useNavigation } from '../../hooks/useNavigation';

type ErrorState = {
    error: any;
    errorInfo: any;
    eventId: any;
};

const CLOSE_ERROR_TIMEOUT = 250;
const PageLevelErrorDialog: FC<Partial<AlertPropTypes> & { onCloseClicked: () => void }> = ({ buttons, hintText, onCloseClicked }) => {
    const { onBackPressed } = useNavigation();

    return (
        <AlertDialog
            title={translate('ERR_GENERIC_TITLE')}
            bodyText={translate('ERR_GENERIC_BODY')}
            hintText={hintText}
            buttons={[
                ...buttons,
                ...[
                    {
                        text: translate('CLOSE_BUTTON'),
                        onClick: () => {
                            onBackPressed();
                            setTimeout(() => onCloseClicked(), CLOSE_ERROR_TIMEOUT);
                        },
                    },
                ],
            ]}
        />
    );
};

export class ErrorBoundary extends React.Component<{ innerLevel: boolean }, ErrorState> {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null, eventId: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error,
            errorInfo,
        });
        Sentry.withScope(scope => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId });
        });
    }

    render() {
        if (this.state.errorInfo) {
            return this.props.innerLevel ? (
                <PageLevelErrorDialog
                    hintText={this.state?.error?.code}
                    buttons={[
                        {
                            text: translate('TRY_AGAIN_BUTTON'),
                            onClick: () => {
                                this.setState({
                                    error: null,
                                    errorInfo: null,
                                    eventId: null,
                                });
                            },
                        },
                    ]}
                    onCloseClicked={() =>
                        this.setState({
                            error: null,
                            errorInfo: null,
                            eventId: null,
                        })
                    }
                />
            ) : (
                // in case an error happens outside of the inner level provide a reload button which reloads the entire app
                <AlertDialog
                    title={translate('ERR_GENERIC_TITLE')}
                    bodyText={translate('ERR_GENERIC_BODY')}
                    hintText={this.state?.error?.code}
                    buttons={[
                        {
                            text: translate('TRY_AGAIN_BUTTON'),
                            onClick: () => {
                                // eslint-disable-next-line no-restricted-globals
                                location.reload();
                            },
                        },
                    ]}
                />
            );
        }

        return this.props.children;
    }
}
