import React, { useRef, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { PictureStyle, PictureStyleProps, PictureWrapper } from './Picture.css';

type SourceSet = Array<{
    srcSet: string;
    media: string;
}>;

export type PicturePropType = React.ImgHTMLAttributes<HTMLImageElement> &
    PictureStyleProps & {
        sources?: SourceSet;
        imageError?: () => void;
        imageLoaded?: () => void;
    };

const isInvalidSrc = (src: string, srcSet: SourceSet) => (!src || src === '') && srcSet.map(x => x.srcSet).join('') === '';

// eslint-disable-next-line import/no-unused-modules
export const Picture: React.FC<PicturePropType> = ({
    sources = [],
    fit,
    vPosition,
    hPosition,
    src,
    alwaysLoad = false,
    imageError,
    imageLoaded,
    children,
    alt,
    ...rest
}) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const supportsLazyLoading = 'loading' in HTMLImageElement.prototype;

    const imgRef = useRef<HTMLImageElement>();
    const [error, setError] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const invalidSrc = isInvalidSrc(src, sources);
    const showFallback = invalidSrc || error;

    useEffect(() => {
        if (showFallback) {
            if (imageError) {
                imageError();
            }
        }
    }, [showFallback, imageError]);

    useEffect(() => {
        if (loaded && imageLoaded) {
            imageLoaded();
        }
    }, [loaded, imageLoaded]);

    if (showFallback) {
        return <>{children}</>;
    }

    return (
        <PictureWrapper data-test-id={'tv-castandcrew-castmemberphoto'} ref={!supportsLazyLoading ? ref : null}>
            <>
                {inView || alwaysLoad || supportsLazyLoading ? (
                    <PictureStyle
                        data-test-id={'tv-detailscreen-channellogo'}
                        onError={() => {
                            setError(true);
                        }}
                        onLoad={() => {
                            setLoaded(true);
                        }}
                        fit={fit}
                        vPosition={vPosition}
                        hPosition={hPosition}
                        loaded={loaded}
                    >
                        {sources.map(({ media, srcSet }, k) => (
                            <source key={k} srcSet={srcSet} media={media} />
                        ))}

                        <img ref={imgRef} alt={alt} {...rest} src={src} loading={'lazy'} />
                    </PictureStyle>
                ) : (
                    children
                )}
            </>
        </PictureWrapper>
    );
};
