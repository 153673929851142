import React from 'react';
import SVGInline from 'react-svg-inline';
import icons from '../../style';
import { DropdownItemSimple, DropdownItemWithSvg, DropdownMenu } from '../DropdownMenu/DropdownMenu';
import { useAuth } from '../../providers/useAuth/AuthContext';
import { useNavigation } from '../../hooks/useNavigation';
import translate from '../../utils/fnTranslate';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { StaticPageRoutes } from '../../types/RouteTypes';
import { useElementInteractionTracking } from '../../providers/useTracking/TrackingContext';
import { LoginButton } from './Header.css';
import { DropdownItemWSeparator } from '../DropdownMenu/DropdownMenu.css';
import { useConfig } from '../../providers/useConfig/ConfigContext';

const HeaderAccount = styled.div`
    text-align: center;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    border-radius: 50%;

    .SVGInline {
        height: 100%;
        width: 100%;
    }

    &:hover {
        cursor: pointer;
        opacity: 0.6;
    }
`;

const DropdownMenuSettingsComponent: React.FC = ({ ...rest }) => {
    const { isGuest, isLoggedIn, logUserOut, openLogOutAlert, userInfoDetails } = useAuth();
    const { history } = useNavigation();
    const interactionTracking = useElementInteractionTracking();
    const { config } = useConfig();

    const onSettingsClicked = () => {
        interactionTracking({
            'data-track-id': 'user_menu_settings',
        });
        history.push(StaticPageRoutes.SETTINGS);
    };

    const onKidsClicked = () => {
        interactionTracking({
            'data-track-id': 'user_menu_kids',
        });
        const path = config.getPagePath('KIDS');
        history.push(path);
    };

    const onLogInOutClicked = () => {
        interactionTracking({
            'data-track-id': !isGuest ? 'user_menu_logout' : 'user_menu_login',
        });

        if (!isGuest) {
            openLogOutAlert();
        } else {
            logUserOut(true);
        }
    };

    return (
        <>
            {isLoggedIn && !isGuest && (
                <DropdownMenu
                    {...rest}
                    triggerButton={
                        <HeaderAccount data-test-id={'header-account-dropdownbutton'}>
                            <SVGInline svg={icons.userIcon} />
                        </HeaderAccount>
                    }
                    horizontalPosition={'right'}
                    verticalPosition={'bottom'}
                >
                    <DropdownItemWithSvg svg={icons.avatarIcon} onClick={() => {}}>
                        {userInfoDetails?.email}
                    </DropdownItemWithSvg>
                    <DropdownItemWSeparator />
                    <DropdownItemSimple onClick={onSettingsClicked} withSelectedIcon={false}>
                        {translate('SETTINGS_SECTION_TITLE')}
                    </DropdownItemSimple>
                    <DropdownItemSimple onClick={onLogInOutClicked} withSelectedIcon={false}>
                        {!isLoggedIn || isGuest ? translate('SETTINGS_LOGIN') : translate('SETTINGS_SIGNOUT')}
                    </DropdownItemSimple>
                </DropdownMenu>
            )}
            {(!isLoggedIn || isGuest) && <LoginButton onClick={onLogInOutClicked}>{translate('SCREEN_LOGIN_BUTTON')}</LoginButton>}
        </>
    );
};

export const DropdownMenuSettings = styled(DropdownMenuSettingsComponent)`
    float: right;
`;
