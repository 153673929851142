import React from 'react';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';

interface Attrs {
    percentage: number;
}

export const ProgressContainer = styled.span`
    overflow: hidden;
    display: block;
    height: 5px;
    z-index: 5;
    opacity: 1;
    transition: all 0.2s ease-in-out;
    position: absolute;
    bottom: 10px;
    left: 8px;
    right: 8px;
    border-radius: 3px;

    &:before {
        background-color: ${props => props.theme.brandedConfig.white_color};
        content: '';
        width: 100%;
        height: 100%;
        opacity: 0.12;
        display: block;
    }
`;

export const ProgressBar = styled.span.attrs<Attrs>(props => ({
    style: {
        width: `${props.percentage}%`,
    },
}))<Attrs>`
    background-color: ${props => props.theme.brandedConfig.white_color};
    border-radius: 3px;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
`;

export const ProgressIndicator: React.FC<{ percentage: number; displayWithNullProgress?: boolean }> = ({
    percentage = 0,
    displayWithNullProgress = false,
    ...rest
}) => {
    if ((percentage < 1 && !displayWithNullProgress) || Number.isNaN(percentage)) {
        return null;
    }

    return (
        <ProgressContainer {...rest}>
            <ProgressBar data-test-id={'tvguide-item-progressbar'} percentage={percentage} {...rest} />
        </ProgressContainer>
    );
};
