import SVGInline from 'react-svg-inline';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { maxZIndex } from '../../style/styled-components/cssGlobalVariables';
import { hexToRgba } from '../../utils/fnColors';
import { DropdownItemSimpleWrapper } from '../DropdownMenu/DropdownMenu.css';
import { Breakpoints, MQ } from '../../style/styled-components/cssMediaQueries';

export const RecordingPopupContentWrapper = styled.div`
    text-align: left;
`;

export const RecordingPopupWrapper = styled.div`
    position: relative;
    z-index: 5;
`;

export const RecordingBlock = styled.div`
    padding-top: 15px;
    width: 50%;
    display: inline-block;

    @media (orientation: portrait) {
        width: 100%;
        padding-top: 5px;
    }

    ${MQ(Breakpoints.s)} {
        padding-top: 0;
        width: 100%;
    }
`;

export const StyledSVGInline = styled(SVGInline)`
    width: 100%;
    height: 100%;

    .SVGInline {
        width: 100%;
        height: 100%;
    }

    .SVGInline-svg {
        width: 100%;
    }
`;

export const OptionContainer = styled.div`
    ${DropdownItemSimpleWrapper} {
        padding-left: 50px;

        .SVGInline {
            left: 20px;
        }
    }
`;

/**
 * Mobile view
 *
 */

export const CloseButton = styled.a`
    height: 27px;
    width: 27px;
    display: block;
    z-index: 15;
    cursor: pointer;
    line-height: 24px;
    opacity: 1;
    position: absolute;
    right: 18.67px;
    top: 18.67px;
    font-size: 24px;

    ${MQ(Breakpoints.xs)} {
        right: 26.67px;
        top: 26.67px;
    }
`;

export const RecordingActionWindowMobileWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    z-index: ${maxZIndex};
    width: 100%;
    height: 100%;
    background: ${props => hexToRgba(props.theme.brandedConfig.low_contrast, 0.9)};

    ${DropdownItemSimpleWrapper} {
        background: transparent;
    }

    ${RecordingPopupContentWrapper} {
        padding-top: 64px;
        overflow: auto;
        height: 100%;

        ${DropdownItemSimpleWrapper} {
            position: absolute;
            width: 100%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
        }
    }
`;
