import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { usePersistentState } from 'hooks/useStorage/useStorage';
import { useNavigation } from '../../hooks/useNavigation';
import { StaticPageRoutes } from '../../types/RouteTypes';
import { stripeAction } from '../reducers/stripe-reducer';
import localConfig from '../../config/localConfig';
import { DataSourceMethod } from '../../types/ApiTypes';

const QUERY = 'term';

const useSearchService = () => {
    const { history, onBackPressed } = useNavigation();
    const [searchTerm, setSearchTerm] = useState<string>(null);
    const { store: searchHistory, set: setSearchHistory } = usePersistentState('searchHistory', []);
    const dispatch = useDispatch();

    const handleSearchHistory = (query: string) => {
        if (!query) return;

        if (query.length < 3) return;

        if (searchHistory?.find(item => item === query)) {
            const fiterSearchHistory = searchHistory.filter(item => item !== query);

            fiterSearchHistory.unshift(query);
            setSearchHistory(fiterSearchHistory);
        } else {
            const searchHistoryToSet = [query, ...searchHistory];

            if (searchHistoryToSet.length > 15) {
                searchHistoryToSet.pop();
            }
            setSearchHistory(searchHistoryToSet);
        }
    };

    const deleteSearchHistoryItem = (value: string) => {
        const filteredSearchHistory = searchHistory.filter(item => item !== value);
        setSearchHistory(filteredSearchHistory);
    };

    const doSearch = (query: string) => {
        const searchInput = query.trim();

        const urlQueryParams = new URLSearchParams(history.location.search);
        urlQueryParams.set(QUERY, searchInput);

        history.replace({ search: urlQueryParams.toString() });
    };

    const exitSearch = (exit: boolean) => {
        setSearchTerm(null);

        dispatch(stripeAction('CLEAR', { key: `${localConfig.data.searchCachePrefix}${DataSourceMethod.SEARCH_MOVIES}` }));
        dispatch(stripeAction('CLEAR', { key: `${localConfig.data.searchCachePrefix}${DataSourceMethod.SEARCH_SERIES}` }));

        if (exit) {
            onBackPressed();
        }
    };

    const enterSearch = () => {
        history.push(StaticPageRoutes.SEARCH);
    };

    useEffect(() => {
        const urlQueryParams = new URLSearchParams(history.location.search);
        const term = urlQueryParams.get(QUERY);

        if (term != null) {
            setSearchTerm(term);
        }
    }, [history.location.search]);

    useEffect(() => {
        if (searchTerm) {
            dispatch(stripeAction('CLEAR', { key: `${localConfig.data.searchCachePrefix}${DataSourceMethod.SEARCH_MOVIES}` }));
            dispatch(stripeAction('CLEAR', { key: `${localConfig.data.searchCachePrefix}${DataSourceMethod.SEARCH_SERIES}` }));
        }
    }, [searchTerm]);

    return {
        searchTerm,
        doSearch,

        exitSearch,
        enterSearch,

        searchHistory,
        handleSearchHistory,
        deleteSearchHistoryItem,
    };
};

export const SearchContext = createContext<ReturnType<typeof useSearchService>>(null);

export const SearchProvider = ({ children }) => {
    const service = useSearchService();

    return <SearchContext.Provider value={service}>{children}</SearchContext.Provider>;
};

export const useSearch = () => useContext(SearchContext);
