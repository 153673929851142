import { AssetTypes } from './Common';
import { AiringTime, PlayOptionsData, Recordable, Rentable, Subscribable, TVEpisodeInfo } from './CommonTypes';

export enum AudioMixType {
    NO_SOUND = '1',
    MONO = '2',
    STEREO = '3',
    DOLBY_5_1 = '5',
    DOLBY_DIGITAL = '10',
    DOLBY_PROLOGIC = '11',
}

export enum PersonRole {
    ACTOR,
    DIRECTOR,
}

export type Marker = {
    value: string;
    backgroundColor?: string;
    textColor?: string;
    priority?: number;
};

export enum ResolutionType {
    'SD' = 'SD',
    'HD' = 'HD',
    '4K' = '4K',
    '8K' = '8K',
}

export type Bookmark = {
    titleId: string;
    bookmark: number;
};

export type PlaceholderAsset = {
    id: string;
    type: AssetTypes;
    isPortrait: boolean;
};

export type Channel = {
    id: string;
    logo: string;
    number: number;
    name: string;
    type: AssetTypes;
    productCode: string;
    subscription?: boolean;
    favorite?: boolean;
    isHd?: boolean;
    replay: boolean;
};

export type Broadcast = AiringTime &
    TVEpisodeInfo &
    Subscribable &
    Bookmark & {
        id: string;
        title: string;
        image: string;
        landscapeImage: string;
        metaData: string;
        countries: string[];
        duration: number;
        releaseYear: string;
        genres: string[];
        audioMixType: AudioMixType;
        hasSubtitle: boolean;
        hasAudioLanguage: boolean;
        channelHasReplay: boolean;
        isChannelSubscribed: boolean;
        isLive: boolean;
        channelId: string;
        channelName?: string;
        channelLogo?: string;
        isHDChannel?: boolean;
        episodeNumber?: number;
        type: AssetTypes;
        isFavorite?: boolean;
        isPortrait: boolean;
        isMovie?: boolean;
        worldId?: string;
    };

export type NowAndNext = AiringTime &
    TVEpisodeInfo &
    Subscribable & {
        id: string;
        title: string;
        nextBroadcastTitle: string;
        image: string;
        channelId: string;
        channelNumber: number;
        channelName: string;
        channelLogo: string;
        isChannelSubscribed: boolean;
        isLive: boolean;
        type: AssetTypes;
        bookmark: number;
    };

export type Movie = Rentable &
    Bookmark & {
        id: string;
        title: string;
        image: string;
        landscapeImage: string;
        metaData: string;
        countries: string[];
        releaseYear: string;
        duration: number;
        genres: string[];
        audioMixType: AudioMixType;
        hasSubtitle: boolean;
        hasAudioLanguage: boolean;
        firstAvailability: number;
        resolution: ResolutionType;
        price: number;
        type: AssetTypes;
        imdbRating?: number;
        isPortrait: boolean;
        entitlementEnd?: number;
    };

export type Series = {
    id: string;
    title: string;
    image: string;
    landscapeImage: string;
    metaData: string;
    countries: string[];
    releaseYear: string;
    genres: string[];
    audioMixType: AudioMixType;
    hasSubtitle: boolean;
    hasAudioLanguage: boolean;
    numberOfSeasons: number;
    firstAvailability: number;
    price: number;
    type: AssetTypes;
    imdbRating?: number;
    lastEpisodeFirstAvailability?: number;
    isVOD: boolean;
};

export type Season<T extends DetailEpisode | TVSeriesDetailEpisode> = {
    id: string;
    name: string;
    numberOfEpisodes: number;
    number: number;
    episodes?: T[];
};

export type Episode = Rentable &
    Bookmark & {
        id: string;
        episodeId: string;
        episodeNumber: number;
        seasonNumber: number;
        title: string;
        image: string;
        landscapeImage: string;
        metaData: string;
        countries: string[];
        releaseYear: string;
        duration: number;
        genres: any[];
        audioMixType: AudioMixType;
        hasSubtitle: boolean;
        hasAudioLanguage: boolean;
        firstAvailability: number;
        resolution: ResolutionType;
        price: number;
        type: AssetTypes;
        imdbRating?: number;
    };

export type DetailEpisode = Episode & {
    episodeTitle: string;
    episodeTitleShort: string;
    shortDescription: string;
    longDescription: string;
    cast: Person[];
    hasTrailer: boolean;
    captionLanguages?: string[];
    originalLanguages?: string[];
    channelImage?: string;
    channelName?: string;
    seriesTitle?: string;
};

export type TVSeriesDetailEpisode = Omit<DetailEpisode, keyof Rentable> &
    AiringTime &
    Subscribable &
    Recordable & {
        eventId?: string;
        channelId?: string;
        channelImage?: string;
        channelName?: string;
        isHDChannel?: boolean;
        isRecorded: boolean;
        isChannelSubscribed?: boolean;
        channelHasReplay?: boolean;
        playOptions: PlayOptionsData;
        isLive?: boolean;
    };

export type Person = {
    id: string;
    title: string;
    image: string;
    type: AssetTypes;
    role: PersonRole;
    onClickOpenCollection?: boolean;
};

export type Resource = {
    id: string;
};

export type Recording = AiringTime &
    Bookmark & {
        id: string;
        title: string;
        duration: number;
        releaseYear?: string;
        eventId: string;
        genres: any[];
        image: string;
        landscapeImage: string;
        type: AssetTypes;
        metaData: string;
        isPortrait: boolean;
        seriesId?: string;
        isLive?: boolean;
    };

export type CoverAsset = (Broadcast | Movie | Series | Episode | Channel | NowAndNext | Person | Resource | Recording) & {
    genres?: string[];
    vodAssetState?: string;
};

export type StandardAsset = {
    type: AssetTypes;
    title: string;
    id: string;
    landscapeImage: string;
    hasTrailer: boolean;
    captionLanguages?: string[];
    originalLanguages?: string[];
};

export type MovieDetails = StandardAsset &
    Rentable &
    Bookmark & {
        image: string;
        progType: string;
        genres?: any[];
        duration: number;
        shortDescription: string;
        longDescription: string;
        fsk: string;
        releaseYear: string;
        countries: string[];
        cast: Person[];
        isFavorite?: boolean;
        price: number;
        firstAvailability: number;
        resolution: ResolutionType;
        entitlementEnd?: number;
    };

export type SeriesDetails<T extends DetailEpisode | TVSeriesDetailEpisode> = StandardAsset & {
    seasons?: Season<T>[];
    description?: string;
};

export type ProgramDetails = StandardAsset &
    AiringTime &
    Subscribable &
    Recordable &
    Bookmark & {
        progType: string;
        channelId: string;
        channelNumber: number;
        channelName: string;
        channelLogo: string;
        isHDChannel: boolean;
        resolution: ResolutionType;
        isChannelSubscribed: boolean;
        description: string;
        cast: Person[];
        subTitle?: string;
        episodeName?: string;
        releaseYear?: string;
        countries: string[];
        genres?: any[];
        seasonId?: string;
        seriesId?: string;
        channelHasReplay: boolean;
        isFavorite: boolean;
        isMovie: boolean;
        worldId: string;
        isLive?: boolean;
        episodeTitleShort?: string;
        playOptions: PlayOptionsData;
    };

export type RecordingDetails = Omit<ProgramDetails, (keyof Subscribable & 'playOptions') | 'worldId' | 'isFavorite' | 'resolution'>;

export type ExtraMetaData = {
    imdRating: number;
    actors?: Person[];
    directors?: Person[];
};

export enum defaultLanguageTypes {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
}
