import React, { FC, useEffect, useRef, useState } from 'react';
import SVGInline from 'react-svg-inline';
import { getLanguage } from 'utils/fnData';
import { Person } from 'types/Asset';
import {
    DetailMoreAudio,
    DetailMoreAudioAndSubtitle,
    DetailMoreCast,
    DetailMoreSubtitle,
    GradientBottom,
    MetadataWrapper,
    ModalContent,
    ModalContentWrapper,
    ModalDetails,
    ModalSubtitle,
    ModalTitle,
    ModalTitleWrapper,
} from './DetailMoreModal.css';
import icons from '../../style';
import { withNoScroll } from '../../hooks/withNoScroll/withNoScroll';
import translate from '../../utils/fnTranslate';
import { ModalCloseButton, ModalNavigation, ModalWrapper } from '../../style/styled-components/reusable.css';

type PropTypes = {
    modalTitle?: string;
    modalSubTitle?: string;
    metadata?: string;
    showMoreMetadataTitle?: any[];
    showMoreMetadataContent?: any[];
    cast?: Person[];
    directors?: Person[];
    modalText?: string;
    closeOverlay: () => void;
    caption?: string[] | null;
    original?: string[] | null;
};

const DetailMoreModalComponent: FC<PropTypes> = ({
    modalTitle,
    modalSubTitle,
    metadata,
    showMoreMetadataTitle,
    showMoreMetadataContent,
    cast = [],
    directors = [],
    modalText,
    closeOverlay,
    caption,
    original,
}) => {
    const scrollbarRef = useRef<HTMLDivElement>(null);
    const contentScrollbarRef = useRef(null);
    const [isScrollable, setIsScrollable] = useState<boolean>(true);
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        if (scrollbarRef?.current && contentScrollbarRef?.current) {
            setIsVisible(true);
            setIsScrollable(contentScrollbarRef?.current?.clientHeight > scrollbarRef?.current?.clientHeight);
        } else {
            setIsVisible(false);
            setIsScrollable(!isScrollable);
        }
    }, [scrollbarRef]);

    return (
        <ModalWrapper ref={scrollbarRef} opacity={0.9}>
            <ModalNavigation>
                <ModalCloseButton data-test-id={'tv-detailscreen-closeoverlaybutton'} onClick={closeOverlay}>
                    <SVGInline svg={icons.closeIcon} />
                </ModalCloseButton>
            </ModalNavigation>
            <ModalDetails isVisible={isVisible} isScrollable={isScrollable} ref={contentScrollbarRef}>
                <ModalTitleWrapper>
                    <ModalTitle data-test-id={'tv-detailscreen-modaltitleoverlay'}>{modalTitle}</ModalTitle>
                    <ModalSubtitle data-test-id={'tv-detailscreen-modalsubtitleoverlay'}>{modalSubTitle}</ModalSubtitle>
                </ModalTitleWrapper>
                <ModalContentWrapper>
                    {showMoreMetadataTitle && showMoreMetadataContent ? (
                        <MetadataWrapper>
                            <ModalContent>{showMoreMetadataTitle}</ModalContent>
                            <ModalContent>{showMoreMetadataContent}</ModalContent>
                        </MetadataWrapper>
                    ) : (
                        <MetadataWrapper>
                            <ModalContent>{metadata}</ModalContent>
                        </MetadataWrapper>
                    )}
                    <ModalContent data-test-id={'tv-detailscreen-descriptionoverlay'}>{modalText}</ModalContent>
                    <ModalContent>
                        {cast.length > 0 && (
                            <DetailMoreCast>
                                {`${translate('SCREEN_MORE_INFO_ACTORS')} ${cast.map(person => person.title).join(', ')}`}
                            </DetailMoreCast>
                        )}
                        {directors.length > 0 && (
                            <DetailMoreCast>
                                {`${translate('SCREEN_MORE_INFO_DIRECTORS')} ${directors.map(person => person.title).join(', ')}`}{' '}
                            </DetailMoreCast>
                        )}
                    </ModalContent>
                    <DetailMoreAudioAndSubtitle isScrollable={isScrollable}>
                        {original !== null && original?.length > 0 && (
                            <DetailMoreAudio>
                                {`${translate('DETAIL_AUDIO_LANGUAGE')} `}{' '}
                                {original.map(audio => getLanguage(audio, translate(`LANGUAGE_${audio.toUpperCase()}`))).join(', ')}
                            </DetailMoreAudio>
                        )}
                        {caption !== null && caption?.length > 0 && (
                            <DetailMoreSubtitle>
                                {`${translate('DETAIL_SUBTITLES')}`}{' '}
                                {caption.map(subtitle => getLanguage(subtitle, translate(`LANGUAGE_${subtitle.toUpperCase()}`))).join(', ')}
                            </DetailMoreSubtitle>
                        )}
                        {caption !== null && caption?.length === 0 && (
                            <DetailMoreSubtitle>{`${translate('DETAIL_SUBTITLES')} ${translate('SUBTITLES_NONE')}`}</DetailMoreSubtitle>
                        )}
                    </DetailMoreAudioAndSubtitle>
                </ModalContentWrapper>
            </ModalDetails>
            <GradientBottom />
        </ModalWrapper>
    );
};

export const DetailMoreModal = withNoScroll(DetailMoreModalComponent);
