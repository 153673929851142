import React, { FC, useEffect } from 'react';
import axios from 'axios';
import { getMiniPlayerProps } from 'utils/fnData';
import { getVODPlayOptionDetailURL } from '../../../utils/fnUrl';
import { useDataFetcher, useRequestCanceller } from '../../../hooks/useDataFetcher/useDataFetcher';
import { PlayerEvents, PlayingAssetType, VodPlayerAssetInfo } from '../../../types/Player';
import Api from '../../../api/Api';
import { usePlayer } from '../../../providers/player/PlayerContext';
import { useNavigation } from '../../../hooks/useNavigation';
import { PageErrorNotFound } from '../PageErrorNotFound/PageErrorNotFound';
import { PlayerWrapper } from '../../Player/PlayerWrapper';
import { useKPI, useTrackingScreen } from '../../../providers/useTracking/TrackingContext';
import { useAuth } from '../../../providers/useAuth/AuthContext';
import { FetchParamById } from '../../../types/ApiTypes';
import { useGlobalNetworkError, useGlobalNoInternet, withNetworkCheck } from '../../../hooks/withNetworkCheck/withNetworkCheck';
import { HelmetWrapper } from '../../HelmetWrapper/HelmetWrapper';
import { getPlayerPageHelmetData } from '../../../utils/fnHelmet';
import { withPlayer } from '../../../hooks/withPlayer/withPlayer';
import { usePlayerEntitlementChecker } from '../../../hooks/useEntitlementChecker/usePlayerEntitlementChecker';
import { useApp } from '../../../providers/useApp/AppContext';

const VodPlayerPage: FC<{ assetId: string }> = ({ assetId }) => {
    const { onNoInternet } = useGlobalNoInternet();
    const { onNetworkError } = useGlobalNetworkError();
    const setCanceller = useRequestCanceller();

    const { history } = useNavigation();

    const { response, error, loading, fetcher } = useDataFetcher<VodPlayerAssetInfo, FetchParamById>(
        params => Api.fetchVodPlayerInfo(params.id, new axios.CancelToken(executor => setCanceller(executor))),
        onNoInternet,
        onNetworkError
    );

    const { isGuest, openGuestAlert } = useAuth();
    const { appLanguage } = useApp();
    const { onBackPressed } = useNavigation();
    const {
        setAsset,
        addEventListener,
        removeEventListener,
        resetPlayerAsset,
        isMini,
        closeMiniPlayer,
        nextEpisode,
        updateMiniPlayerProps,
        setDisplayBingeWatch,
        loaded,
    } = usePlayer();
    const trackScreen = useTrackingScreen(() => true, [assetId]);
    const { fullLoad } = useKPI('VodPlayer');

    const onStreamEndReached = () => {
        setDisplayBingeWatch(false);

        if (isMini) {
            if (nextEpisode) {
                updateMiniPlayerProps(getMiniPlayerProps(nextEpisode, PlayingAssetType.VOD));
            } else {
                closeMiniPlayer();
            }
        } else {
            onBackPressed();
        }
    };

    const fetchDetails = () => {
        if (!isGuest) {
            fetcher({ id: assetId });
        }
    };

    usePlayerEntitlementChecker(response, {
        onNotEntitled: () => {
            history.replace(getVODPlayOptionDetailURL(response, appLanguage));
        },
    });

    useEffect(() => {
        if (loaded) {
            fullLoad.stop();
        }
    }, [loaded]);

    useEffect(() => {
        if (response) {
            setAsset(response);
        }
    }, [response]);

    useEffect(() => {
        addEventListener(PlayerEvents.END_OF_STREAM, onStreamEndReached);

        return () => {
            removeEventListener(PlayerEvents.END_OF_STREAM, onStreamEndReached);
        };
    }, [response, isMini]);

    useEffect(() => {
        resetPlayerAsset();

        fetchDetails();
        trackScreen('VodPlayer', { asset_id: assetId });
    }, [assetId]);

    useEffect(() => {
        fullLoad.start();

        if (isGuest) {
            openGuestAlert('/');
        }
    }, []);

    if (error) {
        return <PageErrorNotFound />;
    }

    return (
        <>
            {response && <HelmetWrapper {...getPlayerPageHelmetData(response)} />}
            <PlayerWrapper loading={loading} />
        </>
    );
};

export default withNetworkCheck(withPlayer(VodPlayerPage));
