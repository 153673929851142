import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold } from '../../../style/styled-components/cssGlobalVariables';

import { hexToRgba } from '../../../utils/fnColors';

export const ButtonWrapper = styled.div`
    outline: none;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    min-width: 120px;
    z-index: 1;
    padding: 0;
    font-family: ${fontBold};
`;

export const ButtonInner = styled.span<{ isPrimary: boolean; isSelected?: boolean; isDisabled?: boolean }>`
    padding: 12px 16px;
    line-height: 1;
    height: 100%;
    position: relative;
    display: block;
    white-space: nowrap;
    font-size: 16px;
    border-radius: 4px;
    color: ${props =>
        props.isDisabled ? hexToRgba(props.theme.brandedConfig.high_contrast, 0.5) : props.theme.brandedConfig.high_contrast};
    transition: all 0.2s ease-in-out;
    background: ${props =>
        props.isDisabled
            ? props.theme.brandedConfig.low_contrast
            : props.isPrimary
            ? props.theme.brandedConfig.primary
            : props.isSelected
            ? props.theme.brandedConfig.low_contrast
            : hexToRgba(props.theme.brandedConfig.black_color, 0.3)};
    border: ${props =>
        props.isPrimary || props.isSelected || props.isDisabled
            ? `2px solid transparent`
            : `2px solid ${hexToRgba(props.theme.brandedConfig.high_contrast, 0.3)}`};

    &:not(.no-hover):hover {
        color: ${props => props.theme.brandedConfig.high_contrast};
        background: ${props =>
            props.isDisabled
                ? props.theme.brandedConfig.low_contrast
                : props.isPrimary
                ? `${hexToRgba(props.theme.brandedConfig.primary, 0.7)}`
                : props.isSelected
                ? props.theme.brandedConfig.low_contrast
                : `${hexToRgba('#ebebf1', 0.15)}`};
        border: ${props =>
            props.isPrimary || props.isSelected || props.isDisabled
                ? `2px solid transparent`
                : `2px solid ${hexToRgba(props.theme.brandedConfig.high_contrast, 1)}`};
    }
`;
