import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { getNextEpisode } from 'utils/fnData';
import { VODAssetEntitlementState } from 'types/Entitlement';
import { getVodPlayerURL } from 'utils/fnUrl';
import { defaultLanguageTypes } from 'types/Asset';
import { useAuth } from 'providers/useAuth/AuthContext';
import { IPlayer } from './abstract/IPlayer';
import { BitrateTrack, PlayerError, PlayerEvents, PlayerProps, PlayingAsset, PlayingMode, Track } from '../../types/Player';
import { getLocalStorage, setLocalStorage } from '../../utils/fnStorage';
import Events from '../../utils/Events';
import { ControlGroup } from '../../components/Player/PlayerWrapper';
import { useConfig } from '../useConfig/ConfigContext';
import { useApp } from '../useApp/AppContext';
import { isCatchupPlayerAsset, isLivePlayerAsset, isRecordingPlayerAsset, isVodPlayerAsset } from '../../utils/fnTypeGuards';
import { useBookmark } from '../useBookmark/BookmarkContext';
import { MILLISECONDS } from '../../utils/TimeUnit';
import { useTrackingPlayback } from '../useTracking/TrackingContext';
import { PlaybackEvenTypes, YouboraOptions } from '../../types/Tracking';
import { useDataFetcher } from '../../hooks/useDataFetcher/useDataFetcher';
import Api from '../../api/Api';
import { NETWORK_ERROR_CODE } from './playerConstants';
import { FetchParamById } from '../../types/ApiTypes';
import { isSafari } from '../../utils/fnDevices';
import { useCast } from '../cast/CastContext';
import { PlayerProxy } from './PlayerProxy';
import PlaybackManager from './PlaybackManager';
import ApplicationConfig from '../useConfig/ApplicationConfig';
import YouboraTracking from './YouboraTracking';
import { useAssetReplayCapable } from '../../hooks/useAssetReplayCapable/useAssetReplayCapable';

const VOLUME_LOCAL_STORAGE = 'playerVolume';
const APPLY_JUMP_TIMEOUT = 500;
const CAST_TIMEOUT_AFTER_OPERATION = 8 * 1000;

const useThumbnail = () => {
    const [showThumbnail, setShowThumbnail] = useState<boolean>(false);
    const { config } = useConfig();

    const checkForThumbnails = useCallback(
        (engine: IPlayer) => {
            const isThumbnailActive = config?.isFeatureActive('thumbnail_scroll');
            const imageTrack = engine.getThumbnailTrack();
            setShowThumbnail(imageTrack != null && isThumbnailActive);
        },
        [config]
    );

    const getThumbnail = async (time: number) => PlaybackManager.getThumbnail(time);

    return {
        showThumbnail,
        checkForThumbnails,
        getThumbnail,
    };
};

const useMiniPlayer = () => {
    const [miniPlayerProps, setMiniPlayerProps] = useState<PlayerProps>(null);
    const [isMini, setIsMini] = useState<boolean>(false);

    const switchToMiniPlayer = (props: PlayerProps) => {
        setMiniPlayerProps(props);
        setIsMini(true);
    };

    const updateMiniPlayerProps = (props: PlayerProps) => {
        setMiniPlayerProps(props);
    };

    const closeMiniPlayer = () => {
        setMiniPlayerProps(null);
        setIsMini(false);
    };

    return {
        miniPlayerProps,
        isMini,
        switchToMiniPlayer,
        updateMiniPlayerProps,
        closeMiniPlayer,
    };
};

const usePlayerBookmark = (asset: PlayingAsset, playingMode: PlayingMode) => {
    const assetRef = useRef(asset);
    const playingModeRef = useRef(playingMode);
    const { saveBookmark, setOfflineBookmark } = useBookmark();

    useEffect(() => {
        assetRef.current = asset;
    }, [asset]);

    useEffect(() => {
        playingModeRef.current = playingMode;
    }, [playingMode]);

    const saveBookmarkPosition = useCallback((isOnline: boolean = true) => {
        if (
            assetRef.current &&
            (isCatchupPlayerAsset(assetRef.current) ||
                isRecordingPlayerAsset(assetRef.current) ||
                (isLivePlayerAsset(assetRef.current) && playingModeRef.current === PlayingMode.TIME_SHIFT) ||
                (isVodPlayerAsset(assetRef.current) && assetRef.current.titleId)) &&
            PlaybackManager.isPlayerLoaded()
        ) {
            let progress = PlaybackManager.getProgress() - PlaybackManager.getPrePadding();

            if (isCatchupPlayerAsset(assetRef.current) || isRecordingPlayerAsset(assetRef.current)) {
                const duration = MILLISECONDS.toSeconds(assetRef.current.endTime - assetRef.current.startTime);

                if (progress < 0) {
                    progress = 0;
                }

                if (progress > duration) {
                    progress = duration;
                }
            }

            if (isOnline) {
                saveBookmark(assetRef.current.titleId, progress);
            } else {
                setOfflineBookmark({ titleId: assetRef.current.titleId, progress });
            }
        }
        return null;
    }, []);

    return {
        saveBookmarkPosition,
    };
};

const useBingeWatching = (asset: PlayingAsset, config: typeof ApplicationConfig, isMini: boolean, dismissCallback) => {
    const [nextEpisode, setNextEpisode] = useState<any | null>(null);
    const [displayBingeWatch, setDisplayBingeWatch] = useState<boolean>(false);
    const [bingeWatchDuration, setBingeWatchDuration] = useState<any | null>(null);
    const [bingeWatchDismissed, setBingeWatchDismissed] = useState<boolean>(false);
    const [showNextEpisode, setShowNextEpisode] = useState<boolean>(false);

    const dismissed = useRef<boolean>(false);
    const display = useRef<boolean>(false);

    const nextEpisodeRef = useRef(null);
    const assetRef = useRef<PlayingAsset>(null);

    const history = useHistory();

    const { response: series, fetcher: fetchEpisodeDetails } = useDataFetcher<any, FetchParamById>(params => {
        return Api.fetchVodSeriesDetails(params.id);
    });

    const showBingeWatchingBanner = useCallback(() => {
        const next = nextEpisodeRef.current;
        const refAsset = assetRef.current;

        if (!isVodPlayerAsset(refAsset) || !next) {
            return;
        }

        if (isVodPlayerAsset(refAsset) && refAsset.isMovie) {
            return;
        }

        const { vodAssetState } = next;

        if (vodAssetState !== VODAssetEntitlementState.ENTITLED) {
            return;
        }

        const bannerTimeout = MILLISECONDS.toSeconds(config.app_config.player_settings.binge_watching_start_timeout);
        let thresholdSeconds = (config.app_config.player_settings.binge_watching_threshold / 100) * PlaybackManager.getDuration();

        const remainingTime = Math.floor(PlaybackManager.getDuration()) - Math.floor(PlaybackManager.getProgress());

        if (thresholdSeconds < bannerTimeout + 2) {
            thresholdSeconds = bannerTimeout + 2;
        }

        if (remainingTime && thresholdSeconds && remainingTime < thresholdSeconds && !display.current && !dismissed.current) {
            setDisplayBingeWatch(true);
            setBingeWatchDuration(bannerTimeout);
        }
    }, [config, asset]);

    const dismissBingeWatchingBanner = useCallback(
        (exitPlayer?: boolean) => {
            setBingeWatchDismissed(true);
            setDisplayBingeWatch(false);
            setShowNextEpisode(nextEpisode !== null);

            if (exitPlayer) {
                dismissCallback();
                history.goBack();
            }
        },
        [dismissCallback, history]
    );

    const loadNextEpisode = useCallback(() => {
        if (!isMini && nextEpisode) {
            setDisplayBingeWatch(false);
            dismissCallback();
            history.replace(getVodPlayerURL(nextEpisode.episodeId));
        }
    }, [nextEpisode, isMini]);

    const fetchNextEpisode = useCallback(() => {
        if (asset && isVodPlayerAsset(asset)) {
            const { isMovie, seriesId } = asset;
            setBingeWatchDismissed(false);

            if (isMovie) return;

            fetchEpisodeDetails({
                id: seriesId,
            });
        }
    }, [asset]);

    useEffect(() => {
        setDisplayBingeWatch(false);
        assetRef.current = asset;
        fetchNextEpisode();
    }, [asset]);

    useEffect(() => {
        if (asset && isVodPlayerAsset(asset) && series) {
            const { seasonNumber, episodeNumber } = asset;
            const next = getNextEpisode(series?.seasons, seasonNumber, episodeNumber);

            setNextEpisode(next);
            nextEpisodeRef.current = next;
        }
    }, [series]);

    useEffect(() => {
        display.current = displayBingeWatch;
        dismissed.current = bingeWatchDismissed;
    }, [displayBingeWatch, bingeWatchDismissed]);

    return {
        showBingeWatchingBanner,
        dismissBingeWatchingBanner,
        loadNextEpisode,
        nextEpisode,
        displayBingeWatch,
        bingeWatchDuration,
        setDisplayBingeWatch,
        showNextEpisode,
        setShowNextEpisode,
    };
};

const useVolume = (engine: IPlayer, castConnected: boolean) => {
    const volumeSetup = getLocalStorage(VOLUME_LOCAL_STORAGE);

    const [isMuted, setIsMuted] = useState<boolean>(volumeSetup?.muted ?? false);
    const [volume, setVolume] = useState<number>((volumeSetup?.level ?? 30) / 100);

    const volumeRef = useRef<number>(volume);
    const setVolumeTimeout = useRef<number>(null);

    const setVolumeLevel = (level: number) => {
        volumeRef.current = level;
        if (engine) {
            engine.setVolume(level);
        }

        let muted = false;

        if (level === 0) {
            muted = true;
            setIsMuted(muted);
        } else {
            setIsMuted(false);
        }

        setLocalStorage(VOLUME_LOCAL_STORAGE, {
            level: level * 100,
            muted,
        });

        setVolume(level);
    };

    const toggleMute = useCallback(() => {
        const muted = !isMuted;
        engine.mute(muted, true);
        setIsMuted(muted);

        if (!muted && volume === 0) {
            setVolumeLevel(0.5);
        }

        setLocalStorage(VOLUME_LOCAL_STORAGE, {
            level: volume * 100,
            muted,
        });
    }, [isMuted, volume, engine]);

    const onVolumeStateChange = useCallback(() => {
        if (castConnected) {
            clearTimeout(setVolumeTimeout.current);
            setVolumeTimeout.current = window.setTimeout(() => {
                if (volumeRef.current === engine.getVolume()) {
                    return;
                }
                setVolumeLevel(engine.getVolume());
            }, 250);
        }
    }, [castConnected, engine]);

    const initVolumeLevel = useCallback(() => {
        if (volumeSetup) {
            engine.setVolume(volumeSetup.level / 100);
            engine.mute(volumeSetup.muted, false);
        } else {
            engine.setVolume(0.5);
        }
    }, [engine]);

    return {
        setVolumeLevel,
        toggleMute,
        onVolumeStateChange,
        initVolumeLevel,
        volume,
        isMuted,
    };
};

const usePlayerService = () => {
    // storage values
    const appLanguageStorage = getLocalStorage('appLanguage');
    const primaryAudioLanguageStorage = getLocalStorage('primaryAudioLanguage');
    const secondaryAudioLanguageStorage = getLocalStorage('secondaryAudioLanguage');
    const primarySubtitlesLanguageStorage = getLocalStorage('primarySubtitlesLanguage');
    const secondarySubtitlesLanguageStorage = getLocalStorage('secondarySubtitlesLanguage');
    const accessibilityStorage = getLocalStorage('accessibility');

    // states
    const [playerEngine, setPlayerEngine] = useState<IPlayer | null>(null);
    const [asset, setAsset] = useState<PlayingAsset>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [initialized, setInitialized] = useState<boolean>(true);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [isBuffering, setIsBuffering] = useState<boolean>(false);
    const [initCasting, setInitCasting] = useState<boolean>(false);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [activeControlGroup, setActiveControlGroup] = useState<ControlGroup>(ControlGroup.BASE);
    const [playerError, setPlayerError] = useState<PlayerError | null>(null);
    const [hasMultipleTracks, setHasMultipleTracks] = useState<boolean>(false);
    const [canStartOver, setCanStartOver] = useState<boolean>(false);
    const [streamEndReached, setStreamEndReached] = useState<boolean>(false);
    const [playingMode, setPlayingMode] = useState<PlayingMode>(PlayingMode.NORMAL);
    const [toJumpValue, setToJumpValue] = useState<number>(0);
    const [isEmbedded, setIsEmbedded] = useState<boolean>(false);

    const [audioTrack, setAudioTrack] = useState<Track | null>(null);
    const [subtitleTrack, setSubtitleTrack] = useState<Track | null>(null);
    const [bitrateTrack, setBitrateTrack] = useState<BitrateTrack>(null);

    const [primaryAudioLanguage, setPrimaryAudioLanguage] = useState<string>(primaryAudioLanguageStorage || appLanguageStorage || 'et');
    const [secondaryAudioLanguage, setSecondaryAudioLanguage] = useState<string>(secondaryAudioLanguageStorage || 'en');
    const [primarySubtitlesLanguage, setPrimarySubtitlesLanguage] = useState<string>(
        primarySubtitlesLanguageStorage || appLanguageStorage || 'et'
    );
    const [secondarySubtitlesLanguage, setSecondarySubtitlesLanguage] = useState<string>(secondarySubtitlesLanguageStorage || 'en');
    const [accessibility, setAccessibility] = useState<boolean>(accessibilityStorage || false);

    // refs
    const events = useRef<Events>(new Events());
    const engine = useRef<IPlayer | null>(null);
    const applyJumpTimeoutRef = useRef<number | null>(null);
    const toJumpValueRef = useRef<number | null>(0);
    const errorRef = useRef<PlayerError | undefined>();
    const lastCastEmitTimestampRef = useRef<number>(0);

    const audioTrackRef = useRef<Track | null>(null);
    const subtitleTrackRef = useRef<Track | null>(null);
    const bitrateTrackRef = useRef<BitrateTrack>(null);

    const selectedTrack = useRef<{ audio: Track; subtitle: Track; bitrate: BitrateTrack }>({
        audio: null,
        subtitle: null,
        bitrate: null,
    });

    const preferredAudioTrack = useRef<Track | null>(null);
    const preferredSubtitleTrack = useRef<Track | null>(null);
    const preferredBitrateTrack = useRef<BitrateTrack>(null);

    const playAfterLoad = useRef<boolean>(true);
    const isPlayed = useRef<boolean>(true);
    const emitTimeShiftEndedEvent = useRef<boolean>(true);
    const seekTimeoutRef = useRef<number | null>(null);
    const saveBookmarkIntervalRef = useRef<number>();

    const prevPlayingMode = useRef<PlayingMode>(playingMode);
    const prevAsset = useRef<PlayingAsset>(asset);

    const { config } = useConfig();
    const { isReplayCapable } = useAssetReplayCapable(asset);
    const { appLanguage } = useApp();
    const { userInfoDetails } = useAuth();
    const playbackTracker = useTrackingPlayback();
    const { castConnected } = useCast();
    const { showThumbnail, checkForThumbnails, getThumbnail } = useThumbnail();
    const { isMini, miniPlayerProps, switchToMiniPlayer, updateMiniPlayerProps, closeMiniPlayer } = useMiniPlayer();
    const { saveBookmarkPosition } = usePlayerBookmark(asset, playingMode);
    const history = useHistory();

    const {
        showBingeWatchingBanner,
        dismissBingeWatchingBanner,
        loadNextEpisode,
        nextEpisode,
        displayBingeWatch,
        bingeWatchDuration,
        setDisplayBingeWatch,
        showNextEpisode,
        setShowNextEpisode,
    } = useBingeWatching(asset, config, isMini, () => {
        setStreamEndReached(true);
    });
    const { onVolumeStateChange, initVolumeLevel, setVolumeLevel, toggleMute, volume, isMuted } = useVolume(playerEngine, castConnected);

    const track = useCallback(
        (event: PlaybackEvenTypes) => {
            if (!asset && !playerEngine) {
                // playing asset or engine not yet initialized
                return;
            }

            const { type, id, title } = asset ?? {};
            let channelId;

            if (asset && (isLivePlayerAsset(asset) || isCatchupPlayerAsset(asset) || isRecordingPlayerAsset(asset))) {
                channelId = asset.channelId;
            }

            const { audio, subtitle } = selectedTrack.current ?? {};

            playbackTracker(event, {
                asset_id: id,
                asset_name: title,
                playback_mode: type,
                // eslint-disable-next-line no-use-before-define
                position: PlaybackManager.getProgress(),
                audio_language: audio?.language,
                subtitle_language: subtitle?.language,
                channel_id: channelId,
                drm_type: playerEngine.getDRMType(),
                playback_error: errorRef.current?.code,
            });
        },
        [asset, playbackTracker, playerEngine]
    );

    const getProgress = (): number => PlaybackManager.getProgress();

    const setEngine = (toSetEngine: IPlayer) => {
        const proxyEngine = new PlayerProxy(toSetEngine);
        setPlayerEngine(proxyEngine);
    };

    const getSeekMax = (): number => PlaybackManager.getSeekMax();

    const getProgressWithJump = useCallback((): number => {
        if (!isLivePlayerAsset(asset)) {
            return getProgress() + toJumpValueRef.current;
        }

        const seekMax = getSeekMax();
        const progress = getProgress();

        return getSeekMax() > 0 && progress + toJumpValueRef.current > seekMax ? seekMax : progress + toJumpValueRef.current;
    }, [asset]);

    const setPlayingState = useCallback(
        async (playing, timeShiftPlayed = false) => {
            if (playerError) return;

            isPlayed.current = playing;

            if (playing) {
                await PlaybackManager.setPlayingState(playing, timeShiftPlayed);
                track('playback_start');
            } else {
                await PlaybackManager.setPlayingState(playing);
                track('playback_pause');
            }

            setIsPlaying(playing);
        },
        [asset, playerError, playingMode]
    );

    const getPrePadding = (): number => PlaybackManager.getPrePadding();

    const canStartOverHandler = useCallback(
        (position: number) => {
            if (config) {
                setCanStartOver(position > MILLISECONDS.toSeconds(config.app_config.player_settings.startover_min_position_ms));
            }
        },
        [config]
    );

    const seek = async (to: number) => {
        if (to && !playerError) await PlaybackManager.seek(to);
    };

    const seekStart = useCallback(() => {}, [playerError]);

    const seekEnd = useCallback(() => {
        if (playerError) return;

        track('playback_seek');
    }, [playerError]);

    const startOver = () => PlaybackManager.startOver();

    const jumpToLive = useCallback(async () => {
        if (isCatchupPlayerAsset(asset)) {
            events.current.triggerEvents(PlayerEvents.JUMP_TO_LIVE, null);
        } else {
            await PlaybackManager.switchToNormalMode();
        }
    }, [asset]);

    const setBufferingState = useCallback(
        event => {
            setIsBuffering(event.buffering);
        },
        [setIsBuffering]
    );

    const onWaiting = useCallback(() => {
        if (!isSafari()) {
            setIsBuffering(true);
        }
    }, [setIsBuffering]);

    const playStateChanged = useCallback(
        e => {
            if (!castConnected) {
                setIsBuffering(false);
            }
            if (e && (e.type === 'play' || e.type === 'pause')) {
                setIsPlaying(e.type === 'play');
                setStreamEndReached(false);
            }
        },
        [setIsBuffering, setIsPlaying, castConnected]
    );

    const endOfStreamReached = useCallback(() => {
        events.current.triggerEvents(PlayerEvents.END_OF_STREAM, null);
        setStreamEndReached(true);
        track('playback_end');
    }, []);

    const onlineStateChanged = useCallback(() => {
        if (playerError && playerError.code === NETWORK_ERROR_CODE) {
            setPlayerError(null);

            // if there was a network error and the current asset is live seek to current live position
            if (isLivePlayerAsset(asset) && playerEngine && playingMode === PlayingMode.NORMAL) {
                playerEngine.seekTo(0);
            }
        }
    }, [asset, playingMode, playerEngine, playerError]);

    const getAudioLanguages = (): Track[] => engine.current?.getAudioLanguages() ?? [];

    const getTextLanguages = (): Track[] => engine.current?.getTextLanguages() ?? [];

    const getBitrateTracks = (): BitrateTrack[] => {
        return engine.current?.getBitrateTracks() ?? [];
    };

    const getPreferredBitrateTrack = (): any => {
        return engine.current?.getSelectedBitrateTrack() ?? preferredBitrateTrack.current;
    };

    const getPreferredAudio = (): any => {
        return engine.current?.getSelectedLanguage() ?? preferredAudioTrack.current;
    };

    const getPreferredSubtitle = (): any => engine.current?.getSelectedSubtitle() ?? preferredSubtitleTrack.current;

    const initAudioSubtitle = () => {
        if (engine.current.getAudioLanguages().length) {
            const preferred = getPreferredAudio();
            if (preferred) {
                if (audioTrackRef.current?.language !== preferred?.language || audioTrackRef.current?.role !== preferred?.role) {
                    setAudioTrack(preferred);
                }
                preferredAudioTrack.current = null;
            } else {
                const audioTracks = getAudioLanguages();

                // use first available audio track
                if (!audioTrackRef.current && audioTracks.length) {
                    const mainAppLanguageTrack = audioTracks.find(
                        audio => audio.language === appLanguage && (audio.role == null || audio.role === 'main')
                    );
                    setAudioTrack(mainAppLanguageTrack || audioTracks[0]);
                }
            }
        }

        if (engine.current.getTextLanguages().length) {
            const preferred = getPreferredSubtitle();
            if (preferred) {
                if (subtitleTrackRef.current?.language !== preferred?.language || subtitleTrackRef.current?.role !== preferred?.role) {
                    setSubtitleTrack(preferred);
                }
                preferredSubtitleTrack.current = null;
            } else {
                const subtitleTracks = getTextLanguages();

                // item 0 is always NONE option
                if (!subtitleTrackRef.current && subtitleTracks.length) {
                    const activeSubtitle = subtitleTracks.find(subtitle => subtitle.raw?.active === true);
                    setSubtitleTrack(activeSubtitle || subtitleTracks[0]);
                }
            }
        }

        if (engine.current.getBitrateTracks()?.length) {
            const preferred = getPreferredBitrateTrack();
            if (preferred) {
                if (bitrateTrackRef.current?.height !== preferred?.height) {
                    setBitrateTrack(preferred);
                }
                preferredBitrateTrack.current = null;
            } else {
                const bitrateTracks = getBitrateTracks();
                let activeBitrateTrack: BitrateTrack;

                // use first available bitrate track
                if (!bitrateTrackRef.current && bitrateTracks.length) {
                    activeBitrateTrack = bitrateTracks.find(bitrate => bitrate.raw?.active === true);
                }
                setBitrateTrack(activeBitrateTrack || bitrateTracks[0]);
            }
        }
    };

    const onTracksChanged = useCallback(() => {
        initAudioSubtitle();
        setHasMultipleTracks(Object.keys(getAudioLanguages()).length > 1 || Object.keys(getTextLanguages()).length > 1);
        checkForThumbnails(engine.current);
    }, [setHasMultipleTracks, checkForThumbnails]);

    const getDuration = (): number => PlaybackManager.getDuration();

    const getPostPadding = (): number => PlaybackManager.getPostPadding();

    const getSecondaryProgress = (): number => PlaybackManager.getSecondaryProgress();

    const getSecondaryProgressWithJump = useCallback(
        (): number => getSecondaryProgress() + (isLivePlayerAsset(asset) ? 0 : toJumpValueRef.current),
        [asset]
    );

    const canUpdateProgress = (): boolean => true;

    const timeUpdatedListener = useCallback(() => {
        if (castConnected) {
            if (lastCastEmitTimestampRef.current) {
                if (Date.now() < lastCastEmitTimestampRef.current + CAST_TIMEOUT_AFTER_OPERATION) {
                    return;
                }
            }
        }

        events.current.triggerEvents(PlayerEvents.TIME_UPDATE, null);

        canStartOverHandler(getProgress());
        showBingeWatchingBanner();
    }, [castConnected, canStartOverHandler, showBingeWatchingBanner]);

    const setAudioLanguage = (audio: Track) => {
        selectedTrack.current.audio = audio;
        setAudioTrack(audio);

        track('playback_switch_audio');
    };

    const setTextLanguage = (subtitle: Track) => {
        selectedTrack.current.subtitle = subtitle;
        setSubtitleTrack(subtitle);

        track('playback_switch_subtitle');
    };

    const setBitrateStream = (bitrate: BitrateTrack) => {
        selectedTrack.current.bitrate = bitrate;
        setBitrateTrack(bitrate);

        track('playback_switch_bitrate_track');
    };

    const jump = (value: number, withTimeout = true) => {
        setToJumpValue(getSeekMax() === 0 || toJumpValue + value < getSeekMax() ? toJumpValue + value : getSeekMax());

        if (applyJumpTimeoutRef.current) {
            clearTimeout(applyJumpTimeoutRef.current);
        }

        const jumpFn = async () => {
            await seek(
                getSeekMax() === 0 || getProgress() + toJumpValueRef.current < getSeekMax()
                    ? getProgress() + toJumpValueRef.current
                    : getSeekMax()
            );
            clearTimeout(applyJumpTimeoutRef.current);

            setToJumpValue(0);
            track(value > 0 ? 'playback_skip_fwd' : 'playback_skip_rwd');
        };

        if (!withTimeout) {
            jumpFn().catch(() => {});
            return;
        }

        applyJumpTimeoutRef.current = window.setTimeout(jumpFn, APPLY_JUMP_TIMEOUT);
    };

    const attachPlayerListeners = useCallback(() => {
        if (playerEngine) {
            playerEngine.addEventListener(PlayerEvents.BUFFERING, setBufferingState);
            playerEngine.addEventListener(PlayerEvents.WAITING, onWaiting);
            playerEngine.addEventListener(PlayerEvents.VOLUME_CHANGE, onVolumeStateChange);
            playerEngine.addEventListener(PlayerEvents.PLAY, playStateChanged);
            playerEngine.addEventListener(PlayerEvents.BUFFER_END, playStateChanged);
            playerEngine.addEventListener(PlayerEvents.PLAYING, playStateChanged);
            playerEngine.addEventListener(PlayerEvents.PAUSE, playStateChanged);
            playerEngine.addEventListener(PlayerEvents.ENDED, endOfStreamReached);
            playerEngine.addEventListener(PlayerEvents.TRACKS_CHANGED, onTracksChanged);
            playerEngine.addEventListener(PlayerEvents.TIME_UPDATE, timeUpdatedListener);
        }
    }, [
        playerEngine,
        setBufferingState,
        onWaiting,
        onVolumeStateChange,
        playStateChanged,
        endOfStreamReached,
        onTracksChanged,
        timeUpdatedListener,
    ]);

    const detachPlayerListeners = useCallback(() => {
        if (playerEngine) {
            playerEngine.removeEventListener(PlayerEvents.BUFFERING, setBufferingState);
            playerEngine.removeEventListener(PlayerEvents.WAITING, onWaiting);
            playerEngine.removeEventListener(PlayerEvents.VOLUME_CHANGE, onVolumeStateChange);
            playerEngine.removeEventListener(PlayerEvents.PLAY, playStateChanged);
            playerEngine.removeEventListener(PlayerEvents.BUFFER_END, playStateChanged);
            playerEngine.removeEventListener(PlayerEvents.PLAYING, playStateChanged);
            playerEngine.removeEventListener(PlayerEvents.PAUSE, playStateChanged);
            playerEngine.removeEventListener(PlayerEvents.ENDED, endOfStreamReached);
            playerEngine.removeEventListener(PlayerEvents.TRACKS_CHANGED, onTracksChanged);
            playerEngine.removeEventListener(PlayerEvents.TIME_UPDATE, timeUpdatedListener);
        }
    }, [
        playerEngine,
        setBufferingState,
        onWaiting,
        onVolumeStateChange,
        playStateChanged,
        endOfStreamReached,
        onTracksChanged,
        timeUpdatedListener,
    ]);

    const release = async () => {
        saveBookmarkPosition(errorRef.current == null || errorRef.current?.code !== NETWORK_ERROR_CODE);

        PlaybackManager.setPlayingMode(PlayingMode.NORMAL);

        if (engine.current) {
            detachPlayerListeners();
        }

        PlaybackManager.destroy();

        setPlayerEngine(null);
        setAsset(null);
        setHasMultipleTracks(false);
        setPlayerError(null);
        setLoaded(false);
        setIsPlaying(false);
        setCanStartOver(false);
        playAfterLoad.current = true;

        emitTimeShiftEndedEvent.current = true;
        setAudioTrack(null);
        setSubtitleTrack(null);

        audioTrackRef.current = null;
        subtitleTrackRef.current = null;

        preferredAudioTrack.current = null;
        preferredSubtitleTrack.current = null;
        preferredBitrateTrack.current = null;

        if (applyJumpTimeoutRef.current) {
            clearTimeout(applyJumpTimeoutRef.current);
        }

        if (seekTimeoutRef.current) {
            clearTimeout(seekTimeoutRef.current);
        }
    };

    const resetPlayerAsset = (playMode?: PlayingMode) => {
        setPlayerError(null);
        setAsset(null);
        setCanStartOver(null);

        setPlayingMode(playMode ?? PlayingMode.NORMAL);
        PlaybackManager.setPlayingMode(playMode ?? PlayingMode.NORMAL);

        emitTimeShiftEndedEvent.current = true;
        preferredAudioTrack.current = null;
        preferredSubtitleTrack.current = null;
        preferredBitrateTrack.current = null;

        if (seekTimeoutRef.current) {
            clearTimeout(seekTimeoutRef.current);
        }
    };

    const addEventListener = (type: PlayerEvents, callback: () => void) => {
        events.current.addEventListener(type, callback);
    };

    const removeEventListener = (type: PlayerEvents, callback: () => void) => {
        events.current.removeEventListener(type, callback);
    };

    const resetError = () => {
        setPlayerError(null);
    };

    const changeDefaultAudioLanguage = (type: defaultLanguageTypes, toSetLanguage: string) => {
        if (type === defaultLanguageTypes.PRIMARY) {
            setPrimaryAudioLanguage(toSetLanguage);
        }
        if (type === defaultLanguageTypes.SECONDARY) {
            setSecondaryAudioLanguage(toSetLanguage);
        }
    };

    const changeDefaultSubtitlesLanguage = (type: defaultLanguageTypes, toSetLanguage: string) => {
        if (type === defaultLanguageTypes.PRIMARY) {
            setPrimarySubtitlesLanguage(toSetLanguage);
        }
        if (type === defaultLanguageTypes.SECONDARY) {
            setSecondarySubtitlesLanguage(toSetLanguage);
        }
    };

    useEffect(() => {
        setLocalStorage('primaryAudioLanguage', primaryAudioLanguage);
    }, [primaryAudioLanguage]);

    useEffect(() => {
        setLocalStorage('secondaryAudioLanguage', secondaryAudioLanguage);
    }, [secondaryAudioLanguage]);

    useEffect(() => {
        setLocalStorage('primarySubtitlesLanguage', primarySubtitlesLanguage);
    }, [primarySubtitlesLanguage]);

    useEffect(() => {
        setLocalStorage('secondarySubtitlesLanguage', secondarySubtitlesLanguage);
    }, [secondarySubtitlesLanguage]);

    useEffect(() => {
        setLocalStorage('accessibility', accessibility);
    }, [accessibility]);

    useEffect(() => {
        if (isBuffering) {
            track('playback_buffer');
        } else if (isPlaying) {
            track('playback_start');
        }
    }, [isBuffering]);

    useEffect(() => {
        if (isPlaying && !saveBookmarkIntervalRef.current) {
            saveBookmarkIntervalRef.current = window.setInterval(() => {
                saveBookmarkPosition(playerError?.code !== NETWORK_ERROR_CODE);
            }, config.app_config.player_settings.bookmark_save_interval);
        }

        if (playerError || streamEndReached) {
            saveBookmarkPosition(playerError?.code !== NETWORK_ERROR_CODE);
        }

        if ((!isPlaying || playerError || streamEndReached) && saveBookmarkIntervalRef.current) {
            clearInterval(saveBookmarkIntervalRef.current);
            saveBookmarkIntervalRef.current = null;
        }
    }, [isPlaying, playerError, saveBookmarkPosition, streamEndReached]);

    useEffect(() => {
        return () => {
            clearInterval(saveBookmarkIntervalRef.current);
        };
    }, []);

    useEffect(() => {
        if (playerError?.code === NETWORK_ERROR_CODE) {
            PlaybackManager.setPlayingState(false);
        }
    }, [playerError]);

    useEffect(() => {
        if (engine.current) {
            engine.current?.setAudioLanguage(audioTrack);
            selectedTrack.current.audio = audioTrack;
        }
    }, [audioTrack]);

    useEffect(() => {
        if (engine.current) {
            setTimeout(() => engine.current?.setTextLanguage(subtitleTrack), 100);
            selectedTrack.current.subtitle = subtitleTrack;
        }
    }, [subtitleTrack]);

    useEffect(() => {
        if (engine.current) {
            setTimeout(() => engine.current?.setBitrateTrack(bitrateTrack), 100);
            selectedTrack.current.bitrate = bitrateTrack;
        }
    }, [bitrateTrack]);

    useEffect(() => {
        if (asset && playerEngine) {
            setLoaded(true);
            PlaybackManager.setEngine(playerEngine);
            PlaybackManager.updatePlayingAsset(asset).catch(() => {});
        }
    }, [asset, playerEngine]);

    useEffect(() => {
        if (engine.current) {
            initVolumeLevel();
        }

        if (!castConnected) {
            setAudioTrack(null);
            setSubtitleTrack(null);
        }

        setInitCasting(castConnected);
        PlaybackManager.setCastConnected(castConnected);
    }, [castConnected]);

    useEffect(() => {
        toJumpValueRef.current = toJumpValue;
    }, [toJumpValue]);

    useEffect(() => {
        // necessary reference for the callbacks
        errorRef.current = playerError;
        track('playback_error');
    }, [playerError]);

    useEffect(() => {
        PlaybackManager.setPlayingMode(playingMode);
    }, [playingMode]);

    useEffect(() => {
        if (isLivePlayerAsset(asset)) {
            if (playingMode === PlayingMode.TIME_SHIFT) {
                const url = new URL(window.location.href);
                if (url.searchParams.get('live') === 'true') {
                    url.searchParams.delete('live');
                    history.replace(url.search || url);
                }
            } else {
                const url = new URL(window.location.href);
                url.searchParams.set('live', 'true');

                history.replace(url.search);
            }
        }
    }, [playingMode]);

    useEffect(() => {
        subtitleTrackRef.current = subtitleTrack;
        audioTrackRef.current = audioTrack;
        bitrateTrackRef.current = bitrateTrack;
    }, [subtitleTrack, audioTrack, bitrateTrack]);

    useEffect(() => {
        attachPlayerListeners();
        return () => detachPlayerListeners();
    }, [attachPlayerListeners, detachPlayerListeners]);

    useEffect(() => {
        engine.current = playerEngine;

        if (playerEngine) {
            PlaybackManager.setEngine(playerEngine);

            PlaybackManager.setAssetChangedListener(newAsset => {
                setAsset(newAsset);
            });

            PlaybackManager.setCastInitialisedListener(() => {
                setInitCasting(false);
            });

            PlaybackManager.setPlaybackModeChangeListener(mode => {
                setPlayingMode(mode);
            });

            if (engine.current) {
                initVolumeLevel();
                setInitialized(true);
            }
        }
    }, [playerEngine]);

    useEffect(() => {
        window.addEventListener('online', onlineStateChanged);

        return () => {
            window.removeEventListener('online', onlineStateChanged);
        };
    }, [onlineStateChanged]);

    useEffect(() => {
        if (streamEndReached) {
            engine.current?.getYoubora()?.fireStop();
            engine.current?.getYoubora()?.setOptions({
                enabled: false,
            });
        } else {
            engine.current?.getYoubora()?.setOptions({
                enabled: true,
            });
        }
    }, [streamEndReached]);

    useEffect(() => {
        const { subscription } = userInfoDetails || {};

        if (subscription && asset && engine.current) {
            const youboraOptions: YouboraOptions = {
                enabled: true,
                'user.name': subscription,
                'content.title': asset?.title,
                'content.playbackType': YouboraTracking.getPlaybackType(asset, playingMode),
                'content.episodeTitle': asset?.episodeName,
                'content.isLive': isLivePlayerAsset(asset),
            };

            if (isLivePlayerAsset(asset) || isCatchupPlayerAsset(asset)) {
                youboraOptions['content.channel'] = asset.channelId;
            }

            engine.current?.getYoubora()?.setOptions(youboraOptions);
        }

        if (prevAsset.current?.id !== asset?.id) {
            engine.current?.getYoubora()?.fireStop();
        } else if (isLivePlayerAsset(asset) && prevPlayingMode.current !== playingMode) {
            engine.current?.getYoubora()?.fireStop();
        }

        prevPlayingMode.current = playingMode;
        prevAsset.current = asset;
    }, [asset, playingMode, userInfoDetails]);

    useEffect(() => {
        if (asset && isLivePlayerAsset(asset)) {
            const { channelId } = asset;

            setLocalStorage('lastPlayedChannel', channelId);
        }
    }, [asset]);

    useEffect(() => {
        PlaybackManager.setIsReplayCapable(isReplayCapable);
    }, [isReplayCapable]);

    return {
        initialized,
        setEngine,
        asset,
        setAsset,
        release,
        resetPlayerAsset,
        loaded,
        setPlayingMode,
        playingMode,
        engine,
        playerEngine,
        isOpen,
        setIsOpen,
        setStreamEndReached,
        streamEndReached,
        nextEpisode,
        displayBingeWatch,
        bingeWatchDuration,
        dismissBingeWatchingBanner,
        setDisplayBingeWatch,
        loadNextEpisode,
        isEmbedded,
        setIsEmbedded,

        getDuration,
        getProgress,
        getSecondaryProgress,
        getProgressWithJump,
        getSecondaryProgressWithJump,
        canUpdateProgress,

        saveBookmarkPosition,
        getPrePadding,
        getPostPadding,
        getSeekMax,

        setActiveControlGroup,
        activeControlGroup,

        getTextLanguages,
        getAudioLanguages,
        getBitrateTracks,
        setAudioLanguage,
        setTextLanguage,
        setBitrateStream,
        audioTrack,
        subtitleTrack,
        bitrateTrack,
        hasMultipleTracks,

        isPlaying,
        isBuffering: isBuffering || initCasting,
        volume,
        isMuted,
        canStartOver,
        seekStart,
        seekEnd,
        seek,
        getThumbnail,
        showThumbnail,
        jump,
        toggleMute,
        setPlayingState,
        startOver,
        jumpToLive,
        setVolumeLevel,
        addEventListener,
        removeEventListener,

        playerError,
        setPlayerError,
        resetError,

        switchToMiniPlayer,
        closeMiniPlayer: () => {
            closeMiniPlayer();
            // setStreamEndReached(true);
        },
        updateMiniPlayerProps,
        miniPlayerProps,
        isMini,

        showNextEpisode,
        setShowNextEpisode,

        primaryAudioLanguage,
        secondaryAudioLanguage,
        primarySubtitlesLanguage,
        secondarySubtitlesLanguage,
        accessibility,
        changeDefaultAudioLanguage,
        changeDefaultSubtitlesLanguage,
        setAccessibility,
    };
};

export const PlayerContext = createContext<ReturnType<typeof usePlayerService>>(null);

export const PlayerProvider = ({ children }) => {
    const player = usePlayerService();
    return <PlayerContext.Provider value={player}>{children}</PlayerContext.Provider>;
};

export const usePlayer = () => useContext(PlayerContext);
