import { RegisterDevice } from '../../../types/CommonTypes';
import { DRMToken, RegisterDeviceResponse } from '../../../types/AuthTypes';
import { FetchResponse } from '../../../types/ApiTypes';

export abstract class IDRM {
    abstract handleWidevine: () => void;

    abstract handlePlayReady: () => void;

    abstract release: () => void;

    abstract stopKeepAlive: () => void;

    abstract keepAlive: () => void;

    abstract getToken: (deviceToRemove: string) => void;

    abstract detectSupport: () => void;

    abstract getConfiguration: () => any;

    abstract getAdditionalConfiguration: () => Promise<{ [key: string]: any }>;

    abstract getHeaders: () => void;

    abstract getRequestFilter: (type, request) => void;

    abstract getResponseFilter: (type, response) => void;

    abstract getDRMType: () => string;

    abstract configurePlayer: (playerInstance: any) => any;

    abstract init: (
        registerDevice: (url: string, payload: RegisterDevice) => Promise<FetchResponse<RegisterDeviceResponse>>,
        requestToken: (url: string, method: 'POST' | 'PUT', payload: string, canceller?) => Promise<FetchResponse<DRMToken>>,
        closeSession: (url: string, payload: string, canceller?) => Promise<FetchResponse<any>>,
        deviceRegistrationCb: (deviceId: string, error) => void
    ) => void;

    abstract subscribeErrorHandler: (handler: (error) => void) => void;
}
