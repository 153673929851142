import React, { FC, useEffect } from 'react';
import { useDataFetcher } from '../../../hooks/useDataFetcher/useDataFetcher';
import { PlayerEvents, TrailerPlayerAssetInfo } from '../../../types/Player';
import Api from '../../../api/Api';
import { usePlayer } from '../../../providers/player/PlayerContext';
import { PageErrorNotFound } from '../PageErrorNotFound/PageErrorNotFound';
import { PlayerWrapper } from '../../Player/PlayerWrapper';
import { useNavigation } from '../../../hooks/useNavigation';
import { useKPI, useTrackingScreen } from '../../../providers/useTracking/TrackingContext';
import { useAuth } from '../../../providers/useAuth/AuthContext';
import { FetchParamById } from '../../../types/ApiTypes';
import { useGlobalNetworkError, useGlobalNoInternet, withNetworkCheck } from '../../../hooks/withNetworkCheck/withNetworkCheck';
import { HelmetWrapper } from '../../HelmetWrapper/HelmetWrapper';
import { getPlayerPageHelmetData } from '../../../utils/fnHelmet';
import { withPlayer } from '../../../hooks/withPlayer/withPlayer';

const TrailerPlayerPage: FC<{ assetId: string }> = ({ assetId }) => {
    const { onNoInternet } = useGlobalNoInternet();
    const { onNetworkError } = useGlobalNetworkError();
    const { response, error, loading, fetcher } = useDataFetcher<TrailerPlayerAssetInfo, FetchParamById>(
        params => Api.fetchTrailerPlayerInfo(params.id),
        onNoInternet,
        onNetworkError
    );

    const { isGuest, openGuestAlert } = useAuth();
    const { setAsset, addEventListener, removeEventListener, resetPlayerAsset, isMini, closeMiniPlayer, loaded } = usePlayer();
    const { onBackPressed } = useNavigation();
    const trackScreen = useTrackingScreen(() => true, [assetId]);
    const { fullLoad } = useKPI('TrailerPlayer');

    const fetchDetails = () => {
        if (!isGuest) {
            fetcher({ id: assetId });
        }
    };

    const onStreamEndReached = () => {
        if (isMini) {
            closeMiniPlayer();
        } else {
            onBackPressed();
        }
    };

    useEffect(() => {
        if (loaded) {
            fullLoad.stop();
        }
    }, [loaded]);

    useEffect(() => {
        resetPlayerAsset();
        fetchDetails();
        trackScreen('TrailerPlayer', { asset_id: assetId });
    }, [assetId]);

    useEffect(() => {
        fullLoad.start();

        if (isGuest) {
            openGuestAlert('/');
        }
    }, []);

    useEffect(() => {
        if (response) {
            setAsset(response);
        }
    }, [response]);

    useEffect(() => {
        addEventListener(PlayerEvents.END_OF_STREAM, onStreamEndReached);

        return () => {
            removeEventListener(PlayerEvents.END_OF_STREAM, onStreamEndReached);
        };
    }, [response, isMini]);

    if (error) {
        return <PageErrorNotFound />;
    }

    return (
        <>
            {response && <HelmetWrapper {...getPlayerPageHelmetData(response)} />}
            <PlayerWrapper loading={loading} />
        </>
    );
};

export default withNetworkCheck(withPlayer(TrailerPlayerPage));
