import Button from 'components/Buttons/Button/Button';
import { usePlayer } from 'providers/player/PlayerContext';
import React, { FC, useEffect, useRef, useState } from 'react';
import { CardImage } from 'style/styled-components/reusable.css';
import { getLocalStorage } from 'utils/fnStorage';
import translate from 'utils/fnTranslate';
import fallbackLandscape from '../../assets/images/landscape_fallback.png';
import { BingeWatchingContainer, BingeWatchingCover, BingeWatchingDetails, Buttons, Description, Title } from './BingeWatchingBanner.css';

type props = {
    title: string;
    timeLeft: number;
    episodeNumber: number;
    seasonNumber: number;
    image: string;
    loadNextEpisode: () => void;
    dismissBanner: (arg?: boolean) => void;
    showLimitNotification: boolean;
};

export const BingeWatchingBanner: FC<props> = ({
    title,
    timeLeft,
    episodeNumber,
    seasonNumber,
    image,
    showLimitNotification = false,
    loadNextEpisode,
    dismissBanner,
}) => {
    const { setDisplayBingeWatch, setShowNextEpisode } = usePlayer();
    const allowAutoPlay = getLocalStorage('autoPlay');
    const DEFAULT_AUTOPLAY = allowAutoPlay === null;
    const showAutoPlay = allowAutoPlay || DEFAULT_AUTOPLAY;

    const [timeOut, setTimeOut] = useState(timeLeft);
    const updateTimerRef = useRef(null);

    useEffect(() => {
        if (!showAutoPlay || showLimitNotification) {
            return;
        }

        if (updateTimerRef.current) {
            clearInterval(updateTimerRef.current);
        }

        updateTimerRef.current = setInterval(() => {
            if (timeOut <= 1) {
                setTimeOut(0);
                clearInterval(updateTimerRef.current);
                loadNextEpisode();
                return;
            }
            setTimeOut(prevTimeout => prevTimeout - 1);
        }, 1000);
    }, [timeOut]);

    useEffect(() => {
        return () => {
            setDisplayBingeWatch(false);
            clearInterval(updateTimerRef.current);
        };
    }, []);

    useEffect(() => {
        if (!showAutoPlay) {
            setShowNextEpisode(true);
        }
    }, [showAutoPlay]);

    const getData = () => {
        let bannerTitle;
        let bannerSubtitle;

        if (showLimitNotification) {
            bannerTitle = translate('BINGE_WATCHING_LIMIT_TITLE');
            bannerSubtitle = translate('BINGE_WATCHING_LIMIT_HINT');
        } else {
            bannerTitle = title;

            if (!showAutoPlay) {
                bannerSubtitle = `${translate('BINGE_WATCHING_NO_AUTOPLAY')} ${seasonNumber ? translate('HOVER_SEASON') + seasonNumber : ''}
                 ${episodeNumber ? translate('HOVER_EPISODE') + episodeNumber : ''}`;
            } else {
                bannerSubtitle =
                    seasonNumber || episodeNumber
                        ? `${seasonNumber ? translate('HOVER_SEASON') + seasonNumber : ''}
                  ${episodeNumber ? translate('HOVER_EPISODE') + episodeNumber : ''}  ${translate('BINGE_WATCHING_HINT', {
                              count: timeOut,
                          })} `
                        : translate('BINGE_WATCHING_HINT_FALLBACK', { count: timeOut });
            }
        }

        return {
            bannerTitle,
            bannerSubtitle,
        };
    };

    const getButtons = () => {
        return (
            <>
                <Button
                    label={translate(showLimitNotification || !showAutoPlay ? 'CONTINUE_BUTTON' : 'NEXT_EPISODE_BUTTON')}
                    isPrimary={true}
                    onClick={() => {
                        loadNextEpisode();
                    }}
                />
                <Button
                    label={translate(showLimitNotification || !showAutoPlay ? 'STOP_BUTTON' : 'WATCH_CREDITS_BUTTON')}
                    onClick={() => {
                        dismissBanner(showLimitNotification || !showAutoPlay);
                    }}
                />
            </>
        );
    };

    const { bannerTitle, bannerSubtitle } = getData();

    return (
        <BingeWatchingContainer>
            <BingeWatchingDetails>
                <Title>{bannerTitle}</Title>
                <Description>{bannerSubtitle}</Description>
                <Buttons>{getButtons()}</Buttons>
            </BingeWatchingDetails>
            <BingeWatchingCover>
                <CardImage url={image} fallbackImage={fallbackLandscape} />
            </BingeWatchingCover>
        </BingeWatchingContainer>
    );
};
