import smoothscroll from 'smoothscroll-polyfill';
import 'intersection-observer';
import shaka from 'shaka-player';

/* eslint-disable global-require */
if (typeof Promise === 'undefined') {
    window.Promise = require('es6-promise').Promise;
}

smoothscroll.polyfill();

// eslint-disable-next-line import/no-extraneous-dependencies
require('whatwg-fetch');

require('youbora-adapter-shaka');

shaka.polyfill.installAll();
