import '@babel/polyfill';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import TagManager from 'react-gtm-module';

import Application from 'components/Application';
import { ConfigProvider } from 'providers/useConfig/ConfigContext';
import { matchIsMobileDevice } from 'style/styled-components/cssMediaQueries';
import React, { FC } from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { AuthProvider } from './providers/useAuth/AuthContext';
import { PlayerProvider } from './providers/player/PlayerContext';
import { setLocalStorage } from './utils/fnStorage';
import { TrackingProvider } from './providers/useTracking/TrackingContext';
import localConfig from './config/localConfig';
import { syncedHistory } from './config/syncedHistory';

import './style/scss/global.scss';

import { EpgProvider } from './providers/useEpgStore/EpgContext';
import { store } from './providers/reducers';
import { AppProvider, useApp } from './providers/useApp/AppContext';
import { ThemeConfigConnector } from './components/ThemeConfigConnector/ThemeConfigConnnector';
import { CollectionProvider } from './providers/useCollection/CollectionContext';
import { PurchaseProvider } from './providers/usePurchase/PurchaseContext';
import { WorldProvider } from './providers/useWorld/WorldContext';
import { BookmarkProvider } from './providers/useBookmark/BookmarkContext';
import { SearchProvider } from './providers/useSearch/SearchContext';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import { PinProvider } from './providers/usePin/PinContext';
import { SubscribeProvider } from './providers/useSubscription/SubscriptionContext';
import { PlayOptionsProvider } from './providers/usePlayOptions/PlayOptionsContext';
import { Notification } from './components/Notification/Notification';
import { SECONDS } from './utils/TimeUnit';
import { RecordingProvider } from './providers/useRecording/RecordingContext';
import { CastProvider } from './providers/cast/CastContext';
import { HintProvider } from './providers/useHint/HintContext';

// eslint-disable-next-line no-var, no-underscore-dangle
declare var __VERSION__: string;
// eslint-disable-next-line no-var, no-underscore-dangle
declare var __COMMITHASH__: string;
// eslint-disable-next-line no-var, no-underscore-dangle
declare var chromeCastStatus: boolean;

declare global {
    export interface Window {
        __VERSION__: string;
        __COMMITHASH__: string;
        chromeCastStatus: boolean;
        Cypress: any;
    }
}

// eslint-disable-next-line no-underscore-dangle
window.__VERSION__ = __VERSION__;
// eslint-disable-next-line no-underscore-dangle
window.__COMMITHASH__ = __COMMITHASH__;
// eslint-disable-next-line no-underscore-dangle
window.chromeCastStatus = chromeCastStatus;

// Set document title
document.title = localConfig.title;

setLocalStorage('isMobile', matchIsMobileDevice());

Sentry.init({
    dsn: 'https://93ef6185f9744add9b6ae9c0bf8d1924@o850291.ingest.sentry.io/5840558',
    // @ts-ignore
    enabled: process.env.NODE_ENV === 'production',
    // @ts-ignore
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.8,
    ignoreErrors: ['Non-Error exception captured', 'The user aborted a request', 'Network Error'],
});

const tagManagerArgs = {
    gtmId: 'GTM-KZFW4Z',
};

TagManager.initialize(tagManagerArgs);

const DataProvider: FC = ({ children }) => {
    const { isDesktop } = useApp();

    return (
        <Provider store={store}>
            <SearchProvider>
                <WorldProvider>
                    <CollectionProvider>
                        <BookmarkProvider>
                            <ToastProvider
                                components={{ Toast: Notification }}
                                placement={isDesktop() ? 'top-right' : 'bottom-right'}
                                autoDismissTimeout={SECONDS.toMillis(5)}
                            >
                                <PinProvider>
                                    <SubscribeProvider>
                                        <CastProvider>
                                            <PlayerProvider>
                                                <EpgProvider>
                                                    <PurchaseProvider>
                                                        <RecordingProvider>
                                                            <HintProvider>
                                                                <PlayOptionsProvider>{children}</PlayOptionsProvider>
                                                            </HintProvider>
                                                        </RecordingProvider>
                                                    </PurchaseProvider>
                                                </EpgProvider>
                                            </PlayerProvider>
                                        </CastProvider>
                                    </SubscribeProvider>
                                </PinProvider>
                            </ToastProvider>
                        </BookmarkProvider>
                    </CollectionProvider>
                </WorldProvider>
            </SearchProvider>
        </Provider>
    );
};

const CombinedProvider: FC = ({ children }) => {
    return (
        <AppProvider>
            <ConfigProvider>
                <TrackingProvider>
                    <ErrorBoundary innerLevel={false}>
                        <AuthProvider>
                            <Router history={syncedHistory}>
                                <ThemeConfigConnector>
                                    <ErrorBoundary innerLevel={true}>
                                        <DataProvider>{children}</DataProvider>
                                    </ErrorBoundary>
                                </ThemeConfigConnector>
                            </Router>
                        </AuthProvider>
                    </ErrorBoundary>
                </TrackingProvider>
            </ConfigProvider>
        </AppProvider>
    );
};

render(
    <CombinedProvider>
        <Application />
    </CombinedProvider>,
    document.getElementById('root')
);
