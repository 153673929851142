import SVGInline from 'react-svg-inline';
import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold } from '../../../style/styled-components/cssGlobalVariables';
import { DropdownContent, DropdownItemSimpleWrapper } from '../../DropdownMenu/DropdownMenu.css';
import { Breakpoints, MQ } from '../../../style/styled-components/cssMediaQueries';
import { hexToRgba } from '../../../utils/fnColors';

export const FilterSelector = styled.div`
    text-align: left;
    line-height: 21px;

    ${DropdownContent} {
        min-width: 164px;
        left: 0;
        box-shadow: 0 2px 2px 0 ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.24)},
            0 0 2px 0 ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.12)};
    }

    ${DropdownItemSimpleWrapper} {
        color: ${props => props.theme.brandedConfig.high_contrast};
        font-size: 14px;
        line-height: 20px;

        ${MQ(Breakpoints.s)} {
            padding: 10px 16px;
        }
    }
`;

export const SelectedLabel = styled.div`
    opacity: 1;
    display: inline-block;
    width: 100%;
    border-radius: 2px;
    text-align: left;
    position: relative;
    z-index: 1;
    overflow: hidden;
    font-size: 14px;
    line-height: 16px;
    height: 30px;
    padding: 0 16px;
    font-family: ${fontBold};
    cursor: pointer;
`;

export const DropDownLabelWrapper = styled.div``;

export const DropDownArrow = styled.div`
    background: none;
    position: absolute;
    right: 5px;
    top: 0;
    width: 24px;
    padding: 0 5.5px;
    z-index: 1;
`;

export const StyledSVGInline = styled(SVGInline)`
    width: 100%;
    height: 100%;
`;
