import React, { FC } from 'react';
import { Channel } from '../../../types/Asset';
import { CardChannel } from '../../Cards/CardChannel/CardChannel';
import { ChannelBundleWrapper } from '../../Cards/CardChannel/CardChannel.css';
import { useGuestAwareClickHandler } from '../../../providers/useAuth/AuthContext';
import { getLivePlayerURL } from '../../../utils/fnUrl';
import { useSubscription } from '../../../providers/useSubscription/SubscriptionContext';
import { CardTrackingData } from '../../../types/Tracking';

/**
 * Stripe item with 2 rows for channel case
 *
 * @param channelRow1 - first row data
 * @param channelRow2 - second row data
 *
 * @param trackingData
 */
export const ChannelBundlePresenter: FC<{
    channelRow1: Channel;
    channelRow2?: Channel;
    trackingData: CardTrackingData;
    trackingDataRow2?: CardTrackingData;
}> = ({ channelRow1, channelRow2, trackingData, trackingDataRow2 }) => {
    const guestCheck = useGuestAwareClickHandler();
    const { initSubscribeFlow } = useSubscription();

    const getCoverData = (channel: Channel) => {
        const playerURL = getLivePlayerURL(channel.id);

        return {
            uuid: channel.id + channel.number,
            id: channel.id,
            number: channel.number,
            path: playerURL,
            title: channel.name,
            image: channel.logo,
            metaData: null,
            landscapeImage: null,
            landscape: false,
            circle: true,
            assetType: channel.type,
            rawData: channel,
            subscription: channel.subscription,
            favorite: channel.favorite,
            isHd: channel.isHd,
            onClick: event =>
                guestCheck(event, () => {
                    if (!channel.subscription) {
                        event.preventDefault();
                        event.stopPropagation();

                        initSubscribeFlow(playerURL, {
                            type: 'Channel',
                            code: channel.productCode,
                        });
                    }
                }),
        };
    };

    return (
        <ChannelBundleWrapper>
            <CardChannel key={'line-1'} asset={getCoverData(channelRow1)} {...trackingData} />
            {channelRow2 && <CardChannel key={'line-2'} asset={getCoverData(channelRow2)} {...trackingDataRow2} />}
        </ChannelBundleWrapper>
    );
};
