import React from 'react';
import icons from '../../style';
import { AllButtonArrow, LinkShowAllWrapper } from './LinkShowAll.css';

export const LinkShowAll: React.FC = () => {
    return (
        <LinkShowAllWrapper>
            <AllButtonArrow className="font-svg" svg={icons.disclosureArrowIcon} />
        </LinkShowAllWrapper>
    );
};
