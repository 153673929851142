import { combineReducers, createStore } from 'redux';

import stripeStore from './stripe-reducer';
import channelsStore from './channel-reducer';

const reducers = combineReducers({
    stripeStore,
    channelsStore,
});

export type RootState = ReturnType<typeof reducers>;
export const store = createStore(reducers);

export default reducers;
