type Converter = {
    toMillis: (input: number) => number;
    toSeconds: (input: number) => number;
    toMinutes: (input: number) => number;
    toHours: (input: number) => number;
    toDays: (input: number) => number;
};

enum Unit {
    MILLISECOND,
    SECOND,
    MINUTE,
    HOUR,
    DAY,
}

const getUnitConverter = (dimension: Unit): Converter => {
    switch (dimension) {
        case Unit.MILLISECOND:
            return {
                toMillis: input => input,
                toSeconds: input => input / 1000,
                toMinutes: input => input / (1000 * 60),
                toHours: input => input / (1000 * 60 * 60),
                toDays: input => input / (1000 * 60 * 60 * 24),
            };
        case Unit.SECOND:
            return {
                toMillis: input => input * 1000,
                toSeconds: input => input,
                toMinutes: input => input / 60,
                toHours: input => input / (60 * 60),
                toDays: input => input / (60 * 60 * 24),
            };
        case Unit.MINUTE:
            return {
                toMillis: input => input * (1000 * 60),
                toSeconds: input => input * 60,
                toMinutes: input => input,
                toHours: input => input / 60,
                toDays: input => input / (60 * 24),
            };
        case Unit.HOUR:
            return {
                toMillis: input => input * (1000 * 60 * 60),
                toSeconds: input => input * (60 * 60),
                toMinutes: input => input * 60,
                toHours: input => input,
                toDays: input => input / 24,
            };

        case Unit.DAY:
            return {
                toMillis: input => input * (1000 * 60 * 60 * 24),
                toSeconds: input => input * (60 * 60 * 24),
                toMinutes: input => input * (60 * 24),
                toHours: input => input * 24,
                toDays: input => input,
            };
        default:
            return {
                toMillis: input => input,
                toSeconds: input => input,
                toMinutes: input => input,
                toHours: input => input,
                toDays: input => input,
            };
    }
};

class TimeUnit {
    private unit: Unit;

    constructor(unit: Unit) {
        this.unit = unit;
    }

    toMillis = (value: number) => getUnitConverter(this.unit)?.toMillis(value);

    toSeconds = (value: number) => getUnitConverter(this.unit)?.toSeconds(value);

    toMinutes = (value: number) => getUnitConverter(this.unit)?.toMinutes(value);

    toHours = (value: number) => getUnitConverter(this.unit)?.toHours(value);

    toDays = (value: number) => getUnitConverter(this.unit)?.toDays(value);
}

export const MILLISECONDS: TimeUnit = new TimeUnit(Unit.MILLISECOND);
export const SECONDS: TimeUnit = new TimeUnit(Unit.SECOND);
export const MINUTES: TimeUnit = new TimeUnit(Unit.MINUTE);
export const HOURS: TimeUnit = new TimeUnit(Unit.HOUR);
export const DAYS: TimeUnit = new TimeUnit(Unit.DAY);
