import Button from 'components/Buttons/Button/Button';
import { css } from 'styled-components';
import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { Breakpoints, MQ } from '../../../style/styled-components/cssMediaQueries';
import { body1FontSize, body1LineHeight, fontBold, fontNormal } from '../../../style/styled-components/cssGlobalVariables';
import { hexToRgba } from '../../../utils/fnColors';
import { PageTitle, PageTitleText } from '../../../style/styled-components/reusable.css';
import { LoaderContainer, LoaderIcon } from '../../LoadingIndicator/LoadingIndicator.css';

export const PageWrapper = styled.div`
    padding: 88px 16px 0 16px;
    margin: 0 auto;

    ${MQ(Breakpoints.s)} {
        max-width: 640px;
        padding: 88px 0 0 0;
    }

    ${MQ(Breakpoints.m)} {
        padding-top: 124px;
    }

    ${PageTitle} {
        font-size: 14px;
        padding: 14px 40px;
        position: fixed;
        top: 48px;
        left: 0;
        right: 0;
        background-color: ${props => props.theme.brandedConfig.black_color};
        border-bottom: 1px solid ${props => hexToRgba(props.theme.brandedConfig.white_color, 0.1)};
        display: none;

        ${MQ(Breakpoints.m)} {
            display: block;
        }
    }

    ${PageTitleText} {
        font-family: ${fontNormal};
    }
`;

export const Title = styled.h1`
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 32px;
    line-height: 40px;
    font-family: ${fontBold};
    text-align: center;
    display: none;

    ${MQ(Breakpoints.m)} {
        display: block;
    }
`;

export const Back = styled.div`
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 14px;
    line-height: 20px;
    font-family: ${fontNormal};
    text-align: center;
    padding-bottom: 48px;
    display: none;

    ${MQ(Breakpoints.m)} {
        display: block;
    }
`;

export const Subtitle = styled.h2<{ hideOnSmallRes?: boolean }>`
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 20px;
    line-height: 24px;
    font-family: ${fontBold};
    padding-bottom: 16px;

    ${props =>
        props.hideOnSmallRes
            ? css`
                  display: none;
                  ${MQ(Breakpoints.m)} {
                      display: block;
                  }
              `
            : css``}

    &:not(:first-child) {
        padding-top: 40px;
    }
`;

export const Option = styled.div<{ reducedHeight?: boolean; clickable?: boolean }>`
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 16px;
    line-height: 24px;
    font-family: ${fontNormal};
    background-color: ${props => props.theme.brandedConfig.low_contrast};
    border-radius: 4px;
    margin-bottom: 8px;
    position: relative;
    display: flex;
    align-items: center;

    ${props =>
        props.clickable
            ? css`
                  cursor: pointer;
              `
            : ``}

    ${props =>
        props.reducedHeight
            ? css`
                  padding: 10px 23px 10px 18px;
              `
            : css`
                  padding: 18px 23px 18px 18px;
              `}
`;

export const OptionIcon = styled.div`
    margin-right: 16px;

    svg {
        display: block;
        width: 24px;
        height: 100%;

        path {
            fill: ${props => props.theme.brandedConfig.high_contrast};
        }
    }
`;

export const OptionName = styled.div``;

export const OptionNameSub = styled.div`
    font-size: 14px;
    line-height: 20px;
    clear: both;
    opacity: 0.7;
    display: none;

    ${MQ(Breakpoints.m)} {
        display: block;
    }
`;

export const OptionButton = styled.a`
    font-family: ${fontNormal};
    display: block;
    cursor: pointer;

    svg {
        display: block;
        width: 7px;
        height: 100%;

        path {
            fill: ${props => props.theme.brandedConfig.high_contrast};
        }
    }
`;

export const OptionRightSide = styled.div`
    margin-left: auto;
`;

export const AboutInfo = styled.span`
    opacity: 0.7;
`;

export const DescriptionTitle = styled.p`
    font-size: 16px;
    line-height: 24px;
    font-family: ${fontNormal};
    padding-bottom: 16px;
`;

export const StyledButton = styled(Button)`
    min-width: 100%;
    text-align: center;

    ${MQ(Breakpoints.xs)} {
        min-width: 160px;
    }
`;

export const ButtonsContainer = styled.div`
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    ${MQ(Breakpoints.s)} {
        position: static;
    }
`;

export const SpeedTest = styled.div`
    height: calc(100vh - 160px);
    position: relative;

    ${OptionRightSide} {
        .SVGInline {
            display: block;
            width: 24px;
            height: 24px;
        }

        ${LoaderContainer} {
            min-height: auto;
            height: 24px;

            ${LoaderIcon} {
                width: 24px;
                height: 24px;
            }
        }
    }

    ${MQ(Breakpoints.s)} {
        height: auto;
    }

    ${MQ(Breakpoints.m)} {
        padding-top: 36px;
        text-align: center;

        ${Option} {
            &.internet-speed {
                text-align: left;
                background-color: transparent;
                flex-direction: column;
                padding: 0;
                margin: 0;

                ${OptionName} {
                    font-family: ${fontBold};
                    line-height: 40px;
                    font-size: 32px;
                    padding-bottom: 32px;
                }

                ${OptionRightSide} {
                    margin-left: unset;
                    font-family: ${fontBold};
                    line-height: 32px;
                    font-size: 24px;

                    ${LoaderContainer} {
                        min-height: auto;

                        ${LoaderIcon} {
                            width: 32px;
                            height: 32px;
                        }
                    }
                }
            }
        }
    }
`;

export const SpeedDescription = styled.div`
    padding-bottom: 24px;
    padding-top: 12px;

    ${MQ(Breakpoints.xs)} {
        padding-bottom: 40px;
    }

    ${MQ(Breakpoints.m)} {
        padding-top: 40px;
        text-align: center;
    }

    p:first-child {
        font-size: 16px;
        font-family: ${fontBold};
        padding: 0 0 14px 0;
        line-height: 24px;
    }

    p {
        font-size: 14px;
        font-family: ${fontNormal};
        line-height: 20px;
        padding: 4px 0;
    }
`;

export const CenterButton = styled(Button)<{ isDisabled?: boolean }>`
    position: fixed;
    bottom: 70px;
    left: 0;
    right: 0;
    margin-left: 13px;
    margin-right: 13px;

    ${MQ(Breakpoints.xs)} {
        position: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 22px;
    }
`;

export const ErrorMessage = styled.p`
    text-align: center;
    font-family: ${fontNormal};
    font-size: ${body1FontSize};
    line-height: ${body1LineHeight};

    ${MQ(Breakpoints.xs)} {
        margin-top: 38px;
    }
`;
