import React, { FC, useEffect, useRef, useState } from 'react';
import CastPlayer from 'providers/cast/CastPlayer';
import axios from 'axios';
import { useCast } from '../../providers/cast/CastContext';
import { usePlayer } from '../../providers/player/PlayerContext';
import {
    CastDeviceInfo,
    CastedTitle,
    InfoWrapper,
    InnerContainer,
    InnerWrapper,
    MiniControllerWrapper,
    Overlay,
    PlayPauseIcon,
} from './CastMiniController.css';
import fallbackImage from '../../assets/images/hero_fallback.png';
import { PictureWithFallback } from '../Picture/PictureWithFallback';
import translate from '../../utils/fnTranslate';
import icons from '../../style';
import { LinkTrackable } from '../Trackable/LinkTrackable';
import { getPlayerURLForCastMiniController } from '../../utils/fnUrl';
import { MiniControlState } from '../../types/Cast';
import CastHandler from '../../providers/cast/CastHandler';
import { LivePlayerAssetInfo, PlayerEvents } from '../../types/Player';
import { useAuth } from '../../providers/useAuth/AuthContext';
import { useDataFetcher, useRequestCanceller } from '../../hooks/useDataFetcher/useDataFetcher';
import { FetchPlayingAsset } from '../../types/ApiTypes';
import Api from '../../api/Api';
import { useGlobalNetworkError, useGlobalNoInternet } from '../../hooks/withNetworkCheck/withNetworkCheck';
import PlaybackManager from '../../providers/player/PlaybackManager';

export const CastMiniController: FC = () => {
    const { castConnected, deviceName, mediaInfo } = useCast();
    const { isOpen, isMini } = usePlayer();
    const [state, setState] = useState<MiniControlState>(null);
    const { isGuest } = useAuth();
    const { onNoInternet } = useGlobalNoInternet();
    const { onNetworkError } = useGlobalNetworkError();
    const setCanceller = useRequestCanceller();

    const { response, fetcher } = useDataFetcher<LivePlayerAssetInfo, FetchPlayingAsset>(
        params => Api.fetchLivePlayerInfo(params.id, params.createSession, new axios.CancelToken(executor => setCanceller(executor))),
        onNoInternet,
        onNetworkError
    );

    const castPlayer = useRef(new CastPlayer());

    const onPlayPauseClicked = e => {
        e.preventDefault();
        e.stopPropagation();

        if (mediaInfo?.playing) {
            castPlayer.current.pause();
        } else {
            castPlayer.current.play();
        }
    };

    const fetchDetails = channelId => {
        if (!isGuest) {
            fetcher(
                {
                    id: channelId,
                    createSession: false,
                },
                true
            );
        }
    };

    useEffect(() => {
        const onPlayerStateChangedListener = async event => {
            if (mediaInfo && mediaInfo?.type === 'LIVE_TV') {
                if (event?.value === PlayerEvents.ENDED.toUpperCase()) {
                    fetchDetails(mediaInfo.channelId);
                }
            }
        };

        if (CastHandler.getRemotePlayerController()) {
            CastHandler.getRemotePlayerController().addEventListener(
                window.cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
                onPlayerStateChangedListener
            );
        }

        return () => {
            if (CastHandler.getRemotePlayerController()) {
                CastHandler.getRemotePlayerController().removeEventListener(
                    window.cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
                    onPlayerStateChangedListener
                );
            }
        };
    }, [mediaInfo]);

    useEffect(() => {
        if (response) {
            PlaybackManager.updatePlayingAsset(response).catch(() => {});
        }
    }, [response]);

    useEffect(() => {
        const playerIsOpened = isOpen && !isMini;

        if (!castConnected || !mediaInfo) {
            setState(null);
        }

        if (!castConnected && mediaInfo) {
            setState('closing');
        }

        if (!state && !playerIsOpened && castConnected && mediaInfo && mediaInfo.loaded) {
            setState('opening');
        }

        if ((state && playerIsOpened && castConnected && mediaInfo) || !mediaInfo?.loaded) {
            setState('closing');
        }
    }, [isOpen, isMini, castConnected, mediaInfo]);

    useEffect(() => {
        if (state === 'opening') {
            setTimeout(() => {
                setState('opened');
            }, 250);
        }

        if (state === 'closing') {
            setTimeout(() => {
                setState(null);
            }, 250);
        }
    }, [state]);

    if (!state || !mediaInfo) {
        return null;
    }

    return (
        <MiniControllerWrapper className={state}>
            <InnerContainer>
                <LinkTrackable to={getPlayerURLForCastMiniController(mediaInfo?.id, mediaInfo?.type)}>
                    <PictureWithFallback
                        src={mediaInfo?.image}
                        fallbackImage={fallbackImage}
                        fit={'cover'}
                        hPosition={'center'}
                        vPosition={'top'}
                    />
                    <Overlay />
                    <InfoWrapper>
                        <InnerWrapper>
                            <CastedTitle>{mediaInfo?.title}</CastedTitle>
                            <CastDeviceInfo>{translate('FEATURE_CAST_SENDER_HINT', { receiver_name: deviceName })}</CastDeviceInfo>
                        </InnerWrapper>
                    </InfoWrapper>
                    {mediaInfo && mediaInfo.type !== 'LIVE_TV' && (
                        <PlayPauseIcon
                            svg={mediaInfo?.playing ? icons.pauseIconSimple : icons.playIconSimple}
                            onClick={onPlayPauseClicked}
                        />
                    )}
                </LinkTrackable>
            </InnerContainer>
        </MiniControllerWrapper>
    );
};
